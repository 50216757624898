// Importing Antd
import { Button, Menu } from 'antd';
import {
  LoginOutlined,
  LogoutOutlined,
  PartitionOutlined,
  PlayCircleOutlined,
  DatabaseOutlined,
} from '@ant-design/icons';
import './styles.less';
// Importing React
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
// Importing child components & types
import type { MenuLeftProps } from './types';
import RoutesMenu from './RoutesMenu';
import ConfigurationMenu from './ConfigurationMenu';
import { useLocation } from 'react-router';

const MenuLeft = ({
  breadcrumb,
  organizationsState,
  usersState,
  isSiderCollapsed,
  getOrganization,
  getProfile,
  getStateProfile,
  getUser,
  setLanguage,
}: MenuLeftProps) => {
  const location = useLocation();
  const [activeMenuItem, setActiveMenuItem] = useState<string>();
  const getMenuItemStyle = (menuKey: string) => {
    if (activeMenuItem === menuKey) return 'sider-menu-item-button sider-menu-item-selected';
    return 'sider-menu-item-button';
  };

  useEffect(() => {
    const routeName =
      location.pathname.split('/').length > 0 ? location.pathname.split('/')[1] : '';
    setActiveMenuItem(routeName);
  }, [location]);
  return (
    <div className="site-layout__slider-content">
      <RoutesMenu
        activeMenuItem={activeMenuItem}
        isSiderCollapsed={isSiderCollapsed}
        setActiveMenuItem={setActiveMenuItem}
        getMenuItemStyle={getMenuItemStyle}
      />
      <ConfigurationMenu
        isSiderCollapsed={isSiderCollapsed}
        activeMenuItem={activeMenuItem}
        setActiveMenuItem={setActiveMenuItem}
        breadcrumb={breadcrumb}
        getMenuItemStyle={getMenuItemStyle}
        organizationsState={organizationsState}
        usersState={usersState}
        getOrganization={getOrganization}
        getProfile={getProfile}
        getStateProfile={getStateProfile}
        getUser={getUser}
        setLanguage={setLanguage}
      />{' '}
    </div>
  );
};

export default MenuLeft;
