import React, { useState } from 'react';
import { Table } from 'antd';
import {
  OctoTableProps,
  TableColumn,
  TableRecord,
  rowSelectionConfig,
  sizeStyleType,
  expandableConfig,
  paginationDisableType,
  scrollConfig,
  tableSorter,
  FormattersType,
} from './types';

const useOctoTable = (initialColumns: TableColumn[]) => {
  const [columns, setColumns] = useState<TableColumn[]>(initialColumns);
  const [records, setRecords] = useState<TableRecord[]>();
  const [formatters, setFormatters] = useState<FormattersType>();
  const [sorters, setSorters] = useState<tableSorter>();
  const [rowSelection, setRowSelection] = useState<rowSelectionConfig>();
  const [selectedRow, setSelectedRow] = useState<Array<any>>([]);
  const [size, setSize] = useState<sizeStyleType>();
  const [expandable, setExpandable] = useState<expandableConfig>();
  const [pagination, setPagination] = useState<paginationDisableType>();
  const [scroll, setScroll] = useState<scrollConfig>({});
  const [rowClassName, setRowClassName] = useState<any>();
  // const [loading, setLoading] = useState<boolean>(false);
  return {
    columns,
    records,
    formatters,
    sorters,
    rowSelection,
    selectedRow,
    size,
    expandable,
    pagination,
    scroll,
    rowClassName,
    // loading,
    putColumns: setColumns,
    putFormatters: setFormatters,
    putRecords: setRecords,
    putSorters: setSorters,
    putRowSelection: setRowSelection,
    putSelectedRow: setSelectedRow,
    putSize: setSize,
    putExpandable: setExpandable,
    putPagination: setPagination,
    putScroll: setScroll,
    putRowClassName: setRowClassName,
    // putLoading: setLoading,
  };
};
const OctoTable = ({
  columns,
  records,
  formatters,
  sorters,
  rowSelection,
  size,
  className,
  recordTable,
  style,
  expandable,
  pagination,
  scroll,
  rowClassName,
  tableLoading,
}: OctoTableProps) => {
  columns?.map((col) => {
    return {
      ...col,
      dataIndex: col.key,
    };
  });
  const antdColumns = columns?.map((col) => {
    return {
      ...col,
      render: formatters ? formatters[col.key] : undefined,
      dataIndex: col.key,
      sorter: sorters ? sorters[col.key] : false,
    };
  });

  let sizeType;
  if (size) {
    sizeType = size;
  }

  return (
    <Table
      scroll={scroll}
      className={recordTable ? 'table-records__container ' + className : className}
      style={style}
      columns={antdColumns}
      dataSource={records}
      rowSelection={rowSelection}
      rowKey={(record) => record.key}
      size={sizeType}
      expandable={expandable}
      pagination={pagination}
      rowClassName={rowClassName}
      loading={tableLoading}
    />
  );
};

export { useOctoTable, OctoTable };
