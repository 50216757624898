import { createSlice } from '@reduxjs/toolkit';
import type { ConnectorsReducerActions } from './types';
import { Connector, ConnectorCategory } from '@features/connectors/types';

export const INITIAL_SOURCE_STATE = { loaded: false, isFetching: false, connectors:[] ,connectorsCategories:[] } as ConnectorsState;
export interface ConnectorsState {
  loaded: boolean;
  isFetching?: boolean;
  shouldBeClosed?: boolean;
  error?: boolean;
  errorDetails?: string | undefined;
  page?: number;
  limit?: number;
  total?: number;
  connectors: Connector[];
  connectorsCategories: ConnectorCategory[]
}
const connectorsSlice = createSlice({
  name: 'connectors',
  initialState: INITIAL_SOURCE_STATE,

  reducers: {
    getConnectors: (state) => {
      state.isFetching = true;
      state.connectors = [];
    },
    getConnectorsSuccess: (state, { payload }: ConnectorsReducerActions) => {
      state.loaded = true;
      state.isFetching = false;
      state.connectors = payload.connectors;
      state.connectorsCategories = payload.connectorsCategories;
    },
    getConnectorsFailed: (state, { payload }: ConnectorsReducerActions) => {
      state.isFetching = false;
      state.error = true;
      state.errorDetails = payload.errorDetails;
    },
  }
});
export default connectorsSlice;
