import { OctoObject } from '@features/objects/types';
import {
  consentsColumns,
  contactsColumns,
  eventsColumns,
  eventsStatisticsColumns,
  ordersColumns,
  ordersItemsColumns,
  productsColumns,
  storesColumns,
  AudienceRFMColumns,
} from '@data/objectsColumns';
import {
  contactsRecordsGenerator,
  ordersItemsRecordsGenerator,
  ordersRecordsGenerator,
  productsRecordsGenerator,
  storesRecordsGenerator,
  eventsRecordsGenerator,
  eventsStatsRecordsGenerator,
  consentsRecordsGenerator,
} from '@utils/recordsGenerator';
import { generateRandomInteger } from '@utils/helpers';

const randomInteger = generateRandomInteger(1, 50);

export const objects: OctoObject[] = [
  {
    key: 'object1',
    name: 'Contacts',
    recordsNumber: randomInteger,
    tableName: 'Contacts',
    records: contactsRecordsGenerator(randomInteger),
    pointer: 'octolis_raw.contacts_ceigd',
    created: new Date(),
    category: 'contacts',
    schema: contactsColumns,
    primaryKeys: ['col1'],
    exposedToMarketingUsers: true,
  },
  {
    key: 'object2',
    name: 'Orders',
    tableName: 'Orders',
    recordsNumber: randomInteger,
    records: ordersRecordsGenerator(randomInteger),
    pointer: 'octolis_raw.orders_ceigd',
    created: new Date(),
    category: 'orders',
    schema: ordersColumns,
    primaryKeys: ['col1'],
    exposedToMarketingUsers: true,
  },
  {
    key: 'object3',
    name: 'Orders items',
    tableName: 'Orders_items',
    recordsNumber: randomInteger,
    records: ordersItemsRecordsGenerator(randomInteger),
    pointer: 'octolis_raw.orders_items',
    created: new Date(),
    category: 'orders_items',
    schema: ordersItemsColumns,
    primaryKeys: ['col1'],
    exposedToMarketingUsers: true,
  },
  {
    key: 'object4',
    name: 'Products',
    tableName: 'products',
    recordsNumber: randomInteger,
    records: productsRecordsGenerator(randomInteger),
    pointer: 'octolis_raw.products',
    created: new Date(),
    category: 'products',
    schema: productsColumns,
    primaryKeys: ['col1'],
    exposedToMarketingUsers: true,
  },
  {
    key: 'object5',
    name: 'Stores',
    tableName: 'stores',
    recordsNumber: randomInteger,
    records: storesRecordsGenerator(randomInteger),
    pointer: 'octolis_raw.stores',
    created: new Date(),
    category: 'stores',
    schema: storesColumns,
    primaryKeys: ['col1'],
    exposedToMarketingUsers: true,
  },
  {
    key: 'object6',
    name: 'Events',
    tableName: 'events',
    recordsNumber: randomInteger,
    records: eventsRecordsGenerator(randomInteger),
    pointer: 'octolis_raw.events',
    created: new Date(),
    category: 'events',
    schema: eventsColumns,
    primaryKeys: ['col1'],
    exposedToMarketingUsers: true,
  },
  {
    key: 'object7',
    name: 'Events statistics',
    tableName: 'events_statistics',
    recordsNumber: randomInteger,
    records: eventsStatsRecordsGenerator(randomInteger),
    pointer: 'octolis_raw.events_statistics',
    created: new Date(),
    category: 'events_statistics',
    schema: eventsStatisticsColumns,
    primaryKeys: ['col1'],
    exposedToMarketingUsers: true,
  },
  {
    key: 'object8',
    name: 'Consents',
    tableName: 'consents',
    recordsNumber: randomInteger,
    records: consentsRecordsGenerator(randomInteger),
    pointer: 'octolis_raw.consents',
    created: new Date(),
    category: 'consents',
    schema: consentsColumns,
    primaryKeys: ['col1'],
    exposedToMarketingUsers: true,
  },
  {
    key: 'object9',
    name: 'Contacts generated from recipe1',
    tableName: 'Contacts generated',
    recordsNumber: 452000,
    records: [
      {
        name: 'Alfred',
        email: 'Alfred@gmail.com',
        phone: '26154859',
        emails_org: 'Alfred@org.co',
      },
    ],
    pointer: 'octolis_raw.contacts_ceigd',
    created: new Date(),
    category: 'contacts',
    schema: [
      {
        id: 'col1',
        columnName: 'name',
        columnType: 'string',
        required: false,
        category: 'firstName',
        description: 'this is a description of the column',
        position: 1,
      },
      {
        id: 'col2',
        columnName: 'email',
        columnType: 'string',
        required: false,
        category: 'email',
        description: 'this is a description of the column',
        position: 2,
      },
      {
        id: 'col3',
        columnName: 'phone',
        columnType: 'string',
        required: false,
        category: 'phone',
        description: 'this is a description of the column',
        position: 3,
      },
      {
        id: 'col4',
        columnName: 'emails_org',
        columnType: 'string',
        required: true,
        category: 'email',
        description: 'this is a description of the column',
        position: 4,
      },
    ],
    primaryKeys: ['col4'],
  },
  {
    key: 'object10',
    name: 'Orders generated from recipe2',
    tableName: 'Orders generated',
    recordsNumber: 1902048,
    records: [
      {
        client: 'client1',
        product: 'prod1',
        price: '90',
        date: new Date(),
        transaction_id: '84tq6fm*e9sd54w/c5b8d',
      },
    ],
    pointer: 'octolis_raw.orders_ceigd',
    created: new Date(),
    category: 'orders',
    schema: [
      {
        id: 'col1',
        columnName: 'client',
        columnType: 'string',
        required: false,
        category: 'key',
        description: 'this is a description of the column',
        position: 1,
      },
      {
        id: 'col2',
        columnName: 'product',
        columnType: 'string',
        required: false,
        category: 'key',
        description: 'this is a description of the column',
        position: 2,
      },
      {
        id: 'col3',
        columnName: 'price',
        columnType: 'number',
        required: false,
        category: 'price',
        description: 'this is a description of the column',
        position: 3,
      },
      {
        id: 'col4',
        columnName: 'date',
        columnType: 'date',
        required: false,
        category: 'Date',
        description: 'this is a description of the column',
        position: 4,
      },
      {
        id: 'col5',
        columnName: 'transaction_id',
        columnType: 'string',
        required: false,
        category: 'key',
        description: 'this is a description of the column',
        position: 5,
      },
    ],
    primaryKeys: ['col5'],
  },
  {
    key: 'object11',
    name: 'Products generated from recipe3',
    tableName: 'Products generated',
    recordsNumber: 452000,
    records: [
      {
        name: 'product1',
        price: '530',
        product_ref: 'Y8dKs56',
      },
    ],
    pointer: 'octolis_raw.contacts_ceigd',
    created: new Date(),
    category: 'contacts',
    schema: [
      {
        id: 'col1',
        columnName: 'name',
        columnType: 'string',
        required: false,
        category: 'firstName',
        description: 'this is a description of the column',
        position: 1,
      },
      {
        id: 'col2',
        columnName: 'price',
        columnType: 'number',
        required: false,
        category: 'price',
        description: 'this is a description of the column',
        position: 2,
      },
      {
        id: 'col5',
        columnName: 'product_ref',
        columnType: 'string',
        required: true,
        category: 'key',
        description: 'this is a description of the column',
        position: 3,
      },
    ],
    primaryKeys: ['col1'],
  },
  {
    key: 'object12',
    name: 'Raw Data from source 1',
    tableName: 'Raw',
    recordsNumber: 452000,
    records: [],
    pointer: 'octolis_raw.contacts_ceigd',
    created: new Date(),
    category: 'contacts',
    schema: contactsColumns,
    primaryKeys: ['col1'],
  },
  {
    key: 'object13',
    name: 'Raw Data from source 2',
    tableName: 'Raw',
    recordsNumber: 452000,
    records: [],
    pointer: 'octolis_raw.orders_cegid',
    created: new Date(),
    category: 'orders',
    schema: ordersColumns,
    primaryKeys: ['col1'],
  },
  {
    key: 'object14',
    name: 'Raw Data from source 3',
    tableName: 'Raw',
    recordsNumber: 452000,
    records: [],
    pointer: 'octolis_raw.contacts_cegid',
    created: new Date(),
    category: 'orders',
    schema: contactsColumns,
    primaryKeys: ['col1'],
  },
  {
    key: 'object15',
    name: 'Raw Data from source 2',
    tableName: 'Raw',
    recordsNumber: 452000,
    records: [],
    pointer: 'octolis_raw.orders_cegid',
    created: new Date(),
    category: 'orders',
    schema: ordersColumns,
    primaryKeys: ['col1'],
  },
  {
    key: 'objectrfm',
    name: 'contacts_RFM',
    tableName: 'contacts_RFM',
    recordsNumber: randomInteger,
    records: storesRecordsGenerator(randomInteger),
    pointer: 'octolis_raw.contacts_RFM',
    created: new Date(),
    category: 'contacts',
    schema: AudienceRFMColumns,
    primaryKeys: ['id_eshop'],
    exposedToMarketingUsers: true,
  }
];
