// Importing type
import * as apiTypes from './types';
import { sleep } from '@utils/helpers';
import { Connector, ConnectorCategory } from '@features/connectors/types';
import { connectors, connectorsCategoriesData } from '@data/connectors';

const getConnectors: apiTypes.APIGetConnectors = async (type: string) => {
  await sleep(1500);
  let connectorsloaded: Connector[];
  let connectorsCategories: ConnectorCategory[];
  let error: boolean = false;
  let errorDetails: string | undefined = undefined;
  // const connectorsList: Connector[] = connectors;
  // connectors = connectorsList.filter((a) => a.type === type);
  connectorsCategories = connectorsCategoriesData;

  if (!connectors) {
    error = true;
    errorDetails = 'No sources found.';
  }
  return {
    connectors,
    connectorsCategories,
    error,
    loaded: true,
    errorDetails: error && errorDetails ? errorDetails : undefined,
  };
};
export { getConnectors };
