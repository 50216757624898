// Importing type
import * as apiTypes from './types';
import { generateId, sleep } from '@utils/helpers';
import { User } from '@features/users/types';

const getUsers: apiTypes.APIGetUsers = async (app) => {
  await sleep(1000);

  let users: User[];
  let octolisUsers: User[];
  let sendinblueUsers: User[];
  let splioUsers: User[];
  let error: boolean = false;
  let errorDetails: string | undefined = undefined;

  octolisUsers = [
    {
      id: generateId(),
      email: 'admin@octolis.com',
      firstName: 'Admin',
      lastName: 'Admin',
      lastLogin: Date.now(),
      access: [
        {
          organization: 'octolis',
          userProfile: 'admin',
          active: true,
        },
      ],
    },
    {
      id: generateId(),
      email: 'marc@octolis.com',
      firstName: 'Marc',
      lastName: 'Dupont',
      lastLogin: Date.now(),
      access: [
        {
          organization: 'octolis',
          userProfile: 'data',
          active: true,
        },
      ],
    },
    {
      id: generateId(),
      email: 'cecile@octolis.com',
      firstName: 'Cécile',
      lastName: 'Doe',
      lastLogin: Date.now(),
      access: [
        {
          organization: 'octolis',
          userProfile: 'marketing',
          active: true,
        },
      ],
    },
  ];
  sendinblueUsers = [
    {
      id: generateId(),
      email: 'admin@sendinblue.com',
      firstName: 'Admin',
      lastName: 'Admin',
      lastLogin: Date.now(),
      access: [
        {
          organization: 'sendinblue',
          userProfile: 'admin',
          active: true,
        },
      ],
    },
    {
      id: generateId(),
      email: 'marc@sendinblue.com',
      firstName: 'Marc',
      lastName: 'Dupont',
      lastLogin: Date.now(),
      access: [
        {
          organization: 'sendinblue',
          userProfile: 'data',
          active: true,
        },
      ],
    },
    {
      id: generateId(),
      email: 'cecile@sendinblue.com',
      firstName: 'Cécile',
      lastName: 'Doe',
      lastLogin: Date.now(),
      access: [
        {
          organization: 'sendinblue',
          userProfile: 'marketing',
          active: true,
        },
      ],
    },
  ];
  splioUsers = [
    {
      id: generateId(),
      email: 'admin@splio.com',
      firstName: 'Admin',
      lastName: 'Admin',
      lastLogin: Date.now(),
      access: [
        {
          organization: 'splio',
          userProfile: 'admin',
          active: true,
        },
      ],
    },
    {
      id: generateId(),
      email: 'marc@splio.com',
      firstName: 'Marc',
      lastName: 'Dupont',
      lastLogin: Date.now(),
      access: [
        {
          organization: 'splio',
          userProfile: 'data',
          active: true,
        },
      ],
    },
    {
      id: generateId(),
      email: 'cecile@splio.com',
      firstName: 'Cécile',
      lastName: 'Doe',
      lastLogin: Date.now(),
      access: [
        {
          organization: 'splio',
          userProfile: 'marketing',
          active: true,
        },
      ],
    },
  ];
  users = [...sendinblueUsers, ...splioUsers, ...octolisUsers];
  if (!users) {
    error = true;
    errorDetails = 'No users found.';
  }

  return {
    error,
    users,
    loaded: true,
    errorDetails: error && errorDetails ? errorDetails : undefined,
  };
};

const getUser: apiTypes.APIGetUser = async (userId, organization) => {
  await sleep(1000);

  let user: User;
  let error: boolean = false;
  let errorDetails: string | undefined = undefined;

  if (userId === 'admin') {
    user = {
      id: generateId(),
      email: 'admin@octolis.com',
      firstName: 'admin',
      lastName: 'admin',
      lastLogin: Date.now(),
      access: [
        {
          organization,
          userProfile: 'admin',
          active: true,
        },
      ],
    };
  } else if (userId === 'marketing') {
    user = {
      id: generateId(),
      email: 'marketing@octolis.com',
      firstName: 'marketing',
      lastName: 'marketing',
      lastLogin: Date.now(),

      access: [
        {
          organization,
          userProfile: 'marketing',
          active: true,
        },
      ],
    };
  } else if (userId === 'data') {
    user = {
      id: generateId(),
      email: 'data@octolis.com',
      firstName: 'data',
      lastName: 'data',
      lastLogin: Date.now(),

      access: [
        {
          organization,
          userProfile: 'data',
          active: true,
        },
      ],
    };
  } else {
    user = {
      id: generateId(),
      email: 'clement@cartelis.com',
      firstName: 'Clément',
      lastLogin: Date.now(),
      lastName: 'Galopin',
      access: [
        {
          organization: 'cartelis',
          userProfile: 'admin',
          active: true,
        },
        {
          organization: 'splio',
          userProfile: 'admin',
          active: false,
        },
      ],
    };
  }
  if (!user) {
    error = true;
    errorDetails = 'No user found.';
  }

  return {
    error,
    user,
    loaded: true,
    errorDetails: error && errorDetails ? errorDetails : undefined,
  };
};
const saveUser: apiTypes.APISaveUser = async (user: User) => {
  await sleep(1000);

  let error: boolean = false;
  let errorDetails: string | undefined = undefined;

  if (!user) {
    error = true;
    errorDetails = 'No user found.';
  }

  return {
    error,
    user,
    loaded: true,
    errorDetails: error && errorDetails ? errorDetails : undefined,
  };
};
const deleteUser: apiTypes.APIDeleteUser = async (userId: string) => {
  await sleep(1000);

  let error: boolean = false;
  let errorDetails: string | undefined = undefined;

  if (!userId) {
    error = true;
    errorDetails = 'No user found.';
  }

  return {
    error,
    userId,
    loaded: true,
    errorDetails: error && errorDetails ? errorDetails : undefined,
  };
};
export { getUsers, getUser, saveUser, deleteUser };
