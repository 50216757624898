import React from 'react';
import { generateId } from '@utils/helpers';
import { Position } from 'react-flow-renderer';
import { Node, NodeTypes } from '@features/flows/types';
import generateObject from '@features/objects/generateObject';
import { Sync } from '@features/syncs/types';
import { generateRecipe } from '@features/recipes/generateRecipe';
import { sources } from '@data/sources';
import { Segment } from '@features/segments/types';
import { Audience } from '@features/audiences/types';

const generateSyncFlow = (sync: Sync, flowKey?: string) => {
  const sourceNodeId = generateId();

  let sourceNode: Node;
  let sourceObjectNode: Node;
  let recipeNode: Node;
  let recipeObjectNode: Node;
  const sourceObjectNodeId = generateId();
  const recipeNodeId = generateId();
  const recipeObjectNodeId = generateId();
  const sourceToObjectEdge = {
    key: generateId(),
    source: sourceNodeId,
    target: sourceObjectNodeId,
    animated: true,
    type: 'smoothstep',
  };
  const objectToRecipeEdge = {
    key: generateId(),
    source: sourceObjectNodeId,
    target: recipeNodeId,
    animated: true,
    type: 'smoothstep',
  };
  const SQlToObjectEdge = {
    key: generateId(),
    source: recipeNodeId,
    target: recipeObjectNodeId,
    animated: true,
    type: 'smoothstep',
  };
  /*
  const objectToMappingNodeEdge = {
    key: generateId(),
    source: recipeObjectNodeId,
    target: mappingNodeId,
    animated: true,
    type: 'smoothstep',
  };
  const mappingToIntegrationNodeEdge = {
    key: generateId(),
    source: mappingNodeId,
    target: integrationNodeId,
    animated: true,
    type: 'smoothstep',
  };
  const integrationToObjectNodeEdge = {
    key: generateId(),
    source: integrationNodeId,
    target: integrationObjectNodeId,
    animated: true,
    type: 'smoothstep',
  };
 if (sync.origin.originType === 'rawSource') {
    const source = sync.origin;
    sourceNode = {
      source,
      key: sourceNodeId,
      type: NodeTypes.SOURCE,
      label: source.name,
      records: source.records,
      sourcePosition: Position.Right,
      position: { x: 100, y: 100 },
    };
    sourceObjectNode = {
      key: sourceObjectNodeId,
      type: NodeTypes.OBJECT,
      targetPosition: Position.Left,
      sourcePosition: Position.Right,
      label: source.sourceObject ? source.sourceObject.name : 'No object',
      object: source.sourceObject ? source.sourceObject : generateObject('no object'),
      position: { x: 250, y: 100 },
    };
    recipeNode = {
      recipe: source.recipe ? source.recipe : generateRecipe('recipe for sync source'),
      key: recipeNodeId,
      type: NodeTypes.RECIPE,
      targetPosition: Position.Left,
      sourcePosition: Position.Right,
      label: source.recipe ? source.recipe.name : 'recipe for sync source',
      position: { x: 400, y: 100 },
    };
    const recipeObject = source.targetObject;
    recipeObjectNode = {
      key: recipeObjectNodeId,
      type: NodeTypes.OBJECT,
      targetPosition: Position.Left,
      sourcePosition: Position.Right,
      label: recipeObject ? recipeObject.name : 'No object',
      object: recipeObject ? recipeObject : generateObject('no object'),
      position: { x: 550, y: 100 },
    };
    mappingNode = {
      key: mappingNodeId,
      type: NodeTypes.MAPPING,
      targetPosition: Position.Left,
      sourcePosition: Position.Right,
      label: 'Mapping',
      mapping: sync.mapping,
      position: { x: 700, y: 100 },
    };
    integrationNode = {
      key: integrationNodeId,
      type: NodeTypes.INTEGRATION,
      targetPosition: Position.Left,
      sourcePosition: Position.Right,
      label: sync.integration.name,
      integration: sync.integration,
      position: { x: 850, y: 100 },
    };
    integrationObjectNode = {
      key: integrationObjectNodeId,
      type: NodeTypes.OBJECT,
      targetPosition: Position.Left,
      sourcePosition: Position.Right,
      label: 'Integration object',
      object: sync.integrationObject,
      position: { x: 1000, y: 100 },
    };

    return {
      key: flowKey ? flowKey : generateId(),
      name: sync.name,
      nodes: [
        sourceNode,
        sourceObjectNode,
        recipeNode,
        recipeObjectNode,
        mappingNode,
        integrationNode,
        integrationObjectNode,
      ],
      edges: [
        objectToRecipeEdge,
        SQlToObjectEdge,
        sourceToObjectEdge,
        objectToMappingNodeEdge,
        mappingToIntegrationNodeEdge,
        integrationToObjectNodeEdge,
      ],
    };
  } else {*/
  const audience: Audience = sync.origin;
  // const source = audience ? audience.sources[0].source : sources[0];
  const source = sources[0];
  sourceNode = {
    source,
    key: sourceNodeId,
    type: NodeTypes.SOURCE,
    label: source.name,
    records: 0,
    sourcePosition: Position.Right,
    position: { x: 100, y: 100 },
  };
  sourceObjectNode = {
    key: sourceObjectNodeId,
    type: NodeTypes.OBJECT,
    targetPosition: Position.Left,
    sourcePosition: Position.Right,
    label: source.sourceObject ? source.sourceObject.name : 'No object',
    object: source.sourceObject ? source.sourceObject : generateObject('no object'),
    position: { x: 300, y: 100 },
  };
  recipeNode = {
    recipe: source.recipe ? source.recipe : generateRecipe('recipe for sync source'),
    key: recipeNodeId,
    type: NodeTypes.RECIPE,
    targetPosition: Position.Left,
    sourcePosition: Position.Right,
    label: source.recipe ? source.recipe.name : 'recipe for sync source',
    position: { x: 500, y: 100 },
  };
  const recipeObject = source.targetObject;
  recipeObjectNode = {
    key: recipeObjectNodeId,
    type: NodeTypes.OBJECT,
    targetPosition: Position.Left,
    sourcePosition: Position.Right,
    label: recipeObject ? recipeObject.name : 'No object',
    object: recipeObject ? recipeObject : generateObject('no object'),
    position: { x: 700, y: 100 },
  };

  return {
    nodes: [sourceNode, sourceObjectNode, recipeNode, recipeObjectNode],
    edges: [objectToRecipeEdge, SQlToObjectEdge, sourceToObjectEdge],
  };
};

export default generateSyncFlow;
