import React from 'react';
import { Tag } from 'antd';

interface OctoFlowsItemProps {
  elements: {
    name: string;
    [key: string]: any;
  }[];
  onClick: (element: any) => void;
}

const OctoLinksItem = ({ elements, onClick }: OctoFlowsItemProps) => {
  return (
    <div>
      {elements.map((element: any, index: number) => {
        return (
          <Tag key={index} onClick={() => onClick(element)}>
            <a>{element.name}</a>
          </Tag>
        );
      })}
    </div>
  );
};

export default OctoLinksItem;
