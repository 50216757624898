import { Integration } from '@features/integrations/types';
import { destinationAPIConnections } from './connections';

// export type Integration = {
//     key:  string;
//     name: string;
//     type: INTEGRATION_TYPE;
//     connection: Connection
// }

// enum INTEGRATION_TYPE {
//     UPSERT = 'upsert',
//     DELETE = 'delete'
// }

export const integrations: Integration[] = [
  {
    key: 'integration_key_1',
    status: true,
    pointer: 'orders',
    query: 'SELECT',
    name: 'Salesforce orders',
    connection: destinationAPIConnections[0],
    category: 'orders',
  },
  {
    key: 'integration_key_2',
    pointer: 'contacts',
    status: false,
    query: 'upsert',
    name: 'Zendesk contacts',
    connection: destinationAPIConnections[1],
    category: 'contacts',
  },
  {
    key: 'integration_key_3',
    pointer: 'products',
    status: false,
    query: 'upsert',
    name: 'Splio products',
    connection: destinationAPIConnections[2],
    category: 'products',
  },
  {
    key: 'integration_key_4',
    pointer: 'orders',
    status: false,
    query: 'upsert',
    name: 'Google Ads orders',
    connection: destinationAPIConnections[3],
    category: 'orders',
  },
  {
    key: 'integration_key_5',
    status: true,
    pointer: 'contacts',
    query: 'SELECT',
    name: 'Salesforce contacts',
    connection: destinationAPIConnections[0],
    category: 'contacts',
  },
  {
    key: 'integration_key_6',
    pointer: 'products',
    status: false,
    query: 'upsert',
    name: 'Zendesk products',
    connection: destinationAPIConnections[1],
    category: 'products',
  },
  {
    key: 'integration_key_9',
    pointer: 'contacts',
    status: false,
    query: 'upsert',
    name: 'Splio contacts',
    connection: destinationAPIConnections[2],
    category: 'contacts',
  },
  {
    key: 'integration_key_11',
    pointer: 'products',
    status: false,
    query: 'upsert',
    name: 'Google Ads products',
    connection: destinationAPIConnections[3],
    category: 'products',
  },
  {
    key: 'integration_key_7',
    status: true,
    pointer: 'products',
    query: 'SELECT',
    name: 'Salesforce products',
    connection: destinationAPIConnections[0],
    category: 'products',
  },
  {
    key: 'integration_key_8',
    pointer: 'orders',
    status: false,
    query: 'upsert',
    name: 'Zendesk orders',
    connection: destinationAPIConnections[1],
    category: 'orders',
  },

  {
    key: 'integration_key_10',
    pointer: 'orders',
    status: false,
    query: 'upsert',
    name: 'Splio orders',
    connection: destinationAPIConnections[2],
    category: 'orders',
  },
  {
    key: 'integration_key_12',
    pointer: 'contacts',
    status: false,
    query: 'upsert',
    name: 'Google Ads contacts',
    connection: destinationAPIConnections[3],
    category: 'contacts',
  },
  {
    key: 'integration_key_13',
    pointer: 'contacts',
    status: false,
    query: 'upsert',
    name: 'Facebook Ads contacts',
    connection: destinationAPIConnections[4],
    category: 'contacts',
  },
];
