// Seems like it is going to be easier to rewrite SQL-HINT
// import Fuse from 'fuse.js'

// Constants
// -----------------------------------

const COLUMNS_TABLE_COLLECTION = '_____@@@@@@@_____';
const PATH_FUNC = 'ref';

// Autocomplete configuration builder
// -----------------------------------

const SAFE_PATTERN = /^[_a-zA-Z][_0-9a-zA-Z]*$/;
const testSafety = SAFE_PATTERN.test.bind(SAFE_PATTERN);

const isSafe = (...names:string[]) => names.every(testSafety);

const combineNames = (names:string[], sep = '.') => names.join(sep);
const sanitizeNames = (names:string[] )=> names.map(name => `'${name}'`);

const withBraces = (name:string) => `{{${name}}}`;

const formatSafeName = (...names:string[]) => withBraces(combineNames(names));
const formatRefName = (...names:string[]) => withBraces(`${PATH_FUNC}(${combineNames(sanitizeNames(names), ',')})`);

export const sqlHintDefinitionFromTables = (tables: { [key:string]:string[] }) => {
  const tableNames = [];
  const refTableNames = [];
  const columnNames = [];
  const refColumnNames = [];
  const rawNames = [];

  for(const tableName of Object.keys(tables)) {
    if(isSafe(tableName)) tableNames.push(formatSafeName(tableName));

    refTableNames.push(formatRefName(tableName));

    rawNames.push(tableName);

    for(const columnName of tables[tableName]) {
      if(isSafe(tableName, columnName)) columnNames.push(formatSafeName(tableName, columnName));

      refColumnNames.push(formatRefName(tableName, columnName));

      rawNames.push(columnName);
    }
  }

  // Ordering: Ref names after regular names

  const hintTables = [
    ...tableNames,
    ...refTableNames,
  ];

  const hintColumns = [
    ...columnNames,
    ...refColumnNames,
  ];

  const hintTablesMap = hintTables.reduce((names, table) => ({...names, [table]: []}), {});

  return {
    tables: {
      ...hintTablesMap,
      [COLUMNS_TABLE_COLLECTION]: hintColumns,
    },
    defaultTable: COLUMNS_TABLE_COLLECTION,
    full: [...hintTables, ...hintColumns],
    raw: rawNames,
  };
};

// Autocomplete helper extension
// -----------------------------------

const excludeSpecialTable = ({text}: { text:string }) => text !== COLUMNS_TABLE_COLLECTION;

const isSpecial = (str:string) => str[0] === '{' && str[str.length - 1] === '}';
const isRef = (str:string) => str.match(PATH_FUNC);

const sortSpecials = (str1:string, str2:string) => {
  const isRef1 = isRef(str1)
  const isRef2 = isRef(str2);

  // References go after regular special suggestions
  if(isRef1 && !isRef2) return 1;
  if(!isRef1 && isRef2) return -1;

  // Retain the same order (Tables and Columns)
  return 1;
};

const sortWithQuotesBeingLast = (s1:any, s2:any) => {
  const str1 = String(s1.text)
  const str2 = String(s2.text);
  const isSpecial1 = isSpecial(str1)
  const isSpecial2 = isSpecial(str2);

  // If both are special
  if(isSpecial1 && isSpecial2) return sortSpecials(str1, str2);

  // If the first suggestion is special, the it should go after the second suggestion
  if(isSpecial1) return 1;

  // If the second suggestion is special, the it should go after the first suggestion
  if(isSpecial2) return -1;

  // Otherwise, sort alphabetically
  return str1.localeCompare(str2);
};

// IMPORTANT: At least one curly bracket must be present
//  - CodeMirror can autocomplete on its own without it
const NAME_PATTERN = /^{{?/;

export const ModifiedSQLHint = (originalSQLHint:any) => (editor:any, params:any) => {
  const result = originalSQLHint(editor, params);

  // Note: Just a prototype - Does NOT support changing names everywhere
  const terms = editor.getValue(' ').split(' ');
  const currentTerm = terms[terms.length - 1];

  // Fuzzy Search - Requires replacing (Otherwise autocomplete is not going to have ANY effect)
  // Note: Should NOT be rebuild on every iteration -> Instantiate and index names only ONCE in the custom hinter
  // const fuse = new Fuse(params.raw, {
  //   isCaseSensitive: false,
  //   includeScore: false,
  //   shouldSort: true,
  //   includeMatches: false,
  //   findAllMatches: false,
  //   minMatchCharLength: 2,
  // });
  // if(NAME_PATTERN.test(currentTerm)) {
  //   const rawTerm = currentTerm.replace(NAME_PATTERN, '');
  //
  //   if(rawTerm.length === 0) {
  //     const nameHints = params.raw.map(name => name.slice(rawTerm.length) + '}}');
  //
  //     return {
  //       ...result,
  //       list: nameHints,
  //     };
  //   }
  //
  //   console.log(rawTerm);
  //
  //   const matches = fuse.search(rawTerm);
  //
  //   console.log(matches);
  //
  //   const nameHints = matches
  //       .map(match => match.item)
  //       .map(name => name.slice(rawTerm.length) + '}}');
  //
  //   console.log(nameHints);
  //
  //   return {
  //     ...result,
  //     list: nameHints,
  //   };
  // }

  if(NAME_PATTERN.test(currentTerm)) {
    const nameHints = params.full
      .filter((name:string) => name.startsWith(currentTerm))
      .map((name:string) => name.replace(NAME_PATTERN, ''));

    return {
      ...result,
      list: nameHints,
    };
  }

  const newHintsList = result.list
    // Filtering: Excluding the special table from users
    .filter(excludeSpecialTable)
    // Ordering: Syntax (Alphabetical) -> Columns -> Tables -> Columns with Refs -> Tables with Refs (Ordinal Order)
    .sort(sortWithQuotesBeingLast);

  return {
    ...result,
    list: newHintsList,
  };
};
