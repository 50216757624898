import { takeLatest, call, put } from 'redux-saga/effects';
// api
import * as API from './api';
import * as ObjectsAPI from '@features/objects/ducks/api';
import * as SourcesAPI from '@features/sources/ducks/api';
// connectorsSlice
import segmentsSlice from '@features/segments/ducks/segmentsSlice';
// types
import * as types from './types';

import { Combination, Segment } from '../types';
import { ApiGetObjectPayload } from '@features/objects/ducks/types';
import objectsSlice from '@features/objects/ducks/objectsSlice';
import { ApiCombinedSourcePayload } from '@features/sources/ducks/types';
import sourcesSlice from '@features/sources/ducks/sourcesSlice';
import flowsSlice from '@features/flows/ducks/flowsSlice';
import generateSegmentFlow from '@features/flows/generators/generateSegmentFlow';
function* getSegmentsSaga() {
  try {
    const getSegmentPayload: types.ApiSegmentsPayload = yield call(API.getSegments);
    yield put({ type: segmentsSlice.actions.getSegmentsSuccess.type, payload: getSegmentPayload });
  } catch (error) {
    yield put({ type: segmentsSlice.actions.getSegmentsFailed.type, payload: error });
  }
}
function* saveSegmentSaga({ payload }: types.SaveSegmentSaga) {
  try {
    const { segment } = payload;
    const newSegmentValues: Segment = { ...segment };
    const saveSegmentPayload: types.ApiSegmentPayload = yield call(
      API.saveSegment,
      newSegmentValues
    );
    if (segment.targetObject) {
      const saveObjectPayload: ApiGetObjectPayload = yield call(
        ObjectsAPI.saveObject,
        segment.targetObject
      );
      yield put({
        type: objectsSlice.actions.saveObjectSuccess.type,
        payload: saveObjectPayload,
      });
    }
    if (segment.combinations[0].object) {
      const sortedCombinationsByLevels = segment.combinations.sort(
        (a, b) => a.combinationLevel - b.combinationLevel
      );
      const combination = sortedCombinationsByLevels[segment.combinations.length - 1];
      const saveCombinedSourcePayload: ApiCombinedSourcePayload = yield call(
        SourcesAPI.saveCombinedSource,
        combination,
        newSegmentValues
      );
      yield put({
        type: sourcesSlice.actions.saveCombinedSourceSuccess.type,
        payload: saveCombinedSourcePayload,
      });
    }
    if (saveSegmentPayload.segment) {
      yield put({
        type: flowsSlice.actions.saveSegmentFlowSuccess.type,
        payload: { segmentFlow: generateSegmentFlow(saveSegmentPayload.segment) },
      });
    }
    yield put({
      type: segmentsSlice.actions.saveSegmentSuccess.type,
      payload: saveSegmentPayload,
    });
  } catch (error) {
    yield put({ type: segmentsSlice.actions.saveSegmentFailed.type, payload: error });
  }
}

function* runSegmentTargetObjectSaga({ payload }: types.RunSegmentTargetObject) {
  try {
    const { activeSegment } = payload;
    const updatedSegmentPayload: types.ApiSegmentPayload = yield call(
      API.runSegmentTargetObject,
      activeSegment
    );
    yield put({
      type: segmentsSlice.actions.runSegmentTargetObjectSuccess.type,
      payload: { activeSegment: updatedSegmentPayload.segment },
    });
  } catch (error) {
    yield put({
      type: segmentsSlice.actions.runSegmentTargetObjectFailed.type,
      payload: error,
    });
  }
}

function* checkColumnsMatchSaga({ payload }: types.CheckColumnsMatchSaga) {
  try {
    const { match } = payload;
    const checkColumnsMatchPayload: types.ApiCheckColumnsMatchPayload = yield call(
      API.checkColumnsMatch,
      match
    );
    yield put({
      type: segmentsSlice.actions.checkColumnsMatchSuccess.type,
      payload: checkColumnsMatchPayload,
    });
  } catch (error) {
    yield put({ type: segmentsSlice.actions.checkColumnsMatchFailed.type, payload: error });
  }
}

function* updateActiveSegmentCombinationObjectSaga({
  payload,
}: types.SaveCombinedSourceObjectSaga) {
  try {
    const { combination, connection, pointer } = payload;
    const getRecordsObjectPayload: ApiGetObjectPayload = yield call(
      ObjectsAPI.getRecordsObject,
      connection,
      pointer
    );
    const combinationNewValues: Combination = {
      ...combination,
      connection,
      pointer,
      object: getRecordsObjectPayload.object,
    };
    yield put({
      type: segmentsSlice.actions.updateActiveSegmentCombinationObjectSuccess.type,
      payload: { combination: combinationNewValues },
    });
  } catch (error) {
    yield put({
      type: segmentsSlice.actions.updateActiveSegmentCombinationObjectFailed.type,
      payload: error,
    });
  }
}
// Attach Sagas to Redux Action
export const segmentsSagas = [
  takeLatest(segmentsSlice.actions.getSegments.type, getSegmentsSaga),
  takeLatest(segmentsSlice.actions.saveSegment.type, saveSegmentSaga),
  takeLatest(segmentsSlice.actions.runSegmentTargetObject.type, runSegmentTargetObjectSaga),
  takeLatest(
    segmentsSlice.actions.updateActiveSegmentCombinationObject.type,
    updateActiveSegmentCombinationObjectSaga
  ),
  takeLatest(segmentsSlice.actions.checkColumnsMatch.type, checkColumnsMatchSaga),
];
