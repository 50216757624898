import { createSlice } from '@reduxjs/toolkit';
import type {
  CategoryReducerActions,
  ObjectCategoriesReducerActions,
  ObjectReducerActions,
  ObjectsReducerActions,
  ObjectRecordStatsReducerActions,
  SchemaCategoriesReducerActions,
  SchemaCategoryReducerActions,
  SchemaPointersReducerActions,
  BusinessCategoriesReducerActions,
  GetBusinessCategoryReducerActions,
  BusinessCategoryReducerActions,
} from './types';
import { BusinessCategory, OctoObject, OctoObjectCategory } from '@features/objects/types';
import { OctoStats } from '@features/objects/statistics/types';

import { updateOrAddElementInArray } from '@utils/helpers';
export const INITIAL_BUSINESS_CATEGORY_STATE = {
  loaded: false,
  isFetching: false,
  businessCategories: [],
};
export const INITIAL_CATEGORY_STATE = {
  loaded: false,
  isFetching: false,
  categories: [],
};
export const INITIAL_SCHEMA_CATEGORY_STATE = {
  loaded: false,
  isFetching: false,
  schemaCategories: [],
};
export const INITIAL_SCHEMA_POINTERS_STATE = {
  loaded: false,
  isFetching: false,
  schemaPointers: [],
};
export const INITIAL_STATS_STATE = {
  loaded: false,
  isFetching: false,
  ObjectRecordStats: [],
};

export interface BusinessCategoryState {
  loaded: boolean;
  isFetching?: boolean;
  error?: boolean;
  errorDetails?: string | undefined;
  businessCategories: BusinessCategory[];
  businessCategory?: BusinessCategory;
}
export interface ObjectCategoryState {
  loaded: boolean;
  isFetching?: boolean;
  error?: boolean;
  errorDetails?: string | undefined;
  categories: OctoObjectCategory[];
  category?: OctoObjectCategory;
}
export interface ObjectSchemaCategoryState {
  loaded: boolean;
  isFetching?: boolean;
  error?: boolean;
  errorDetails?: string | undefined;
  schemaCategories: OctoObjectCategory[];
  schemaCategory?: OctoObjectCategory;
}
export interface ObjectSchemaPointersState {
  loaded: boolean;
  isFetching?: boolean;
  error?: boolean;
  errorDetails?: string | undefined;
  schemaPointers: OctoStats[];
}
export interface ObjectRecordStatsState {
  loaded: boolean;
  isFetching?: boolean;
  error?: boolean;
  errorDetails?: string | undefined;
  ObjectRecordStats: OctoStats[];
  ObjectRecordStat?: OctoStats;
}

export interface ObjectsState {
  loaded: boolean;
  isFetching?: boolean;
  shouldBeClosed?: boolean;
  error?: boolean;
  errorDetails?: string | undefined;
  page?: number;
  limit?: number;
  total?: number;
  objects: OctoObject[];
  object?: OctoObject;
  activeObject?: OctoObject;
  businessCategoryState: BusinessCategoryState;
  categoryState: ObjectCategoryState;
  schemaCategoryState: ObjectSchemaCategoryState;
  schemaPointersState: ObjectSchemaPointersState;
  ObjectRecordStatsState: ObjectRecordStatsState;
}
export const INITIAL_Object_STATE = {
  loaded: false,
  isFetching: false,
  objects: [],
  businessCategoryState: INITIAL_BUSINESS_CATEGORY_STATE,
  categoryState: INITIAL_CATEGORY_STATE,
  schemaCategoryState: INITIAL_SCHEMA_CATEGORY_STATE,
  schemaPointersState: INITIAL_SCHEMA_POINTERS_STATE,
  ObjectRecordStatsState: INITIAL_STATS_STATE,
} as ObjectsState;
const objectsSlice = createSlice({
  name: 'objects',
  initialState: INITIAL_Object_STATE,
  reducers: {
    getObjects: (state) => {
      state.isFetching = true;
      state.objects = [];
    },

    getObjectsSuccess: (state, { payload }: ObjectsReducerActions) => {
      state.loaded = true;
      state.isFetching = false;
      state.objects = payload.objects;
    },

    getObjectsFailed: (state, { payload }: ObjectsReducerActions) => {
      state.isFetching = false;
      state.error = true;
      state.errorDetails = payload.errorDetails;
    },

    getObject: (state, payload) => {
      state.isFetching = true;
    },
    getObjectSuccess: (state, { payload }: ObjectReducerActions) => {
      state.loaded = true;
      state.isFetching = false;
      state.object = payload.object;
    },
    getObjectFailed: (state, { payload }: ObjectReducerActions) => {
      state.isFetching = false;
      state.error = true;
      state.errorDetails = payload.errorDetails;
    },
    getRecordsObject: (state, payload) => {
      state.isFetching = true;
    },
    getRecordsObjectSuccess: (state, { payload }: ObjectReducerActions) => {
      state.loaded = true;
      state.isFetching = false;
      state.object = payload.object;
    },
    getRecordsObjectFailed: (state, { payload }: ObjectReducerActions) => {
      state.isFetching = false;
      state.error = true;
      state.errorDetails = payload.errorDetails;
    },
    saveSourceObject: (state) => {
      state.isFetching = true;
      state.shouldBeClosed = false;
    },
    saveSourceTargetObject: (state) => {
      state.isFetching = true;
      state.shouldBeClosed = false;
    },
    saveObject: (state) => {
      state.isFetching = true;
      state.shouldBeClosed = false;
    },
    saveObjectSuccess: (state, { payload }: ObjectReducerActions) => {
      state.loaded = true;
      state.isFetching = false;
      state.objects = updateOrAddElementInArray(payload.object, state.objects);
      state.shouldBeClosed = true;
    },
    saveObjectFailed: (state, { payload }: ObjectReducerActions) => {
      state.isFetching = false;
      state.error = true;
      state.errorDetails = payload.errorDetails;
    },
    deleteObject: (state) => {
      state.isFetching = true;
      state.shouldBeClosed = false;
    },
    deleteObjectSuccess: (state, { payload }: ObjectReducerActions) => {
      state.loaded = true;
      state.isFetching = false;
      state.shouldBeClosed = true;
      state.objects = state.objects?.filter((a) => a.key !== payload.object?.key);
    },
    deleteObjectFailed: (state, { payload }: ObjectReducerActions) => {
      state.isFetching = false;
      state.error = true;
      state.errorDetails = payload.errorDetails;
    },
    setActiveObject: (state, { payload }: ObjectReducerActions) => {
      state.activeObject = payload.object;
    },
    // Business Category reducers NEW
    getBusinessCategories: (state) => {
      state.businessCategoryState.isFetching = true;
      state.businessCategoryState.businessCategories = [];
    },
    getBusinessCategoriesSuccess: (state, { payload }: BusinessCategoriesReducerActions) => {
      state.businessCategoryState.loaded = true;
      state.businessCategoryState.isFetching = false;
      state.businessCategoryState.businessCategories = payload.businessCategories;
    },
    getBusinessCategoriesFailed: (state, { payload }: BusinessCategoriesReducerActions) => {
      state.businessCategoryState.isFetching = false;
      state.businessCategoryState.error = true;
      state.businessCategoryState.errorDetails = payload.errorDetails;
    },

    getBusinessCategory: (state) => {
      state.businessCategoryState.isFetching = true;
    },
    getBusinessCategorySuccess: (state, { payload }: GetBusinessCategoryReducerActions) => {
      state.businessCategoryState.loaded = true;
      state.businessCategoryState.isFetching = false;
      state.businessCategoryState.businessCategory = state.businessCategoryState.businessCategories.find(
        (category) => category.key === payload.businessCategoryKey
      );
    },
    getBusinessCategoryFailed: (state, { payload }: GetBusinessCategoryReducerActions) => {
      state.businessCategoryState.isFetching = false;
      state.businessCategoryState.error = true;
      state.businessCategoryState.errorDetails = payload.errorDetails;
    },

    saveBusinessCategory: (state) => {
      state.businessCategoryState.isFetching = true;
    },
    saveAndAssignBusinessCategory: (state) => {
      state.businessCategoryState.isFetching = true;
    },
    saveAndAssignBusinessTerm: (state) => {
      state.businessCategoryState.isFetching = true;
    },
    saveBusinessCategorySuccess: (state, { payload }: BusinessCategoryReducerActions) => {
      state.businessCategoryState.loaded = true;
      state.businessCategoryState.isFetching = false;
      state.businessCategoryState.businessCategories = updateOrAddElementInArray(
        payload.businessCategory,
        state.businessCategoryState.businessCategories
      );
    },
    saveBusinessCategoryFailed: (state, { payload }: BusinessCategoryReducerActions) => {
      state.businessCategoryState.isFetching = false;
      state.businessCategoryState.error = true;
      state.businessCategoryState.errorDetails = payload.errorDetails;
    },

    deleteBusinessCategory: (state) => {
      state.businessCategoryState.isFetching = true;
    },
    deleteBusinessCategorySuccess: (state, { payload }: BusinessCategoryReducerActions) => {
      state.businessCategoryState.loaded = true;
      state.businessCategoryState.isFetching = false;
      state.businessCategoryState.businessCategories = state.businessCategoryState.businessCategories.filter(
        (a) => a.key !== payload.businessCategory.key
      );
    },
    deleteBusinessCategoryFailed: (state, { payload }: BusinessCategoryReducerActions) => {
      state.businessCategoryState.isFetching = false;
      state.businessCategoryState.error = true;
      state.businessCategoryState.errorDetails = payload.errorDetails;
    },
    // Category reducers OLD
    getCategories: (state) => {
      state.categoryState.isFetching = true;
      state.categoryState.categories = [];
    },

    getCategoriesSuccess: (state, { payload }: ObjectCategoriesReducerActions) => {
      state.categoryState.loaded = true;
      state.categoryState.isFetching = false;
      state.categoryState.categories = payload.categories;
    },

    getCategoriesFailed: (state, { payload }: ObjectCategoriesReducerActions) => {
      state.categoryState.isFetching = false;
      state.categoryState.error = true;
      state.categoryState.errorDetails = payload.errorDetails;
    },

    getCategory: (state, payload) => {
      state.categoryState.isFetching = true;
    },
    getCategorySuccess: (state, { payload }: CategoryReducerActions) => {
      state.categoryState.loaded = true;
      state.categoryState.isFetching = false;
      state.categoryState.category = payload.category;
    },
    getCategoryFailed: (state, { payload }: CategoryReducerActions) => {
      state.categoryState.isFetching = false;
      state.categoryState.error = true;
      state.categoryState.errorDetails = payload.errorDetails;
    },

    saveCategory: (state) => {
      state.categoryState.isFetching = true;
    },
    saveCategorySuccess: (state, { payload }: CategoryReducerActions) => {
      state.categoryState.loaded = true;
      state.categoryState.isFetching = false;
      state.categoryState.categories = updateOrAddElementInArray(
        payload.category,
        state.categoryState.categories
      );
    },
    saveCategoryFailed: (state, { payload }: CategoryReducerActions) => {
      state.categoryState.isFetching = false;
      state.categoryState.error = true;
      state.categoryState.errorDetails = payload.errorDetails;
    },

    deleteCategory: (state) => {
      state.categoryState.isFetching = true;
    },
    deleteCategorySuccess: (state, { payload }: CategoryReducerActions) => {
      state.categoryState.loaded = true;
      state.categoryState.isFetching = false;

      state.categoryState.categories = state.categoryState.categories?.filter(
        (a) => a.key !== payload.category?.key
      );
    },
    deleteCategoryFailed: (state, { payload }: CategoryReducerActions) => {
      state.categoryState.isFetching = false;
      state.categoryState.error = true;
      state.categoryState.errorDetails = payload.errorDetails;
    },
    // Schema pointers reducers
    getSchemaPointers: (state) => {
      state.schemaPointersState.isFetching = true;
      state.schemaPointersState.schemaPointers = [];
    },

    getSchemaPointersSuccess: (state, { payload }: SchemaPointersReducerActions) => {
      state.schemaPointersState.loaded = true;
      state.schemaPointersState.isFetching = false;
      state.schemaPointersState.schemaPointers = payload.schemaPointers;
    },

    getSchemaPointersFailed: (state, { payload }: SchemaPointersReducerActions) => {
      state.schemaPointersState.isFetching = false;
      state.schemaPointersState.error = true;
      state.schemaPointersState.errorDetails = payload.errorDetails;
    },
    // Schema category reducers
    getSchemaCategories: (state) => {
      state.schemaCategoryState.isFetching = true;
      state.schemaCategoryState.schemaCategories = [];
    },

    getSchemaCategoriesSuccess: (state, { payload }: SchemaCategoriesReducerActions) => {
      state.schemaCategoryState.loaded = true;
      state.schemaCategoryState.isFetching = false;
      state.schemaCategoryState.schemaCategories = payload.schemaCategories;
    },

    getSchemaCategoriesFailed: (state, { payload }: SchemaCategoriesReducerActions) => {
      state.schemaCategoryState.isFetching = false;
      state.schemaCategoryState.error = true;
      state.schemaCategoryState.errorDetails = payload.errorDetails;
    },

    getSchemaCategory: (state, payload) => {
      state.categoryState.isFetching = true;
    },
    getSchemaCategorySuccess: (state, { payload }: SchemaCategoryReducerActions) => {
      state.schemaCategoryState.loaded = true;
      state.schemaCategoryState.isFetching = false;
      state.schemaCategoryState.schemaCategory = payload.schemaCategory;
    },
    getSchemaCategoryFailed: (state, { payload }: SchemaCategoryReducerActions) => {
      state.schemaCategoryState.isFetching = false;
      state.schemaCategoryState.error = true;
      state.schemaCategoryState.errorDetails = payload.errorDetails;
    },

    saveSchemaCategory: (state) => {
      state.categoryState.isFetching = true;
    },
    saveSchemaCategorySuccess: (state, { payload }: SchemaCategoryReducerActions) => {
      state.schemaCategoryState.loaded = true;
      state.schemaCategoryState.isFetching = false;
      state.schemaCategoryState.schemaCategories = updateOrAddElementInArray(
        payload.schemaCategory,
        state.schemaCategoryState.schemaCategories
      );
    },
    saveSchemaCategoryFailed: (state, { payload }: SchemaCategoryReducerActions) => {
      state.schemaCategoryState.isFetching = false;
      state.schemaCategoryState.error = true;
      state.schemaCategoryState.errorDetails = payload.errorDetails;
    },

    deleteSchemaCategory: (state) => {
      state.schemaCategoryState.isFetching = true;
    },
    deleteSchemaCategorySuccess: (state, { payload }: SchemaCategoryReducerActions) => {
      state.schemaCategoryState.loaded = true;
      state.schemaCategoryState.isFetching = false;

      state.schemaCategoryState.schemaCategories = state.schemaCategoryState.schemaCategories?.filter(
        (a) => a.key !== payload.schemaCategory?.key
      );
    },
    deleteSchemaCategoryFailed: (state, { payload }: SchemaCategoryReducerActions) => {
      state.schemaCategoryState.isFetching = false;
      state.schemaCategoryState.error = true;
      state.schemaCategoryState.errorDetails = payload.errorDetails;
    },

    // Objects Statistics Details Reducers
    getObjectRecordStats: (state, payload) => {
      state.ObjectRecordStatsState.isFetching = true;
    },
    getObjectRecordStatsSuccess: (state, { payload }: ObjectRecordStatsReducerActions) => {
      state.ObjectRecordStatsState.loaded = true;
      state.ObjectRecordStatsState.isFetching = false;
      state.ObjectRecordStatsState.ObjectRecordStats = payload.ObjectRecordStats;
    },
    getObjectRecordStatsFailed: (state, { payload }: ObjectRecordStatsReducerActions) => {
      state.ObjectRecordStatsState.isFetching = false;
      state.ObjectRecordStatsState.error = true;
      state.ObjectRecordStatsState.errorDetails = payload.errorDetails;
    },

    getObjectRecordStatByColumn: (state, payload) => {
      state.ObjectRecordStatsState.isFetching = true;
    },
    getObjectRecordStatByColumnSuccess: (state, { payload }: ObjectRecordStatsReducerActions) => {
      state.ObjectRecordStatsState.loaded = true;
      state.ObjectRecordStatsState.isFetching = false;
      state.ObjectRecordStatsState.ObjectRecordStat = payload.ObjectRecordStat;
    },
    getObjectRecordStatByColumnFailed: (state, { payload }: ObjectRecordStatsReducerActions) => {
      state.ObjectRecordStatsState.isFetching = false;
      state.ObjectRecordStatsState.error = true;
      state.ObjectRecordStatsState.errorDetails = payload.errorDetails;
    },
  },
});
export default objectsSlice;
