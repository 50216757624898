import * as types from './types';
import * as API from './api';
import { takeLatest, call, put } from 'redux-saga/effects';
import filtersSlice from './filterSlice';
function* getFilterByObjectSaga({ payload }: types.GetFilterByObjectSaga) {
  try {
    const { objectId } = payload;
    const getFilterPayload: types.ApiGetFilterPayload = yield call(API.getFilterByObject, objectId);
    yield put({
      type: filtersSlice.actions.getFilterByObjectSuccess.type,
      payload: getFilterPayload,
    });
  } catch (error) {
    yield put({ type: filtersSlice.actions.getFilterByObjectFailed.type, payload: error });
  }
}
function* saveFilterSaga({ payload }: types.SaveFilterSaga) {
  try {
    const { filter } = payload;
    const saveFilterConditionPayload: types.ApiFilterConditionPayload = yield call(
      API.saveFilter,
      filter
    );
    yield put({
      type: filtersSlice.actions.saveFilterSuccess.type,
      payload: saveFilterConditionPayload,
    });
  } catch (error) {
    yield put({ type: filtersSlice.actions.saveFilterFailed.type, payload: error });
  }
}
function* saveFilterCondition({ payload }: types.SaveFilterConditionSaga) {
  try {
    const { condition, groupId } = payload;
    const saveFilterConditionPayload: types.ApiFilterConditionPayload = yield call(
      API.saveFilterCondition,
      condition,
      groupId
    );
    yield put({
      type: filtersSlice.actions.saveFilterConditionSuccess.type,
      payload: saveFilterConditionPayload,
    });
  } catch (error) {
    yield put({ type: filtersSlice.actions.saveFilterConditionFailed.type, payload: error });
  }
}
function* removeFilterCondition({ payload }: types.RemoveFilterConditionSaga) {
  try {
    const { condition, groupId } = payload;
    const removeFilterConditionPayload: types.ApiFilterConditionPayload = yield call(
      API.removeFilterCondition,
      condition,
      groupId
    );
    yield put({
      type: filtersSlice.actions.removeFilterConditionSuccess.type,
      payload: removeFilterConditionPayload,
    });
  } catch (error) {
    yield put({ type: filtersSlice.actions.removeFilterConditionFailed.type, payload: error });
  }
}
function* updateCombinationOperator({ payload }: types.UpdateCombinationOperatorSaga) {
  try {
    const { operatorKey, groupId } = payload;
    const updateCombinationOperatorPayload: types.ApiFilterCombinationOperatorPayload = yield call(
      API.updateCombinationOperator,
      operatorKey,
      groupId
    );
    yield put({
      type: filtersSlice.actions.updateCombinationOperatorSuccess.type,
      payload: updateCombinationOperatorPayload,
    });
  } catch (error) {
    yield put({ type: filtersSlice.actions.updateCombinationOperatorFailed.type, payload: error });
  }
}
function* getQuickFiltersByObject({ payload }: types.GetQuickFiltersByObjectSaga) {
  try {
    const { objectId } = payload;
    const getQuickFiltersPayload: types.ApiGetQuickFiltersPayload = yield call(
      API.getQuickFiltersByObject,
      objectId
    );
    yield put({
      type: filtersSlice.actions.getQuickFiltersByObjectSuccess.type,
      payload: getQuickFiltersPayload,
    });
  } catch (error) {
    yield put({ type: filtersSlice.actions.getQuickFiltersByObjectFailed.type, payload: error });
  }
}
function* saveQuickFilter({ payload }: types.SaveQuickFitlerSaga) {
  try {
    const { quickFilter } = payload;
    const saveQuickFilter: types.ApiGetQuickFilterPayload = yield call(
      API.saveQuickFilter,
      quickFilter
    );
    yield put({
      type: filtersSlice.actions.saveQuickFilterSuccess.type,
      payload: saveQuickFilter,
    });
  } catch (error) {
    yield put({
      type: filtersSlice.actions.saveQuickFilterFailed.type,
      payload: error,
    });
  }
}
function* removeQuickFilter({ payload }: types.RemoveQuickFiltersSaga) {
  try {
    const { condition, objectKey } = payload;
    const removeQuickFilterPayload: types.ApiGetQuickFilterPayload = yield call(
      API.removeQuickFilter,
      condition,
      objectKey
    );
    yield put({
      type: filtersSlice.actions.removeQuickFilterSuccess.type,
      payload: removeQuickFilterPayload,
    });
  } catch (error) {
    yield put({ type: filtersSlice.actions.removeQuickFilterFailed.type, payload: error });
  }
}
export const filtersSagas = [
  takeLatest(filtersSlice.actions.getFilterByObject.type, getFilterByObjectSaga),
  takeLatest(filtersSlice.actions.saveFilter.type, saveFilterSaga),
  takeLatest(filtersSlice.actions.saveFilterCondition.type, saveFilterCondition),
  takeLatest(filtersSlice.actions.removeFilterCondition.type, removeFilterCondition),
  takeLatest(filtersSlice.actions.updateCombinationOperator.type, updateCombinationOperator),
  takeLatest(filtersSlice.actions.getQuickFiltersByObject.type, getQuickFiltersByObject),
  takeLatest(filtersSlice.actions.saveQuickFilter.type, saveQuickFilter),
  takeLatest(filtersSlice.actions.removeQuickFilter.type, removeQuickFilter),
];
