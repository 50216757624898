import { createSlice } from '@reduxjs/toolkit';
import type {
  ReducerCombinedSourceActions,
  ReducerSourceActions,
  ReducerSourcesActions,
  SourceConnectionReducerActions,
  SourceObjectReducerActions,
  SourceRecipeReducerActions,
} from './types';
import { CombinedSource, Source } from '@features/sources/types';
import { updateOrAddElementInArray } from '@utils/helpers';
import { OctoObject } from '@features/objects/types';
import { Recipe } from '@features/recipes/types';

// Initial source and state definition
export const INITIAL_SOURCE_STATE = {
  loaded: false,
  isFetching: false,
  activeSource: {},
} as SourcesState;
export interface SourcesState {
  loaded: boolean;
  isFetching?: boolean;
  shouldBeClosed?: boolean;
  error?: boolean;
  errorDetails?: string | undefined;
  page?: number;
  limit?: number;
  total?: number;
  source?: Source;
  activeSource: Source;
  sources: Source[];
  combinedSources: CombinedSource[];
  activeSourceObject: OctoObject;
  activeSourceRecipe: Recipe;
}
const sourcesSlice = createSlice({
  // Key name that gets added to combineReducers
  name: 'sources',
  // Reducers object is the bread and butter of saga slice.
  // Defining a reducer also defines a type and action.
  // The type will be `source/fetchGet`, using the pattern of `{name}/{key}`
  // @@@Amine so no need to have an actions an reducers files

  initialState: INITIAL_SOURCE_STATE,
  reducers: {
    // Actions for fetching the Sources from the API and storing them in the sources state
    getSources: (state) => {
      state.isFetching = true;
      state.sources = [];
    },
    getSourcesSuccess: (state, { payload }: ReducerSourcesActions) => {
      state.loaded = true;
      state.isFetching = false;
      state.sources = payload.sources;
    },
    getSourcesFailed: (state, { payload }: ReducerSourcesActions) => {
      state.isFetching = false;
      state.error = true;
      state.errorDetails = payload.errorDetails;
    },

    // Actions for fetching a single source from the API and storing it in the sources state
    getSource: (state, payload) => {
      state.isFetching = true;
    },
    getSourceSuccess: (state, { payload }: ReducerSourceActions) => {
      state.loaded = true;
      state.isFetching = false;
      state.source = payload.source;
    },
    getSourceFailed: (state, { payload }: ReducerSourcesActions) => {
      state.isFetching = false;
      state.error = true;
      state.errorDetails = payload.errorDetails;
    },

    // Actions for saving source to the state
    saveSource: (state) => {
      state.isFetching = true;
      state.shouldBeClosed = false;
    },
    saveSourceSuccess: (state, { payload }: any) => {
      state.loaded = true;
      state.isFetching = false;
      state.sources = updateOrAddElementInArray(payload.source, state.sources);
      state.shouldBeClosed = true;
    },
    saveSourceFailed: (state, { payload }: ReducerSourceActions) => {
      state.isFetching = false;
      state.error = true;
      state.errorDetails = payload.errorDetails;
    },
    // Actions for saving combined source to the state
    saveCombinedSource: (state) => {
      state.isFetching = true;
      state.shouldBeClosed = false;
    },
    saveCombinedSourceSuccess: (state, { payload }: ReducerCombinedSourceActions) => {
      state.loaded = true;
      state.isFetching = false;
      state.combinedSources = updateOrAddElementInArray(
        payload.combinedSource,
        state.combinedSources || []
      );
      state.shouldBeClosed = true;
    },
    saveCombinedSourceFailed: (state, { payload }: ReducerCombinedSourceActions) => {
      state.isFetching = false;
      state.error = true;
      state.errorDetails = payload.errorDetails;
    },

    // Actions for deleting source to the state
    deleteSource: (state) => {
      state.isFetching = true;
      state.shouldBeClosed = false;
    },
    deleteSourceSuccess: (state, { payload }: ReducerSourceActions) => {
      state.loaded = true;
      state.isFetching = false;
      state.shouldBeClosed = true;
      state.sources = state.sources?.filter((a) => a.key !== payload.source?.key);
    },
    deleteSourceFailed: (state, { payload }: ReducerSourceActions) => {
      state.isFetching = false;
      state.error = true;
      state.errorDetails = payload.errorDetails;
    },

    // Action for setting Active Source
    setActiveEditedSource: (state, { payload }: ReducerSourceActions) => {
      state.activeSource = payload.source;
    },
    cleanActiveSource: (state, { payload }: ReducerSourceActions) => {
      state.activeSource = {} as Source;
    },

    // Action for setting Source Object of the Form
    setActiveSourceObject: (state, { payload }: SourceObjectReducerActions) => {
      state.activeSource = {
        ...state.activeSource,
        sourceObject: payload.object,
      };
    },

    // Action for setting Target Object of the Form
    setActiveSourceTargetObject: (state, { payload }: SourceObjectReducerActions) => {
      state.activeSource = {
        ...state.activeSource,
        targetObject: payload.object,
      };
    },

    // Action for setting Source Recipe
    setActiveSourceRecipe: (state, { payload }: SourceRecipeReducerActions) => {
      state.activeSource = {
        ...state.activeSource,
        recipe: payload.recipe,
      };
    },
    // Action for setting Source Recipe
    updateActiveSource: (state, { payload }: ReducerSourceActions) => {
      state.activeSource = {
        ...state.activeSource,
        ...payload.source,
      };
    },

    // Action for setting Source Recipe
    setActiveSourceConnection: (state, { payload }: SourceConnectionReducerActions) => {
      state.activeSource = {
        ...state.activeSource,
        connection: payload.connection,
      };
    },
    runSourcesForAudience: (state) => {
      state.isFetching = true;
    },
    runSourcesForAudienceSuccess: (state, { payload }: ReducerSourceActions) => {
      state.loaded = true;
      state.isFetching = false;
    },
    runSourcesForAudienceFailed: (state, { payload }: ReducerSourceActions) => {
      state.isFetching = false;
      state.error = true;
      state.errorDetails = payload.errorDetails;
    },
    runSourceForSegment: (state) => {
      state.isFetching = true;
    },
    runSourceForSegmentSuccess: (state, { payload }: ReducerSourceActions) => {
      state.loaded = true;
      state.isFetching = false;
      state.sources = updateOrAddElementInArray(payload.source, state.sources);
    },
    runSourceForSegmentFailed: (state, { payload }: ReducerSourceActions) => {
      state.isFetching = false;
      state.error = true;
      state.errorDetails = payload.errorDetails;
    },
  },
});
export default sourcesSlice;
