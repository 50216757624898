import React, { useState } from 'react';
import { Button, Col, Collapse, List, Row } from 'antd';
import './style.less';

const { Panel } = Collapse;

export type ExplorerItem = {
  type: string;
  name: string | JSX.Element;
  items?: ExplorerItem[];
  action?: () => void;
};
const useOctoExplorer = () => {
  const [explorerItems, setExplorerItems] = useState<ExplorerItem[]>();
  const [name, setName] = useState<string>();

  return {
    explorerItems,
    name,
    putName: setName,
    putTreeStructure: (tree: ExplorerItem[]) => {
      setExplorerItems(tree);
    },
  };
};

interface OctoExplorerPros {
  name?: string;
  loading?: boolean;
  expanded?: boolean;
  explorerItems?: ExplorerItem[];
}

const OctoExplorer = ({ explorerItems, name, expanded, loading }: OctoExplorerPros) => {
  // @@@Amine list Item might have subFolders as panels
  const createListItems = (item: any, i: number) => {
    if (item.type === 'panel') {
      return (
        <Collapse ghost={true} defaultActiveKey={expanded ? 1 : -1}>
          <Panel
            key={1}
            header={
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                  width: '100%',
                  marginTop: '-5px',
                }}
              >
                <h4>{item.name}</h4>

                <Button
                  type="text"
                  onClick={(e) => {
                    item.action();
                    e.stopPropagation();
                  }}
                  style={{ marginLeft: 'auto', color: '#5297af' }}
                >
                  Insert
                </Button>
              </div>
            }
          >
            <List
              size="small"
              itemLayout="horizontal"
              dataSource={item.items}
              renderItem={(item, j) => createListItems(item, j)}
            />
          </Panel>
        </Collapse>
      );
    }

    return (
      <List.Item key={i}>
        <List.Item.Meta title={item.name} />

        <Button type="text" onClick={item.action} style={{ marginLeft: 'auto', color: '#5297af' }}>
          Insert
        </Button>
      </List.Item>
    );
  };

  return (
    <>
      <div style={{ height: 'auto' }}>
        <List
          size="small"
          loading={loading}
          itemLayout="horizontal"
          dataSource={explorerItems}
          renderItem={(item, i) => createListItems(item, i)}
        />
      </div>
    </>
  );
};
export { useOctoExplorer, OctoExplorer };
