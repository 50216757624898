import React from 'react';
import { Form, Switch } from 'antd';

interface OctoSwitchProps {
  name: string | string[];
  label?: string;
  checked?: boolean;
  disabled: boolean;
  onChange?: any;
  size?: 'small' | 'default' | undefined;
  className?: string;
}
const OctoSwitch = ({
  name,
  label,
  checked,
  disabled,
  onChange,
  size,
  className,
}: OctoSwitchProps) => {
  return (
    <Form.Item label={label} name={name} initialValue={checked}>
      <Switch
        onChange={(v) => {
          if (onChange) onChange(v);
        }}
        disabled={disabled}
        defaultChecked={checked}
        size={size}
        className={className}
      />
    </Form.Item>
  );
};

export default OctoSwitch;
