// Importing React
import React, { Suspense, useContext, useEffect, useState } from 'react';
// Importing Antd & styles
import { Layout, Space } from 'antd';
import './styles.less';
// Importing router
import { ConnectedRouter } from 'connected-react-router';
import { Switch } from 'react-router-dom';
// Importing redux
import { connect } from 'react-redux';
import { history } from '@redux/store';
// Importing child components & types
import PublicRoutes from '@routes/PublicRoutes';

// Importing functions
import { getHighlightedMenuItem } from '@utils/menu';
import { ReducerStates } from '@redux/reducers';
import { ThunkDispatch } from '@reduxjs/toolkit';
import { bindActionCreators } from 'redux';
import appContextSlice from '@features/appContext/ducks/appContextSlice';
import { AppContext } from '@providers/AppContextProvider';
import { AppLayoutProps } from '@layout/App/types';
import { extractObjectKeys } from '@utils/helpers';
import profilesSlice from '@features/profiles/ducks/profilesSlice';
import { ProfileContext } from '@providers/ProfileContextProvider';
import organizationsSlice from '@features/organizations/ducks/organizationsSlice';
import usersSlice from '@features/users/ducks/usersSlice';
import sourcesSlice from '@features/sources/ducks/sourcesSlice';
import connectionsSlice from '@features/connections/ducks/connectionsSlice';
import connectorsSlice from '@features/connectors/ducks/connectorsSlice';
import accountSlice from '@features/account/ducks/accountSlice';
import objectsSlice from '@features/objects/ducks/objectsSlice';
import recipesSlice from '@features/recipes/ducks/recipesSlice';
import flowsSlice from '@features/flows/ducks/flowsSlice';
import { getProfile } from '@features/profiles/ducks/api';
import MenuLeft from '@components/menuLeft';
import syncsSlice from '@features/syncs/ducks/syncsSlice';
import destinationsSlice from '@features/destinations/ducks/destinationsSlice';
import integrationsSlice from '@features/integrations/ducks/integrationsSlice';
import segmentsSlice from '@features/segments/ducks/segmentsSlice';
import audiencesSlice from '@features/audiences/ducks/audiencesSlice';

const { Content } = Layout;
const { Sider } = Layout;
const bigSideMenuWidth = '250px';
const smallSideMenuWidth = '80px';
function getRightsState(state: ReducerStates) {
  return state.profiles;
}

function getOrganizationState(state: ReducerStates) {
  return state.organizations;
}

function getAccountState(state: ReducerStates) {
  return state.account;
}

function getAppContextState(state: ReducerStates) {
  return state.appContext;
}

function getUsersState(state: ReducerStates) {
  return state.users;
}

const mapStateToProps = (state: ReducerStates) => {
  return {
    rightsState: getRightsState(state),
    organizationState: getOrganizationState(state),
    accountState: getAccountState(state),
    appContextState: getAppContextState(state),
    usersState: getUsersState(state),
  };
};

const mapDispatchToProps: (dispatch: ThunkDispatch<any, any, any>) => void = (dispatch) => {
  return {
    getOrganizations: bindActionCreators(organizationsSlice.actions.getOrganizations, dispatch),
    getOrganization: bindActionCreators(organizationsSlice.actions.getOrganization, dispatch),
    getAppContext: bindActionCreators(appContextSlice.actions.getAppContext, dispatch),
    getAccount: bindActionCreators(accountSlice.actions.getAccount, dispatch),
    setAppLanguage: bindActionCreators(appContextSlice.actions.setAppLanguage, dispatch),
    saveAppContext: bindActionCreators(appContextSlice.actions.saveAppContext, dispatch),
    setAppIconAndName: bindActionCreators(appContextSlice.actions.setAppIconAndName, dispatch),
    getDestinations: bindActionCreators(destinationsSlice.actions.getDestinations, dispatch),
    getProfile: bindActionCreators(profilesSlice.actions.getProfile, dispatch),
    getProfiles: bindActionCreators(profilesSlice.actions.getProfiles, dispatch),
    getUsers: bindActionCreators(usersSlice.actions.getUsers, dispatch),
    getUser: bindActionCreators(usersSlice.actions.getUser, dispatch),
    getConnectors: bindActionCreators(connectorsSlice.actions.getConnectors, dispatch),
    getConnections: bindActionCreators(connectionsSlice.actions.getConnections, dispatch),
    getSources: bindActionCreators(sourcesSlice.actions.getSources, dispatch),
    getObjects: bindActionCreators(objectsSlice.actions.getObjects, dispatch),
    getObjectCategories: bindActionCreators(objectsSlice.actions.getCategories, dispatch),
    getSchemaCategories: bindActionCreators(objectsSlice.actions.getSchemaCategories, dispatch),
    getSchemaPointers: bindActionCreators(objectsSlice.actions.getSchemaPointers, dispatch),
    getRecipes: bindActionCreators(recipesSlice.actions.getRecipes, dispatch),
    getFlows: bindActionCreators(flowsSlice.actions.getFlows, dispatch),
    getModels: bindActionCreators(recipesSlice.actions.getModels, dispatch),
    getSyncs: bindActionCreators(syncsSlice.actions.getSyncs, dispatch),
    getAudiences: bindActionCreators(audiencesSlice.actions.getAudiences, dispatch),
    getSegments: bindActionCreators(segmentsSlice.actions.getSegments, dispatch),
    getIntegrations: bindActionCreators(integrationsSlice.actions.getIntegrations, dispatch),
    getStateProfile: bindActionCreators(profilesSlice.actions.getStateProfile, dispatch),
    setLanguage: bindActionCreators(appContextSlice.actions.setAppLanguage, dispatch),
  };
};
const AppContainer: React.FC<AppLayoutProps> = ({
  appContextState,
  organizationState,
  accountState,
  usersState,
  getOrganizations,
  getOrganization,
  getAppContext,
  getAccount,
  saveAppContext,
  setAppLanguage,
  setAppIconAndName,
  getProfiles,
  getUser,
  getUsers,
  getConnectors,
  getConnections,
  getSources,
  getObjects,
  getObjectCategories,
  getSchemaCategories,
  getSchemaPointers,
  getRecipes,
  getFlows,
  getModels,
  getSyncs,
  getAudiences,
  getSegments,
  getIntegrations,
  getDestinations,
  getStateProfile,
  setLanguage,
}) => {
  const appContext = useContext(AppContext);
  const profileRights = useContext(ProfileContext).rights;
  const getProfileFeatures = () => {
    const profileFeatures: string[] = [];
    const features = extractObjectKeys(profileRights);
    features.map((feature) => {
      if (profileRights[feature].length) profileFeatures.push(feature);
    });
    return profileFeatures;
  };
  const visibleFeature = appContext.visibleFeatures;
  const accessibleFeature = appContext.accessibleFeatures;

  const sideBarCollapsed = appContext.layoutPreferences.sideBarCollapsed;
  // @@@Amine saving user preference fo sider collapsing
  const onCollapse = () => {
    if (saveAppContext) {
      saveAppContext({
        appContext: {
          ...appContext,
          layoutPreferences: {
            sideBarCollapsed: !sideBarCollapsed,
          },
        },
      });
    }
  };

  const [highlightedMenuItem, setHighlightedMenuItem] = useState(
    getHighlightedMenuItem(history.location.pathname)
  );

  useEffect(() => {
    if (getFlows) getFlows({ app: 'oc' });
  }, []);
  useEffect(() => {
    if (getModels) getModels({ app: 'oc' });
  }, []);
  useEffect(() => {
    if (getRecipes) getRecipes();
  }, [getRecipes]);

  useEffect(() => {
    if (getAccount) getAccount({ name: 'source' });
  }, [getAccount]);
  useEffect(() => {
    if (getConnectors) getConnectors({ type: 'source' });
  }, [getConnectors]);
  useEffect(() => {
    if (getConnections) getConnections({ type: 'source' });
  }, [getConnections]);
  useEffect(() => {
    if (getSources) getSources();
  }, [getSources]);
  useEffect(() => {
    if (getObjects) getObjects();
  }, [getObjects]);
  useEffect(() => {
    if (getDestinations) getDestinations();
  }, [getDestinations]);
  useEffect(() => {
    if (getIntegrations) getIntegrations();
  }, [getIntegrations]);

  useEffect(() => {
    if (getObjectCategories) getObjectCategories();
  }, [getObjectCategories]);
  useEffect(() => {
    if (getSchemaPointers) getSchemaPointers();
  }, [getSchemaPointers]);
  useEffect(() => {
    if (getSchemaCategories) getSchemaCategories();
  }, [getSchemaCategories]);
  useEffect(() => {
    if (getSyncs) getSyncs();
  }, [getSyncs]);
  useEffect(() => {
    if (getSegments) getSegments();
  }, [getSegments]);
  useEffect(() => {
    if (getAudiences) getAudiences();
  }, [getAudiences]);
  useEffect(() => {
    if (getOrganizations) getOrganizations({ app: 'octo' });
    if (getProfiles) getProfiles({ app: 'octo' });
    if (getUsers) getUsers({ app: 'octo' });
  }, []);
  // @@@Amine set Language from context value
  useEffect(() => {
    if (setAppLanguage) setAppLanguage({ language: appContext.language });
  }, [appContext.language]);

  useEffect(() => {
    // @@@Amine here we are getting the organization from the url query
    const query = window.location.search;
    const urlParams = new URLSearchParams(query);
    let selectedOrganization = urlParams.get('organization');
    if (!selectedOrganization) selectedOrganization = 'octolis';
    if (getOrganization) getOrganization({ organization: selectedOrganization });
    if (getUser) getUser({ userId: 'data', organization: selectedOrganization });
  }, []);
  const applyCssVariables = (variablesMapper: { [key: string]: string }) => {
    const cssVars = extractObjectKeys(variablesMapper);
    for (const variable of cssVars) {
      document.documentElement.style.setProperty(variable, variablesMapper[variable]);
    }
  };
  useEffect(() => {
    applyCssVariables(appContext.theme.variables);
  }, [appContext]);
  useEffect(() => {
    if (setAppIconAndName && appContext.labels.appName) {
      setAppIconAndName({ name: appContext.labels.appName });
    }
  }, [appContext.labels]);
  useEffect(() => {
    if (getAppContext) getAppContext({ name: organizationState.organization.key });
  }, [organizationState.organization]);
  // Listening to history changes to adjust breadcrumb & highlighted menu item
  useEffect(() => {
    return history.listen((location) => {
      setHighlightedMenuItem(getHighlightedMenuItem(location.pathname));
    });
  }, [history.location.pathname, accountState]);

  return (
    <ConnectedRouter history={history}>
      {accountState.loaded && appContextState.loaded ? (
        <Layout className="site-layout__main">
          <div
            className={'site-layout__slider'}
            style={{
              width: !appContext.layoutPreferences.sideBarCollapsed
                ? bigSideMenuWidth
                : smallSideMenuWidth,
              minWidth: !appContext.layoutPreferences.sideBarCollapsed
                ? bigSideMenuWidth
                : smallSideMenuWidth,
            }}
          >
            <Sider
              className="site-layout__fixed-slider "
              collapsed={sideBarCollapsed}
              onCollapse={onCollapse}
              collapsible={true}
            >
              <div className="site-layout__logo-container">
                {!sideBarCollapsed ? (
                  <div
                    className=" octolis__default-logo-expanded "
                    style={{
                      backgroundImage: `url(/icons/appLogos/${appContext.labels.appName}-white.png)`,
                    }}
                  />
                ) : (
                  <div
                    className=" octolis__default-logo "
                    style={{
                      backgroundImage: `url(/icons/appLogos/${appContext.labels.appName}.ico)`,
                    }}
                  />
                )}
              </div>

              <MenuLeft
                highlightedItem={highlightedMenuItem}
                isSiderCollapsed={sideBarCollapsed}
                breadcrumb={[]}
                organizationsState={organizationState}
                usersState={usersState}
                getOrganization={getOrganization}
                getProfile={getProfile}
                getStateProfile={getStateProfile}
                getUser={getUser}
                setLanguage={setAppLanguage}
              />
            </Sider>
          </div>
          <div
            className="site-layout__right-layout"
            style={{
              width: `calc(100% - ${
                !appContext.layoutPreferences.sideBarCollapsed
                  ? bigSideMenuWidth
                  : smallSideMenuWidth
              })`,
            }}
          >
            <Content className="site-layout__right-layout-content">
              <Switch>
                <Suspense fallback={<CustomSpinner />}>
                  {PublicRoutes({
                    visibleFeature,
                    accessibleFeature,
                    profileFeatures: getProfileFeatures(),
                  })}
                </Suspense>
              </Switch>
            </Content>
            <Layout className="site-layout__right-layout-footer">
              <div>{appContext.labels.copyright}</div>
            </Layout>
          </div>
        </Layout>
      ) : (
        <CustomSpinner />
      )}
    </ConnectedRouter>
  );
};
const CustomSpinner = () => {
  return (
    <Space className="ScreenSpinContainer" direction="vertical">
      <div className="breeding-rhombus-spinner">
        <div className="rhombus child-1" />
        <div className="rhombus child-2" />
        <div className="rhombus child-3" />
        <div className="rhombus child-4" />
        <div className="rhombus child-5" />
        <div className="rhombus child-6" />
        <div className="rhombus child-7" />
        <div className="rhombus child-8" />
        <div className="rhombus big" />
      </div>
    </Space>
  );
};
const App = connect(mapStateToProps, mapDispatchToProps)(AppContainer);

export default App;
