import { takeLatest, call, put } from 'redux-saga/effects';
// api
import * as API from './api';
// connectionsSlice
// types
import * as types from './types';
import accountSlice, { AccountState } from '@features/account/ducks/accountSlice';

function* getAccountSaga({ payload }: types.GetAccountSaga) {
  try {
    const { name } = payload;
    const getAccountPayload: AccountState = yield call(API.getAccount,name);
    yield put({ type: accountSlice.actions.getAccountSuccess.type, payload: getAccountPayload });
  } catch (error) {
    yield put({ type: accountSlice.actions.getAccountFailed.type, payload: error });
  }
}

function* saveAccountSaga({ payload }: types.SaveAccountSaga) {
  try {
    const { account } = payload;
    const saveAccountPayload: AccountState = yield call(API.saveAccount, account);
    yield put({ type: accountSlice.actions.saveAccountSuccess.type, payload: saveAccountPayload });
  } catch (error) {
    yield put({ type: accountSlice.actions.saveAccountFailed.type, payload: error });
  }
}

export const accountsSagas = [
  takeLatest(accountSlice.actions.getAccount.type, getAccountSaga),
  takeLatest(accountSlice.actions.saveAccount.type, saveAccountSaga),
];
