import { createSlice } from '@reduxjs/toolkit';
import type { OrganizationReducerActions, OrganizationsReducerActions } from './types';
import { Organization } from '@features/organizations/types';

export interface OrganizationsState {
  loaded: boolean;
  isFetching?: boolean;
  error?: boolean;
  errorDetails?: string | undefined;
  organizations: Organization[];
  organization: Organization;
}

export const INITIAL_THEME_STATE = {
  loaded: false,
  isFetching: false,
  organizations: [],
  organization: {
    key: '',
    details: {
      name: '',
      short_name: ''
    },
    theme: 'default'
  }
} as OrganizationsState;


const organizationsSlice = createSlice({
  name: 'organizations',

  initialState: INITIAL_THEME_STATE,
  reducers: {
    getOrganizations: (state) => {
      state.isFetching = true;
    },
    getOrganizationsSuccess: (state, { payload }: OrganizationsReducerActions) => {
      state.loaded = true;
      state.isFetching = false;
      state.organizations = payload.organizations;
    },
    getOrganizationsFailed: (state, { payload }: OrganizationsReducerActions) => {
      state.isFetching = false;
      state.error = true;
      state.errorDetails = payload.errorDetails;
    },
    getOrganization: (state) => {
      state.isFetching = true;
    },
    getOrganizationSuccess: (state, { payload }: OrganizationReducerActions) => {
      state.loaded = true;
      state.isFetching = false;
      state.organization = payload.organization;
    },
    getOrganizationFailed: (state, { payload }: OrganizationReducerActions) => {
      state.isFetching = false;
      state.error = true;
      state.errorDetails = payload.errorDetails;
    }

  }
});

export default organizationsSlice;
