import { createSlice } from '@reduxjs/toolkit';
import { updateOrAddElementInArray } from '@utils/helpers';
import { generateSegment } from './generateSegment';
import { MatchingConfig, Segment } from '../types';
import {
  ActiveSegmentReducerActions,
  ActiveSegmentUpdateReducerActions,
  CheckColumnsMatchReducerActions,
  CombinationObjectReducerAction,
  SegmentReducerActions,
  SegmentsReducerActions,
} from './types';

// Initial source and state definition
export const INITIAL_SEGMENTS_STATE = {
  loaded: false,
  isFetching: false,
  activeSegment: generateSegment(),
  segments: [],
} as SegmentsState;
export interface SegmentsState {
  loaded: boolean;
  isFetching?: boolean;
  error?: boolean;
  errorDetails?: string | undefined;
  segment?: Segment;
  segments: Segment[];
  activeSegment: Segment;
  match?: MatchingConfig;
}
const segmentsSlice = createSlice({
  name: 'segments',
  initialState: INITIAL_SEGMENTS_STATE,
  reducers: {
    getSegments: (state) => {
      state.isFetching = true;
      state.segments = [];
    },
    getSegmentsSuccess: (state, { payload }: SegmentsReducerActions) => {
      state.loaded = true;
      state.isFetching = false;
      state.segments = payload.segments;
    },
    getSegmentsFailed: (state, { payload }: SegmentsReducerActions) => {
      state.isFetching = false;
      state.error = true;
      state.errorDetails = payload.errorDetails;
    },
    saveSegment: (state) => {
      state.isFetching = true;
    },
    saveSegmentSuccess: (state, { payload }: SegmentReducerActions) => {
      state.loaded = true;
      state.isFetching = false;
      state.segments = updateOrAddElementInArray(payload.segment, state.segments);
      state.activeSegment = generateSegment();
    },
    saveSegmentFailed: (state, { payload }: SegmentReducerActions) => {
      state.isFetching = false;
      state.error = true;
    },
    checkColumnsMatch: (state) => {
      state.isFetching = true;
    },
    checkColumnsMatchSuccess: (state, { payload }: CheckColumnsMatchReducerActions) => {
      state.loaded = true;
      state.isFetching = false;
      state.match = payload.match;
    },
    checkColumnsMatchFailed: (state, { payload }: CheckColumnsMatchReducerActions) => {
      state.isFetching = false;
      state.error = true;
    },
    updateActiveSegment: (state, { payload }: ActiveSegmentReducerActions) => {
      state.activeSegment = {
        ...state.activeSegment,
        ...payload.activeSegment,
      };
    },
    updateActiveSegmentSource: (state, { payload }: ActiveSegmentUpdateReducerActions) => {
      state.loaded = true;
      state.isFetching = false;
      state.activeSegment = {
        ...state.activeSegment,
        source: payload.source,
        targetObject: payload.source.targetObject,
      };
    },
    runSegmentTargetObject: (state) => {
      state.isFetching = true;
    },
    runSegmentTargetObjectSuccess: (state, { payload }: ActiveSegmentReducerActions) => {
      state.loaded = true;
      state.isFetching = false;
      state.activeSegment = payload.activeSegment;
    },
    runSegmentTargetObjectFailed: (state, { payload }: SegmentReducerActions) => {
      state.isFetching = false;
      state.error = true;
    },
    updateActiveSegmentCombinationObject: (state) => {
      state.isFetching = true;
    },
    updateActiveSegmentCombinationObjectSuccess: (
      state,
      { payload }: CombinationObjectReducerAction
    ) => {
      state.loaded = true;
      state.isFetching = false;
      state.activeSegment = {
        ...state.activeSegment,
        combinations: updateOrAddElementInArray(
          payload.combination,
          state.activeSegment.combinations
        ),
      };
    },
    updateActiveSegmentCombinationObjectFailed: (state, { payload }: SegmentReducerActions) => {
      state.isFetching = false;
      state.error = true;
    },
  },
});

export default segmentsSlice;
