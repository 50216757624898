const sourcesScreen = {
  sourcesDescription:
    'Les sources sont des emplacements en dehors du datahub à partir desquels vous obtenez des données.',
  addASource: 'Ajouter une source',

  // source keys
  name: 'Nom',
  connector: 'Connecteur',
  pointer: 'Pointeur',
  records: 'Registres',
  lastRun: 'Last run',
  status: 'Statut',
  schedule: 'Planning',
  actions: 'Actions',
  // source actions
  create: 'Créer',
  view: 'Voir',
  edit: 'Éditer',
  log: 'Log',
  active: 'Active',
  broken: 'Interrompu',
  // form
  editTheSource: 'Editer une source',
  viewTheSource: 'Voir une source',
  createTheSource: 'Créer une source',
  chooseConnector: 'Choisir un connecteur',
  configuration: 'Configuration',
  sourcePointer: 'Pointeur de la source',
  globalSettings: 'Paramètres globaux',
  usageInFlows: 'Utilisation dans les flux',
  sourceUsedInFlows: 'Cette source est actuellement utilisée dans les flux suivants',
  connectorRequiredMessage: 'Veuillez sélectionner un connecteur.',
  pointerRequiredMessage: 'Veuillez sélectionner un pointeur.',
  nameRequiredMessage: 'Veuillez sélectionner un nom.',
  statusRequiredMessage: 'Veuillez sélectionner un statut.',
  creationFlowRequiredMessage:
    'Veuillez choisir si vous souhaitez créer un flux pour cette source.',

  creatFlowLabel: 'Créer un flux pour alimenter un objet avec les données de cette source',
  confirmDeletion: 'Êtes-vous sûr? La source sera supprimée.',
  confirmCancel: 'Êtes-vous sûr? Vos modifications ne seront pas enregistrées.',
  save: 'Sauvegarder',
  next: 'Suivant',
  choose: 'Choisir',
  previous: 'Retour',
  cancel: 'Annuler',
  delete: 'Supprimer',
  yes: 'Oui',
  no: 'Non',
};
const menuHeader = {
  controlCenter: 'Centre de contrôle',
  settings: 'Paramètres',
};
const menuLeft = {
  connections: 'Connections',
  sources: 'Sources',
  allSources: 'Toutes les sources',
  flows: 'Flux',
  allFlows: 'Tous les flux',
  sourceFlows: 'Flux de source',
  destinationsFlows: 'Flux de destination',
  internalFlows: 'Flux internes',
  recipes: 'Recipes',
  allRecipes: 'Toutes les recipes',
  objects: 'Objets',
  allObjects: 'Tous les objets',
  contacts: 'Contacts',
  orders: 'Ordres',
  products: 'Produits',
  destinations: 'Destinations',
  allDestinations: 'Toutes les destinations',
  syncs: 'Syncs',
};
const syncsScreen = {
  //screens
  syncsDescription: 'Les Syncs associent une source vers une destination',
  addNewSync: 'Ajouter une sync',
  createANewSync: 'Créer une nouvelle sync',
  //form
  whatAudienceDoSync: 'What audience do you want to sync?',
  sendARawSource: 'Envoyer une Source',
  sendASegment: 'Envoyer un Segment',
  addSource: 'Ajouter une Source',
  createANewSource: 'Créer un nouveau source ',
  createANewSegment: 'Créer un nouveau segment',
  addDestination: 'Ajouter une Destination',
  originTypeLabel: 'Quelles données voulez-vous synchroniser?',
  chooseYourSource: 'Choisissez une Source',
  chooseYourSegment: 'Choisissez un Segment',
  chooseYourOrigin: 'Choisissez une Origine',
  source: 'Source',
  segment: 'Segment',
  origin: 'Origine',
  settings: 'Paramètres',
  destination: 'Destination',
  whereSyncDataQuestion: 'Vers où voulez-vous synchroniser les données ?',
  whichFieldToSyncQuestion: 'Quels champs doivent être synchronisés ?',
  whenDoYouWantToSync: 'When do you want to sync the audience?',
  nameAndActivateThisSync: 'Name and activate this sync ',
  weWillCastText: 'Nous allons convertir ',
  stringAsText: " en chaîne de caractères telle qu'elle est actuellement stockée en tant que ",
  weCannotSendText: 'Nous ne pouvons pas envoyer ',
  asAText: ' comme un ',
  updateMappingText: ', veuillez mettre à jour le Mapping.',
  sourceColumnTitle: 'Source Colonne',
  distinationFieldTitle: 'Champ de destination',
  refreshFieldsTitle: 'Actualiser les champs de destination',
  addMappingText: 'Ajouter un Mapping',
  addStaticValueText: 'Ajouter une valeur statique',
  syncNameLabel: 'Nom de la Sync',

  syncNameInputRuquiredMessage: 'Veuillez entrer un nom',
  activeLabel: 'Activer la Sync',
  viewTheSync: 'Voir la sync',
  editTheSync: 'Editer la sync',
  originSettings: "Paramètres d'Origin",
  destinationSettings: 'Paramètres de Destination',
  mappingSettings: 'Paramètre de Mapping',
  originStepFailed: "Echec du paramétrage d'Origin, accès à cet écran refusé",
  mappingStaticValidValue: 'Veuillez entrer une valeur valide !',
  mappingOriginEmptyValue: "Veuillez sélectionner une colonne d'origin !",
  mappingTargetEmptyValue: 'Veuillez sélectionner une colonne de destination !',
  mappingInvalidAlert: 'Veuillez entrer un ',
  whatKindOfSync: 'What kind of sync would you like us to perform?',
  chooseAction: 'Choose action',

  whatShouldWedoRecordLeaves: 'What should we do when a record leaves the audience?',
  syncTypeRequiredRuleMessage: 'Please choose the sync type',
  recordsActionTypeRequiredRuleMessage: 'Please choose records action',
  afterCreation: 'Sync only when a record is created in the audience',
  upsert: 'Sync when a record is created or updated (upsert) in audience',
  nothing: 'Do nothing',
  deleteInDestination: 'Delete it in the destination',
};
const segmentScreen = {
  createANewSegment: 'Ajouter un  segment',
  editSegment: 'Edit segment',
  segmenCombineMultiple: 'Les segments servent à combiner plusieurs sources',
  selectSourceOrCreateOne: 'Select a source or create one',
  saveAndSync: 'Sauvegarder & Sync',
  whatDataToSegment: 'Quelles données souhaitez-vous segmenter?',
  addSource: 'Ajouter Source',

  chooseYourBaseData: 'Choose your base data',
  addData: 'Add data',
  updateCombinedSource: 'Update combined source',
  resetSources: 'Reset sources',
  selectSourceOrCreate: 'Select a source or create one',
  segmentNameRequiredMessage: 'Please enter the segment name',
  segmentName: 'Enter the segment name',
  howToSegmentData: 'Comment souhaitez-vous segmenter vos données ?',
  easy: 'Mode facile',
  sql: ' Utiliser SQL',
  filterData: 'Filtrer les données',
  //form
  combineSources: 'Combiner les sources',
  explore: 'Explorer',
  combinationSettings: 'Paramètres de combinaison',
  columnSelection: 'Sélection de colonne',
  whatSourceLikeToCombine: 'Avec quelle source souhaitez-vous combiner',
  connection: 'Connection',
  file: 'File',
  recordsInTotal: 'données au total',
  howShouldCombineNewSource: "Comment combiner cette nouvelle source avec l'autre ?",
  ofRecordsFrom: ' des data de ',
  matchOnesFrom: ' correspondent à ceux de ',
  whatRecordsShouldWeKeep:
    'Quels enregistrements devons-nous conserver pour cette nouvelle source ?',
  severalRecordsOfCombinedSource:
    ' Plusieurs enregistrements de source combinée correspondent à chaque enregistrement de ',
  requiredCombinationTypeMessage: 'Veuillez choisir le mode pour combinée',
  whatShouldWeDo: ', Que devrions nous faire?',
  chooseColumn: 'Choisissez la colonne',
  chooseFormula: 'Choisissez la formule',
  pleaseChooseTheFormula: 'Veuillez choisir la formule!',
  requiredColumnRuleMessage: 'Veuillez choisir la colonne!',

  clickToReduce: 'Cliquez pour fermer les statistiques',
  clickToExpand: 'Cliquez pour voir les statistiques',
  statistics: 'Statistiques',
  sampleValues: 'Les valeurs',
  //formula
  sum: 'Sum',
  max: 'Max',
  min: 'Min',
  avg: 'Avg',
  first: 'First',
  last: 'Last',
  // segments table
  sourceName: 'Source',
  combinedSources: 'Combined sources',
};
const scheduleContainer = {
  trigger: 'Trigger',
  regular: 'Interval régulier',
  manual: 'Manuel',
  triggerRequiredRuleMessage: 'Le Trigger est requis !',
  scheduleEvery: 'Planifié tous les',
  scheduleRequiredRuleMessage: 'Une fréquence est requise !',
  intervalRequiredRuleMessage: 'Un interval est requis !',
  startingDateRequiredRuleMessage: `Une date de début est requise !`,
  startingDateValidator: `Expression CRON invalide`,
  startingAtLabel: 'À',
  startingFromLabel: 'À partir de',
  hour: 'Heure',
  day: 'Jour',
  month: 'Mois',
  cronExpressionLabel: 'Expression CRON',
  cronExpressionRequiredRuleMessage: 'Veuillez entrer une expression CRON !',
};
const audiencesScreen = {
  newAudiences: 'New Audience',
};
const recipeScreen = {
  pleaseEnterRecipeName: 'Please enter a name for the recipe!',
  availableDataOrigins: 'Available data origins',
  availableDataTargets: 'Available data targets',
  useModel: 'Use model',
  context: 'Context',
  testQuery: 'Test query',
  queryRunSuccess: 'Query run with Success',
  queryFailed: 'Query failed',
  runQuery: 'Run query',
  compiledSql: 'Compiled SQL',
  results: 'results',
  logs: 'Logs',
  pleaseExecuteQuery: 'Please execute query',
  youCanSaveRecipe: 'You can save your recipe now',
  queryMustBeTested: 'Query must be tested with success',
};
const connectionsScreen = {
  connectionsListDescription:
    'Add Sources and Destinations to Octolis to get started and do more with your data.',
  addMoreLabel: '+ Add more',
};
const fr = {
  translation: {
    // ...menuLeft,
    // ...menuHeader,
    // ...sourcesScreen,
    // ...syncsScreen,
    // ...scheduleContainer,
    // ...segmentScreen,
    // ...connectionsScreen,
    // ...recipeScreen,
    // ...audiencesScreen,
  },
};
export default fr;
