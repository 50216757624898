import { OctoObject, SchemaColumn } from '@features/objects/types';
import { Segment } from '@features/segments/types';

import { Integration } from '@features/integrations/types';
import { ScheduleInterval, ScheduleManual, ScheduleTypes } from '@features/schedule/types';
import { Audience } from '@features/audiences/types';
import { OctoRecordsFilter } from '@features/filter/types';
import { Connection } from '@features/connections/types';

export type Sync = {
  key: string;
  name: string;
  type?: syncTypes;
  recordLeavesAction: recordsActionsTypes;
  origin: Audience;
  integration: Integration;
  integrationObject: OctoObject;
  mapping: Mapping[];
  schedule: ScheduleManual | ScheduleInterval | undefined;
  scheduleType: ScheduleTypes;
  active: boolean;
  filter?: OctoRecordsFilter;
};

export type ActiveSync = {
  key?: string;
  name?: string;
  type?: syncTypes;
  recordLeavesAction?: recordsActionsTypes;
  origin?: Audience;
  originObject?: OctoObject;
  integration?: Integration;
  integrationConnection?: Connection;
  integrationObject?: OctoObject;
  mapping: Mapping[];
  schedule?: ScheduleManual | ScheduleInterval;
  scheduleType: ScheduleTypes;
  active?: boolean;
  filter?: OctoRecordsFilter;
};
export type SyncForm = {
  segment?: string;
  name?: string;
  integrationName?: string;
  type?: syncTypes;
  recordLeavesAction?: recordsActionsTypes;
  mapping: MappingWithIds[];
  schedule?: ScheduleManual | ScheduleInterval;
  scheduleType: ScheduleTypes;
  segmentAudience: string;
  active?: boolean;
};
export type SyncsTableRecord = {
  key: string;
  name: string;
  audience: string | undefined;
  lastRun: string;
  destination: string;
  schedule: string;
  status: boolean;
};
export type Mapping =
  | {
      id: string;
      targetColumn: SchemaColumn;
      staticValue: string;
      isStaticValue: true;
    }
  | {
      id: string;
      targetColumn: SchemaColumn;
      originColumn: SchemaColumn;
      isStaticValue: false;
      syncTrigger?: syncTrigger;
    };

export type MappingWithIds =
  | {
      id: string;
      targetColumn: string;
      staticValue: string;
      isStaticValue: true;
    }
  | {
      id: string;
      originColumn: string;
      targetColumn: string;
      isStaticValue: false;
      syncTrigger?: syncTrigger;
    };
export enum SYNC_TRIGGERS {
  CREATED_UPDATED = 'createdUpdated',
  CREATED = 'created',
}
export type syncTypes = 'afterCreation' | 'upsert';
export type recordsActionsTypes = 'nothing' | 'deleteInDestination';
export type syncTrigger = 'createdUpdated' | 'created';
