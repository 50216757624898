import { createSlice } from '@reduxjs/toolkit';
import type { AccountReducerActions } from './types';
import { Account } from '@features/account/types';

export interface AccountState {
  loaded: boolean;
  isFetching?: boolean;
  error?: boolean;
  errorDetails?: string | undefined;
  account: Account;
}

export const INITIAL_THEME_STATE = {
  loaded: false,
  isFetching: false,
  account: {
    name: "Octolis",
    dbConfig: {
      connection: '',
      trigram: ""
    },
    accessibleFeatures:[],
    visibleFeatures:[],
    pureReverseETL:false
  }
} as AccountState;


const accountSlice = createSlice({
  name: 'accounts',

  initialState: INITIAL_THEME_STATE,
  reducers: {
    getAccount: (state) => {
      state.isFetching = true;
    },
    getAccountSuccess: (state, { payload }: AccountReducerActions) => {
      state.loaded = true;
      state.isFetching = false;
      state.account = payload.account;
    },
    getAccountFailed: (state, { payload }: AccountReducerActions) => {
      state.isFetching = false;
      state.error = true;
      state.errorDetails = payload.errorDetails;
    },

    saveAccount: (state) => {
      state.isFetching = true;
    },
    saveAccountSuccess: (state, { payload }: AccountReducerActions) => {
      state.loaded = true;
      state.isFetching = false;
      state.account = payload.account;
    },
    saveAccountFailed: (state, { payload }: AccountReducerActions) => {
      state.isFetching = false;
      state.error = true;
      state.errorDetails = payload.errorDetails;
    },

  }
});

export default accountSlice;
