import { createSlice } from '@reduxjs/toolkit';
import type {
  ConnectionPointerReducerActions,
  ConnectionReducerActions,
  ConnectionsReducerActions,
  DeleteConnectionReducerActions,
} from './types';
import { ActiveConnection, Connection } from '@features/connections/types';
import { updateOrAddElementInArray } from '@utils/helpers';
import { TreeStructure } from '@components/form/items/OctoTreeSelect';

export interface ConnectionsState {
  loaded: boolean;
  isFetching?: boolean;
  isFetchingConnectionPointers?: boolean;
  shouldBeClosed?: boolean;
  error?: boolean;
  errorDetails?: string | undefined;
  page?: number;
  limit?: number;
  total?: number;
  connections: Connection[];
  activeConnection: ActiveConnection;
  pointers: TreeStructure;
}

export const INITIAL_SOURCE_STATE = {
  loaded: false,
  isFetching: false,
  isFetchingConnectionPointers: false,
  connections: [],
  pointers: {},
  activeConnection: {
    usedAs: [],
  },
} as ConnectionsState;

const connectionsSlice = createSlice({
  name: 'connections',
  initialState: INITIAL_SOURCE_STATE,

  reducers: {
    getConnections: (state) => {
      state.isFetching = true;
    },
    getConnectionsSuccess: (state, { payload }: ConnectionsReducerActions) => {
      state.loaded = true;
      state.isFetching = false;
      state.connections = payload.connections;
    },
    getConnectionsFailed: (state, { payload }: ConnectionsReducerActions) => {
      state.isFetching = false;
      state.error = true;
      state.errorDetails = payload.errorDetails;
    },

    saveConnection: (state) => {
      state.isFetching = true;
      state.shouldBeClosed = false;
    },
    saveSourceConnection: (state) => {
      state.isFetching = true;
      state.shouldBeClosed = false;
    },
    saveConnectionSuccess: (state, { payload }: ConnectionReducerActions) => {
      state.loaded = true;
      state.isFetching = false;
      state.shouldBeClosed = true;
      state.connections = updateOrAddElementInArray(payload.connection, state.connections);
    },
    saveConnectionFailed: (state, { payload }: ConnectionReducerActions) => {
      state.isFetching = false;
      state.error = true;
      state.errorDetails = payload.errorDetails;
    },

    deleteConnection: (state) => {
      state.isFetching = true;
      state.shouldBeClosed = false;
    },
    deleteConnectionSuccess: (state, { payload }: DeleteConnectionReducerActions) => {
      state.loaded = true;
      state.isFetching = false;
      state.shouldBeClosed = true;
      state.connections = state.connections.filter(
        (connection) => connection.key !== payload.connection.key
      );
    },
    deleteConnectionFailed: (state, { payload }: DeleteConnectionReducerActions) => {
      state.isFetching = false;
      state.error = true;
      state.errorDetails = payload.errorDetails;
    },

    getConnectionPointers: (state) => {
      state.isFetchingConnectionPointers = true;
      state.pointers = {};
    },
    getConnectionPointersSuccess: (state, { payload }: ConnectionPointerReducerActions) => {
      state.loaded = true;
      state.isFetchingConnectionPointers = false;
      state.pointers = payload.pointers;
    },
    getConnectionPointersFailed: (state, { payload }: ConnectionPointerReducerActions) => {
      state.isFetchingConnectionPointers = false;
      state.error = true;
      state.errorDetails = payload.errorDetails;
    },

    setActiveConnection: (state, { payload }: ConnectionReducerActions) => {
      state.activeConnection = payload.connection;
    },
    updateActiveConnection: (state, { payload }: ConnectionReducerActions) => {
      state.activeConnection = { ...state.activeConnection, ...payload.connection };
    },
  },
});
export default connectionsSlice;
