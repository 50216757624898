import { Sync, SYNC_TRIGGERS } from '@features/syncs/types';
import { objects } from './objects';
import { integrations } from '@data/integrations';
import moment from 'moment';
import { generateId } from '@utils/helpers';
import { CronTriggers } from '@features/schedule/types';
import segments from './segments';
import { audiences } from '@data/audiences';

export const syncs: Sync[] = [
  {
    key: '1',
    name: 'Sync 1',
    type: 'upsert',
    recordLeavesAction: 'nothing',
    origin: audiences[0],
    integration: integrations[6],
    integrationObject: objects[14],
    mapping: [
      {
        id: '1639398596380.040o5m',
        isStaticValue: false,
        targetColumn: objects[4].schema[0],
        originColumn: objects[14].schema[2],
        syncTrigger: SYNC_TRIGGERS.CREATED_UPDATED,
      },
    ],
    schedule: { schedule: '* * * * * *', startingDate: moment() },
    scheduleType: CronTriggers.MANUAL,
    active: true,
  },
];
