import React from 'react';
import { Form, Input, InputNumber } from 'antd';
import { SizeType } from 'antd/lib/config-provider/SizeContext';

interface OctoInputProps {
  name: string | string[];
  label?: string;
  type?: string;
  value?: string | number | boolean;
  disabled?: boolean;
  hidden?: boolean;
  placeholder?: string;
  rule?: any;
  onChange?: any;
  dataTestID?: string;

  // number
  size?: SizeType;
  min?: number;
  max?: number;
  formItemClassName?: string;
  formItemStyle?: React.CSSProperties;
  inputClassName?: string;
  inputStyle?: React.CSSProperties;
  inputId?: string;
}

const OctoInput = ({
  name,
  label,
  disabled,
  placeholder,
  dataTestID,
  value,
  type,
  rule,
  hidden,
  size,
  min,
  max,
  onChange,
  formItemClassName,
  formItemStyle,
  inputStyle,
  inputClassName,
  inputId,
}: OctoInputProps) => {
  return (
    <Form.Item
      label={label}
      name={name}
      hidden={hidden}
      initialValue={value}
      rules={!disabled && rule ? (rule.length ? rule : [rule]) : []}
      className={formItemClassName}
      style={formItemStyle}
    >
      {type === 'password' ? (
        <Input.Password
          data-testid={dataTestID}
          disabled={disabled}
          placeholder={placeholder}
          size={size}
          style={inputStyle}
          className={inputClassName}
        />
      ) : type === 'number' ? (
        <InputNumber
          data-testid={dataTestID}
          size={size}
          min={min}
          max={max}
          value={typeof value === 'number' ? value : undefined}
          onChange={onChange ? (e) => onChange(e) : undefined}
          style={inputStyle}
          className={inputClassName}
        />
      ) : (
        <Input
          data-testid={dataTestID}
          size={size}
          onChange={onChange ? (e) => onChange(e.target.value) : undefined}
          disabled={disabled}
          placeholder={placeholder}
          style={inputStyle}
          className={inputClassName}
          id={inputId}
        />
      )}
    </Form.Item>
  );
};

export default OctoInput;
