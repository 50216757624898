import * as apiTypes from './types';
import { sleep } from '@utils/helpers';
import { integrations } from '@data/integrations';
import { Integration } from '@features/integrations/types';
import { contactsColumns, ordersColumns, productsColumns } from '@data/objectsColumns';
import { SchemaColumn } from '@features/objects/types';

const getIntegrations: apiTypes.APIGetIntegrations = async () => {
  await sleep(1500);
  let error: boolean = false;
  let errorDetails: string | undefined = undefined;

  if (!integrations) {
    error = true;
    errorDetails = 'No integrations found.';
  }
  return {
    integrations,
    error,
    loaded: true,
    errorDetails: error && errorDetails ? errorDetails : undefined,
  };
};

const getIntegrationSchema: apiTypes.APIGetIntegrationSchema = async (integration: Integration) => {
  await sleep(1500);
  let error: boolean = false;
  let errorDetails: string | undefined = undefined;

  const integrationPointer = integration.pointer;
  let integrationSchema: SchemaColumn[];
  if (!integration) {
    error = true;
    errorDetails = 'No integration found.';
  }
  if (integrationPointer.indexOf('contacts') !== -1) {
    integrationSchema = contactsColumns;
  } else if (integrationPointer.indexOf('orders') !== -1) {
    integrationSchema = ordersColumns;
  } else if (integrationPointer.indexOf('products') !== -1) {
    integrationSchema = productsColumns;
  } else {
    integrationSchema = contactsColumns;
  }
  return {
    error,
    schema: integrationSchema,
    loaded: true,
    errorDetails: error && errorDetails ? errorDetails : undefined,
  };
};
export { getIntegrations, getIntegrationSchema };
