import { combineReducers } from 'redux';
import { connectRouter, RouterState } from 'connected-react-router';
import { History } from 'history';
import sourcesSlice, { SourcesState } from '@features/sources/ducks/sourcesSlice';
import appContextSlice, { AppContextState } from '@features/appContext/ducks/appContextSlice';
import recipesSlice, { RecipesState } from '@features/recipes/ducks/recipesSlice';
import profilesSlice, { ProfilesState } from '@features/profiles/ducks/profilesSlice';
import flowsSlice, { FlowsState } from '@features/flows/ducks/flowsSlice';
import connectorsSlice, { ConnectorsState } from '@features/connectors/ducks/connectorsSlice';
import organizationsSlice, {
  OrganizationsState,
} from '@features/organizations/ducks/organizationsSlice';
import usersSlice, { UsersState } from '@features/users/ducks/usersSlice';
import connectionsSlice, { ConnectionsState } from '@features/connections/ducks/connectionsSlice';
import accountSlice, { AccountState } from '@features/account/ducks/accountSlice';
import objectsSlice, { ObjectsState } from '@features/objects/ducks/objectsSlice';
import rawRecordsSlice, { RawRecordsState } from '@features/rawRecords/ducks/rawRecordsSlice';
import filterSlice, { FiltersState } from '@features/filter/ducks/filterSlice';
import segmentsSlice, { SegmentsState } from '@features/segments/ducks/segmentsSlice';
import syncsSlice, { SyncsState } from '@features/syncs/ducks/syncsSlice';
import integrationSlice, {
  IntegrationsState,
} from '@features/integrations/ducks/integrationsSlice';
import destinationsSlice, {
  DestinationsState,
} from '@features/destinations/ducks/destinationsSlice';
import audiencesSlice, { AudienceState } from '@features/audiences/ducks/audiencesSlice';
import redirectionsSlice, {
  RedirectionsState,
} from '@features/redirections/ducks/redirectionsSlice';

// @@@Amine I don't if using unknown is safe or not

export interface ReducerStates {
  sources: SourcesState;
  appContext: AppContextState;
  recipes: RecipesState;
  profiles: ProfilesState;
  flows: FlowsState;
  connectors: ConnectorsState;
  organizations: OrganizationsState;
  users: UsersState;
  connections: ConnectionsState;
  account: AccountState;
  objects: ObjectsState;
  filter: FiltersState;
  router: RouterState<unknown>;
  rawRecords: RawRecordsState;
  syncs: SyncsState;
  segments: SegmentsState;
  integrations: IntegrationsState;
  destinations: DestinationsState;
  audiences: AudienceState;
  redirections: RedirectionsState;
}
const reducer = (history: History) =>
  combineReducers<ReducerStates>({
    sources: sourcesSlice.reducer,
    appContext: appContextSlice.reducer,
    recipes: recipesSlice.reducer,
    profiles: profilesSlice.reducer,
    flows: flowsSlice.reducer,
    connectors: connectorsSlice.reducer,
    organizations: organizationsSlice.reducer,
    users: usersSlice.reducer,
    connections: connectionsSlice.reducer,
    account: accountSlice.reducer,
    objects: objectsSlice.reducer,
    filter: filterSlice.reducer,
    router: connectRouter(history),
    rawRecords: rawRecordsSlice.reducer,
    segments: segmentsSlice.reducer,
    syncs: syncsSlice.reducer,
    integrations: integrationSlice.reducer,
    destinations: destinationsSlice.reducer,
    audiences: audiencesSlice.reducer,
    redirections: redirectionsSlice.reducer,
  });
export default reducer;
