import { takeLatest, call, put } from 'redux-saga/effects';
// api
import * as API from './api';
import syncsSlice from './syncsSlice';
import * as types from './types';
import generateSyncFlow from '@features/flows/generators/generateSyncFlow';
import flowsSlice from '@features/flows/ducks/flowsSlice';
import { Sync } from '../types';
import { generateId } from '@utils/helpers';
import { push } from 'connected-react-router';
import { routesEnum } from '@routes/types';

function* getSyncsSaga() {
  try {
    const getSyncsPayload: types.ApiAllSyncsPayload = yield call(API.getAllSyncs);
    yield put({ type: syncsSlice.actions.getSyncsSuccess.type, payload: getSyncsPayload });
  } catch (error) {
    yield put({ type: syncsSlice.actions.getSyncsFailed.type, payload: error });
  }
}

function* saveSyncSaga({ payload }: types.SaveSyncSaga) {
  try {
    const { activeSync } = payload;
    if (
      activeSync.name &&
      activeSync.recordLeavesAction &&
      activeSync.integration &&
      activeSync.integrationObject &&
      activeSync.mapping &&
      activeSync.scheduleType &&
      activeSync.origin
    ) {
      const sync: Sync = {
        key: activeSync.key ? activeSync.key : generateId(),
        name: activeSync.name,
        recordLeavesAction: activeSync.recordLeavesAction,
        origin: activeSync.origin,
        integration: activeSync.integration,
        integrationObject: activeSync.integrationObject,
        mapping: activeSync.mapping,
        schedule: activeSync.schedule,
        scheduleType: activeSync.scheduleType,
        active: !!activeSync.active,
        filter: activeSync.filter,
      };

      const saveSyncPayload: types.APISyncPayload = yield call(API.saveSync, sync);
      yield put({ type: syncsSlice.actions.saveSyncSuccess.type, payload: saveSyncPayload });
      // Save generated flows
      const syncFlow = generateSyncFlow(saveSyncPayload.sync);
      yield put({ type: flowsSlice.actions.saveSyncFlowSuccess.type, payload: { syncFlow } });
      // Redirection after success
      yield put(push(routesEnum.SYNCS));
    } else {
      throw new Error('Please fill all the form inputs');
    }
  } catch (err: unknown) {
    if (err instanceof Error) {
      yield put({
        type: syncsSlice.actions.saveSyncFailed.type,
        payload: { err, errorDetails: err.message },
      });
    }
  }
}

export const syncsSagas = [
  takeLatest(syncsSlice.actions.getSyncs.type, getSyncsSaga),
  takeLatest(syncsSlice.actions.saveSync.type, saveSyncSaga),
];
