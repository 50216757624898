import { createSlice } from '@reduxjs/toolkit';
import { updateOrAddElementInArrayWithKey } from '@utils/helpers';
import { generateFilterGroup } from '../generateFilter';

import {
  ConditionType,
  FilterGroupType,
  OctoRecordsFilter,
  OctoRecordsQuickFilter,
} from '../types';
import {
  FilterCombinationOperatorReducerActions,
  FilterConditionReducerActions,
  FilterReducerActions,
  QuickFilterReducerActions,
  QuickFiltersReducerActions,
  SaveFilterReducerActions,
} from './types';
export const INITIAL_QUICK_FILTERS_STATE = {
  loaded: false,
  isFetching: false,
  quickFilters: [],
};
export const INITIAL_FILTER_STATE = {
  loaded: false,
  isFetching: false,
  quickFilterState: INITIAL_QUICK_FILTERS_STATE,
} as FiltersState;
export interface QuickFilterState {
  loaded: boolean;
  isFetching?: boolean;
  error?: boolean;
  errorDetails?: string | undefined;
  quickFilters: OctoRecordsQuickFilter[];
}
export interface FiltersState {
  loaded: boolean;
  isFetching?: boolean;
  error?: boolean;
  errorDetails?: string | undefined;
  filter?: OctoRecordsFilter;
  quickFilterState: QuickFilterState;
}
const filterSlice = createSlice({
  name: 'filter',
  initialState: INITIAL_FILTER_STATE,
  reducers: {
    getFilterByObject: (state) => {
      state.isFetching = true;
    },
    getFilterByObjectSuccess: (state, { payload }: FilterReducerActions) => {
      state.loaded = true;
      state.isFetching = false;
      state.filter = payload.filter;
    },

    getFilterByObjectFailed: (state, { payload }: FilterReducerActions) => {
      state.isFetching = false;
      state.error = true;
      state.errorDetails = payload.errorDetails;
    },
    saveFilter: (state) => {
      state.isFetching = true;
    },
    saveFilterSuccess: (state, { payload }: SaveFilterReducerActions) => {
      const { filter } = payload;
      state.loaded = true;
      state.isFetching = false;
      state.filter = filter;
    },
    saveFilterFailed: (state, { payload }: SaveFilterReducerActions) => {
      state.isFetching = false;
      state.error = true;
      state.errorDetails = payload.errorDetails;
    },
    saveFilterCondition: (state) => {
      state.isFetching = true;
    },
    saveFilterConditionSuccess: (state, { payload }: FilterConditionReducerActions) => {
      const { condition, groupId } = payload;
      let filterGroups: FilterGroupType[];
      if (state.filter) {
        if (groupId) {
          filterGroups = state.filter.groupFilters.reduce(
            (result: FilterGroupType[], group: FilterGroupType) => {
              if (group.id === groupId) {
                const conditions = updateOrAddElementInArrayWithKey(
                  condition,
                  group.conditions,
                  'id'
                );
                result.push({ ...group, conditions });
              } else {
                result.push(group);
              }
              return result;
            },
            []
          );
        } else {
          filterGroups = [generateFilterGroup(condition), ...state.filter.groupFilters];
        }
        state.filter = { ...state.filter, groupFilters: filterGroups };
      }
      state.loaded = true;
      state.isFetching = false;
    },

    saveFilterConditionFailed: (state, { payload }: FilterConditionReducerActions) => {
      state.isFetching = false;
      state.error = true;
      state.errorDetails = payload.errorDetails;
    },
    removeFilterCondition: (state) => {
      state.isFetching = true;
    },
    removeFilterConditionSuccess: (state, { payload }: FilterConditionReducerActions) => {
      const { condition, groupId } = payload;

      let filterGroups: FilterGroupType[];

      if (state.filter) {
        filterGroups = state.filter.groupFilters.reduce(
          (result: FilterGroupType[], group: FilterGroupType) => {
            if (group.id === groupId) {
              const conditions = group.conditions.filter((element) => element.id !== condition.id);
              conditions.length && result.push({ ...group, conditions });
            } else {
              result.push(group);
            }
            return result;
          },
          []
        );
        state.filter = { ...state.filter, groupFilters: filterGroups };
      }

      state.loaded = true;
      state.isFetching = false;
    },
    removeFilterConditionFailed: (state, { payload }: FilterConditionReducerActions) => {
      state.isFetching = false;
      state.error = true;
      state.errorDetails = payload.errorDetails;
    },
    updateCombinationOperator: (state) => {
      state.isFetching = true;
    },
    updateCombinationOperatorSuccess: (
      state,
      { payload }: FilterCombinationOperatorReducerActions
    ) => {
      const { operatorKey, groupId } = payload;
      if (state.filter)
        if (groupId) {
          const groupFilters = state.filter.groupFilters.map((group) => {
            if (group.id === groupId) {
              group.combinationOperator = operatorKey;
            }
            return group;
          });
          state.filter = { ...state.filter, groupFilters: groupFilters };
        } else {
          state.filter = { ...state.filter, combinationOperator: operatorKey };
        }
      state.loaded = true;
      state.isFetching = false;
    },
    updateCombinationOperatorFailed: (
      state,
      { payload }: FilterCombinationOperatorReducerActions
    ) => {
      state.isFetching = false;
      state.error = true;
      state.errorDetails = payload.errorDetails;
    },
    getQuickFiltersByObject: (state) => {
      state.quickFilterState.isFetching = true;
      state.quickFilterState.quickFilters = [];
    },
    getQuickFiltersByObjectSuccess: (state, { payload }: QuickFiltersReducerActions) => {
      state.quickFilterState.loaded = true;
      state.quickFilterState.isFetching = false;
      state.quickFilterState.quickFilters = payload.quickFilters;
    },
    getQuickFiltersByObjectFailed: (state, { payload }: QuickFiltersReducerActions) => {
      state.quickFilterState.isFetching = false;
      state.quickFilterState.error = true;
      state.quickFilterState.errorDetails = payload.errorDetails;
    },
    saveQuickFilter: (state) => {
      state.quickFilterState.isFetching = true;
    },
    saveQuickFilterSuccess: (state, { payload }: QuickFilterReducerActions) => {
      state.quickFilterState.loaded = true;
      state.quickFilterState.isFetching = false;
      if (payload.quickFilter)
        state.quickFilterState.quickFilters = updateQuickFilter(
          payload.quickFilter,
          state.quickFilterState.quickFilters
        );
      if (state.filter && payload.quickFilter) {
        const newFitlerGroups = updateConditionInGroup(
          {
            ...payload.quickFilter.condition,
            quickFilterName: payload.quickFilter.name,
          },
          state.filter.groupFilters
        );
        state.filter = { ...state.filter, groupFilters: newFitlerGroups };
      }
    },
    saveQuickFilterFailed: (state, { payload }: QuickFilterReducerActions) => {
      state.quickFilterState.isFetching = false;
      state.quickFilterState.error = true;
      state.quickFilterState.errorDetails = payload.errorDetails;
    },
    removeQuickFilter: (state) => {
      state.quickFilterState.isFetching = true;
    },
    removeQuickFilterSuccess: (state, { payload }: QuickFilterReducerActions) => {
      state.quickFilterState.loaded = true;
      state.quickFilterState.isFetching = false;
      state.quickFilterState.quickFilters = state.quickFilterState.quickFilters.filter(
        (element) => {
          return element.condition.id !== payload.condition?.id;
        }
      );
    },
    removeQuickFilterFailed: (state, { payload }: QuickFilterReducerActions) => {
      state.quickFilterState.isFetching = false;
      state.quickFilterState.error = true;
      state.quickFilterState.errorDetails = payload.errorDetails;
    },
  },
});

// helpers that will be removed when backend api's will be ready
const updateQuickFilter = (
  quickFilterToAddOrUpdate: OctoRecordsQuickFilter,
  oldQuickilters: OctoRecordsQuickFilter[]
): OctoRecordsQuickFilter[] => {
  let quickFilterExist: boolean = false;
  const newQuickFilters = oldQuickilters.map((elem) => {
    if (elem.name === quickFilterToAddOrUpdate.condition.quickFilterName) {
      quickFilterExist = true;
      return quickFilterToAddOrUpdate;
    } else return elem;
  });
  if (!quickFilterExist) newQuickFilters.push(quickFilterToAddOrUpdate);
  return newQuickFilters;
};
const updateConditionInGroup = (
  condition: ConditionType,
  groupFilters: FilterGroupType[]
): FilterGroupType[] => {
  const filterGroups = groupFilters.reduce((result: FilterGroupType[], group: FilterGroupType) => {
    const newConditions = group.conditions.map((elem: ConditionType) => {
      if (elem.id === condition.id) return condition;
      return elem;
    });
    result.push({ ...group, conditions: newConditions });
    return result;
  }, []);
  return filterGroups;
};
export default filterSlice;
