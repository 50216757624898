import { createSlice } from '@reduxjs/toolkit';
import { Integration } from '../types';
import { IntegrationsReducerActions } from './types';

export interface IntegrationsState {
  loaded: boolean;
  isFetching?: boolean;
  isFetchingIntegrationSchema?: boolean;
  shouldBeClosed?: boolean;
  error?: boolean;
  errorDetails?: string | undefined;
  integrations: Integration[];
}

export const INITIAL_INTEGRATIONS_STATE = {
  loaded: false,
  isFetching: false,
  isFetchingIntegrationSchema: false,
  integrations: [],
} as IntegrationsState;

const integrationsSlice = createSlice({
  name: 'integrations',
  initialState: INITIAL_INTEGRATIONS_STATE,

  reducers: {
    getIntegrations: (state) => {
      state.isFetching = true;
    },
    getIntegrationsSuccess: (state, { payload }: IntegrationsReducerActions) => {
      state.loaded = true;
      state.isFetching = false;
      state.integrations = payload.integrations;
    },
    getIntegrationsFailed: (state, { payload }: IntegrationsReducerActions) => {
      state.isFetching = false;
      state.error = true;
      state.errorDetails = payload.errorDetails;
    },
    getIntegrationSchema: (state) => {
      state.isFetchingIntegrationSchema = true;
    },
    getIntegrationSchemaSuccess: (state) => {
      state.loaded = true;
      state.isFetchingIntegrationSchema = false;
    },
    getIntegrationSchemaFailed: (state, { payload }: IntegrationsReducerActions) => {
      state.isFetchingIntegrationSchema = false;
      state.error = true;
      state.errorDetails = payload.errorDetails;
    },
  },
});

export default integrationsSlice;
