import React from 'react';
import { generateId } from '@utils/helpers';
import { Position } from 'react-flow-renderer';
import { Edge, Node, NodeSourceData, NodeTypes } from '@features/flows/types';
import { Segment } from '@features/segments/types';
import { generateRecipe } from '@features/recipes/generateRecipe';

const generateSegmentFlow = (segment: Segment, flowKey?: string) => {
  const sourceNodeId = generateId();

  const segmentFlowNodes: Node[] = [];
  const segmentFlowEdges: Edge[] = [];

  const mergeNodeId = generateId();
  const mergeNode: Node = {
    key: mergeNodeId,
    label: '',
    position: { x: 300, y: 50 + ((1 + segment.combinations.length) / 2) * 100 },
    sourcePosition: Position.Right,
    targetPosition: Position.Left,
    type: NodeTypes.MERGE,
  };
  segmentFlowNodes.push(mergeNode);
  if (segment.source && segment.source.targetObject) {
    const sourceNodeId = generateId();
    const sourceNode: Node = {
      key: sourceNodeId,
      source: segment.source,
      type: NodeTypes.SOURCE,
      label: segment.source.name,
      sourcePosition: Position.Right,
      position: { x: 100, y: 100 },
    };
    segmentFlowNodes.push(sourceNode);

    const sourceObjectNodeId = generateId();
    const sourceObjectNode: Node = {
      key: sourceObjectNodeId,
      type: NodeTypes.OBJECT,
      label: segment.source.targetObject.name,
      sourcePosition: Position.Right,
      targetPosition: Position.Left,
      object: segment.source.targetObject,
      position: { x: 200, y: 100 },
    };
    segmentFlowNodes.push(sourceObjectNode);
    const sourceToObjectEdge = {
      key: generateId(),
      source: sourceNodeId,
      target: sourceObjectNodeId,
      animated: true,
      type: 'smoothstep',
    };
    const objectToMergeEdge = {
      key: generateId(),
      source: sourceObjectNodeId,
      target: mergeNodeId,
      animated: true,
      type: 'smoothstep',
    };
    segmentFlowEdges.push(...[sourceToObjectEdge, objectToMergeEdge]);
  }
  segment.combinations.forEach((combination, index) => {
    if (combination.connection && combination.pointer && combination.object) {
      const combinedSource: NodeSourceData = {
        key: combination.key,
        connection: combination.connection,
        name: `CombinedSource 1`,
        pointer: combination.pointer,
        status: ['active'],
      };
      const sourceNodeId = generateId();
      const sourceNode: Node = {
        key: sourceNodeId,
        source: combinedSource,
        type: NodeTypes.SOURCE,
        label: `CombinedSource ${index + 1}`,
        sourcePosition: Position.Right,
        position: { x: 100, y: 200 + index * 100 },
      };
      segmentFlowNodes.push(sourceNode);
      const sourceObjectNodeId = generateId();
      const sourceObject = combination.object;
      const sourceObjectNode: Node = {
        key: sourceObjectNodeId,
        type: NodeTypes.OBJECT,
        label: sourceObject.name,
        sourcePosition: Position.Right,
        targetPosition: Position.Left,
        object: sourceObject,
        position: { x: 200, y: 200 + index * 100 },
      };
      segmentFlowNodes.push(sourceObjectNode);
      const sourceToObjectEdge = {
        key: generateId(),
        source: sourceNodeId,
        target: sourceObjectNodeId,
        animated: true,
        type: 'smoothstep',
      };
      const objectToMergeEdge = {
        key: generateId(),
        source: sourceObjectNodeId,
        target: mergeNodeId,
        animated: true,
        type: 'smoothstep',
      };
      segmentFlowEdges.push(...[sourceToObjectEdge, objectToMergeEdge]);
    }
  });
  const recipeNodeId = generateId();
  if (segment.recipe) {
    const recipeNode: Node = {
      key: recipeNodeId,
      type: NodeTypes.RECIPE,
      label: segment.recipe.name,
      sourcePosition: Position.Right,
      targetPosition: Position.Left,
      recipe: segment.recipe,
      position: { x: 400, y: 50 + ((1 + segment.combinations.length) / 2) * 100 },
    };
    segmentFlowNodes.push(recipeNode);
    const mergeToRecipeEdge = {
      key: generateId(),
      source: mergeNodeId,
      target: recipeNodeId,
      animated: true,
      type: 'smoothstep',
    };
    segmentFlowEdges.push(mergeToRecipeEdge);
  }
  if (segment.filter) {
    const recipeFromFilter = generateRecipe('Filter as recipe');
    const recipeFromFilterNode: Node = {
      key: recipeNodeId,
      type: NodeTypes.RECIPE,
      label: recipeFromFilter.name,
      sourcePosition: Position.Right,
      targetPosition: Position.Left,
      recipe: recipeFromFilter,
      position: { x: 400, y: 50 + ((1 + segment.combinations.length) / 2) * 100 },
    };
    segmentFlowNodes.push(recipeFromFilterNode);
    const mergeToRecipeEdge = {
      key: generateId(),
      source: mergeNodeId,
      target: recipeNodeId,
      animated: true,
      type: 'smoothstep',
    };
    segmentFlowEdges.push(mergeToRecipeEdge);
  }
  if (segment.targetObject) {
    const targetObjectId = generateId();
    const targetObjectNode: Node = {
      key: targetObjectId,
      type: NodeTypes.OBJECT,
      label: segment.targetObject.name,
      sourcePosition: Position.Right,
      targetPosition: Position.Left,
      object: segment.targetObject,
      position: { x: 500, y: 50 + ((1 + segment.combinations.length) / 2) * 100 },
    };
    segmentFlowNodes.push(targetObjectNode);
    const recipeToTargetObjectEdge = {
      key: generateId(),
      source: recipeNodeId,
      target: targetObjectId,
      animated: true,
      type: 'smoothstep',
    };
    segmentFlowEdges.push(recipeToTargetObjectEdge);
  }

  return {
    key: flowKey ? flowKey : generateId(),
    name: segment.name,
    nodes: segmentFlowNodes,
    edges: segmentFlowEdges,
  };
};

export default generateSegmentFlow;
