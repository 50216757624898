import React from 'react';
import { Form, Radio, Space } from 'antd';

interface OctoRadioGroupProps {
  name: string;
  label?: string | JSX.Element;
  options: {
    value: string | boolean | any;
    label: string | JSX.Element;
  }[];
  direction?: 'horizontal' | 'vertical';
  disabled?: boolean;
  rule?: {
    required: boolean;
    message: string;
  };
  formItemStyle?: React.CSSProperties;
  formItemClassName?: string;
  radioGroupStyle?: React.CSSProperties;
  radioGroupClassName?: string;
  radioButtonStyle?: React.CSSProperties;
  radioButtonsStyles?: React.CSSProperties[];
  radioButtonClassName?: string;
  radioOptionalContent?: JSX.Element;
  value?: string | boolean;
  type: 'default' | 'button';
  onchange?: (value: string | boolean | any) => void;
}

const OctoRadioGroup = ({
  name,
  label,
  options,
  direction,
  disabled,
  rule,
  formItemStyle,
  formItemClassName,
  radioGroupStyle,
  radioGroupClassName,
  radioButtonStyle,
  radioButtonClassName,
  radioOptionalContent,
  radioButtonsStyles,
  value,
  type,
  onchange,
}: OctoRadioGroupProps) => {
  return (
    <Form.Item
      label={label}
      name={name}
      rules={!disabled && rule ? [rule] : []}
      style={formItemStyle}
      className={formItemClassName}
    >
      <Radio.Group
        value={value}
        style={radioGroupStyle}
        className={radioGroupClassName}
        onChange={(e) => onchange && onchange(e.target.value)}
        disabled={disabled}
      >
        {type === 'button' ? (
          options.map((option, index) => (
            <Radio.Button
              key={index}
              value={option.value}
              style={
                radioButtonsStyles
                  ? radioButtonsStyles[index]
                  : radioButtonStyle
                  ? radioButtonStyle
                  : {}
              }
              className={radioButtonClassName}
            >
              {option.label}
            </Radio.Button>
          ))
        ) : (
          //default type
          <Space direction={direction || 'horizontal'}>
            {options.map((option, index) => {
              return (
                <React.Fragment key={index}>
                  <Radio
                    value={option.value}
                    style={radioButtonStyle}
                    className={radioButtonClassName}
                  >
                    {option.label}
                  </Radio>
                  {value === option.value && radioOptionalContent ? radioOptionalContent : null}
                </React.Fragment>
              );
            })}
          </Space>
        )}
      </Radio.Group>
    </Form.Item>
  );
};

export default OctoRadioGroup;
