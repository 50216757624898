// Importing Antd
import { Button, Menu } from 'antd';
import {
  LoginOutlined,
  LogoutOutlined,
  PartitionOutlined,
  PlayCircleOutlined,
  DatabaseOutlined,
  ControlOutlined,
  SyncOutlined,
  RiseOutlined
} from '@ant-design/icons';
import React, { useContext } from 'react';
import { MENU_ROUTES, RoutesMenuProps } from './types';
// Context and handlers
import { ProfileContext, ProfileContextProvider } from '@providers/ProfileContextProvider';
import { AppContext } from '@providers/AppContextProvider';
import { useRedirectionHandler } from '@handlers/redirectionHandler';
import { useTranslation } from 'react-i18next';
const collapsedBtnSettingsIconStyle = { marginTop: '-5px' };
const RoutesMenu = ({
  activeMenuItem,
  isSiderCollapsed,
  setActiveMenuItem,
  getMenuItemStyle,
}: RoutesMenuProps) => {
  const { t } = useTranslation();
  const profileRights = useContext(ProfileContext).rights;
  const recommandationsAccess = profileRights.recommandations.length;
  const connectionsAccess = profileRights.connections.length;
  const sourcesAccess = profileRights.sources.length;
  const destinationsAccess = profileRights.destinations.length;
  const recipesAccess = profileRights.recipes.length;
  const objectsAccess = profileRights.objects.length;
  const syncsAccess = profileRights.syncs.length;
  const segmentsAccess = profileRights.segments.length;
  const audiencesAccess = profileRights.audiences.length;
  const flowsAccess =
    profileRights.sourceFlows.length ||
    profileRights.destinationFlows.length ||
    profileRights.internalFlows.length;
  const appContext = useContext(AppContext);
  const visibleFeatures = appContext.visibleFeatures;
  const redirectHandler = useRedirectionHandler();



  return (
    <Menu theme="dark" defaultSelectedKeys={activeMenuItem ? [activeMenuItem] : []} mode={'inline'}>
      
      {/* {visibleFeatures.includes('recommandations') && recommandationsAccess && appContext.theme.name === 'octolis' ? (
      <Menu.Item key="recommandations" disabled={!recommandationsAccess}>
          <Button
            data-testid={'app-menu-button-recommandations'}
            type={'text'}
            onClick={() => {
              redirectHandler.goToRecommandations();
              setActiveMenuItem(MENU_ROUTES.RECOMMANDATIONS);
            }}
            className={getMenuItemStyle(MENU_ROUTES.RECOMMANDATIONS)}
            icon={<RiseOutlined style={isSiderCollapsed ? collapsedBtnSettingsIconStyle : {}} />}
          >
            {t('Dashboard')}
          </Button>
        </Menu.Item>
      ) : null} */}
      
      {visibleFeatures.includes('connections') && connectionsAccess ? (
        <Menu.Item key="connections" disabled={!connectionsAccess}>
          <Button
            data-testid={'app-menu-button-connections'}
            type={'text'}
            onClick={() => {
              redirectHandler.goToConnections();
              setActiveMenuItem(MENU_ROUTES.CONNECTIONS);
            }}
            className={getMenuItemStyle(MENU_ROUTES.CONNECTIONS)}
            icon={<LoginOutlined style={isSiderCollapsed ? collapsedBtnSettingsIconStyle : {}} />}
          >
            {t('connections')}
          </Button>
        </Menu.Item>
      ) : null}

      {visibleFeatures.includes('audiences') && audiencesAccess ? (
        <Menu.Item key="audiences" disabled={false}>
          <Button
            type={'text'}
            onClick={() => {
              redirectHandler.goToAudiences();
              setActiveMenuItem(MENU_ROUTES.AUDIENCES);
            }}
            className={getMenuItemStyle(MENU_ROUTES.AUDIENCES)}
            icon={<ControlOutlined style={isSiderCollapsed ? collapsedBtnSettingsIconStyle : {}} />}
          >
            {t('audiences')}
          </Button>
        </Menu.Item>
      ) : null}
      {visibleFeatures.includes('syncs') && syncsAccess ? (
        <Menu.Item key="syncs" disabled={!syncsAccess}>
          <Button
            type={'text'}
            onClick={() => {
              redirectHandler.goToSyncs();
              setActiveMenuItem(MENU_ROUTES.SYNCS);
            }}
            className={getMenuItemStyle(MENU_ROUTES.SYNCS)}
            icon={<SyncOutlined style={isSiderCollapsed ? collapsedBtnSettingsIconStyle : {}} />}
          >
            {t('syncs')}
          </Button>
        </Menu.Item>
      ) : null}
      {visibleFeatures.includes('segments') && segmentsAccess ? (
        <Menu.Item key="segments" disabled={!segmentsAccess}>
          <Button
            type={'text'}
            onClick={() => {
              redirectHandler.goToDisplaySegments();
              setActiveMenuItem(MENU_ROUTES.SEGMENTS);
            }}
            className={getMenuItemStyle(MENU_ROUTES.SEGMENTS)}
            icon={<ControlOutlined style={isSiderCollapsed ? collapsedBtnSettingsIconStyle : {}} />}
          >
            {t('Segments')}
          </Button>
        </Menu.Item>
      ) : null}
      {visibleFeatures.includes('sources') && sourcesAccess ? (
        <Menu.Item key="sources" disabled={!sourcesAccess}>
          <Button
            type={'text'}
            onClick={() => {
              redirectHandler.goToSources();
              setActiveMenuItem(MENU_ROUTES.SOURCE);
            }}
            className={getMenuItemStyle(MENU_ROUTES.SOURCE)}
            icon={<LoginOutlined style={isSiderCollapsed ? collapsedBtnSettingsIconStyle : {}} />}
          >
            {t('sources')}
          </Button>
        </Menu.Item>
      ) : null}
      {visibleFeatures.includes('flows') && flowsAccess ? (
        <Menu.Item key="allFlows" disabled={!flowsAccess}>
          <Button
            icon={
              <PartitionOutlined style={isSiderCollapsed ? collapsedBtnSettingsIconStyle : {}} />
            }
            type={'text'}
            onClick={() => {
              redirectHandler.goToFlows();
              setActiveMenuItem(MENU_ROUTES.FLOWS);
            }}
            className={getMenuItemStyle(MENU_ROUTES.FLOWS)}
          >
            {t('flows')}
          </Button>
        </Menu.Item>
      ) : null}
      {visibleFeatures.includes('recipes') && recipesAccess ? (
        <Menu.Item key="all-recipes" disabled={!recipesAccess}>
          <Button
            icon={
              <PlayCircleOutlined style={isSiderCollapsed ? collapsedBtnSettingsIconStyle : {}} />
            }
            type={'text'}
            onClick={() => {
              redirectHandler.goToRecipes();
              setActiveMenuItem(MENU_ROUTES.RECIPES);
            }}
            className={getMenuItemStyle(MENU_ROUTES.RECIPES)}
          >
            {t('recipes')}
          </Button>
        </Menu.Item>
      ) : null}
      {visibleFeatures.includes('objects') && objectsAccess ? (
        <Menu.Item key="allObjects" disabled={!objectsAccess}>
          <Button
            icon={
              <DatabaseOutlined style={isSiderCollapsed ? collapsedBtnSettingsIconStyle : {}} />
            }
            type={'text'}
            onClick={() => {
              redirectHandler.goToObject();
              setActiveMenuItem(MENU_ROUTES.OBJECTS);
            }}
            className={getMenuItemStyle(MENU_ROUTES.OBJECTS)}
          >
            {t('objects')}
          </Button>
        </Menu.Item>
      ) : null}
      {visibleFeatures.includes('destinations') && destinationsAccess ? (
        <Menu.Item key="destinations" disabled={!destinationsAccess}>
          <Button
            type={'text'}
            onClick={() => {
              redirectHandler.goToDestinations();
              setActiveMenuItem(MENU_ROUTES.DESTINATIONS);
            }}
            icon={<LogoutOutlined style={isSiderCollapsed ? collapsedBtnSettingsIconStyle : {}} />}
            className={getMenuItemStyle(MENU_ROUTES.DESTINATIONS)}
          >
            {t('destinations')}
          </Button>
        </Menu.Item>
      ) : null}
    </Menu>
  );
};

export default RoutesMenu;
