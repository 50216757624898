import {
    Audience,
    buildTypesEnum,
    columnDisplayEnum,
    dataTypesEnum,
    keepTypesEnum,
    retrievalModeEnum,
    retrieveFrequencyEnum,
    sourceTypesEnum,
  } from '@features/audiences/types';
  import { businessCategoriesDefaultList } from './businessCategory';
  
  import {
    ordersItemsRecordsGenerator
  } from '@utils/recordsGenerator';
  
import { ordersItemsColumns } from '@data/objectsColumns'

import { generateId, generateRandomInteger, sleep } from '@utils/helpers';
  
  const pad = (num: any, size: any) => {
    num = num.toString();
    while (num.length < size) num = "0" + num;
    return num;
  }

  export const OrdersitemsAudience: Audience[] = [
    {
      key: '1639398565924.014e8rt5ad',
      status: true,
      category: businessCategoriesDefaultList[2],
      filter: {
        key: '1638360408948.06vz6',
        combinationOperator: 'or',
        groupFilters: [
          {
            combinationOperator: 'and',
            conditions: [],
            type: 'property',
            id: '1638361056033.08fcg',
          },
        ],
        object: '1639398651795.0e12p',
      },
      sources: [
        {
          key: '1640912329789.0u73x',
          combinationLevel: 0,
          dataType: dataTypesEnum.UNION,
          sourceType: sourceTypesEnum.SOURCE,
          retrieveFrequency: retrieveFrequencyEnum.EVERY_HOUR,
          deduplicateSettings: {
            keep: keepTypesEnum.RECENT,
            deduplicateRecord: 'false',
            deduplicateColumns: {
              key: '1639398562937.02j7w',
              combinationOperator: 'or',
              columnGroups: [],
            },
          },
          audienceSource: {
            key: '1639398565924.0p5xz',
            connection: {
              key: 'connection7',
              name: 'Magento',
              usedAs: ['destination'],
              connector: {
                key: 'connector8',
                title: 'Magento',
                description: 'This is some description of the destination connector',
                category: 'api',
                logo: '/icons/logos/magento.png',
                expandedLogo: '/icons/magento-expanded.png',
              },
              credentials: {
                url: 'customdomain.magento.com',
                apiKey: 'Kq5-SD$O5qsd"79qsdLSdf*/sd985654qs',
              },
            },
            pointer: 'Available integrations/All orders',
            object: {
              updatedAtId: '1639398585186.0dv04',
              name: 'object_Available integrations/All orders',
              tableName: 'Available integrations/All orders',
              category: 'orders',
              schema: [
                {
                  id: '1639398585185.0m2el',
                  columnName: 'userId',
                  columnType: 'string',
                  required: false,
                  description: '',
                  category: 'no category',
                  position: 0,
                },
                {
                  id: '1639398585185.0wdo8',
                  columnName: 'firstName',
                  columnType: 'string',
                  required: false,
                  description: '',
                  category: 'no category',
                  position: 1,
                },
                {
                  id: '1639398585185.0lspq',
                  columnName: 'lastName',
                  columnType: 'string',
                  required: false,
                  description: '',
                  category: 'no category',
                  position: 2,
                },
                {
                  id: '1639398585185.050ra',
                  columnName: 'fullName',
                  columnType: 'string',
                  required: false,
                  description: '',
                  category: 'no category',
                  position: 3,
                },
                {
                  id: '1639398585185.0nmad',
                  columnName: 'birthdate',
                  columnType: 'date',
                  required: false,
                  description: '',
                  category: 'no category',
                  position: 4,
                },
                {
                  id: '1639398585185.0wuh6',
                  columnName: 'language',
                  columnType: 'string',
                  required: false,
                  description: '',
                  category: 'no category',
                  position: 5,
                },
                {
                  id: '1639398585185.0xyxc',
                  columnName: 'gender',
                  columnType: 'string',
                  required: false,
                  description: '',
                  category: 'no category',
                  position: 6,
                },
                {
                  id: '1639398585185.0c1x2',
                  columnName: 'civility',
                  columnType: 'string',
                  required: false,
                  description: '',
                  category: 'no category',
                  position: 7,
                },
                {
                  id: '1639398585185.0tpk3',
                  columnName: 'address',
                  columnType: 'string',
                  required: false,
                  description: '',
                  category: 'no category',
                  position: 8,
                },
                {
                  id: '1639398585185.0blh8',
                  columnName: 'postalCode',
                  columnType: 'string',
                  required: false,
                  description: '',
                  category: 'no category',
                  position: 9,
                },
                {
                  id: '1639398585185.0ttyp',
                  columnName: 'city',
                  columnType: 'string',
                  required: false,
                  description: '',
                  category: 'no category',
                  position: 10,
                },
                {
                  id: '1639398585185.0an89',
                  columnName: 'region',
                  columnType: 'string',
                  required: false,
                  description: '',
                  category: 'no category',
                  position: 11,
                },
                {
                  id: '1639398585185.0bvxa',
                  columnName: 'countryName',
                  columnType: 'string',
                  required: false,
                  description: '',
                  category: 'no category',
                  position: 12,
                },
                {
                  id: '1639398585185.0nhs9',
                  columnName: 'countryCode',
                  columnType: 'string',
                  required: false,
                  description: '',
                  category: 'no category',
                  position: 13,
                },
                {
                  id: '1639398585185.0dggi',
                  columnName: 'email',
                  columnType: 'string',
                  required: false,
                  description: '',
                  category: 'no category',
                  position: 14,
                },
                {
                  id: '1639398585185.0fo4u',
                  columnName: 'emailFormat',
                  columnType: 'string',
                  required: false,
                  description: '',
                  category: 'no category',
                  position: 15,
                },
                {
                  id: '1639398585186.0hoft',
                  columnName: 'emailDomain',
                  columnType: 'string',
                  required: false,
                  description: '',
                  category: 'no category',
                  position: 16,
                },
                {
                  id: '1639398585186.0u8ir',
                  columnName: 'emailValidity',
                  columnType: 'no type',
                  required: false,
                  description: '',
                  category: 'no category',
                  position: 17,
                },
                {
                  id: '1639398585186.051wn',
                  columnName: 'emailValidityReason',
                  columnType: 'no type',
                  required: false,
                  description: '',
                  category: 'no category',
                  position: 18,
                },
                {
                  id: '1639398585186.0gu87',
                  columnName: 'phoneMobile',
                  columnType: 'string',
                  required: false,
                  description: '',
                  category: 'no category',
                  position: 19,
                },
                {
                  id: '1639398585186.0zxuj',
                  columnName: 'phoneLandline',
                  columnType: 'number',
                  required: false,
                  description: '',
                  category: 'no category',
                  position: 20,
                },
                {
                  id: '1639398585186.0mgy9',
                  columnName: 'phone',
                  columnType: 'number',
                  required: false,
                  description: '',
                  category: 'no category',
                  position: 21,
                },
                {
                  id: '1639398585186.0rfe9',
                  columnName: 'phoneValidity',
                  columnType: 'no type',
                  required: false,
                  description: '',
                  category: 'no category',
                  position: 22,
                },
                {
                  id: '1639398585186.033d1',
                  columnName: 'phoneValidityReason',
                  columnType: 'no type',
                  required: false,
                  description: '',
                  category: 'no category',
                  position: 23,
                },
                {
                  id: '1639398585186.0ahfm',
                  columnName: 'phoneCountry',
                  columnType: 'string',
                  required: false,
                  description: '',
                  category: 'no category',
                  position: 24,
                },
                {
                  id: '1639398585186.0mvoh',
                  columnName: 'phoneNormalized',
                  columnType: 'no type',
                  required: false,
                  description: '',
                  category: 'no category',
                  position: 25,
                },
                {
                  id: '1639398585186.08127',
                  columnName: 'phoneType',
                  columnType: 'no type',
                  required: false,
                  description: '',
                  category: 'no category',
                  position: 26,
                },
                {
                  id: '1639398585186.0676h',
                  columnName: 'consentEmail',
                  columnType: 'string',
                  required: false,
                  description: '',
                  category: 'no category',
                  position: 27,
                },
                {
                  id: '1639398585186.0psvl',
                  columnName: 'consentEmailUpdatedAt',
                  columnType: 'string',
                  required: false,
                  description: '',
                  category: 'no category',
                  position: 28,
                },
                {
                  id: '1639398585186.0n45k',
                  columnName: 'consentSms',
                  columnType: 'string',
                  required: false,
                  description: '',
                  category: 'no category',
                  position: 29,
                },
                {
                  id: '1639398585186.06wqf',
                  columnName: 'consentSmsUpdatedAt',
                  columnType: 'string',
                  required: false,
                  description: '',
                  category: 'no category',
                  position: 30,
                },
                {
                  id: '1639398585186.0snkj',
                  columnName: 'linkedin',
                  columnType: 'no type',
                  required: false,
                  description: '',
                  category: 'no category',
                  position: 31,
                },
                {
                  id: '1639398585186.0q4na',
                  columnName: 'website',
                  columnType: 'no type',
                  required: false,
                  description: '',
                  category: 'no category',
                  position: 32,
                },
                {
                  id: '1639398585186.0p8as',
                  columnName: 'companyName',
                  columnType: 'string',
                  required: false,
                  description: '',
                  category: 'no category',
                  position: 33,
                },
                {
                  id: '1639398585186.02x5l',
                  columnName: 'jobTitle',
                  columnType: 'string',
                  required: false,
                  description: '',
                  category: 'no category',
                  position: 34,
                },
                {
                  id: '1639398585186.0i0dw',
                  columnName: 'createdAt',
                  columnType: 'date',
                  required: false,
                  description: '',
                  category: 'no category',
                  position: 35,
                },
                {
                  id: '1639398585186.0dv04',
                  columnName: 'updatedAt',
                  columnType: 'date',
                  required: false,
                  description: '',
                  category: 'no category',
                  position: 36,
                },
              ],
              primaryKeys: ['1639398585185.0m2el'],
              recordsNumber: 12,
              pointer: 'Available integrations/All orders',
              records: [
                {
                  userId: '1639398585177.0mml3',
                  firstName: 'Donny',
                  lastName: 'Labadie',
                  fullName: 'Donny Labadie',
                  birthdate: '2021-02-23T14:32:36.386Z',
                  language: 'TD',
                  gender: 'Intersex man',
                  civility: 'Mr.',
                  address: '0126 Ondricka Plaza',
                  postalCode: '38093-0009',
                  city: 'Filomenaville',
                  region: '',
                  countryName: 'Martinique',
                  countryCode: 'AD',
                  email: 'Damaris.Schamberger@hotmail.com',
                  emailFormat: '',
                  emailDomain: 'sharon.name',
                  emailValidity: '',
                  emailValidityReason: '',
                  phoneMobile: '(697) 757-9602 x56462',
                  phoneLandline: '1-761-411-4669',
                  phone: '1-995-328-0238 x9768',
                  phoneValidity: '',
                  phoneValidityReason: '',
                  phoneCountry: 'SB',
                  phoneNormalized: '',
                  phoneType: '',
                  consentEmail: false,
                  consentEmailUpdatedAt: '2021-04-26T17:07:29.904Z',
                  consentSms: true,
                  consentSmsUpdatedAt: '2021-06-12T15:08:16.847Z',
                  linkedin: '',
                  website: '',
                  companyName: 'Ernser - Herzog',
                  jobTitle: 'Lead Group Coordinator',
                  createdAt: '2021-11-12T16:54:20.102Z',
                  updatedAt: '2021-10-01T04:14:06.861Z',
                },
                {
                  userId: '1639398585181.0rpq3',
                  firstName: 'Archibald',
                  lastName: 'Hintz',
                  fullName: 'Archibald Hintz',
                  birthdate: '2021-01-26T09:51:41.278Z',
                  language: 'MP',
                  gender: 'Transexual Female',
                  civility: 'Ms.',
                  address: '08805 Zulauf Bridge',
                  postalCode: '13824',
                  city: 'Port Merrittside',
                  region: '',
                  countryName: 'United Arab Emirates',
                  countryCode: 'SD',
                  email: 'Lester_Hane@gmail.com',
                  emailFormat: '',
                  emailDomain: 'maxine.biz',
                  emailValidity: '',
                  emailValidityReason: '',
                  phoneMobile: '(562) 798-5051 x637',
                  phoneLandline: '783.628.6850 x03178',
                  phone: '(274) 842-3032 x1345',
                  phoneValidity: '',
                  phoneValidityReason: '',
                  phoneCountry: 'IE',
                  phoneNormalized: '',
                  phoneType: '',
                  consentEmail: false,
                  consentEmailUpdatedAt: '2021-12-11T04:07:49.805Z',
                  consentSms: false,
                  consentSmsUpdatedAt: '2021-01-03T09:15:55.891Z',
                  linkedin: '',
                  website: '',
                  companyName: 'Crist, Olson and Durgan',
                  jobTitle: 'District Identity Architect',
                  createdAt: '2021-03-16T02:26:34.034Z',
                  updatedAt: '2021-04-18T19:31:11.549Z',
                },
                {
                  userId: '1639398585181.013bs',
                  firstName: 'Gladys',
                  lastName: 'Walsh',
                  fullName: 'Gladys Walsh',
                  birthdate: '2021-09-08T08:15:15.251Z',
                  language: 'CN',
                  gender: 'Cis Female',
                  civility: 'Mr.',
                  address: '1237 Webster Flat',
                  postalCode: '46469',
                  city: 'Carson',
                  region: '',
                  countryName: 'Tanzania',
                  countryCode: 'BM',
                  email: 'Bria52@gmail.com',
                  emailFormat: '',
                  emailDomain: 'estevan.net',
                  emailValidity: '',
                  emailValidityReason: '',
                  phoneMobile: '559.510.8002',
                  phoneLandline: '(754) 578-0773 x917',
                  phone: '(409) 769-5345 x95354',
                  phoneValidity: '',
                  phoneValidityReason: '',
                  phoneCountry: 'CV',
                  phoneNormalized: '',
                  phoneType: '',
                  consentEmail: false,
                  consentEmailUpdatedAt: '2021-02-15T08:57:51.651Z',
                  consentSms: true,
                  consentSmsUpdatedAt: '2021-11-04T03:38:09.160Z',
                  linkedin: '',
                  website: '',
                  companyName: 'Cummings - Heidenreich',
                  jobTitle: 'International Creative Executive',
                  createdAt: '2021-12-07T09:51:17.959Z',
                  updatedAt: '2021-07-22T04:01:23.350Z',
                },
                {
                  userId: '1639398585182.07by4',
                  firstName: 'Octavia',
                  lastName: 'Haag',
                  fullName: 'Octavia Haag',
                  birthdate: '2021-08-18T20:13:14.417Z',
                  language: 'VA',
                  gender: 'F2M',
                  civility: 'Mr.',
                  address: '3790 Nicole Bypass',
                  postalCode: '62478-6301',
                  city: 'Port Samson',
                  region: '',
                  countryName: 'Iran',
                  countryCode: 'SM',
                  email: 'Marquis15@gmail.com',
                  emailFormat: '',
                  emailDomain: 'emmitt.com',
                  emailValidity: '',
                  emailValidityReason: '',
                  phoneMobile: '(834) 964-6173 x806',
                  phoneLandline: '1-370-407-3962 x0296',
                  phone: '705-466-8741 x486',
                  phoneValidity: '',
                  phoneValidityReason: '',
                  phoneCountry: 'TR',
                  phoneNormalized: '',
                  phoneType: '',
                  consentEmail: true,
                  consentEmailUpdatedAt: '2021-08-17T04:40:35.766Z',
                  consentSms: true,
                  consentSmsUpdatedAt: '2021-08-17T04:00:19.136Z',
                  linkedin: '',
                  website: '',
                  companyName: 'Bins, Pagac and Rippin',
                  jobTitle: 'Direct Brand Specialist',
                  createdAt: '2021-03-10T16:32:19.055Z',
                  updatedAt: '2021-06-23T22:52:45.628Z',
                },
                {
                  userId: '1639398585182.0s31v',
                  firstName: 'Tomas',
                  lastName: 'Marquardt',
                  fullName: 'Tomas Marquardt',
                  birthdate: '2021-10-21T10:56:43.936Z',
                  language: 'CK',
                  gender: 'Two-spirit',
                  civility: 'Dr.',
                  address: '542 Zieme Fields',
                  postalCode: '55782-4477',
                  city: 'Amarillo',
                  region: '',
                  countryName: 'Croatia',
                  countryCode: 'HM',
                  email: 'Idella13@hotmail.com',
                  emailFormat: '',
                  emailDomain: 'kitty.org',
                  emailValidity: '',
                  emailValidityReason: '',
                  phoneMobile: '945.337.0329',
                  phoneLandline: '1-889-909-8951',
                  phone: '528.332.9562',
                  phoneValidity: '',
                  phoneValidityReason: '',
                  phoneCountry: 'NC',
                  phoneNormalized: '',
                  phoneType: '',
                  consentEmail: true,
                  consentEmailUpdatedAt: '2021-07-22T16:30:39.842Z',
                  consentSms: false,
                  consentSmsUpdatedAt: '2021-06-22T19:30:04.058Z',
                  linkedin: '',
                  website: '',
                  companyName: 'Lemke, Torphy and Wolff',
                  jobTitle: 'Senior Infrastructure Agent',
                  createdAt: '2021-06-30T11:17:40.554Z',
                  updatedAt: '2021-01-16T21:30:44.401Z',
                },
                {
                  userId: '1639398585183.0nv5w',
                  firstName: 'Javon',
                  lastName: 'Steuber',
                  fullName: 'Javon Steuber',
                  birthdate: '2020-12-25T08:34:31.003Z',
                  language: 'AL',
                  gender: 'Trans*Male',
                  civility: 'Mrs.',
                  address: '578 Erica Neck',
                  postalCode: '87711',
                  city: 'Lake Casimerview',
                  region: '',
                  countryName: 'Guatemala',
                  countryCode: 'SN',
                  email: 'Jaqueline_Gusikowski83@hotmail.com',
                  emailFormat: '',
                  emailDomain: 'aubrey.net',
                  emailValidity: '',
                  emailValidityReason: '',
                  phoneMobile: '231.863.4123',
                  phoneLandline: '492.767.3258 x0038',
                  phone: '(547) 543-8960',
                  phoneValidity: '',
                  phoneValidityReason: '',
                  phoneCountry: 'BR',
                  phoneNormalized: '',
                  phoneType: '',
                  consentEmail: true,
                  consentEmailUpdatedAt: '2021-09-21T16:59:20.380Z',
                  consentSms: true,
                  consentSmsUpdatedAt: '2021-11-14T23:06:16.572Z',
                  linkedin: '',
                  website: '',
                  companyName: 'Davis Group',
                  jobTitle: 'Human Configuration Architect',
                  createdAt: '2021-08-17T21:02:51.352Z',
                  updatedAt: '2021-12-02T13:34:07.939Z',
                },
                {
                  userId: '1639398585183.030hf',
                  firstName: 'Aletha',
                  lastName: 'Gibson',
                  fullName: 'Aletha Gibson',
                  birthdate: '2021-09-27T06:06:33.014Z',
                  language: 'MF',
                  gender: 'Female to Male',
                  civility: 'Dr.',
                  address: '2412 Crist Forest',
                  postalCode: '62827-0024',
                  city: 'North Port',
                  region: '',
                  countryName: 'Fiji',
                  countryCode: 'CV',
                  email: 'Aracely57@gmail.com',
                  emailFormat: '',
                  emailDomain: 'susanna.name',
                  emailValidity: '',
                  emailValidityReason: '',
                  phoneMobile: '979-865-8961 x84183',
                  phoneLandline: '712.611.4330',
                  phone: '(606) 314-8933 x53572',
                  phoneValidity: '',
                  phoneValidityReason: '',
                  phoneCountry: 'WS',
                  phoneNormalized: '',
                  phoneType: '',
                  consentEmail: false,
                  consentEmailUpdatedAt: '2021-02-16T14:13:18.857Z',
                  consentSms: true,
                  consentSmsUpdatedAt: '2021-07-29T05:15:01.682Z',
                  linkedin: '',
                  website: '',
                  companyName: "O'Reilly LLC",
                  jobTitle: 'Investor Implementation Orchestrator',
                  createdAt: '2021-10-08T21:38:55.001Z',
                  updatedAt: '2020-12-19T17:49:27.173Z',
                },
                {
                  userId: '1639398585183.0w8sc',
                  firstName: 'Kyla',
                  lastName: 'Considine',
                  fullName: 'Kyla Considine',
                  birthdate: '2021-02-28T09:10:21.224Z',
                  language: 'RU',
                  gender: 'Transexual Female',
                  civility: 'Miss',
                  address: '33214 Queen Locks',
                  postalCode: '66806-5950',
                  city: 'Farmington',
                  region: '',
                  countryName: 'Western Sahara',
                  countryCode: 'AT',
                  email: 'Floy_Mraz@yahoo.com',
                  emailFormat: '',
                  emailDomain: 'emmanuel.net',
                  emailValidity: '',
                  emailValidityReason: '',
                  phoneMobile: '304-950-8493',
                  phoneLandline: '1-255-502-8030 x973',
                  phone: '651.739.6455',
                  phoneValidity: '',
                  phoneValidityReason: '',
                  phoneCountry: 'TV',
                  phoneNormalized: '',
                  phoneType: '',
                  consentEmail: false,
                  consentEmailUpdatedAt: '2021-07-17T23:25:05.410Z',
                  consentSms: true,
                  consentSmsUpdatedAt: '2020-12-21T03:27:16.528Z',
                  linkedin: '',
                  website: '',
                  companyName: 'Barton LLC',
                  jobTitle: 'International Security Liaison',
                  createdAt: '2021-07-15T16:49:38.632Z',
                  updatedAt: '2021-09-05T19:00:40.009Z',
                },
                {
                  userId: '1639398585184.01x27',
                  firstName: 'Hector',
                  lastName: 'Schultz',
                  fullName: 'Hector Schultz',
                  birthdate: '2021-02-13T01:09:14.552Z',
                  language: 'KE',
                  gender: 'Intersex person',
                  civility: 'Miss',
                  address: '5728 Schmitt Grove',
                  postalCode: '95189',
                  city: 'Camdenhaven',
                  region: '',
                  countryName: 'Turkey',
                  countryCode: 'MQ',
                  email: 'Dena64@yahoo.com',
                  emailFormat: '',
                  emailDomain: 'blanca.org',
                  emailValidity: '',
                  emailValidityReason: '',
                  phoneMobile: '552-776-0405 x862',
                  phoneLandline: '(718) 419-7658',
                  phone: '945.340.4039 x08131',
                  phoneValidity: '',
                  phoneValidityReason: '',
                  phoneCountry: 'TZ',
                  phoneNormalized: '',
                  phoneType: '',
                  consentEmail: true,
                  consentEmailUpdatedAt: '2021-11-27T10:41:23.629Z',
                  consentSms: true,
                  consentSmsUpdatedAt: '2020-12-29T23:46:36.745Z',
                  linkedin: '',
                  website: '',
                  companyName: 'Roberts Inc',
                  jobTitle: 'Chief Factors Architect',
                  createdAt: '2021-12-13T04:53:03.356Z',
                  updatedAt: '2021-04-03T12:27:18.885Z',
                },
                {
                  userId: '1639398585184.0t5v0',
                  firstName: 'Rhiannon',
                  lastName: 'Lowe',
                  fullName: 'Rhiannon Lowe',
                  birthdate: '2021-02-05T20:52:08.701Z',
                  language: 'BB',
                  gender: 'Gender neutral',
                  civility: 'Ms.',
                  address: '51160 Wiegand Alley',
                  postalCode: '23540-2423',
                  city: 'South Skylar',
                  region: '',
                  countryName: 'Cuba',
                  countryCode: 'NA',
                  email: 'Zander_Schuppe@yahoo.com',
                  emailFormat: '',
                  emailDomain: 'kaitlyn.name',
                  emailValidity: '',
                  emailValidityReason: '',
                  phoneMobile: '1-953-816-7077 x293',
                  phoneLandline: '588-554-6388 x844',
                  phone: '451-608-5149',
                  phoneValidity: '',
                  phoneValidityReason: '',
                  phoneCountry: 'TH',
                  phoneNormalized: '',
                  phoneType: '',
                  consentEmail: false,
                  consentEmailUpdatedAt: '2021-12-11T19:57:04.021Z',
                  consentSms: true,
                  consentSmsUpdatedAt: '2021-11-01T07:38:33.222Z',
                  linkedin: '',
                  website: '',
                  companyName: 'Heller, Parker and Sanford',
                  jobTitle: 'National Applications Representative',
                  createdAt: '2021-08-14T22:50:30.094Z',
                  updatedAt: '2020-12-21T08:49:55.154Z',
                },
                {
                  userId: '1639398585185.0vd4f',
                  firstName: 'Anastasia',
                  lastName: 'Schmitt',
                  fullName: 'Anastasia Schmitt',
                  birthdate: '2021-07-08T20:07:27.701Z',
                  language: 'MA',
                  gender: 'Man',
                  civility: 'Mrs.',
                  address: '828 Olaf Prairie',
                  postalCode: '51755-0371',
                  city: 'East Rosariomouth',
                  region: '',
                  countryName: 'Estonia',
                  countryCode: 'GS',
                  email: 'Percival7@hotmail.com',
                  emailFormat: '',
                  emailDomain: 'walter.com',
                  emailValidity: '',
                  emailValidityReason: '',
                  phoneMobile: '(504) 229-7865 x65542',
                  phoneLandline: '(618) 231-8887 x641',
                  phone: '1-618-723-4528 x18380',
                  phoneValidity: '',
                  phoneValidityReason: '',
                  phoneCountry: 'BZ',
                  phoneNormalized: '',
                  phoneType: '',
                  consentEmail: true,
                  consentEmailUpdatedAt: '2021-06-23T03:45:01.139Z',
                  consentSms: false,
                  consentSmsUpdatedAt: '2020-12-24T23:11:30.906Z',
                  linkedin: '',
                  website: '',
                  companyName: 'Wunsch, Bruen and Yundt',
                  jobTitle: 'Investor Solutions Coordinator',
                  createdAt: '2021-09-19T20:32:43.832Z',
                  updatedAt: '2021-08-21T22:12:26.324Z',
                },
                {
                  userId: '1639398585185.05aks',
                  firstName: 'Cooper',
                  lastName: 'Franecki',
                  fullName: 'Cooper Franecki',
                  birthdate: '2021-07-18T02:26:45.386Z',
                  language: 'MV',
                  gender: 'Non-binary',
                  civility: 'Mrs.',
                  address: '2342 Blaise Villages',
                  postalCode: '70178',
                  city: 'Wheaton',
                  region: '',
                  countryName: 'Zimbabwe',
                  countryCode: 'MT',
                  email: 'Cleveland95@yahoo.com',
                  emailFormat: '',
                  emailDomain: 'madelyn.biz',
                  emailValidity: '',
                  emailValidityReason: '',
                  phoneMobile: '(351) 292-9524 x15865',
                  phoneLandline: '220.453.0260 x850',
                  phone: '(346) 368-1019 x28506',
                  phoneValidity: '',
                  phoneValidityReason: '',
                  phoneCountry: 'KN',
                  phoneNormalized: '',
                  phoneType: '',
                  consentEmail: false,
                  consentEmailUpdatedAt: '2021-04-10T02:06:17.704Z',
                  consentSms: false,
                  consentSmsUpdatedAt: '2020-12-19T20:59:27.529Z',
                  linkedin: '',
                  website: '',
                  companyName: 'Gislason, Reichert and Cremin',
                  jobTitle: 'District Communications Planner',
                  createdAt: '2021-01-05T09:51:51.358Z',
                  updatedAt: '2021-01-07T10:58:59.082Z',
                },
              ],
              key: '1639398585186.07leh',
              created: new Date(),
            },
            name: 'Eshop',
            category: {
              key: '1',
              name: 'orders',
              columns: [
                {
                  id: 'col1',
                  columnName: 'userId',
                  columnType: 'string',
                  required: true,
                  category: 'string',
                  description: 'this is a description of the column',
                  position: 1,
                },
                {
                  id: '1639398562939.08eow',
                  columnName: 'firstName',
                  columnType: 'string',
                  required: true,
                  category: 'first_name',
                  description: 'this is a description of the column',
                  position: 1,
                },
                {
                  id: '1639398562939.0vge6',
                  columnName: 'lastName',
                  columnType: 'string',
                  required: false,
                  category: 'string',
                  description: 'this is a description of the column',
                  position: 2,
                },
                {
                  id: '1639398562939.06v4h',
                  columnName: 'fullName',
                  columnType: 'string',
                  required: false,
                  category: 'string',
                  description: 'this is a description of the column',
                  position: 3,
                },
                {
                  id: '1639398562939.0mnpp',
                  columnName: 'birthdate',
                  columnType: 'date',
                  required: false,
                  category: 'date',
                  description: 'this is a description of the column',
                  position: 4,
                },
                {
                  id: '1639398562939.09zbe',
                  columnName: 'language',
                  columnType: 'string',
                  required: false,
                  category: 'string',
                  description: 'this is a description of the column',
                  position: 5,
                },
                {
                  id: '1639398562939.0rfns',
                  columnName: 'gender',
                  columnType: 'string',
                  required: false,
                  category: 'string',
                  description: 'this is a description of the column',
                  position: 6,
                },
                {
                  id: '1639398562939.0cw4q',
                  columnName: 'civility',
                  columnType: 'string',
                  required: false,
                  category: 'string',
                  description: 'this is a description of the column',
                  position: 7,
                },
                {
                  id: '1639398562939.0yq4e',
                  columnName: 'address',
                  columnType: 'string',
                  required: false,
                  category: 'string',
                  description: 'this is a description of the column',
                  position: 8,
                },
                {
                  id: '1639398562939.0u1fn',
                  columnName: 'postalCode',
                  columnType: 'string',
                  required: false,
                  category: 'string',
                  description: 'this is a description of the column',
                  position: 9,
                },
                {
                  id: '1639398562939.0hchv',
                  columnName: 'city',
                  columnType: 'string',
                  required: false,
                  category: 'string',
                  description: 'this is a description of the column',
                  position: 10,
                },
                {
                  id: '1639398562939.0hrmt',
                  columnName: 'region',
                  columnType: 'string',
                  required: false,
                  category: 'string',
                  description: 'this is a description of the column',
                  position: 11,
                },
                {
                  id: '1639398562939.0ukiz',
                  columnName: 'countryName',
                  columnType: 'string',
                  required: false,
                  category: 'string',
                  description: 'this is a description of the column',
                  position: 12,
                },
                {
                  id: '1639398562939.03t2c',
                  columnName: 'countryCode',
                  columnType: 'string',
                  required: false,
                  category: 'string',
                  description: 'this is a description of the column',
                  position: 13,
                },
                {
                  id: '1639398562939.0v0s4',
                  columnName: 'email',
                  columnType: 'string',
                  required: true,
                  category: 'email',
                  description: 'this is a description of the column',
                  position: 14,
                },
                {
                  id: '1639398562939.0jtac',
                  columnName: 'emailFormat',
                  columnType: 'string',
                  required: false,
                  category: 'string',
                  description: 'this is a description of the column',
                  position: 15,
                },
                {
                  id: '1639398562939.02tve',
                  columnName: 'emailDomain',
                  columnType: 'string',
                  required: false,
                  category: 'string',
                  description: 'this is a description of the column',
                  position: 16,
                },
                {
                  id: '1639398562939.0x2pa',
                  columnName: 'emailValidity',
                  columnType: 'string',
                  required: false,
                  category: 'string',
                  description: 'this is a description of the column',
                  position: 17,
                },
                {
                  id: '1639398562939.0cjt9',
                  columnName: 'emailValidityReason',
                  columnType: 'string',
                  required: false,
                  category: 'string',
                  description: 'this is a description of the column',
                  position: 18,
                },
                {
                  id: '1639398562939.0djjq',
                  columnName: 'phoneMobile',
                  columnType: 'string',
                  required: false,
                  category: 'phone',
                  description: 'this is a description of the column',
                  position: 19,
                },
                {
                  id: '1639398562939.0omwk',
                  columnName: 'phoneLandline',
                  columnType: 'string',
                  required: false,
                  category: 'phone',
                  description: 'this is a description of the column',
                  position: 20,
                },
                {
                  id: '1639398562939.0lt7q',
                  columnName: 'phone',
                  columnType: 'string',
                  required: false,
                  category: 'phone',
                  description: 'this is a description of the column',
                  position: 21,
                },
                {
                  id: '1639398562939.0z5b4',
                  columnName: 'phoneValidity',
                  columnType: 'string',
                  required: false,
                  category: 'string',
                  description: 'this is a description of the column',
                  position: 22,
                },
                {
                  id: '1639398562939.0ie2i',
                  columnName: 'phoneValidityReason',
                  columnType: 'string',
                  required: false,
                  category: 'string',
                  description: 'this is a description of the column',
                  position: 23,
                },
                {
                  id: '1639398562939.0ics0',
                  columnName: 'phoneCountry',
                  columnType: 'string',
                  required: false,
                  category: 'string',
                  description: 'this is a description of the column',
                  position: 24,
                },
                {
                  id: '1639398562939.0u1dg',
                  columnName: 'phoneNormalized',
                  columnType: 'string',
                  required: false,
                  category: 'string',
                  description: 'this is a description of the column',
                  position: 25,
                },
                {
                  id: '1639398562939.00cg7',
                  columnName: 'phoneType',
                  columnType: 'string',
                  required: false,
                  category: 'string',
                  description: 'this is a description of the column',
                  position: 26,
                },
                {
                  id: '1639398562939.04xtt',
                  columnName: 'consentEmail',
                  columnType: 'boolean',
                  required: false,
                  category: 'boolean',
                  description: 'this is a description of the column',
                  position: 27,
                },
                {
                  id: '1639398562939.0wzkg',
                  columnName: 'consentEmailUpdatedAt',
                  columnType: 'date',
                  required: false,
                  category: 'date',
                  description: 'this is a description of the column',
                  position: 28,
                },
                {
                  id: '1639398562939.0xyeh',
                  columnName: 'consentSms',
                  columnType: 'boolean',
                  required: false,
                  category: 'boolean',
                  description: 'this is a description of the column',
                  position: 29,
                },
                {
                  id: '1639398562939.05dil',
                  columnName: 'consent_sms_updated_at',
                  columnType: 'date',
                  required: false,
                  category: 'date',
                  description: 'this is a description of the column',
                  position: 30,
                },
                {
                  id: '1639398562939.0g3yf',
                  columnName: 'linkedin',
                  columnType: 'string',
                  required: false,
                  category: 'string',
                  description: 'this is a description of the column',
                  position: 31,
                },
                {
                  id: '1639398562939.0qllt',
                  columnName: 'website',
                  columnType: 'string',
                  required: false,
                  category: 'string',
                  description: 'this is a description of the column',
                  position: 32,
                },
                {
                  id: '1639398562939.06jlr',
                  columnName: 'companyName',
                  columnType: 'string',
                  required: false,
                  category: 'string',
                  description: 'this is a description of the column',
                  position: 33,
                },
                {
                  id: '1639398562939.07qat',
                  columnName: 'jobTitle',
                  columnType: 'string',
                  required: false,
                  category: 'string',
                  description: 'this is a description of the column',
                  position: 34,
                },
                {
                  id: '1639398562939.0z8rc',
                  columnName: 'createdAt',
                  columnType: 'date',
                  required: false,
                  category: 'date',
                  description: 'this is a description of the column',
                  position: 35,
                },
                {
                  id: '1639398562939.01kgy',
                  columnName: 'updatedAt',
                  columnType: 'date',
                  required: false,
                  category: 'date',
                  description: 'this is a description of the column',
                  position: 36,
                },
              ],
            },
          },
          selectedColumns: [
            {
              id: '1639398585185.0m2el',
              columnName: 'userId',
              columnType: 'string',
              required: false,
              description: '',
              category: 'no category',
              position: 0,
            },
            {
              id: '1639398585186.0dv04',
              columnName: 'updatedAt',
              columnType: 'date',
              required: false,
              description: '',
              category: 'no category',
              position: 36,
            },
            {
              id: '1639398585185.0wdo8',
              columnName: 'firstName',
              columnType: 'string',
              required: false,
              description: '',
              category: 'no category',
              position: 1,
            },
            {
              id: '1639398585185.0lspq',
              columnName: 'lastName',
              columnType: 'string',
              required: false,
              description: '',
              category: 'no category',
              position: 2,
            },
            {
              id: '1639398585185.050ra',
              columnName: 'fullName',
              columnType: 'string',
              required: false,
              description: '',
              category: 'no category',
              position: 3,
            },
          ],
          joinByColumns: [
            {
              key: '1639398562937.08mj8',
            },
          ],
        },
        {
          key: '1242346767889.0u73x',
          combinationLevel: 1,
          dataType: dataTypesEnum.JOIN,
          sourceType: sourceTypesEnum.SOURCE,
          retrieveFrequency: retrieveFrequencyEnum.EVERY_HOUR,
          deduplicateSettings: {
            keep: keepTypesEnum.RECENT,
            deduplicateRecord: 'false',
            deduplicateColumns: {
              key: '1639398562937.02j7w',
              combinationOperator: 'or',
              columnGroups: [],
            },
          },
          audienceSource: {
            key: '1639398605906.03t4n',
            connection: {
              key: 'connection7',
              name: 'Snowflake',
              usedAs: ['destination'],
              connector: {
                key: 'connector8',
                title: 'Snowflake',
                description: 'This is some description of the destination connector',
                category: 'api',
                logo: '/icons/logos/snowflake.png',
                expandedLogo: '/icons/snowflake-expanded.png',
              },
              credentials: {
                url: 'customdomain.snowflake.com',
                apiKey: 'Kq5-SD$O5qsd"79qsdLSdf*/sd985654qs',
              },
            },
            pointer: 'Available integrations/All orders',
            object: {
              updatedAtId: '1639398628511.0yo2q',
              name: 'object_Available integrations/All orders',
              tableName: 'Available integrations/All orders',
              category: 'orders',
              schema: [
                {
                  id: '1639398628511.0624i',
                  columnName: 'userId',
                  columnType: 'string',
                  required: false,
                  description: '',
                  category: 'no category',
                  position: 0,
                },
                {
                  id: '1639398628511.0a5zp',
                  columnName: 'firstName',
                  columnType: 'string',
                  required: false,
                  description: '',
                  category: 'no category',
                  position: 1,
                },
                {
                  id: '1639398628511.0t4sq',
                  columnName: 'lastName',
                  columnType: 'string',
                  required: false,
                  description: '',
                  category: 'no category',
                  position: 2,
                },
                {
                  id: '1639398628511.0r0dt',
                  columnName: 'fullName',
                  columnType: 'string',
                  required: false,
                  description: '',
                  category: 'no category',
                  position: 3,
                },
                {
                  id: '1639398628511.0lnij',
                  columnName: 'birthdate',
                  columnType: 'date',
                  required: false,
                  description: '',
                  category: 'no category',
                  position: 4,
                },
                {
                  id: '1639398628511.0oywd',
                  columnName: 'language',
                  columnType: 'string',
                  required: false,
                  description: '',
                  category: 'no category',
                  position: 5,
                },
                {
                  id: '1639398628511.06q2x',
                  columnName: 'gender',
                  columnType: 'string',
                  required: false,
                  description: '',
                  category: 'no category',
                  position: 6,
                },
                {
                  id: '1639398628511.0e7um',
                  columnName: 'civility',
                  columnType: 'string',
                  required: false,
                  description: '',
                  category: 'no category',
                  position: 7,
                },
                {
                  id: '1639398628511.0h9th',
                  columnName: 'address',
                  columnType: 'string',
                  required: false,
                  description: '',
                  category: 'no category',
                  position: 8,
                },
                {
                  id: '1639398628511.0nmoi',
                  columnName: 'postalCode',
                  columnType: 'string',
                  required: false,
                  description: '',
                  category: 'no category',
                  position: 9,
                },
                {
                  id: '1639398628511.0frym',
                  columnName: 'city',
                  columnType: 'string',
                  required: false,
                  description: '',
                  category: 'no category',
                  position: 10,
                },
                {
                  id: '1639398628511.0jo91',
                  columnName: 'region',
                  columnType: 'string',
                  required: false,
                  description: '',
                  category: 'no category',
                  position: 11,
                },
                {
                  id: '1639398628511.07tw1',
                  columnName: 'countryName',
                  columnType: 'string',
                  required: false,
                  description: '',
                  category: 'no category',
                  position: 12,
                },
                {
                  id: '1639398628511.09rwv',
                  columnName: 'countryCode',
                  columnType: 'string',
                  required: false,
                  description: '',
                  category: 'no category',
                  position: 13,
                },
                {
                  id: '1639398628511.01ysj',
                  columnName: 'email',
                  columnType: 'string',
                  required: false,
                  description: '',
                  category: 'no category',
                  position: 14,
                },
                {
                  id: '1639398628511.0rt9o',
                  columnName: 'emailFormat',
                  columnType: 'string',
                  required: false,
                  description: '',
                  category: 'no category',
                  position: 15,
                },
                {
                  id: '1639398628511.0ojw2',
                  columnName: 'emailDomain',
                  columnType: 'string',
                  required: false,
                  description: '',
                  category: 'no category',
                  position: 16,
                },
                {
                  id: '1639398628511.04iry',
                  columnName: 'emailValidity',
                  columnType: 'no type',
                  required: false,
                  description: '',
                  category: 'no category',
                  position: 17,
                },
                {
                  id: '1639398628511.0b3t8',
                  columnName: 'emailValidityReason',
                  columnType: 'no type',
                  required: false,
                  description: '',
                  category: 'no category',
                  position: 18,
                },
                {
                  id: '1639398628511.0g5jw',
                  columnName: 'phoneMobile',
                  columnType: 'string',
                  required: false,
                  description: '',
                  category: 'no category',
                  position: 19,
                },
                {
                  id: '1639398628511.05vj2',
                  columnName: 'phoneLandline',
                  columnType: 'number',
                  required: false,
                  description: '',
                  category: 'no category',
                  position: 20,
                },
                {
                  id: '1639398628511.0ua5q',
                  columnName: 'phone',
                  columnType: 'number',
                  required: false,
                  description: '',
                  category: 'no category',
                  position: 21,
                },
                {
                  id: '1639398628511.06l2z',
                  columnName: 'phoneValidity',
                  columnType: 'no type',
                  required: false,
                  description: '',
                  category: 'no category',
                  position: 22,
                },
                {
                  id: '1639398628511.09sex',
                  columnName: 'phoneValidityReason',
                  columnType: 'no type',
                  required: false,
                  description: '',
                  category: 'no category',
                  position: 23,
                },
                {
                  id: '1639398628511.0mkv0',
                  columnName: 'phoneCountry',
                  columnType: 'string',
                  required: false,
                  description: '',
                  category: 'no category',
                  position: 24,
                },
                {
                  id: '1639398628511.0xzis',
                  columnName: 'phoneNormalized',
                  columnType: 'no type',
                  required: false,
                  description: '',
                  category: 'no category',
                  position: 25,
                },
                {
                  id: '1639398628511.04l72',
                  columnName: 'phoneType',
                  columnType: 'no type',
                  required: false,
                  description: '',
                  category: 'no category',
                  position: 26,
                },
                {
                  id: '1639398628511.0faju',
                  columnName: 'consentEmail',
                  columnType: 'string',
                  required: false,
                  description: '',
                  category: 'no category',
                  position: 27,
                },
                {
                  id: '1639398628511.05mxh',
                  columnName: 'consentEmailUpdatedAt',
                  columnType: 'string',
                  required: false,
                  description: '',
                  category: 'no category',
                  position: 28,
                },
                {
                  id: '1639398628511.07rpe',
                  columnName: 'consentSms',
                  columnType: 'string',
                  required: false,
                  description: '',
                  category: 'no category',
                  position: 29,
                },
                {
                  id: '1639398628511.0nvft',
                  columnName: 'consentSmsUpdatedAt',
                  columnType: 'string',
                  required: false,
                  description: '',
                  category: 'no category',
                  position: 30,
                },
                {
                  id: '1639398628511.0siu2',
                  columnName: 'linkedin',
                  columnType: 'no type',
                  required: false,
                  description: '',
                  category: 'no category',
                  position: 31,
                },
                {
                  id: '1639398628511.0ztvq',
                  columnName: 'website',
                  columnType: 'no type',
                  required: false,
                  description: '',
                  category: 'no category',
                  position: 32,
                },
                {
                  id: '1639398628511.012sg',
                  columnName: 'companyName',
                  columnType: 'string',
                  required: false,
                  description: '',
                  category: 'no category',
                  position: 33,
                },
                {
                  id: '1639398628511.0nxk5',
                  columnName: 'jobTitle',
                  columnType: 'string',
                  required: false,
                  description: '',
                  category: 'no category',
                  position: 34,
                },
                {
                  id: '1639398628511.07wdl',
                  columnName: 'createdAt',
                  columnType: 'date',
                  required: false,
                  description: '',
                  category: 'no category',
                  position: 35,
                },
                {
                  id: '1639398628511.0yo2q',
                  columnName: 'updatedAt',
                  columnType: 'date',
                  required: false,
                  description: '',
                  category: 'no category',
                  position: 36,
                },
              ],
              primaryKeys: ['1639398628511.0624i'],
              recordsNumber: 12,
              pointer: 'Available integrations/All orders',
              records: [
                {
                  userId: '1639398628508.0057e',
                  firstName: 'Darrion',
                  lastName: 'Emmerich',
                  fullName: 'Darrion Emmerich',
                  birthdate: '2021-10-29T18:09:30.268Z',
                  language: 'VG',
                  gender: 'Neither',
                  civility: 'Ms.',
                  address: '34956 Bette Fort',
                  postalCode: '61335-4282',
                  city: 'South Glennie',
                  region: '',
                  countryName: 'Cook Islands',
                  countryCode: 'LY',
                  email: 'Joshuah_Kutch@gmail.com',
                  emailFormat: '',
                  emailDomain: 'carlo.info',
                  emailValidity: '',
                  emailValidityReason: '',
                  phoneMobile: '786-275-4449 x90299',
                  phoneLandline: '687-907-8335 x4408',
                  phone: '920.913.4129 x38834',
                  phoneValidity: '',
                  phoneValidityReason: '',
                  phoneCountry: 'JP',
                  phoneNormalized: '',
                  phoneType: '',
                  consentEmail: false,
                  consentEmailUpdatedAt: '2021-04-27T08:06:26.859Z',
                  consentSms: true,
                  consentSmsUpdatedAt: '2021-01-08T15:02:06.213Z',
                  linkedin: '',
                  website: '',
                  companyName: 'Raynor, Williamson and Conn',
                  jobTitle: 'Senior Mobility Representative',
                  createdAt: '2021-10-07T22:49:37.074Z',
                  updatedAt: '2021-08-09T17:17:47.923Z',
                },
                {
                  userId: '1639398628508.0s9sk',
                  firstName: 'Rafaela',
                  lastName: 'Waters',
                  fullName: 'Rafaela Waters',
                  birthdate: '2021-07-30T01:37:30.954Z',
                  language: 'DE',
                  gender: 'Male to female transsexual woman',
                  civility: 'Miss',
                  address: '1384 Rippin Mill',
                  postalCode: '05840',
                  city: 'South Antoniachester',
                  region: '',
                  countryName: 'Tokelau',
                  countryCode: 'GY',
                  email: 'Cristian.Denesik69@gmail.com',
                  emailFormat: '',
                  emailDomain: 'jaleel.biz',
                  emailValidity: '',
                  emailValidityReason: '',
                  phoneMobile: '544.741.0413 x573',
                  phoneLandline: '(265) 398-0332 x09157',
                  phone: '1-710-714-8706',
                  phoneValidity: '',
                  phoneValidityReason: '',
                  phoneCountry: 'MF',
                  phoneNormalized: '',
                  phoneType: '',
                  consentEmail: false,
                  consentEmailUpdatedAt: '2021-03-21T12:21:15.269Z',
                  consentSms: true,
                  consentSmsUpdatedAt: '2021-11-10T20:14:32.759Z',
                  linkedin: '',
                  website: '',
                  companyName: 'Gorczany - Heller',
                  jobTitle: 'Legacy Marketing Liaison',
                  createdAt: '2021-06-26T06:22:04.520Z',
                  updatedAt: '2021-04-27T07:35:42.484Z',
                },
                {
                  userId: '1639398628508.0metz',
                  firstName: 'Camden',
                  lastName: 'Vandervort',
                  fullName: 'Camden Vandervort',
                  birthdate: '2021-07-01T14:34:33.585Z',
                  language: 'HT',
                  gender: 'Trans*Woman',
                  civility: 'Ms.',
                  address: '5771 McLaughlin Forks',
                  postalCode: '39923-2794',
                  city: 'Decatur',
                  region: '',
                  countryName: 'Algeria',
                  countryCode: 'TH',
                  email: 'Abbie_Considine@hotmail.com',
                  emailFormat: '',
                  emailDomain: 'archibald.com',
                  emailValidity: '',
                  emailValidityReason: '',
                  phoneMobile: '690-899-7643',
                  phoneLandline: '654.291.5768 x268',
                  phone: '(626) 603-8692',
                  phoneValidity: '',
                  phoneValidityReason: '',
                  phoneCountry: 'NZ',
                  phoneNormalized: '',
                  phoneType: '',
                  consentEmail: true,
                  consentEmailUpdatedAt: '2021-09-12T05:43:32.238Z',
                  consentSms: true,
                  consentSmsUpdatedAt: '2021-06-30T21:22:38.337Z',
                  linkedin: '',
                  website: '',
                  companyName: 'Rodriguez, Hansen and Botsford',
                  jobTitle: 'Principal Operations Producer',
                  createdAt: '2021-01-28T07:25:47.587Z',
                  updatedAt: '2021-05-12T05:16:12.599Z',
                },
                {
                  userId: '1639398628509.035hv',
                  firstName: 'Kelton',
                  lastName: 'Doyle',
                  fullName: 'Kelton Doyle',
                  birthdate: '2021-08-10T18:48:36.028Z',
                  language: 'MO',
                  gender: 'Transexual Woman',
                  civility: 'Dr.',
                  address: '457 Eusebio Estate',
                  postalCode: '76204-0041',
                  city: 'Keelyport',
                  region: '',
                  countryName: 'French Polynesia',
                  countryCode: 'IL',
                  email: 'Emily31@yahoo.com',
                  emailFormat: '',
                  emailDomain: 'fabian.biz',
                  emailValidity: '',
                  emailValidityReason: '',
                  phoneMobile: '261.581.4933 x8487',
                  phoneLandline: '554-416-7087',
                  phone: '(715) 989-0111 x7976',
                  phoneValidity: '',
                  phoneValidityReason: '',
                  phoneCountry: 'LV',
                  phoneNormalized: '',
                  phoneType: '',
                  consentEmail: false,
                  consentEmailUpdatedAt: '2021-05-31T15:07:48.665Z',
                  consentSms: false,
                  consentSmsUpdatedAt: '2021-09-08T17:27:22.069Z',
                  linkedin: '',
                  website: '',
                  companyName: 'Emard, Lebsack and Casper',
                  jobTitle: 'Forward Infrastructure Strategist',
                  createdAt: '2021-05-19T06:00:17.631Z',
                  updatedAt: '2020-12-28T05:22:36.652Z',
                },
                {
                  userId: '1639398628509.0w9nm',
                  firstName: 'Pamela',
                  lastName: 'Stoltenberg',
                  fullName: 'Pamela Stoltenberg',
                  birthdate: '2021-08-08T21:08:46.784Z',
                  language: 'CH',
                  gender: 'Transexual',
                  civility: 'Miss',
                  address: '9026 Will Manor',
                  postalCode: '34854',
                  city: 'West Finn',
                  region: '',
                  countryName: 'Gambia',
                  countryCode: 'NZ',
                  email: 'Bessie_Hoppe25@gmail.com',
                  emailFormat: '',
                  emailDomain: 'jordan.net',
                  emailValidity: '',
                  emailValidityReason: '',
                  phoneMobile: '1-262-555-5595 x503',
                  phoneLandline: '299.427.0179',
                  phone: '576.352.9991 x44951',
                  phoneValidity: '',
                  phoneValidityReason: '',
                  phoneCountry: 'MN',
                  phoneNormalized: '',
                  phoneType: '',
                  consentEmail: true,
                  consentEmailUpdatedAt: '2020-12-17T00:53:00.079Z',
                  consentSms: true,
                  consentSmsUpdatedAt: '2021-06-14T09:42:50.166Z',
                  linkedin: '',
                  website: '',
                  companyName: 'Hessel - Ankunding',
                  jobTitle: 'Investor Brand Executive',
                  createdAt: '2021-09-09T01:05:12.395Z',
                  updatedAt: '2021-05-30T23:42:35.280Z',
                },
                {
                  userId: '1639398628509.0mp3i',
                  firstName: 'Maximus',
                  lastName: 'Bayer',
                  fullName: 'Maximus Bayer',
                  birthdate: '2021-02-02T02:05:50.092Z',
                  language: 'TZ',
                  gender: 'Cis Woman',
                  civility: 'Ms.',
                  address: '2122 Upton Ridge',
                  postalCode: '32884',
                  city: 'Port Dulcemouth',
                  region: '',
                  countryName: 'Belarus',
                  countryCode: 'LR',
                  email: 'Julio.Pfeffer@gmail.com',
                  emailFormat: '',
                  emailDomain: 'florencio.com',
                  emailValidity: '',
                  emailValidityReason: '',
                  phoneMobile: '980-997-1024',
                  phoneLandline: '1-601-838-4991',
                  phone: '(843) 919-4666',
                  phoneValidity: '',
                  phoneValidityReason: '',
                  phoneCountry: 'PE',
                  phoneNormalized: '',
                  phoneType: '',
                  consentEmail: true,
                  consentEmailUpdatedAt: '2020-12-19T10:09:43.571Z',
                  consentSms: false,
                  consentSmsUpdatedAt: '2021-03-11T13:28:29.226Z',
                  linkedin: '',
                  website: '',
                  companyName: 'Schneider and Sons',
                  jobTitle: 'Human Paradigm Architect',
                  createdAt: '2021-06-08T04:33:47.704Z',
                  updatedAt: '2021-03-01T13:07:36.122Z',
                },
                {
                  userId: '1639398628509.0aand',
                  firstName: 'Emmalee',
                  lastName: 'Gerlach',
                  fullName: 'Emmalee Gerlach',
                  birthdate: '2020-12-15T10:27:18.550Z',
                  language: 'PN',
                  gender: 'M2F',
                  civility: 'Ms.',
                  address: '8898 Botsford Trail',
                  postalCode: '74464',
                  city: 'Germainemouth',
                  region: '',
                  countryName: 'Gibraltar',
                  countryCode: 'MM',
                  email: 'Peggie0@gmail.com',
                  emailFormat: '',
                  emailDomain: 'herminia.info',
                  emailValidity: '',
                  emailValidityReason: '',
                  phoneMobile: '270.842.8305',
                  phoneLandline: '1-244-777-4255',
                  phone: '476.379.8644 x53973',
                  phoneValidity: '',
                  phoneValidityReason: '',
                  phoneCountry: 'SC',
                  phoneNormalized: '',
                  phoneType: '',
                  consentEmail: false,
                  consentEmailUpdatedAt: '2020-12-19T16:44:30.577Z',
                  consentSms: false,
                  consentSmsUpdatedAt: '2021-11-08T15:59:09.403Z',
                  linkedin: '',
                  website: '',
                  companyName: 'VonRueden, Kirlin and Nicolas',
                  jobTitle: 'Forward Security Manager',
                  createdAt: '2021-02-27T14:04:41.473Z',
                  updatedAt: '2021-07-24T20:50:13.911Z',
                },
                {
                  userId: '1639398628510.0nwo3',
                  firstName: 'Kylee',
                  lastName: 'Kessler',
                  fullName: 'Kylee Kessler',
                  birthdate: '2021-01-09T10:23:31.207Z',
                  language: 'KN',
                  gender: 'M2F',
                  civility: 'Dr.',
                  address: '95056 Ledner View',
                  postalCode: '32820-6917',
                  city: 'Madison',
                  region: '',
                  countryName: 'Turks and Caicos Islands',
                  countryCode: 'PW',
                  email: 'Hoyt_Blanda@gmail.com',
                  emailFormat: '',
                  emailDomain: 'tamia.info',
                  emailValidity: '',
                  emailValidityReason: '',
                  phoneMobile: '432-905-0845 x9737',
                  phoneLandline: '262.988.0115 x76516',
                  phone: '472.906.0858 x3972',
                  phoneValidity: '',
                  phoneValidityReason: '',
                  phoneCountry: 'NZ',
                  phoneNormalized: '',
                  phoneType: '',
                  consentEmail: false,
                  consentEmailUpdatedAt: '2021-09-23T02:33:25.654Z',
                  consentSms: false,
                  consentSmsUpdatedAt: '2021-07-02T18:35:02.631Z',
                  linkedin: '',
                  website: '',
                  companyName: 'Fritsch - Spinka',
                  jobTitle: 'Global Functionality Supervisor',
                  createdAt: '2021-01-08T10:07:59.776Z',
                  updatedAt: '2021-01-03T23:29:10.009Z',
                },
                {
                  userId: '1639398628510.0cd0v',
                  firstName: 'Nicholaus',
                  lastName: 'Brown',
                  fullName: 'Nicholaus Brown',
                  birthdate: '2021-08-29T21:00:20.588Z',
                  language: 'TK',
                  gender: 'Transexual Female',
                  civility: 'Ms.',
                  address: '14377 Johnnie Junction',
                  postalCode: '13797',
                  city: 'Lednerstad',
                  region: '',
                  countryName: 'Djibouti',
                  countryCode: 'MA',
                  email: 'Janie16@gmail.com',
                  emailFormat: '',
                  emailDomain: 'kenton.biz',
                  emailValidity: '',
                  emailValidityReason: '',
                  phoneMobile: '446-271-9304',
                  phoneLandline: '(790) 827-1238',
                  phone: '618.466.2334',
                  phoneValidity: '',
                  phoneValidityReason: '',
                  phoneCountry: 'MW',
                  phoneNormalized: '',
                  phoneType: '',
                  consentEmail: false,
                  consentEmailUpdatedAt: '2021-08-14T15:33:34.937Z',
                  consentSms: false,
                  consentSmsUpdatedAt: '2021-03-19T13:58:21.128Z',
                  linkedin: '',
                  website: '',
                  companyName: 'Turcotte, Erdman and Jones',
                  jobTitle: 'Chief Assurance Executive',
                  createdAt: '2021-01-23T09:48:26.329Z',
                  updatedAt: '2021-11-13T22:43:38.091Z',
                },
                {
                  userId: '1639398628511.0vhhd',
                  firstName: 'Antonietta',
                  lastName: 'Moen',
                  fullName: 'Antonietta Moen',
                  birthdate: '2021-12-04T14:00:49.832Z',
                  language: 'UG',
                  gender: 'Intersex woman',
                  civility: 'Miss',
                  address: '0132 Zander Center',
                  postalCode: '20823-9837',
                  city: 'Harbershire',
                  region: '',
                  countryName: 'Ukraine',
                  countryCode: 'CD',
                  email: 'Kieran.Adams75@yahoo.com',
                  emailFormat: '',
                  emailDomain: 'archibald.com',
                  emailValidity: '',
                  emailValidityReason: '',
                  phoneMobile: '463.715.4585 x7034',
                  phoneLandline: '647.414.0898 x78325',
                  phone: '347.410.5694 x070',
                  phoneValidity: '',
                  phoneValidityReason: '',
                  phoneCountry: 'GS',
                  phoneNormalized: '',
                  phoneType: '',
                  consentEmail: true,
                  consentEmailUpdatedAt: '2021-06-24T21:14:19.644Z',
                  consentSms: true,
                  consentSmsUpdatedAt: '2021-02-27T16:39:39.988Z',
                  linkedin: '',
                  website: '',
                  companyName: 'Effertz - Dickinson',
                  jobTitle: 'Internal Factors Associate',
                  createdAt: '2021-03-05T05:47:20.289Z',
                  updatedAt: '2021-04-13T06:04:15.533Z',
                },
                {
                  userId: '1639398628511.0jf2k',
                  firstName: 'Allie',
                  lastName: 'Konopelski',
                  fullName: 'Allie Konopelski',
                  birthdate: '2021-08-11T18:46:23.834Z',
                  language: 'AS',
                  gender: 'Female to male transgender man',
                  civility: 'Dr.',
                  address: '8439 Jazmin Curve',
                  postalCode: '22009',
                  city: 'Erickmouth',
                  region: '',
                  countryName: 'Chad',
                  countryCode: 'UA',
                  email: 'Unique_Glover52@yahoo.com',
                  emailFormat: '',
                  emailDomain: 'scottie.org',
                  emailValidity: '',
                  emailValidityReason: '',
                  phoneMobile: '270-715-1877 x345',
                  phoneLandline: '1-867-728-5635',
                  phone: '320-467-2332 x5781',
                  phoneValidity: '',
                  phoneValidityReason: '',
                  phoneCountry: 'AX',
                  phoneNormalized: '',
                  phoneType: '',
                  consentEmail: false,
                  consentEmailUpdatedAt: '2021-09-13T16:12:02.489Z',
                  consentSms: true,
                  consentSmsUpdatedAt: '2021-11-05T06:50:58.341Z',
                  linkedin: '',
                  website: '',
                  companyName: 'Heller and Sons',
                  jobTitle: 'Customer Mobility Analyst',
                  createdAt: '2021-02-08T07:48:08.018Z',
                  updatedAt: '2021-03-10T07:11:03.661Z',
                },
                {
                  userId: '1639398628511.0ch0a',
                  firstName: 'Ayden',
                  lastName: 'Streich',
                  fullName: 'Ayden Streich',
                  birthdate: '2021-07-20T07:19:37.933Z',
                  language: 'GS',
                  gender: 'Transmasculine',
                  civility: 'Dr.',
                  address: '318 Jermaine Walk',
                  postalCode: '11319',
                  city: 'Abilene',
                  region: '',
                  countryName: 'Chile',
                  countryCode: 'GG',
                  email: 'Sheridan.Fay83@gmail.com',
                  emailFormat: '',
                  emailDomain: 'ophelia.name',
                  emailValidity: '',
                  emailValidityReason: '',
                  phoneMobile: '769.300.9959',
                  phoneLandline: '457-267-1897 x8654',
                  phone: '(805) 929-8543 x847',
                  phoneValidity: '',
                  phoneValidityReason: '',
                  phoneCountry: 'ST',
                  phoneNormalized: '',
                  phoneType: '',
                  consentEmail: false,
                  consentEmailUpdatedAt: '2021-02-08T14:34:04.898Z',
                  consentSms: true,
                  consentSmsUpdatedAt: '2021-12-09T01:11:06.957Z',
                  linkedin: '',
                  website: '',
                  companyName: 'Bailey - Bins',
                  jobTitle: 'Forward Tactics Developer',
                  createdAt: '2021-01-03T21:26:33.563Z',
                  updatedAt: '2021-03-28T03:00:06.820Z',
                },
              ],
              key: '1639398628512.0ykzf',
              created: new Date(),
            },
            name: 'POS',
            category: {
              key: '1',
              name: 'orders',
              columns: [
                {
                  id: 'col1',
                  columnName: 'userId',
                  columnType: 'string',
                  required: true,
                  category: 'string',
                  description: 'this is a description of the column',
                  position: 1,
                },
                {
                  id: '1639398562939.08eow',
                  columnName: 'firstName',
                  columnType: 'string',
                  required: true,
                  category: 'first_name',
                  description: 'this is a description of the column',
                  position: 1,
                },
                {
                  id: '1639398562939.0vge6',
                  columnName: 'lastName',
                  columnType: 'string',
                  required: false,
                  category: 'string',
                  description: 'this is a description of the column',
                  position: 2,
                },
                {
                  id: '1639398562939.06v4h',
                  columnName: 'fullName',
                  columnType: 'string',
                  required: false,
                  category: 'string',
                  description: 'this is a description of the column',
                  position: 3,
                },
                {
                  id: '1639398562939.0mnpp',
                  columnName: 'birthdate',
                  columnType: 'date',
                  required: false,
                  category: 'date',
                  description: 'this is a description of the column',
                  position: 4,
                },
                {
                  id: '1639398562939.09zbe',
                  columnName: 'language',
                  columnType: 'string',
                  required: false,
                  category: 'string',
                  description: 'this is a description of the column',
                  position: 5,
                },
                {
                  id: '1639398562939.0rfns',
                  columnName: 'gender',
                  columnType: 'string',
                  required: false,
                  category: 'string',
                  description: 'this is a description of the column',
                  position: 6,
                },
                {
                  id: '1639398562939.0cw4q',
                  columnName: 'civility',
                  columnType: 'string',
                  required: false,
                  category: 'string',
                  description: 'this is a description of the column',
                  position: 7,
                },
                {
                  id: '1639398562939.0yq4e',
                  columnName: 'address',
                  columnType: 'string',
                  required: false,
                  category: 'string',
                  description: 'this is a description of the column',
                  position: 8,
                },
                {
                  id: '1639398562939.0u1fn',
                  columnName: 'postalCode',
                  columnType: 'string',
                  required: false,
                  category: 'string',
                  description: 'this is a description of the column',
                  position: 9,
                },
                {
                  id: '1639398562939.0hchv',
                  columnName: 'city',
                  columnType: 'string',
                  required: false,
                  category: 'string',
                  description: 'this is a description of the column',
                  position: 10,
                },
                {
                  id: '1639398562939.0hrmt',
                  columnName: 'region',
                  columnType: 'string',
                  required: false,
                  category: 'string',
                  description: 'this is a description of the column',
                  position: 11,
                },
                {
                  id: '1639398562939.0ukiz',
                  columnName: 'countryName',
                  columnType: 'string',
                  required: false,
                  category: 'string',
                  description: 'this is a description of the column',
                  position: 12,
                },
                {
                  id: '1639398562939.03t2c',
                  columnName: 'countryCode',
                  columnType: 'string',
                  required: false,
                  category: 'string',
                  description: 'this is a description of the column',
                  position: 13,
                },
                {
                  id: '1639398562939.0v0s4',
                  columnName: 'email',
                  columnType: 'string',
                  required: true,
                  category: 'email',
                  description: 'this is a description of the column',
                  position: 14,
                },
                {
                  id: '1639398562939.0jtac',
                  columnName: 'emailFormat',
                  columnType: 'string',
                  required: false,
                  category: 'string',
                  description: 'this is a description of the column',
                  position: 15,
                },
                {
                  id: '1639398562939.02tve',
                  columnName: 'emailDomain',
                  columnType: 'string',
                  required: false,
                  category: 'string',
                  description: 'this is a description of the column',
                  position: 16,
                },
                {
                  id: '1639398562939.0x2pa',
                  columnName: 'emailValidity',
                  columnType: 'string',
                  required: false,
                  category: 'string',
                  description: 'this is a description of the column',
                  position: 17,
                },
                {
                  id: '1639398562939.0cjt9',
                  columnName: 'emailValidityReason',
                  columnType: 'string',
                  required: false,
                  category: 'string',
                  description: 'this is a description of the column',
                  position: 18,
                },
                {
                  id: '1639398562939.0djjq',
                  columnName: 'phoneMobile',
                  columnType: 'string',
                  required: false,
                  category: 'phone',
                  description: 'this is a description of the column',
                  position: 19,
                },
                {
                  id: '1639398562939.0omwk',
                  columnName: 'phoneLandline',
                  columnType: 'string',
                  required: false,
                  category: 'phone',
                  description: 'this is a description of the column',
                  position: 20,
                },
                {
                  id: '1639398562939.0lt7q',
                  columnName: 'phone',
                  columnType: 'string',
                  required: false,
                  category: 'phone',
                  description: 'this is a description of the column',
                  position: 21,
                },
                {
                  id: '1639398562939.0z5b4',
                  columnName: 'phoneValidity',
                  columnType: 'string',
                  required: false,
                  category: 'string',
                  description: 'this is a description of the column',
                  position: 22,
                },
                {
                  id: '1639398562939.0ie2i',
                  columnName: 'phoneValidityReason',
                  columnType: 'string',
                  required: false,
                  category: 'string',
                  description: 'this is a description of the column',
                  position: 23,
                },
                {
                  id: '1639398562939.0ics0',
                  columnName: 'phoneCountry',
                  columnType: 'string',
                  required: false,
                  category: 'string',
                  description: 'this is a description of the column',
                  position: 24,
                },
                {
                  id: '1639398562939.0u1dg',
                  columnName: 'phoneNormalized',
                  columnType: 'string',
                  required: false,
                  category: 'string',
                  description: 'this is a description of the column',
                  position: 25,
                },
                {
                  id: '1639398562939.00cg7',
                  columnName: 'phoneType',
                  columnType: 'string',
                  required: false,
                  category: 'string',
                  description: 'this is a description of the column',
                  position: 26,
                },
                {
                  id: '1639398562939.04xtt',
                  columnName: 'consentEmail',
                  columnType: 'boolean',
                  required: false,
                  category: 'boolean',
                  description: 'this is a description of the column',
                  position: 27,
                },
                {
                  id: '1639398562939.0wzkg',
                  columnName: 'consentEmailUpdatedAt',
                  columnType: 'date',
                  required: false,
                  category: 'date',
                  description: 'this is a description of the column',
                  position: 28,
                },
                {
                  id: '1639398562939.0xyeh',
                  columnName: 'consentSms',
                  columnType: 'boolean',
                  required: false,
                  category: 'boolean',
                  description: 'this is a description of the column',
                  position: 29,
                },
                {
                  id: '1639398562939.05dil',
                  columnName: 'consent_sms_updated_at',
                  columnType: 'date',
                  required: false,
                  category: 'date',
                  description: 'this is a description of the column',
                  position: 30,
                },
                {
                  id: '1639398562939.0g3yf',
                  columnName: 'linkedin',
                  columnType: 'string',
                  required: false,
                  category: 'string',
                  description: 'this is a description of the column',
                  position: 31,
                },
                {
                  id: '1639398562939.0qllt',
                  columnName: 'website',
                  columnType: 'string',
                  required: false,
                  category: 'string',
                  description: 'this is a description of the column',
                  position: 32,
                },
                {
                  id: '1639398562939.06jlr',
                  columnName: 'companyName',
                  columnType: 'string',
                  required: false,
                  category: 'string',
                  description: 'this is a description of the column',
                  position: 33,
                },
                {
                  id: '1639398562939.07qat',
                  columnName: 'jobTitle',
                  columnType: 'string',
                  required: false,
                  category: 'string',
                  description: 'this is a description of the column',
                  position: 34,
                },
                {
                  id: '1639398562939.0z8rc',
                  columnName: 'createdAt',
                  columnType: 'date',
                  required: false,
                  category: 'date',
                  description: 'this is a description of the column',
                  position: 35,
                },
                {
                  id: '1639398562939.01kgy',
                  columnName: 'updatedAt',
                  columnType: 'date',
                  required: false,
                  category: 'date',
                  description: 'this is a description of the column',
                  position: 36,
                },
              ],
            },
          },
          selectedColumns: [
            {
              id: '1639398628511.0624i',
              columnName: 'userId',
              columnType: 'string',
              required: false,
              description: '',
              category: 'no category',
              position: 0,
            },
            {
              id: '1639398628511.0yo2q',
              columnName: 'updatedAt',
              columnType: 'date',
              required: false,
              description: '',
              category: 'no category',
              position: 36,
            },
            {
              id: '1639398628511.0a5zp',
              columnName: 'firstName',
              columnType: 'string',
              required: false,
              description: '',
              category: 'no category',
              position: 1,
            },
            {
              id: '1639398628511.0r0dt',
              columnName: 'fullName',
              columnType: 'string',
              required: false,
              description: '',
              category: 'no category',
              position: 3,
            },
            {
              id: '1639398628511.0t4sq',
              columnName: 'lastName',
              columnType: 'string',
              required: false,
              description: '',
              category: 'no category',
              position: 2,
            },
          ],
          joinByColumns: [
            {
              key: '1640965852275.05dr3',
              audienceColumnId: '1639398596380.04o5m',
              sourceColumnId: 'col1',
            },
          ],
        },
      ],
      mappingRows: [
        {
          key: '1639398596380.04o5m',
          mappedColumns: {
            '1640912329789.0u73x': {
              columnId: '1639398585185.0m2el',
              columnName: 'userId',
              columnType: 'string',
              primaryKey: true,
              updatedAtKey: false,
              columnDisplay: columnDisplayEnum.DISABLED,
            },
            '1242346767889.0u73x': {
              columnId: '1639398628511.0624i',
              columnName: 'userId',
              columnType: 'string',
              columnDisplay: columnDisplayEnum.SELECT,
            },
          },
          audienceColumnName: 'user_id',
          audienceColumnType: 'string',
          primaryKey: true,
          updatedAtKey: false,
        },
        {
          key: '1639398596380.0ohfi',
          mappedColumns: {
            '1640912329789.0u73x': {
              columnId: '1639398585186.0dv04',
              columnName: 'updatedAt',
              columnType: 'date',
              primaryKey: false,
              updatedAtKey: true,
              columnDisplay: columnDisplayEnum.DISABLED,
            },
            '1242346767889.0u73x': {
              columnId: '1639398628511.0yo2q',
              columnName: 'updatedAt',
              columnType: 'date',
              columnDisplay: columnDisplayEnum.SELECT,
            },
          },
          audienceColumnName: 'updated_at',
          audienceColumnType: 'date',
          primaryKey: false,
          updatedAtKey: true,
        },
        {
          key: '1639398596380.0t6c0',
          mappedColumns: {
            '1640912329789.0u73x': {
              columnId: '1639398585185.0wdo8',
              columnName: 'firstName',
              columnType: 'string',
              primaryKey: false,
              updatedAtKey: false,
              columnDisplay: columnDisplayEnum.DISABLED,
            },
            '1242346767889.0u73x': {
              columnId: '1639398628511.0624i',
              columnName: 'userId',
              columnType: 'string',
              columnDisplay: columnDisplayEnum.SELECT,
            },
          },
          audienceColumnName: 'first_name',
          audienceColumnType: 'string',
          canDelete: true,
          primaryKey: false,
          updatedAtKey: false,
        },
        {
          key: '1639398596380.0n5do',
          mappedColumns: {
            '1640912329789.0u73x': {
              columnId: '1639398585185.0lspq',
              columnName: 'lastName',
              columnType: 'string',
              primaryKey: false,
              updatedAtKey: false,
              columnDisplay: columnDisplayEnum.DISABLED,
            },
            '1242346767889.0u73x': {
              columnId: '1639398628511.0a5zp',
              columnName: 'firstName',
              columnType: 'string',
              columnDisplay: columnDisplayEnum.SELECT,
            },
          },
          audienceColumnName: 'last_name',
          audienceColumnType: 'string',
          canDelete: true,
          primaryKey: false,
          updatedAtKey: false,
        },
        {
          key: '1639398596380.0fn7o',
          mappedColumns: {
            '1640912329789.0u73x': {
              columnId: '1639398585185.050ra',
              columnName: 'fullName',
              columnType: 'string',
              primaryKey: false,
              updatedAtKey: false,
              columnDisplay: columnDisplayEnum.DISABLED,
            },
            '1242346767889.0u73x': {
              columnId: '1639398628511.0t4sq',
              columnName: 'lastName',
              columnType: 'string',
              columnDisplay: columnDisplayEnum.SELECT,
            },
          },
          audienceColumnName: 'full_name',
          audienceColumnType: 'string',
          canDelete: true,
          primaryKey: false,
          updatedAtKey: false,
        },
      ],
      preparationSteps: [
        {
          key: '1639398605761.0nuxs',
          position: 0,
          type: 'initialSource',
          title: 'Init with offline orders items',
          configuration: {
            sourceKey: '1640912329789.0u73x',
          },
        },
        {
          key: '1639398651935.01pc5',
          position: 3,
          type: 'combinedSource',
          title: 'Add online orders items',
          configuration: {
            sourceKey: '1242346767889.0u73x',
          },
        },
      ],
      deduplicateSettings: {
        duplicateKeys: [],
        preservedValuesKeys: [],
      },
      retrievalMode: retrievalModeEnum.INCREMENTAL_MODE,
      buildType: buildTypesEnum.BUSINESS,
      name: 'Orders items',
      object: {
        key: '1640963206876.0frtg',
        name: 'object',
        tableName: 'table-object',
        category: 'default',
        schema: ordersItemsColumns,
        primaryKeys: ['1639398596380.04o5m'],
        recordsNumber: 0,
        pointer: 'default',
        records: ordersItemsRecordsGenerator(generateRandomInteger(1000, 1000)),
        created: new Date(),
        updatedAtId: '1639398596380.0ohfi',
      },
    },
  ];
  