import { OrganizationsState } from '@features/organizations/ducks/organizationsSlice';
import { UsersState } from '@features/users/ducks/usersSlice';

export interface MenuLeftItem {
  key: string | null;
  breadcrumb: string[];
  defaultOpenKey?: string;
}

export interface MenuLeftProps {
  highlightedItem?: MenuLeftItem;
  isSiderCollapsed: boolean;
  usersState: UsersState;
  organizationsState: OrganizationsState;
  breadcrumb: string[];
  getProfile?: (p: any) => void;
  getUser?: (p: { userId: string; organization: string }) => void;
  getOrganization?: (p: any) => void;
  getStateProfile?: (p: any) => void;
  setLanguage?: (p: any) => void;
  setTheme?: (p: any) => void;
}
export interface RoutesMenuProps {
  activeMenuItem?: string;
  isSiderCollapsed: boolean;
  setActiveMenuItem: (value: React.SetStateAction<string | undefined>) => void;
  getMenuItemStyle: (menuKey: string) => string;
}

export interface MenuLeftState {
  key: string[];
  breadcrumb: string[];
  defaultOpenKeys: string[];
  isSiderCollapsed: boolean;
}

export enum MENU_ROUTES {
  RECOMMANDATIONS = 'recommandations',
  CONNECTIONS = 'connections',
  AUDIENCES = 'audiences',
  SYNCS = 'syncs',
  SEGMENTS = 'segments',
  SOURCE = 'sources',
  FLOWS = 'flows',
  // SOURCE_FLOWS = 'flows/source',
  // DESTINATION_FLOWS = 'flows/destination',
  // INTERNAL_FLOWS = 'flows/internal',
  RECIPES = 'all-recipes',
  // RECIPES_EDITOR = 'recipes-editor',
  OBJECTS = 'objects',
  DESTINATIONS = 'destinations',
  ACCOUNT_MANAGEMENT = 'account-management',
}
