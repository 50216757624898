// Importing type
import * as apiTypes from './types';
import { RawRecord } from '@features/rawRecords/types';
import { sleep } from '@utils/helpers';

// Function containing emulating API call with fake data
const getRawData: apiTypes.APIGetRawData = async (connection, pointer) => {
  await sleep(1500);
  let rawData: RawRecord[];
  let error: boolean = false;
  let errorDetails: string | undefined = undefined;

  if (pointer.indexOf('contacts') !== -1) {
    rawData = [
      {
        name: 'Marc',
        email: 'marc@gmail.com',
        age: 21,
      },
      {
        name: 'Claire',
        email: 'claire@gmail.com',
        age: 23,
      },
      {
        name: 'Claire',
        email: 'claire@gmail.com',
        age: 23,
      },
    ];
  } else if (pointer.indexOf('orders') !== -1) {
    rawData = [
      {
        client: 'client1',
        product: 'prod1',
        price: '90',
        date: new Date(),
        transaction_id: '84tq6fm*e9sd54w/azesd',
      },
      {
        client: 'client2',
        product: 'prod1',
        price: '90',
        date: new Date(),
        transaction_id: '84tq6fm*e9sd54w/cazed',
      },
      {
        client: 'client3',
        product: 'prod2',
        price: 'a90',
        date: new Date(),
        transaction_id: '84tqazeaze*e9sd54w/c5b8d',
      },
    ];
  } else if (pointer.indexOf('products') !== -1) {
    rawData = [
      {
        name: 'prod1',
        price: '90',
        total_orders: '30260',
        day_orders: '140',
        product_ref: 'X84sDs5',
      },
      {
        name: 'prod2',
        price: '190',
        total_orders: '10960',
        day_orders: '140',
        product_ref: 'X84aze',
      },
      {
        name: 'prod3',
        price: '150',
        total_orders: '41266',
        day_orders: '140',
        product_ref: 'X8dddss',
      },
    ];
  } else {
    rawData = [
      {
        name: 'No record',
        email: 'No record',
        age: 'No record',
      },
    ];
  }
  if (!rawData) {
    error = true;
    errorDetails = 'No Raw Record found.';
  }
  return {
    error,
    records: rawData,
    loaded: true,
    errorDetails: error && errorDetails ? errorDetails : undefined,
  };
};

export { getRawData };
