// Importing type
import * as apiTypes from './types';
import { sleep } from '@utils/helpers';
import { ActiveAudience, Audience } from '../types';
import { audiences } from '@data/audiences';

const getAudiences: apiTypes.APIGetAudiences = async () => {
  await sleep(1500);
  let error: boolean = false;
  let errorDetails: string | undefined = undefined;

  if (!audiences) {
    error = true;
    errorDetails = 'No audiences found.';
  }
  return {
    audiences,
    error,
    loaded: true,
    errorDetails: error && errorDetails ? errorDetails : undefined,
  };
};

const getAudience: apiTypes.APIGetAudience = async (audienceKey: string) => {
  await sleep(1500);
  let error: boolean = false;
  let errorDetails: string | undefined = undefined;

  if (!audienceKey) {
    error = true;
    errorDetails = 'No audience found.';
  }
  return {
    audienceKey,
    error,
    loaded: true,
    errorDetails: error && errorDetails ? errorDetails : undefined,
  };
};

const saveAudience: apiTypes.APISaveAudience = async (activeAudience: ActiveAudience) => {
  await sleep(1500);

  let error: boolean = false;
  let errorDetails: string | undefined = undefined;
  let audience: Audience = { ...activeAudience, status: true } as Audience;

  if (!audience) {
    error = true;
    errorDetails = 'No audience found.';
  }

  return {
    error,
    audience,
    loaded: true,
    errorDetails: error && errorDetails ? errorDetails : undefined,
  };
};

const deleteAudience: apiTypes.APIDeleteAudience = async (audience: Audience) => {
  await sleep(1500);

  let error: boolean = false;
  let errorDetails: string | undefined = undefined;

  if (!audience) {
    error = true;
    errorDetails = 'Impossible to delete audience (not found).';
  }

  return {
    error,
    audience,
    loaded: true,
    errorDetails: error && errorDetails ? errorDetails : undefined,
  };
};

export { getAudiences, getAudience, saveAudience, deleteAudience };
