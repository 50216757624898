import { Button, Form, Popconfirm } from 'antd';
import React from 'react';

interface OctoButtonProps {
  onClick: () => void;
  label: string;
  disabled?: boolean;
  popConfirmConfig: {
    title: string;
    okText: string;
    cancelText: string;
  };
  buttonConfig?: {
    type?: 'link' | 'text' | 'ghost' | 'default' | 'primary' | 'dashed';
    danger?: boolean;
  };
  triggerClassName?: string;
}

const OctoButton = ({
  onClick,
  label,
  disabled,
  buttonConfig,
  triggerClassName,
  popConfirmConfig,
}: OctoButtonProps) => {
  return (
    <div className="octo-button-container">
      <Form.Item>
        <Popconfirm
          placement="topRight"
          disabled={disabled}
          title={popConfirmConfig.title}
          onConfirm={onClick}
          okText={popConfirmConfig.okText}
          cancelText={popConfirmConfig.cancelText}
          okButtonProps={{ className: 'octolis__primary-btn', type: 'text' }}
          cancelButtonProps={{ className: 'octolis__secondary-btn', type: 'text' }}
          overlayClassName={'ant-popover-inner-content-custom-padding'}
        >
          <Button
            type={buttonConfig?.type ? buttonConfig?.type : 'text'}
            className={triggerClassName ? triggerClassName : 'octolis__secondary-btn'}
            danger={buttonConfig?.danger}
            disabled={disabled}
          >
            {label}
          </Button>
        </Popconfirm>
      </Form.Item>
    </div>
  );
};

export default OctoButton;
