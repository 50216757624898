import { all } from 'redux-saga/effects';
import { sourcesSagas } from '@features/sources/ducks/sagas';
import { appContextSagas } from '@features/appContext/ducks/sagas';
import { recipesSagas } from '@features/recipes/ducks/sagas';
import { profilesSagas } from '@features/profiles/ducks/sagas';
import { flowsSagas } from '@features/flows/ducks/sagas';
import { connectorsSagas } from '@features/connectors/ducks/sagas';
import { connectionsSagas } from '@features/connections/ducks/sagas';
import { organizationsSagas } from '@features/organizations/ducks/sagas';
import { usersSagas } from '@features/users/ducks/sagas';
import { accountsSagas } from '@features/account/ducks/sagas';
import { objectsSagas } from '@features/objects/ducks/sagas';
import { rawRecordsSagas } from '@features/rawRecords/ducks/sagas';
import { filtersSagas } from '@features/filter/ducks/sagas';
import { segmentsSagas } from '@features/segments/ducks/sagas';
import { syncsSagas } from '@features/syncs/ducks/sagas';
import { integrationsSaga } from '@features/integrations/ducks/sagas';
import { destinationsSaga } from '@features/destinations/ducks/sagas';
import { audienceSagas } from '@features/audiences/ducks/sagas';
import { redirectionsSagas } from '@features/redirections/ducks/sagas';

export default function* rootSaga() {
  yield all([
    ...sourcesSagas,
    ...segmentsSagas,
    ...appContextSagas,
    ...recipesSagas,
    ...profilesSagas,
    ...flowsSagas,
    ...connectorsSagas,
    ...organizationsSagas,
    ...usersSagas,
    ...connectionsSagas,
    ...accountsSagas,
    ...objectsSagas,
    ...filtersSagas,
    ...rawRecordsSagas,
    ...syncsSagas,
    ...integrationsSaga,
    ...destinationsSaga,
    ...audienceSagas,
    ...redirectionsSagas,
  ]);
}
