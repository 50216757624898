import { Connector, ConnectorCategory } from '@features/connectors/types';

export enum CONNECTOR_CATEGORY {
  API = 'api',
  DB = 'db',
  FTP = 'ftp',
}

export const connectors: Connector[] = [
  {
    key: 'connector1',
    title: 'Salesforce',
    description: 'This is some description of the source connector',
    category: CONNECTOR_CATEGORY.API,
    logo: '/icons/logos/salesforce.png',
    expandedLogo: '/icons/salesforce.png',
  },
  {
    key: 'connector2',
    title: 'Sendinblue',
    description: 'This is some description of the source connector',
    category: CONNECTOR_CATEGORY.API,
    logo: '/icons/logos/sendinblue.png',
    expandedLogo: '/icons/sendinblue.png',
  },
  {
    key: 'connector3',
    title: 'PostgreSQL',
    description: 'This is some description of the source connector',
    category: CONNECTOR_CATEGORY.DB,
    logo: '/icons/logos/postgresql.png',
    expandedLogo: '/icons/postgres.png',
  },
  {
    key: 'connector4',
    title: 'SFTP',
    description: 'This is some description of the source connector',
    category: CONNECTOR_CATEGORY.FTP,
    logo: '/icons/ftp.png',
    expandedLogo: '/icons/ftp.png',
  },
  {
    key: 'connector5',
    title: 'AWS s3',
    description: 'This is some description of the source connector',
    category: CONNECTOR_CATEGORY.FTP,
    logo: '/icons/logos/s3.png',
    expandedLogo: '/icons/aws-s3.png',
  },
  {
    key: 'connector6',
    title: 'mailchimp',
    description: 'This is some description of the destination connector',
    category: CONNECTOR_CATEGORY.API,
    logo: '/icons/logos/mailchimp.png',
    expandedLogo: '/icons/mailchimp.png',
  },
  {
    key: 'connector7',
    title: 'intercom',
    description: 'This is some description of the destination connector',
    category: CONNECTOR_CATEGORY.API,
    logo: '/icons/logos/intercom.png',
    expandedLogo: '/icons/intercom.png',
  },
  {
    key: 'connector8',
    title: 'Zendesk',
    description: 'This is some description of the destination connector',
    category: CONNECTOR_CATEGORY.API,
    logo: '/icons/logos/zendesk.png',
    expandedLogo: '/icons/Zendesk-expanded.png',
  },
  {
    key: 'connector9',
    title: 'Splio',
    description: 'This is some description of the destination connector',
    category: CONNECTOR_CATEGORY.API,
    logo: '/icons/logos/splio.png',
    expandedLogo: '/icons/splio-expanded.png',
  },
  {
    key: 'connector10',
    title: 'Google Ads',
    description: 'This is some description of the destination connector',
    category: CONNECTOR_CATEGORY.API,
    logo: '/icons/logos/gads.png',
    expandedLogo: '/icons/gads.png',
  },
  // {
  //   key: 'connector11',
  //   title: 'Magento',
  //   description: 'This is some description of the destination connector',
  //   category: CONNECTOR_CATEGORY.API,
  //   logo: '/icons/logos/magento.png',
  //   expandedLogo: '/icons/magento-expanded.png',
  // },
  {
    key: 'connector12',
    title: 'Octolis Web Tracker',
    description: 'This is some description of the destination connector',
    category: CONNECTOR_CATEGORY.API,
    logo: '/icons/appLogos/octo-dark-min.png',
    expandedLogo: '/icons/octo-dark.png',
  },
  {
    key: 'connector13',
    title: 'Snowflake',
    description: 'This is some description of the destination connector',
    category: CONNECTOR_CATEGORY.DB,
    logo: '/icons/logos/snowflake.png',
    expandedLogo: '/icons/logos/snowflake.png',
  },
  {
    key: 'connector14',
    title: 'BigQuery',
    description: 'This is some description of the destination connector',
    category: CONNECTOR_CATEGORY.DB,
    logo: '/icons/logos/bigquery.png',
    expandedLogo: '/icons/logos/bigquery.png',
  },
  {
    key: 'connector15',
    title: 'Shopify',
    description: 'This is some description of the destination connector',
    category: CONNECTOR_CATEGORY.API,
    logo: '/icons/logos/shopify.svg',
    expandedLogo: '/icons/shopify-expanded.png',
  },
  {
    key: 'connector15',
    title: 'Facebook Ads',
    description: 'This is some description of the destination connector',
    category: CONNECTOR_CATEGORY.API,
    logo: '/icons/logos/facebook.png',
    expandedLogo: '/icons/logos/facebook.png',
  },
  {
    key: 'connector16',
    title: 'GSheet',
    description: 'This is some description of the destination connector',
    category: CONNECTOR_CATEGORY.API,
    logo: '/icons/logos/gsheet.png',
    expandedLogo: '/icons/logos/gsheet.png',
  },
];

export const connectorsCategoriesData: ConnectorCategory[] = [
  {
    key: CONNECTOR_CATEGORY.API,
    name: 'Marketing sofware APIs',
  },
  {
    key: CONNECTOR_CATEGORY.DB,
    name: 'Datawarehouses',
  },
  {
    key: CONNECTOR_CATEGORY.FTP,
    name: 'Static files storage',
  },
];
