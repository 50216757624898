import { createSlice } from '@reduxjs/toolkit';
import {
  ActiveAudience,
  ActiveSource,
  ActiveSourceConfiguration,
  Audience,
  dataTypesEnum,
  JoinByColumn,
  keepTypesEnum,
  MappingRow,
  PreparationsStepsTypesEnum,
  PreparationStep,
  retrievalModeEnum,
  retrieveFrequencyEnum,
  SourceDeduplicateSettings,
  sourceTypesEnum,
} from '../types';
import {
  ActiveMappingRowsPayload,
  ActivePreparationPayload,
  ActivePreparationStepsPayload,
  ActiveSourceConfigurationPayload,
  AudienceReducerActions,
  AudiencesReducerActions,
  GetAudienceReducerActions,
  NewAActiveSourceConfigurationPayload,
  ReducerAudienceActions,
} from './types';
import { generateId, updateOrAddElementInArray } from '@utils/helpers';
import {
  BusinessCategoryReducerActions,
  BusinessTermToActiveColumnReducerActions,
} from '@features/objects/ducks/types';
import { ActivePreparation } from '@features/audiences/PrepareAudience/types';
import { isActiveSourceType } from '@features/audiences/typesPredicates';

export interface AudienceState {
  loaded: boolean;
  isFetching?: boolean;
  isFetchingActiveSourceObject?: boolean;
  error?: boolean;
  errorDetails?: string;
  audiences: Audience[];
  activeAudience: ActiveAudience;
  activeMappingRows: MappingRow[];
  activeSourceConfiguration: ActiveSourceConfiguration;
  activePreparation: ActivePreparation;
}

const initialSource: ActiveSource = {
  key: generateId(),
};
const initialJoinByColumns: JoinByColumn[] = [
  { key: generateId(), audienceColumnId: undefined, sourceColumnId: undefined },
];
const initialSourceDeduplicateSettings: SourceDeduplicateSettings = {
  keep: keepTypesEnum.RECENT,
  deduplicateRecord: 'false',
  deduplicateColumns: {
    key: generateId(),
    combinationOperator: 'or',
    columnGroups: [],
  },
};
const initiaActiveSourceConfiguration: ActiveSourceConfiguration = {
  key: generateId(),
  combinationLevel: 0,
  audienceSource: initialSource,
  dataType: dataTypesEnum.UNION,
  sourceType: sourceTypesEnum.SOURCE,
  retrieveFrequency: retrieveFrequencyEnum.EVERY_HOUR,
  selectedColumns: [],
  joinByColumns: initialJoinByColumns,
  deduplicateSettings: initialSourceDeduplicateSettings,
};

const initialActiveAudience: ActiveAudience = {
  key: generateId(),
  sources: [],
  mappingRows: [],
  preparationSteps: [],
  deduplicateSettings: {
    duplicateKeys: [],
    preservedValuesKeys: [],
  },
  retrievalMode: retrievalModeEnum.INCREMENTAL_MODE,
};
export const INITIAL_AUDIENCE_STATE = {
  loaded: false,
  isFetching: false,
  isFetchingActiveSourceObject: false,
  audiences: [],
  audiencesTableRecords: [],
  activeAudience: initialActiveAudience,
  activeMappingRows: [],
  activeSourceConfiguration: initiaActiveSourceConfiguration,
  activePreparation: {
    configuration: [],
  },
} as AudienceState;

const audiencesSlice = createSlice({
  name: 'audiences',
  initialState: INITIAL_AUDIENCE_STATE,
  reducers: {
    // Audience crud reducers

    getAudiences: (state) => {
      state.isFetching = true;
      state.audiences = [];
    },
    getAudiencesSuccess: (state, { payload }: AudiencesReducerActions) => {
      state.loaded = true;
      state.isFetching = false;
      state.audiences = payload.audiences;
    },
    getAudiencesFailed: (state, { payload }: AudiencesReducerActions) => {
      state.isFetching = false;
      state.error = true;
      state.errorDetails = payload.errorDetails;
    },

    getAudience: (state) => {
      state.isFetching = true;
    },
    getAudienceSuccess: (state, { payload }: GetAudienceReducerActions) => {
      const audience = state.audiences.find((audience) => audience.key === payload.audienceKey);
      state.loaded = true;
      state.isFetching = false;
      state.activeAudience = audience as ActiveAudience;
    },
    getAudienceFailed: (state, { payload }: GetAudienceReducerActions) => {
      state.isFetching = false;
      state.error = true;
      state.errorDetails = payload.errorDetails;
    },

    saveAudience: (state) => {
      state.isFetching = true;
    },
    saveAudienceSuccess: (state, { payload }: AudienceReducerActions) => {
      const audiencesNewValues = updateOrAddElementInArray(payload.audience, state.audiences);
      state.loaded = true;
      state.isFetching = false;
      state.audiences = audiencesNewValues;
    },
    saveAudienceFailed: (state, { payload }: AudienceReducerActions) => {
      state.isFetching = false;
      state.error = true;
      state.errorDetails = payload.errorDetails;
    },

    deleteAudience: (state) => {
      state.isFetching = true;
    },
    deleteAudienceSuccess: (state, { payload }: AudienceReducerActions) => {
      const audiencesNewValues = state.audiences.filter((a) => a.key !== payload.audience.key);
      state.loaded = true;
      state.isFetching = false;
      state.audiences = audiencesNewValues;
    },
    deleteAudienceFailed: (state, { payload }: AudienceReducerActions) => {
      state.isFetching = false;
      state.error = true;
      state.errorDetails = payload.errorDetails;
    },

    // Active audience reducers
    saveInitialAudienceForm: (state) => {
      state.isFetching = true;
    },
    saveInitialAudienceFormSuccess: (state) => {
      state.loaded = true;
      state.isFetching = false;
    },
    saveInitialAudienceFormFailed: (state) => {
      state.isFetching = false;
      state.error = true;
    },
    createSourceDataForm: (state) => {
      state.isFetching = true;
    },
    createSourceDataFormSuccess: (state) => {
      state.loaded = true;
      state.isFetching = false;
    },
    createSourceDataFormFailed: (state) => {
      state.isFetching = false;
      state.error = true;
    },
    editSourceDataForm: (state) => {
      state.isFetching = true;
    },
    editSourceDataFormSuccess: (state) => {
      state.loaded = true;
      state.isFetching = false;
    },
    editSourceDataFormFailed: (state) => {
      state.isFetching = false;
      state.error = true;
    },
    // Update redux state direct actions
    updateActiveAudience: (state, { payload }: ReducerAudienceActions) => {
      const { activeAudience } = payload;
      state.activeAudience = activeAudience;
    },
    updateActivePreparation: (state, { payload }: ActivePreparationPayload) => {
      const { preparation } = payload;
      state.activePreparation = preparation;
    },
    updateActivePreparationSteps: (state, { payload }: ActivePreparationStepsPayload) => {
      const { preparationSteps } = payload;
      state.activeAudience.preparationSteps = preparationSteps;
    },
    updateActiveMappingRows: (state, { payload }: ActiveMappingRowsPayload) => {
      const { mappingRows } = payload;
      state.activeMappingRows = mappingRows;
    },
    updateActiveSourceConfiguration: (state, { payload }: ActiveSourceConfigurationPayload) => {
      const { sourceConfiguration } = payload;
      state.activeSourceConfiguration = sourceConfiguration;
    },
    // update Active Source Object
    updateActiveSourceObject: (state) => {
      state.isFetchingActiveSourceObject = true;
    },
    updateActiveSourceObjectSuccess: (state, { payload }: ActiveSourceConfigurationPayload) => {
      const { sourceConfiguration } = payload;
      state.loaded = true;
      state.isFetchingActiveSourceObject = false;
      state.activeSourceConfiguration = sourceConfiguration;
    },
    updateActiveSourceObjectFailed: (state) => {
      state.isFetchingActiveSourceObject = false;
      state.error = true;
    },
    // Preparation step
    saveInitialAudiencePreparationStep: (state, { payload }: ActiveSourceConfigurationPayload) => {
      const { sourceConfiguration } = payload;
      const audienceSource: ActiveAudience | ActiveSource = sourceConfiguration.audienceSource;
      const preparationSteps: PreparationStep[] = [
        {
          key: generateId(),
          position: 0,
          type: PreparationsStepsTypesEnum.INITIAL_SOURCE,
          title: `Init with ${
            isActiveSourceType(audienceSource) && audienceSource.connection?.name
          } / ${audienceSource.name}`,
          configuration: { sourceKey: sourceConfiguration.key },
        },
      ];
      state.activeAudience.preparationSteps = preparationSteps;
    },
    saveSourceDataPreparationStep: (state, { payload }: ActiveSourceConfigurationPayload) => {
      const { sourceConfiguration } = payload;
      const audienceSource: ActiveAudience | ActiveSource = sourceConfiguration.audienceSource;
      const preparationSteps: PreparationStep[] = [
        ...state.activeAudience.preparationSteps,
        {
          key: generateId(),
          position: state.activeAudience.preparationSteps.length,
          type: PreparationsStepsTypesEnum.COMBINED_SOURCE,
          title: `Combine ${audienceSource.name}`,
          configuration: { sourceKey: sourceConfiguration.key },
        },
      ];
      state.activeAudience.preparationSteps = preparationSteps;
    },
    // empty actions / initial actions
    setEmptyActiveAudience: (state) => {
      state.activeAudience = initialActiveAudience;
    },
    setInitialActiveAudienceFormsStates: (state) => {
      state.activeAudience = { ...initialActiveAudience, key: generateId() };
      state.activeMappingRows = [];
      state.activeSourceConfiguration = {
        ...initiaActiveSourceConfiguration,
        key: generateId(),
        audienceSource: { ...initiaActiveSourceConfiguration.audienceSource, key: generateId() },
      };
    },

    setNewActiveSourceConfiguration: (state, { payload }: NewAActiveSourceConfigurationPayload) => {
      const { combinationLevel } = payload;
      state.activeSourceConfiguration = {
        ...initiaActiveSourceConfiguration,
        combinationLevel,
        key: generateId(),
        audienceSource: { ...initiaActiveSourceConfiguration.audienceSource, key: generateId() },
      };
    },
    assignBusinessCategoryToActiveSource: (state, { payload }: BusinessCategoryReducerActions) => {
      const { businessCategory } = payload;
      state.activeSourceConfiguration = {
        ...state.activeSourceConfiguration,
        audienceSource: {
          ...state.activeSourceConfiguration.audienceSource,
          category: businessCategory,
        },
      };
    },
    assignBusinessTermToActiveAudienceColumn: (
      state,
      { payload }: BusinessTermToActiveColumnReducerActions
    ) => {
      const { businessTerm, mappignRowKey, businessCategory } = payload;
      state.activeMappingRows = state.activeMappingRows.reduce((result: MappingRow[], row) => {
        row.key === mappignRowKey
          ? result.push({ ...row, businessColumn: businessTerm })
          : result.push(row);
        return result;
      }, []);
      state.activeSourceConfiguration = {
        ...state.activeSourceConfiguration,
        audienceSource: {
          ...state.activeSourceConfiguration.audienceSource,
          category: businessCategory,
        },
      };
    },
  },
});

export default audiencesSlice;
