import { takeLatest, call, put } from 'redux-saga/effects';
import * as API from './api';
import { GetDestinationsSaga, APIGetDestinationsPayload } from '@features/destinations/ducks/types';
import destinationsSlice from './destinationsSlice';

function* getDestinationsSaga({ payload }: GetDestinationsSaga) {
  try {
    const getIntegrationsPayload: APIGetDestinationsPayload = yield call(API.getDestinations);
    yield put({
      type: destinationsSlice.actions.getDestinationsSuccess.type,
      payload: getIntegrationsPayload,
    });
  } catch (error) {
    yield put({ type: destinationsSlice.actions.getDestinationsFailed.type, payload: error });
  }
}

export const destinationsSaga = [
  takeLatest(destinationsSlice.actions.getDestinations.type, getDestinationsSaga),
];
