import { createSlice } from '@reduxjs/toolkit';
import { Destination } from '../types';
import { DestinationsReducerActions } from './types';

export interface DestinationsState {
  loaded: boolean;
  isFetching?: boolean;
  shouldBeClosed?: boolean;
  error?: boolean;
  errorDetails?: string | undefined;
  destinations: Destination[];
}

export const INITIAL_DESTINATIONS_STATE = {
  loaded: false,
  isFetching: false,
  destinations: [],
} as DestinationsState;

const destinationsSlice = createSlice({
  name: 'destinations',
  initialState: INITIAL_DESTINATIONS_STATE,

  reducers: {
    getDestinations: (state) => {
      state.isFetching = true;
    },
    getDestinationsSuccess: (state, { payload }: DestinationsReducerActions) => {
      state.loaded = true;
      state.isFetching = false;
      state.destinations = payload.destinations;
    },
    getDestinationsFailed: (state, { payload }: DestinationsReducerActions) => {
      state.isFetching = false;
      state.error = true;
      state.errorDetails = payload.errorDetails;
    },
  },
});

export default destinationsSlice;
