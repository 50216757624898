// Importing type
import * as apiTypes from './types';
import { generateId, sleep } from '@utils/helpers';
import { Model, Recipe } from '@features/recipes/types';
import { objects } from '@data/objects';
import { recipes } from '@data/recipes';
import { QueryRun } from "./types";

const getModels: apiTypes.APIGetModels = async () => {
  await sleep(1000);

  let userModels: Model[];
  let octoModels: Model[];
  let communityModels: Model[];
  let error: boolean = false;
  let errorDetails: string | undefined = undefined;

  octoModels = [
    {
      key: generateId(),
      title: 'Contacts selection',
      type: 'octo',
      description: 'select all your contacts ',
      context: `{ "name": "Contacts selection" }`,
      query: 'SELECT * from contacts',
      image: '',
    },
    {
      key: generateId(),
      title: 'Orders selection',
      type: 'octo',
      query: 'SELECT * from Orders',
      context: `{ "name": "Orders selection" }`,
      description: 'select all your Orders and prepare them for use',
      image: '',
    },
    {
      key: generateId(),
      title: 'Products selection',
      type: 'octo',
      description: 'select all your Products ',
      query: 'SELECT * from Products',
      context: `{ "name": "Products selection" }`,
      image: '',
    },
    {
      key: generateId(),
      title: 'Products',
      type: 'octo',
      query: 'SELECT * from Products',
      context: `{ "name": "Products selection" }`,
      description: 'select all ',
      image: '',
    },
    {
      key: generateId(),
      title: 'Products',
      type: 'octo',
      query: 'SELECT * from Products',
      context: `{ "name": "Products selection" }`,
      description: 'select all ',
      image: '',
    },
  ];
  userModels = [
    {
      key: generateId(),
      title: 'My model',
      type: 'app',
      context: `{ "name": "Products selection" }`,
      description: 'contacts preperation',
      image: '',
      query: 'SELECT * from contacts',
    },
    {
      key: generateId(),
      title: 'My model 2',
      type: 'app',
      context: `{ "name": "Products selection" }`,
      description: 'orders preperation',
      image: '',
      query: 'SELECT * from orders',
    },
  ];

  communityModels = [
    {
      key: generateId(),
      title: 'Contacts selection',
      type: 'community',
      context: `{ "name": "Products selection" }`,
      description: 'select all your contacts and prepare them for use',
      image: '',
      query: 'SELECT * from contacts',
    },
    {
      key: generateId(),
      title: 'Orders selection',
      type: 'community',
      context: `{ "name": "Products selection" }`,
      description: 'select all your Orders and prepare them for use',
      image: '',
      query: 'SELECT * from Orders',
    },
    {
      key: generateId(),
      title: 'Products selection',
      type: 'community',
      context: `{ "name": "Products selection" }`,
      description: 'select all your Products and prepare them for use',
      image: '',
      query: 'SELECT * from Products',
    },
  ];
  if (!communityModels) {
    error = true;
    errorDetails = 'No communityModels found.';
  }

  return {
    error,
    models: [...userModels, ...communityModels, ...octoModels, ...octoModels],

    loaded: true,
    errorDetails: error && errorDetails ? errorDetails : undefined,
  };
};
const getRecipes: apiTypes.APIGetRecipes = async () => {
  await sleep(1000);

  let error: boolean = false;
  let errorDetails: string | undefined = undefined;


  if (!recipes) {
    error = true;
    errorDetails = 'No recipes found.';
  }

  return {
    error,
    recipes,
    loaded: true,
    errorDetails: error && errorDetails ? errorDetails : undefined,
  };
};

const saveRecipe: apiTypes.APISaveRecipe = async (recipe: Recipe) => {
  await sleep(500);
  let error: boolean = false;
  let errorDetails: string | undefined = undefined;

  if (!recipe) {
    error = true;
    errorDetails = 'No recipe to save .';
  }

  return {
    error,
    recipe,
    loaded: true,
    errorDetails: error && errorDetails ? errorDetails : undefined,
  };
};
const deleteRecipe: apiTypes.APISaveRecipe = async (recipe: Recipe) => {
  await sleep(1000);
  let error: boolean = false;
  let errorDetails: string | undefined = undefined;

  if (!recipe) {
    error = true;
    errorDetails = 'No recipe to save .';
  }

  return {
    error,
    recipe,
    loaded: true,
    errorDetails: error && errorDetails ? errorDetails : undefined,
  };
};
const runQuery: apiTypes.APIRunQuery = async (recipe) => {
  await sleep(1000);

  let query: QueryRun;
  let error: boolean = false;
  let errorDetails: string | undefined = undefined;

  if ( Math.random() <0.4 ) {
    query = {
      compiledCode:'',
      logs:[ {
        status: 'failed',
        message: 'query query2 failed to execute',
      }],
      results:[],
      runSuccess:false
    }
  } else {
    query = {
      results: [
        {
          key: "1a54h89b63a258cg4785",
          name: "contact 1",
          email: "email@gmail.com"
        },
        {
          key: "1h48s6d5d4da258cg4785",
          name: "contact 2",
          email: "email@gmail.com"
        }
      ],
      logs: [
        {
          status: "success",
          message: "query query1 executed with success"
        },
        {
          status: "failed",
          message: "query query2 failed to execute"
        }
      ],
      runSuccess: true,
      compiledCode: "SELECT * FROM contacts wher 5e8d6s3=d48s596"
    };
  }
  if (!query) {
    error = true;
    errorDetails = 'Query not executed';
  }

  return {
    error,
    query,
    loaded: true,
    errorDetails: error && errorDetails ? errorDetails : undefined,
  };
};
export { getRecipes, getModels, saveRecipe, deleteRecipe, runQuery };
