import { createSlice } from '@reduxjs/toolkit';
import type { RedirectionReducerActions } from './types';

export interface RedirectionsState {
  loaded: boolean;
  isFetching?: boolean;
  isRedirecting?: boolean;
  error?: boolean;
  errorDetails?: string | undefined;
  redirection: {};
}

export const INITIAL_THEME_STATE = {
  loaded: false,
  isFetching: false,
  isRedirecting: false,
  redirection: {},
} as RedirectionsState;

const redirectionsSlice = createSlice({
  name: 'redirections',

  initialState: INITIAL_THEME_STATE,
  reducers: {
    redirectToSyncsCreator: (state) => {
      state.isRedirecting = true;
    },
    redirectToSyncsCreatorSuccess: (state, { payload }: RedirectionReducerActions) => {
      state.loaded = true;
      state.isRedirecting = false;
      state.redirection = {};
    },
    redirectToSyncsCreatorFailed: (state, { payload }: RedirectionReducerActions) => {
      state.isRedirecting = false;
      state.error = true;
      state.errorDetails = payload.errorDetails;
    },

    redirectToSyncsEditor: (state) => {
      state.isRedirecting = true;
    },
    redirectToSyncsEditorSuccess: (state, { payload }: RedirectionReducerActions) => {
      state.loaded = true;
      state.isRedirecting = false;
      state.redirection = {};
    },
    redirectToSyncsEditorFailed: (state, { payload }: RedirectionReducerActions) => {
      state.isRedirecting = false;
      state.error = true;
      state.errorDetails = payload.errorDetails;
    },

    redirectToAudiencesCreator: (state) => {
      state.isRedirecting = true;
    },
    redirectToAudiencesCreatorSuccess: (state, { payload }: RedirectionReducerActions) => {
      state.loaded = true;
      state.isRedirecting = false;
      state.redirection = {};
    },
    redirectToAudiencesCreatorFailed: (state, { payload }: RedirectionReducerActions) => {
      state.isRedirecting = false;
      state.error = true;
      state.errorDetails = payload.errorDetails;
    },

    redirectToAudiencesEditor: (state) => {
      state.isRedirecting = true;
    },
    redirectToAudiencesEditorSuccess: (state, { payload }: RedirectionReducerActions) => {
      state.loaded = true;
      state.isRedirecting = false;
      state.redirection = {};
    },
    redirectToAudiencesEditorFailed: (state, { payload }: RedirectionReducerActions) => {
      state.isRedirecting = false;
      state.error = true;
      state.errorDetails = payload.errorDetails;
    },

    redirectToAudienceView: (state) => {
      state.isRedirecting = true;
    },
    redirectToAudienceViewSuccess: (state) => {
      state.loaded = true;
      state.isRedirecting = false;
      state.redirection = {};
    },
    redirectToAudienceViewFailed: (state, { payload }: RedirectionReducerActions) => {
      state.isRedirecting = false;
      state.error = true;
      state.errorDetails = payload.errorDetails;
    },
  },
});

export default redirectionsSlice;
