import { takeLatest, put, all } from 'redux-saga/effects';
// api
// connectionsSlice
// types
import { push } from 'connected-react-router';
import * as types from './types';
import redirectionsSlice from '@features/redirections/ducks/redirectionsSlice';

import syncsSlice from '@features/syncs/ducks/syncsSlice';
import audiencesSlice from '@features/audiences/ducks/audiencesSlice';
import { queryParamKeysEnum, routesEnum } from '@routes/types';

function* redirectToSyncsCreatorSaga() {
  try {
    yield put(push(routesEnum.SYNCS_CREATE));
    yield put({ type: redirectionsSlice.actions.redirectToSyncsCreatorSuccess.type, payload: {} });
  } catch (error) {
    yield put({
      type: redirectionsSlice.actions.redirectToSyncsCreatorFailed.type,
      payload: error,
    });
  }
}

function* redirectToSyncsEditorSaga({ payload }: types.RedirectToSyncsSaga) {
  try {
    const toRoute = `${routesEnum.SYNCS_EDIT}?${queryParamKeysEnum.QUERY_PARAM_ELEMENT}=${payload.syncKey}`;
    // api call to get sync by key
    yield put({
      type: syncsSlice.actions.updateActiveSync.type,
      payload: { activeSync: payload.activeSync },
    });
    yield put(push(toRoute));
    yield put({ type: redirectionsSlice.actions.redirectToSyncsEditorSuccess.type, payload: {} });
  } catch (error) {
    yield put({
      type: redirectionsSlice.actions.redirectToSyncsEditorFailed.type,
      payload: error,
    });
  }
}

function* redirectToAudiencesCreatorSaga() {
  try {
    yield put(push(routesEnum.AUDIENCES_CREATE));
    yield put({
      type: redirectionsSlice.actions.redirectToAudiencesCreatorSuccess.type,
      payload: {},
    });
  } catch (error) {
    yield put({
      type: redirectionsSlice.actions.redirectToAudiencesCreatorFailed.type,
      payload: error,
    });
  }
}

function* redirectToAudiencesEditorSaga({ payload }: types.RedirectToAudiencesSaga) {
  try {
    const { activeAudience, audienceKey } = payload;
    const toRoute: string = `${routesEnum.AUDIENCES_PREPARE_DATA}?${queryParamKeysEnum.QUERY_PARAM_ELEMENT}=${audienceKey}`;
    yield all([
      put({
        type: audiencesSlice.actions.updateActiveAudience.type,
        payload: { activeAudience: activeAudience },
      }),
      put({
        type: audiencesSlice.actions.updateActiveMappingRows.type,
        payload: { mappingRows: activeAudience.mappingRows },
      }),
      put({
        type: audiencesSlice.actions.setNewActiveSourceConfiguration.type,
        payload: { combinationLevel: activeAudience.sources.length },
      }),
      put(push(toRoute)),
      put({
        type: redirectionsSlice.actions.redirectToAudiencesEditorSuccess.type,
      }),
    ]);
  } catch (error) {
    yield put({
      type: redirectionsSlice.actions.redirectToAudiencesEditorFailed.type,
      payload: error,
    });
  }
}

function* redirectToAudienceViewSaga({ payload }: types.RedirectToAudiencesSaga) {
  try {
    const { activeAudience, audienceKey } = payload;
    const toRoute: string = `${routesEnum.AUDIENCES_VIEW}?${queryParamKeysEnum.QUERY_PARAM_ELEMENT}=${audienceKey}`;
    yield all([
      put({
        type: audiencesSlice.actions.updateActiveAudience.type,
        payload: { activeAudience },
      }),
      put({
        type: audiencesSlice.actions.updateActiveMappingRows.type,
        payload: { mappingRows: activeAudience.mappingRows },
      }),
      put(push(toRoute)),
      put({
        type: redirectionsSlice.actions.redirectToAudienceViewSuccess.type,
      }),
    ]);
  } catch (error) {
    yield put({
      type: redirectionsSlice.actions.redirectToAudienceViewFailed.type,
      payload: error,
    });
  }
}

export const redirectionsSagas = [
  takeLatest(redirectionsSlice.actions.redirectToSyncsCreator.type, redirectToSyncsCreatorSaga),
  takeLatest(redirectionsSlice.actions.redirectToSyncsEditor.type, redirectToSyncsEditorSaga),
  takeLatest(
    redirectionsSlice.actions.redirectToAudiencesCreator.type,
    redirectToAudiencesCreatorSaga
  ),
  takeLatest(
    redirectionsSlice.actions.redirectToAudiencesEditor.type,
    redirectToAudiencesEditorSaga
  ),
  takeLatest(redirectionsSlice.actions.redirectToAudienceView.type, redirectToAudienceViewSaga),
];
