export type ScheduleTypes = CronTriggers.REAL_TIME | CronTriggers.REGULAR | CronTriggers.MANUAL;
export enum CronTriggers {
  REAL_TIME = 'realTime',
  REGULAR = 'regular',
  MANUAL = 'manual',
}
export type ScheduleManual = { schedule: string; startingDate: moment.Moment };
export type ScheduleInterval = {
  every: number;
  dateType: string;
  at: moment.Moment;
  startingDate: moment.Moment;
};

export const scheduleTypes: { label: string; value: ScheduleTypes }[] = [
  {
    label: 'Real time',
    value: CronTriggers.REAL_TIME,
  },
  {
    label: 'Regular interval',
    value: CronTriggers.REGULAR,
  },
  {
    label: 'Manual',
    value: CronTriggers.MANUAL,
  },
];

export type DateIntervals = 'hour' | 'day' | 'month';
export enum ScheduleIntervals {
  HOUR = 'hour',
  DAY = 'day',
  MONTH = 'month',
}
export const scheduleIntervals: DateIntervals[] = ['hour', 'day', 'month'];
