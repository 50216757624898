import { takeLatest, call, put } from 'redux-saga/effects';
// api
import * as API from './api';
// Reducer Slice
import rawRecordsSlice, { RawRecordsState } from '@features/rawRecords/ducks/rawRecordsSlice';
// types
import * as types from './types';

// Generator function for retrieving the Raw data from the API
function* getRawRecordSaga({ payload }: types.GetRawRecordSaga) {
  try {
    const { connection, pointer } = payload;
    // console.log(payload);
    const getRawRecordsPayload = yield call(API.getRawData, connection, pointer);
    yield put({
      type: rawRecordsSlice.actions.getRawDataSuccess.type,
      payload: getRawRecordsPayload,
    });
  } catch (error) {
    yield put({ type: rawRecordsSlice.actions.getRawDataFailed.type, payload: error });
  }
}
export const rawRecordsSagas = [
  takeLatest(rawRecordsSlice.actions.getRawData.type, getRawRecordSaga),
];
