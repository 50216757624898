import { SchemaColumnWithReferenceColumn } from '@features/sources/ducks/types';
import { generateRandomInteger, generateId, generateRandomBoolean } from '@utils/helpers';
import moment from 'moment';

const faker = require('faker');

export const contactsRecordsGenerator = (recordsNumber: number) => {
  let contactsRecords = [];

  for (let i: number = 0; i <= recordsNumber; i++) {
    const firstName: string = faker.name.firstName();
    const lastName: string = faker.name.lastName();
    contactsRecords.push({
      userId: generateId(),
      firstName: firstName,
      lastName: lastName,
      fullName: firstName + ' ' + lastName,
      birthdate: faker.date.past(),
      language: faker.address.countryCode(),
      gender: faker.name.gender(),
      civility: faker.name.prefix(),
      address: faker.address.streetAddress(),
      postalCode: faker.address.zipCode(),
      city: faker.address.city(),
      region: '',
      countryName: faker.address.country(),
      countryCode: faker.address.countryCode(),
      email: faker.internet.email(),
      emailFormat: '',
      emailDomain: faker.internet.domainName(),
      emailValidity: '',
      emailValidityReason: '',
      phoneMobile: faker.phone.phoneNumber(),
      phoneLandline: faker.phone.phoneNumber(),
      phone: faker.phone.phoneNumber(),
      phoneValidity: '',
      phoneValidityReason: '',
      phoneCountry: faker.address.countryCode(),
      phoneNormalized: '',
      phoneType: '',
      consentEmail: generateRandomBoolean(),
      consentEmailUpdatedAt: faker.date.past(),
      consentSms: generateRandomBoolean(),
      consentSmsUpdatedAt: faker.date.past(),
      linkedin: '',
      website: '',
      companyName: faker.company.companyName(),
      jobTitle: faker.name.jobTitle(),
      createdAt: faker.date.past(),
      updatedAt: faker.date.past(),
    });
  }
  return contactsRecords;
};

export const ordersRecordsGenerator = (recordsNumber: number) => {
  let ordersRecords = [];

  for (let i: number = 0; i <= recordsNumber; i++) {
    const taxAmount: number = generateRandomInteger(1, 20);
    const shippingAmount: number = generateRandomInteger(1, 50);
    const discountAmount: number = generateRandomInteger(0, 10);
    ordersRecords.push({
      recordId: generateId(),
      contact_masterid: generateId().split('.')[1] + generateId().split('.')[1],
      totalAmount: faker.commerce.price() - discountAmount + shippingAmount,
      shippingAmount: shippingAmount,
      discountAmount: discountAmount,
      taxAmount: taxAmount,
      currency: faker.finance.currencyCode(),
      completed: 'true',
      storeId: generateId(),
      salesPerson: faker.name.findName(),
      createdAt: moment(new Date(faker.date.past())).format('YYYY-MM-DD').toString(),
      updatedAt: moment(new Date(faker.date.past())).format('YYYY-MM-DD').toString(),
    });
  }
  return ordersRecords;
};

export const ordersItemsRecordsGenerator = (recordsNumber: number) => {
  let ordersItemsRecords = [];

  for (let i: number = 0; i <= recordsNumber; i++) {
    const unitPrice: number = faker.commerce.price();
    const quantity: number = generateRandomInteger(1, 10);
    const taxAmount: number = generateRandomInteger(1, 20);
    const discountAmount: number = generateRandomInteger(0, 10);
    ordersItemsRecords.push({
      recordId: generateId(),
      orderId: generateId(),
      orderCompleted: (generateRandomInteger(1, 3) % 3) ? 'false' : 'true',
      contact_masterid: generateId().split('.')[1] + generateId().split('.')[1],
      productId: generateId(),
      unitPrice: unitPrice,
      quantity: quantity,
      taxAmount: taxAmount,
      discountAmount: discountAmount,
      totalAmount: unitPrice * quantity + taxAmount - discountAmount,
      currency: faker.finance.currencyCode(),
      createdAt: faker.date.past(),
      updatedAt:moment(new Date(faker.date.past())).format('YYYY-MM-DD').toString(),
    });
  }
  return ordersItemsRecords;
};

export const productsRecordsGenerator = (recordsNumber: number) => {
  let productsRecords = [];
  for (let i: number = 0; i <= recordsNumber; i++) {
    productsRecords.push({
      recordId: generateId(),
      createdAt: faker.date.past(),
      name: faker.commerce.productName(),
      brand: '',
      description: faker.commerce.productDescription(),
      price: faker.commerce.price(),
      category: faker.commerce.department(),
      imgUrl: '',
      sku: '',
      updatedAt: moment(new Date(faker.date.past())).locale('en'),
    });
  }
  return productsRecords;
};

export const storesRecordsGenerator = (recordsNumber: number) => {
  let storesRecords = [];
  for (let i: number = 0; i <= recordsNumber; i++) {
    storesRecords.push({
      recordId: generateId(),
      createdAt: faker.date.past(),
      name: faker.company.companyName(),
      storeType: '',
      manager: faker.name.findName(),
      updatedAt: moment(new Date(faker.date.past())).locale('en'),
    });
  }
  return storesRecords;
};

export const eventsRecordsGenerator = (recordsNumber: number) => {
  let eventsRecords = [];
  for (let i: number = 0; i <= recordsNumber; i++) {
    eventsRecords.push({
      recordId: generateId(),
      contactId: generateId(),
      name: '',
      valueNumber: generateRandomInteger(1, 100),
      valueDate: faker.date.past(),
      createdAt: faker.date.past(),
      updatedAt: moment(new Date(faker.date.past())).locale('en'),
    });
  }
  return eventsRecords;
};

export const eventsStatsRecordsGenerator = (recordsNumber: number) => {
  let eventsStatsRecords = [];
  for (let i: number = 0; i <= recordsNumber; i++) {
    eventsStatsRecords.push({
      recordId: generateId(),
      contactId: generateId(),
      name: '',
      valueNumber: generateRandomInteger(1, 100),
      valueDate: faker.date.past(),
      updatedAt: faker.date.past(),
      createdAt: faker.date.past(),
    });
  }
  return eventsStatsRecords;
};

export const consentsRecordsGenerator = (recordsNumber: number) => {
  let consentsRecords = [];
  for (let i: number = 0; i <= recordsNumber; i++) {
    consentsRecords.push({
      recordId: generateId(),
      contact_masterid: generateId().split('.')[1] + generateId().split('.')[1],
      value: (new Array('true', 'true', 'true', 'false'))[generateRandomInteger(0, 3)],
      source: (generateRandomInteger(1, 2) % 2) ? 'offline' : 'online',
      category: (new Array('email', 'email', 'sms'))[generateRandomInteger(0, 3)],
      createdAt: moment(new Date(faker.date.past())).format('YYYY-MM-DD').toString(),
      updatedAt: moment(new Date(faker.date.past())).format('YYYY-MM-DD').toString(),
    });
  }
  return consentsRecords;
};

const getMeFakerFunctionByColumnName = (key: string) => {
  const typesByColumn: { [key: string]: string | boolean | number } = {
    userId: generateId(),
    firstName: faker.name.firstName(),
    lastName: faker.name.lastName(),
    fullName: faker.name.firstName() + ' ' + faker.name.lastName(),
    birthdate: faker.date.past(),
    language: faker.address.countryCode(),
    gender: faker.name.gender(),
    civility: faker.name.prefix(),
    address: faker.address.streetAddress(),
    postalCode: faker.address.zipCode(),
    city: faker.address.city(),
    region: '',
    countryName: faker.address.country(),
    countryCode: faker.address.countryCode(),
    email: faker.internet.email(),
    emailFormat: '',
    emailDomain: faker.internet.domainName(),
    emailValidity: '',
    emailValidityReason: '',
    phoneMobile: faker.phone.phoneNumber(),
    phoneLandline: faker.phone.phoneNumber(),
    phone: faker.phone.phoneNumber(),
    phoneValidity: '',
    phoneValidityReason: '',
    phoneCountry: faker.address.countryCode(),
    phoneNormalized: '',
    phoneType: '',
    consentEmail: generateRandomBoolean(),
    consentEmailUpdatedAt: faker.date.past(),
    consentSms: generateRandomBoolean(),
    consentSmsUpdatedAt: faker.date.past(),
    linkedin: '',
    website: '',
    companyName: faker.company.companyName(),
    jobTitle: faker.name.jobTitle(),
    createdAt: faker.date.past(),
    updatedAt: faker.date.past(),
    recordId: generateId(),
    contactId: generateId(),
    totalAmount:
      faker.commerce.price() - generateRandomInteger(0, 10) + generateRandomInteger(1, 50),
    shippingAmount: generateRandomInteger(1, 50),
    discountAmount: generateRandomInteger(0, 10),
    taxAmount: generateRandomInteger(1, 20),
    currency: faker.finance.currencyCode(),
    completed: generateRandomBoolean(),
    storeId: generateId(),
    salesPerson: faker.name.findName(),
    name: faker.commerce.productName(),
    brand: '',
    description: faker.commerce.productDescription(),
    price: faker.commerce.price(),
    category: faker.commerce.department(),
    imgUrl: '',
    sku: '',
  };
  return typesByColumn[key] ? typesByColumn[key] : 'no value';
};
export const generateRecordsFromSchema = (
  schema: SchemaColumnWithReferenceColumn[],
  recordsNumber: number
) => {
  let records = [];
  for (let i: number = 0; i <= recordsNumber; i++) {
    let record = {};
    schema.forEach((column) => {
      const key: string = column.referenceColumnName ? column.referenceColumnName : '';
      const newName: string = column.columnName;
      record = { ...record, [newName]: getMeFakerFunctionByColumnName(key) };
    });
    records.push(record);
  }
  return records;
};
