// Importing type
import * as apiTypes from './types';
import { RightsType, Profile } from '@features/profiles/types';
import { generateId, sleep } from '@utils/helpers';
import { allRights } from '@features/profiles/profilesHelpers';

const getProfiles: apiTypes.APIGetProfiles = async (profileName: string) => {
  await sleep(500);

  let adminProfile: Profile;
  let marketingProfile: Profile;
  let dataProfile: Profile;
  let error: boolean = false;
  let errorDetails: string | undefined = undefined;
  adminProfile = {
    id: generateId(),
    name: 'admin',
    type: 'admin',
    rights: {
      recommandations: allRights,
      connections: allRights,
      syncs: allRights,
      audiences: allRights,
      segments: allRights,
      sources: allRights,
      destinations: allRights,
      recipes: allRights,
      objects: allRights,
      flows: allRights,
      internalFlows: allRights,
      sourceFlows: allRights,
      destinationFlows: allRights,
      users: allRights,
      accountManagement: allRights,
    },
  };
  marketingProfile = {
    id: generateId(),
    name: 'marketing',
    type: 'marketing',
    rights: {
      recommandations: [],
      connections: allRights,
      audiences: allRights,
      syncs: allRights,
      segments: [],
      sources: [],
      destinations: [],
      objects: [],
      recipes: [],
      flows: [],
      internalFlows: [],
      sourceFlows: [],
      destinationFlows: [],
      users: [],
      accountManagement: [],
    },
  };
  dataProfile = {
    id: generateId(),
    name: 'data',
    type: 'data',
    rights: {
      recommandations: allRights,
      connections: allRights,
      audiences: allRights,
      syncs: allRights,
      segments: [],
      sources: [],
      destinations: [],
      recipes: [],
      objects: [],
      flows: [],
      internalFlows: [],
      sourceFlows: [],
      destinationFlows: [],
      users: [],
      accountManagement: [],
    },
  };
  if (!marketingProfile) {
    error = true;
    errorDetails = 'No profile found.';
  }

  return {
    error,
    profiles: [adminProfile, marketingProfile, dataProfile],
    loaded: true,
    errorDetails: error && errorDetails ? errorDetails : undefined,
  };
};
const getProfile: apiTypes.APIGetProfile = async (profileName: string) => {
  await sleep(1000);

  let profile: Profile;
  let rights: RightsType;
  let error: boolean = false;
  let errorDetails: string | undefined = undefined;

  if (profileName === 'admin') {
    rights = {
      recommandations: allRights,
      connections: allRights,
      audiences: allRights,
      syncs: allRights,
      segments: allRights,
      recipes: allRights,
      flows: allRights,
      internalFlows: allRights,
      sourceFlows: allRights,
      sources: allRights,
      destinationFlows: allRights,
      destinations: allRights,
      objects: allRights,
      users: allRights,
      accountManagement: allRights,
    };
  } else if (profileName === 'marketing') {
    rights = {
      recommandations: allRights,
      connections: allRights,
      audiences: allRights,
      syncs: [],
      segments: [],
      sources: allRights,
      destinations: allRights,
      recipes: [],
      flows: [],
      internalFlows: [],
      sourceFlows: [],
      destinationFlows: [],
      objects: [],
      users: [],
      accountManagement: [],
    };
  } else if (profileName === 'data') {
    rights = {
      recommandations: allRights,
      connections: allRights,
      audiences: allRights,
      syncs: [],
      segments: [],
      recipes: [],
      flows: [],
      internalFlows: [],
      sourceFlows: [],
      destinationFlows: [],
      sources: [],
      destinations: [],
      objects: [],
      users: [],
      accountManagement: [],
    };
  } else {
    rights = {
      recommandations: [],
      connections: allRights,
      audiences: [],
      syncs: [],
      segments: [],
      recipes: [],
      flows: [],
      internalFlows: [],
      sourceFlows: [],
      destinationFlows: [],
      sources: [],
      destinations: [],
      objects: [],
      users: [],
      accountManagement: [],
    };
  }
  profile = {
    rights,
    id: 'carsupadmin',
    name: profileName,
    type: 'admin',
  };
  if (!profile) {
    error = true;
    errorDetails = 'No profile found.';
  }

  return {
    error,
    profile,
    loaded: true,
    errorDetails: error && errorDetails ? errorDetails : undefined,
  };
};
const saveProfiles: apiTypes.APISaveProfiles = async (profiles: Profile[]) => {
  await sleep(1000);

  let error: boolean = false;
  let errorDetails: string | undefined = undefined;

  if (!profiles) {
    error = true;
    errorDetails = 'no profiles to save';
  }
  return {
    error,
    profiles,
    loaded: true,
    errorDetails: error && errorDetails ? errorDetails : undefined,
  };
};

export { getProfile, getProfiles, saveProfiles };
