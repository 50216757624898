import { takeLatest, call, put, all } from 'redux-saga/effects';
// api
import * as API from './api';
import * as RawRecordsAPI from '@features/rawRecords/ducks/api';
// types
import * as types from './types';
import objectsSlice from '@features/objects/ducks/objectsSlice';
import {
  ApiGetObjectPayload,
  ApiGetObjectsPayload,
  ApiGetObjectCategoriesPayload,
  ApigetObjectRecordStatsPayload,
} from './types';
import generateObject, { generateSchemaFromRecords } from '@features/objects/generateObject';
import sourcesSlice from '@features/sources/ducks/sourcesSlice';
import { ApiRawRecordPayload } from '@features/rawRecords/ducks/types';
import audiencesSlice from '@features/audiences/ducks/audiencesSlice';

// @@@Amine here we are gonna define the sagas which will trigger the state with actions
// depending on the api calls responses

function* getObjectsSaga() {
  try {
    const getObjectsPayload: ApiGetObjectsPayload = yield call(API.getObjects);
    yield put({ type: objectsSlice.actions.getObjectsSuccess.type, payload: getObjectsPayload });
  } catch (error) {
    yield put({ type: objectsSlice.actions.getObjectsFailed.type, payload: error });
  }
}

function* getObjectSaga({ payload }: types.GetObjectSaga) {
  try {
    const { objectKey } = payload;
    const getObjectPayload: ApiGetObjectPayload = yield call(API.getObject, objectKey);
    yield put({ type: objectsSlice.actions.getObjectSuccess.type, payload: getObjectPayload });
  } catch (error) {
    yield put({ type: objectsSlice.actions.getObjectFailed.type, payload: error });
  }
}
function* getRecordsObjectSaga({ payload }: types.GetRecordsObject) {
  try {
    const { connection, pointer } = payload;
    const getRecordsObjectPayload: ApiGetObjectPayload = yield call(
      API.getRecordsObject,
      connection,
      pointer
    );
    yield put({
      type: objectsSlice.actions.getRecordsObjectSuccess.type,
      payload: getRecordsObjectPayload,
    });
  } catch (error) {
    yield put({ type: objectsSlice.actions.getRecordsObjectFailed.type, payload: error });
  }
}

function* saveObjectSaga({ payload }: types.SaveObjectSaga) {
  try {
    const { object } = payload;
    const saveObjectPayload: ApiGetObjectPayload = yield call(API.saveObject, object);
    yield put({ type: objectsSlice.actions.saveObjectSuccess.type, payload: saveObjectPayload });
  } catch (error) {
    yield put({
      type: objectsSlice.actions.saveObjectFailed.type,
      payload: { error, errorDetails: error },
    });
  }
}

function* saveSourceObjectSaga({ payload }: types.SaveSourceObjectSaga) {
  try {
    const { connection, pointer, sourceName } = payload;
    const getConnectionRawRecordsPayload: ApiRawRecordPayload = yield call(
      RawRecordsAPI.getRawData,
      connection,
      pointer
    );

    let object = generateObject(`Source_${sourceName}`);
    const sourceRawRecords = getConnectionRawRecordsPayload.records;
    const sourceObjectSchema = generateSchemaFromRecords(sourceRawRecords[0]);
    object = {
      ...object,
      records: sourceRawRecords,
      schema: sourceObjectSchema,
      primaryKeys: [...object.primaryKeys, sourceObjectSchema[0].id],
    };
    const saveObjectPayload: ApiGetObjectPayload = yield call(API.saveObject, object);
    yield put({ type: objectsSlice.actions.saveObjectSuccess.type, payload: saveObjectPayload });
    yield put({
      type: sourcesSlice.actions.setActiveSourceObject.type,
      payload: saveObjectPayload,
    });
  } catch (error) {
    yield put({
      type: objectsSlice.actions.saveObjectFailed.type,
      payload: { error, errorDetails: error },
    });
  }
}

function* saveSourceTargetObjectSaga({ payload }: types.SaveObjectSaga) {
  try {
    const { object } = payload;
    const saveObjectPayload: ApiGetObjectPayload = yield call(API.saveObject, object);
    yield put({ type: objectsSlice.actions.saveObjectSuccess.type, payload: saveObjectPayload });
    yield put({
      type: sourcesSlice.actions.setActiveSourceTargetObject.type,
      payload: saveObjectPayload,
    });
  } catch (error) {
    yield put({
      type: objectsSlice.actions.saveObjectFailed.type,
      payload: { error, errorDetails: error },
    });
  }
}
function* deleteObjectSaga({ payload }: types.DeleteObjectSaga) {
  const { object } = payload;
  try {
    const deleteObjectPayload: ApiGetObjectPayload = yield call(API.deleteObject, object);
    yield put({
      type: objectsSlice.actions.deleteObjectSuccess.type,
      payload: deleteObjectPayload,
    });
  } catch (error) {
    yield put({ type: objectsSlice.actions.deleteObjectFailed.type, payload: error });
  }
}
// Business Categories saga functions NEW
function* getBusinessCategoriesSaga() {
  try {
    const getBusinessCategoriesPayload: types.ApiGetBusinessCategoriesPayload = yield call(
      API.getBusinessCategories
    );
    yield put({
      type: objectsSlice.actions.getBusinessCategoriesSuccess.type,
      payload: getBusinessCategoriesPayload,
    });
  } catch (error) {
    yield put({ type: objectsSlice.actions.getBusinessCategoriesFailed.type, payload: error });
  }
}
function* getBusinessCategorySaga({ payload }: types.GetBusinessCategorySaga) {
  try {
    const { businessCategoryKey } = payload;
    const getBusinessCategoryPayload: types.ApiGetBusinessCategoryPayload = yield call(
      API.getBusinessCategory,
      businessCategoryKey
    );
    yield put({
      type: objectsSlice.actions.getBusinessCategorySuccess.type,
      payload: getBusinessCategoryPayload,
    });
  } catch (error) {
    yield put({ type: objectsSlice.actions.getBusinessCategoryFailed.type, payload: error });
  }
}
function* saveBusinessCategorySaga({ payload }: types.SaveBusinessCategorySaga) {
  try {
    const { name } = payload;
    const saveBusinessCategoryPayload: types.ApiBusinessCategoryPayload = yield call(
      API.saveBusinessCategory,
      name
    );
    yield put({
      type: objectsSlice.actions.saveBusinessCategorySuccess.type,
      payload: saveBusinessCategoryPayload,
    });
  } catch (error) {
    yield put({
      type: objectsSlice.actions.saveBusinessCategoryFailed.type,
      payload: { error, errorDetails: error },
    });
  }
}
function* saveAndAssignBusinessCategoryToAudienceSaga({ payload }: types.SaveBusinessCategorySaga) {
  try {
    const { name } = payload;
    const saveBusinessCategoryPayload: types.ApiBusinessCategoryPayload = yield call(
      API.saveBusinessCategory,
      name
    );
    yield all([
      put({
        type: objectsSlice.actions.saveBusinessCategorySuccess.type,
        payload: saveBusinessCategoryPayload,
      }),
      put({
        type: audiencesSlice.actions.assignBusinessCategoryToActiveSource.type,
        payload: saveBusinessCategoryPayload,
      }),
    ]);
  } catch (error) {
    yield put({
      type: objectsSlice.actions.saveBusinessCategoryFailed.type,
      payload: { error, errorDetails: error },
    });
  }
}
function* saveAndAssignBusinessTermToAudienceSaga({ payload }: types.SaveBusinessTermSaga) {
  try {
    const { mappignRowKey, businessCategory, businessTermName, businessTermType } = payload;
    const saveBusinessCategoryPayload: types.ApiBusinessTermPayload = yield call(
      API.saveBusinessTermToBusinessCategory,
      businessCategory,
      businessTermName,
      businessTermType
    );
    yield all([
      put({
        type: objectsSlice.actions.saveBusinessCategorySuccess.type,
        payload: saveBusinessCategoryPayload,
      }),
      put({
        type: audiencesSlice.actions.assignBusinessTermToActiveAudienceColumn.type,
        payload: {
          mappignRowKey,
          businessTerm: saveBusinessCategoryPayload.businessTerm,
          businessCategory: saveBusinessCategoryPayload.businessCategory,
        },
      }),
    ]);
  } catch (error) {
    yield put({
      type: objectsSlice.actions.saveBusinessCategoryFailed.type,
      payload: { error, errorDetails: error },
    });
  }
}
function* deleteBusinessCategorySaga({ payload }: types.DeleteBusinessCategorySaga) {
  const { businessCategory } = payload;
  try {
    const deleteBusinessCategoryPayload: types.ApiBusinessCategoryPayload = yield call(
      API.deleteBusinessCategory,
      businessCategory
    );
    yield put({
      type: objectsSlice.actions.deleteBusinessCategorySuccess.type,
      payload: deleteBusinessCategoryPayload,
    });
  } catch (error) {
    yield put({ type: objectsSlice.actions.deleteBusinessCategoryFailed.type, payload: error });
  }
}

// object Categories saga functions OLD
function* getObjectCategoriesSaga() {
  try {
    const getCategoriesPayload: ApiGetObjectCategoriesPayload = yield call(API.getObjectCategories);
    yield put({
      type: objectsSlice.actions.getCategoriesSuccess.type,
      payload: getCategoriesPayload,
    });
  } catch (error) {
    yield put({ type: objectsSlice.actions.getCategoriesFailed.type, payload: error });
  }
}
function* getCategorySaga({ payload }: types.GetCategorySaga) {
  try {
    const { categoryKey } = payload;
    const getCategoryPayload: types.ApiGetCategoryPayload = yield call(API.getObject, categoryKey);
    yield put({ type: objectsSlice.actions.getCategorySuccess.type, payload: getCategoryPayload });
  } catch (error) {
    yield put({ type: objectsSlice.actions.getCategoryFailed.type, payload: error });
  }
}

function* saveCategorySaga({ payload }: types.SaveCategorySaga) {
  try {
    const { category } = payload;
    const saveCategoryPayload: types.ApiGetCategoryPayload = yield call(API.saveCategory, category);
    yield put({
      type: objectsSlice.actions.saveCategorySuccess.type,
      payload: saveCategoryPayload,
    });
  } catch (error) {
    yield put({
      type: objectsSlice.actions.saveCategoryFailed.type,
      payload: { error, errorDetails: error },
    });
  }
}
function* deleteCategorySaga({ payload }: types.DeleteCategorySaga) {
  const { category } = payload;
  try {
    const deleteObjectPayload: types.ApiGetCategoryPayload = yield call(
      API.deleteCategory,
      category
    );
    yield put({
      type: objectsSlice.actions.deleteCategorySuccess.type,
      payload: deleteObjectPayload,
    });
  } catch (error) {
    yield put({ type: objectsSlice.actions.deleteCategoryFailed.type, payload: error });
  }
}

// Schema pointers saga functions
function* getSchemaPointersSaga() {
  try {
    const GetschemaPointersPayload: types.ApiGetSchemaPointersPayload = yield call(
      API.getSchemaPointers
    );
    yield put({
      type: objectsSlice.actions.getSchemaPointersSuccess.type,
      payload: GetschemaPointersPayload,
    });
  } catch (error) {
    yield put({ type: objectsSlice.actions.getSchemaPointersFailed.type, payload: error });
  }
}
// Schema Categories saga functions
function* getSchemaCategoriesSaga() {
  try {
    const getSchemaCategoriesPayload: types.ApiGetSchemaCategoriesPayload = yield call(
      API.getSchemaCategories
    );
    yield put({
      type: objectsSlice.actions.getSchemaCategoriesSuccess.type,
      payload: getSchemaCategoriesPayload,
    });
  } catch (error) {
    yield put({ type: objectsSlice.actions.getSchemaCategoriesFailed.type, payload: error });
  }
}
function* getSchemaCategorySaga({ payload }: types.GetSchemaCategorySaga) {
  try {
    const { categoryKey } = payload;
    const getSchemaCategoryPayload: types.ApiGetSchemaCategoryPayload = yield call(
      API.getSchemaCategory,
      categoryKey
    );
    yield put({
      type: objectsSlice.actions.getCategorySuccess.type,
      payload: getSchemaCategoryPayload,
    });
  } catch (error) {
    yield put({ type: objectsSlice.actions.getCategoryFailed.type, payload: error });
  }
}

function* saveSchemaCategorySaga({ payload }: types.SaveSchemaCategorySaga) {
  try {
    const { schemaCategory } = payload;
    const saveSchemaCategoryPayload: types.ApiGetSchemaCategoryPayload = yield call(
      API.saveSchemaCategory,
      schemaCategory
    );
    yield put({
      type: objectsSlice.actions.saveSchemaCategorySuccess.type,
      payload: saveSchemaCategoryPayload,
    });
  } catch (error) {
    yield put({
      type: objectsSlice.actions.saveSchemaCategoryFailed.type,
      payload: { error, errorDetails: error },
    });
  }
}
function* deleteSchemaCategorySaga({ payload }: types.DeleteSchemaCategorySaga) {
  const { schemaCategory } = payload;
  try {
    const deleteObjectPayload: types.ApiGetSchemaCategoryPayload = yield call(
      API.deleteSchemaCategory,
      schemaCategory
    );
    yield put({
      type: objectsSlice.actions.deleteSchemaCategorySuccess.type,
      payload: deleteObjectPayload,
    });
  } catch (error) {
    yield put({ type: objectsSlice.actions.deleteSchemaCategoryFailed.type, payload: error });
  }
}

// Objects Stats funtion
function* getObjectRecordStatsSaga() {
  try {
    const getObjectRecordStatsPayload: ApigetObjectRecordStatsPayload = yield call(
      API.getObjectRecordStats
    );
    yield put({
      type: objectsSlice.actions.getObjectRecordStatsSuccess.type,
      payload: getObjectRecordStatsPayload,
    });
  } catch (error) {
    yield put({ type: objectsSlice.actions.getObjectRecordStatsFailed.type, payload: error });
  }
}
function* getObjectRecordStatByColumnSaga({ payload }: types.GetObjectRecordStatByColumnSaga) {
  const { column } = payload;
  try {
    const getObjectRecordStatByColumnPayload: ApigetObjectRecordStatsPayload = yield call(
      API.getObjectRecordStatByColumn,
      column
    );
    yield put({
      type: objectsSlice.actions.getObjectRecordStatByColumnSuccess.type,
      payload: getObjectRecordStatByColumnPayload,
    });
  } catch (error) {
    yield put({
      type: objectsSlice.actions.getObjectRecordStatByColumnSuccess.type,
      payload: error,
    });
  }
}

export const objectsSagas = [
  takeLatest(objectsSlice.actions.getObjects.type, getObjectsSaga),
  takeLatest(objectsSlice.actions.getObject.type, getObjectSaga),
  takeLatest(objectsSlice.actions.saveObject.type, saveObjectSaga),
  takeLatest(objectsSlice.actions.deleteObject.type, deleteObjectSaga),
  takeLatest(objectsSlice.actions.getRecordsObject.type, getRecordsObjectSaga),
  takeLatest(objectsSlice.actions.saveSourceObject.type, saveSourceObjectSaga),
  takeLatest(objectsSlice.actions.saveSourceTargetObject.type, saveSourceTargetObjectSaga),
  takeLatest(objectsSlice.actions.getObjectRecordStats.type, getObjectRecordStatsSaga),
  takeLatest(
    objectsSlice.actions.getObjectRecordStatByColumn.type,
    getObjectRecordStatByColumnSaga
  ),
  // object category sagas NEW
  takeLatest(objectsSlice.actions.getBusinessCategories.type, getBusinessCategoriesSaga),
  takeLatest(objectsSlice.actions.getBusinessCategory.type, getBusinessCategorySaga),
  takeLatest(objectsSlice.actions.saveBusinessCategory.type, saveBusinessCategorySaga),
  takeLatest(
    objectsSlice.actions.saveAndAssignBusinessCategory.type,
    saveAndAssignBusinessCategoryToAudienceSaga
  ),
  takeLatest(
    objectsSlice.actions.saveAndAssignBusinessTerm.type,
    saveAndAssignBusinessTermToAudienceSaga
  ),
  takeLatest(objectsSlice.actions.deleteBusinessCategory.type, deleteBusinessCategorySaga),
  // object category sagas OLD
  takeLatest(objectsSlice.actions.getCategories.type, getObjectCategoriesSaga),
  takeLatest(objectsSlice.actions.getCategory.type, getCategorySaga),
  takeLatest(objectsSlice.actions.saveCategory.type, saveCategorySaga),
  takeLatest(objectsSlice.actions.deleteCategory.type, deleteCategorySaga),
  // schema pointers sagas
  takeLatest(objectsSlice.actions.getSchemaPointers.type, getSchemaPointersSaga),
  // schema categories sagas
  takeLatest(objectsSlice.actions.getSchemaCategories.type, getSchemaCategoriesSaga),
  takeLatest(objectsSlice.actions.getSchemaCategory.type, getSchemaCategorySaga),
  takeLatest(objectsSlice.actions.saveSchemaCategory.type, saveSchemaCategorySaga),
  takeLatest(objectsSlice.actions.deleteSchemaCategory.type, deleteSchemaCategorySaga),
];
