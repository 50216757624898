import { Features } from '@features/appContext/types';
import HiddenFeature from '@screens/hiddenFeature/HiddenFeature';
import InaccessibleFeature from '@screens/inaccessibleFeature/InaccessibleFeature';
import UnderConstructionFeature from '@screens/underConstructionFeature/UnderConstructionFeature';
import React from 'react';
import { Route } from 'react-router';
import { routesEnum } from './types';

const Recommandations = React.lazy(() => import('@screens/recommandations'));

const Connections = React.lazy(() => import('@screens/connections'));
const Audiences = React.lazy(() => import('@screens/audiences/list'));
const AudienceCreate = React.lazy(() => import('@screens/audiences/create'));
const AudienceView = React.lazy(() => import('@screens/audiences/view'));
const AudiencePrepareObject = React.lazy(() => import('@screens/audiences/prepareObject'));

const Syncs = React.lazy(() => import('@screens/syncs/list'));
const SyncsCreate = React.lazy(() => import('@screens/syncs/create'));
const SyncsEdit = React.lazy(() => import('@screens/syncs/edit'));
const Segments = React.lazy(() => import('@screens/segments'));
const CreateSegment = React.lazy(() => import('@screens/segments/create'));
const EditSegment = React.lazy(() => import('@screens/segments/edit'));

const Sources = React.lazy(() => import('@screens/sources'));
const RecipesEditor = React.lazy(() => import('@screens/recipes/editor'));
const Recipes = React.lazy(() => import('@screens/recipes'));
const Flows = React.lazy(() => import('@screens/flows'));
const SourceFlows = React.lazy(() => import('@screens/sourceFlows'));
const AccountManagement = React.lazy(() => import('@screens/management'));
const InternalFlows = React.lazy(() => import('@screens/management/internalFlows'));
const Objects = React.lazy(() => import('@screens/objects'));

const publicPaths: {
  exact: boolean;
  featureName: Features;
  path: routesEnum;
  component: React.FC;
}[] = [
  {
    exact: true,
    featureName: 'recommandations',
    path: routesEnum.RECOMMANDATIONS,
    component: Recommandations,
  },
  {
    exact: true,
    featureName: 'connections',
    path: routesEnum.CONNECTONS,
    component: Connections,
  },
  {
    exact: true,
    featureName: 'audiences',
    path: routesEnum.AUDIENCES,
    component: Audiences,
  },
  {
    exact: true,
    featureName: 'audiences',
    path: routesEnum.AUDIENCES_CREATE,
    component: AudienceCreate,
  },
  {
    exact: true,
    featureName: 'audiences',
    path: routesEnum.AUDIENCES_VIEW,
    component: AudienceView,
  },
  {
    exact: true,
    featureName: 'audiences',
    path: routesEnum.AUDIENCES_PREPARE_DATA,
    component: AudiencePrepareObject,
  },

  {
    exact: true,
    featureName: 'syncs',
    path: routesEnum.SYNCS,
    component: Syncs,
  },
  { exact: true, featureName: 'syncs', path: routesEnum.SYNCS_CREATE, component: SyncsCreate },
  { exact: true, featureName: 'syncs', path: routesEnum.SYNCS_EDIT, component: SyncsEdit },
  {
    exact: true,
    featureName: 'segments',
    path: routesEnum.SEGMENTS,
    component: Segments,
  },
  {
    exact: true,
    featureName: 'segments',
    path: routesEnum.SEGMENTS_CREATE,
    component: CreateSegment,
  },
  {
    exact: true,
    featureName: 'segments',
    path: routesEnum.SEGMENTS_EDIT,
    component: EditSegment,
  },
  {
    exact: true,
    featureName: 'sources',
    path: routesEnum.SOURCES,
    component: Sources,
  },
  {
    exact: true,
    featureName: 'recipes',
    path: routesEnum.RECIPES,
    component: Recipes,
  },
  {
    exact: true,
    featureName: 'recipes',
    path: routesEnum.RECIPES_EDITOR,
    component: RecipesEditor,
  },
  {
    exact: true,
    featureName: 'flows',
    path: routesEnum.FLOWS,
    component: Flows,
  },
  {
    exact: true,
    featureName: 'sourceFlows',
    path: routesEnum.FLOWS_SOURCES,
    component: SourceFlows,
  },
  {
    exact: true,
    featureName: 'internalFlows',
    path: routesEnum.FLOWS_INTERNAL,
    component: InternalFlows,
  },

  {
    exact: true,
    featureName: 'objects',
    path: routesEnum.OBJECTS,
    component: Objects,
  },
  {
    exact: true,
    featureName: 'accountManagement',
    path: routesEnum.ACCOUNT_MANAGEMENT,
    component: AccountManagement,
  },
  {
    exact: true,
    featureName: 'destinationFlows',
    path: routesEnum.FLOWS_DESTINATION,
    component: () => <UnderConstructionFeature />,
  },
  {
    exact: true,
    featureName: 'destinations',
    path: routesEnum.DESTINATION,
    component: () => <UnderConstructionFeature />,
  },
];

const PublicRoutes = ({ visibleFeature, accessibleFeature, profileFeatures }: any) => {
  return publicPaths.map(({ path, featureName, component: Component, ...props }) => (
    <Route
      key={path}
      path={path}
      render={() => {
        if (!visibleFeature.includes(featureName)) return <HiddenFeature />;
        if (!accessibleFeature.includes(featureName)) return <InaccessibleFeature />;
        if (!profileFeatures) return <></>;
        if (profileFeatures.includes(featureName)) return <Component />;
        return <InaccessibleFeature />;
      }}
      {...props}
    />
  ));
};
export default PublicRoutes;
