import { Row } from 'antd';
import React, { CSSProperties } from 'react';
import { OctoSelectCardOption } from '../types';
interface OctoSelectCardProps {
  options: OctoSelectCardOption[];
  disabledCardsValues: string[];
  selectedValue?: string;
  position?: 'horizontal' | 'vertical';
  containerClassName?: string;
  containerStyle?: CSSProperties;
  onClick: (value: string) => void;
}
const OctoSelectCard = ({
  options,
  selectedValue,
  position,
  disabledCardsValues,
  containerClassName,
  containerStyle,
  onClick,
}: OctoSelectCardProps) => {
  const containerInitialClassName: string = 'octo-select-card__container ';
  const classNameValue = (value: string) => {
    const isActive: boolean = selectedValue === value;
    if (value && disabledCardsValues.indexOf(value) !== -1) {
      return `${containerInitialClassName}  octo-select-card__btn-disabled`;
    }
    return containerClassName && isActive
      ? `${containerInitialClassName} ${containerClassName} octo-select-card__btn-active`
      : containerClassName && !isActive
      ? `${containerInitialClassName} ${containerClassName}`
      : !containerClassName && isActive
      ? `${containerInitialClassName}  octo-select-card__btn-active`
      : `${containerInitialClassName}`;
  };
  const VerticalDisplay = () => {
    return (
      <div>
        {options.map((option) => (
          <Row align={'middle'} className="octolis__default-margin-top-bot" key={option.value}>
            <div
              className={classNameValue(option.value)}
              style={containerStyle}
              onClick={() => {
                if (disabledCardsValues.indexOf(option.value) === -1) {
                  onClick(option.value);
                }
              }}
            >
              {option.content}
            </div>
            {option.optionalVerticalContent}
          </Row>
        ))}
      </div>
    );
  };
  const HorizontalDisplay = () => {
    return (
      <Row align={'middle'} className="octolis__default-margin-top-bot">
        {options.map((option) => (
          <div
            key={option.value}
            className={classNameValue(option.value)}
            style={containerStyle}
            onClick={() => {
              if (disabledCardsValues.indexOf(option.value) === -1) {
                onClick(option.value);
              }
            }}
          >
            {option.content}
          </div>
        ))}
      </Row>
    );
  };
  return <> {position === 'vertical' ? <VerticalDisplay /> : <HorizontalDisplay />}</>;
};

export default OctoSelectCard;
