import { takeLatest, call, put } from 'redux-saga/effects';
import * as API from './api';
import {
  APIGetIntegrationSchemaPayload,
  APIGetIntegrationsPayload,
  GetIntegrationSaga,
  GetIntegrationsSaga,
} from '@features/integrations/ducks/types';
import integrationsSlice from './integrationsSlice';
import syncsSlice from '@features/syncs/ducks/syncsSlice';
import generateObject from '@features/objects/generateObject';

function* getIntegrationsSaga({ payload }: GetIntegrationsSaga) {
  try {
    const getIntegrationsPayload: APIGetIntegrationsPayload = yield call(API.getIntegrations);
    yield put({
      type: integrationsSlice.actions.getIntegrationsSuccess.type,
      payload: getIntegrationsPayload,
    });
  } catch (error) {
    yield put({ type: integrationsSlice.actions.getIntegrationsFailed.type, payload: error });
  }
}
function* getIntegrationSchemaForSyncSaga({ payload }: GetIntegrationSaga) {
  try {
    const { integration } = payload;
    const getIntegrationSchemaPayload: APIGetIntegrationSchemaPayload = yield call(
      API.getIntegrationSchema,
      integration
    );
    const integrationObject = {
      ...generateObject(`${integration.name}-object`),
      schema: getIntegrationSchemaPayload.schema,
    };
    yield put({
      type: syncsSlice.actions.setActiveSyncIntegrationObject.type,
      payload: { object: integrationObject },
    });
    yield put({
      type: integrationsSlice.actions.getIntegrationSchemaSuccess.type,
      payload: { object: integrationObject },
    });
  } catch (error) {
    yield put({
      type: integrationsSlice.actions.getIntegrationSchemaFailed.type,
      payload: { errorDetails: error },
    });
  }
}
export const integrationsSaga = [
  takeLatest(integrationsSlice.actions.getIntegrations.type, getIntegrationsSaga),
  takeLatest(integrationsSlice.actions.getIntegrationSchema.type, getIntegrationSchemaForSyncSaga),
];
