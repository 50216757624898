import { useOctoExplorer, OctoExplorer } from '../../components/explorer';
import React, { useEffect } from 'react';
import { extractObjectKeys } from '@utils/helpers';
import { TablesMapper } from '@features/recipes/types';
import { SchemaColumn } from '@features/objects/types';
import { Col, Row, Typography } from 'antd';

interface DataOriginExplorerProps {
  insertText: (text: string) => void;
  expanded?: boolean;
  tables: TablesMapper;
  loading?: boolean;
}

const DataExplorer = ({ insertText, tables, expanded, loading }: DataOriginExplorerProps) => {
  const octoExplorer = useOctoExplorer();

  const formatSchemaColumnToTitle = (col: SchemaColumn) => {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'flex-start',
        }}
      >
        <Typography.Text strong={true}>{col.columnName}</Typography.Text>
        <Typography.Text style={{ fontStyle: 'italic' }}>{col.columnType}</Typography.Text>
      </div>
    );
  };
  const tablesMapperToItemsConfig = (mapper: TablesMapper, insertText: (text: string) => void) => {
    const tablesNames = extractObjectKeys(mapper);
    return tablesNames.map((name) => {
      const tablesItems = mapper[name].map((col) => {
        return {
          type: 'line',
          name: formatSchemaColumnToTitle(col),
          action: () => {
            insertText(`ref('${name}', '${col.columnName}')`);
          },
        };
      });
      return {
        name,
        items: tablesItems,
        type: 'panel',
        action: () => {
          insertText(name);
        },
      };
    });
  };
  const dataOriginItems = tablesMapperToItemsConfig(tables, insertText);
  useEffect(() => {
    octoExplorer.putTreeStructure(dataOriginItems);
  }, [tables, insertText]);
  return <OctoExplorer expanded={expanded} loading={loading} {...octoExplorer} />;
};

export default DataExplorer;
