const sourcesScreen = {
  sourcesDescription: 'Sources are places outside of the datahub from which you get data.',
  addASource: 'Add a source',
  // source keys
  name: 'Name',
  connector: 'Connector',
  pointer: 'Pointer',
  records: 'Records',
  lastRun: 'Last run',
  status: 'Status',
  schedule: 'Schedule',
  actions: 'Actions',
  // source actions
  create: 'Create',
  view: 'View',
  edit: 'Edit',
  log: 'Log',
  active: 'Active',
  broken: 'Broken',
  // form
  editTheSource: 'Edit the source',
  viewTheSource: 'View the source',
  createTheSource: 'Create the source',
  chooseConnector: 'Choose connector',
  configuration: 'Configuration',
  sourcePointer: 'Source pointer',
  globalSettings: 'Global settings',
  usageInFlows: 'Usage in flows',
  sourceUsedInFlows: 'This source is currently being used in the following flows',
  connectorRequiredMessage: 'Please select a connector.',
  pointerRequiredMessage: 'Please select a pointer.',
  nameRequiredMessage: 'Please select a name.',
  statusRequiredMessage: 'Please select a status.',
  creationFlowRequiredMessage: 'Please choose if you want to create a flow for this source.',
  creatFlowLabel: "Create a flow to fuel an object with this source's data",
  confirmDeletion: 'Are you sure? The source will be deleted.',
  confirmCancel: 'Are you sure? Your changes will not be saved.',
  save: 'Save',
  next: 'Next',
  choose: 'Choose',
  previous: 'Previous',
  cancel: 'Cancel',
  delete: 'Delete',
  yes: 'Yes',
  no: 'No',
};
const menuHeader = {
  controlCenter: 'Control center',
  settings: 'Settings',
};
const menuLeft = {
  connections: 'Connections',
  sources: 'Sources',
  allSources: 'All sources',
  flows: 'Flows',
  allFlows: 'All flows',
  sourceFlows: 'Source flows',
  destinationsFlows: 'Destination flows',
  internalFlows: 'Internal flows',
  recipes: 'Recipes',
  objects: 'Objects',
  allObjects: 'All Objects',
  contacts: 'Contacts',
  orders: 'Orders',
  products: 'Products',
  destinations: 'Destinations',
  allDestinations: 'All destinations',
  syncs: 'Syncs',
  segments: 'Segments',
  audience: 'Audience',
  audiences: 'Audiences',
};

const syncsScreen = {
  a: { b: 'hello' },
  // screens
  syncsDescription: 'Syncs map sources to a Destination',
  addNewSync: 'Add sync',
  createANewSync: 'Create a new Sync',
  // form
  whatAudienceDoSync: 'What audience do you want to sync?',
  selectAudience: 'Select an audience',
  newAudience: 'New audience',
  sendARawSource: 'Send a raw source',
  sendASegment: 'Send a raw segment',
  createANewSource: 'Create a new source ',
  createANewSegment: 'Create a new segment',
  addSource: 'Add Source',

  addDestination: 'Add Destination',
  originTypeLabel: 'What data do you want to sync?',
  chooseYourSource: 'Choose your source',
  chooseYourSegment: 'Choose your segment',
  chooseYourOrigin: 'Choose your origin',
  source: 'Source',
  segment: 'Segment',
  origin: 'Origin',
  settings: 'Settings',
  destination: 'Destination',
  whereSyncDataQuestion: 'Where do you want to sync the audience to?',
  whichFieldToSyncQuestion: 'Which fields should be synced?',
  whenDoYouWantToSync: 'When do you want to sync the audience?',
  nameAndActivateThisSync: 'Name and activate this sync ',
  weWillCastText: 'We will cast ',
  stringAsText: " to string as it's currently stored as ",
  weCannotSendText: 'We cannot send ',
  asAText: ' as a ',
  updateMappingText: ', please update mapping.',
  sourceColumnTitle: 'Source Column',
  audienceColumnTitle: 'Audience column',
  destinationFieldTitle: 'Destination field',
  chooseDestination: 'Choose destination',
  chooseAudience: 'Choose audience',
  refreshFieldsTitle: 'Refresh fields',
  addMappingText: 'Add Mapping',
  addStaticValueText: 'Add Static Value',
  syncNameLabel: 'Sync name',
  writeNameHere: 'Write name here',
  syncNameInputRuquiredMessage: 'Please enter a valid name',
  activeLabel: 'Make it active now',
  viewTheSync: 'View the sync',
  editTheSync: 'Edit the sync',
  originSettings: 'Origin Settings',
  destinationSettings: 'Destination Settings',
  mappingSettings: 'Mapping Settings',
  originStepFailed: 'origin step failed and not authorized to get to this screen',
  mappingStaticValidValue: 'Please enter a valid static value !',
  mappingOriginEmptyValue: 'Please enter an origin column !',
  mappingTargetEmptyValue: 'Please enter the destination field !',
  mappingInvalidAlert: 'Please input a valid ',
  whatKindOfSync: 'What kind of sync would you like us to perform?',
  chooseAction: 'Choose action',
  whatShouldWedoRecordLeaves: 'What should we do when a record leaves the audience?',
  syncTypeRequiredRuleMessage: 'Please choose the sync type',
  recordsActionTypeRequiredRuleMessage: 'Please choose records action',
  afterCreation: 'Sync only when a record is created in the audience',
  upsert: 'Sync when a record is created or updated (upsert) in audience',
  nothing: 'Do nothing',
  deleteInDestination: 'Delete it in the destination',
};
const segmentScreen = {
  createANewSegment: 'Create a new segment',
  editSegment: 'Edit segment',
  segmenCombineMultiple: 'Segments used to combine multiple sources',
  selectSourceOrCreateOne: 'Select a source or create one',
  saveAndSync: 'Save & Sync',
  whatDataToSegment: 'What data do you want to work with?',
  chooseYourBaseData: 'Choose your base data',
  addData: 'Add data',
  updateCombinedSource: 'Update combined source',
  resetSources: 'Reset sources',
  resetSource: 'Reset source',
  selectSourceOrCreate: 'Select a source or create one',
  segmentNameRequiredMessage: 'Please enter the segment name',
  segmentName: 'Enter the segment name',
  howToSegmentData: 'How do you want to work with your data?',
  easy: 'Easy mode',
  sql: 'Use SQL',
  filterData: 'Filter the data',
  //form
  combineSources: 'Combine Sources',
  explore: 'Explore',
  combinationSettings: 'Combination settings',
  columnSelection: 'Column selection',
  whatSourceLikeToCombine: 'What source would you like to combine with ',
  connection: 'Connection',
  file: 'File',
  recordsInTotal: 'records in total',
  howShouldCombineNewSource: 'How should we combine this new source with the other one?',
  ofRecordsFrom: ' of records from ',
  matchOnesFrom: ' match ones from ',
  whatRecordsShouldWeKeep: 'What records should we keep for this new source?',
  severalRecordsOfCombinedSource: ' Several records of combined source match each record of ',
  requiredCombinationTypeMessage: 'Please choose combination type',
  whatShouldWeDo: ', what should we do?',
  chooseColumn: 'Choose Column',
  chooseFormula: 'Choose formula',
  pleaseChooseTheFormula: 'Please choose the formula!',
  requiredColumnRuleMessage: 'Please choose the column',
  clickToReduce: 'Click to reduce stats',
  clickToExpand: 'Click to expand stats',
  statistics: 'Statistics',
  sampleValues: 'Sample values',
  //formula
  sum: 'Sum',
  max: 'Max',
  min: 'Min',
  avg: 'Avg',
  first: 'First',
  last: 'Last',
  // segments table
  sourceName: 'Source',
  combinedSources: 'Combined sources',
};
const recipeScreen = {
  pleaseEnterRecipeName: 'Please enter a name for the recipe!',
  availableDataOrigins: 'Available data origins',
  availableDataTargets: 'Available data targets',
  useModel: 'Use model',
  context: 'Context',
  testQuery: 'Test query',
  queryRunSuccess: 'Query run with Success',
  queryFailed: 'Query failed',
  runQuery: 'Run query',
  compiledSql: 'Compiled SQL',
  results: 'results',
  logs: 'Logs',
  pleaseExecuteQuery: 'Please execute query',
  youCanSaveRecipe: 'You can save your recipe now',
  queryMustBeTested: 'Query must be tested with success',
};
const audiencesScreen = {
  newAudiences: 'New Audience',
};
const scheduleContainer = {
  trigger: 'Trigger',
  realTime: 'Real time',
  regular: 'Regular interval',
  manual: 'Manual',
  triggerRequiredRuleMessage: 'Trigger is required!',
  scheduleEvery: 'Schedule every',
  scheduleRequiredRuleMessage: 'Number is required!',
  intervalRequiredRuleMessage: 'Interval is required!',
  startingDateRequiredRuleMessage: `Starting date required!`,
  startingDateValidator: `Invalid CRON expression`,
  startingAtLabel: 'At',
  startingFromLabel: 'Starting from',
  hour: 'Hour',
  day: 'Day',
  month: 'Month',
  cronExpressionLabel: 'Cron expression',
  cronExpressionRequiredRuleMessage: 'Please type the cron expression!',
  whatColumnsFromNewSource: 'What columns from this new source should we add?',
  pleaseCheckTheColumns:
    'Please check the columns you want to add, and choose the formula we should apply to each one of them.',
};
const connectionsScreen = {
  connectionsListDescription:
    'Add Sources and Destinations to Octolis to get started and do more with your data.',
  addMoreLabel: '+ Add more',
};
const en = {
  translation: {
    ...menuLeft,
    ...menuHeader,
    ...sourcesScreen,
    ...syncsScreen,
    ...scheduleContainer,
    ...connectionsScreen,
    ...segmentScreen,
    ...recipeScreen,
    ...audiencesScreen,
  },
};
export default en;
