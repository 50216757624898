import { takeLatest, call, put } from 'redux-saga/effects';
// api
import * as API from './api';
import * as ObjectsApi from '@features/objects/ducks/api';
// connectorsSlice
import sourcesSlice, { SourcesState } from '@features/sources/ducks/sourcesSlice';
// types
import * as types from './types';
import { generateId } from '@utils/helpers';
import flowsSlice from '@features/flows/ducks/flowsSlice';
import { SourceFlowConfig } from '@features/flows/ducks/types';
import { ApiSourcePayload } from './types';
import objectsSlice from '@features/objects/ducks/objectsSlice';
import segmentsSlice from '@features/segments/ducks/segmentsSlice';
import { ApiGetObjectPayload } from '@features/objects/ducks/types';
import audiencesSlice from '@features/audiences/ducks/audiencesSlice';

// @@@Amine here we are gonna define the sagas which will trigger the state with actions
// depending on the api calls responses

// Sagas for retrieving the sources list from the API call and sending them to the states via the Payload
function* getSourcesSaga() {
  try {
    const getSourcesPayload: SourcesState = yield call(API.getSources);
    yield put({ type: sourcesSlice.actions.getSourcesSuccess.type, payload: getSourcesPayload });
  } catch (error) {
    yield put({ type: sourcesSlice.actions.getSourcesFailed.type, payload: error });
  }
}

// Sagas for retrieving a single source from the API call and sending it to the state via the Payload
function* getSourceSaga({ payload }: types.GetSourceSaga) {
  try {
    const { sourceId } = payload;
    const getSourcePayload: SourcesState = yield call(API.getSource, sourceId);
    yield put({ type: sourcesSlice.actions.getSourceSuccess.type, payload: getSourcePayload });
  } catch (error) {
    yield put({ type: sourcesSlice.actions.getSourceFailed.type, payload: error });
  }
}

// Sagas for saving a new/edited source from the Form
function* saveSourceSaga({ payload }: any) {
  try {
    const { sourceConfig } = payload;

    // Save generated Source Object
    const sourceObject = {
      ...sourceConfig.sourceObject,
      name: `source_${sourceConfig.name}`,
    };
    const saveObjectPayload: ApiGetObjectPayload = yield call(ObjectsApi.saveObject, sourceObject);
    yield put({ type: objectsSlice.actions.saveObjectSuccess.type, payload: saveObjectPayload });

    // Save Source
    const saveSourcePayload: ApiSourcePayload = yield call(API.saveSource, sourceConfig);
    yield put({ type: sourcesSlice.actions.saveSourceSuccess.type, payload: saveSourcePayload });

    // Save generated Flow
    const sourceFlowConfig: SourceFlowConfig = {
      sourceObject,
      recipe: sourceConfig.recipe,
      recipeObject: sourceConfig.targetObject,
      source: saveSourcePayload.source,
      key: generateId(),
      name: `${sourceConfig.name} flow`,
    };
    yield put({ type: flowsSlice.actions.saveSourceFlow.type, payload: { sourceFlowConfig } });
  } catch (error) {
    console.log(error);
    yield put({
      type: sourcesSlice.actions.saveSourceFailed.type,
      payload: { error, errorDetails: error },
    });
  }
}

// Sagas for deleting a source
function* deleteSourceSaga({ payload }: types.DeleteSourceSaga) {
  const { source } = payload;
  try {
    const deleteSourcePayload: SourcesState = yield call(API.deleteSource, source);
    yield put({
      type: sourcesSlice.actions.deleteSourceSuccess.type,
      payload: deleteSourcePayload,
    });
  } catch (error) {
    yield put({ type: sourcesSlice.actions.deleteSourceFailed.type, payload: error });
  }
}
/*
// GetSourceTargetObject : it will fetch the current targetobject + update the record inside it
// getSourceTargetObject API : pass the source + update sourceObject (raw) & targetObject (dataprep or input) (by calling the rawRecord API)
// return the new source & update the source (saga + slice)
//
function* runSourceForSyncSaga({ payload }: types.RunSourceSaga) {
  const { source } = payload;
  try {
    const newSource: ApiSourcePayload = yield call(API.runSource, source);
    yield put({
      type: sourcesSlice.actions.runSourceForSyncSuccess.type,
      payload: newSource,
    });

    if (newSource.source.sourceObject) {
      yield put({
        type: objectsSlice.actions.saveObjectSuccess.type,
        payload: { object: newSource.source?.sourceObject },
      });
    }
    if (newSource.source.targetObject) {
      yield put({
        type: objectsSlice.actions.saveObjectSuccess.type,
        payload: { object: newSource.source?.targetObject },
      });
    }
    yield put({
      type: syncsSlice.actions.setActiveSyncSource.type,
      payload: { source: newSource.source },
    });
  } catch (error) {
    yield put({ type: sourcesSlice.actions.runSourceForSyncFailed.type, payload: error });
  }
}*/
function* runSourceForSegmentSaga({ payload }: types.RunSourceSaga) {
  const { source } = payload;
  try {
    const runSourcePayload: ApiSourcePayload = yield call(API.runSource, source);
    yield put({
      type: sourcesSlice.actions.runSourceForSegmentSuccess.type,
      payload: runSourcePayload,
    });

    if (runSourcePayload.source.sourceObject) {
      yield put({
        type: objectsSlice.actions.saveObjectSuccess.type,
        payload: { object: runSourcePayload.source?.sourceObject },
      });
    }
    if (runSourcePayload.source.targetObject) {
      yield put({
        type: objectsSlice.actions.saveObjectSuccess.type,
        payload: { object: runSourcePayload.source?.targetObject },
      });
    }
    yield put({
      type: segmentsSlice.actions.updateActiveSegmentSource.type,
      payload: { source: runSourcePayload.source },
    });
  } catch (error) {
    yield put({ type: sourcesSlice.actions.runSourceForSegmentFailed.type, payload: error });
  }
}
function* runSourcesForAudienceSaga({ payload }: types.RunSourcesForAudienceSaga) {
  const { activeAudience } = payload;
  try {
    const runSourcesPayload: types.ApiRunSourcesPayload = yield call(
      API.runSourcesForAudience,
      activeAudience.sources,
      activeAudience.mappingRows
    );
    yield put({
      type: sourcesSlice.actions.runSourcesForAudienceSuccess.type,
      payload: runSourcesPayload,
    });
    yield put({
      type: audiencesSlice.actions.updateActiveAudience.type,
      payload: { activeAudience: { ...activeAudience, object: runSourcesPayload.object } },
    });
  } catch (err: unknown) {
    if (err instanceof Error) {
      yield put({
        type: sourcesSlice.actions.runSourcesForAudienceFailed.type,
        payload: { error: err, errorDetails: err.message },
      });
    }
  }
}
function* saveCombinedSourceSaga({ payload }: types.SaveCombinedSourceSaga) {
  const { combination, segment } = payload;
  try {
    const saveCombinedSourcePayload: types.ApiCombinedSourcePayload = yield call(
      API.saveCombinedSource,
      combination,
      segment
    );
    yield put({
      type: sourcesSlice.actions.saveCombinedSourceSuccess.type,
      payload: saveCombinedSourcePayload,
    });
  } catch (error) {
    yield put({ type: sourcesSlice.actions.saveCombinedSourceFailed.type, payload: error });
  }
}

// Attach Sagas to Redux Action
export const sourcesSagas = [
  takeLatest(sourcesSlice.actions.getSources.type, getSourcesSaga),
  takeLatest(sourcesSlice.actions.getSource.type, getSourceSaga),
  takeLatest(sourcesSlice.actions.saveSource.type, saveSourceSaga),
  takeLatest(sourcesSlice.actions.deleteSource.type, deleteSourceSaga),
  takeLatest(sourcesSlice.actions.runSourceForSegment.type, runSourceForSegmentSaga),
  takeLatest(sourcesSlice.actions.runSourcesForAudience.type, runSourcesForAudienceSaga),
  takeLatest(sourcesSlice.actions.saveCombinedSource.type, saveCombinedSourceSaga),
];
