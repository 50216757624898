import React, { CSSProperties } from 'react';
import { Form, Select } from 'antd';
import { OctoSelectProps } from '../types';

const { Option } = Select;

export const OctoSelect = ({
  name,
  label,
  mode,
  placeHolder,
  value,
  width,
  options,
  disabled,
  allowClear,
  loading,
  showSearch,
  rule,
  children,
  onChange,
  style,
  selectClassName,
}: OctoSelectProps) => {
  return (
    <Form.Item
      label={label}
      name={name}
      rules={!disabled && rule ? (rule.length ? rule : [rule]) : []}
    >
      <Select
        loading={loading}
        mode={mode}
        showSearch={showSearch}
        className={selectClassName}
        style={style ? style : { width: width ? width : '100%' }}
        placeholder={placeHolder}
        allowClear={allowClear}
        //value={value}
        defaultValue={value}
        disabled={disabled}
        dropdownRender={(menu) => {
          return (
            <div>
              {children}
              {menu}
            </div>
          );
        }}
        onChange={onChange}
        children={
          <>
            {options.map((option, index) => {
              return (
                <Option
                  key={index}
                  value={option.value}
                  children={option.label}
                  disabled={option.disabled ? option.disabled : false}
                />
              );
            })}
          </>
        }
      />
    </Form.Item>
  );
};

export default OctoSelect;
