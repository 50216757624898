import {
  ActiveAudience,
  ActiveSource,
  ActiveSourceConfiguration,
  DefaultActiveSourceConfiguration,
  JoinActiveSourceConfiguration,
} from './types';

// Using type predicates functions
export const isActiveAudienceType = (
  audienceSource: ActiveAudience | ActiveSource
): audienceSource is ActiveAudience => (audienceSource as ActiveAudience).sources !== undefined;

export const isActiveSourceType = (
  audienceSource: ActiveAudience | ActiveSource
): audienceSource is ActiveSource =>
  (audienceSource as ActiveSource).connection !== undefined ||
  (audienceSource as ActiveSource).pointer !== undefined;

export const isJoinSourceConfigurationType = (
  sourceConfiguration: ActiveSourceConfiguration
): sourceConfiguration is DefaultActiveSourceConfiguration & JoinActiveSourceConfiguration =>
  (sourceConfiguration as DefaultActiveSourceConfiguration & JoinActiveSourceConfiguration)
    .joinByColumns !== undefined;
