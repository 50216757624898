// Importing type
import * as apiTypes from './types';
import { Sync } from '../types';
import { sleep } from '@utils/helpers';
import { syncs } from '@data/syncs';
const getAllSyncs: apiTypes.APIGetAllSyncs = async () => {
  let error: boolean = false;
  let errorDetails: string | undefined = undefined;

  if (!syncs) {
    error = true;
    errorDetails = 'No Syncs Found';
  }
  return {
    syncs,
    error,
    loaded: true,
    errorDetails: errorDetails,
  };
};

const saveSync: apiTypes.APISaveSync = async (sync: Sync) => {
  await sleep(1500);

  let error: boolean = false;
  let errorDetails: string | undefined = undefined;

  if (!sync) {
    error = true;
    errorDetails = 'Impossible to save sync (not found).';
  }

  return {
    error,
    loaded: true,
    errorDetails: errorDetails,
    sync: sync,
  };
};

export { getAllSyncs, saveSync };
