import { generateId } from '@utils/helpers';
import { Recipe } from '@features/recipes/types';
import { OctoObject } from '@features/objects/types';

export const generateRecipe = (
  name: string,
  context?: string,
  model?: string,
  query?: string,
  targets?: OctoObject[],
  origins?: OctoObject[]
) => {
  const recipeKey = generateId();
  const recipe: Recipe = {
    name,
    key: recipeKey,
    context: context,
    model: model,
    query: query ? query : 'SELECT *',
    targets: targets ? targets : [],
    origins: origins ? origins : [],
  };
  return recipe;
};
