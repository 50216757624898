import { Checkbox, Form } from 'antd';
import React from 'react';
interface OctoCheckBoxProps {
  name?: string;
  label?: string | JSX.Element;
  formItemLabel?: string;
  formItemStyle?: React.CSSProperties;
  formItemClassName?: string;
  checked?: boolean;
  disabled?: boolean;
  indeterminate?: boolean;
  checkBoxStyle?: React.CSSProperties;
  checkBoxClassName?: string;
  onChange?: (checkedStatus: boolean) => void;
}
const OctoCheckBox = ({
  name,
  label,
  formItemLabel,
  formItemStyle,
  formItemClassName,
  checked,
  disabled,
  indeterminate,
  checkBoxStyle,
  checkBoxClassName,
  onChange,
}: OctoCheckBoxProps) => {
  return (
    <Form.Item
      label={formItemLabel}
      name={name}
      style={formItemStyle}
      className={formItemClassName}
      initialValue={checked}
    >
      <Checkbox
        disabled={disabled}
        checked={checked}
        indeterminate={indeterminate}
        onChange={() => {
          if (onChange) onChange(!checked);
        }}
        style={checkBoxStyle}
        className={checkBoxClassName}
      >
        {label}
      </Checkbox>
    </Form.Item>
  );
};

export default OctoCheckBox;
