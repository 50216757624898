import React, { createContext } from 'react';
import { RightsType } from '@features/profiles/types';
import { ReducerStates } from '@redux/reducers';
import { connect } from 'react-redux';
import { extractObjectKeys } from '@utils/helpers';
import { AccountState } from '@features/account/ducks/accountSlice';
import { AppContextState } from '@features/appContext/ducks/appContextSlice';
import { ProfilesState } from '@features/profiles/ducks/profilesSlice';

interface Profile {
  type: 'admin' | 'marketing' | 'data' | 'user';
  rights: RightsType;
}

export const ProfileContext = createContext<Profile>({
  type: 'user',
  rights: {
    recommandations: [],
    connections: [],
    audiences: [],
    syncs: [],
    segments: [],
    sources: [],
    flows: [],
    recipes: [],
    internalFlows: [],
    sourceFlows: [],
    destinationFlows: [],
    destinations: [],
    objects: [],
    users: [],
    accountManagement: [],
  },
});

const mapRightsStateToProps = (state: ReducerStates) => {
  return {
    profileState: state.profiles,
    accountState: state.account,
    appContextState: state.appContext,
  };
};

interface RightsContextProps {
  children: JSX.Element;
  profileState: ProfilesState;
  accountState: AccountState;
  appContextState: AppContextState;
}

const RightsContainer = ({
  children,
  profileState,
  accountState,
  appContextState,
}: RightsContextProps) => {
  let appRights: RightsType = {
    recommandations: [],
    connections: [],
    audiences: [],
    syncs: [],
    segments: [],
    sources: [],
    flows: [],
    recipes: [],
    internalFlows: [],
    sourceFlows: [],
    destinationFlows: [],
    destinations: [],
    objects: [],
    users: [],
    accountManagement: [],
  };
  const profileRights = profileState.profile.rights;
  const profileFeaturesRights = extractObjectKeys(profileState.profile.rights);
  const accessibleFeatures = accountState.account.accessibleFeatures
    ? accountState.account.accessibleFeatures
    : appContextState.appContext.accessibleFeatures;
  const visibleFeatures = accountState.account.visibleFeatures
    ? accountState.account.visibleFeatures
    : appContextState.appContext.visibleFeatures;
  profileFeaturesRights.map((feature) => {
    if (
      profileRights[feature] &&
      accessibleFeatures.includes(feature) &&
      visibleFeatures.includes(feature)
    ) {
      appRights = {
        ...appRights,
        [feature]: profileRights[feature],
      };
    }
  });
  const profile = {
    type: profileState.profile.type,
    rights: appRights,
  };
  return <ProfileContext.Provider value={profile}>{children}</ProfileContext.Provider>;
};
export const ProfileContextProvider = connect(mapRightsStateToProps)(RightsContainer);
