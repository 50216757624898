import { createSlice } from '@reduxjs/toolkit';
import * as types from './types';
import React from 'react';
import { generateId, updateOrAddElementInArray } from '@utils/helpers';
import { Flow, Node } from '@features/flows/types';

export interface FlowsState {
  loaded: boolean;
  isFetching?: boolean;
  error?: boolean;
  shouldBeClosed?: boolean;
  errorDetails?: string | undefined;
  activeInternalFlow: Flow;
  activeSourceFlow: Flow;
  sourceFlows: Flow[];
  internalFlows: Flow[];
  syncFlows: Flow[];
  segmentFlows: Flow[];
}

export const INITIAL_THEME_STATE = {
  loaded: false,
  isFetching: false,
  sourceFlows: [],
  internalFlows: [],
  syncFlows: [],
  segmentFlows: [],
  activeInternalFlow: {
    key: generateId(),
    name: 'New',
    nodes: [],
    edges: [],
  },
  activeSourceFlow: {
    key: generateId(),
    name: 'New',
    nodes: [],
    edges: [],
  },
} as FlowsState;

const flowsSlice = createSlice({
  name: 'flows',

  initialState: INITIAL_THEME_STATE,
  reducers: {
    getFlows: (state) => {
      state.isFetching = true;
    },
    getFlowsSuccess: (state, { payload }: types.FlowsReducerActions) => {
      state.loaded = true;
      state.isFetching = false;
      state.internalFlows = payload.internalFlows;
      state.sourceFlows = payload.sourceFlows;
      state.syncFlows = payload.syncFlows;
      state.segmentFlows = payload.segmentFlows;
    },
    getFlowsFailed: (state, { payload }: types.FlowsReducerActions) => {
      state.isFetching = false;
      state.error = true;
      state.errorDetails = payload.errorDetails;
    },

    setActiveSourceFlow: (state, { payload }: types.SetActiveSourceFlowActions) => {
      state.activeSourceFlow = payload.sourceFlow;
    },
    saveSourceFlow: (state) => {
      state.isFetching = true;
      state.shouldBeClosed = false;
    },
    saveSourceFlowSuccess: (state, { payload }: types.SourceFlowActions) => {
      state.loaded = true;
      state.isFetching = false;
      state.shouldBeClosed = true;
      state.sourceFlows = updateOrAddElementInArray(payload.sourceFlow, state.sourceFlows);
    },
    saveSourceFlowFailed: (state, { payload }: types.SourceFlowActions) => {
      state.isFetching = false;
      state.error = true;
      state.errorDetails = payload.errorDetails;
    },

    saveSyncFlow: (state) => {
      state.isFetching = true;
      state.shouldBeClosed = false;
    },
    saveSyncFlowSuccess: (state, { payload }: types.SyncFlowActions) => {
      state.loaded = true;
      state.isFetching = false;
      state.shouldBeClosed = true;
      state.syncFlows = updateOrAddElementInArray(payload.syncFlow, state.syncFlows);
    },
    saveSyncFlowFailed: (state, { payload }: types.SyncFlowActions) => {
      state.isFetching = false;
      state.error = true;
      state.errorDetails = payload.errorDetails;
    },

    saveSegmentFlow: (state) => {
      state.isFetching = true;
      state.shouldBeClosed = false;
    },
    saveSegmentFlowSuccess: (state, { payload }: types.SegmentFlowActions) => {
      state.loaded = true;
      state.isFetching = false;
      state.shouldBeClosed = true;
      state.segmentFlows = updateOrAddElementInArray(payload.segmentFlow, state.segmentFlows);
    },
    saveSegmentFlowFailed: (state, { payload }: types.SegmentFlowActions) => {
      state.isFetching = false;
      state.error = true;
      state.errorDetails = payload.errorDetails;
    },

    setActiveInternalFlow: (state, { payload }: types.SetActiveInternalFlowActions) => {
      state.activeInternalFlow = payload.internalFlow;
    },
    saveInternalFlow: (state) => {
      state.isFetching = true;
      state.shouldBeClosed = false;
    },
    saveInternalFlowSuccess: (state, { payload }: types.InternalFlowActions) => {
      state.loaded = true;
      state.isFetching = false;
      state.shouldBeClosed = true;
      state.internalFlows = updateOrAddElementInArray(payload.internalFlow, state.internalFlows);
    },
    saveInternalFlowFailed: (state, { payload }: types.InternalFlowActions) => {
      state.isFetching = false;
      state.error = true;
      state.errorDetails = payload.errorDetails;
    },
    deleteInternalFlow: (state) => {
      state.isFetching = true;
      state.shouldBeClosed = false;
    },
    deleteInternalFlowSuccess: (state, { payload }: types.InternalFlowActions) => {
      state.loaded = true;
      state.isFetching = false;
      state.shouldBeClosed = true;
      state.internalFlows = state.internalFlows.filter(
        (flow) => flow.key !== payload.internalFlow.key
      );
    },
    deleteInternalFlowFailed: (state, { payload }: types.InternalFlowActions) => {
      state.isFetching = false;
      state.error = true;
      state.errorDetails = payload.errorDetails;
    },

    updateNodeInFlow: (state, { payload }: { payload: { node: Node } }) => {
      state.activeInternalFlow.nodes = state.activeInternalFlow.nodes.map((node) => {
        if (node.key === payload.node.key) return payload.node;
        return node;
      });
    },
  },
});

export default flowsSlice;
