import React, { useEffect, useState } from 'react';
import { OctoDropDownProps } from './types';
import { Popover } from 'antd';
import './style.less';
import { TooltipPlacement } from 'antd/lib/tooltip';

const useOctoDropDown = () => {
  const [visible, setVisible] = useState(false);
  const [containerClassName, putContainerClassName] = useState('default__container');
  const [placement, putPlacement] = useState<TooltipPlacement>('bottom');
  const [trigger, putTrigger] = useState('click');
  const [triggerContent, putTriggerContent] = useState(<>Trigger</>);
  const [overlayStyle, putOverlayStyle] = useState({});
  const [overlayClassName, putOverlayClassName] = useState('');
  const [content, putContent] = useState(<></>);
  const [optionalRightContent, putOptionalRightContent] = useState(<></>);

  return {
    containerClassName,
    placement,
    content,
    optionalRightContent,
    trigger,
    triggerContent,
    overlayStyle,
    overlayClassName,
    visible,
    onVisibleChange: (visible: boolean) => {
      setVisible(visible);
    },
    closeDropDown: () => {
      setVisible(false);
    },
    putContent,
    putContainerClassName,
    putPlacement,
    putTrigger,
    putTriggerContent,
    putOverlayStyle,
    putOverlayClassName,
    putOptionalRightContent,
  };
};

const OctoDropDown = ({
  //pop over trigger
  triggerContent,
  trigger,
  //right optional button
  optionalRightContent,
  //pop over
  overlayClassName,
  overlayStyle,
  placement,
  content,
  containerClassName,
  visible,
  onVisibleChange,
}: OctoDropDownProps) => {
  return (
    <div className={containerClassName}>
      <Popover
        placement={placement}
        content={content}
        trigger={trigger}
        overlayStyle={overlayStyle}
        overlayClassName={overlayClassName}
        visible={visible}
        onVisibleChange={onVisibleChange}
      >
        {triggerContent}
      </Popover>
      {optionalRightContent}
    </div>
  );
};
export { OctoDropDown, useOctoDropDown };
