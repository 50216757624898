import { objects } from '@data/objects';
import { recipes } from '@data/recipes';
import { Source } from '@features/sources/types';
import { connectors } from '@data/connectors';
import { sourceConnections } from '@data/connections';
export const sources: Source[] = [
  {
    key: 'sourcename1',
    name: 'Source 1',
    connection: sourceConnections[0],
    pointer: 'raw/contacts_salesforce_YYYYMMDD.csv',
    records: 3500,
    lastRun: '2020-02-01 00:00:00 (01:35:12)',
    status: ['active'],
    active: true,
    schedule: '* * */6 * *',
    deleted: false,
    sourceObject: objects[11],
    targetObject: objects[8],
    recipe: recipes[0],
    parseOption: {
      delimiter: ',',
      escape: '"',
      quote: "'",
    },
  },
  {
    key: 'sourcename2',
    name: 'Source 2',
    connection: sourceConnections[1],
    pointer: 'octolis_raw.orders_salesforce',
    records: 15000,
    lastRun: '2020-03-01 00:00:00 (01:35:12)',
    status: ['broken'],
    schedule: '* * */15 * *',
    active: false,
    deleted: false,
    sourceObject: objects[12],
    targetObject: objects[9],
    recipe: recipes[1],
  },
  {
    key: 'sourcename3',
    name: 'Source 3',
    connection: sourceConnections[0],
    pointer: 'raw/contacts_salesforce_YYYYMMDD.csv',
    records: 21000,
    lastRun: '2020-01-01 00:00:00 (01:35:12)',
    status: ['active'],

    schedule: '* * */9 * *',
    active: true,
    deleted: false,
    sourceObject: objects[13],
    targetObject: objects[10],
    recipe: recipes[2],
    parseOption: {
      delimiter: ',',
      escape: '"',
      quote: "'",
    },
  },
  {
    key: 'sourcename4',
    name: 'Source 4',
    connection: sourceConnections[2],
    pointer: 'Available integrations/All orders',
    records: 13000,
    lastRun: '2020-01-01 00:00:00 (01:35:12)',
    schedule: '* * */6 10 *',
    status: ['active'],
    active: true,
    deleted: false,
    sourceObject: objects[14],
    targetObject: objects[8],
    recipe: recipes[3],
  },
];
