import { Operation } from '@features/profiles/types';

export const allRights: Operation[] = ['create', 'read', 'update', 'delete'];

export const canUserRead = (rights: Operation[]) => {
  return rights.includes('read' || 'create' || 'update' || 'delete');
};
export const canUserCreate = (rights: Operation[]) => {
  return rights.includes('create' || 'update' || 'delete');
};
export const canUserEdit = (rights: Operation[]) => {
  return rights.includes('update' || 'delete');
};
export const canUserDelete = (rights: Operation[]) => {
  return rights.includes('delete');
};
