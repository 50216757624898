import { generateId } from '@utils/helpers';
import moment from 'moment';
import {
  ArrayElementTypes,
  ConditionForm,
  ConditionType,
  FilterGroupType,
  OctoRecordsFilter,
  OctoRecordsQuickFilter,
} from './types';

export const generateFilter = (objectId: string) => {
  const filterKey = generateId();
  const filter: OctoRecordsFilter = {
    key: filterKey,
    combinationOperator: 'or',
    groupFilters: [],
    object: objectId,
  };
  return filter;
};
export const generateQuickFilter = (name: string, condition: ConditionType, object: string) => {
  const quickFilterKey = generateId();
  const quickFilter: OctoRecordsQuickFilter = {
    key: quickFilterKey,
    name,
    condition,
    object,
  };
  return quickFilter;
};
export const generateFilterGroup = (condition: ConditionType) => {
  const FilterGroupKey = generateId();
  const FilterGroup: FilterGroupType = {
    combinationOperator: 'and',
    conditions: [condition],
    id: FilterGroupKey,
    type: 'property',
  };
  return FilterGroup;
};

export const generateConditionForm = (
  operator?: string,
  saveAsQuickFilter?: boolean,
  quickFilterName?: string,
  arrayElementType?: ArrayElementTypes
): ConditionForm => {
  return {
    operator: operator ? operator : '',
    value: '',
    values: [],
    datePicker: moment(),
    rangePicker: [moment(), moment()],
    dateList: [],
    saveAsQuickFilter: saveAsQuickFilter ? saveAsQuickFilter : false,
    quickFilterName: quickFilterName ? quickFilterName : '',
    arrayElementType: arrayElementType ? arrayElementType : 'first',
  };
};
