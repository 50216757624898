import { objects } from '@data/objects';
import { Recipe } from '@features/recipes/types';

export const recipes:Recipe[] = [
  {
    key: 'rec1',
    name: 'Recipe for Source 1',
    targets: [objects[8]],
    query:
      'SELECT {{column1}}, {{column2}} FROM {{table_name}} \n WHERE condition1 OR condition2 OR condition3 ...;',
    origins: [objects[0]],
  },
  {
    key: 'rec2',
    name: 'Recipe for Source 2',
    targets: [objects[9]],
    query: 'SELECT * FROM {{SQL Recipe 2}}',
    origins: [objects[1]],
  },
  {
    key: 'rec3',
    name: 'Recipe for Source 3',
    targets: [objects[10]],
    query: 'SELECT * FROM {{SQL Recipe 2}}',
    origins: [objects[2]],
  },
  {
    key: 'rec4',
    name: 'Recipe for Source 4',
    targets: [objects[8]],
    query:
      'SELECT {{column1}}, {{column2}} FROM {{table_name}} \n WHERE condition1 OR condition2 OR condition3 ...;',
    origins: [objects[0]],
  },
  {
    key: 'rec5',
    name: 'Recipe for default flow',
    targets: [objects[8]],
    query:
      'SELECT {{column1}}, {{column2}} FROM {{table_name}} \n WHERE condition1 OR condition2 OR condition3 ...;',
    origins: [objects[0]],
  },
];
