import {
  Audience,
  buildTypesEnum,
  columnDisplayEnum,
  dataTypesEnum,
  keepTypesEnum,
  retrievalModeEnum,
  retrieveFrequencyEnum,
  sourceTypesEnum,
} from '@features/audiences/types';
import { businessCategoriesDefaultList } from './businessCategory';

import { generateRandomInteger, generateId } from '@utils/helpers';

import { ProductAudience } from './audience.products'
import { OrdersAudience } from './audience.orders'
import { OrdersitemsAudience } from './audience.ordersitems'
import { ConsentsAudience } from './audience.consents'

import { ordersColumns, productsColumns } from '@data/objectsColumns'

import moment from 'moment';

const faker = require('faker');

faker.setLocale('fr');

const pad = (num: any, size: any) => {
  num = num.toString();
  while (num.length < size) num = "0" + num;
  return num;
}


const getRandomNumber = () => {
  return pad(Math.floor(Math.random() * 98) + 1, 2);
};

const getRandomPhoneNumber = () => {
  return `00336${getRandomNumber()}${getRandomNumber()}${getRandomNumber()}${getRandomNumber()}`;
};

let audiences1: Audience[] = [
  // {
  //   key: '1640966966008.0u73x',
  //   filter: {
  //     key: '1638360408948.06vz6',
  //     combinationOperator: 'or',
  //     groupFilters: [
  //       {
  //         combinationOperator: 'and',
  //         conditions: [],
  //         type: 'property',
  //         id: '1638361056033.08fcg',
  //       },
  //     ],
  //     object: '1639398651795.0e12p',
  //   },
  //   sources: [
  //     {
  //       key: '1640966967674.0u73x',
  //       combinationLevel: 0,
  //       audienceSource: {
  //         key: '1640966966008.0ds9j',
  //         connection: {
  //           key: 'connection6',
  //           name: 'Salesforce',
  //           usedAs: ['destination'],
  //           connector: {
  //             key: 'connector1',
  //             title: 'Salesforce',
  //             description: 'This is some description of the source connector',
  //             category: 'api',
  //             logo: '/icons/logos/salesforce.png',
  //             expandedLogo: '/icons/salesforce.png',
  //           },
  //           credentials: {
  //             url: 'customdomain.salesforce.com',
  //             apiKey: 'Kq5-SD$O5qsd"79qsdLSdf*/sdf64654qs',
  //           },
  //         },
  //         pointer: 'Available integrations/All contacts',
  //         object: {
  //           updatedAtId: '1640966984440.0dsub',
  //           name: 'object_Available integrations/All contacts',
  //           tableName: 'Available integrations/All contacts',
  //           category: 'contacts',
  //           schema: [
  //             {
  //               id: '1640966984440.0qp8m',
  //               columnName: 'userId',
  //               columnType: 'string',
  //               required: false,
  //               description: '',
  //               category: 'no category',
  //               position: 0,
  //             },
  //             {
  //               id: '1640966984440.0uw45',
  //               columnName: 'firstName',
  //               columnType: 'string',
  //               required: false,
  //               description: '',
  //               category: 'no category',
  //               position: 1,
  //             },
  //             {
  //               id: '1640966984440.078rw',
  //               columnName: 'lastName',
  //               columnType: 'string',
  //               required: false,
  //               description: '',
  //               category: 'no category',
  //               position: 2,
  //             },
  //             {
  //               id: '1640966984440.08bym',
  //               columnName: 'fullName',
  //               columnType: 'string',
  //               required: false,
  //               description: '',
  //               category: 'no category',
  //               position: 3,
  //             },
  //             {
  //               id: '1640966984440.0pclz',
  //               columnName: 'birthdate',
  //               columnType: 'date',
  //               required: false,
  //               description: '',
  //               category: 'no category',
  //               position: 4,
  //             },
  //             {
  //               id: '1640966984440.0fp3w',
  //               columnName: 'language',
  //               columnType: 'string',
  //               required: false,
  //               description: '',
  //               category: 'no category',
  //               position: 5,
  //             },
  //             {
  //               id: '1640966984440.096ak',
  //               columnName: 'gender',
  //               columnType: 'string',
  //               required: false,
  //               description: '',
  //               category: 'no category',
  //               position: 6,
  //             },
  //             {
  //               id: '1640966984440.02qmz',
  //               columnName: 'civility',
  //               columnType: 'string',
  //               required: false,
  //               description: '',
  //               category: 'no category',
  //               position: 7,
  //             },
  //             {
  //               id: '1640966984440.0y0ow',
  //               columnName: 'address',
  //               columnType: 'string',
  //               required: false,
  //               description: '',
  //               category: 'no category',
  //               position: 8,
  //             },
  //             {
  //               id: '1640966984440.0irh0',
  //               columnName: 'postalCode',
  //               columnType: 'string',
  //               required: false,
  //               description: '',
  //               category: 'no category',
  //               position: 9,
  //             },
  //             {
  //               id: '1640966984440.0xn5c',
  //               columnName: 'city',
  //               columnType: 'string',
  //               required: false,
  //               description: '',
  //               category: 'no category',
  //               position: 10,
  //             },
  //             {
  //               id: '1640966984440.07wtx',
  //               columnName: 'region',
  //               columnType: 'string',
  //               required: false,
  //               description: '',
  //               category: 'no category',
  //               position: 11,
  //             },
  //             {
  //               id: '1640966984440.0i8pm',
  //               columnName: 'countryName',
  //               columnType: 'string',
  //               required: false,
  //               description: '',
  //               category: 'no category',
  //               position: 12,
  //             },
  //             {
  //               id: '1640966984440.0pfwp',
  //               columnName: 'countryCode',
  //               columnType: 'string',
  //               required: false,
  //               description: '',
  //               category: 'no category',
  //               position: 13,
  //             },
  //             {
  //               id: '1640966984440.0z5m7',
  //               columnName: 'email',
  //               columnType: 'string',
  //               required: false,
  //               description: '',
  //               category: 'no category',
  //               position: 14,
  //             },
  //             {
  //               id: '1640966984440.0v8uk',
  //               columnName: 'emailFormat',
  //               columnType: 'string',
  //               required: false,
  //               description: '',
  //               category: 'no category',
  //               position: 15,
  //             },
  //             {
  //               id: '1640966984440.0vikb',
  //               columnName: 'emailDomain',
  //               columnType: 'string',
  //               required: false,
  //               description: '',
  //               category: 'no category',
  //               position: 16,
  //             },
  //             {
  //               id: '1640966984440.0qneq',
  //               columnName: 'emailValidity',
  //               columnType: 'no type',
  //               required: false,
  //               description: '',
  //               category: 'no category',
  //               position: 17,
  //             },
  //             {
  //               id: '1640966984440.09tjz',
  //               columnName: 'emailValidityReason',
  //               columnType: 'no type',
  //               required: false,
  //               description: '',
  //               category: 'no category',
  //               position: 18,
  //             },
  //             {
  //               id: '1640966984440.08fv4',
  //               columnName: 'phoneMobile',
  //               columnType: 'string',
  //               required: false,
  //               description: '',
  //               category: 'no category',
  //               position: 19,
  //             },
  //             {
  //               id: '1640966984440.0thsn',
  //               columnName: 'phoneLandline',
  //               columnType: 'number',
  //               required: false,
  //               description: '',
  //               category: 'no category',
  //               position: 20,
  //             },
  //             {
  //               id: '1640966984440.0t9m8',
  //               columnName: 'phone',
  //               columnType: 'number',
  //               required: false,
  //               description: '',
  //               category: 'no category',
  //               position: 21,
  //             },
  //             {
  //               id: '1640966984440.0k1eo',
  //               columnName: 'phoneValidity',
  //               columnType: 'no type',
  //               required: false,
  //               description: '',
  //               category: 'no category',
  //               position: 22,
  //             },
  //             {
  //               id: '1640966984440.00fvl',
  //               columnName: 'phoneValidityReason',
  //               columnType: 'no type',
  //               required: false,
  //               description: '',
  //               category: 'no category',
  //               position: 23,
  //             },
  //             {
  //               id: '1640966984440.07w4f',
  //               columnName: 'phoneCountry',
  //               columnType: 'string',
  //               required: false,
  //               description: '',
  //               category: 'no category',
  //               position: 24,
  //             },
  //             {
  //               id: '1640966984440.0ihov',
  //               columnName: 'phoneNormalized',
  //               columnType: 'no type',
  //               required: false,
  //               description: '',
  //               category: 'no category',
  //               position: 25,
  //             },
  //             {
  //               id: '1640966984440.007on',
  //               columnName: 'phoneType',
  //               columnType: 'no type',
  //               required: false,
  //               description: '',
  //               category: 'no category',
  //               position: 26,
  //             },
  //             {
  //               id: '1640966984440.0b4q1',
  //               columnName: 'consentEmail',
  //               columnType: 'string',
  //               required: false,
  //               description: '',
  //               category: 'no category',
  //               position: 27,
  //             },
  //             {
  //               id: '1640966984440.0wqpn',
  //               columnName: 'consentEmailUpdatedAt',
  //               columnType: 'string',
  //               required: false,
  //               description: '',
  //               category: 'no category',
  //               position: 28,
  //             },
  //             {
  //               id: '1640966984440.0loai',
  //               columnName: 'consentSms',
  //               columnType: 'string',
  //               required: false,
  //               description: '',
  //               category: 'no category',
  //               position: 29,
  //             },
  //             {
  //               id: '1640966984440.0lbns',
  //               columnName: 'consentSmsUpdatedAt',
  //               columnType: 'string',
  //               required: false,
  //               description: '',
  //               category: 'no category',
  //               position: 30,
  //             },
  //             {
  //               id: '1640966984440.0tu6e',
  //               columnName: 'linkedin',
  //               columnType: 'no type',
  //               required: false,
  //               description: '',
  //               category: 'no category',
  //               position: 31,
  //             },
  //             {
  //               id: '1640966984440.0icw2',
  //               columnName: 'website',
  //               columnType: 'no type',
  //               required: false,
  //               description: '',
  //               category: 'no category',
  //               position: 32,
  //             },
  //             {
  //               id: '1640966984440.0gs98',
  //               columnName: 'companyName',
  //               columnType: 'string',
  //               required: false,
  //               description: '',
  //               category: 'no category',
  //               position: 33,
  //             },
  //             {
  //               id: '1640966984440.0k1sr',
  //               columnName: 'jobTitle',
  //               columnType: 'string',
  //               required: false,
  //               description: '',
  //               category: 'no category',
  //               position: 34,
  //             },
  //             {
  //               id: '1640966984440.0y7dj',
  //               columnName: 'createdAt',
  //               columnType: 'date',
  //               required: false,
  //               description: '',
  //               category: 'no category',
  //               position: 35,
  //             },
  //             {
  //               id: '1640966984440.0dsub',
  //               columnName: 'updatedAt',
  //               columnType: 'date',
  //               required: false,
  //               description: '',
  //               category: 'no category',
  //               position: 36,
  //             },
  //           ],
  //           primaryKeys: ['1640966984440.0qp8m'],
  //           recordsNumber: 46,
  //           pointer: 'Available integrations/All contacts',
  //           records: [
  //             {
  //               userId: '1640966984436.028gf',
  //               firstName: 'Vena',
  //               lastName: 'Upton',
  //               fullName: 'Vena Upton',
  //               birthdate: '2021-08-06T06:32:11.056Z',
  //               language: 'BM',
  //               gender: 'Transexual Male',
  //               civility: 'Mr.',
  //               address: '617 Kunze Mews',
  //               postalCode: '51954-5442',
  //               city: 'East Jammiemouth',
  //               region: '',
  //               countryName: 'Papua New Guinea',
  //               countryCode: 'FJ',
  //               email: 'Jayson.Herzog58@hotmail.com',
  //               emailFormat: '',
  //               emailDomain: 'andreanne.com',
  //               emailValidity: '',
  //               emailValidityReason: '',
  //               phoneMobile: '(266) 958-4639',
  //               phoneLandline: '908-352-4735 x4711',
  //               phone: '(645) 693-1348',
  //               phoneValidity: '',
  //               phoneValidityReason: '',
  //               phoneCountry: 'FR',
  //               phoneNormalized: '',
  //               phoneType: '',
  //               consentEmail: true,
  //               consentEmailUpdatedAt: '2021-12-16T05:48:09.730Z',
  //               consentSms: true,
  //               consentSmsUpdatedAt: '2021-01-28T10:48:40.129Z',
  //               linkedin: '',
  //               website: '',
  //               companyName: 'Kunde and Sons',
  //               jobTitle: 'Forward Optimization Officer',
  //               createdAt: '2021-07-25T05:22:20.745Z',
  //               updatedAt: '2021-04-29T07:47:32.333Z',
  //             },
  //             {
  //               userId: '1640966984437.00kdz',
  //               firstName: 'Viva',
  //               lastName: 'Rath',
  //               fullName: 'Viva Rath',
  //               birthdate: '2021-04-04T02:13:37.419Z',
  //               language: 'GF',
  //               gender: 'Intersex',
  //               civility: 'Dr.',
  //               address: '94871 Howard Manor',
  //               postalCode: '93813-1442',
  //               city: 'Howellport',
  //               region: '',
  //               countryName: 'Panama',
  //               countryCode: 'LU',
  //               email: 'Bonnie71@yahoo.com',
  //               emailFormat: '',
  //               emailDomain: 'ofelia.net',
  //               emailValidity: '',
  //               emailValidityReason: '',
  //               phoneMobile: '368-280-3632 x9916',
  //               phoneLandline: '263.277.8518 x1713',
  //               phone: '(753) 666-3889',
  //               phoneValidity: '',
  //               phoneValidityReason: '',
  //               phoneCountry: 'GA',
  //               phoneNormalized: '',
  //               phoneType: '',
  //               consentEmail: false,
  //               consentEmailUpdatedAt: '2021-12-25T10:38:22.099Z',
  //               consentSms: true,
  //               consentSmsUpdatedAt: '2021-04-16T22:04:35.154Z',
  //               linkedin: '',
  //               website: '',
  //               companyName: 'Schroeder, Ruecker and Nitzsche',
  //               jobTitle: 'Forward Usability Liaison',
  //               createdAt: '2021-05-23T16:36:17.955Z',
  //               updatedAt: '2021-07-28T05:36:26.935Z',
  //             },
  //             {
  //               userId: '1640966984437.0j8gu',
  //               firstName: 'Asa',
  //               lastName: 'Farrell',
  //               fullName: 'Asa Farrell',
  //               birthdate: '2021-02-21T20:21:23.282Z',
  //               language: 'IE',
  //               gender: 'Male to female transgender woman',
  //               civility: 'Ms.',
  //               address: '5755 Elisabeth Plains',
  //               postalCode: '14932',
  //               city: 'Lake Alberto',
  //               region: '',
  //               countryName: 'Western Sahara',
  //               countryCode: 'KI',
  //               email: 'Saige90@gmail.com',
  //               emailFormat: '',
  //               emailDomain: 'zula.com',
  //               emailValidity: '',
  //               emailValidityReason: '',
  //               phoneMobile: '(938) 717-9634 x543',
  //               phoneLandline: '971.442.6301',
  //               phone: '1-830-456-4476 x06844',
  //               phoneValidity: '',
  //               phoneValidityReason: '',
  //               phoneCountry: 'GL',
  //               phoneNormalized: '',
  //               phoneType: '',
  //               consentEmail: true,
  //               consentEmailUpdatedAt: '2021-02-15T02:14:34.441Z',
  //               consentSms: false,
  //               consentSmsUpdatedAt: '2021-09-23T09:06:35.502Z',
  //               linkedin: '',
  //               website: '',
  //               companyName: 'Cormier, Stark and Shanahan',
  //               jobTitle: 'Chief Markets Architect',
  //               createdAt: '2021-01-23T15:05:44.524Z',
  //               updatedAt: '2021-02-12T02:52:45.721Z',
  //             },
  //             {
  //               userId: '1640966984437.0jcz1',
  //               firstName: 'Julian',
  //               lastName: 'Daugherty',
  //               fullName: 'Julian Daugherty',
  //               birthdate: '2021-12-08T21:11:50.823Z',
  //               language: 'VC',
  //               gender: 'Cis Female',
  //               civility: 'Miss',
  //               address: '9474 Merle Lake',
  //               postalCode: '56950-8029',
  //               city: 'West Marguerite',
  //               region: '',
  //               countryName: 'El Salvador',
  //               countryCode: 'DZ',
  //               email: 'Kole41@yahoo.com',
  //               emailFormat: '',
  //               emailDomain: 'jayde.org',
  //               emailValidity: '',
  //               emailValidityReason: '',
  //               phoneMobile: '429.210.0710',
  //               phoneLandline: '(234) 508-7014 x4327',
  //               phone: '539.494.7222 x08860',
  //               phoneValidity: '',
  //               phoneValidityReason: '',
  //               phoneCountry: 'AO',
  //               phoneNormalized: '',
  //               phoneType: '',
  //               consentEmail: true,
  //               consentEmailUpdatedAt: '2021-03-05T01:28:33.364Z',
  //               consentSms: true,
  //               consentSmsUpdatedAt: '2021-05-30T06:45:17.871Z',
  //               linkedin: '',
  //               website: '',
  //               companyName: 'Gutkowski - Legros',
  //               jobTitle: 'District Mobility Liaison',
  //               createdAt: '2021-06-02T09:35:19.744Z',
  //               updatedAt: '2021-09-12T20:17:47.402Z',
  //             },
  //             {
  //               userId: '1640966984437.0h7kc',
  //               firstName: 'Helene',
  //               lastName: 'Cassin',
  //               fullName: 'Helene Cassin',
  //               birthdate: '2021-04-10T20:20:02.676Z',
  //               language: 'CV',
  //               gender: 'Transgender Person',
  //               civility: 'Dr.',
  //               address: '6326 Grimes Crest',
  //               postalCode: '62361',
  //               city: 'Lake Madisyn',
  //               region: '',
  //               countryName: 'Maldives',
  //               countryCode: 'SA',
  //               email: 'Carli48@hotmail.com',
  //               emailFormat: '',
  //               emailDomain: 'trycia.biz',
  //               emailValidity: '',
  //               emailValidityReason: '',
  //               phoneMobile: '306.284.2180 x6720',
  //               phoneLandline: '873-917-7697',
  //               phone: '(542) 224-8097 x71060',
  //               phoneValidity: '',
  //               phoneValidityReason: '',
  //               phoneCountry: 'NC',
  //               phoneNormalized: '',
  //               phoneType: '',
  //               consentEmail: true,
  //               consentEmailUpdatedAt: '2021-06-01T20:17:38.835Z',
  //               consentSms: false,
  //               consentSmsUpdatedAt: '2021-04-08T15:23:42.288Z',
  //               linkedin: '',
  //               website: '',
  //               companyName: 'Kessler, Batz and Gerhold',
  //               jobTitle: 'Forward Factors Coordinator',
  //               createdAt: '2021-06-16T08:59:57.683Z',
  //               updatedAt: '2021-02-12T14:39:35.883Z',
  //             },
  //             {
  //               userId: '1640966984437.0ww2t',
  //               firstName: 'Lavon',
  //               lastName: 'Koss',
  //               fullName: 'Lavon Koss',
  //               birthdate: '2021-04-01T11:10:48.970Z',
  //               language: 'SO',
  //               gender: 'Cis Female',
  //               civility: 'Ms.',
  //               address: '6907 Noe Stream',
  //               postalCode: '67894',
  //               city: 'Rickeyborough',
  //               region: '',
  //               countryName: 'Israel',
  //               countryCode: 'BM',
  //               email: 'Tyrel.Moore71@hotmail.com',
  //               emailFormat: '',
  //               emailDomain: 'elena.info',
  //               emailValidity: '',
  //               emailValidityReason: '',
  //               phoneMobile: '1-249-414-0295 x227',
  //               phoneLandline: '(830) 343-1652 x29838',
  //               phone: '816-992-1837',
  //               phoneValidity: '',
  //               phoneValidityReason: '',
  //               phoneCountry: 'GM',
  //               phoneNormalized: '',
  //               phoneType: '',
  //               consentEmail: true,
  //               consentEmailUpdatedAt: '2021-02-26T05:24:44.886Z',
  //               consentSms: true,
  //               consentSmsUpdatedAt: '2021-06-29T09:03:09.060Z',
  //               linkedin: '',
  //               website: '',
  //               companyName: 'Leuschke and Sons',
  //               jobTitle: 'Future Branding Representative',
  //               createdAt: '2021-08-19T06:05:07.379Z',
  //               updatedAt: '2021-12-11T19:55:33.184Z',
  //             },
  //             {
  //               userId: '1640966984437.09y2e',
  //               firstName: 'Anjali',
  //               lastName: 'Schamberger',
  //               fullName: 'Anjali Schamberger',
  //               birthdate: '2021-10-04T04:42:47.822Z',
  //               language: 'CV',
  //               gender: 'Female to Male',
  //               civility: 'Mr.',
  //               address: '0538 Schiller Place',
  //               postalCode: '81170-9122',
  //               city: 'South Michele',
  //               region: '',
  //               countryName: 'Greece',
  //               countryCode: 'QA',
  //               email: 'Donato.Conroy@gmail.com',
  //               emailFormat: '',
  //               emailDomain: 'edward.biz',
  //               emailValidity: '',
  //               emailValidityReason: '',
  //               phoneMobile: '(577) 534-9678',
  //               phoneLandline: '802.278.0063 x694',
  //               phone: '311-843-8616',
  //               phoneValidity: '',
  //               phoneValidityReason: '',
  //               phoneCountry: 'MV',
  //               phoneNormalized: '',
  //               phoneType: '',
  //               consentEmail: true,
  //               consentEmailUpdatedAt: '2021-12-13T02:32:35.652Z',
  //               consentSms: true,
  //               consentSmsUpdatedAt: '2021-04-04T20:36:15.258Z',
  //               linkedin: '',
  //               website: '',
  //               companyName: 'Schinner Inc',
  //               jobTitle: 'Global Program Representative',
  //               createdAt: '2021-10-01T23:34:50.465Z',
  //               updatedAt: '2021-10-16T07:56:46.804Z',
  //             },
  //             {
  //               userId: '1640966984437.05gli',
  //               firstName: 'Osbaldo',
  //               lastName: 'Macejkovic',
  //               fullName: 'Osbaldo Macejkovic',
  //               birthdate: '2021-12-28T06:53:34.170Z',
  //               language: 'MK',
  //               gender: 'Androgynous',
  //               civility: 'Mr.',
  //               address: '7955 Mitchell Shoals',
  //               postalCode: '60129',
  //               city: 'Satterfieldfurt',
  //               region: '',
  //               countryName: 'Afghanistan',
  //               countryCode: 'NL',
  //               email: 'Tiffany.Johnson42@gmail.com',
  //               emailFormat: '',
  //               emailDomain: 'lelah.name',
  //               emailValidity: '',
  //               emailValidityReason: '',
  //               phoneMobile: '1-448-947-5862',
  //               phoneLandline: '1-537-224-7074 x07593',
  //               phone: '870-458-2289 x3235',
  //               phoneValidity: '',
  //               phoneValidityReason: '',
  //               phoneCountry: 'UZ',
  //               phoneNormalized: '',
  //               phoneType: '',
  //               consentEmail: true,
  //               consentEmailUpdatedAt: '2021-11-09T08:37:21.251Z',
  //               consentSms: false,
  //               consentSmsUpdatedAt: '2021-06-20T19:59:44.009Z',
  //               linkedin: '',
  //               website: '',
  //               companyName: 'Rippin - Feeney',
  //               jobTitle: 'Internal Assurance Supervisor',
  //               createdAt: '2021-09-19T04:57:25.476Z',
  //               updatedAt: '2021-04-20T13:25:22.216Z',
  //             },
  //             {
  //               userId: '1640966984437.0rlld',
  //               firstName: 'Audrey',
  //               lastName: 'Koepp',
  //               fullName: 'Audrey Koepp',
  //               birthdate: '2021-10-22T06:59:44.836Z',
  //               language: 'GM',
  //               gender: 'Trans*Male',
  //               civility: 'Miss',
  //               address: '7724 Vena Roads',
  //               postalCode: '68555',
  //               city: 'Pacochahaven',
  //               region: '',
  //               countryName: 'Gabon',
  //               countryCode: 'FO',
  //               email: 'Omari77@yahoo.com',
  //               emailFormat: '',
  //               emailDomain: 'maida.net',
  //               emailValidity: '',
  //               emailValidityReason: '',
  //               phoneMobile: '456.598.8520 x43339',
  //               phoneLandline: '291-408-8162',
  //               phone: '(235) 574-4228 x898',
  //               phoneValidity: '',
  //               phoneValidityReason: '',
  //               phoneCountry: 'IT',
  //               phoneNormalized: '',
  //               phoneType: '',
  //               consentEmail: false,
  //               consentEmailUpdatedAt: '2021-03-15T14:32:50.127Z',
  //               consentSms: true,
  //               consentSmsUpdatedAt: '2021-09-04T08:18:27.804Z',
  //               linkedin: '',
  //               website: '',
  //               companyName: 'Hoppe - Steuber',
  //               jobTitle: 'Internal Program Officer',
  //               createdAt: '2021-06-13T02:22:34.716Z',
  //               updatedAt: '2021-08-19T06:12:22.925Z',
  //             },
  //             {
  //               userId: '1640966984437.0eb9l',
  //               firstName: 'Vance',
  //               lastName: 'Bailey',
  //               fullName: 'Vance Bailey',
  //               birthdate: '2021-11-08T22:51:35.240Z',
  //               language: 'TK',
  //               gender: 'Hermaphrodite',
  //               civility: 'Ms.',
  //               address: '7727 Hayes Rue',
  //               postalCode: '51367',
  //               city: 'Fargo',
  //               region: '',
  //               countryName: 'Iran',
  //               countryCode: 'ML',
  //               email: 'Annabell40@gmail.com',
  //               emailFormat: '',
  //               emailDomain: 'shea.biz',
  //               emailValidity: '',
  //               emailValidityReason: '',
  //               phoneMobile: '(730) 552-3454 x877',
  //               phoneLandline: '874.723.0188',
  //               phone: '687-522-1628 x590',
  //               phoneValidity: '',
  //               phoneValidityReason: '',
  //               phoneCountry: 'MT',
  //               phoneNormalized: '',
  //               phoneType: '',
  //               consentEmail: true,
  //               consentEmailUpdatedAt: '2021-06-04T11:45:45.811Z',
  //               consentSms: false,
  //               consentSmsUpdatedAt: '2021-09-03T08:59:05.875Z',
  //               linkedin: '',
  //               website: '',
  //               companyName: 'Hilll LLC',
  //               jobTitle: 'Chief Factors Strategist',
  //               createdAt: '2021-03-03T03:34:06.333Z',
  //               updatedAt: '2021-11-09T08:39:14.884Z',
  //             },
  //             {
  //               userId: '1640966984437.0nhl3',
  //               firstName: 'Era',
  //               lastName: 'Lynch',
  //               fullName: 'Era Lynch',
  //               birthdate: '2021-05-31T18:57:32.206Z',
  //               language: 'RO',
  //               gender: 'Cisgender',
  //               civility: 'Mr.',
  //               address: '71721 Jayden Glen',
  //               postalCode: '65078',
  //               city: 'Montebello',
  //               region: '',
  //               countryName: 'France',
  //               countryCode: 'UY',
  //               email: 'Judy82@hotmail.com',
  //               emailFormat: '',
  //               emailDomain: 'markus.name',
  //               emailValidity: '',
  //               emailValidityReason: '',
  //               phoneMobile: '(221) 987-0573',
  //               phoneLandline: '822-957-0796 x67724',
  //               phone: '755-831-1452',
  //               phoneValidity: '',
  //               phoneValidityReason: '',
  //               phoneCountry: 'YT',
  //               phoneNormalized: '',
  //               phoneType: '',
  //               consentEmail: false,
  //               consentEmailUpdatedAt: '2021-02-01T03:24:27.533Z',
  //               consentSms: true,
  //               consentSmsUpdatedAt: '2021-11-04T03:28:14.523Z',
  //               linkedin: '',
  //               website: '',
  //               companyName: 'Bernhard - Robel',
  //               jobTitle: 'Dynamic Assurance Producer',
  //               createdAt: '2021-01-17T01:02:29.598Z',
  //               updatedAt: '2021-12-02T06:39:34.032Z',
  //             },
  //             {
  //               userId: '1640966984437.0lzq6',
  //               firstName: 'Vallie',
  //               lastName: 'Becker',
  //               fullName: 'Vallie Becker',
  //               birthdate: '2021-05-16T12:26:24.573Z',
  //               language: 'JE',
  //               gender: 'Other',
  //               civility: 'Dr.',
  //               address: '3553 Orpha Manors',
  //               postalCode: '11124',
  //               city: 'Marietta',
  //               region: '',
  //               countryName: 'Andorra',
  //               countryCode: 'SX',
  //               email: 'Sherwood_Jerde@yahoo.com',
  //               emailFormat: '',
  //               emailDomain: 'candida.org',
  //               emailValidity: '',
  //               emailValidityReason: '',
  //               phoneMobile: '1-332-857-4501',
  //               phoneLandline: '(380) 946-0407 x7650',
  //               phone: '1-897-943-6988 x9343',
  //               phoneValidity: '',
  //               phoneValidityReason: '',
  //               phoneCountry: 'SA',
  //               phoneNormalized: '',
  //               phoneType: '',
  //               consentEmail: false,
  //               consentEmailUpdatedAt: '2021-07-15T15:13:12.834Z',
  //               consentSms: false,
  //               consentSmsUpdatedAt: '2021-07-08T15:17:14.517Z',
  //               linkedin: '',
  //               website: '',
  //               companyName: "O'Reilly Inc",
  //               jobTitle: 'Regional Solutions Analyst',
  //               createdAt: '2021-03-14T19:46:45.596Z',
  //               updatedAt: '2021-06-16T02:00:33.724Z',
  //             },
  //             {
  //               userId: '1640966984437.0tjxx',
  //               firstName: 'Emil',
  //               lastName: 'Quitzon',
  //               fullName: 'Emil Quitzon',
  //               birthdate: '2021-03-03T23:20:48.766Z',
  //               language: 'CR',
  //               gender: 'Genderqueer',
  //               civility: 'Miss',
  //               address: '591 Zemlak Place',
  //               postalCode: '12880-8487',
  //               city: 'Oswaldomouth',
  //               region: '',
  //               countryName: 'Jordan',
  //               countryCode: 'SZ',
  //               email: 'Reid_Bailey9@yahoo.com',
  //               emailFormat: '',
  //               emailDomain: 'emmanuel.net',
  //               emailValidity: '',
  //               emailValidityReason: '',
  //               phoneMobile: '687.873.9124 x840',
  //               phoneLandline: '(793) 723-2575 x25875',
  //               phone: '311.587.8822 x959',
  //               phoneValidity: '',
  //               phoneValidityReason: '',
  //               phoneCountry: 'GA',
  //               phoneNormalized: '',
  //               phoneType: '',
  //               consentEmail: true,
  //               consentEmailUpdatedAt: '2021-05-26T13:12:51.749Z',
  //               consentSms: true,
  //               consentSmsUpdatedAt: '2021-06-07T17:41:57.953Z',
  //               linkedin: '',
  //               website: '',
  //               companyName: 'Corwin, Frami and Gottlieb',
  //               jobTitle: 'Product Implementation Developer',
  //               createdAt: '2021-04-22T10:31:55.287Z',
  //               updatedAt: '2021-02-18T15:25:12.859Z',
  //             },
  //             {
  //               userId: '1640966984438.0smv3',
  //               firstName: 'Kiara',
  //               lastName: 'Fritsch',
  //               fullName: 'Kiara Fritsch',
  //               birthdate: '2021-06-23T00:16:16.947Z',
  //               language: 'SZ',
  //               gender: 'Cisgender Man',
  //               civility: 'Mrs.',
  //               address: '04803 Ward Rue',
  //               postalCode: '08561',
  //               city: 'East Ebonyborough',
  //               region: '',
  //               countryName: 'Gibraltar',
  //               countryCode: 'ER',
  //               email: 'Elenora_Balistreri98@yahoo.com',
  //               emailFormat: '',
  //               emailDomain: 'barbara.org',
  //               emailValidity: '',
  //               emailValidityReason: '',
  //               phoneMobile: '484.474.7391 x4349',
  //               phoneLandline: '896.733.1576',
  //               phone: '651-613-6706 x822',
  //               phoneValidity: '',
  //               phoneValidityReason: '',
  //               phoneCountry: 'AM',
  //               phoneNormalized: '',
  //               phoneType: '',
  //               consentEmail: false,
  //               consentEmailUpdatedAt: '2021-08-03T23:05:30.491Z',
  //               consentSms: false,
  //               consentSmsUpdatedAt: '2021-07-29T16:37:26.998Z',
  //               linkedin: '',
  //               website: '',
  //               companyName: 'Corwin, Kuhn and Beer',
  //               jobTitle: 'Dynamic Creative Producer',
  //               createdAt: '2021-02-12T11:35:42.099Z',
  //               updatedAt: '2021-08-22T05:24:33.494Z',
  //             },
  //             {
  //               userId: '1640966984438.0tju7',
  //               firstName: 'Lue',
  //               lastName: 'Spencer',
  //               fullName: 'Lue Spencer',
  //               birthdate: '2021-09-12T18:01:11.725Z',
  //               language: 'TL',
  //               gender: 'Transgender Female',
  //               civility: 'Dr.',
  //               address: '4627 Koepp Camp',
  //               postalCode: '95434-7280',
  //               city: 'Lorenland',
  //               region: '',
  //               countryName: 'Saint Pierre and Miquelon',
  //               countryCode: 'HM',
  //               email: 'Gordon_Jerde@yahoo.com',
  //               emailFormat: '',
  //               emailDomain: 'estel.info',
  //               emailValidity: '',
  //               emailValidityReason: '',
  //               phoneMobile: '320-203-7943 x120',
  //               phoneLandline: '608-805-9192',
  //               phone: '1-200-925-5095 x470',
  //               phoneValidity: '',
  //               phoneValidityReason: '',
  //               phoneCountry: 'EG',
  //               phoneNormalized: '',
  //               phoneType: '',
  //               consentEmail: false,
  //               consentEmailUpdatedAt: '2021-03-22T11:34:18.744Z',
  //               consentSms: true,
  //               consentSmsUpdatedAt: '2021-06-17T08:44:48.191Z',
  //               linkedin: '',
  //               website: '',
  //               companyName: 'Bayer, Feil and Bernier',
  //               jobTitle: 'Principal Communications Officer',
  //               createdAt: '2021-01-21T14:16:33.395Z',
  //               updatedAt: '2021-09-03T13:37:58.594Z',
  //             },
  //             {
  //               userId: '1640966984438.0odkt',
  //               firstName: 'Trystan',
  //               lastName: 'Blick',
  //               fullName: 'Trystan Blick',
  //               birthdate: '2021-01-15T04:08:31.989Z',
  //               language: 'EG',
  //               gender: 'F2M',
  //               civility: 'Dr.',
  //               address: '8987 Euna Points',
  //               postalCode: '80339',
  //               city: 'Enahaven',
  //               region: '',
  //               countryName: 'Cocos (Keeling) Islands',
  //               countryCode: 'ET',
  //               email: 'Jarrod75@yahoo.com',
  //               emailFormat: '',
  //               emailDomain: 'johnathan.info',
  //               emailValidity: '',
  //               emailValidityReason: '',
  //               phoneMobile: '1-254-621-5805 x1033',
  //               phoneLandline: '533.962.3558 x2940',
  //               phone: '513-283-9332 x35608',
  //               phoneValidity: '',
  //               phoneValidityReason: '',
  //               phoneCountry: 'ZW',
  //               phoneNormalized: '',
  //               phoneType: '',
  //               consentEmail: false,
  //               consentEmailUpdatedAt: '2021-01-22T12:48:03.132Z',
  //               consentSms: false,
  //               consentSmsUpdatedAt: '2021-01-24T17:13:50.034Z',
  //               linkedin: '',
  //               website: '',
  //               companyName: 'Schuppe, Sipes and Dare',
  //               jobTitle: 'Future Intranet Architect',
  //               createdAt: '2021-12-12T09:12:27.175Z',
  //               updatedAt: '2021-03-12T18:44:52.127Z',
  //             },
  //             {
  //               userId: '1640966984438.0md0b',
  //               firstName: 'Anabelle',
  //               lastName: 'Zboncak',
  //               fullName: 'Anabelle Zboncak',
  //               birthdate: '2021-02-23T22:20:36.832Z',
  //               language: 'US',
  //               gender: 'Trans Man',
  //               civility: 'Mr.',
  //               address: '0565 Marco Track',
  //               postalCode: '12296',
  //               city: 'Watsicafort',
  //               region: '',
  //               countryName: 'Ecuador',
  //               countryCode: 'FR',
  //               email: 'Etha_Hermiston@yahoo.com',
  //               emailFormat: '',
  //               emailDomain: 'dessie.info',
  //               emailValidity: '',
  //               emailValidityReason: '',
  //               phoneMobile: '465.948.5024 x8956',
  //               phoneLandline: '496.825.8276 x864',
  //               phone: '228-558-3250 x8485',
  //               phoneValidity: '',
  //               phoneValidityReason: '',
  //               phoneCountry: 'VN',
  //               phoneNormalized: '',
  //               phoneType: '',
  //               consentEmail: false,
  //               consentEmailUpdatedAt: '2021-04-27T12:59:26.593Z',
  //               consentSms: false,
  //               consentSmsUpdatedAt: '2021-02-01T03:29:52.141Z',
  //               linkedin: '',
  //               website: '',
  //               companyName: 'Sanford Group',
  //               jobTitle: 'Direct Accountability Producer',
  //               createdAt: '2021-01-10T21:05:08.607Z',
  //               updatedAt: '2021-01-29T21:45:22.255Z',
  //             },
  //             {
  //               userId: '1640966984438.08btp',
  //               firstName: 'Celestine',
  //               lastName: 'Collins',
  //               fullName: 'Celestine Collins',
  //               birthdate: '2021-12-06T06:20:38.529Z',
  //               language: 'FM',
  //               gender: 'Male to Female',
  //               civility: 'Ms.',
  //               address: '6077 Dickinson Summit',
  //               postalCode: '18265',
  //               city: 'Howestad',
  //               region: '',
  //               countryName: 'Bulgaria',
  //               countryCode: 'EG',
  //               email: 'Stephanie_Strosin75@hotmail.com',
  //               emailFormat: '',
  //               emailDomain: 'keith.name',
  //               emailValidity: '',
  //               emailValidityReason: '',
  //               phoneMobile: '1-626-585-2412',
  //               phoneLandline: '532-903-2499 x48944',
  //               phone: '(851) 629-3546',
  //               phoneValidity: '',
  //               phoneValidityReason: '',
  //               phoneCountry: 'NP',
  //               phoneNormalized: '',
  //               phoneType: '',
  //               consentEmail: false,
  //               consentEmailUpdatedAt: '2021-04-08T18:00:48.739Z',
  //               consentSms: false,
  //               consentSmsUpdatedAt: '2021-10-22T00:34:29.494Z',
  //               linkedin: '',
  //               website: '',
  //               companyName: 'Bogisich - McLaughlin',
  //               jobTitle: 'Lead Accountability Manager',
  //               createdAt: '2021-09-24T02:26:56.103Z',
  //               updatedAt: '2021-10-11T14:30:43.677Z',
  //             },
  //             {
  //               userId: '1640966984438.0i0an',
  //               firstName: 'Remington',
  //               lastName: 'Schowalter',
  //               fullName: 'Remington Schowalter',
  //               birthdate: '2021-12-16T03:34:04.672Z',
  //               language: 'MP',
  //               gender: 'Two-spirit',
  //               civility: 'Dr.',
  //               address: '752 Paucek Forge',
  //               postalCode: '50135',
  //               city: 'Cierraport',
  //               region: '',
  //               countryName: 'Venezuela',
  //               countryCode: 'UY',
  //               email: 'Drew.Borer25@gmail.com',
  //               emailFormat: '',
  //               emailDomain: 'gia.org',
  //               emailValidity: '',
  //               emailValidityReason: '',
  //               phoneMobile: '1-966-923-4212',
  //               phoneLandline: '647-723-6243',
  //               phone: '722.455.0269',
  //               phoneValidity: '',
  //               phoneValidityReason: '',
  //               phoneCountry: 'MN',
  //               phoneNormalized: '',
  //               phoneType: '',
  //               consentEmail: false,
  //               consentEmailUpdatedAt: '2021-06-07T06:09:36.884Z',
  //               consentSms: false,
  //               consentSmsUpdatedAt: '2021-06-23T07:12:26.284Z',
  //               linkedin: '',
  //               website: '',
  //               companyName: 'Predovic Group',
  //               jobTitle: 'Legacy Interactions Facilitator',
  //               createdAt: '2021-08-18T10:01:29.195Z',
  //               updatedAt: '2021-09-03T02:23:10.718Z',
  //             },
  //             {
  //               userId: '1640966984438.00o2w',
  //               firstName: 'Laura',
  //               lastName: 'Schulist',
  //               fullName: 'Laura Schulist',
  //               birthdate: '2021-12-28T10:21:22.718Z',
  //               language: 'IQ',
  //               gender: 'Female to male trans man',
  //               civility: 'Dr.',
  //               address: '4157 Dickens Road',
  //               postalCode: '18381-7008',
  //               city: 'Lake Maxshire',
  //               region: '',
  //               countryName: 'Bosnia and Herzegovina',
  //               countryCode: 'IL',
  //               email: 'Akeem_King84@hotmail.com',
  //               emailFormat: '',
  //               emailDomain: 'summer.name',
  //               emailValidity: '',
  //               emailValidityReason: '',
  //               phoneMobile: '(305) 721-9540 x539',
  //               phoneLandline: '758-379-2054 x987',
  //               phone: '1-714-687-6976 x825',
  //               phoneValidity: '',
  //               phoneValidityReason: '',
  //               phoneCountry: 'US',
  //               phoneNormalized: '',
  //               phoneType: '',
  //               consentEmail: false,
  //               consentEmailUpdatedAt: '2021-10-09T11:49:41.811Z',
  //               consentSms: true,
  //               consentSmsUpdatedAt: '2021-02-17T10:38:31.901Z',
  //               linkedin: '',
  //               website: '',
  //               companyName: 'Kshlerin - Jaskolski',
  //               jobTitle: 'Lead Marketing Analyst',
  //               createdAt: '2021-04-11T11:08:00.631Z',
  //               updatedAt: '2021-12-29T12:24:02.439Z',
  //             },
  //             {
  //               userId: '1640966984438.0n53i',
  //               firstName: 'Wyman',
  //               lastName: 'Crona',
  //               fullName: 'Wyman Crona',
  //               birthdate: '2021-06-10T23:16:27.152Z',
  //               language: 'RO',
  //               gender: 'Male to female trans woman',
  //               civility: 'Mr.',
  //               address: '8033 Hyatt Cape',
  //               postalCode: '36080',
  //               city: 'East Rickey',
  //               region: '',
  //               countryName: 'Botswana',
  //               countryCode: 'MK',
  //               email: 'Vivien.Ziemann@yahoo.com',
  //               emailFormat: '',
  //               emailDomain: 'treva.info',
  //               emailValidity: '',
  //               emailValidityReason: '',
  //               phoneMobile: '(434) 354-1596',
  //               phoneLandline: '278.936.2427',
  //               phone: '400-499-7886 x478',
  //               phoneValidity: '',
  //               phoneValidityReason: '',
  //               phoneCountry: 'TD',
  //               phoneNormalized: '',
  //               phoneType: '',
  //               consentEmail: true,
  //               consentEmailUpdatedAt: '2021-03-10T03:43:34.218Z',
  //               consentSms: true,
  //               consentSmsUpdatedAt: '2021-02-24T06:07:47.365Z',
  //               linkedin: '',
  //               website: '',
  //               companyName: 'Wilkinson Inc',
  //               jobTitle: 'Regional Program Director',
  //               createdAt: '2021-09-14T21:02:30.882Z',
  //               updatedAt: '2021-02-18T06:06:47.760Z',
  //             },
  //             {
  //               userId: '1640966984438.0rsko',
  //               firstName: 'Alva',
  //               lastName: 'Miller',
  //               fullName: 'Alva Miller',
  //               birthdate: '2021-01-04T08:55:03.672Z',
  //               language: 'IE',
  //               gender: 'Cis Woman',
  //               civility: 'Miss',
  //               address: '8346 Hester Circle',
  //               postalCode: '93341-9905',
  //               city: 'East Erynport',
  //               region: '',
  //               countryName: 'South Georgia and the South Sandwich Islands',
  //               countryCode: 'GS',
  //               email: 'Tevin.Trantow@hotmail.com',
  //               emailFormat: '',
  //               emailDomain: 'kristina.com',
  //               emailValidity: '',
  //               emailValidityReason: '',
  //               phoneMobile: '959-663-6809',
  //               phoneLandline: '(788) 301-1177',
  //               phone: '868.897.2243 x28394',
  //               phoneValidity: '',
  //               phoneValidityReason: '',
  //               phoneCountry: 'GE',
  //               phoneNormalized: '',
  //               phoneType: '',
  //               consentEmail: false,
  //               consentEmailUpdatedAt: '2021-08-02T11:04:23.051Z',
  //               consentSms: false,
  //               consentSmsUpdatedAt: '2021-11-21T00:47:12.922Z',
  //               linkedin: '',
  //               website: '',
  //               companyName: 'Wolf - Wisoky',
  //               jobTitle: 'Dynamic Solutions Supervisor',
  //               createdAt: '2021-07-02T05:27:16.947Z',
  //               updatedAt: '2021-10-05T18:06:35.808Z',
  //             },
  //             {
  //               userId: '1640966984438.0l61u',
  //               firstName: 'Erin',
  //               lastName: 'Koch',
  //               fullName: 'Erin Koch',
  //               birthdate: '2021-07-28T06:50:42.834Z',
  //               language: 'LS',
  //               gender: 'Transgender Person',
  //               civility: 'Miss',
  //               address: '65407 Roberts Way',
  //               postalCode: '58698-4884',
  //               city: 'Vallejo',
  //               region: '',
  //               countryName: 'Netherlands',
  //               countryCode: 'AD',
  //               email: 'Hilma_Toy2@gmail.com',
  //               emailFormat: '',
  //               emailDomain: 'theron.org',
  //               emailValidity: '',
  //               emailValidityReason: '',
  //               phoneMobile: '(640) 470-3784',
  //               phoneLandline: '597-840-1626',
  //               phone: '454.397.4916',
  //               phoneValidity: '',
  //               phoneValidityReason: '',
  //               phoneCountry: 'KN',
  //               phoneNormalized: '',
  //               phoneType: '',
  //               consentEmail: false,
  //               consentEmailUpdatedAt: '2021-08-19T08:53:11.128Z',
  //               consentSms: true,
  //               consentSmsUpdatedAt: '2021-04-22T20:14:20.781Z',
  //               linkedin: '',
  //               website: '',
  //               companyName: 'Miller - Oberbrunner',
  //               jobTitle: 'District Directives Officer',
  //               createdAt: '2021-08-02T17:27:46.656Z',
  //               updatedAt: '2021-03-17T15:53:09.666Z',
  //             },
  //             {
  //               userId: '1640966984439.03ita',
  //               firstName: 'Ena',
  //               lastName: 'Harvey',
  //               fullName: 'Ena Harvey',
  //               birthdate: '2021-07-14T11:49:51.257Z',
  //               language: 'TV',
  //               gender: 'Gender Questioning',
  //               civility: 'Ms.',
  //               address: '14641 Laron Roads',
  //               postalCode: '52110',
  //               city: 'Consueloside',
  //               region: '',
  //               countryName: 'Nauru',
  //               countryCode: 'UM',
  //               email: 'Dudley.Hayes@yahoo.com',
  //               emailFormat: '',
  //               emailDomain: 'morton.net',
  //               emailValidity: '',
  //               emailValidityReason: '',
  //               phoneMobile: '(926) 735-3968',
  //               phoneLandline: '891.945.5264 x74672',
  //               phone: '984.819.2336 x2357',
  //               phoneValidity: '',
  //               phoneValidityReason: '',
  //               phoneCountry: 'SG',
  //               phoneNormalized: '',
  //               phoneType: '',
  //               consentEmail: false,
  //               consentEmailUpdatedAt: '2021-03-13T19:10:59.578Z',
  //               consentSms: true,
  //               consentSmsUpdatedAt: '2021-04-29T21:30:48.299Z',
  //               linkedin: '',
  //               website: '',
  //               companyName: 'Haley, Skiles and DuBuque',
  //               jobTitle: 'Dynamic Implementation Administrator',
  //               createdAt: '2021-04-17T22:10:22.576Z',
  //               updatedAt: '2021-10-21T17:51:47.522Z',
  //             },
  //             {
  //               userId: '1640966984439.0sv0x',
  //               firstName: 'Jeffry',
  //               lastName: 'Grant',
  //               fullName: 'Jeffry Grant',
  //               birthdate: '2021-03-03T08:40:17.894Z',
  //               language: 'AG',
  //               gender: 'Trans*Man',
  //               civility: 'Dr.',
  //               address: '427 Trantow Skyway',
  //               postalCode: '07845',
  //               city: 'Racine',
  //               region: '',
  //               countryName: 'Burkina Faso',
  //               countryCode: 'MM',
  //               email: 'Brent54@yahoo.com',
  //               emailFormat: '',
  //               emailDomain: 'priscilla.name',
  //               emailValidity: '',
  //               emailValidityReason: '',
  //               phoneMobile: '1-489-290-9629 x046',
  //               phoneLandline: '310-936-4325',
  //               phone: '964-339-4710 x65850',
  //               phoneValidity: '',
  //               phoneValidityReason: '',
  //               phoneCountry: 'BB',
  //               phoneNormalized: '',
  //               phoneType: '',
  //               consentEmail: false,
  //               consentEmailUpdatedAt: '2021-12-11T05:24:19.047Z',
  //               consentSms: false,
  //               consentSmsUpdatedAt: '2021-06-24T20:23:27.230Z',
  //               linkedin: '',
  //               website: '',
  //               companyName: 'Kihn - Veum',
  //               jobTitle: 'Dynamic Metrics Facilitator',
  //               createdAt: '2021-08-26T20:38:34.580Z',
  //               updatedAt: '2021-08-10T02:10:18.147Z',
  //             },
  //             {
  //               userId: '1640966984439.0umfk',
  //               firstName: 'Jo',
  //               lastName: 'Spinka',
  //               fullName: 'Jo Spinka',
  //               birthdate: '2021-07-08T15:17:35.519Z',
  //               language: 'AG',
  //               gender: 'Transexual Person',
  //               civility: 'Miss',
  //               address: '6970 Von Route',
  //               postalCode: '10400',
  //               city: 'Elisabethmouth',
  //               region: '',
  //               countryName: 'Belarus',
  //               countryCode: 'BN',
  //               email: 'Dante64@gmail.com',
  //               emailFormat: '',
  //               emailDomain: 'freddy.biz',
  //               emailValidity: '',
  //               emailValidityReason: '',
  //               phoneMobile: '(217) 330-9077 x2903',
  //               phoneLandline: '600.360.8596 x3545',
  //               phone: '1-793-309-3424 x92674',
  //               phoneValidity: '',
  //               phoneValidityReason: '',
  //               phoneCountry: 'NL',
  //               phoneNormalized: '',
  //               phoneType: '',
  //               consentEmail: false,
  //               consentEmailUpdatedAt: '2021-05-11T08:18:11.102Z',
  //               consentSms: false,
  //               consentSmsUpdatedAt: '2021-11-10T01:36:59.832Z',
  //               linkedin: '',
  //               website: '',
  //               companyName: 'White, Homenick and Harber',
  //               jobTitle: 'Future Brand Producer',
  //               createdAt: '2021-04-25T19:15:10.923Z',
  //               updatedAt: '2021-07-28T11:42:23.810Z',
  //             },
  //             {
  //               userId: '1640966984439.0xonc',
  //               firstName: 'Doug',
  //               lastName: 'Parker',
  //               fullName: 'Doug Parker',
  //               birthdate: '2021-01-12T08:46:31.872Z',
  //               language: 'GG',
  //               gender: 'Intersex',
  //               civility: 'Mr.',
  //               address: '36068 Bechtelar Overpass',
  //               postalCode: '73902',
  //               city: 'Verdieshire',
  //               region: '',
  //               countryName: 'Nauru',
  //               countryCode: 'LR',
  //               email: 'Carissa_Keebler43@yahoo.com',
  //               emailFormat: '',
  //               emailDomain: 'deron.info',
  //               emailValidity: '',
  //               emailValidityReason: '',
  //               phoneMobile: '1-391-799-4761',
  //               phoneLandline: '444.579.3855 x936',
  //               phone: '1-493-645-1761 x1139',
  //               phoneValidity: '',
  //               phoneValidityReason: '',
  //               phoneCountry: 'UZ',
  //               phoneNormalized: '',
  //               phoneType: '',
  //               consentEmail: false,
  //               consentEmailUpdatedAt: '2021-02-24T06:22:23.236Z',
  //               consentSms: false,
  //               consentSmsUpdatedAt: '2021-11-15T16:35:33.024Z',
  //               linkedin: '',
  //               website: '',
  //               companyName: 'Macejkovic - Hermann',
  //               jobTitle: 'National Brand Architect',
  //               createdAt: '2021-01-04T07:19:22.007Z',
  //               updatedAt: '2021-02-28T19:09:59.477Z',
  //             },
  //             {
  //               userId: '1640966984439.0qpnd',
  //               firstName: 'Arden',
  //               lastName: 'Doyle',
  //               fullName: 'Arden Doyle',
  //               birthdate: '2021-06-28T23:26:17.199Z',
  //               language: 'EG',
  //               gender: 'Transmasculine',
  //               civility: 'Miss',
  //               address: '1416 Schneider Freeway',
  //               postalCode: '95483-2358',
  //               city: 'Bereniceborough',
  //               region: '',
  //               countryName: 'Japan',
  //               countryCode: 'IL',
  //               email: 'Kailey.Green30@yahoo.com',
  //               emailFormat: '',
  //               emailDomain: 'eunice.biz',
  //               emailValidity: '',
  //               emailValidityReason: '',
  //               phoneMobile: '(939) 923-4208 x289',
  //               phoneLandline: '361-403-0915',
  //               phone: '1-294-849-1012 x240',
  //               phoneValidity: '',
  //               phoneValidityReason: '',
  //               phoneCountry: 'VU',
  //               phoneNormalized: '',
  //               phoneType: '',
  //               consentEmail: false,
  //               consentEmailUpdatedAt: '2021-02-02T18:59:12.787Z',
  //               consentSms: true,
  //               consentSmsUpdatedAt: '2021-12-23T13:10:34.013Z',
  //               linkedin: '',
  //               website: '',
  //               companyName: 'Padberg and Sons',
  //               jobTitle: 'Investor Group Supervisor',
  //               createdAt: '2021-12-28T19:12:13.080Z',
  //               updatedAt: '2021-04-05T01:57:39.857Z',
  //             },
  //             {
  //               userId: '1640966984439.0mt5l',
  //               firstName: 'Matt',
  //               lastName: 'DuBuque',
  //               fullName: 'Matt DuBuque',
  //               birthdate: '2021-02-27T08:36:29.521Z',
  //               language: 'AZ',
  //               gender: 'Transexual Man',
  //               civility: 'Ms.',
  //               address: '43810 Swaniawski Loop',
  //               postalCode: '08641',
  //               city: 'New Lea',
  //               region: '',
  //               countryName: 'Syrian Arab Republic',
  //               countryCode: 'GG',
  //               email: 'Garth_Lebsack51@gmail.com',
  //               emailFormat: '',
  //               emailDomain: 'bailee.org',
  //               emailValidity: '',
  //               emailValidityReason: '',
  //               phoneMobile: '1-930-737-8833 x81665',
  //               phoneLandline: '(343) 564-4952 x60968',
  //               phone: '(957) 435-6569',
  //               phoneValidity: '',
  //               phoneValidityReason: '',
  //               phoneCountry: 'IR',
  //               phoneNormalized: '',
  //               phoneType: '',
  //               consentEmail: true,
  //               consentEmailUpdatedAt: '2021-09-02T05:41:43.502Z',
  //               consentSms: false,
  //               consentSmsUpdatedAt: '2021-09-24T00:27:07.207Z',
  //               linkedin: '',
  //               website: '',
  //               companyName: 'Bahringer, Mraz and Predovic',
  //               jobTitle: 'International Markets Associate',
  //               createdAt: '2021-08-15T03:46:46.443Z',
  //               updatedAt: '2021-08-17T10:29:51.044Z',
  //             },
  //             {
  //               userId: '1640966984439.0nbf3',
  //               firstName: 'Rowland',
  //               lastName: 'Rippin',
  //               fullName: 'Rowland Rippin',
  //               birthdate: '2021-09-28T22:44:11.021Z',
  //               language: 'EG',
  //               gender: 'Trans Female',
  //               civility: 'Ms.',
  //               address: '74058 Eileen Green',
  //               postalCode: '85046-9874',
  //               city: 'South Darylville',
  //               region: '',
  //               countryName: 'Namibia',
  //               countryCode: 'SN',
  //               email: 'Greta_Prosacco36@yahoo.com',
  //               emailFormat: '',
  //               emailDomain: 'wallace.info',
  //               emailValidity: '',
  //               emailValidityReason: '',
  //               phoneMobile: '464-999-5947 x67974',
  //               phoneLandline: '(795) 720-7779 x7502',
  //               phone: '(718) 812-6400',
  //               phoneValidity: '',
  //               phoneValidityReason: '',
  //               phoneCountry: 'UM',
  //               phoneNormalized: '',
  //               phoneType: '',
  //               consentEmail: true,
  //               consentEmailUpdatedAt: '2021-05-27T23:13:43.780Z',
  //               consentSms: false,
  //               consentSmsUpdatedAt: '2021-10-18T01:40:07.040Z',
  //               linkedin: '',
  //               website: '',
  //               companyName: 'Rutherford, Doyle and Tillman',
  //               jobTitle: 'Dynamic Creative Facilitator',
  //               createdAt: '2021-07-13T08:12:49.412Z',
  //               updatedAt: '2021-12-22T23:13:53.820Z',
  //             },
  //             {
  //               userId: '1640966984439.0bfio',
  //               firstName: 'Jonatan',
  //               lastName: 'Turner',
  //               fullName: 'Jonatan Turner',
  //               birthdate: '2021-04-23T10:42:11.056Z',
  //               language: 'MF',
  //               gender: 'M2F',
  //               civility: 'Ms.',
  //               address: '16673 Toy Path',
  //               postalCode: '70104-1778',
  //               city: 'North Wyatt',
  //               region: '',
  //               countryName: 'El Salvador',
  //               countryCode: 'SD',
  //               email: 'Augustus_Boyer8@gmail.com',
  //               emailFormat: '',
  //               emailDomain: 'tiara.name',
  //               emailValidity: '',
  //               emailValidityReason: '',
  //               phoneMobile: '(346) 475-0574 x9994',
  //               phoneLandline: '(693) 360-1223 x246',
  //               phone: '(588) 977-5072 x529',
  //               phoneValidity: '',
  //               phoneValidityReason: '',
  //               phoneCountry: 'IQ',
  //               phoneNormalized: '',
  //               phoneType: '',
  //               consentEmail: true,
  //               consentEmailUpdatedAt: '2021-10-21T04:28:23.151Z',
  //               consentSms: true,
  //               consentSmsUpdatedAt: '2021-06-15T14:02:17.594Z',
  //               linkedin: '',
  //               website: '',
  //               companyName: 'Rowe, Metz and Aufderhar',
  //               jobTitle: 'International Applications Coordinator',
  //               createdAt: '2021-03-12T12:30:55.206Z',
  //               updatedAt: '2021-12-04T02:15:47.430Z',
  //             },
  //             {
  //               userId: '1640966984439.0rb7r',
  //               firstName: 'Clemens',
  //               lastName: 'Howe',
  //               fullName: 'Clemens Howe',
  //               birthdate: '2021-04-09T15:28:02.980Z',
  //               language: 'CK',
  //               gender: 'Intersex',
  //               civility: 'Ms.',
  //               address: '881 Jed Turnpike',
  //               postalCode: '60628',
  //               city: 'Medhurstbury',
  //               region: '',
  //               countryName: 'Turkmenistan',
  //               countryCode: 'YE',
  //               email: 'Gabriella_Nolan77@gmail.com',
  //               emailFormat: '',
  //               emailDomain: 'monique.name',
  //               emailValidity: '',
  //               emailValidityReason: '',
  //               phoneMobile: '325-964-3105 x6386',
  //               phoneLandline: '251.542.0447',
  //               phone: '688-916-7453 x38607',
  //               phoneValidity: '',
  //               phoneValidityReason: '',
  //               phoneCountry: 'KM',
  //               phoneNormalized: '',
  //               phoneType: '',
  //               consentEmail: false,
  //               consentEmailUpdatedAt: '2021-04-04T17:29:36.290Z',
  //               consentSms: true,
  //               consentSmsUpdatedAt: '2021-03-01T02:58:25.543Z',
  //               linkedin: '',
  //               website: '',
  //               companyName: 'Boyle, Buckridge and Kohler',
  //               jobTitle: 'National Interactions Associate',
  //               createdAt: '2021-07-03T13:24:08.782Z',
  //               updatedAt: '2021-01-18T06:04:16.607Z',
  //             },
  //             {
  //               userId: '1640966984439.08p7i',
  //               firstName: 'Eleonore',
  //               lastName: 'Grady',
  //               fullName: 'Eleonore Grady',
  //               birthdate: '2021-05-17T03:52:19.871Z',
  //               language: 'GM',
  //               gender: 'Trans*Man',
  //               civility: 'Mrs.',
  //               address: '392 Simonis Spur',
  //               postalCode: '10415-8730',
  //               city: 'West Tod',
  //               region: '',
  //               countryName: 'Spain',
  //               countryCode: 'FR',
  //               email: 'Juston42@gmail.com',
  //               emailFormat: '',
  //               emailDomain: 'larue.info',
  //               emailValidity: '',
  //               emailValidityReason: '',
  //               phoneMobile: '365-489-5547 x4417',
  //               phoneLandline: '295-259-2453',
  //               phone: '916.208.3599',
  //               phoneValidity: '',
  //               phoneValidityReason: '',
  //               phoneCountry: 'JE',
  //               phoneNormalized: '',
  //               phoneType: '',
  //               consentEmail: false,
  //               consentEmailUpdatedAt: '2021-10-17T21:20:04.348Z',
  //               consentSms: false,
  //               consentSmsUpdatedAt: '2021-05-18T12:31:01.476Z',
  //               linkedin: '',
  //               website: '',
  //               companyName: 'Wintheiser, Crooks and Schumm',
  //               jobTitle: 'District Configuration Director',
  //               createdAt: '2021-07-10T06:37:00.191Z',
  //               updatedAt: '2021-09-29T06:12:50.443Z',
  //             },
  //             {
  //               userId: '1640966984439.0rjo4',
  //               firstName: 'Aglae',
  //               lastName: 'Koch',
  //               fullName: 'Aglae Koch',
  //               birthdate: '2021-05-22T09:16:41.532Z',
  //               language: 'PT',
  //               gender: 'Transgender Person',
  //               civility: 'Dr.',
  //               address: '3333 Rolfson Circles',
  //               postalCode: '66402',
  //               city: 'Port Shanel',
  //               region: '',
  //               countryName: 'Kuwait',
  //               countryCode: 'IM',
  //               email: 'Theodore_Durgan27@gmail.com',
  //               emailFormat: '',
  //               emailDomain: 'abigail.info',
  //               emailValidity: '',
  //               emailValidityReason: '',
  //               phoneMobile: '710-882-9786 x90262',
  //               phoneLandline: '(828) 523-9973 x061',
  //               phone: '648.671.0390 x73734',
  //               phoneValidity: '',
  //               phoneValidityReason: '',
  //               phoneCountry: 'BG',
  //               phoneNormalized: '',
  //               phoneType: '',
  //               consentEmail: false,
  //               consentEmailUpdatedAt: '2021-05-05T04:24:56.724Z',
  //               consentSms: true,
  //               consentSmsUpdatedAt: '2021-03-07T08:31:04.918Z',
  //               linkedin: '',
  //               website: '',
  //               companyName: 'Morissette, Schroeder and Ritchie',
  //               jobTitle: 'Central Operations Architect',
  //               createdAt: '2021-10-05T23:01:36.457Z',
  //               updatedAt: '2021-04-25T20:17:58.179Z',
  //             },
  //             {
  //               userId: '1640966984439.0bolj',
  //               firstName: 'Melvin',
  //               lastName: 'Abernathy',
  //               fullName: 'Melvin Abernathy',
  //               birthdate: '2021-09-30T01:45:42.030Z',
  //               language: 'TJ',
  //               gender: 'Gender Questioning',
  //               civility: 'Mr.',
  //               address: '048 Simonis Port',
  //               postalCode: '89403',
  //               city: 'Flatleychester',
  //               region: '',
  //               countryName: 'Mayotte',
  //               countryCode: 'DJ',
  //               email: 'Ana.Hagenes@hotmail.com',
  //               emailFormat: '',
  //               emailDomain: 'jerrold.biz',
  //               emailValidity: '',
  //               emailValidityReason: '',
  //               phoneMobile: '1-511-400-0928 x873',
  //               phoneLandline: '(713) 795-9750',
  //               phone: '218.311.3990 x3602',
  //               phoneValidity: '',
  //               phoneValidityReason: '',
  //               phoneCountry: 'AR',
  //               phoneNormalized: '',
  //               phoneType: '',
  //               consentEmail: true,
  //               consentEmailUpdatedAt: '2021-09-30T16:54:10.888Z',
  //               consentSms: false,
  //               consentSmsUpdatedAt: '2021-06-18T04:12:23.697Z',
  //               linkedin: '',
  //               website: '',
  //               companyName: 'Howell - Mosciski',
  //               jobTitle: 'Senior Marketing Executive',
  //               createdAt: '2021-07-07T07:04:56.725Z',
  //               updatedAt: '2021-06-13T06:52:26.562Z',
  //             },
  //             {
  //               userId: '1640966984440.02zl3',
  //               firstName: 'Kody',
  //               lastName: 'Brakus',
  //               fullName: 'Kody Brakus',
  //               birthdate: '2021-01-30T07:59:20.909Z',
  //               language: 'SL',
  //               gender: 'Transmasculine',
  //               civility: 'Mrs.',
  //               address: '1376 Janessa Port',
  //               postalCode: '99842-1910',
  //               city: 'East Ford',
  //               region: '',
  //               countryName: "Lao People's Democratic Republic",
  //               countryCode: 'ML',
  //               email: 'Turner_Cartwright@hotmail.com',
  //               emailFormat: '',
  //               emailDomain: 'gage.biz',
  //               emailValidity: '',
  //               emailValidityReason: '',
  //               phoneMobile: '581.601.8983',
  //               phoneLandline: '(835) 640-7620 x13916',
  //               phone: '543-439-7460',
  //               phoneValidity: '',
  //               phoneValidityReason: '',
  //               phoneCountry: 'DZ',
  //               phoneNormalized: '',
  //               phoneType: '',
  //               consentEmail: false,
  //               consentEmailUpdatedAt: '2021-04-02T12:33:18.517Z',
  //               consentSms: true,
  //               consentSmsUpdatedAt: '2021-06-18T09:30:25.105Z',
  //               linkedin: '',
  //               website: '',
  //               companyName: 'Little Inc',
  //               jobTitle: 'Senior Data Technician',
  //               createdAt: '2021-07-08T19:09:21.922Z',
  //               updatedAt: '2021-07-31T22:13:16.057Z',
  //             },
  //             {
  //               userId: '1640966984440.0rlgs',
  //               firstName: 'Lolita',
  //               lastName: 'Greenholt',
  //               fullName: 'Lolita Greenholt',
  //               birthdate: '2021-10-18T15:41:03.504Z',
  //               language: 'CK',
  //               gender: 'FTM',
  //               civility: 'Dr.',
  //               address: '977 Hickle Parks',
  //               postalCode: '76648-0186',
  //               city: 'Josuefort',
  //               region: '',
  //               countryName: 'Thailand',
  //               countryCode: 'SB',
  //               email: 'Bobbie49@yahoo.com',
  //               emailFormat: '',
  //               emailDomain: 'verner.org',
  //               emailValidity: '',
  //               emailValidityReason: '',
  //               phoneMobile: '1-487-369-1884 x9219',
  //               phoneLandline: '(972) 510-1628 x7265',
  //               phone: '561-735-7309',
  //               phoneValidity: '',
  //               phoneValidityReason: '',
  //               phoneCountry: 'TG',
  //               phoneNormalized: '',
  //               phoneType: '',
  //               consentEmail: false,
  //               consentEmailUpdatedAt: '2021-11-03T04:22:01.246Z',
  //               consentSms: false,
  //               consentSmsUpdatedAt: '2021-05-21T19:08:50.694Z',
  //               linkedin: '',
  //               website: '',
  //               companyName: 'Heidenreich Group',
  //               jobTitle: 'Dynamic Security Facilitator',
  //               createdAt: '2021-04-09T07:01:37.752Z',
  //               updatedAt: '2021-09-29T18:24:30.578Z',
  //             },
  //             {
  //               userId: '1640966984440.0jbai',
  //               firstName: 'Derick',
  //               lastName: 'Muller',
  //               fullName: 'Derick Muller',
  //               birthdate: '2021-08-16T20:09:06.309Z',
  //               language: 'GS',
  //               gender: 'Neither',
  //               civility: 'Mrs.',
  //               address: '6417 Otilia Prairie',
  //               postalCode: '79404',
  //               city: 'Palatine',
  //               region: '',
  //               countryName: 'Western Sahara',
  //               countryCode: 'DE',
  //               email: 'Rebekah_Okuneva36@yahoo.com',
  //               emailFormat: '',
  //               emailDomain: 'marques.com',
  //               emailValidity: '',
  //               emailValidityReason: '',
  //               phoneMobile: '1-279-220-9334',
  //               phoneLandline: '1-889-386-4541 x69035',
  //               phone: '429.455.0231 x87359',
  //               phoneValidity: '',
  //               phoneValidityReason: '',
  //               phoneCountry: 'CH',
  //               phoneNormalized: '',
  //               phoneType: '',
  //               consentEmail: true,
  //               consentEmailUpdatedAt: '2021-01-28T02:53:09.755Z',
  //               consentSms: true,
  //               consentSmsUpdatedAt: '2021-11-17T15:15:32.268Z',
  //               linkedin: '',
  //               website: '',
  //               companyName: 'Grimes, Robel and Greenholt',
  //               jobTitle: 'International Metrics Designer',
  //               createdAt: '2021-09-24T01:35:58.125Z',
  //               updatedAt: '2021-11-01T15:42:25.007Z',
  //             },
  //             {
  //               userId: '1640966984440.04l5s',
  //               firstName: 'Earnest',
  //               lastName: 'Koepp',
  //               fullName: 'Earnest Koepp',
  //               birthdate: '2021-01-17T22:42:55.705Z',
  //               language: 'DO',
  //               gender: 'Other',
  //               civility: 'Ms.',
  //               address: '861 Toy Trail',
  //               postalCode: '65007',
  //               city: 'Port Amani',
  //               region: '',
  //               countryName: 'Central African Republic',
  //               countryCode: 'BH',
  //               email: 'Rosalind.Barton76@yahoo.com',
  //               emailFormat: '',
  //               emailDomain: 'gerry.net',
  //               emailValidity: '',
  //               emailValidityReason: '',
  //               phoneMobile: '216.380.7237 x96441',
  //               phoneLandline: '(825) 589-3570 x502',
  //               phone: '855-874-4515 x50208',
  //               phoneValidity: '',
  //               phoneValidityReason: '',
  //               phoneCountry: 'VC',
  //               phoneNormalized: '',
  //               phoneType: '',
  //               consentEmail: true,
  //               consentEmailUpdatedAt: '2021-08-15T20:14:51.123Z',
  //               consentSms: false,
  //               consentSmsUpdatedAt: '2021-01-09T04:28:18.396Z',
  //               linkedin: '',
  //               website: '',
  //               companyName: 'Bartell Inc',
  //               jobTitle: 'Principal Optimization Designer',
  //               createdAt: '2021-06-19T06:29:07.922Z',
  //               updatedAt: '2021-10-29T06:30:20.055Z',
  //             },
  //             {
  //               userId: '1640966984440.0t850',
  //               firstName: 'Elyse',
  //               lastName: 'Hudson',
  //               fullName: 'Elyse Hudson',
  //               birthdate: '2021-10-16T08:59:46.471Z',
  //               language: 'GW',
  //               gender: 'Female to male transsexual man',
  //               civility: 'Miss',
  //               address: '5940 Colten Circles',
  //               postalCode: '25209',
  //               city: 'Lake Arliechester',
  //               region: '',
  //               countryName: 'Brazil',
  //               countryCode: 'BB',
  //               email: 'Blaise_Volkman@gmail.com',
  //               emailFormat: '',
  //               emailDomain: 'laura.net',
  //               emailValidity: '',
  //               emailValidityReason: '',
  //               phoneMobile: '435-830-1581',
  //               phoneLandline: '785-872-7132 x5656',
  //               phone: '(465) 216-5571 x20972',
  //               phoneValidity: '',
  //               phoneValidityReason: '',
  //               phoneCountry: 'MO',
  //               phoneNormalized: '',
  //               phoneType: '',
  //               consentEmail: true,
  //               consentEmailUpdatedAt: '2021-03-29T14:04:29.047Z',
  //               consentSms: false,
  //               consentSmsUpdatedAt: '2021-03-25T13:20:04.702Z',
  //               linkedin: '',
  //               website: '',
  //               companyName: 'Stamm - West',
  //               jobTitle: 'Customer Quality Director',
  //               createdAt: '2021-03-15T05:38:23.252Z',
  //               updatedAt: '2021-06-09T12:53:05.079Z',
  //             },
  //             {
  //               userId: '1640966984440.0xnn8',
  //               firstName: 'Jamey',
  //               lastName: 'Thompson',
  //               fullName: 'Jamey Thompson',
  //               birthdate: '2021-04-29T06:06:18.794Z',
  //               language: 'AL',
  //               gender: 'Gender Nonconforming',
  //               civility: 'Mrs.',
  //               address: '486 Walter Ports',
  //               postalCode: '20163',
  //               city: 'Lucindaton',
  //               region: '',
  //               countryName: 'Republic of Korea',
  //               countryCode: 'TH',
  //               email: 'Lacey.Gutmann@yahoo.com',
  //               emailFormat: '',
  //               emailDomain: 'josiane.biz',
  //               emailValidity: '',
  //               emailValidityReason: '',
  //               phoneMobile: '1-511-417-9341 x64650',
  //               phoneLandline: '950.388.1281 x38092',
  //               phone: '290-274-8487 x454',
  //               phoneValidity: '',
  //               phoneValidityReason: '',
  //               phoneCountry: 'DZ',
  //               phoneNormalized: '',
  //               phoneType: '',
  //               consentEmail: false,
  //               consentEmailUpdatedAt: '2021-03-24T05:19:17.789Z',
  //               consentSms: true,
  //               consentSmsUpdatedAt: '2021-06-14T03:32:55.060Z',
  //               linkedin: '',
  //               website: '',
  //               companyName: 'Haley - Littel',
  //               jobTitle: 'Human Research Executive',
  //               createdAt: '2021-02-24T02:15:07.973Z',
  //               updatedAt: '2021-06-17T09:03:31.140Z',
  //             },
  //             {
  //               userId: '1640966984440.0oznh',
  //               firstName: 'Kallie',
  //               lastName: 'Watsica',
  //               fullName: 'Kallie Watsica',
  //               birthdate: '2021-02-10T03:09:02.283Z',
  //               language: 'TD',
  //               gender: 'Pangender',
  //               civility: 'Dr.',
  //               address: '8394 Senger Spur',
  //               postalCode: '09758-7184',
  //               city: 'Cary',
  //               region: '',
  //               countryName: 'Macedonia',
  //               countryCode: 'GB',
  //               email: 'Everardo_Schaden@hotmail.com',
  //               emailFormat: '',
  //               emailDomain: 'erna.biz',
  //               emailValidity: '',
  //               emailValidityReason: '',
  //               phoneMobile: '(639) 980-7233',
  //               phoneLandline: '356-378-0496 x221',
  //               phone: '1-364-720-2163 x672',
  //               phoneValidity: '',
  //               phoneValidityReason: '',
  //               phoneCountry: 'SS',
  //               phoneNormalized: '',
  //               phoneType: '',
  //               consentEmail: true,
  //               consentEmailUpdatedAt: '2021-03-08T04:41:48.231Z',
  //               consentSms: false,
  //               consentSmsUpdatedAt: '2021-09-14T22:36:39.653Z',
  //               linkedin: '',
  //               website: '',
  //               companyName: 'Cremin, Zboncak and Goldner',
  //               jobTitle: 'Future Intranet Strategist',
  //               createdAt: '2021-08-12T04:27:41.272Z',
  //               updatedAt: '2021-05-22T18:17:41.457Z',
  //             },
  //             {
  //               userId: '1640966984440.0b1oh',
  //               firstName: 'Vernie',
  //               lastName: 'Cartwright',
  //               fullName: 'Vernie Cartwright',
  //               birthdate: '2021-12-17T05:16:46.928Z',
  //               language: 'LK',
  //               gender: 'Two-spirit person',
  //               civility: 'Ms.',
  //               address: '102 Lucious Freeway',
  //               postalCode: '45310',
  //               city: 'East Justonland',
  //               region: '',
  //               countryName: 'China',
  //               countryCode: 'SR',
  //               email: 'Rupert7@gmail.com',
  //               emailFormat: '',
  //               emailDomain: 'ephraim.biz',
  //               emailValidity: '',
  //               emailValidityReason: '',
  //               phoneMobile: '462.397.4076 x123',
  //               phoneLandline: '1-367-701-2237 x35330',
  //               phone: '968-810-5832',
  //               phoneValidity: '',
  //               phoneValidityReason: '',
  //               phoneCountry: 'BM',
  //               phoneNormalized: '',
  //               phoneType: '',
  //               consentEmail: false,
  //               consentEmailUpdatedAt: '2021-04-16T07:36:18.223Z',
  //               consentSms: true,
  //               consentSmsUpdatedAt: '2021-10-03T21:09:08.379Z',
  //               linkedin: '',
  //               website: '',
  //               companyName: 'Upton and Sons',
  //               jobTitle: 'Global Marketing Facilitator',
  //               createdAt: '2021-12-03T17:19:55.747Z',
  //               updatedAt: '2021-04-27T20:22:14.147Z',
  //             },
  //             {
  //               userId: '1640966984440.0ksxv',
  //               firstName: 'Melvina',
  //               lastName: 'Rau',
  //               fullName: 'Melvina Rau',
  //               birthdate: '2021-04-22T06:54:40.249Z',
  //               language: 'GD',
  //               gender: 'Male to female transsexual woman',
  //               civility: 'Ms.',
  //               address: '941 Reichel Springs',
  //               postalCode: '79711-3193',
  //               city: 'Port Carmela',
  //               region: '',
  //               countryName: 'Dominican Republic',
  //               countryCode: 'AG',
  //               email: 'Katrina6@yahoo.com',
  //               emailFormat: '',
  //               emailDomain: 'tania.org',
  //               emailValidity: '',
  //               emailValidityReason: '',
  //               phoneMobile: '1-828-586-5026',
  //               phoneLandline: '841-371-7996 x340',
  //               phone: '1-321-397-1288',
  //               phoneValidity: '',
  //               phoneValidityReason: '',
  //               phoneCountry: 'KY',
  //               phoneNormalized: '',
  //               phoneType: '',
  //               consentEmail: false,
  //               consentEmailUpdatedAt: '2021-10-10T20:06:25.093Z',
  //               consentSms: true,
  //               consentSmsUpdatedAt: '2021-12-18T21:32:10.977Z',
  //               linkedin: '',
  //               website: '',
  //               companyName: 'Lehner, Haag and Jones',
  //               jobTitle: 'Future Assurance Planner',
  //               createdAt: '2021-02-21T06:19:52.687Z',
  //               updatedAt: '2021-06-10T09:09:08.534Z',
  //             },
  //             {
  //               userId: '1640966984440.027f4',
  //               firstName: 'Ron',
  //               lastName: 'Thompson',
  //               fullName: 'Ron Thompson',
  //               birthdate: '2021-03-11T04:20:46.853Z',
  //               language: 'CA',
  //               gender: 'Trans Man',
  //               civility: 'Dr.',
  //               address: '1844 Colleen Highway',
  //               postalCode: '92756-9915',
  //               city: 'Albany',
  //               region: '',
  //               countryName: 'Gabon',
  //               countryCode: 'CU',
  //               email: 'Charlotte.Wuckert@yahoo.com',
  //               emailFormat: '',
  //               emailDomain: 'hallie.net',
  //               emailValidity: '',
  //               emailValidityReason: '',
  //               phoneMobile: '266-562-5774',
  //               phoneLandline: '1-644-342-2617 x596',
  //               phone: '1-764-557-7349 x307',
  //               phoneValidity: '',
  //               phoneValidityReason: '',
  //               phoneCountry: 'CY',
  //               phoneNormalized: '',
  //               phoneType: '',
  //               consentEmail: false,
  //               consentEmailUpdatedAt: '2021-03-21T12:24:19.374Z',
  //               consentSms: false,
  //               consentSmsUpdatedAt: '2021-01-19T19:57:12.398Z',
  //               linkedin: '',
  //               website: '',
  //               companyName: 'Pacocha Group',
  //               jobTitle: 'Chief Creative Producer',
  //               createdAt: '2021-02-12T12:14:01.214Z',
  //               updatedAt: '2021-01-19T08:56:00.058Z',
  //             },
  //             {
  //               userId: '1640966984440.062ge',
  //               firstName: 'Rosalyn',
  //               lastName: 'Halvorson',
  //               fullName: 'Rosalyn Halvorson',
  //               birthdate: '2021-12-07T08:37:19.267Z',
  //               language: 'UA',
  //               gender: 'M2F',
  //               civility: 'Ms.',
  //               address: '8715 Ullrich Cove',
  //               postalCode: '68568-6886',
  //               city: 'Riverton',
  //               region: '',
  //               countryName: 'Netherlands Antilles',
  //               countryCode: 'UM',
  //               email: 'Miracle_Lockman@hotmail.com',
  //               emailFormat: '',
  //               emailDomain: 'dawson.info',
  //               emailValidity: '',
  //               emailValidityReason: '',
  //               phoneMobile: '682.896.8016 x65378',
  //               phoneLandline: '894.338.9068 x36596',
  //               phone: '1-991-904-7010 x659',
  //               phoneValidity: '',
  //               phoneValidityReason: '',
  //               phoneCountry: 'TD',
  //               phoneNormalized: '',
  //               phoneType: '',
  //               consentEmail: true,
  //               consentEmailUpdatedAt: '2021-11-08T06:49:47.905Z',
  //               consentSms: false,
  //               consentSmsUpdatedAt: '2021-11-11T23:24:02.243Z',
  //               linkedin: '',
  //               website: '',
  //               companyName: 'Powlowski LLC',
  //               jobTitle: 'International Brand Strategist',
  //               createdAt: '2021-03-24T07:21:06.061Z',
  //               updatedAt: '2021-06-30T07:14:43.339Z',
  //             },
  //           ],
  //           key: '1640966984440.05ypg',
  //           created: new Date(),
  //         },
  //         name: 'MY CONTACTS',
  //         category: {
  //           key: '1',
  //           name: 'contacts',
  //           columns: [
  //             {
  //               id: 'col1',
  //               columnName: 'userId',
  //               columnType: 'string',
  //               required: true,
  //               category: 'string',
  //               description: 'this is a description of the column',
  //               position: 1,
  //             },
  //             {
  //               id: '1640965852275.0odo9',
  //               columnName: 'firstName',
  //               columnType: 'string',
  //               required: true,
  //               category: 'first_name',
  //               description: 'this is a description of the column',
  //               position: 1,
  //             },
  //             {
  //               id: '1640965852275.058ic',
  //               columnName: 'lastName',
  //               columnType: 'string',
  //               required: false,
  //               category: 'string',
  //               description: 'this is a description of the column',
  //               position: 2,
  //             },
  //             {
  //               id: '1640965852275.0465e',
  //               columnName: 'fullName',
  //               columnType: 'string',
  //               required: false,
  //               category: 'string',
  //               description: 'this is a description of the column',
  //               position: 3,
  //             },
  //             {
  //               id: '1640965852275.01of7',
  //               columnName: 'birthdate',
  //               columnType: 'date',
  //               required: false,
  //               category: 'date',
  //               description: 'this is a description of the column',
  //               position: 4,
  //             },
  //             {
  //               id: '1640965852275.055c8',
  //               columnName: 'language',
  //               columnType: 'string',
  //               required: false,
  //               category: 'string',
  //               description: 'this is a description of the column',
  //               position: 5,
  //             },
  //             {
  //               id: '1640965852275.08tde',
  //               columnName: 'gender',
  //               columnType: 'string',
  //               required: false,
  //               category: 'string',
  //               description: 'this is a description of the column',
  //               position: 6,
  //             },
  //             {
  //               id: '1640965852275.0wgfq',
  //               columnName: 'civility',
  //               columnType: 'string',
  //               required: false,
  //               category: 'string',
  //               description: 'this is a description of the column',
  //               position: 7,
  //             },
  //             {
  //               id: '1640965852275.0fy4o',
  //               columnName: 'address',
  //               columnType: 'string',
  //               required: false,
  //               category: 'string',
  //               description: 'this is a description of the column',
  //               position: 8,
  //             },
  //             {
  //               id: '1640965852275.05qab',
  //               columnName: 'postalCode',
  //               columnType: 'string',
  //               required: false,
  //               category: 'string',
  //               description: 'this is a description of the column',
  //               position: 9,
  //             },
  //             {
  //               id: '1640965852275.0cj4x',
  //               columnName: 'city',
  //               columnType: 'string',
  //               required: false,
  //               category: 'string',
  //               description: 'this is a description of the column',
  //               position: 10,
  //             },
  //             {
  //               id: '1640965852275.0hpyx',
  //               columnName: 'region',
  //               columnType: 'string',
  //               required: false,
  //               category: 'string',
  //               description: 'this is a description of the column',
  //               position: 11,
  //             },
  //             {
  //               id: '1640965852275.00i9z',
  //               columnName: 'countryName',
  //               columnType: 'string',
  //               required: false,
  //               category: 'string',
  //               description: 'this is a description of the column',
  //               position: 12,
  //             },
  //             {
  //               id: '1640965852275.0m2vk',
  //               columnName: 'countryCode',
  //               columnType: 'string',
  //               required: false,
  //               category: 'string',
  //               description: 'this is a description of the column',
  //               position: 13,
  //             },
  //             {
  //               id: '1640965852275.0i9bu',
  //               columnName: 'email',
  //               columnType: 'string',
  //               required: true,
  //               category: 'email',
  //               description: 'this is a description of the column',
  //               position: 14,
  //             },
  //             {
  //               id: '1640965852275.0ykew',
  //               columnName: 'emailFormat',
  //               columnType: 'string',
  //               required: false,
  //               category: 'string',
  //               description: 'this is a description of the column',
  //               position: 15,
  //             },
  //             {
  //               id: '1640965852275.015q1',
  //               columnName: 'emailDomain',
  //               columnType: 'string',
  //               required: false,
  //               category: 'string',
  //               description: 'this is a description of the column',
  //               position: 16,
  //             },
  //             {
  //               id: '1640965852275.03lpc',
  //               columnName: 'emailValidity',
  //               columnType: 'string',
  //               required: false,
  //               category: 'string',
  //               description: 'this is a description of the column',
  //               position: 17,
  //             },
  //             {
  //               id: '1640965852275.0juk9',
  //               columnName: 'emailValidityReason',
  //               columnType: 'string',
  //               required: false,
  //               category: 'string',
  //               description: 'this is a description of the column',
  //               position: 18,
  //             },
  //             {
  //               id: '1640965852275.00ptc',
  //               columnName: 'phoneMobile',
  //               columnType: 'string',
  //               required: false,
  //               category: 'phone',
  //               description: 'this is a description of the column',
  //               position: 19,
  //             },
  //             {
  //               id: '1640965852275.0xcjl',
  //               columnName: 'phoneLandline',
  //               columnType: 'string',
  //               required: false,
  //               category: 'phone',
  //               description: 'this is a description of the column',
  //               position: 20,
  //             },
  //             {
  //               id: '1640965852275.0c1e9',
  //               columnName: 'phone',
  //               columnType: 'string',
  //               required: false,
  //               category: 'phone',
  //               description: 'this is a description of the column',
  //               position: 21,
  //             },
  //             {
  //               id: '1640965852275.0wfzb',
  //               columnName: 'phoneValidity',
  //               columnType: 'string',
  //               required: false,
  //               category: 'string',
  //               description: 'this is a description of the column',
  //               position: 22,
  //             },
  //             {
  //               id: '1640965852275.0pc30',
  //               columnName: 'phoneValidityReason',
  //               columnType: 'string',
  //               required: false,
  //               category: 'string',
  //               description: 'this is a description of the column',
  //               position: 23,
  //             },
  //             {
  //               id: '1640965852275.0ju1a',
  //               columnName: 'phoneCountry',
  //               columnType: 'string',
  //               required: false,
  //               category: 'string',
  //               description: 'this is a description of the column',
  //               position: 24,
  //             },
  //             {
  //               id: '1640965852275.0m2f7',
  //               columnName: 'phoneNormalized',
  //               columnType: 'string',
  //               required: false,
  //               category: 'string',
  //               description: 'this is a description of the column',
  //               position: 25,
  //             },
  //             {
  //               id: '1640965852275.05lrl',
  //               columnName: 'phoneType',
  //               columnType: 'string',
  //               required: false,
  //               category: 'string',
  //               description: 'this is a description of the column',
  //               position: 26,
  //             },
  //             {
  //               id: '1640965852275.05q4c',
  //               columnName: 'consentEmail',
  //               columnType: 'boolean',
  //               required: false,
  //               category: 'boolean',
  //               description: 'this is a description of the column',
  //               position: 27,
  //             },
  //             {
  //               id: '1640965852275.0czcs',
  //               columnName: 'consentEmailUpdatedAt',
  //               columnType: 'date',
  //               required: false,
  //               category: 'date',
  //               description: 'this is a description of the column',
  //               position: 28,
  //             },
  //             {
  //               id: '1640965852275.002a6',
  //               columnName: 'consentSms',
  //               columnType: 'boolean',
  //               required: false,
  //               category: 'boolean',
  //               description: 'this is a description of the column',
  //               position: 29,
  //             },
  //             {
  //               id: '1640965852275.0uzgh',
  //               columnName: 'consent_sms_updated_at',
  //               columnType: 'date',
  //               required: false,
  //               category: 'date',
  //               description: 'this is a description of the column',
  //               position: 30,
  //             },
  //             {
  //               id: '1640965852275.0ooky',
  //               columnName: 'linkedin',
  //               columnType: 'string',
  //               required: false,
  //               category: 'string',
  //               description: 'this is a description of the column',
  //               position: 31,
  //             },
  //             {
  //               id: '1640965852275.09dth',
  //               columnName: 'website',
  //               columnType: 'string',
  //               required: false,
  //               category: 'string',
  //               description: 'this is a description of the column',
  //               position: 32,
  //             },
  //             {
  //               id: '1640965852275.0k6i8',
  //               columnName: 'companyName',
  //               columnType: 'string',
  //               required: false,
  //               category: 'string',
  //               description: 'this is a description of the column',
  //               position: 33,
  //             },
  //             {
  //               id: '1640965852275.0gbl9',
  //               columnName: 'jobTitle',
  //               columnType: 'string',
  //               required: false,
  //               category: 'string',
  //               description: 'this is a description of the column',
  //               position: 34,
  //             },
  //             {
  //               id: '1640965852275.0r34h',
  //               columnName: 'createdAt',
  //               columnType: 'date',
  //               required: false,
  //               category: 'date',
  //               description: 'this is a description of the column',
  //               position: 35,
  //             },
  //             {
  //               id: '1640965852275.0k0q6',
  //               columnName: 'updatedAt',
  //               columnType: 'date',
  //               required: false,
  //               category: 'date',
  //               description: 'this is a description of the column',
  //               position: 36,
  //             },
  //           ],
  //         },
  //       },
  //       dataType: dataTypesEnum.UNION,
  //       sourceType: sourceTypesEnum.SOURCE,
  //       retrieveFrequency: retrieveFrequencyEnum.EVERY_HOUR,
  //       selectedColumns: [
  //         {
  //           id: '1640966984440.0qp8m',
  //           columnName: 'userId',
  //           columnType: 'string',
  //           required: false,
  //           description: '',
  //           category: 'no category',
  //           position: 0,
  //         },
  //         {
  //           id: '1640966984440.0uw45',
  //           columnName: 'firstName',
  //           columnType: 'string',
  //           required: false,
  //           description: '',
  //           category: 'no category',
  //           position: 1,
  //         },
  //         {
  //           id: '1640966984440.078rw',
  //           columnName: 'lastName',
  //           columnType: 'string',
  //           required: false,
  //           description: '',
  //           category: 'no category',
  //           position: 2,
  //         },
  //         {
  //           id: '1640966984440.08bym',
  //           columnName: 'fullName',
  //           columnType: 'string',
  //           required: false,
  //           description: '',
  //           category: 'no category',
  //           position: 3,
  //         },
  //         {
  //           id: '1640966984440.0pclz',
  //           columnName: 'birthdate',
  //           columnType: 'date',
  //           required: false,
  //           description: '',
  //           category: 'no category',
  //           position: 4,
  //         },
  //         {
  //           id: '1640966984440.0fp3w',
  //           columnName: 'language',
  //           columnType: 'string',
  //           required: false,
  //           description: '',
  //           category: 'no category',
  //           position: 5,
  //         },
  //         {
  //           id: '1640966984440.096ak',
  //           columnName: 'gender',
  //           columnType: 'string',
  //           required: false,
  //           description: '',
  //           category: 'no category',
  //           position: 6,
  //         },
  //         {
  //           id: '1640966984440.02qmz',
  //           columnName: 'civility',
  //           columnType: 'string',
  //           required: false,
  //           description: '',
  //           category: 'no category',
  //           position: 7,
  //         },
  //         {
  //           id: '1640966984440.0y0ow',
  //           columnName: 'address',
  //           columnType: 'string',
  //           required: false,
  //           description: '',
  //           category: 'no category',
  //           position: 8,
  //         },
  //         {
  //           id: '1640966984440.0irh0',
  //           columnName: 'postalCode',
  //           columnType: 'string',
  //           required: false,
  //           description: '',
  //           category: 'no category',
  //           position: 9,
  //         },
  //         {
  //           id: '1640966984440.0xn5c',
  //           columnName: 'city',
  //           columnType: 'string',
  //           required: false,
  //           description: '',
  //           category: 'no category',
  //           position: 10,
  //         },
  //         {
  //           id: '1640966984440.07wtx',
  //           columnName: 'region',
  //           columnType: 'string',
  //           required: false,
  //           description: '',
  //           category: 'no category',
  //           position: 11,
  //         },
  //         {
  //           id: '1640966984440.0i8pm',
  //           columnName: 'countryName',
  //           columnType: 'string',
  //           required: false,
  //           description: '',
  //           category: 'no category',
  //           position: 12,
  //         },
  //         {
  //           id: '1640966984440.0pfwp',
  //           columnName: 'countryCode',
  //           columnType: 'string',
  //           required: false,
  //           description: '',
  //           category: 'no category',
  //           position: 13,
  //         },
  //         {
  //           id: '1640966984440.0z5m7',
  //           columnName: 'email',
  //           columnType: 'string',
  //           required: false,
  //           description: '',
  //           category: 'no category',
  //           position: 14,
  //         },
  //         {
  //           id: '1640966984440.0v8uk',
  //           columnName: 'emailFormat',
  //           columnType: 'string',
  //           required: false,
  //           description: '',
  //           category: 'no category',
  //           position: 15,
  //         },
  //         {
  //           id: '1640966984440.0vikb',
  //           columnName: 'emailDomain',
  //           columnType: 'string',
  //           required: false,
  //           description: '',
  //           category: 'no category',
  //           position: 16,
  //         },
  //         {
  //           id: '1640966984440.0qneq',
  //           columnName: 'emailValidity',
  //           columnType: 'no type',
  //           required: false,
  //           description: '',
  //           category: 'no category',
  //           position: 17,
  //         },
  //         {
  //           id: '1640966984440.09tjz',
  //           columnName: 'emailValidityReason',
  //           columnType: 'no type',
  //           required: false,
  //           description: '',
  //           category: 'no category',
  //           position: 18,
  //         },
  //         {
  //           id: '1640966984440.08fv4',
  //           columnName: 'phoneMobile',
  //           columnType: 'string',
  //           required: false,
  //           description: '',
  //           category: 'no category',
  //           position: 19,
  //         },
  //         {
  //           id: '1640966984440.0thsn',
  //           columnName: 'phoneLandline',
  //           columnType: 'number',
  //           required: false,
  //           description: '',
  //           category: 'no category',
  //           position: 20,
  //         },
  //         {
  //           id: '1640966984440.0t9m8',
  //           columnName: 'phone',
  //           columnType: 'number',
  //           required: false,
  //           description: '',
  //           category: 'no category',
  //           position: 21,
  //         },
  //         {
  //           id: '1640966984440.0k1eo',
  //           columnName: 'phoneValidity',
  //           columnType: 'no type',
  //           required: false,
  //           description: '',
  //           category: 'no category',
  //           position: 22,
  //         },
  //         {
  //           id: '1640966984440.00fvl',
  //           columnName: 'phoneValidityReason',
  //           columnType: 'no type',
  //           required: false,
  //           description: '',
  //           category: 'no category',
  //           position: 23,
  //         },
  //         {
  //           id: '1640966984440.07w4f',
  //           columnName: 'phoneCountry',
  //           columnType: 'string',
  //           required: false,
  //           description: '',
  //           category: 'no category',
  //           position: 24,
  //         },
  //         {
  //           id: '1640966984440.0ihov',
  //           columnName: 'phoneNormalized',
  //           columnType: 'no type',
  //           required: false,
  //           description: '',
  //           category: 'no category',
  //           position: 25,
  //         },
  //         {
  //           id: '1640966984440.007on',
  //           columnName: 'phoneType',
  //           columnType: 'no type',
  //           required: false,
  //           description: '',
  //           category: 'no category',
  //           position: 26,
  //         },
  //         {
  //           id: '1640966984440.0b4q1',
  //           columnName: 'consentEmail',
  //           columnType: 'string',
  //           required: false,
  //           description: '',
  //           category: 'no category',
  //           position: 27,
  //         },
  //         {
  //           id: '1640966984440.0wqpn',
  //           columnName: 'consentEmailUpdatedAt',
  //           columnType: 'string',
  //           required: false,
  //           description: '',
  //           category: 'no category',
  //           position: 28,
  //         },
  //         {
  //           id: '1640966984440.0loai',
  //           columnName: 'consentSms',
  //           columnType: 'string',
  //           required: false,
  //           description: '',
  //           category: 'no category',
  //           position: 29,
  //         },
  //         {
  //           id: '1640966984440.0lbns',
  //           columnName: 'consentSmsUpdatedAt',
  //           columnType: 'string',
  //           required: false,
  //           description: '',
  //           category: 'no category',
  //           position: 30,
  //         },
  //         {
  //           id: '1640966984440.0tu6e',
  //           columnName: 'linkedin',
  //           columnType: 'no type',
  //           required: false,
  //           description: '',
  //           category: 'no category',
  //           position: 31,
  //         },
  //         {
  //           id: '1640966984440.0icw2',
  //           columnName: 'website',
  //           columnType: 'no type',
  //           required: false,
  //           description: '',
  //           category: 'no category',
  //           position: 32,
  //         },
  //         {
  //           id: '1640966984440.0gs98',
  //           columnName: 'companyName',
  //           columnType: 'string',
  //           required: false,
  //           description: '',
  //           category: 'no category',
  //           position: 33,
  //         },
  //         {
  //           id: '1640966984440.0k1sr',
  //           columnName: 'jobTitle',
  //           columnType: 'string',
  //           required: false,
  //           description: '',
  //           category: 'no category',
  //           position: 34,
  //         },
  //         {
  //           id: '1640966984440.0y7dj',
  //           columnName: 'createdAt',
  //           columnType: 'date',
  //           required: false,
  //           description: '',
  //           category: 'no category',
  //           position: 35,
  //         },
  //         {
  //           id: '1640966984440.0dsub',
  //           columnName: 'updatedAt',
  //           columnType: 'date',
  //           required: false,
  //           description: '',
  //           category: 'no category',
  //           position: 36,
  //         },
  //       ],
  //       joinByColumns: [
  //         {
  //           key: '1640965852275.05dr3',
  //         },
  //       ],
  //       deduplicateSettings: {
  //         keep: keepTypesEnum.RECENT,
  //         deduplicateRecord: 'false',
  //         deduplicateColumns: {
  //           key: '1640965852275.0c0mq',
  //           combinationOperator: 'or',
  //           columnGroups: [],
  //         },
  //       },
  //     },
  //     {
  //       key: '164096323465.0u73x',
  //       combinationLevel: 1,
  //       audienceSource: {
  //         key: '1640967054900.09qu1',
  //         connection: {
  //           key: 'connection7',
  //           name: 'Magento',
  //           usedAs: ['destination'],
  //           connector: {
  //             key: 'connector8',
  //             title: 'Zendesk',
  //             description: 'This is some description of the destination connector',
  //             category: 'api',
  //             logo: '/icons/logos/zendesk.png',
  //             expandedLogo: '/icons/Zendesk-expanded.png',
  //           },
  //           credentials: {
  //             url: 'customdomain.zendesk.com',
  //             apiKey: 'Kq5-SD$O5qsd"79qsdLSdf*/sd985654qs',
  //           },
  //         },
  //         pointer: 'Available integrations/All orders',
  //         object: {
  //           updatedAtId: '1640967062537.0wsz9',
  //           name: 'object_Available integrations/All orders',
  //           tableName: 'Available integrations/All orders',
  //           category: 'orders',
  //           schema: [
  //             {
  //               id: '1640967062537.0loj1',
  //               columnName: 'recordId',
  //               columnType: 'string',
  //               required: false,
  //               description: '',
  //               category: 'no category',
  //               position: 0,
  //             },
  //             {
  //               id: '1640967062537.0qzrr',
  //               columnName: 'contactId',
  //               columnType: 'string',
  //               required: false,
  //               description: '',
  //               category: 'no category',
  //               position: 1,
  //             },
  //             {
  //               id: '1640967062537.0b9kf',
  //               columnName: 'totalAmount',
  //               columnType: 'number',
  //               required: false,
  //               description: '',
  //               category: 'no category',
  //               position: 2,
  //             },
  //             {
  //               id: '1640967062537.0g3me',
  //               columnName: 'shippingAmount',
  //               columnType: 'number',
  //               required: false,
  //               description: '',
  //               category: 'no category',
  //               position: 3,
  //             },
  //             {
  //               id: '1640967062537.0z9y9',
  //               columnName: 'discountAmount',
  //               columnType: 'number',
  //               required: false,
  //               description: '',
  //               category: 'no category',
  //               position: 4,
  //             },
  //             {
  //               id: '1640967062537.0lh8p',
  //               columnName: 'taxAmount',
  //               columnType: 'number',
  //               required: false,
  //               description: '',
  //               category: 'no category',
  //               position: 5,
  //             },
  //             {
  //               id: '1640967062537.05710',
  //               columnName: 'currency',
  //               columnType: 'number',
  //               required: false,
  //               description: '',
  //               category: 'no category',
  //               position: 6,
  //             },
  //             {
  //               id: '1640967062537.0pgxj',
  //               columnName: 'completed',
  //               columnType: 'boolean',
  //               required: false,
  //               description: '',
  //               category: 'no category',
  //               position: 7,
  //             },
  //             {
  //               id: '1640967062537.0olk3',
  //               columnName: 'storeId',
  //               columnType: 'string',
  //               required: false,
  //               description: '',
  //               category: 'no category',
  //               position: 8,
  //             },
  //             {
  //               id: '1640967062537.0obyb',
  //               columnName: 'salesPerson',
  //               columnType: 'string',
  //               required: false,
  //               description: '',
  //               category: 'no category',
  //               position: 9,
  //             },
  //             {
  //               id: '1640967062537.0b5fi',
  //               columnName: 'createdAt',
  //               columnType: 'date',
  //               required: false,
  //               description: '',
  //               category: 'no category',
  //               position: 10,
  //             },
  //             {
  //               id: '1640967062537.0wsz9',
  //               columnName: 'updatedAt',
  //               columnType: 'date',
  //               required: false,
  //               description: '',
  //               category: 'no category',
  //               position: 11,
  //             },
  //           ],
  //           primaryKeys: ['1640967062537.0loj1'],
  //           recordsNumber: 46,
  //           pointer: 'Available integrations/All orders',
  //           records: [
  //             {
  //               recordId: '1640967062535.0rnhb',
  //               contactId: '1640967062535.0mvlf',
  //               totalAmount: 416,
  //               shippingAmount: 41,
  //               discountAmount: 1,
  //               taxAmount: 9,
  //               currency: 'SEK',
  //               completed: false,
  //               storeId: '1640967062535.092f2',
  //               salesPerson: 'Cristina Turcotte',
  //               createdAt: '2021-05-05T12:51:16.961Z',
  //               updatedAt: '2021-05-04T07:33:13.013Z',
  //             },
  //             {
  //               recordId: '1640967062536.0jopz',
  //               contactId: '1640967062536.08epp',
  //               totalAmount: 173,
  //               shippingAmount: 26,
  //               discountAmount: 9,
  //               taxAmount: 1,
  //               currency: 'STN',
  //               completed: true,
  //               storeId: '1640967062536.0wayy',
  //               salesPerson: 'Phil Thiel',
  //               createdAt: '2021-08-14T21:28:58.761Z',
  //               updatedAt: '2021-07-14T00:12:13.652Z',
  //             },
  //             {
  //               recordId: '1640967062536.0pcg0',
  //               contactId: '1640967062536.012fq',
  //               totalAmount: 748,
  //               shippingAmount: 32,
  //               discountAmount: 7,
  //               taxAmount: 5,
  //               currency: 'SCR',
  //               completed: false,
  //               storeId: '1640967062536.03dd2',
  //               salesPerson: 'Jean Schamberger',
  //               createdAt: '2021-07-25T04:08:02.361Z',
  //               updatedAt: '2021-09-23T04:30:46.205Z',
  //             },
  //             {
  //               recordId: '1640967062536.0lgcj',
  //               contactId: '1640967062536.0d2lx',
  //               totalAmount: 155,
  //               shippingAmount: 28,
  //               discountAmount: 6,
  //               taxAmount: 7,
  //               currency: 'KPW',
  //               completed: false,
  //               storeId: '1640967062536.02err',
  //               salesPerson: 'Brent Brekke',
  //               createdAt: '2021-07-21T08:46:45.880Z',
  //               updatedAt: '2021-10-28T22:31:06.642Z',
  //             },
  //             {
  //               recordId: '1640967062536.03y4n',
  //               contactId: '1640967062536.0xl2p',
  //               totalAmount: 429,
  //               shippingAmount: 30,
  //               discountAmount: 1,
  //               taxAmount: 11,
  //               currency: 'FJD',
  //               completed: true,
  //               storeId: '1640967062536.01hge',
  //               salesPerson: 'Rolando Torp',
  //               createdAt: '2021-02-09T08:39:21.309Z',
  //               updatedAt: '2021-05-28T08:16:54.007Z',
  //             },
  //             {
  //               recordId: '1640967062536.03yyh',
  //               contactId: '1640967062536.0tzvh',
  //               totalAmount: 171,
  //               shippingAmount: 10,
  //               discountAmount: 7,
  //               taxAmount: 5,
  //               currency: 'HUF',
  //               completed: false,
  //               storeId: '1640967062536.078mo',
  //               salesPerson: 'Kristina Lindgren',
  //               createdAt: '2021-08-23T18:38:06.087Z',
  //               updatedAt: '2021-07-29T11:47:06.338Z',
  //             },
  //             {
  //               recordId: '1640967062536.08k35',
  //               contactId: '1640967062536.0yz0k',
  //               totalAmount: 767,
  //               shippingAmount: 23,
  //               discountAmount: 0,
  //               taxAmount: 16,
  //               currency: 'UAH',
  //               completed: false,
  //               storeId: '1640967062536.0w25o',
  //               salesPerson: 'Daniel Bernier',
  //               createdAt: '2021-06-22T02:16:30.158Z',
  //               updatedAt: '2021-02-09T02:36:03.622Z',
  //             },
  //             {
  //               recordId: '1640967062536.0cacc',
  //               contactId: '1640967062536.07659',
  //               totalAmount: 294,
  //               shippingAmount: 22,
  //               discountAmount: 2,
  //               taxAmount: 18,
  //               currency: 'INR',
  //               completed: false,
  //               storeId: '1640967062536.0lc2h',
  //               salesPerson: 'Pauline Barrows',
  //               createdAt: '2021-03-11T14:18:19.872Z',
  //               updatedAt: '2021-12-16T00:19:22.264Z',
  //             },
  //             {
  //               recordId: '1640967062536.0a1t5',
  //               contactId: '1640967062536.0uvp3',
  //               totalAmount: 308,
  //               shippingAmount: 36,
  //               discountAmount: 4,
  //               taxAmount: 16,
  //               currency: 'XAF',
  //               completed: true,
  //               storeId: '1640967062536.08oyd',
  //               salesPerson: 'Mr. Salvatore Skiles',
  //               createdAt: '2021-10-04T23:32:02.022Z',
  //               updatedAt: '2021-10-29T14:56:31.965Z',
  //             },
  //             {
  //               recordId: '1640967062536.0dxhp',
  //               contactId: '1640967062536.0yw0h',
  //               totalAmount: 323,
  //               shippingAmount: 14,
  //               discountAmount: 9,
  //               taxAmount: 6,
  //               currency: 'IDR',
  //               completed: false,
  //               storeId: '1640967062536.0i6g9',
  //               salesPerson: 'Diane Bogisich',
  //               createdAt: '2021-11-21T09:53:04.377Z',
  //               updatedAt: '2021-10-06T15:25:52.087Z',
  //             },
  //             {
  //               recordId: '1640967062536.0k0ei',
  //               contactId: '1640967062536.0mii1',
  //               totalAmount: 881,
  //               shippingAmount: 17,
  //               discountAmount: 7,
  //               taxAmount: 15,
  //               currency: 'RSD',
  //               completed: false,
  //               storeId: '1640967062536.0ssoe',
  //               salesPerson: 'Ebony Koelpin',
  //               createdAt: '2021-12-15T00:26:56.202Z',
  //               updatedAt: '2021-11-14T09:05:59.702Z',
  //             },
  //             {
  //               recordId: '1640967062536.0ftvt',
  //               contactId: '1640967062536.0kff7',
  //               totalAmount: 835,
  //               shippingAmount: 3,
  //               discountAmount: 6,
  //               taxAmount: 9,
  //               currency: 'KZT',
  //               completed: true,
  //               storeId: '1640967062536.0dk8l',
  //               salesPerson: 'Geneva Glover',
  //               createdAt: '2021-09-20T04:40:28.419Z',
  //               updatedAt: '2021-11-05T12:24:20.257Z',
  //             },
  //             {
  //               recordId: '1640967062536.0imi2',
  //               contactId: '1640967062536.0y6z1',
  //               totalAmount: 349,
  //               shippingAmount: 18,
  //               discountAmount: 1,
  //               taxAmount: 1,
  //               currency: 'KYD',
  //               completed: false,
  //               storeId: '1640967062536.0he87',
  //               salesPerson: 'Stella Mante',
  //               createdAt: '2021-04-17T20:48:25.421Z',
  //               updatedAt: '2021-12-24T12:07:15.436Z',
  //             },
  //             {
  //               recordId: '1640967062536.0clw6',
  //               contactId: '1640967062536.0ag2g',
  //               totalAmount: 123,
  //               shippingAmount: 47,
  //               discountAmount: 5,
  //               taxAmount: 5,
  //               currency: 'ISK',
  //               completed: true,
  //               storeId: '1640967062536.0zt67',
  //               salesPerson: 'Willis Spencer',
  //               createdAt: '2021-11-14T05:38:37.221Z',
  //               updatedAt: '2021-01-13T03:52:04.465Z',
  //             },
  //             {
  //               recordId: '1640967062536.0vyit',
  //               contactId: '1640967062536.014w3',
  //               totalAmount: 774,
  //               shippingAmount: 37,
  //               discountAmount: 9,
  //               taxAmount: 10,
  //               currency: 'AED',
  //               completed: false,
  //               storeId: '1640967062536.0tkxi',
  //               salesPerson: 'Lee Tromp',
  //               createdAt: '2021-07-20T06:27:34.166Z',
  //               updatedAt: '2021-01-29T18:17:37.499Z',
  //             },
  //             {
  //               recordId: '1640967062536.08nhb',
  //               contactId: '1640967062536.0xxem',
  //               totalAmount: 320,
  //               shippingAmount: 21,
  //               discountAmount: 3,
  //               taxAmount: 15,
  //               currency: 'MZN',
  //               completed: true,
  //               storeId: '1640967062536.0c1ax',
  //               salesPerson: 'Terrence Bauch',
  //               createdAt: '2021-06-23T16:55:21.503Z',
  //               updatedAt: '2021-03-13T09:51:17.075Z',
  //             },
  //             {
  //               recordId: '1640967062536.0074c',
  //               contactId: '1640967062536.0utdb',
  //               totalAmount: 323,
  //               shippingAmount: 6,
  //               discountAmount: 7,
  //               taxAmount: 2,
  //               currency: 'XTS',
  //               completed: true,
  //               storeId: '1640967062536.0het6',
  //               salesPerson: 'Vicky Frami',
  //               createdAt: '2021-05-09T10:46:10.637Z',
  //               updatedAt: '2021-05-21T11:10:36.704Z',
  //             },
  //             {
  //               recordId: '1640967062536.0z0ft',
  //               contactId: '1640967062536.0m1xq',
  //               totalAmount: 959,
  //               shippingAmount: 36,
  //               discountAmount: 9,
  //               taxAmount: 19,
  //               currency: 'XBC',
  //               completed: true,
  //               storeId: '1640967062536.0k9sm',
  //               salesPerson: 'Herman Cummings',
  //               createdAt: '2021-10-30T17:00:17.585Z',
  //               updatedAt: '2021-02-05T13:49:28.441Z',
  //             },
  //             {
  //               recordId: '1640967062536.0faug',
  //               contactId: '1640967062536.0gkug',
  //               totalAmount: 510,
  //               shippingAmount: 37,
  //               discountAmount: 8,
  //               taxAmount: 7,
  //               currency: 'NZD',
  //               completed: true,
  //               storeId: '1640967062536.0lu1e',
  //               salesPerson: 'Kathy Bechtelar',
  //               createdAt: '2021-03-20T20:35:28.349Z',
  //               updatedAt: '2021-08-06T11:44:24.806Z',
  //             },
  //             {
  //               recordId: '1640967062536.0r75x',
  //               contactId: '1640967062536.05f1h',
  //               totalAmount: 299,
  //               shippingAmount: 44,
  //               discountAmount: 7,
  //               taxAmount: 19,
  //               currency: 'XAU',
  //               completed: false,
  //               storeId: '1640967062536.0sn50',
  //               salesPerson: 'Sylvia Raynor',
  //               createdAt: '2021-09-17T05:18:48.422Z',
  //               updatedAt: '2021-09-17T08:34:28.640Z',
  //             },
  //             {
  //               recordId: '1640967062536.0u2lf',
  //               contactId: '1640967062536.0j6cx',
  //               totalAmount: 8,
  //               shippingAmount: 12,
  //               discountAmount: 9,
  //               taxAmount: 1,
  //               currency: 'XDR',
  //               completed: false,
  //               storeId: '1640967062536.0c2od',
  //               salesPerson: 'Alberta Jerde',
  //               createdAt: '2021-02-26T22:50:06.064Z',
  //               updatedAt: '2021-07-31T05:49:27.553Z',
  //             },
  //             {
  //               recordId: '1640967062536.0b0pd',
  //               contactId: '1640967062536.06j5y',
  //               totalAmount: 540,
  //               shippingAmount: 19,
  //               discountAmount: 4,
  //               taxAmount: 5,
  //               currency: 'DOP',
  //               completed: false,
  //               storeId: '1640967062536.02skc',
  //               salesPerson: 'Blanca Gaylord DVM',
  //               createdAt: '2021-08-10T11:07:58.362Z',
  //               updatedAt: '2021-02-01T16:57:32.498Z',
  //             },
  //             {
  //               recordId: '1640967062536.0ed2f',
  //               contactId: '1640967062536.0j9dt',
  //               totalAmount: 724,
  //               shippingAmount: 34,
  //               discountAmount: 6,
  //               taxAmount: 8,
  //               currency: 'XBA',
  //               completed: true,
  //               storeId: '1640967062536.0th25',
  //               salesPerson: 'Terry Denesik',
  //               createdAt: '2021-12-31T12:33:07.118Z',
  //               updatedAt: '2021-06-22T05:37:37.208Z',
  //             },
  //             {
  //               recordId: '1640967062536.0oy9g',
  //               contactId: '1640967062536.094oe',
  //               totalAmount: 985,
  //               shippingAmount: 44,
  //               discountAmount: 9,
  //               taxAmount: 6,
  //               currency: 'SDG',
  //               completed: true,
  //               storeId: '1640967062536.0jq4a',
  //               salesPerson: 'Barbara Hettinger',
  //               createdAt: '2021-02-17T05:30:23.789Z',
  //               updatedAt: '2021-09-17T00:52:12.691Z',
  //             },
  //             {
  //               recordId: '1640967062536.0k3er',
  //               contactId: '1640967062536.0dpb6',
  //               totalAmount: 983,
  //               shippingAmount: 8,
  //               discountAmount: 9,
  //               taxAmount: 11,
  //               currency: 'MYR',
  //               completed: true,
  //               storeId: '1640967062536.08eqi',
  //               salesPerson: 'Ray Zulauf',
  //               createdAt: '2021-06-25T20:24:54.520Z',
  //               updatedAt: '2021-08-04T19:37:15.388Z',
  //             },
  //             {
  //               recordId: '1640967062536.0ikvz',
  //               contactId: '1640967062536.0u87b',
  //               totalAmount: 349,
  //               shippingAmount: 32,
  //               discountAmount: 8,
  //               taxAmount: 1,
  //               currency: 'XAG',
  //               completed: true,
  //               storeId: '1640967062536.0l9y8',
  //               salesPerson: 'Rene Bergnaum',
  //               createdAt: '2021-06-01T05:55:09.287Z',
  //               updatedAt: '2021-03-04T18:06:54.416Z',
  //             },
  //             {
  //               recordId: '1640967062536.08uz7',
  //               contactId: '1640967062536.01n55',
  //               totalAmount: 978,
  //               shippingAmount: 23,
  //               discountAmount: 4,
  //               taxAmount: 10,
  //               currency: 'NZD',
  //               completed: false,
  //               storeId: '1640967062536.0ncpz',
  //               salesPerson: 'Mr. Ramon Walter',
  //               createdAt: '2021-03-15T16:31:14.999Z',
  //               updatedAt: '2021-10-17T21:50:30.485Z',
  //             },
  //             {
  //               recordId: '1640967062536.0ltmj',
  //               contactId: '1640967062536.0i9em',
  //               totalAmount: 804,
  //               shippingAmount: 7,
  //               discountAmount: 8,
  //               taxAmount: 14,
  //               currency: 'VEF',
  //               completed: true,
  //               storeId: '1640967062536.0oqu0',
  //               salesPerson: "Dr. Ruth O'Kon",
  //               createdAt: '2021-08-24T21:27:54.891Z',
  //               updatedAt: '2021-09-07T05:34:58.245Z',
  //             },
  //             {
  //               recordId: '1640967062536.070kd',
  //               contactId: '1640967062536.0ub7x',
  //               totalAmount: 792,
  //               shippingAmount: 40,
  //               discountAmount: 3,
  //               taxAmount: 7,
  //               currency: 'SBD',
  //               completed: true,
  //               storeId: '1640967062536.0g3zd',
  //               salesPerson: 'Charlotte Simonis',
  //               createdAt: '2021-01-28T05:17:27.882Z',
  //               updatedAt: '2021-05-14T00:59:15.325Z',
  //             },
  //             {
  //               recordId: '1640967062536.0cbve',
  //               contactId: '1640967062536.0olb1',
  //               totalAmount: 106,
  //               shippingAmount: 8,
  //               discountAmount: 2,
  //               taxAmount: 5,
  //               currency: 'AZN',
  //               completed: false,
  //               storeId: '1640967062536.0v2v3',
  //               salesPerson: 'Antoinette Paucek',
  //               createdAt: '2021-09-20T19:56:58.244Z',
  //               updatedAt: '2021-06-09T21:44:38.739Z',
  //             },
  //             {
  //               recordId: '1640967062536.0pmvx',
  //               contactId: '1640967062536.033ef',
  //               totalAmount: 320,
  //               shippingAmount: 18,
  //               discountAmount: 1,
  //               taxAmount: 11,
  //               currency: 'BGN',
  //               completed: false,
  //               storeId: '1640967062536.02u7h',
  //               salesPerson: 'Ken Zieme IV',
  //               createdAt: '2021-01-27T02:27:08.466Z',
  //               updatedAt: '2021-01-21T03:58:42.589Z',
  //             },
  //             {
  //               recordId: '1640967062536.0xyf0',
  //               contactId: '1640967062536.04dek',
  //               totalAmount: 705,
  //               shippingAmount: 10,
  //               discountAmount: 6,
  //               taxAmount: 14,
  //               currency: 'MUR',
  //               completed: false,
  //               storeId: '1640967062536.0h6zz',
  //               salesPerson: 'Andrew Heidenreich',
  //               createdAt: '2021-06-23T02:18:42.536Z',
  //               updatedAt: '2021-01-09T17:34:35.956Z',
  //             },
  //             {
  //               recordId: '1640967062536.02jvg',
  //               contactId: '1640967062536.0h0oa',
  //               totalAmount: 481,
  //               shippingAmount: 34,
  //               discountAmount: 5,
  //               taxAmount: 12,
  //               currency: 'GEL',
  //               completed: false,
  //               storeId: '1640967062536.0bbmy',
  //               salesPerson: 'Patti Volkman',
  //               createdAt: '2021-04-18T19:02:12.053Z',
  //               updatedAt: '2021-12-06T21:35:33.681Z',
  //             },
  //             {
  //               recordId: '1640967062536.0ck52',
  //               contactId: '1640967062536.0ga82',
  //               totalAmount: 426,
  //               shippingAmount: 50,
  //               discountAmount: 4,
  //               taxAmount: 9,
  //               currency: 'XBC',
  //               completed: false,
  //               storeId: '1640967062536.0zx2a',
  //               salesPerson: 'Mr. Tom Grady',
  //               createdAt: '2021-09-05T23:47:14.126Z',
  //               updatedAt: '2021-07-06T19:03:55.379Z',
  //             },
  //             {
  //               recordId: '1640967062536.0fkdx',
  //               contactId: '1640967062536.0g11q',
  //               totalAmount: 265,
  //               shippingAmount: 23,
  //               discountAmount: 5,
  //               taxAmount: 9,
  //               currency: 'HKD',
  //               completed: true,
  //               storeId: '1640967062536.09li6',
  //               salesPerson: 'Lance Wyman',
  //               createdAt: '2021-03-30T12:37:00.800Z',
  //               updatedAt: '2021-03-30T20:44:32.141Z',
  //             },
  //             {
  //               recordId: '1640967062536.0kazh',
  //               contactId: '1640967062536.0yjsr',
  //               totalAmount: 851,
  //               shippingAmount: 25,
  //               discountAmount: 6,
  //               taxAmount: 1,
  //               currency: 'AFN',
  //               completed: false,
  //               storeId: '1640967062536.0idnm',
  //               salesPerson: 'Silvia Weber',
  //               createdAt: '2021-08-12T09:03:04.955Z',
  //               updatedAt: '2021-04-06T00:37:12.824Z',
  //             },
  //             {
  //               recordId: '1640967062536.0pauh',
  //               contactId: '1640967062536.0qhm0',
  //               totalAmount: 951,
  //               shippingAmount: 27,
  //               discountAmount: 3,
  //               taxAmount: 6,
  //               currency: 'KYD',
  //               completed: false,
  //               storeId: '1640967062536.0f9us',
  //               salesPerson: 'Lucas Heathcote',
  //               createdAt: '2021-08-30T15:13:44.743Z',
  //               updatedAt: '2021-09-03T08:34:08.506Z',
  //             },
  //             {
  //               recordId: '1640967062536.0sai1',
  //               contactId: '1640967062536.00pvt',
  //               totalAmount: 618,
  //               shippingAmount: 34,
  //               discountAmount: 4,
  //               taxAmount: 9,
  //               currency: 'ALL',
  //               completed: false,
  //               storeId: '1640967062536.0ql1l',
  //               salesPerson: 'Melissa Grady',
  //               createdAt: '2021-08-15T01:23:23.873Z',
  //               updatedAt: '2021-02-22T18:37:27.267Z',
  //             },
  //             {
  //               recordId: '1640967062536.09ikr',
  //               contactId: '1640967062536.0w5n3',
  //               totalAmount: 438,
  //               shippingAmount: 17,
  //               discountAmount: 9,
  //               taxAmount: 1,
  //               currency: 'MZN',
  //               completed: false,
  //               storeId: '1640967062536.04rwy',
  //               salesPerson: 'Fernando Kovacek',
  //               createdAt: '2021-07-29T14:17:31.305Z',
  //               updatedAt: '2021-11-07T17:55:15.736Z',
  //             },
  //             {
  //               recordId: '1640967062536.02mn8',
  //               contactId: '1640967062536.0ez72',
  //               totalAmount: 406,
  //               shippingAmount: 48,
  //               discountAmount: 4,
  //               taxAmount: 15,
  //               currency: 'KGS',
  //               completed: true,
  //               storeId: '1640967062536.0k0uj',
  //               salesPerson: 'Krystal Hintz',
  //               createdAt: '2021-04-05T05:49:30.020Z',
  //               updatedAt: '2021-07-18T22:42:11.158Z',
  //             },
  //             {
  //               recordId: '1640967062536.08ktn',
  //               contactId: '1640967062536.0reb7',
  //               totalAmount: 318,
  //               shippingAmount: 45,
  //               discountAmount: 0,
  //               taxAmount: 19,
  //               currency: 'SCR',
  //               completed: false,
  //               storeId: '1640967062536.0pgmv',
  //               salesPerson: 'Agnes Collier',
  //               createdAt: '2021-07-24T20:29:26.489Z',
  //               updatedAt: '2021-05-04T14:40:17.828Z',
  //             },
  //             {
  //               recordId: '1640967062536.0o0xe',
  //               contactId: '1640967062536.0mg0t',
  //               totalAmount: 631,
  //               shippingAmount: 28,
  //               discountAmount: 1,
  //               taxAmount: 15,
  //               currency: 'JPY',
  //               completed: false,
  //               storeId: '1640967062536.01gt7',
  //               salesPerson: 'Laverne Kuhic',
  //               createdAt: '2021-10-14T15:26:30.081Z',
  //               updatedAt: '2021-10-20T19:55:25.097Z',
  //             },
  //             {
  //               recordId: '1640967062536.0c1e8',
  //               contactId: '1640967062536.0jkvp',
  //               totalAmount: 505,
  //               shippingAmount: 40,
  //               discountAmount: 1,
  //               taxAmount: 15,
  //               currency: 'LTL',
  //               completed: true,
  //               storeId: '1640967062536.0be9t',
  //               salesPerson: 'Rachael Fadel Sr.',
  //               createdAt: '2021-10-25T11:23:58.917Z',
  //               updatedAt: '2021-04-25T11:45:20.449Z',
  //             },
  //             {
  //               recordId: '1640967062536.0l2s2',
  //               contactId: '1640967062536.0adyx',
  //               totalAmount: 848,
  //               shippingAmount: 35,
  //               discountAmount: 8,
  //               taxAmount: 5,
  //               currency: 'XPD',
  //               completed: true,
  //               storeId: '1640967062536.0mssg',
  //               salesPerson: 'Brandon Bins',
  //               createdAt: '2021-06-19T02:56:39.763Z',
  //               updatedAt: '2021-09-01T22:39:34.037Z',
  //             },
  //             {
  //               recordId: '1640967062536.0hfw3',
  //               contactId: '1640967062536.0b1f6',
  //               totalAmount: 397,
  //               shippingAmount: 23,
  //               discountAmount: 7,
  //               taxAmount: 15,
  //               currency: 'KZT',
  //               completed: true,
  //               storeId: '1640967062536.01dht',
  //               salesPerson: 'Elisa Reichel',
  //               createdAt: '2021-09-22T20:00:09.619Z',
  //               updatedAt: '2021-12-17T02:59:55.464Z',
  //             },
  //             {
  //               recordId: '1640967062536.00xgb',
  //               contactId: '1640967062536.0sb7a',
  //               totalAmount: 634,
  //               shippingAmount: 31,
  //               discountAmount: 7,
  //               taxAmount: 14,
  //               currency: 'GHS',
  //               completed: false,
  //               storeId: '1640967062536.0irvt',
  //               salesPerson: 'Elisa McCullough V',
  //               createdAt: '2021-04-30T01:41:42.211Z',
  //               updatedAt: '2021-11-21T16:26:16.480Z',
  //             },
  //           ],
  //           key: '1640967062537.0lfyy',
  //           created: new Date(),
  //         },
  //         name: 'MY ORDERS',
  //         category: {
  //           key: '2',
  //           name: 'orders',
  //           columns: [
  //             {
  //               id: 'col1',
  //               columnName: 'recordId',
  //               columnType: 'string',
  //               required: true,
  //               category: 'string',
  //               description: 'Mandatory',
  //               position: 1,
  //             },
  //             {
  //               id: '1640965852275.0m4ry',
  //               columnName: 'contactId',
  //               columnType: 'string',
  //               required: true,
  //               category: 'string',
  //               description: 'Mandatory, link to contact',
  //               position: 1,
  //             },
  //             {
  //               id: '1640965852275.0c02h',
  //               columnName: 'totalAmount',
  //               columnType: 'number',
  //               required: true,
  //               category: 'number',
  //               description: 'Mandatory, instead of price',
  //               position: 2,
  //             },
  //             {
  //               id: '1640965852275.0337u',
  //               columnName: 'shippingAmount',
  //               columnType: 'number',
  //               required: false,
  //               category: 'number',
  //               description: 'this is a description of the column',
  //               position: 3,
  //             },
  //             {
  //               id: '1640965852275.0m7gb',
  //               columnName: 'discountAmount',
  //               columnType: 'number',
  //               required: false,
  //               category: 'number',
  //               description: 'this is a description of the column',
  //               position: 4,
  //             },
  //             {
  //               id: '1634805510991.0mnt3',
  //               columnName: 'taxAmount',
  //               columnType: 'number',
  //               required: false,
  //               category: 'number',
  //               description: 'this is a description of the column',
  //               position: 5,
  //             },
  //             {
  //               id: '1640965852275.0rz9j',
  //               columnName: 'currency',
  //               columnType: 'string',
  //               required: false,
  //               category: 'string',
  //               description: 'this is a description of the column',
  //               position: 6,
  //             },
  //             {
  //               id: '1640965852275.01oaz',
  //               columnName: 'completed',
  //               columnType: 'boolean',
  //               required: true,
  //               category: 'boolean',
  //               description: 'Mandatory',
  //               position: 7,
  //             },
  //             {
  //               id: '1640965852275.0jd3g',
  //               columnName: 'storeId',
  //               columnType: 'string',
  //               required: false,
  //               category: 'string',
  //               description: 'Link to store',
  //               position: 8,
  //             },
  //             {
  //               id: '1640965852275.0a12u',
  //               columnName: 'salesPerson',
  //               columnType: 'string',
  //               required: false,
  //               category: 'string',
  //               description: 'this is a description of the column',
  //               position: 9,
  //             },
  //             {
  //               id: '1640965852275.09575',
  //               columnName: 'createdAt',
  //               columnType: 'date',
  //               required: true,
  //               category: 'date',
  //               description: 'Mandatory',
  //               position: 10,
  //             },
  //           ],
  //         },
  //       },
  //       dataType: dataTypesEnum.JOIN,
  //       sourceType: sourceTypesEnum.SOURCE,
  //       retrieveFrequency: retrieveFrequencyEnum.EVERY_HOUR,
  //       selectedColumns: [
  //         {
  //           id: '1640967062537.0loj1',
  //           columnName: 'recordId',
  //           columnType: 'string',
  //           required: false,
  //           description: '',
  //           category: 'no category',
  //           position: 0,
  //         },
  //         {
  //           id: '1640967062537.0qzrr',
  //           columnName: 'contactId',
  //           columnType: 'string',
  //           required: false,
  //           description: '',
  //           category: 'no category',
  //           position: 1,
  //         },
  //         {
  //           id: '1640967062537.0b9kf',
  //           columnName: 'totalAmount',
  //           columnType: 'number',
  //           required: false,
  //           description: '',
  //           category: 'no category',
  //           position: 2,
  //         },
  //         {
  //           id: '1640967062537.0g3me',
  //           columnName: 'shippingAmount',
  //           columnType: 'number',
  //           required: false,
  //           description: '',
  //           category: 'no category',
  //           position: 3,
  //         },
  //         {
  //           id: '1640967062537.0z9y9',
  //           columnName: 'discountAmount',
  //           columnType: 'number',
  //           required: false,
  //           description: '',
  //           category: 'no category',
  //           position: 4,
  //         },
  //         {
  //           id: '1640967062537.0lh8p',
  //           columnName: 'taxAmount',
  //           columnType: 'number',
  //           required: false,
  //           description: '',
  //           category: 'no category',
  //           position: 5,
  //         },
  //         {
  //           id: '1640967062537.05710',
  //           columnName: 'currency',
  //           columnType: 'number',
  //           required: false,
  //           description: '',
  //           category: 'no category',
  //           position: 6,
  //         },
  //         {
  //           id: '1640967062537.0pgxj',
  //           columnName: 'completed',
  //           columnType: 'boolean',
  //           required: false,
  //           description: '',
  //           category: 'no category',
  //           position: 7,
  //         },
  //         {
  //           id: '1640967062537.0olk3',
  //           columnName: 'storeId',
  //           columnType: 'string',
  //           required: false,
  //           description: '',
  //           category: 'no category',
  //           position: 8,
  //         },
  //         {
  //           id: '1640967062537.0obyb',
  //           columnName: 'salesPerson',
  //           columnType: 'string',
  //           required: false,
  //           description: '',
  //           category: 'no category',
  //           position: 9,
  //         },
  //         {
  //           id: '1640967062537.0b5fi',
  //           columnName: 'createdAt',
  //           columnType: 'date',
  //           required: false,
  //           description: '',
  //           category: 'no category',
  //           position: 10,
  //         },
  //         {
  //           id: '1640967062537.0wsz9',
  //           columnName: 'updatedAt',
  //           columnType: 'date',
  //           required: false,
  //           description: '',
  //           category: 'no category',
  //           position: 11,
  //         },
  //       ],
  //       joinByColumns: [
  //         {
  //           key: '1640965852275.05dr3',
  //           audienceColumnId: '1640967003363.0aw0z',
  //           sourceColumnId: '1640967062537.0qzrr',
  //         },
  //       ],
  //       deduplicateSettings: {
  //         keep: keepTypesEnum.RECENT,
  //         deduplicateRecord: 'false',
  //         deduplicateColumns: {
  //           key: '1640965852275.0c0mq',
  //           combinationOperator: 'or',
  //           columnGroups: [],
  //         },
  //       },
  //     },
  //   ],
  //   mappingRows: [
  //     {
  //       key: '1640967003363.0aw0z',
  //       mappedColumns: {
  //         '1640966967674.0u73x': {
  //           columnId: '1640966984440.0qp8m',
  //           columnName: 'userId',
  //           columnType: 'string',
  //           primaryKey: true,
  //           updatedAtKey: false,
  //           columnDisplay: columnDisplayEnum.DISABLED,
  //         },
  //         '164096323465.0u73x': {
  //           columnId: '1640967062537.0qzrr',
  //           columnName: 'contactId',
  //           columnType: 'string',
  //           columnDisplay: columnDisplayEnum.DISABLED,
  //         },
  //       },
  //       audienceColumnName: 'user_id',
  //       audienceColumnType: 'string',
  //       primaryKey: true,
  //       updatedAtKey: false,
  //     },
  //     {
  //       key: '1640967003363.01jnt',
  //       mappedColumns: {
  //         '1640966967674.0u73x': {
  //           columnId: '1640966984440.0uw45',
  //           columnName: 'firstName',
  //           columnType: 'string',
  //           primaryKey: false,
  //           updatedAtKey: false,
  //           columnDisplay: columnDisplayEnum.DISABLED,
  //         },
  //         '164096323465.0u73x': {
  //           columnId: '',
  //           columnName: '',
  //           columnType: '',
  //           columnDisplay: columnDisplayEnum.LONG_LINE,
  //         },
  //       },
  //       audienceColumnName: 'first_name',
  //       audienceColumnType: 'string',
  //       canDelete: true,
  //       primaryKey: false,
  //       updatedAtKey: false,
  //       businessColumn: {
  //         id: '1640965852275.0odo9',
  //         columnName: 'firstName',
  //         columnType: 'string',
  //         required: true,
  //         category: 'first_name',
  //         description: 'this is a description of the column',
  //         position: 1,
  //       },
  //     },
  //     {
  //       key: '1640967003363.0n047',
  //       mappedColumns: {
  //         '1640966967674.0u73x': {
  //           columnId: '1640966984440.078rw',
  //           columnName: 'lastName',
  //           columnType: 'string',
  //           primaryKey: false,
  //           updatedAtKey: false,
  //           columnDisplay: columnDisplayEnum.DISABLED,
  //         },
  //         '164096323465.0u73x': {
  //           columnId: '',
  //           columnName: '',
  //           columnType: '',
  //           columnDisplay: columnDisplayEnum.LONG_LINE,
  //         },
  //       },
  //       audienceColumnName: 'last_name',
  //       audienceColumnType: 'string',
  //       canDelete: true,
  //       primaryKey: false,
  //       updatedAtKey: false,
  //       businessColumn: {
  //         id: '1640965852275.058ic',
  //         columnName: 'lastName',
  //         columnType: 'string',
  //         required: false,
  //         category: 'string',
  //         description: 'this is a description of the column',
  //         position: 2,
  //       },
  //     },
  //     {
  //       key: '1640967003363.0k49k',
  //       mappedColumns: {
  //         '1640966967674.0u73x': {
  //           columnId: '1640966984440.08bym',
  //           columnName: 'fullName',
  //           columnType: 'string',
  //           primaryKey: false,
  //           updatedAtKey: false,
  //           columnDisplay: columnDisplayEnum.DISABLED,
  //         },
  //         '164096323465.0u73x': {
  //           columnId: '',
  //           columnName: '',
  //           columnType: '',
  //           columnDisplay: columnDisplayEnum.LONG_LINE,
  //         },
  //       },
  //       audienceColumnName: 'full_name',
  //       audienceColumnType: 'string',
  //       canDelete: true,
  //       primaryKey: false,
  //       updatedAtKey: false,
  //       businessColumn: {
  //         id: '1640965852275.0465e',
  //         columnName: 'fullName',
  //         columnType: 'string',
  //         required: false,
  //         category: 'string',
  //         description: 'this is a description of the column',
  //         position: 3,
  //       },
  //     },
  //     {
  //       key: '1640967003363.0hy0x',
  //       mappedColumns: {
  //         '1640966967674.0u73x': {
  //           columnId: '1640966984440.0pclz',
  //           columnName: 'birthdate',
  //           columnType: 'date',
  //           primaryKey: false,
  //           updatedAtKey: false,
  //           columnDisplay: columnDisplayEnum.DISABLED,
  //         },
  //         '164096323465.0u73x': {
  //           columnId: '',
  //           columnName: '',
  //           columnType: '',
  //           columnDisplay: columnDisplayEnum.LONG_LINE,
  //         },
  //       },
  //       audienceColumnName: 'birthdate',
  //       audienceColumnType: 'date',
  //       canDelete: true,
  //       primaryKey: false,
  //       updatedAtKey: false,
  //     },
  //     {
  //       key: '1640967003363.04ssf',
  //       mappedColumns: {
  //         '1640966967674.0u73x': {
  //           columnId: '1640966984440.0vikb',
  //           columnName: 'emailDomain',
  //           columnType: 'string',
  //           primaryKey: false,
  //           updatedAtKey: false,
  //           columnDisplay: columnDisplayEnum.DISABLED,
  //         },
  //         '164096323465.0u73x': {
  //           columnId: '',
  //           columnName: '',
  //           columnType: '',
  //           columnDisplay: columnDisplayEnum.LONG_LINE,
  //         },
  //       },
  //       audienceColumnName: 'email_domain',
  //       audienceColumnType: 'string',
  //       canDelete: true,
  //       primaryKey: false,
  //       updatedAtKey: false,
  //     },
  //     {
  //       key: '1640967003363.0cpd5',
  //       mappedColumns: {
  //         '1640966967674.0u73x': {
  //           columnId: '1640966984440.0dsub',
  //           columnName: 'updatedAt',
  //           columnType: 'date',
  //           primaryKey: false,
  //           updatedAtKey: true,
  //           columnDisplay: columnDisplayEnum.DISABLED,
  //         },
  //         '164096323465.0u73x': {
  //           columnId: '',
  //           columnName: '',
  //           columnType: '',
  //           columnDisplay: columnDisplayEnum.LONG_LINE,
  //         },
  //       },
  //       audienceColumnName: 'updated_at',
  //       audienceColumnType: 'date',
  //       primaryKey: false,
  //       updatedAtKey: true,
  //     },
  //     {
  //       key: '_m_y_o_r_d_e_r_s_records',
  //       audienceColumnName: 'my_orders_records',
  //       audienceColumnType: 'number',
  //       canDelete: true,
  //       mappedColumns: {
  //         '1640966967674.0u73x': {
  //           columnId: '',
  //           columnName: '',
  //           columnType: '',
  //           columnDisplay: columnDisplayEnum.HIDDEN,
  //         },
  //         '164096323465.0u73x': {
  //           columnId: '_m_y_o_r_d_e_r_s_records',
  //           columnType: 'number',
  //           columnName: '_m_y_o_r_d_e_r_s_records_nbr',
  //           aggregate: {
  //             excludeNullvalues: false,
  //             filterByColumns: false,
  //             filterByOtherDateColumn: false,
  //             sourceColumnId: 'allcolumns',
  //             function: 'countall',
  //             datePeriod: 'alltime',
  //           },
  //           columnDisplay: columnDisplayEnum.DROP_DOWN_VIEW_MENU,
  //         },
  //       },
  //     },
  //     {
  //       key: '1640967096214.0drjx',
  //       mappedColumns: {
  //         '1640966967674.0u73x': {
  //           columnId: '',
  //           columnName: '',
  //           columnType: '',
  //           columnDisplay: columnDisplayEnum.HIDDEN,
  //         },
  //         '164096323465.0u73x': {
  //           columnId: '1640967062537.0z9y9',
  //           columnName: 'discount_amount_count_orders_365days',
  //           columnType: 'number',
  //           aggregate: {
  //             excludeNullvalues: false,
  //             filterByColumns: false,
  //             filterByOtherDateColumn: false,
  //             sourceColumnId: '1640967062537.0z9y9',
  //             function: 'count',
  //             datePeriod: '365days',
  //           },
  //           columnDisplay: columnDisplayEnum.DROP_DOWN_VIEW_MENU,
  //         },
  //       },
  //       canDelete: true,
  //       audienceColumnName: 'discount_amount_count_orders_365days',
  //       audienceColumnType: 'number',
  //     },
  //   ],
  //   preparationSteps: [
  //     {
  //       key: '1640967054849.0f2d2',
  //       position: 0,
  //       type: 'initialSource',
  //       title: 'Init with Salesforce / MY CONTACTS',
  //       configuration: {
  //         sourceKey: '1640966967674.0u73x',
  //       },
  //     },
  //     {
  //       key: '1640967119566.097y1',
  //       position: 1,
  //       type: 'combinedSource',
  //       title: 'Combine MY ORDERS',
  //       configuration: {
  //         sourceKey: '164096323465.0u73x',
  //       },
  //     },
  //   ],
  //   deduplicateSettings: {
  //     duplicateKeys: [],
  //     preservedValuesKeys: [],
  //   },
  //   retrievalMode: retrievalModeEnum.INCREMENTAL_MODE,
  //   buildType: buildTypesEnum.BUSINESS,
  //   name: 'Contacts master',
  //   category: {
  //     key: '1',
  //     name: 'contacts',
  //     columns: [
  //       {
  //         id: 'col1',
  //         columnName: 'userId',
  //         columnType: 'string',
  //         required: true,
  //         category: 'string',
  //         description: 'this is a description of the column',
  //         position: 1,
  //       },
  //       {
  //         id: '1640965852275.0odo9',
  //         columnName: 'firstName',
  //         columnType: 'string',
  //         required: true,
  //         category: 'first_name',
  //         description: 'this is a description of the column',
  //         position: 1,
  //       },
  //       {
  //         id: '1640965852275.058ic',
  //         columnName: 'lastName',
  //         columnType: 'string',
  //         required: false,
  //         category: 'string',
  //         description: 'this is a description of the column',
  //         position: 2,
  //       },
  //       {
  //         id: '1640965852275.0465e',
  //         columnName: 'fullName',
  //         columnType: 'string',
  //         required: false,
  //         category: 'string',
  //         description: 'this is a description of the column',
  //         position: 3,
  //       },
  //       {
  //         id: '1640965852275.01of7',
  //         columnName: 'birthdate',
  //         columnType: 'date',
  //         required: false,
  //         category: 'date',
  //         description: 'this is a description of the column',
  //         position: 4,
  //       },
  //       {
  //         id: '1640965852275.055c8',
  //         columnName: 'language',
  //         columnType: 'string',
  //         required: false,
  //         category: 'string',
  //         description: 'this is a description of the column',
  //         position: 5,
  //       },
  //       {
  //         id: '1640965852275.08tde',
  //         columnName: 'gender',
  //         columnType: 'string',
  //         required: false,
  //         category: 'string',
  //         description: 'this is a description of the column',
  //         position: 6,
  //       },
  //       {
  //         id: '1640965852275.0wgfq',
  //         columnName: 'civility',
  //         columnType: 'string',
  //         required: false,
  //         category: 'string',
  //         description: 'this is a description of the column',
  //         position: 7,
  //       },
  //       {
  //         id: '1640965852275.0fy4o',
  //         columnName: 'address',
  //         columnType: 'string',
  //         required: false,
  //         category: 'string',
  //         description: 'this is a description of the column',
  //         position: 8,
  //       },
  //       {
  //         id: '1640965852275.05qab',
  //         columnName: 'postalCode',
  //         columnType: 'string',
  //         required: false,
  //         category: 'string',
  //         description: 'this is a description of the column',
  //         position: 9,
  //       },
  //       {
  //         id: '1640965852275.0cj4x',
  //         columnName: 'city',
  //         columnType: 'string',
  //         required: false,
  //         category: 'string',
  //         description: 'this is a description of the column',
  //         position: 10,
  //       },
  //       {
  //         id: '1640965852275.0hpyx',
  //         columnName: 'region',
  //         columnType: 'string',
  //         required: false,
  //         category: 'string',
  //         description: 'this is a description of the column',
  //         position: 11,
  //       },
  //       {
  //         id: '1640965852275.00i9z',
  //         columnName: 'countryName',
  //         columnType: 'string',
  //         required: false,
  //         category: 'string',
  //         description: 'this is a description of the column',
  //         position: 12,
  //       },
  //       {
  //         id: '1640965852275.0m2vk',
  //         columnName: 'countryCode',
  //         columnType: 'string',
  //         required: false,
  //         category: 'string',
  //         description: 'this is a description of the column',
  //         position: 13,
  //       },
  //       {
  //         id: '1640965852275.0i9bu',
  //         columnName: 'email',
  //         columnType: 'string',
  //         required: true,
  //         category: 'email',
  //         description: 'this is a description of the column',
  //         position: 14,
  //       },
  //       {
  //         id: '1640965852275.0ykew',
  //         columnName: 'emailFormat',
  //         columnType: 'string',
  //         required: false,
  //         category: 'string',
  //         description: 'this is a description of the column',
  //         position: 15,
  //       },
  //       {
  //         id: '1640965852275.015q1',
  //         columnName: 'emailDomain',
  //         columnType: 'string',
  //         required: false,
  //         category: 'string',
  //         description: 'this is a description of the column',
  //         position: 16,
  //       },
  //       {
  //         id: '1640965852275.03lpc',
  //         columnName: 'emailValidity',
  //         columnType: 'string',
  //         required: false,
  //         category: 'string',
  //         description: 'this is a description of the column',
  //         position: 17,
  //       },
  //       {
  //         id: '1640965852275.0juk9',
  //         columnName: 'emailValidityReason',
  //         columnType: 'string',
  //         required: false,
  //         category: 'string',
  //         description: 'this is a description of the column',
  //         position: 18,
  //       },
  //       {
  //         id: '1640965852275.00ptc',
  //         columnName: 'phoneMobile',
  //         columnType: 'string',
  //         required: false,
  //         category: 'phone',
  //         description: 'this is a description of the column',
  //         position: 19,
  //       },
  //       {
  //         id: '1640965852275.0xcjl',
  //         columnName: 'phoneLandline',
  //         columnType: 'string',
  //         required: false,
  //         category: 'phone',
  //         description: 'this is a description of the column',
  //         position: 20,
  //       },
  //       {
  //         id: '1640965852275.0c1e9',
  //         columnName: 'phone',
  //         columnType: 'string',
  //         required: false,
  //         category: 'phone',
  //         description: 'this is a description of the column',
  //         position: 21,
  //       },
  //       {
  //         id: '1640965852275.0wfzb',
  //         columnName: 'phoneValidity',
  //         columnType: 'string',
  //         required: false,
  //         category: 'string',
  //         description: 'this is a description of the column',
  //         position: 22,
  //       },
  //       {
  //         id: '1640965852275.0pc30',
  //         columnName: 'phoneValidityReason',
  //         columnType: 'string',
  //         required: false,
  //         category: 'string',
  //         description: 'this is a description of the column',
  //         position: 23,
  //       },
  //       {
  //         id: '1640965852275.0ju1a',
  //         columnName: 'phoneCountry',
  //         columnType: 'string',
  //         required: false,
  //         category: 'string',
  //         description: 'this is a description of the column',
  //         position: 24,
  //       },
  //       {
  //         id: '1640965852275.0m2f7',
  //         columnName: 'phoneNormalized',
  //         columnType: 'string',
  //         required: false,
  //         category: 'string',
  //         description: 'this is a description of the column',
  //         position: 25,
  //       },
  //       {
  //         id: '1640965852275.05lrl',
  //         columnName: 'phoneType',
  //         columnType: 'string',
  //         required: false,
  //         category: 'string',
  //         description: 'this is a description of the column',
  //         position: 26,
  //       },
  //       {
  //         id: '1640965852275.05q4c',
  //         columnName: 'consentEmail',
  //         columnType: 'boolean',
  //         required: false,
  //         category: 'boolean',
  //         description: 'this is a description of the column',
  //         position: 27,
  //       },
  //       {
  //         id: '1640965852275.0czcs',
  //         columnName: 'consentEmailUpdatedAt',
  //         columnType: 'date',
  //         required: false,
  //         category: 'date',
  //         description: 'this is a description of the column',
  //         position: 28,
  //       },
  //       {
  //         id: '1640965852275.002a6',
  //         columnName: 'consentSms',
  //         columnType: 'boolean',
  //         required: false,
  //         category: 'boolean',
  //         description: 'this is a description of the column',
  //         position: 29,
  //       },
  //       {
  //         id: '1640965852275.0uzgh',
  //         columnName: 'consent_sms_updated_at',
  //         columnType: 'date',
  //         required: false,
  //         category: 'date',
  //         description: 'this is a description of the column',
  //         position: 30,
  //       },
  //       {
  //         id: '1640965852275.0ooky',
  //         columnName: 'linkedin',
  //         columnType: 'string',
  //         required: false,
  //         category: 'string',
  //         description: 'this is a description of the column',
  //         position: 31,
  //       },
  //       {
  //         id: '1640965852275.09dth',
  //         columnName: 'website',
  //         columnType: 'string',
  //         required: false,
  //         category: 'string',
  //         description: 'this is a description of the column',
  //         position: 32,
  //       },
  //       {
  //         id: '1640965852275.0k6i8',
  //         columnName: 'companyName',
  //         columnType: 'string',
  //         required: false,
  //         category: 'string',
  //         description: 'this is a description of the column',
  //         position: 33,
  //       },
  //       {
  //         id: '1640965852275.0gbl9',
  //         columnName: 'jobTitle',
  //         columnType: 'string',
  //         required: false,
  //         category: 'string',
  //         description: 'this is a description of the column',
  //         position: 34,
  //       },
  //       {
  //         id: '1640965852275.0r34h',
  //         columnName: 'createdAt',
  //         columnType: 'date',
  //         required: false,
  //         category: 'date',
  //         description: 'this is a description of the column',
  //         position: 35,
  //       },
  //       {
  //         id: '1640965852275.0k0q6',
  //         columnName: 'updatedAt',
  //         columnType: 'date',
  //         required: false,
  //         category: 'date',
  //         description: 'this is a description of the column',
  //         position: 36,
  //       },
  //     ],
  //   },
  //   object: {
  //     key: '1640967119515.0j3z7',
  //     name: 'object',
  //     tableName: 'table-object',
  //     category: 'default',
  //     schema: [
  //       {
  //         id: '1640967003363.0aw0z',
  //         columnName: 'user_id',
  //         columnType: 'string',
  //         category: '',
  //         description: '',
  //         position: 1,
  //         required: true,
  //       },
  //       {
  //         id: '1640967003363.01jnt',
  //         columnName: 'first_name',
  //         columnType: 'string',
  //         category: 'first_name',
  //         description: 'this is a description of the column',
  //         position: 2,
  //         required: false,
  //       },
  //       {
  //         id: '1640967003363.0n047',
  //         columnName: 'last_name',
  //         columnType: 'string',
  //         category: 'string',
  //         description: 'this is a description of the column',
  //         position: 3,
  //         required: false,
  //       },
  //       {
  //         id: '1640967003363.0k49k',
  //         columnName: 'full_name',
  //         columnType: 'string',
  //         category: 'string',
  //         description: 'this is a description of the column',
  //         position: 4,
  //         required: false,
  //       },
  //       {
  //         id: '1640967003363.0hy0x',
  //         columnName: 'birthdate',
  //         columnType: 'date',
  //         category: '',
  //         description: '',
  //         position: 5,
  //         required: false,
  //       },
  //       {
  //         id: '1640967003363.04ssf',
  //         columnName: 'email_domain',
  //         columnType: 'string',
  //         category: '',
  //         description: '',
  //         position: 6,
  //         required: false,
  //       },
  //       {
  //         id: '1640967003363.0cpd5',
  //         columnName: 'updated_at',
  //         columnType: 'date',
  //         category: '',
  //         description: '',
  //         position: 7,
  //         required: true,
  //       },
  //       {
  //         id: '_m_y_o_r_d_e_r_s_records',
  //         columnName: 'my_orders_records',
  //         columnType: 'number',
  //         category: '',
  //         description: '',
  //         position: 8,
  //         required: false,
  //       },
  //       {
  //         id: '1640967096214.0drjx',
  //         columnName: 'discount_amount_count_orders_365days',
  //         columnType: 'number',
  //         category: '',
  //         description: '',
  //         position: 9,
  //         required: false,
  //       },
  //     ],
  //     primaryKeys: ['1640967003363.0aw0z'],
  //     recordsNumber: 0,
  //     pointer: 'default',
  //     records: [
  //       {
  //         user_id: '1640967119515.0my1y',
  //         first_name: 'Dorian',
  //         last_name: 'Tremblay',
  //         full_name: 'Oswald Rice',
  //         birthdate: '2021-09-19T15:22:08.926Z',
  //         email_domain: 'drake.info',
  //         updated_at: '2021-01-15T10:21:25.557Z',
  //         my_orders_records: Math.floor(Math.random() * 6) + 1,
  //         discount_amount_count_orders_365days: Math.floor(Math.random() * 3) + 0,
  //       },
  //       {
  //         user_id: '1640967119516.0x3ss',
  //         first_name: 'Jerald',
  //         last_name: 'Durgan',
  //         full_name: 'Douglas Cole',
  //         birthdate: '2021-11-29T01:55:01.824Z',
  //         email_domain: 'johann.org',
  //         updated_at: '2021-05-18T12:56:49.422Z',
  //         my_orders_records: Math.floor(Math.random() * 6) + 1,
  //         discount_amount_count_orders_365days: Math.floor(Math.random() * 3) + 0,
  //       },
  //       {
  //         user_id: '1640967119517.016ff',
  //         first_name: 'Lorenzo',
  //         last_name: 'Waelchi',
  //         full_name: 'Nicole Walsh',
  //         birthdate: '2021-10-23T23:46:25.618Z',
  //         email_domain: 'myriam.biz',
  //         updated_at: '2021-02-23T22:42:01.695Z',
  //         my_orders_records: Math.floor(Math.random() * 6) + 1,
  //         discount_amount_count_orders_365days: Math.floor(Math.random() * 3) + 0,
  //       },
  //       {
  //         user_id: '1640967119518.0pxqu',
  //         first_name: 'Demond',
  //         last_name: 'Senger',
  //         full_name: 'Conrad Spinka',
  //         birthdate: '2021-07-01T02:37:54.076Z',
  //         email_domain: 'olen.org',
  //         updated_at: '2021-04-23T08:48:20.950Z',
  //         my_orders_records: Math.floor(Math.random() * 6) + 1,
  //         discount_amount_count_orders_365days: Math.floor(Math.random() * 3) + 0,
  //       },
  //       {
  //         user_id: '1640967119519.0grcl',
  //         first_name: 'Carissa',
  //         last_name: 'Steuber',
  //         full_name: 'Rocio Hackett',
  //         birthdate: '2021-05-19T15:01:52.868Z',
  //         email_domain: 'sandra.info',
  //         updated_at: '2021-05-17T05:21:10.443Z',
  //         my_orders_records: Math.floor(Math.random() * 6) + 1,
  //         discount_amount_count_orders_365days: Math.floor(Math.random() * 3) + 0,
  //       },
  //       {
  //         user_id: '1640967119520.0utbi',
  //         first_name: 'Edmund',
  //         last_name: 'Halvorson',
  //         full_name: 'Deondre Kemmer',
  //         birthdate: '2021-12-23T12:41:24.571Z',
  //         email_domain: 'bobby.biz',
  //         updated_at: '2021-05-04T04:15:43.538Z',
  //         my_orders_records: Math.floor(Math.random() * 6) + 1,
  //         discount_amount_count_orders_365days: Math.floor(Math.random() * 3) + 0,
  //       },
  //       {
  //         user_id: '1640967119521.0vbq6',
  //         first_name: 'Dillan',
  //         last_name: 'Lesch',
  //         full_name: 'Maynard Daugherty',
  //         birthdate: '2021-09-27T20:31:05.562Z',
  //         email_domain: 'camille.name',
  //         updated_at: '2021-03-29T19:00:24.625Z',
  //         my_orders_records: Math.floor(Math.random() * 6) + 1,
  //         discount_amount_count_orders_365days: Math.floor(Math.random() * 3) + 0,
  //       },
  //       {
  //         user_id: '1640967119522.061zu',
  //         first_name: 'Kraig',
  //         last_name: 'Zboncak',
  //         full_name: 'Sean Bosco',
  //         birthdate: '2021-06-26T04:17:42.994Z',
  //         email_domain: 'hudson.net',
  //         updated_at: '2021-01-01T10:29:25.118Z',
  //         my_orders_records: Math.floor(Math.random() * 6) + 1,
  //         discount_amount_count_orders_365days: Math.floor(Math.random() * 3) + 0,
  //       },
  //       {
  //         user_id: '1640967119522.0wdm4',
  //         first_name: 'August',
  //         last_name: 'Mills',
  //         full_name: 'Oren Swaniawski',
  //         birthdate: '2021-09-30T11:02:48.860Z',
  //         email_domain: 'jaquelin.info',
  //         updated_at: '2021-08-02T12:52:24.688Z',
  //         my_orders_records: Math.floor(Math.random() * 6) + 1,
  //         discount_amount_count_orders_365days: Math.floor(Math.random() * 3) + 0,
  //       },
  //       {
  //         user_id: '1640967119523.0gbx4',
  //         first_name: 'Alexandre',
  //         last_name: 'Mayert',
  //         full_name: 'Bernie Wuckert',
  //         birthdate: '2021-03-21T17:03:05.339Z',
  //         email_domain: 'amos.name',
  //         updated_at: '2021-06-30T15:01:34.813Z',
  //         my_orders_records: Math.floor(Math.random() * 6) + 1,
  //         discount_amount_count_orders_365days: Math.floor(Math.random() * 3) + 0,
  //       },
  //       {
  //         user_id: '1640967119524.0lxl9',
  //         first_name: 'Jacquelyn',
  //         last_name: 'Hilpert',
  //         full_name: 'Tristin Jast',
  //         birthdate: '2021-02-26T14:36:21.217Z',
  //         email_domain: 'mariana.net',
  //         updated_at: '2021-05-23T10:52:42.374Z',
  //         my_orders_records: Math.floor(Math.random() * 6) + 1,
  //         discount_amount_count_orders_365days: Math.floor(Math.random() * 3) + 0,
  //       },
  //       {
  //         user_id: '1640967119525.0okdz',
  //         first_name: 'Isabel',
  //         last_name: 'Mosciski',
  //         full_name: 'Sigmund Altenwerth',
  //         birthdate: '2021-12-08T14:34:57.085Z',
  //         email_domain: 'clinton.net',
  //         updated_at: '2021-09-07T04:13:46.821Z',
  //         my_orders_records: Math.floor(Math.random() * 6) + 1,
  //         discount_amount_count_orders_365days: Math.floor(Math.random() * 3) + 0,
  //       },
  //       {
  //         user_id: '1640967119526.0hgxw',
  //         first_name: 'Freeman',
  //         last_name: 'Witting',
  //         full_name: 'Bridget Beier',
  //         birthdate: '2021-11-04T10:45:42.015Z',
  //         email_domain: 'brooklyn.biz',
  //         updated_at: '2021-06-23T11:33:58.826Z',
  //         my_orders_records: Math.floor(Math.random() * 6) + 1,
  //         discount_amount_count_orders_365days: Math.floor(Math.random() * 3) + 0,
  //       },
  //       {
  //         user_id: '1640967119526.02v8j',
  //         first_name: 'Cesar',
  //         last_name: 'Tillman',
  //         full_name: 'Nicola Wuckert',
  //         birthdate: '2021-08-17T12:35:01.801Z',
  //         email_domain: 'christa.info',
  //         updated_at: '2021-12-05T02:05:58.220Z',
  //         my_orders_records: Math.floor(Math.random() * 6) + 1,
  //         discount_amount_count_orders_365days: Math.floor(Math.random() * 3) + 0,
  //       },
  //       {
  //         user_id: '1640967119527.0qjva',
  //         first_name: 'Selmer',
  //         last_name: 'Torp',
  //         full_name: 'Treva Osinski',
  //         birthdate: '2021-02-20T05:36:55.386Z',
  //         email_domain: 'dean.com',
  //         updated_at: '2021-05-19T04:11:46.220Z',
  //         my_orders_records: Math.floor(Math.random() * 6) + 1,
  //         discount_amount_count_orders_365days: Math.floor(Math.random() * 3) + 0,
  //       },
  //       {
  //         user_id: '1640967119528.0uexy',
  //         first_name: 'Jovany',
  //         last_name: 'Corkery',
  //         full_name: 'Theo Bradtke',
  //         birthdate: '2021-11-21T02:54:45.245Z',
  //         email_domain: 'collin.com',
  //         updated_at: '2021-09-04T21:19:36.341Z',
  //         my_orders_records: Math.floor(Math.random() * 6) + 1,
  //         discount_amount_count_orders_365days: Math.floor(Math.random() * 3) + 0,
  //       },
  //       {
  //         user_id: '1640967119529.0g3vn',
  //         first_name: 'Casandra',
  //         last_name: 'Mann',
  //         full_name: 'Mariam Fay',
  //         birthdate: '2021-01-21T18:40:27.791Z',
  //         email_domain: 'amya.org',
  //         updated_at: '2021-02-03T04:42:55.247Z',
  //         my_orders_records: Math.floor(Math.random() * 6) + 1,
  //         discount_amount_count_orders_365days: Math.floor(Math.random() * 3) + 0,
  //       },
  //       {
  //         user_id: '1640967119529.07ntj',
  //         first_name: 'Garett',
  //         last_name: 'Wehner',
  //         full_name: 'Luz Howe',
  //         birthdate: '2021-08-24T04:13:06.117Z',
  //         email_domain: 'gloria.info',
  //         updated_at: '2021-12-15T16:12:39.575Z',
  //         my_orders_records: Math.floor(Math.random() * 6) + 1,
  //         discount_amount_count_orders_365days: Math.floor(Math.random() * 3) + 0,
  //       },
  //       {
  //         user_id: '1640967119530.0yub7',
  //         first_name: 'Llewellyn',
  //         last_name: 'Kreiger',
  //         full_name: 'Talon Lebsack',
  //         birthdate: '2021-06-21T02:19:06.585Z',
  //         email_domain: 'carol.com',
  //         updated_at: '2021-11-29T20:19:52.306Z',
  //         my_orders_records: Math.floor(Math.random() * 6) + 1,
  //         discount_amount_count_orders_365days: Math.floor(Math.random() * 3) + 0,
  //       },
  //       {
  //         user_id: '1640967119531.0y8i6',
  //         first_name: 'Rudy',
  //         last_name: 'Labadie',
  //         full_name: 'Roxanne Lakin',
  //         birthdate: '2021-01-14T05:52:48.520Z',
  //         email_domain: 'deondre.info',
  //         updated_at: '2021-08-26T14:49:26.283Z',
  //         my_orders_records: Math.floor(Math.random() * 6) + 1,
  //         discount_amount_count_orders_365days: Math.floor(Math.random() * 3) + 0,
  //       },
  //       {
  //         user_id: '1640967119532.0hj9q',
  //         first_name: 'Seamus',
  //         last_name: 'Davis',
  //         full_name: 'Kelly Ankunding',
  //         birthdate: '2021-04-26T05:25:18.767Z',
  //         email_domain: 'jettie.net',
  //         updated_at: '2021-01-05T04:52:25.713Z',
  //         my_orders_records: Math.floor(Math.random() * 6) + 1,
  //         discount_amount_count_orders_365days: Math.floor(Math.random() * 3) + 0,
  //       },
  //     ],
  //     created: new Date(),
  //     updatedAtId: '1640967003363.0cpd5',
  //   },
  //   status: true,
  // },
  {
    key: '1639398565924.015ad',
    status: true,
    category: businessCategoriesDefaultList[0],
    filter: {
      key: '1638360408948.06vz6',
      combinationOperator: 'or',
      groupFilters: [
        {
          combinationOperator: 'and',
          conditions: [],
          type: 'property',
          id: '1638361056033.08fcg',
        },
      ],
      object: '1639398651795.0e12p',
    },
    sources: [
      {
        key: '1640912329789.0u73x',
        combinationLevel: 0,
        dataType: dataTypesEnum.UNION,
        sourceType: sourceTypesEnum.SOURCE,
        retrieveFrequency: retrieveFrequencyEnum.EVERY_HOUR,
        deduplicateSettings: {
          keep: keepTypesEnum.RECENT,
          deduplicateRecord: 'false',
          deduplicateColumns: {
            key: '1639398562937.02j7w',
            combinationOperator: 'or',
            columnGroups: [],
          },
        },
        audienceSource: {
          key: '1639398565924.0p5xz',
          connection: {
            key: 'connection7',
            name: 'Magento',
            usedAs: ['destination'],
            connector: {
              key: 'connector8',
              title: 'Magento',
              description: 'This is some description of the destination connector',
              category: 'api',
              logo: '/icons/logos/magento.png',
              expandedLogo: '/icons/magento-expanded.png',
            },
            credentials: {
              url: 'customdomain.magento.com',
              apiKey: 'Kq5-SD$O5qsd"79qsdLSdf*/sd985654qs',
            },
          },
          pointer: 'Available integrations/All contacts',
          object: {
            updatedAtId: '1639398585186.0dv04',
            name: 'object_Available integrations/All contacts',
            tableName: 'Available integrations/All contacts',
            category: 'contacts',
            schema: [
              {
                id: '1639398585185.0m2el',
                columnName: 'userId',
                columnType: 'string',
                required: false,
                description: '',
                category: 'no category',
                position: 0,
              },
              {
                id: '1639398585185.0wdo8',
                columnName: 'firstName',
                columnType: 'string',
                required: false,
                description: '',
                category: 'no category',
                position: 1,
              },
              {
                id: '1639398585185.0lspq',
                columnName: 'lastName',
                columnType: 'string',
                required: false,
                description: '',
                category: 'no category',
                position: 2,
              },
              {
                id: '1639398585185.050ra',
                columnName: 'fullName',
                columnType: 'string',
                required: false,
                description: '',
                category: 'no category',
                position: 3,
              },
              {
                id: '1639398585185.0nmad',
                columnName: 'birthdate',
                columnType: 'date',
                required: false,
                description: '',
                category: 'no category',
                position: 4,
              },
              {
                id: '1639398585185.0wuh6',
                columnName: 'language',
                columnType: 'string',
                required: false,
                description: '',
                category: 'no category',
                position: 5,
              },
              {
                id: '1639398585185.0xyxc',
                columnName: 'gender',
                columnType: 'string',
                required: false,
                description: '',
                category: 'no category',
                position: 6,
              },
              {
                id: '1639398585185.0c1x2',
                columnName: 'civility',
                columnType: 'string',
                required: false,
                description: '',
                category: 'no category',
                position: 7,
              },
              {
                id: '1639398585185.0tpk3',
                columnName: 'address',
                columnType: 'string',
                required: false,
                description: '',
                category: 'no category',
                position: 8,
              },
              {
                id: '1639398585185.0blh8',
                columnName: 'postalCode',
                columnType: 'string',
                required: false,
                description: '',
                category: 'no category',
                position: 9,
              },
              {
                id: '1639398585185.0ttyp',
                columnName: 'city',
                columnType: 'string',
                required: false,
                description: '',
                category: 'no category',
                position: 10,
              },
              {
                id: '1639398585185.0an89',
                columnName: 'region',
                columnType: 'string',
                required: false,
                description: '',
                category: 'no category',
                position: 11,
              },
              {
                id: '1639398585185.0bvxa',
                columnName: 'countryName',
                columnType: 'string',
                required: false,
                description: '',
                category: 'no category',
                position: 12,
              },
              {
                id: '1639398585185.0nhs9',
                columnName: 'countryCode',
                columnType: 'string',
                required: false,
                description: '',
                category: 'no category',
                position: 13,
              },
              {
                id: '1639398585185.0dggi',
                columnName: 'email',
                columnType: 'string',
                required: false,
                description: '',
                category: 'no category',
                position: 14,
              },
              {
                id: '1639398585185.0fo4u',
                columnName: 'emailFormat',
                columnType: 'string',
                required: false,
                description: '',
                category: 'no category',
                position: 15,
              },
              {
                id: '1639398585186.0hoft',
                columnName: 'emailDomain',
                columnType: 'string',
                required: false,
                description: '',
                category: 'no category',
                position: 16,
              },
              {
                id: '1639398585186.0u8ir',
                columnName: 'emailValidity',
                columnType: 'no type',
                required: false,
                description: '',
                category: 'no category',
                position: 17,
              },
              {
                id: '1639398585186.051wn',
                columnName: 'emailValidityReason',
                columnType: 'no type',
                required: false,
                description: '',
                category: 'no category',
                position: 18,
              },
              {
                id: '1639398585186.0gu87',
                columnName: 'phoneMobile',
                columnType: 'string',
                required: false,
                description: '',
                category: 'no category',
                position: 19,
              },
              {
                id: '1639398585186.0zxuj',
                columnName: 'phoneLandline',
                columnType: 'number',
                required: false,
                description: '',
                category: 'no category',
                position: 20,
              },
              {
                id: '1639398585186.0mgy9',
                columnName: 'phone',
                columnType: 'number',
                required: false,
                description: '',
                category: 'no category',
                position: 21,
              },
              {
                id: '1639398585186.0rfe9',
                columnName: 'phoneValidity',
                columnType: 'no type',
                required: false,
                description: '',
                category: 'no category',
                position: 22,
              },
              {
                id: '1639398585186.033d1',
                columnName: 'phoneValidityReason',
                columnType: 'no type',
                required: false,
                description: '',
                category: 'no category',
                position: 23,
              },
              {
                id: '1639398585186.0ahfm',
                columnName: 'phoneCountry',
                columnType: 'string',
                required: false,
                description: '',
                category: 'no category',
                position: 24,
              },
              {
                id: '1639398585186.0mvoh',
                columnName: 'phoneNormalized',
                columnType: 'no type',
                required: false,
                description: '',
                category: 'no category',
                position: 25,
              },
              {
                id: '1639398585186.08127',
                columnName: 'phoneType',
                columnType: 'no type',
                required: false,
                description: '',
                category: 'no category',
                position: 26,
              },
              {
                id: '1639398585186.0676h',
                columnName: 'consentEmail',
                columnType: 'string',
                required: false,
                description: '',
                category: 'no category',
                position: 27,
              },
              {
                id: '1639398585186.0psvl',
                columnName: 'consentEmailUpdatedAt',
                columnType: 'string',
                required: false,
                description: '',
                category: 'no category',
                position: 28,
              },
              {
                id: '1639398585186.0n45k',
                columnName: 'consentSms',
                columnType: 'string',
                required: false,
                description: '',
                category: 'no category',
                position: 29,
              },
              {
                id: '1639398585186.06wqf',
                columnName: 'consentSmsUpdatedAt',
                columnType: 'string',
                required: false,
                description: '',
                category: 'no category',
                position: 30,
              },
              {
                id: '1639398585186.0snkj',
                columnName: 'linkedin',
                columnType: 'no type',
                required: false,
                description: '',
                category: 'no category',
                position: 31,
              },
              {
                id: '1639398585186.0q4na',
                columnName: 'website',
                columnType: 'no type',
                required: false,
                description: '',
                category: 'no category',
                position: 32,
              },
              {
                id: '1639398585186.0p8as',
                columnName: 'companyName',
                columnType: 'string',
                required: false,
                description: '',
                category: 'no category',
                position: 33,
              },
              {
                id: '1639398585186.02x5l',
                columnName: 'jobTitle',
                columnType: 'string',
                required: false,
                description: '',
                category: 'no category',
                position: 34,
              },
              {
                id: '1639398585186.0i0dw',
                columnName: 'createdAt',
                columnType: 'date',
                required: false,
                description: '',
                category: 'no category',
                position: 35,
              },
              {
                id: '1639398585186.0dv04',
                columnName: 'updatedAt',
                columnType: 'date',
                required: false,
                description: '',
                category: 'no category',
                position: 36,
              },
            ],
            primaryKeys: ['1639398585185.0m2el'],
            recordsNumber: 12,
            pointer: 'Available integrations/All contacts',
            records: [
              {
                userId: '1639398585177.0mml3',
                firstName: 'Donny',
                lastName: 'Labadie',
                fullName: 'Donny Labadie',
                birthdate: '2021-02-23T14:32:36.386Z',
                language: 'TD',
                gender: 'Intersex man',
                civility: 'Mr.',
                address: '0126 Ondricka Plaza',
                postalCode: '38093-0009',
                city: 'Filomenaville',
                region: '',
                countryName: 'Martinique',
                countryCode: 'AD',
                email: 'Damaris.Schamberger@hotmail.com',
                emailFormat: '',
                emailDomain: 'sharon.name',
                emailValidity: '',
                emailValidityReason: '',
                phoneMobile: '(697) 757-9602 x56462',
                phoneLandline: '1-761-411-4669',
                phone: '1-995-328-0238 x9768',
                phoneValidity: '',
                phoneValidityReason: '',
                phoneCountry: 'SB',
                phoneNormalized: '',
                phoneType: '',
                consentEmail: false,
                consentEmailUpdatedAt: '2021-04-26T17:07:29.904Z',
                consentSms: true,
                consentSmsUpdatedAt: '2021-06-12T15:08:16.847Z',
                linkedin: '',
                website: '',
                companyName: 'Ernser - Herzog',
                jobTitle: 'Lead Group Coordinator',
                createdAt: '2021-11-12T16:54:20.102Z',
                updatedAt: '2021-10-01T04:14:06.861Z',
              },
              {
                userId: '1639398585181.0rpq3',
                firstName: 'Archibald',
                lastName: 'Hintz',
                fullName: 'Archibald Hintz',
                birthdate: '2021-01-26T09:51:41.278Z',
                language: 'MP',
                gender: 'Transexual Female',
                civility: 'Ms.',
                address: '08805 Zulauf Bridge',
                postalCode: '13824',
                city: 'Port Merrittside',
                region: '',
                countryName: 'United Arab Emirates',
                countryCode: 'SD',
                email: 'Lester_Hane@gmail.com',
                emailFormat: '',
                emailDomain: 'maxine.biz',
                emailValidity: '',
                emailValidityReason: '',
                phoneMobile: '(562) 798-5051 x637',
                phoneLandline: '783.628.6850 x03178',
                phone: '(274) 842-3032 x1345',
                phoneValidity: '',
                phoneValidityReason: '',
                phoneCountry: 'IE',
                phoneNormalized: '',
                phoneType: '',
                consentEmail: false,
                consentEmailUpdatedAt: '2021-12-11T04:07:49.805Z',
                consentSms: false,
                consentSmsUpdatedAt: '2021-01-03T09:15:55.891Z',
                linkedin: '',
                website: '',
                companyName: 'Crist, Olson and Durgan',
                jobTitle: 'District Identity Architect',
                createdAt: '2021-03-16T02:26:34.034Z',
                updatedAt: '2021-04-18T19:31:11.549Z',
              },
              {
                userId: '1639398585181.013bs',
                firstName: 'Gladys',
                lastName: 'Walsh',
                fullName: 'Gladys Walsh',
                birthdate: '2021-09-08T08:15:15.251Z',
                language: 'CN',
                gender: 'Cis Female',
                civility: 'Mr.',
                address: '1237 Webster Flat',
                postalCode: '46469',
                city: 'Carson',
                region: '',
                countryName: 'Tanzania',
                countryCode: 'BM',
                email: 'Bria52@gmail.com',
                emailFormat: '',
                emailDomain: 'estevan.net',
                emailValidity: '',
                emailValidityReason: '',
                phoneMobile: '559.510.8002',
                phoneLandline: '(754) 578-0773 x917',
                phone: '(409) 769-5345 x95354',
                phoneValidity: '',
                phoneValidityReason: '',
                phoneCountry: 'CV',
                phoneNormalized: '',
                phoneType: '',
                consentEmail: false,
                consentEmailUpdatedAt: '2021-02-15T08:57:51.651Z',
                consentSms: true,
                consentSmsUpdatedAt: '2021-11-04T03:38:09.160Z',
                linkedin: '',
                website: '',
                companyName: 'Cummings - Heidenreich',
                jobTitle: 'International Creative Executive',
                createdAt: '2021-12-07T09:51:17.959Z',
                updatedAt: '2021-07-22T04:01:23.350Z',
              },
              {
                userId: '1639398585182.07by4',
                firstName: 'Octavia',
                lastName: 'Haag',
                fullName: 'Octavia Haag',
                birthdate: '2021-08-18T20:13:14.417Z',
                language: 'VA',
                gender: 'F2M',
                civility: 'Mr.',
                address: '3790 Nicole Bypass',
                postalCode: '62478-6301',
                city: 'Port Samson',
                region: '',
                countryName: 'Iran',
                countryCode: 'SM',
                email: 'Marquis15@gmail.com',
                emailFormat: '',
                emailDomain: 'emmitt.com',
                emailValidity: '',
                emailValidityReason: '',
                phoneMobile: '(834) 964-6173 x806',
                phoneLandline: '1-370-407-3962 x0296',
                phone: '705-466-8741 x486',
                phoneValidity: '',
                phoneValidityReason: '',
                phoneCountry: 'TR',
                phoneNormalized: '',
                phoneType: '',
                consentEmail: true,
                consentEmailUpdatedAt: '2021-08-17T04:40:35.766Z',
                consentSms: true,
                consentSmsUpdatedAt: '2021-08-17T04:00:19.136Z',
                linkedin: '',
                website: '',
                companyName: 'Bins, Pagac and Rippin',
                jobTitle: 'Direct Brand Specialist',
                createdAt: '2021-03-10T16:32:19.055Z',
                updatedAt: '2021-06-23T22:52:45.628Z',
              },
              {
                userId: '1639398585182.0s31v',
                firstName: 'Tomas',
                lastName: 'Marquardt',
                fullName: 'Tomas Marquardt',
                birthdate: '2021-10-21T10:56:43.936Z',
                language: 'CK',
                gender: 'Two-spirit',
                civility: 'Dr.',
                address: '542 Zieme Fields',
                postalCode: '55782-4477',
                city: 'Amarillo',
                region: '',
                countryName: 'Croatia',
                countryCode: 'HM',
                email: 'Idella13@hotmail.com',
                emailFormat: '',
                emailDomain: 'kitty.org',
                emailValidity: '',
                emailValidityReason: '',
                phoneMobile: '945.337.0329',
                phoneLandline: '1-889-909-8951',
                phone: '528.332.9562',
                phoneValidity: '',
                phoneValidityReason: '',
                phoneCountry: 'NC',
                phoneNormalized: '',
                phoneType: '',
                consentEmail: true,
                consentEmailUpdatedAt: '2021-07-22T16:30:39.842Z',
                consentSms: false,
                consentSmsUpdatedAt: '2021-06-22T19:30:04.058Z',
                linkedin: '',
                website: '',
                companyName: 'Lemke, Torphy and Wolff',
                jobTitle: 'Senior Infrastructure Agent',
                createdAt: '2021-06-30T11:17:40.554Z',
                updatedAt: '2021-01-16T21:30:44.401Z',
              },
              {
                userId: '1639398585183.0nv5w',
                firstName: 'Javon',
                lastName: 'Steuber',
                fullName: 'Javon Steuber',
                birthdate: '2020-12-25T08:34:31.003Z',
                language: 'AL',
                gender: 'Trans*Male',
                civility: 'Mrs.',
                address: '578 Erica Neck',
                postalCode: '87711',
                city: 'Lake Casimerview',
                region: '',
                countryName: 'Guatemala',
                countryCode: 'SN',
                email: 'Jaqueline_Gusikowski83@hotmail.com',
                emailFormat: '',
                emailDomain: 'aubrey.net',
                emailValidity: '',
                emailValidityReason: '',
                phoneMobile: '231.863.4123',
                phoneLandline: '492.767.3258 x0038',
                phone: '(547) 543-8960',
                phoneValidity: '',
                phoneValidityReason: '',
                phoneCountry: 'BR',
                phoneNormalized: '',
                phoneType: '',
                consentEmail: true,
                consentEmailUpdatedAt: '2021-09-21T16:59:20.380Z',
                consentSms: true,
                consentSmsUpdatedAt: '2021-11-14T23:06:16.572Z',
                linkedin: '',
                website: '',
                companyName: 'Davis Group',
                jobTitle: 'Human Configuration Architect',
                createdAt: '2021-08-17T21:02:51.352Z',
                updatedAt: '2021-12-02T13:34:07.939Z',
              },
              {
                userId: '1639398585183.030hf',
                firstName: 'Aletha',
                lastName: 'Gibson',
                fullName: 'Aletha Gibson',
                birthdate: '2021-09-27T06:06:33.014Z',
                language: 'MF',
                gender: 'Female to Male',
                civility: 'Dr.',
                address: '2412 Crist Forest',
                postalCode: '62827-0024',
                city: 'North Port',
                region: '',
                countryName: 'Fiji',
                countryCode: 'CV',
                email: 'Aracely57@gmail.com',
                emailFormat: '',
                emailDomain: 'susanna.name',
                emailValidity: '',
                emailValidityReason: '',
                phoneMobile: '979-865-8961 x84183',
                phoneLandline: '712.611.4330',
                phone: '(606) 314-8933 x53572',
                phoneValidity: '',
                phoneValidityReason: '',
                phoneCountry: 'WS',
                phoneNormalized: '',
                phoneType: '',
                consentEmail: false,
                consentEmailUpdatedAt: '2021-02-16T14:13:18.857Z',
                consentSms: true,
                consentSmsUpdatedAt: '2021-07-29T05:15:01.682Z',
                linkedin: '',
                website: '',
                companyName: "O'Reilly LLC",
                jobTitle: 'Investor Implementation Orchestrator',
                createdAt: '2021-10-08T21:38:55.001Z',
                updatedAt: '2020-12-19T17:49:27.173Z',
              },
              {
                userId: '1639398585183.0w8sc',
                firstName: 'Kyla',
                lastName: 'Considine',
                fullName: 'Kyla Considine',
                birthdate: '2021-02-28T09:10:21.224Z',
                language: 'RU',
                gender: 'Transexual Female',
                civility: 'Miss',
                address: '33214 Queen Locks',
                postalCode: '66806-5950',
                city: 'Farmington',
                region: '',
                countryName: 'Western Sahara',
                countryCode: 'AT',
                email: 'Floy_Mraz@yahoo.com',
                emailFormat: '',
                emailDomain: 'emmanuel.net',
                emailValidity: '',
                emailValidityReason: '',
                phoneMobile: '304-950-8493',
                phoneLandline: '1-255-502-8030 x973',
                phone: '651.739.6455',
                phoneValidity: '',
                phoneValidityReason: '',
                phoneCountry: 'TV',
                phoneNormalized: '',
                phoneType: '',
                consentEmail: false,
                consentEmailUpdatedAt: '2021-07-17T23:25:05.410Z',
                consentSms: true,
                consentSmsUpdatedAt: '2020-12-21T03:27:16.528Z',
                linkedin: '',
                website: '',
                companyName: 'Barton LLC',
                jobTitle: 'International Security Liaison',
                createdAt: '2021-07-15T16:49:38.632Z',
                updatedAt: '2021-09-05T19:00:40.009Z',
              },
              {
                userId: '1639398585184.01x27',
                firstName: 'Hector',
                lastName: 'Schultz',
                fullName: 'Hector Schultz',
                birthdate: '2021-02-13T01:09:14.552Z',
                language: 'KE',
                gender: 'Intersex person',
                civility: 'Miss',
                address: '5728 Schmitt Grove',
                postalCode: '95189',
                city: 'Camdenhaven',
                region: '',
                countryName: 'Turkey',
                countryCode: 'MQ',
                email: 'Dena64@yahoo.com',
                emailFormat: '',
                emailDomain: 'blanca.org',
                emailValidity: '',
                emailValidityReason: '',
                phoneMobile: '552-776-0405 x862',
                phoneLandline: '(718) 419-7658',
                phone: '945.340.4039 x08131',
                phoneValidity: '',
                phoneValidityReason: '',
                phoneCountry: 'TZ',
                phoneNormalized: '',
                phoneType: '',
                consentEmail: true,
                consentEmailUpdatedAt: '2021-11-27T10:41:23.629Z',
                consentSms: true,
                consentSmsUpdatedAt: '2020-12-29T23:46:36.745Z',
                linkedin: '',
                website: '',
                companyName: 'Roberts Inc',
                jobTitle: 'Chief Factors Architect',
                createdAt: '2021-12-13T04:53:03.356Z',
                updatedAt: '2021-04-03T12:27:18.885Z',
              },
              {
                userId: '1639398585184.0t5v0',
                firstName: 'Rhiannon',
                lastName: 'Lowe',
                fullName: 'Rhiannon Lowe',
                birthdate: '2021-02-05T20:52:08.701Z',
                language: 'BB',
                gender: 'Gender neutral',
                civility: 'Ms.',
                address: '51160 Wiegand Alley',
                postalCode: '23540-2423',
                city: 'South Skylar',
                region: '',
                countryName: 'Cuba',
                countryCode: 'NA',
                email: 'Zander_Schuppe@yahoo.com',
                emailFormat: '',
                emailDomain: 'kaitlyn.name',
                emailValidity: '',
                emailValidityReason: '',
                phoneMobile: '1-953-816-7077 x293',
                phoneLandline: '588-554-6388 x844',
                phone: '451-608-5149',
                phoneValidity: '',
                phoneValidityReason: '',
                phoneCountry: 'TH',
                phoneNormalized: '',
                phoneType: '',
                consentEmail: false,
                consentEmailUpdatedAt: '2021-12-11T19:57:04.021Z',
                consentSms: true,
                consentSmsUpdatedAt: '2021-11-01T07:38:33.222Z',
                linkedin: '',
                website: '',
                companyName: 'Heller, Parker and Sanford',
                jobTitle: 'National Applications Representative',
                createdAt: '2021-08-14T22:50:30.094Z',
                updatedAt: '2020-12-21T08:49:55.154Z',
              },
              {
                userId: '1639398585185.0vd4f',
                firstName: 'Anastasia',
                lastName: 'Schmitt',
                fullName: 'Anastasia Schmitt',
                birthdate: '2021-07-08T20:07:27.701Z',
                language: 'MA',
                gender: 'Man',
                civility: 'Mrs.',
                address: '828 Olaf Prairie',
                postalCode: '51755-0371',
                city: 'East Rosariomouth',
                region: '',
                countryName: 'Estonia',
                countryCode: 'GS',
                email: 'Percival7@hotmail.com',
                emailFormat: '',
                emailDomain: 'walter.com',
                emailValidity: '',
                emailValidityReason: '',
                phoneMobile: '(504) 229-7865 x65542',
                phoneLandline: '(618) 231-8887 x641',
                phone: '1-618-723-4528 x18380',
                phoneValidity: '',
                phoneValidityReason: '',
                phoneCountry: 'BZ',
                phoneNormalized: '',
                phoneType: '',
                consentEmail: true,
                consentEmailUpdatedAt: '2021-06-23T03:45:01.139Z',
                consentSms: false,
                consentSmsUpdatedAt: '2020-12-24T23:11:30.906Z',
                linkedin: '',
                website: '',
                companyName: 'Wunsch, Bruen and Yundt',
                jobTitle: 'Investor Solutions Coordinator',
                createdAt: '2021-09-19T20:32:43.832Z',
                updatedAt: '2021-08-21T22:12:26.324Z',
              },
              {
                userId: '1639398585185.05aks',
                firstName: 'Cooper',
                lastName: 'Franecki',
                fullName: 'Cooper Franecki',
                birthdate: '2021-07-18T02:26:45.386Z',
                language: 'MV',
                gender: 'Non-binary',
                civility: 'Mrs.',
                address: '2342 Blaise Villages',
                postalCode: '70178',
                city: 'Wheaton',
                region: '',
                countryName: 'Zimbabwe',
                countryCode: 'MT',
                email: 'Cleveland95@yahoo.com',
                emailFormat: '',
                emailDomain: 'madelyn.biz',
                emailValidity: '',
                emailValidityReason: '',
                phoneMobile: '(351) 292-9524 x15865',
                phoneLandline: '220.453.0260 x850',
                phone: '(346) 368-1019 x28506',
                phoneValidity: '',
                phoneValidityReason: '',
                phoneCountry: 'KN',
                phoneNormalized: '',
                phoneType: '',
                consentEmail: false,
                consentEmailUpdatedAt: '2021-04-10T02:06:17.704Z',
                consentSms: false,
                consentSmsUpdatedAt: '2020-12-19T20:59:27.529Z',
                linkedin: '',
                website: '',
                companyName: 'Gislason, Reichert and Cremin',
                jobTitle: 'District Communications Planner',
                createdAt: '2021-01-05T09:51:51.358Z',
                updatedAt: '2021-01-07T10:58:59.082Z',
              },
            ],
            key: '1639398585186.07leh',
            created: new Date(),
          },
          name: 'Eshop',
          category: {
            key: '1',
            name: 'contacts',
            columns: [
              {
                id: 'col1',
                columnName: 'userId',
                columnType: 'string',
                required: true,
                category: 'string',
                description: 'this is a description of the column',
                position: 1,
              },
              {
                id: '1639398562939.08eaaow',
                columnName: 'firstName',
                columnType: 'string',
                required: true,
                category: 'first_name',
                description: 'this is a description of the column',
                position: 1,
              },
              {
                id: '1639398562939.0vererge6',
                columnName: 'lastName',
                columnType: 'string',
                required: false,
                category: 'string',
                description: 'this is a description of the column',
                position: 2,
              },
              {
                id: '1639398562939.06v4ererh',
                columnName: 'fullName',
                columnType: 'string',
                required: false,
                category: 'string',
                description: 'this is a description of the column',
                position: 3,
              },
              {
                id: '1639398562939.ere454',
                columnName: 'birthdate',
                columnType: 'date',
                required: false,
                category: 'date',
                description: 'this is a description of the column',
                position: 4,
              },
              {
                id: '1639398562939.09zrrr44be',
                columnName: 'language',
                columnType: 'string',
                required: false,
                category: 'string',
                description: 'this is a description of the column',
                position: 5,
              },
              {
                id: '1639398562939.0rfn5772s',
                columnName: 'gender',
                columnType: 'string',
                required: false,
                category: 'string',
                description: 'this is a description of the column',
                position: 6,
              },
              {
                id: '1639398562939.0cwttyt454q',
                columnName: 'civility',
                columnType: 'string',
                required: false,
                category: 'string',
                description: 'this is a description of the column',
                position: 7,
              },
              {
                id: '1639398562939.05775tyq4e',
                columnName: 'address',
                columnType: 'string',
                required: false,
                category: 'string',
                description: 'this is a description of the column',
                position: 8,
              },
              {
                id: '1639398562939.0u1fn',
                columnName: 'postalCode',
                columnType: 'string',
                required: false,
                category: 'string',
                description: 'this is a description of the column',
                position: 9,
              },
              {
                id: '1639398562939.0hctyt458hv',
                columnName: 'city',
                columnType: 'string',
                required: false,
                category: 'string',
                description: 'this is a description of the column',
                position: 10,
              },
              {
                id: '1639398562939.0hra27mt',
                columnName: 'region',
                columnType: 'string',
                required: false,
                category: 'string',
                description: 'this is a description of the column',
                position: 11,
              },
              {
                id: '1639398562939.0ukere754iz',
                columnName: 'countryName',
                columnType: 'string',
                required: false,
                category: 'string',
                description: 'this is a description of the column',
                position: 12,
              },
              {
                id: '1639398562939.03ere45t2c',
                columnName: 'countryCode',
                columnType: 'string',
                required: false,
                category: 'string',
                description: 'this is a description of the column',
                position: 13,
              },
              {
                id: '1639398562939.0vere850s4',
                columnName: 'email',
                columnType: 'string',
                required: true,
                category: 'email',
                description: 'this is a description of the column',
                position: 14,
              },
              {
                id: '1639398562939.0jtthtuy45ac',
                columnName: 'emailFormat',
                columnType: 'string',
                required: false,
                category: 'string',
                description: 'this is a description of the column',
                position: 15,
              },
              {
                id: '1639398562939.02ttukjrf5ve',
                columnName: 'emailDomain',
                columnType: 'string',
                required: false,
                category: 'string',
                description: 'this is a description of the column',
                position: 16,
              },
              {
                id: '1639398562939.0x2457jypa',
                columnName: 'emailValidity',
                columnType: 'string',
                required: false,
                category: 'string',
                description: 'this is a description of the column',
                position: 17,
              },
              {
                id: '1639398562939.0cjyhtgh45t9',
                columnName: 'emailValidityReason',
                columnType: 'string',
                required: false,
                category: 'string',
                description: 'this is a description of the column',
                position: 18,
              },
              {
                id: '1639398562939.0djfg752jq',
                columnName: 'phoneMobile',
                columnType: 'string',
                required: false,
                category: 'phone',
                description: 'this is a description of the column',
                position: 19,
              },
              {
                id: '1639398562939.0omyuyui452wk',
                columnName: 'phoneLandline',
                columnType: 'string',
                required: false,
                category: 'phone',
                description: 'this is a description of the column',
                position: 20,
              },
              {
                id: '1639398562939.0lyjy4266t7q',
                columnName: 'phone',
                columnType: 'string',
                required: false,
                category: 'phone',
                description: 'this is a description of the column',
                position: 21,
              },
              {
                id: '1639398562939.0z5puodghr458b4',
                columnName: 'phoneValidity',
                columnType: 'string',
                required: false,
                category: 'string',
                description: 'this is a description of the column',
                position: 22,
              },
              {
                id: '1639398562939.0ie842ere2i',
                columnName: 'phoneValidityReason',
                columnType: 'string',
                required: false,
                category: 'string',
                description: 'this is a description of the column',
                position: 23,
              },
              {
                id: '1639398562939.0ijuy48cs0',
                columnName: 'phoneCountry',
                columnType: 'string',
                required: false,
                category: 'string',
                description: 'this is a description of the column',
                position: 24,
              },
              {
                id: '1639398562939.0u18722erdg',
                columnName: 'phoneNormalized',
                columnType: 'string',
                required: false,
                category: 'string',
                description: 'this is a description of the column',
                position: 25,
              },
              {
                id: '1639398562939.00er538cg7',
                columnName: 'phoneType',
                columnType: 'string',
                required: false,
                category: 'string',
                description: 'this is a description of the column',
                position: 26,
              },
              {
                id: '1639398562939.04xere643tt',
                columnName: 'consentEmail',
                columnType: 'boolean',
                required: false,
                category: 'boolean',
                description: 'this is a description of the column',
                position: 27,
              },
              {
                id: '1639398562939.0wzkg',
                columnName: 'consentEmailUpdatedAt',
                columnType: 'date',
                required: false,
                category: 'date',
                description: 'this is a description of the column',
                position: 28,
              },
              {
                id: '1639398562939.0xyeh',
                columnName: 'consentSms',
                columnType: 'boolean',
                required: false,
                category: 'boolean',
                description: 'this is a description of the column',
                position: 29,
              },
              {
                id: '1639398562939.05dil',
                columnName: 'consent_sms_updated_at',
                columnType: 'date',
                required: false,
                category: 'date',
                description: 'this is a description of the column',
                position: 30,
              },
              {
                id: '1639398562939.0g3yf',
                columnName: 'linkedin',
                columnType: 'string',
                required: false,
                category: 'string',
                description: 'this is a description of the column',
                position: 31,
              },
              {
                id: '1639398562939.0qllt',
                columnName: 'website',
                columnType: 'string',
                required: false,
                category: 'string',
                description: 'this is a description of the column',
                position: 32,
              },
              {
                id: '1639398562939.06jlr',
                columnName: 'companyName',
                columnType: 'string',
                required: false,
                category: 'string',
                description: 'this is a description of the column',
                position: 33,
              },
              {
                id: '1639398562939.07qat',
                columnName: 'jobTitle',
                columnType: 'string',
                required: false,
                category: 'string',
                description: 'this is a description of the column',
                position: 34,
              },
              {
                id: '1639398562939.0z8rc',
                columnName: 'createdAt',
                columnType: 'date',
                required: false,
                category: 'date',
                description: 'this is a description of the column',
                position: 35,
              },
              {
                id: '1639398562939.01kgy',
                columnName: 'updatedAt',
                columnType: 'date',
                required: false,
                category: 'date',
                description: 'this is a description of the column',
                position: 36,
              },
            ],
          },
        },
        selectedColumns: [
          {
            id: '1639398585185.0msrger57822el',
            columnName: 'userId',
            columnType: 'string',
            required: false,
            description: '',
            category: 'no category',
            position: 0,
          },
          {
            id: '1639398585186.0dere58512v04',
            columnName: 'updatedAt',
            columnType: 'date',
            required: false,
            description: '',
            category: 'no category',
            position: 36,
          },
          {
            id: '1639398585185.0wfe84rfedo8',
            columnName: 'firstName',
            columnType: 'string',
            required: false,
            description: '',
            category: 'no category',
            position: 1,
          },
          {
            id: '1639398585185.0lerer81espq',
            columnName: 'lastName',
            columnType: 'string',
            required: false,
            description: '',
            category: 'no category',
            position: 2,
          },
          {
            id: '1639398585185.050efe46422ra',
            columnName: 'fullName',
            columnType: 'string',
            required: false,
            description: '',
            category: 'no category',
            position: 3,
          },
        ],
        joinByColumns: [
          {
            key: '1639398562937.08mj8',
          },
        ],
      },
      {
        key: '1242346767889.0u73x',
        combinationLevel: 1,
        dataType: dataTypesEnum.JOIN,
        sourceType: sourceTypesEnum.SOURCE,
        retrieveFrequency: retrieveFrequencyEnum.EVERY_HOUR,
        deduplicateSettings: {
          keep: keepTypesEnum.RECENT,
          deduplicateRecord: 'false',
          deduplicateColumns: {
            key: '1639398562937.02j7w',
            combinationOperator: 'or',
            columnGroups: [],
          },
        },
        audienceSource: {
          key: '1639398605906.03t4n',
          connection: {
            key: 'connection7',
            name: 'Snowflake',
            usedAs: ['destination'],
            connector: {
              key: 'connector8',
              title: 'Snowflake',
              description: 'This is some description of the destination connector',
              category: 'api',
              logo: '/icons/logos/snowflake.png',
              expandedLogo: '/icons/snowflake-expanded.png',
            },
            credentials: {
              url: 'customdomain.snowflake.com',
              apiKey: 'Kq5-SD$O5qsd"79qsdLSdf*/sd985654qs',
            },
          },
          pointer: 'Available integrations/All contacts',
          object: {
            updatedAtId: '1639398628511.0yo2q',
            name: 'object_Available integrations/All contacts',
            tableName: 'Available integrations/All contacts',
            category: 'contacts',
            schema: [
              {
                id: '1639398628511.0624i',
                columnName: 'userId',
                columnType: 'string',
                required: false,
                description: '',
                category: 'no category',
                position: 0,
              },
              {
                id: '1639398628511.0a5zp',
                columnName: 'firstName',
                columnType: 'string',
                required: false,
                description: '',
                category: 'no category',
                position: 1,
              },
              {
                id: '1639398628511.0t4sq',
                columnName: 'lastName',
                columnType: 'string',
                required: false,
                description: '',
                category: 'no category',
                position: 2,
              },
              {
                id: '1639398628511.0r0dt',
                columnName: 'fullName',
                columnType: 'string',
                required: false,
                description: '',
                category: 'no category',
                position: 3,
              },
              {
                id: '1639398628511.0lnij',
                columnName: 'birthdate',
                columnType: 'date',
                required: false,
                description: '',
                category: 'no category',
                position: 4,
              },
              {
                id: '1639398628511.0oywd',
                columnName: 'language',
                columnType: 'string',
                required: false,
                description: '',
                category: 'no category',
                position: 5,
              },
              {
                id: '1639398628511.06q2x',
                columnName: 'gender',
                columnType: 'string',
                required: false,
                description: '',
                category: 'no category',
                position: 6,
              },
              {
                id: '1639398628511.0e7um',
                columnName: 'civility',
                columnType: 'string',
                required: false,
                description: '',
                category: 'no category',
                position: 7,
              },
              {
                id: '1639398628511.0h9th',
                columnName: 'address',
                columnType: 'string',
                required: false,
                description: '',
                category: 'no category',
                position: 8,
              },
              {
                id: '1639398628511.0nmoi',
                columnName: 'postalCode',
                columnType: 'string',
                required: false,
                description: '',
                category: 'no category',
                position: 9,
              },
              {
                id: '1639398628511.0frym',
                columnName: 'city',
                columnType: 'string',
                required: false,
                description: '',
                category: 'no category',
                position: 10,
              },
              {
                id: '1639398628511.0jo91',
                columnName: 'region',
                columnType: 'string',
                required: false,
                description: '',
                category: 'no category',
                position: 11,
              },
              {
                id: '1639398628511.07tw1',
                columnName: 'countryName',
                columnType: 'string',
                required: false,
                description: '',
                category: 'no category',
                position: 12,
              },
              {
                id: '1639398628511.09rwv',
                columnName: 'countryCode',
                columnType: 'string',
                required: false,
                description: '',
                category: 'no category',
                position: 13,
              },
              {
                id: '1639398628511.01ysj',
                columnName: 'email',
                columnType: 'string',
                required: false,
                description: '',
                category: 'no category',
                position: 14,
              },
              {
                id: '1639398628511.0rt9o',
                columnName: 'emailFormat',
                columnType: 'string',
                required: false,
                description: '',
                category: 'no category',
                position: 15,
              },
              {
                id: '1639398628511.0ojw2',
                columnName: 'emailDomain',
                columnType: 'string',
                required: false,
                description: '',
                category: 'no category',
                position: 16,
              },
              {
                id: '1639398628511.04iry',
                columnName: 'emailValidity',
                columnType: 'no type',
                required: false,
                description: '',
                category: 'no category',
                position: 17,
              },
              {
                id: '1639398628511.0b3t8',
                columnName: 'emailValidityReason',
                columnType: 'no type',
                required: false,
                description: '',
                category: 'no category',
                position: 18,
              },
              {
                id: '1639398628511.0g5jw',
                columnName: 'phoneMobile',
                columnType: 'string',
                required: false,
                description: '',
                category: 'no category',
                position: 19,
              },
              {
                id: '1639398628511.05vj2',
                columnName: 'phoneLandline',
                columnType: 'number',
                required: false,
                description: '',
                category: 'no category',
                position: 20,
              },
              {
                id: '1639398628511.0ua5q',
                columnName: 'phone',
                columnType: 'number',
                required: false,
                description: '',
                category: 'no category',
                position: 21,
              },
              {
                id: '1639398628511.06l2z',
                columnName: 'phoneValidity',
                columnType: 'no type',
                required: false,
                description: '',
                category: 'no category',
                position: 22,
              },
              {
                id: '1639398628511.09sex',
                columnName: 'phoneValidityReason',
                columnType: 'no type',
                required: false,
                description: '',
                category: 'no category',
                position: 23,
              },
              {
                id: '1639398628511.0mkv0',
                columnName: 'phoneCountry',
                columnType: 'string',
                required: false,
                description: '',
                category: 'no category',
                position: 24,
              },
              {
                id: '1639398628511.0xzis',
                columnName: 'phoneNormalized',
                columnType: 'no type',
                required: false,
                description: '',
                category: 'no category',
                position: 25,
              },
              {
                id: '1639398628511.04l72',
                columnName: 'phoneType',
                columnType: 'no type',
                required: false,
                description: '',
                category: 'no category',
                position: 26,
              },
              {
                id: '1639398628511.0faju',
                columnName: 'consentEmail',
                columnType: 'string',
                required: false,
                description: '',
                category: 'no category',
                position: 27,
              },
              {
                id: '1639398628511.05mxh',
                columnName: 'consentEmailUpdatedAt',
                columnType: 'string',
                required: false,
                description: '',
                category: 'no category',
                position: 28,
              },
              {
                id: '1639398628511.07rpe',
                columnName: 'consentSms',
                columnType: 'string',
                required: false,
                description: '',
                category: 'no category',
                position: 29,
              },
              {
                id: '1639398628511.0nvft',
                columnName: 'consentSmsUpdatedAt',
                columnType: 'string',
                required: false,
                description: '',
                category: 'no category',
                position: 30,
              },
              {
                id: '1639398628511.0siu2',
                columnName: 'linkedin',
                columnType: 'no type',
                required: false,
                description: '',
                category: 'no category',
                position: 31,
              },
              {
                id: '1639398628511.0ztvq',
                columnName: 'website',
                columnType: 'no type',
                required: false,
                description: '',
                category: 'no category',
                position: 32,
              },
              {
                id: '1639398628511.012sg',
                columnName: 'companyName',
                columnType: 'string',
                required: false,
                description: '',
                category: 'no category',
                position: 33,
              },
              {
                id: '1639398628511.0nxk5',
                columnName: 'jobTitle',
                columnType: 'string',
                required: false,
                description: '',
                category: 'no category',
                position: 34,
              },
              {
                id: '1639398628511.07wdl',
                columnName: 'createdAt',
                columnType: 'date',
                required: false,
                description: '',
                category: 'no category',
                position: 35,
              },
              {
                id: '1639398628511.0yo2q',
                columnName: 'updatedAt',
                columnType: 'date',
                required: false,
                description: '',
                category: 'no category',
                position: 36,
              },
            ],
            primaryKeys: ['1639398628511.0624i'],
            recordsNumber: 12,
            pointer: 'Available integrations/All contacts',
            records: [
              {
                userId: '1639398628508.0057e',
                firstName: 'Darrion',
                lastName: 'Emmerich',
                fullName: 'Darrion Emmerich',
                birthdate: '2021-10-29T18:09:30.268Z',
                language: 'VG',
                gender: 'Neither',
                civility: 'Ms.',
                address: '34956 Bette Fort',
                postalCode: '61335-4282',
                city: 'South Glennie',
                region: '',
                countryName: 'Cook Islands',
                countryCode: 'LY',
                email: 'Joshuah_Kutch@gmail.com',
                emailFormat: '',
                emailDomain: 'carlo.info',
                emailValidity: '',
                emailValidityReason: '',
                phoneMobile: '786-275-4449 x90299',
                phoneLandline: '687-907-8335 x4408',
                phone: '920.913.4129 x38834',
                phoneValidity: '',
                phoneValidityReason: '',
                phoneCountry: 'JP',
                phoneNormalized: '',
                phoneType: '',
                consentEmail: false,
                consentEmailUpdatedAt: '2021-04-27T08:06:26.859Z',
                consentSms: true,
                consentSmsUpdatedAt: '2021-01-08T15:02:06.213Z',
                linkedin: '',
                website: '',
                companyName: 'Raynor, Williamson and Conn',
                jobTitle: 'Senior Mobility Representative',
                createdAt: '2021-10-07T22:49:37.074Z',
                updatedAt: '2021-08-09T17:17:47.923Z',
              },
              {
                userId: '1639398628508.0s9sk',
                firstName: 'Rafaela',
                lastName: 'Waters',
                fullName: 'Rafaela Waters',
                birthdate: '2021-07-30T01:37:30.954Z',
                language: 'DE',
                gender: 'Male to female transsexual woman',
                civility: 'Miss',
                address: '1384 Rippin Mill',
                postalCode: '05840',
                city: 'South Antoniachester',
                region: '',
                countryName: 'Tokelau',
                countryCode: 'GY',
                email: 'Cristian.Denesik69@gmail.com',
                emailFormat: '',
                emailDomain: 'jaleel.biz',
                emailValidity: '',
                emailValidityReason: '',
                phoneMobile: '544.741.0413 x573',
                phoneLandline: '(265) 398-0332 x09157',
                phone: '1-710-714-8706',
                phoneValidity: '',
                phoneValidityReason: '',
                phoneCountry: 'MF',
                phoneNormalized: '',
                phoneType: '',
                consentEmail: false,
                consentEmailUpdatedAt: '2021-03-21T12:21:15.269Z',
                consentSms: true,
                consentSmsUpdatedAt: '2021-11-10T20:14:32.759Z',
                linkedin: '',
                website: '',
                companyName: 'Gorczany - Heller',
                jobTitle: 'Legacy Marketing Liaison',
                createdAt: '2021-06-26T06:22:04.520Z',
                updatedAt: '2021-04-27T07:35:42.484Z',
              },
              {
                userId: '1639398628508.0metz',
                firstName: 'Camden',
                lastName: 'Vandervort',
                fullName: 'Camden Vandervort',
                birthdate: '2021-07-01T14:34:33.585Z',
                language: 'HT',
                gender: 'Trans*Woman',
                civility: 'Ms.',
                address: '5771 McLaughlin Forks',
                postalCode: '39923-2794',
                city: 'Decatur',
                region: '',
                countryName: 'Algeria',
                countryCode: 'TH',
                email: 'Abbie_Considine@hotmail.com',
                emailFormat: '',
                emailDomain: 'archibald.com',
                emailValidity: '',
                emailValidityReason: '',
                phoneMobile: '690-899-7643',
                phoneLandline: '654.291.5768 x268',
                phone: '(626) 603-8692',
                phoneValidity: '',
                phoneValidityReason: '',
                phoneCountry: 'NZ',
                phoneNormalized: '',
                phoneType: '',
                consentEmail: true,
                consentEmailUpdatedAt: '2021-09-12T05:43:32.238Z',
                consentSms: true,
                consentSmsUpdatedAt: '2021-06-30T21:22:38.337Z',
                linkedin: '',
                website: '',
                companyName: 'Rodriguez, Hansen and Botsford',
                jobTitle: 'Principal Operations Producer',
                createdAt: '2021-01-28T07:25:47.587Z',
                updatedAt: '2021-05-12T05:16:12.599Z',
              },
              {
                userId: '1639398628509.035hv',
                firstName: 'Kelton',
                lastName: 'Doyle',
                fullName: 'Kelton Doyle',
                birthdate: '2021-08-10T18:48:36.028Z',
                language: 'MO',
                gender: 'Transexual Woman',
                civility: 'Dr.',
                address: '457 Eusebio Estate',
                postalCode: '76204-0041',
                city: 'Keelyport',
                region: '',
                countryName: 'French Polynesia',
                countryCode: 'IL',
                email: 'Emily31@yahoo.com',
                emailFormat: '',
                emailDomain: 'fabian.biz',
                emailValidity: '',
                emailValidityReason: '',
                phoneMobile: '261.581.4933 x8487',
                phoneLandline: '554-416-7087',
                phone: '(715) 989-0111 x7976',
                phoneValidity: '',
                phoneValidityReason: '',
                phoneCountry: 'LV',
                phoneNormalized: '',
                phoneType: '',
                consentEmail: false,
                consentEmailUpdatedAt: '2021-05-31T15:07:48.665Z',
                consentSms: false,
                consentSmsUpdatedAt: '2021-09-08T17:27:22.069Z',
                linkedin: '',
                website: '',
                companyName: 'Emard, Lebsack and Casper',
                jobTitle: 'Forward Infrastructure Strategist',
                createdAt: '2021-05-19T06:00:17.631Z',
                updatedAt: '2020-12-28T05:22:36.652Z',
              },
              {
                userId: '1639398628509.0w9nm',
                firstName: 'Pamela',
                lastName: 'Stoltenberg',
                fullName: 'Pamela Stoltenberg',
                birthdate: '2021-08-08T21:08:46.784Z',
                language: 'CH',
                gender: 'Transexual',
                civility: 'Miss',
                address: '9026 Will Manor',
                postalCode: '34854',
                city: 'West Finn',
                region: '',
                countryName: 'Gambia',
                countryCode: 'NZ',
                email: 'Bessie_Hoppe25@gmail.com',
                emailFormat: '',
                emailDomain: 'jordan.net',
                emailValidity: '',
                emailValidityReason: '',
                phoneMobile: '1-262-555-5595 x503',
                phoneLandline: '299.427.0179',
                phone: '576.352.9991 x44951',
                phoneValidity: '',
                phoneValidityReason: '',
                phoneCountry: 'MN',
                phoneNormalized: '',
                phoneType: '',
                consentEmail: true,
                consentEmailUpdatedAt: '2020-12-17T00:53:00.079Z',
                consentSms: true,
                consentSmsUpdatedAt: '2021-06-14T09:42:50.166Z',
                linkedin: '',
                website: '',
                companyName: 'Hessel - Ankunding',
                jobTitle: 'Investor Brand Executive',
                createdAt: '2021-09-09T01:05:12.395Z',
                updatedAt: '2021-05-30T23:42:35.280Z',
              },
              {
                userId: '1639398628509.0mp3i',
                firstName: 'Maximus',
                lastName: 'Bayer',
                fullName: 'Maximus Bayer',
                birthdate: '2021-02-02T02:05:50.092Z',
                language: 'TZ',
                gender: 'Cis Woman',
                civility: 'Ms.',
                address: '2122 Upton Ridge',
                postalCode: '32884',
                city: 'Port Dulcemouth',
                region: '',
                countryName: 'Belarus',
                countryCode: 'LR',
                email: 'Julio.Pfeffer@gmail.com',
                emailFormat: '',
                emailDomain: 'florencio.com',
                emailValidity: '',
                emailValidityReason: '',
                phoneMobile: '980-997-1024',
                phoneLandline: '1-601-838-4991',
                phone: '(843) 919-4666',
                phoneValidity: '',
                phoneValidityReason: '',
                phoneCountry: 'PE',
                phoneNormalized: '',
                phoneType: '',
                consentEmail: true,
                consentEmailUpdatedAt: '2020-12-19T10:09:43.571Z',
                consentSms: false,
                consentSmsUpdatedAt: '2021-03-11T13:28:29.226Z',
                linkedin: '',
                website: '',
                companyName: 'Schneider and Sons',
                jobTitle: 'Human Paradigm Architect',
                createdAt: '2021-06-08T04:33:47.704Z',
                updatedAt: '2021-03-01T13:07:36.122Z',
              },
              {
                userId: '1639398628509.0aand',
                firstName: 'Emmalee',
                lastName: 'Gerlach',
                fullName: 'Emmalee Gerlach',
                birthdate: '2020-12-15T10:27:18.550Z',
                language: 'PN',
                gender: 'M2F',
                civility: 'Ms.',
                address: '8898 Botsford Trail',
                postalCode: '74464',
                city: 'Germainemouth',
                region: '',
                countryName: 'Gibraltar',
                countryCode: 'MM',
                email: 'Peggie0@gmail.com',
                emailFormat: '',
                emailDomain: 'herminia.info',
                emailValidity: '',
                emailValidityReason: '',
                phoneMobile: '270.842.8305',
                phoneLandline: '1-244-777-4255',
                phone: '476.379.8644 x53973',
                phoneValidity: '',
                phoneValidityReason: '',
                phoneCountry: 'SC',
                phoneNormalized: '',
                phoneType: '',
                consentEmail: false,
                consentEmailUpdatedAt: '2020-12-19T16:44:30.577Z',
                consentSms: false,
                consentSmsUpdatedAt: '2021-11-08T15:59:09.403Z',
                linkedin: '',
                website: '',
                companyName: 'VonRueden, Kirlin and Nicolas',
                jobTitle: 'Forward Security Manager',
                createdAt: '2021-02-27T14:04:41.473Z',
                updatedAt: '2021-07-24T20:50:13.911Z',
              },
              {
                userId: '1639398628510.0nwo3',
                firstName: 'Kylee',
                lastName: 'Kessler',
                fullName: 'Kylee Kessler',
                birthdate: '2021-01-09T10:23:31.207Z',
                language: 'KN',
                gender: 'M2F',
                civility: 'Dr.',
                address: '95056 Ledner View',
                postalCode: '32820-6917',
                city: 'Madison',
                region: '',
                countryName: 'Turks and Caicos Islands',
                countryCode: 'PW',
                email: 'Hoyt_Blanda@gmail.com',
                emailFormat: '',
                emailDomain: 'tamia.info',
                emailValidity: '',
                emailValidityReason: '',
                phoneMobile: '432-905-0845 x9737',
                phoneLandline: '262.988.0115 x76516',
                phone: '472.906.0858 x3972',
                phoneValidity: '',
                phoneValidityReason: '',
                phoneCountry: 'NZ',
                phoneNormalized: '',
                phoneType: '',
                consentEmail: false,
                consentEmailUpdatedAt: '2021-09-23T02:33:25.654Z',
                consentSms: false,
                consentSmsUpdatedAt: '2021-07-02T18:35:02.631Z',
                linkedin: '',
                website: '',
                companyName: 'Fritsch - Spinka',
                jobTitle: 'Global Functionality Supervisor',
                createdAt: '2021-01-08T10:07:59.776Z',
                updatedAt: '2021-01-03T23:29:10.009Z',
              },
              {
                userId: '1639398628510.0cd0v',
                firstName: 'Nicholaus',
                lastName: 'Brown',
                fullName: 'Nicholaus Brown',
                birthdate: '2021-08-29T21:00:20.588Z',
                language: 'TK',
                gender: 'Transexual Female',
                civility: 'Ms.',
                address: '14377 Johnnie Junction',
                postalCode: '13797',
                city: 'Lednerstad',
                region: '',
                countryName: 'Djibouti',
                countryCode: 'MA',
                email: 'Janie16@gmail.com',
                emailFormat: '',
                emailDomain: 'kenton.biz',
                emailValidity: '',
                emailValidityReason: '',
                phoneMobile: '446-271-9304',
                phoneLandline: '(790) 827-1238',
                phone: '618.466.2334',
                phoneValidity: '',
                phoneValidityReason: '',
                phoneCountry: 'MW',
                phoneNormalized: '',
                phoneType: '',
                consentEmail: false,
                consentEmailUpdatedAt: '2021-08-14T15:33:34.937Z',
                consentSms: false,
                consentSmsUpdatedAt: '2021-03-19T13:58:21.128Z',
                linkedin: '',
                website: '',
                companyName: 'Turcotte, Erdman and Jones',
                jobTitle: 'Chief Assurance Executive',
                createdAt: '2021-01-23T09:48:26.329Z',
                updatedAt: '2021-11-13T22:43:38.091Z',
              },
              {
                userId: '1639398628511.0vhhd',
                firstName: 'Antonietta',
                lastName: 'Moen',
                fullName: 'Antonietta Moen',
                birthdate: '2021-12-04T14:00:49.832Z',
                language: 'UG',
                gender: 'Intersex woman',
                civility: 'Miss',
                address: '0132 Zander Center',
                postalCode: '20823-9837',
                city: 'Harbershire',
                region: '',
                countryName: 'Ukraine',
                countryCode: 'CD',
                email: 'Kieran.Adams75@yahoo.com',
                emailFormat: '',
                emailDomain: 'archibald.com',
                emailValidity: '',
                emailValidityReason: '',
                phoneMobile: '463.715.4585 x7034',
                phoneLandline: '647.414.0898 x78325',
                phone: '347.410.5694 x070',
                phoneValidity: '',
                phoneValidityReason: '',
                phoneCountry: 'GS',
                phoneNormalized: '',
                phoneType: '',
                consentEmail: true,
                consentEmailUpdatedAt: '2021-06-24T21:14:19.644Z',
                consentSms: true,
                consentSmsUpdatedAt: '2021-02-27T16:39:39.988Z',
                linkedin: '',
                website: '',
                companyName: 'Effertz - Dickinson',
                jobTitle: 'Internal Factors Associate',
                createdAt: '2021-03-05T05:47:20.289Z',
                updatedAt: '2021-04-13T06:04:15.533Z',
              },
              {
                userId: '1639398628511.0jf2k',
                firstName: 'Allie',
                lastName: 'Konopelski',
                fullName: 'Allie Konopelski',
                birthdate: '2021-08-11T18:46:23.834Z',
                language: 'AS',
                gender: 'Female to male transgender man',
                civility: 'Dr.',
                address: '8439 Jazmin Curve',
                postalCode: '22009',
                city: 'Erickmouth',
                region: '',
                countryName: 'Chad',
                countryCode: 'UA',
                email: 'Unique_Glover52@yahoo.com',
                emailFormat: '',
                emailDomain: 'scottie.org',
                emailValidity: '',
                emailValidityReason: '',
                phoneMobile: '270-715-1877 x345',
                phoneLandline: '1-867-728-5635',
                phone: '320-467-2332 x5781',
                phoneValidity: '',
                phoneValidityReason: '',
                phoneCountry: 'AX',
                phoneNormalized: '',
                phoneType: '',
                consentEmail: false,
                consentEmailUpdatedAt: '2021-09-13T16:12:02.489Z',
                consentSms: true,
                consentSmsUpdatedAt: '2021-11-05T06:50:58.341Z',
                linkedin: '',
                website: '',
                companyName: 'Heller and Sons',
                jobTitle: 'Customer Mobility Analyst',
                createdAt: '2021-02-08T07:48:08.018Z',
                updatedAt: '2021-03-10T07:11:03.661Z',
              },
              {
                userId: '1639398628511.0ch0a',
                firstName: 'Ayden',
                lastName: 'Streich',
                fullName: 'Ayden Streich',
                birthdate: '2021-07-20T07:19:37.933Z',
                language: 'GS',
                gender: 'Transmasculine',
                civility: 'Dr.',
                address: '318 Jermaine Walk',
                postalCode: '11319',
                city: 'Abilene',
                region: '',
                countryName: 'Chile',
                countryCode: 'GG',
                email: 'Sheridan.Fay83@gmail.com',
                emailFormat: '',
                emailDomain: 'ophelia.name',
                emailValidity: '',
                emailValidityReason: '',
                phoneMobile: '769.300.9959',
                phoneLandline: '457-267-1897 x8654',
                phone: '(805) 929-8543 x847',
                phoneValidity: '',
                phoneValidityReason: '',
                phoneCountry: 'ST',
                phoneNormalized: '',
                phoneType: '',
                consentEmail: false,
                consentEmailUpdatedAt: '2021-02-08T14:34:04.898Z',
                consentSms: true,
                consentSmsUpdatedAt: '2021-12-09T01:11:06.957Z',
                linkedin: '',
                website: '',
                companyName: 'Bailey - Bins',
                jobTitle: 'Forward Tactics Developer',
                createdAt: '2021-01-03T21:26:33.563Z',
                updatedAt: '2021-03-28T03:00:06.820Z',
              },
            ],
            key: '1639398628512.0ykzf',
            created: new Date(),
          },
          name: 'POS',
          category: {
            key: '1',
            name: 'contacts',
            columns: [
              {
                id: 'col1',
                columnName: 'userId',
                columnType: 'string',
                required: true,
                category: 'string',
                description: 'this is a description of the column',
                position: 1,
              },
              {
                id: '1639398562939.08eow',
                columnName: 'firstName',
                columnType: 'string',
                required: true,
                category: 'first_name',
                description: 'this is a description of the column',
                position: 1,
              },
              {
                id: '1639398562939.0vge6',
                columnName: 'lastName',
                columnType: 'string',
                required: false,
                category: 'string',
                description: 'this is a description of the column',
                position: 2,
              },
              {
                id: '1639398562939.06v4h',
                columnName: 'fullName',
                columnType: 'string',
                required: false,
                category: 'string',
                description: 'this is a description of the column',
                position: 3,
              },
              {
                id: '1639398562939.0mnpp',
                columnName: 'birthdate',
                columnType: 'date',
                required: false,
                category: 'date',
                description: 'this is a description of the column',
                position: 4,
              },
              {
                id: '1639398562939.09zbe',
                columnName: 'language',
                columnType: 'string',
                required: false,
                category: 'string',
                description: 'this is a description of the column',
                position: 5,
              },
              {
                id: '1639398562939.0rfns',
                columnName: 'gender',
                columnType: 'string',
                required: false,
                category: 'string',
                description: 'this is a description of the column',
                position: 6,
              },
              {
                id: '1639398562939.0cw4q',
                columnName: 'civility',
                columnType: 'string',
                required: false,
                category: 'string',
                description: 'this is a description of the column',
                position: 7,
              },
              {
                id: '1639398562939.0yq4e',
                columnName: 'address',
                columnType: 'string',
                required: false,
                category: 'string',
                description: 'this is a description of the column',
                position: 8,
              },
              {
                id: '1639398562939.0u1fn',
                columnName: 'postalCode',
                columnType: 'string',
                required: false,
                category: 'string',
                description: 'this is a description of the column',
                position: 9,
              },
              {
                id: '1639398562939.0hchv',
                columnName: 'city',
                columnType: 'string',
                required: false,
                category: 'string',
                description: 'this is a description of the column',
                position: 10,
              },
              {
                id: '1639398562939.0hrmt',
                columnName: 'region',
                columnType: 'string',
                required: false,
                category: 'string',
                description: 'this is a description of the column',
                position: 11,
              },
              {
                id: '1639398562939.0ukiz',
                columnName: 'countryName',
                columnType: 'string',
                required: false,
                category: 'string',
                description: 'this is a description of the column',
                position: 12,
              },
              {
                id: '1639398562939.03t2c',
                columnName: 'countryCode',
                columnType: 'string',
                required: false,
                category: 'string',
                description: 'this is a description of the column',
                position: 13,
              },
              {
                id: '1639398562939.0v0s4',
                columnName: 'email',
                columnType: 'string',
                required: true,
                category: 'email',
                description: 'this is a description of the column',
                position: 14,
              },
              {
                id: '1639398562939.0jtac',
                columnName: 'emailFormat',
                columnType: 'string',
                required: false,
                category: 'string',
                description: 'this is a description of the column',
                position: 15,
              },
              {
                id: '1639398562939.02tve',
                columnName: 'emailDomain',
                columnType: 'string',
                required: false,
                category: 'string',
                description: 'this is a description of the column',
                position: 16,
              },
              {
                id: '1639398562939.0x2pa',
                columnName: 'emailValidity',
                columnType: 'string',
                required: false,
                category: 'string',
                description: 'this is a description of the column',
                position: 17,
              },
              {
                id: '1639398562939.0cjt9',
                columnName: 'emailValidityReason',
                columnType: 'string',
                required: false,
                category: 'string',
                description: 'this is a description of the column',
                position: 18,
              },
              {
                id: '1639398562939.0djjq',
                columnName: 'phoneMobile',
                columnType: 'string',
                required: false,
                category: 'phone',
                description: 'this is a description of the column',
                position: 19,
              },
              {
                id: '1639398562939.0omwk',
                columnName: 'phoneLandline',
                columnType: 'string',
                required: false,
                category: 'phone',
                description: 'this is a description of the column',
                position: 20,
              },
              {
                id: '1639398562939.0lt7q',
                columnName: 'phone',
                columnType: 'string',
                required: false,
                category: 'phone',
                description: 'this is a description of the column',
                position: 21,
              },
              {
                id: '1639398562939.0z5b4',
                columnName: 'phoneValidity',
                columnType: 'string',
                required: false,
                category: 'string',
                description: 'this is a description of the column',
                position: 22,
              },
              {
                id: '1639398562939.0ie2i',
                columnName: 'phoneValidityReason',
                columnType: 'string',
                required: false,
                category: 'string',
                description: 'this is a description of the column',
                position: 23,
              },
              {
                id: '1639398562939.0ics0',
                columnName: 'phoneCountry',
                columnType: 'string',
                required: false,
                category: 'string',
                description: 'this is a description of the column',
                position: 24,
              },
              {
                id: '1639398562939.0u1dg',
                columnName: 'phoneNormalized',
                columnType: 'string',
                required: false,
                category: 'string',
                description: 'this is a description of the column',
                position: 25,
              },
              {
                id: '1639398562939.00cg7',
                columnName: 'phoneType',
                columnType: 'string',
                required: false,
                category: 'string',
                description: 'this is a description of the column',
                position: 26,
              },
              {
                id: '1639398562939.04xtt',
                columnName: 'consentEmail',
                columnType: 'boolean',
                required: false,
                category: 'boolean',
                description: 'this is a description of the column',
                position: 27,
              },
              {
                id: '1639398562939.0wzkg',
                columnName: 'consentEmailUpdatedAt',
                columnType: 'date',
                required: false,
                category: 'date',
                description: 'this is a description of the column',
                position: 28,
              },
              {
                id: '1639398562939.0xyeh',
                columnName: 'consentSms',
                columnType: 'boolean',
                required: false,
                category: 'boolean',
                description: 'this is a description of the column',
                position: 29,
              },
              {
                id: '1639398562939.05dil',
                columnName: 'consent_sms_updated_at',
                columnType: 'date',
                required: false,
                category: 'date',
                description: 'this is a description of the column',
                position: 30,
              },
              {
                id: '1639398562939.0g3yf',
                columnName: 'linkedin',
                columnType: 'string',
                required: false,
                category: 'string',
                description: 'this is a description of the column',
                position: 31,
              },
              {
                id: '1639398562939.0qllt',
                columnName: 'website',
                columnType: 'string',
                required: false,
                category: 'string',
                description: 'this is a description of the column',
                position: 32,
              },
              {
                id: '1639398562939.06jlr',
                columnName: 'companyName',
                columnType: 'string',
                required: false,
                category: 'string',
                description: 'this is a description of the column',
                position: 33,
              },
              {
                id: '1639398562939.07qat',
                columnName: 'jobTitle',
                columnType: 'string',
                required: false,
                category: 'string',
                description: 'this is a description of the column',
                position: 34,
              },
              {
                id: '1639398562939.0z8rc',
                columnName: 'createdAt',
                columnType: 'date',
                required: false,
                category: 'date',
                description: 'this is a description of the column',
                position: 35,
              },
              {
                id: '1639398562939.01kgy',
                columnName: 'updatedAt',
                columnType: 'date',
                required: false,
                category: 'date',
                description: 'this is a description of the column',
                position: 36,
              },
            ],
          },
        },
        selectedColumns: ordersColumns,
        joinByColumns: [
          {
            key: '1640965852275.05dr3',
            audienceColumnId: '1639398596380.04o5m',
            sourceColumnId: 'col1',
          },
        ],
      },
    ],
    mappingRows: [
      {
        key: '1639398596380.04o5m',
        mappedColumns: {
          '1640912329789.0u73x': {
            columnId: '1639398585185.0m2el',
            columnName: 'userId',
            columnType: 'string',
            primaryKey: true,
            updatedAtKey: false,
            columnDisplay: columnDisplayEnum.DISABLED,
          },
          '1242346767889.0u73x': {
            columnId: '1639398628511.0624i',
            columnName: 'userId',
            columnType: 'string',
            columnDisplay: columnDisplayEnum.SELECT,
          },
        },
        audienceColumnName: 'user_id',
        audienceColumnType: 'string',
        primaryKey: true,
        updatedAtKey: false,
      },
      {
        key: '1639398596380.0ohfi',
        mappedColumns: {
          '1640912329789.0u73x': {
            columnId: '1639398585186.0dv04',
            columnName: 'updatedAt',
            columnType: 'date',
            primaryKey: false,
            updatedAtKey: true,
            columnDisplay: columnDisplayEnum.DISABLED,
          },
          '1242346767889.0u73x': {
            columnId: '1639398628511.0yo2q',
            columnName: 'updatedAt',
            columnType: 'date',
            columnDisplay: columnDisplayEnum.SELECT,
          },
        },
        audienceColumnName: 'updated_at',
        audienceColumnType: 'date',
        primaryKey: false,
        updatedAtKey: true,
      },
      {
        key: '1639398596380.0t6c0',
        mappedColumns: {
          '1640912329789.0u73x': {
            columnId: '1639398585185.0wdo8',
            columnName: 'firstName',
            columnType: 'string',
            primaryKey: false,
            updatedAtKey: false,
            columnDisplay: columnDisplayEnum.DISABLED,
          },
          '1242346767889.0u73x': {
            columnId: '1639398628511.0624i',
            columnName: 'userId',
            columnType: 'string',
            columnDisplay: columnDisplayEnum.SELECT,
          },
        },
        audienceColumnName: 'first_name',
        audienceColumnType: 'string',
        canDelete: true,
        primaryKey: false,
        updatedAtKey: false,
      },
      {
        key: '1639398596380.0n5do',
        mappedColumns: {
          '1640912329789.0u73x': {
            columnId: '1639398585185.0lspq',
            columnName: 'lastName',
            columnType: 'string',
            primaryKey: false,
            updatedAtKey: false,
            columnDisplay: columnDisplayEnum.DISABLED,
          },
          '1242346767889.0u73x': {
            columnId: '1639398628511.0a5zp',
            columnName: 'firstName',
            columnType: 'string',
            columnDisplay: columnDisplayEnum.SELECT,
          },
        },
        audienceColumnName: 'last_name',
        audienceColumnType: 'string',
        canDelete: true,
        primaryKey: false,
        updatedAtKey: false,
      },
      {
        key: '1639398596380.0fn7o',
        mappedColumns: {
          '1640912329789.0u73x': {
            columnId: '1639398585185.050ra',
            columnName: 'fullName',
            columnType: 'string',
            primaryKey: false,
            updatedAtKey: false,
            columnDisplay: columnDisplayEnum.DISABLED,
          },
          '1242346767889.0u73x': {
            columnId: '1639398628511.0t4sq',
            columnName: 'lastName',
            columnType: 'string',
            columnDisplay: columnDisplayEnum.SELECT,
          },
        },
        audienceColumnName: 'full_name',
        audienceColumnType: 'string',
        canDelete: true,
        primaryKey: false,
        updatedAtKey: false,
      },
    ],
    preparationSteps: [
      {
        key: '1639398605761.0nuxs',
        position: 0,
        type: 'initialSource',
        title: 'Init with contacts',
        configuration: {
          sourceKey: '1640912329789.0u73x',
        },
      },
      {
        key: '1639398651935.014c5',
        position: 3,
        type: 'combinedSource',
        title: 'Combine with offline orders',
        configuration: {
          sourceKey: '1242346767889.0u73x',
        },
      },
      {
        key: '1639398651935.01p45',
        position: 3,
        type: 'combinedSource',
        title: 'Combine with online orders',
        configuration: {
          sourceKey: '1242346767889.0u73x',
        },
      },
      {
        key: '1639398651935.0uiiui7845',
        position: 3,
        type: 'combinedSource',
        title: 'Combine with consents',
        configuration: {
          sourceKey: '1242346767889.0u73x',
        },
      },
      {
        key: '1639398664815.0z988h',
        position: 5,
        category: 'dataPreparation',
        type: 'findAndReplace',
        title: 'Compute RFM_segment',
        configuration: {},
      },
      {
        key: '1639398664815.45575',
        position: 5,
        category: 'dataPreparation',
        type: 'findAndReplace',
        title: 'Compute cross_sell',
        configuration: {},
      },
      {
        key: '1639398664815.0z96586786767qh',
        position: 6,
        category: 'dataPreparation',
        type: 'findAndReplace',
        title: 'Compute discount_sensitivity',
        configuration: {},
      },
      {
        key: '1639398664815.0z7867868769qh',
        position: 7,
        category: 'dataPreparation',
        type: 'findAndReplace',
        title: 'Compute delay_btwn_orders',
        configuration: {},
      },
      {
        key: '1639398664815.0z97867867qh',
        position: 8,
        category: 'dataPreparation',
        type: 'findAndReplace',
        title: 'Compute time_since_lastorder',
        configuration: {},
      },
      {
        key: '1639398664815.0z97867867qh',
        position: 9,
        category: 'dataPreparation',
        type: 'findAndReplace',
        title: 'Compute favorite_store',
        configuration: {},
      },
      {
        key: '16393986erez64815.0z97867867qh',
        position: 10,
        category: 'dataPreparation',
        type: 'findAndReplace',
        title: 'Compute favorite_store',
        configuration: {},
      },
      {
        key: '1639398e(yu(euy664815.0z97867867qh',
        position: 11,
        category: 'dataPreparation',
        type: 'findAndReplace',
        title: 'Compute consent_email',
        configuration: {},
      },
      {
        key: '16393zstjytk(uko98664815.0z97867867qh',
        position: 12,
        category: 'dataPreparation',
        type: 'findAndReplace',
        title: 'Compute consent_sms',
        configuration: {},
      },
      {
        key: '16393986hhtheth64815.0z97867867qh',
        position: 13,
        category: 'dataPreparation',
        type: 'findAndReplace',
        title: 'Compute CA Web',
        configuration: {},
      },
      {
        key: '1639ersgdf398664815.0z97867867qh',
        position: 14,
        category: 'dataPreparation',
        type: 'findAndReplace',
        title: 'Compute CA POS',
        configuration: {},
      },
      {
        key: '163939866yty4815.0z97867867qh',
        position: 15,
        category: 'dataPreparation',
        type: 'findAndReplace',
        title: 'Compute email_open_12mo',
        configuration: {},
      },


    ],
    deduplicateSettings: {
      duplicateKeys: [],
      preservedValuesKeys: [],
    },
    retrievalMode: retrievalModeEnum.INCREMENTAL_MODE,
    buildType: buildTypesEnum.BUSINESS,
    name: 'Contacts',
    object: {
      key: '1640963206876.0frtg',
      name: 'object',
      tableName: 'table-object',
      category: 'default',
      schema: [
        {
          id: '1639398596380.04=5o5m',
          columnName: 'master_id',
          columnType: 'string',
          category: '',
          description: '',
          position: 1,
          required: true,
        },
        {
          id: '1639398596380.04rt624o5m',
          columnName: 'id_eshop',
          columnType: 'string',
          category: '',
          description: '',
          position: 2,
          required: true,
        },
        {
          id: '1639398596380.0ohrtrt567552fi',
          columnName: 'id_pos',
          columnType: 'string',
          category: '',
          description: '',
          position: 3,
          required: true,
        },
        {
          id: '1639398596380.04ortr78925m',
          columnName: 'email',
          columnType: 'string',
          category: '',
          description: '',
          position: 4,
          required: true,
        },
        {
          id: '1639398596380.04ozeze78525m',
          columnName: 'postal_code',
          columnType: 'string',
          category: '',
          description: '',
          position: 5,
          required: true,
        },
        {
          id: '1639398596380.04oyogt8645m',
          columnName: 'birthdate',
          columnType: 'string',
          category: '',
          description: '',
          position: 6,
          required: true,
        },
        {
          id: '1639398596380.0nzeze757425do',
          columnName: 'phone',
          columnType: 'string',
          category: '',
          description: '',
          position: 7,
          required: false,
        },
        {
          id: '1639398596380.58zez782496',
          columnName: 'firstname',
          columnType: 'string',
          category: '',
          description: '',
          position: 8,
          required: false,
        },
        {
          id: '1639398596380.0fn7o',
          columnName: 'RFM_segment',
          columnType: 'string',
          category: '',
          description: '',
          position: 9,
          required: false,
        },
        {
          id: '1639398596380.32zez45724',
          columnName: 'RFM_score',
          columnType: 'string',
          category: '',
          description: '',
          position: 10,
          required: false,
        },
        {
          id: '1640963173901.0zze8721zeasd',
          columnName: 'cross_sell',
          columnType: 'string',
          category: '',
          description: '',
          position: 11,
          required: false,
        },
        {
          id: '1640963173901.0zaze48924sd',
          columnName: 'discount_sensitivity',
          columnType: 'string',
          category: '',
          description: '',
          position: 12,
          required: false,
        },
        {
          id: '1640963173901.0ze7624zasd',
          columnName: 'delay_btwn_orders',
          columnType: 'string',
          category: '',
          description: '',
          position: 13,
          required: false,
        },
        {
          id: '1640963173901.0zze45545asd',
          columnName: 'time_since_lastorder',
          columnType: 'string',
          category: '',
          description: '',
          position: 14,
          required: false,
        },
        {
          id: '1640963173901.0zze45rtr5asd',
          columnName: 'favorite_store',
          columnType: 'string',
          category: '',
          description: '',
          position: 15,
          required: false,
        },
        {
          id: '1640963173901.0z58924tytasd',
          columnName: 'consent_email',
          columnType: 'string',
          category: '',
          description: '',
          position: 16,
          required: false,
        },
        {
          id: '164096317ere3901.0z58924ty888989sd',
          columnName: 'consent_sms',
          columnType: 'string',
          category: '',
          description: '',
          position: 17,
          required: false,
        },
        {
          id: '164096317rr3901.0z589llmhjht77asd',
          columnName: 'CA Web',
          columnType: 'string',
          category: '',
          description: '',
          position: 18,
          required: false,
        },
        {
          id: '1640963173rar901.0z58924565646tytasd',
          columnName: 'CA POS',
          columnType: 'string',
          category: '',
          description: '',
          position: 19,
          required: false,
        },
        {
          id: '1640963173rar901.0z589zaeze245656ee46tytasd',
          columnName: 'email_open_12mo',
          columnType: 'string',
          category: '',
          description: '',
          position: 20,
          required: false,
        },
      ],
      primaryKeys: ['1639398596380.04o5m'],
      recordsNumber: 0,
      pointer: 'default',
      records: [
        {
          master_id: generateId().split('.')[1] + generateId().split('.')[1],
          email: (faker.internet.email('jovany')).toLowerCase(),
          postal_code: faker.address.zipCode(),
          birthdate: moment(new Date(faker.date.between('1975-01-01', '2005-01-05'))).format('YYYY-MM-DD').toString(),
          id_eshop: '1640963206876.0kssg'.split('.')[0],
          id_pos: 'aa6',
          firstname: 'Jovany',
          phone: getRandomPhoneNumber(),
          RFM_score: `${Math.floor(Math.random() * 6) + 1},${Math.floor(Math.random() * 6) + 1},${Math.floor(Math.random() * 6) + 1}`,
          RFM_segment: 'Loyal Customers',
          cross_sell: 'Cat B, Cat I',
          discount_sensitivity: (new Array('Low', 'Low', 'Medium', 'High'))[generateRandomInteger(0, 3)],
          delay_btwn_orders: generateRandomInteger(15, 70).toString(),
          time_since_lastorder: generateRandomInteger(15, 50).toString(),
          favorite_store: 'Store A',
        },
        {
          master_id: generateId().split('.')[1] + generateId().split('.')[1],
          email: (faker.internet.email('Velma')).toLowerCase(),
          postal_code: faker.address.zipCode(),
          birthdate: moment(new Date(faker.date.between('1975-01-01', '2005-01-05'))).format('YYYY-MM-DD').toString(),
          id_eshop: '1640963206877.0225v'.split('.')[0],
          id_pos: 'ad8',
          firstname: 'Velma',
          phone: getRandomPhoneNumber(),
          RFM_score: `${Math.floor(Math.random() * 6) + 1},${Math.floor(Math.random() * 6) + 1},${Math.floor(Math.random() * 6) + 1}`,
          RFM_segment: 'Can’t Lose Them',
          cross_sell: 'Cat G, Cat I',
          discount_sensitivity: (new Array('Low', 'Low', 'Medium', 'High'))[generateRandomInteger(0, 3)],
          delay_btwn_orders: generateRandomInteger(15, 70).toString(),
          time_since_lastorder: generateRandomInteger(15, 50).toString(),
          favorite_store: 'Store B',
        },
        {
          master_id: generateId().split('.')[1] + generateId().split('.')[1],
          email: (faker.internet.email('Carol')).toLowerCase(),
          postal_code: faker.address.zipCode(),
          birthdate: moment(new Date(faker.date.between('1975-01-01', '2005-01-05'))).format('YYYY-MM-DD').toString(),
          id_eshop: '1640963206878.0v8ml'.split('.')[0],
          id_pos: 'e82',
          firstname: 'Carol',
          phone: getRandomPhoneNumber(),
          RFM_score: `${Math.floor(Math.random() * 6) + 1},${Math.floor(Math.random() * 6) + 1},${Math.floor(Math.random() * 6) + 1}`,
          RFM_segment: 'Champions',
          cross_sell: 'Cat I, Cat G',
          discount_sensitivity: (new Array('Low', 'Low', 'Medium', 'High'))[generateRandomInteger(0, 3)],
          delay_btwn_orders: generateRandomInteger(15, 70).toString(),
          time_since_lastorder: generateRandomInteger(15, 50).toString(),
          favorite_store: 'Store A',
        },
        {
          master_id: generateId().split('.')[1] + generateId().split('.')[1],
          email: (faker.internet.email('wendell')).toLowerCase(),
          postal_code: faker.address.zipCode(),
          birthdate: moment(new Date(faker.date.between('1975-01-01', '2005-01-05'))).format('YYYY-MM-DD').toString(),
          id_eshop: '1640963206878.00z10'.split('.')[0],
          id_pos: 'zt8',
          firstname: 'Wendell',
          phone: getRandomPhoneNumber(),
          RFM_score: `${Math.floor(Math.random() * 6) + 1},${Math.floor(Math.random() * 6) + 1},${Math.floor(Math.random() * 6) + 1}`,
          RFM_segment: 'Loyal Customers',
          cross_sell: 'Cat I, Cat B',
          discount_sensitivity: (new Array('Low', 'Low', 'Medium', 'High'))[generateRandomInteger(0, 3)],
          delay_btwn_orders: generateRandomInteger(15, 70).toString(),
          time_since_lastorder: generateRandomInteger(15, 50).toString(),
          favorite_store: 'Store C',
        },
        {
          master_id: generateId().split('.')[1] + generateId().split('.')[1],
          email: (faker.internet.email('melvin')).toLowerCase(),
          postal_code: faker.address.zipCode(),
          birthdate: moment(new Date(faker.date.between('1975-01-01', '2005-01-05'))).format('YYYY-MM-DD').toString(),
          id_eshop: '1640963206879.0z0dq'.split('.')[0],
          id_pos: 'tu3',
          firstname: 'Melvin',
          phone: getRandomPhoneNumber(),
          RFM_score: `${Math.floor(Math.random() * 6) + 1},${Math.floor(Math.random() * 6) + 1},${Math.floor(Math.random() * 6) + 1}`,
          RFM_segment: 'Hibernating',
          cross_sell: 'Cat I, Cat G',
          discount_sensitivity: (new Array('Low', 'Low', 'Medium', 'High'))[generateRandomInteger(0, 3)],
          delay_btwn_orders: generateRandomInteger(15, 70).toString(),
          time_since_lastorder: generateRandomInteger(15, 50).toString(),
          favorite_store: 'Store E',
        },
        {
          master_id: generateId().split('.')[1] + generateId().split('.')[1],
          email: (faker.internet.email('neva')).toLowerCase(),
          postal_code: faker.address.zipCode(),
          birthdate: moment(new Date(faker.date.between('1975-01-01', '2005-01-05'))).format('YYYY-MM-DD').toString(),
          id_eshop: '1640963206880.0ie2l'.split('.')[0],
          id_pos: 'zer8',
          firstname: 'Neva',
          phone: getRandomPhoneNumber(),
          RFM_score: `${Math.floor(Math.random() * 6) + 1},${Math.floor(Math.random() * 6) + 1},${Math.floor(Math.random() * 6) + 1}`,
          RFM_segment: 'Customers Needing Attention',
          cross_sell: 'Cat G, Cat I',
          discount_sensitivity: (new Array('Low', 'Low', 'Medium', 'High'))[generateRandomInteger(0, 3)],
          delay_btwn_orders: generateRandomInteger(15, 70).toString(),
          time_since_lastorder: generateRandomInteger(15, 50).toString(),
          favorite_store: 'Store C',
        },
        {
          master_id: generateId().split('.')[1] + generateId().split('.')[1],
          email: (faker.internet.email('aurelie')).toLowerCase(),
          postal_code: faker.address.zipCode(),
          birthdate: moment(new Date(faker.date.between('1975-01-01', '2005-01-05'))).format('YYYY-MM-DD').toString(),
          id_eshop: '1640963206880.04rmt'.split('.')[0],
          id_pos: 'er89',
          firstname: 'Aurelie',
          phone: getRandomPhoneNumber(),
          RFM_score: `${Math.floor(Math.random() * 6) + 1},${Math.floor(Math.random() * 6) + 1},${Math.floor(Math.random() * 6) + 1}`,
          RFM_segment: 'Hibernating',
          cross_sell: 'Cat B, Cat I',
          discount_sensitivity: (new Array('Low', 'Low', 'Medium', 'High'))[generateRandomInteger(0, 3)],
          delay_btwn_orders: generateRandomInteger(15, 70).toString(),
          time_since_lastorder: generateRandomInteger(15, 50).toString(),
          favorite_store: 'Store G',
        },
        {
          master_id: generateId().split('.')[1] + generateId().split('.')[1],
          email: (faker.internet.email('Mathew')).toLowerCase(),
          postal_code: faker.address.zipCode(),
          birthdate: moment(new Date(faker.date.between('1975-01-01', '2005-01-05'))).format('YYYY-MM-DD').toString(),
          id_eshop: '1640963206881.0arta'.split('.')[0],
          id_pos: 'er5',
          firstname: 'Mathew',
          phone: getRandomPhoneNumber(),
          RFM_score: `${Math.floor(Math.random() * 6) + 1},${Math.floor(Math.random() * 6) + 1},${Math.floor(Math.random() * 6) + 1}`,
          RFM_segment: 'Recent Customers',
          cross_sell: 'Cat G, Cat I',
          discount_sensitivity: (new Array('Low', 'Low', 'Medium', 'High'))[generateRandomInteger(0, 3)],
          delay_btwn_orders: generateRandomInteger(15, 70).toString(),
          time_since_lastorder: generateRandomInteger(15, 50).toString(),
          favorite_store: 'Store A',
        },
        {
          master_id: generateId().split('.')[1] + generateId().split('.')[1],
          email: (faker.internet.email('Alberta')).toLowerCase(),
          postal_code: faker.address.zipCode(),
          birthdate: moment(new Date(faker.date.between('1975-01-01', '2005-01-05'))).format('YYYY-MM-DD').toString(),
          id_eshop: '1640963206882.01jnk'.split('.')[0],
          id_pos: 'er2',
          firstname: 'Alberta',
          phone: getRandomPhoneNumber(),
          RFM_score: `${Math.floor(Math.random() * 6) + 1},${Math.floor(Math.random() * 6) + 1},${Math.floor(Math.random() * 6) + 1}`,
          RFM_segment: 'Hibernating',
          cross_sell: 'Cat B, Cat I',
          discount_sensitivity: (new Array('Low', 'Low', 'Medium', 'High'))[generateRandomInteger(0, 3)],
          delay_btwn_orders: generateRandomInteger(15, 70).toString(),
          time_since_lastorder: generateRandomInteger(15, 50).toString(),
          favorite_store: 'Store C',
        },
        {
          master_id: generateId().split('.')[1] + generateId().split('.')[1],
          email: (faker.internet.email('josue')).toLowerCase(),
          postal_code: faker.address.zipCode(),
          birthdate: moment(new Date(faker.date.between('1975-01-01', '2005-01-05'))).format('YYYY-MM-DD').toString(),
          id_eshop: '1640963206882.0zvz0'.split('.')[0],
          id_pos: 'er5',
          firstname: 'Josue',
          phone: getRandomPhoneNumber(),
          RFM_score: `${Math.floor(Math.random() * 6) + 1},${Math.floor(Math.random() * 6) + 1},${Math.floor(Math.random() * 6) + 1}`,
          RFM_segment: 'Loyal Customers',
          cross_sell: 'Cat B, Cat I',
          discount_sensitivity: (new Array('Low', 'Low', 'Medium', 'High'))[generateRandomInteger(0, 3)],
          delay_btwn_orders: generateRandomInteger(15, 70).toString(),
          time_since_lastorder: generateRandomInteger(15, 50).toString(),
          favorite_store: 'Store C',
        },
        {
          master_id: generateId().split('.')[1] + generateId().split('.')[1],
          email: (faker.internet.email('cassandre')).toLowerCase(),
          postal_code: faker.address.zipCode(),
          birthdate: moment(new Date(faker.date.between('1975-01-01', '2005-01-05'))).format('YYYY-MM-DD').toString(),
          id_eshop: '1640963206883.0k2u6'.split('.')[0],
          id_pos: 'yh2',
          firstname: 'Cassandre',
          phone: getRandomPhoneNumber(),
          RFM_score: `${Math.floor(Math.random() * 6) + 1},${Math.floor(Math.random() * 6) + 1},${Math.floor(Math.random() * 6) + 1}`,
          RFM_segment: 'Recent Customers',
          cross_sell: 'Cat I, Cat B',
          discount_sensitivity: (new Array('Low', 'Low', 'Medium', 'High'))[generateRandomInteger(0, 3)],
          delay_btwn_orders: generateRandomInteger(15, 70).toString(),
          time_since_lastorder: generateRandomInteger(15, 50).toString(),
          favorite_store: 'Store A',
        },
        {
          master_id: generateId().split('.')[1] + generateId().split('.')[1],
          email: (faker.internet.email('odessa')).toLowerCase(),
          postal_code: faker.address.zipCode(),
          birthdate: moment(new Date(faker.date.between('1975-01-01', '2005-01-05'))).format('YYYY-MM-DD').toString(),
          id_eshop: '1640963206883.0yvse'.split('.')[0],
          id_pos: 'e2e',
          firstname: 'Odessa',
          phone: getRandomPhoneNumber(),
          RFM_score: `${Math.floor(Math.random() * 6) + 1},${Math.floor(Math.random() * 6) + 1},${Math.floor(Math.random() * 6) + 1}`,
          RFM_segment: 'Champions',
          cross_sell: 'Cat I, Cat G',
          discount_sensitivity: (new Array('Low', 'Low', 'Medium', 'High'))[generateRandomInteger(0, 3)],
          delay_btwn_orders: generateRandomInteger(15, 70).toString(),
          time_since_lastorder: generateRandomInteger(15, 50).toString(),
          favorite_store: 'Store E',
        },
        {
          master_id: generateId().split('.')[1] + generateId().split('.')[1],
          email: (faker.internet.email('roslyn')).toLowerCase(),
          postal_code: faker.address.zipCode(),
          birthdate: moment(new Date(faker.date.between('1975-01-01', '2005-01-05'))).format('YYYY-MM-DD').toString(),
          id_eshop: '1640963206884.0aoqg'.split('.')[0],
          id_pos: 'it8',
          firstname: 'Roslyn',
          phone: getRandomPhoneNumber(),
          RFM_score: `${Math.floor(Math.random() * 6) + 1},${Math.floor(Math.random() * 6) + 1},${Math.floor(Math.random() * 6) + 1}`,
          RFM_segment: 'Customers Needing Attention',
          cross_sell: 'Cat G, Cat I',
          discount_sensitivity: (new Array('Low', 'Low', 'Medium', 'High'))[generateRandomInteger(0, 3)],
          delay_btwn_orders: generateRandomInteger(15, 70).toString(),
          time_since_lastorder: generateRandomInteger(15, 50).toString(),
          favorite_store: 'Store G',
        },
        {
          master_id: generateId().split('.')[1] + generateId().split('.')[1],
          email: (faker.internet.email('stefan')).toLowerCase(),
          postal_code: faker.address.zipCode(),
          birthdate: moment(new Date(faker.date.between('1975-01-01', '2005-01-05'))).format('YYYY-MM-DD').toString(),
          id_eshop: '1640963206884.0hyjj'.split('.')[0],
          id_pos: 'et2',
          firstname: 'Stefan',
          phone: getRandomPhoneNumber(),
          RFM_score: `${Math.floor(Math.random() * 6) + 1},${Math.floor(Math.random() * 6) + 1},${Math.floor(Math.random() * 6) + 1}`,
          RFM_segment: 'Hibernating',
          cross_sell: 'Cat B, Cat I',
          discount_sensitivity: (new Array('Low', 'Low', 'Medium', 'High'))[generateRandomInteger(0, 3)],
          delay_btwn_orders: generateRandomInteger(15, 70).toString(),
          time_since_lastorder: generateRandomInteger(15, 50).toString(),
          favorite_store: 'Store E',
        },
        {
          master_id: generateId().split('.')[1] + generateId().split('.')[1],
          email: (faker.internet.email('anastacio')).toLowerCase(),
          postal_code: faker.address.zipCode(),
          birthdate: moment(new Date(faker.date.between('1975-01-01', '2005-01-05'))).format('YYYY-MM-DD').toString(),
          id_eshop: '1640963206885.09lha'.split('.')[0],
          id_pos: 'etj5',
          firstname: 'Anastacio',
          phone: getRandomPhoneNumber(),
          RFM_score: `${Math.floor(Math.random() * 6) + 1},${Math.floor(Math.random() * 6) + 1},${Math.floor(Math.random() * 6) + 1}`,
          RFM_segment: 'Champions',
          cross_sell: 'Cat G, Cat I',
          discount_sensitivity: (new Array('Low', 'Low', 'Medium', 'High'))[generateRandomInteger(0, 3)],
          delay_btwn_orders: generateRandomInteger(15, 70).toString(),
          time_since_lastorder: generateRandomInteger(15, 50).toString(),
          favorite_store: 'Store C',
        },
        {
          master_id: generateId().split('.')[1] + generateId().split('.')[1],
          email: (faker.internet.email('faye')).toLowerCase(),
          postal_code: faker.address.zipCode(),
          birthdate: moment(new Date(faker.date.between('1975-01-01', '2005-01-05'))).format('YYYY-MM-DD').toString(),
          id_eshop: '1640963206886.0dn78'.split('.')[0],
          id_pos: 'az8',
          firstname: 'Faye',
          phone: getRandomPhoneNumber(),
          RFM_score: `${Math.floor(Math.random() * 6) + 1},${Math.floor(Math.random() * 6) + 1},${Math.floor(Math.random() * 6) + 1}`,
          RFM_segment: 'Customers Needing Attention',
          cross_sell: 'Cat B, Cat I',
          discount_sensitivity: (new Array('Low', 'Low', 'Medium', 'High'))[generateRandomInteger(0, 3)],
          delay_btwn_orders: generateRandomInteger(15, 70).toString(),
          time_since_lastorder: generateRandomInteger(15, 50).toString(),
          favorite_store: 'Store A',
        },
        {
          master_id: generateId().split('.')[1] + generateId().split('.')[1],
          email: (faker.internet.email('jana')).toLowerCase(),
          postal_code: faker.address.zipCode(),
          birthdate: moment(new Date(faker.date.between('1975-01-01', '2005-01-05'))).format('YYYY-MM-DD').toString(),
          id_eshop: '1640963206886.0j0rc'.split('.')[0],
          id_pos: 'lkt8',
          firstname: 'Jana',
          phone: getRandomPhoneNumber(),
          RFM_score: `${Math.floor(Math.random() * 6) + 1},${Math.floor(Math.random() * 6) + 1},${Math.floor(Math.random() * 6) + 1}`,
          RFM_segment: 'Recent Customers',
          cross_sell: 'Cat I, Cat G',
          discount_sensitivity: (new Array('Low', 'Low', 'Medium', 'High'))[generateRandomInteger(0, 3)],
          delay_btwn_orders: generateRandomInteger(15, 70).toString(),
          time_since_lastorder: generateRandomInteger(15, 50).toString(),
          favorite_store: 'Store D',
        },
        {
          master_id: generateId().split('.')[1] + generateId().split('.')[1],
          email: (faker.internet.email('tatum')).toLowerCase(),
          postal_code: faker.address.zipCode(),
          birthdate: moment(new Date(faker.date.between('1975-01-01', '2005-01-05'))).format('YYYY-MM-DD').toString(),
          id_eshop: '1640963206887.068li'.split('.')[0],
          id_pos: 'dl8',
          firstname: 'Tatum',
          phone: getRandomPhoneNumber(),
          RFM_score: `${Math.floor(Math.random() * 6) + 1},${Math.floor(Math.random() * 6) + 1},${Math.floor(Math.random() * 6) + 1}`,
          RFM_segment: 'Can’t Lose Them',
          cross_sell: 'Cat B, Cat I',
          discount_sensitivity: (new Array('Low', 'Low', 'Medium', 'High'))[generateRandomInteger(0, 3)],
          delay_btwn_orders: generateRandomInteger(15, 70).toString(),
          time_since_lastorder: generateRandomInteger(15, 50).toString(),
          favorite_store: 'Store B',
        },
        {
          master_id: generateId().split('.')[1] + generateId().split('.')[1],
          email: (faker.internet.email('douglas')).toLowerCase(),
          postal_code: faker.address.zipCode(),
          birthdate: moment(new Date(faker.date.between('1975-01-01', '2005-01-05'))).format('YYYY-MM-DD').toString(),
          id_eshop: '1640963206888.0msz2'.split('.')[0],
          id_pos: 'dre7',
          firstname: 'Douglas',
          phone: getRandomPhoneNumber(),
          RFM_score: `${Math.floor(Math.random() * 6) + 1},${Math.floor(Math.random() * 6) + 1},${Math.floor(Math.random() * 6) + 1}`,
          RFM_segment: 'Can’t Lose Them',
          cross_sell: 'Cat G, Cat I',
          discount_sensitivity: (new Array('Low', 'Low', 'Medium', 'High'))[generateRandomInteger(0, 3)],
          delay_btwn_orders: generateRandomInteger(15, 70).toString(),
          time_since_lastorder: generateRandomInteger(15, 50).toString(),
          favorite_store: 'Store A',
        },
        {
          master_id: generateId().split('.')[1] + generateId().split('.')[1],
          email: (faker.internet.email('rosalind')).toLowerCase(),
          postal_code: faker.address.zipCode(),
          birthdate: moment(new Date(faker.date.between('1975-01-01', '2005-01-05'))).format('YYYY-MM-DD').toString(),
          id_eshop: '1640963206888.0w5oi'.split('.')[0],
          id_pos: 'ere8',
          firstname: 'Rosalind',
          phone: getRandomPhoneNumber(),
          RFM_score: `${Math.floor(Math.random() * 6) + 1},${Math.floor(Math.random() * 6) + 1},${Math.floor(Math.random() * 6) + 1}`,
          RFM_segment: 'Customers Needing Attention',
          cross_sell: 'Cat G, Cat I',
          discount_sensitivity: (new Array('Low', 'Low', 'Medium', 'High'))[generateRandomInteger(0, 3)],
          delay_btwn_orders: generateRandomInteger(15, 70).toString(),
          time_since_lastorder: generateRandomInteger(15, 50).toString(),
          favorite_store: 'Store C',
        },
        {
          master_id: generateId().split('.')[1] + generateId().split('.')[1],
          email: (faker.internet.email('dedrick')).toLowerCase(),
          postal_code: faker.address.zipCode(),
          birthdate: moment(new Date(faker.date.between('1975-01-01', '2005-01-05'))).format('YYYY-MM-DD').toString(),
          id_eshop: '1640963206889.0joyl'.split('.')[0],
          id_pos: 'ze2',
          firstname: 'Dedrick',
          phone: getRandomPhoneNumber(),
          RFM_score: `${Math.floor(Math.random() * 6) + 1},${Math.floor(Math.random() * 6) + 1},${Math.floor(Math.random() * 6) + 1}`,
          RFM_segment: "Champions",
          cross_sell: 'Cat B, Cat G',
          discount_sensitivity: (new Array('Low', 'Low', 'Medium', 'High'))[generateRandomInteger(0, 3)],
          delay_btwn_orders: generateRandomInteger(15, 70).toString(),
          time_since_lastorder: generateRandomInteger(15, 50).toString(),
          favorite_store: 'Store E',
        },
      ],
      created: new Date(),
      updatedAtId: '1639398596380.0ohfi',
    },
  },
];

audiences1[0].object.records = audiences1[0].object.records.map(r => {
  r['consent_email'] = (new Array('true', 'true', 'true', 'false'))[generateRandomInteger(0, 3)];
  r['consent_sms'] = (new Array('true', 'true', 'true', 'false'))[generateRandomInteger(0, 3)];
  r['CA Web'] = Math.round(faker.commerce.price()*generateRandomInteger(0, 3)/generateRandomInteger(1, 3));
  r['CA POS'] = Math.round(faker.commerce.price()*generateRandomInteger(0, 2)/generateRandomInteger(1, 3));
  r['email_open_12mo'] = (new Array(generateRandomInteger(0, 3), generateRandomInteger(0, 3), generateRandomInteger(4, 8), generateRandomInteger(8, 15)))[generateRandomInteger(0, 3)];
  return r
});

audiences1 = audiences1.concat(ProductAudience);
audiences1 = audiences1.concat(OrdersAudience);
audiences1 = audiences1.concat(OrdersitemsAudience);
audiences1 = audiences1.concat(ConsentsAudience);

export const audiences: Audience[] = audiences1;