// Importing type
import * as apiTypes from './types';
import { sleep } from '@utils/helpers';
import { Account } from '@features/account/types';

const getAccount: apiTypes.APIGetAccount = async (name: string) => {
  await sleep(1000);

  let account: Account;
  let error: boolean = false;
  let errorDetails: string | undefined = undefined;

  account = {
    name: 'Octolis',
    dbConfig: {
      connection: `connection2`,
      trigram: `xyz`,
    },
    accessibleFeatures: [
      'recommandations',
      'connections',
      'audiences',
      'syncs',
      'segments',
      'sources',
      'flows',
      'recipes',
      'objects',
      'sourceFlows',
      'destinations',
      'internalFlows',
      'accountManagement',
    ],
    visibleFeatures: [
      'recommandations',
      'connections',
      'audiences',
      'syncs',
      'segments',
      'sources',
      'flows',
      'recipes',
      'objects',
      'sourceFlows',
      'internalFlows',
      'destinationFlows',
      'accountManagement',
    ],
    pureReverseETL: true,
  };
  if (!account) {
    error = true;
    errorDetails = 'No account found.';
  }

  return {
    error,
    account,
    loaded: true,
    errorDetails: error && errorDetails ? errorDetails : undefined,
  };
};

const saveAccount: apiTypes.APISaveAccount = async (account: Account) => {
  await sleep(1000);

  let error: boolean = false;
  let errorDetails: string | undefined = undefined;

  if (!account) {
    error = true;
    errorDetails = 'No account found.';
  }
  return {
    account,
    error,
    loaded: true,
    errorDetails: error && errorDetails ? errorDetails : undefined,
  };
};

export { saveAccount, getAccount };
