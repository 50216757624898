import { Destination } from '@features/destinations/types';
import { integrations } from '@data/integrations';

export const destinations: Destination[] = [
  {
    key: 'destKey1',
    name: 'SendinBlue destination',
    integration: integrations[0],
  },
  {
    key: 'destKey2',
    name: 'Salesforce destination',
    integration: integrations[1],
  },
];
