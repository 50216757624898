export enum routesEnum {
  // Recommandations feature
  RECOMMANDATIONS = '/dashboard',
  // Source feature
  SOURCES = '/sources',
  // Object feature
  OBJECTS = '/objects',
  // Flows feature
  FLOWS = '/flows',
  FLOWS_SOURCES = '/flows/source',
  FLOWS_DESTINATION = '/flows/destination',
  FLOWS_INTERNAL = '/flows/internal',
  // Recipe feature
  RECIPES = '/all-recipes',
  RECIPES_EDITOR = '/recipes-editor',
  // Account managment feature
  ACCOUNT_MANAGEMENT = '/account-management',
  // Destination feature
  DESTINATION = '/destinations',
  // Sync feature
  SYNCS = '/syncs',
  SYNCS_CREATE = '/syncs/create',
  SYNCS_EDIT = '/syncs/edit',
  // Connection feature
  CONNECTONS = '/connections',
  // Audience feature
  AUDIENCES = '/audiences',
  AUDIENCES_CREATE = '/audiences/create',
  AUDIENCES_VIEW = '/audiences/view',
  AUDIENCES_PREPARE_DATA = '/audiences/prepare-data',
  // Segment feature
  SEGMENTS = '/segments',
  SEGMENTS_CREATE = '/segments/create',
  SEGMENTS_EDIT = '/segments/edit',
}
export enum queryParamKeysEnum {
  QUERY_PARAM_ELEMENT = 'e',
}
