import { createSlice } from '@reduxjs/toolkit';
import * as RecipesTypes from './types';
import { Model, Recipe } from '@features/recipes/types';
import { generateId, updateOrAddElementInArray } from '@utils/helpers';
import { QueryRun } from './types';
import { generateRecipe } from '../generateRecipe';

export interface RecipesState {
  loaded: boolean;
  isFetching?: boolean;
  error?: boolean;
  errorDetails?: string | undefined;
  models: Model[];
  recipes: Recipe[];
  activeRecipe: Recipe;
  query?: QueryRun;
}

export const INITIAL_THEME_STATE = {
  loaded: false,
  isFetching: false,
  models: [],
  activeRecipe: generateRecipe('New recipe'),
  recipes: [],
} as RecipesState;

const recipesSlice = createSlice({
  // Key name that gets added to combineReducers
  name: 'recipes',
  // Reducers object is the bread and butter of saga slice.
  // Defining a reducer also defines a type and action.
  // The type will be `source/fetchGet`, using the pattern of `{name}/{key}`
  // @@@Amine so no need to have an actions an reducers files

  initialState: INITIAL_THEME_STATE,
  reducers: {
    getModels: (state) => {
      state.isFetching = true;
    },
    getModelsSuccess: (state, { payload }: RecipesTypes.ModelsReducerActions) => {
      state.loaded = true;
      state.isFetching = false;
      state.models = payload.models;
    },
    getModelsFailed: (state, { payload }: RecipesTypes.ModelsReducerActions) => {
      state.isFetching = false;
      state.error = true;
      state.errorDetails = payload.errorDetails;
    },

    getRecipes: (state) => {
      state.isFetching = true;
    },
    getRecipesSuccess: (state, { payload }: RecipesTypes.RecipesReducerActions) => {
      state.loaded = true;
      state.isFetching = false;
      state.recipes = payload.recipes;
    },
    getRecipesFailed: (state, { payload }: RecipesTypes.RecipesReducerActions) => {
      state.isFetching = false;
      state.error = true;
      state.errorDetails = payload.errorDetails;
    },

    setActiveRecipe: (state, { payload }: { payload: { recipe: Recipe } }) => {
      state.activeRecipe = payload.recipe;
      state.query = undefined;
    },
    saveRecipe: (state) => {
      state.isFetching = true;
    },
    saveRecipeSuccess: (state, { payload }: RecipesTypes.RecipeReducerActions) => {
      state.loaded = true;
      state.isFetching = false;
      state.recipes = updateOrAddElementInArray(payload.recipe, state.recipes);
      state.activeRecipe = payload.recipe;
    },
    saveRecipeFailed: (state, { payload }: RecipesTypes.RecipeReducerActions) => {
      state.isFetching = false;
      state.error = true;
      state.errorDetails = payload.errorDetails;
    },

    saveSourceRecipe: (state) => {
      state.isFetching = true;
    },
    saveSourceRecipeSuccess: (state, { payload }: RecipesTypes.RecipeReducerActions) => {
      state.loaded = true;
      state.isFetching = false;
      state.recipes = updateOrAddElementInArray(payload.recipe, state.recipes);
    },
    saveSourceRecipeFailed: (state, { payload }: RecipesTypes.RecipeReducerActions) => {
      state.isFetching = false;
      state.error = true;
      state.errorDetails = payload.errorDetails;
    },
    deleteRecipe: (state) => {
      state.isFetching = true;
    },
    deleteRecipeSuccess: (state, { payload }: RecipesTypes.RecipeReducerActions) => {
      state.loaded = true;
      state.isFetching = false;
      state.recipes = state.recipes.filter((r) => r.key !== payload.recipe?.key);
      state.activeRecipe = generateRecipe('New recipe');
    },
    deleteRecipeFailed: (state, { payload }: RecipesTypes.RecipeReducerActions) => {
      state.isFetching = false;
      state.error = true;
      state.errorDetails = payload.errorDetails;
    },
    runQuery: (state) => {
      state.isFetching = true;
    },
    runQuerySuccess: (state, { payload }: RecipesTypes.RunQueryReducerActions) => {
      state.loaded = true;
      state.isFetching = false;
      state.query = payload.query;
    },
    runQueryFailed: (state, { payload }: RecipesTypes.RunQueryReducerActions) => {
      state.isFetching = false;
      state.error = true;
      state.errorDetails = payload.errorDetails;
    },
  },
});

export default recipesSlice;
