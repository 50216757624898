import { Position } from 'react-flow-renderer/dist/types';
import { ReactNode } from 'react';
import { Source } from '@features/sources/types';
import { OctoObject } from '@features/objects/types';
import { Recipe } from '@features/recipes/types';
import { Mapping } from '@features/syncs/types';
import { Integration } from '@features/integrations/types';
import { Connection } from '@features/connections/types';
import { Segment } from '@features/segments/types';

export type NodeType =
  | 'merge'
  | 'destination'
  | 'source'
  | 'SQL'
  | 'object'
  | 'raw'
  | 'block'
  | 'recipe'
  | 'integration'
  | 'mapping';

export interface DefaultNode {
  key: string;
  targetPosition?: Position;
  sourcePosition?: Position;
  position: any;
  label: string | JSX.Element;
  records?: number;
  element?: JSX.Element;
}
interface ConnectionNode extends DefaultNode {
  type: NodeTypes.CONNECTION;
  connection: Connection;
}
export interface SourceNode extends DefaultNode {
  type: NodeTypes.SOURCE;
  source: NodeSourceData;
}
interface ObjectNode extends DefaultNode {
  type: NodeTypes.OBJECT;
  object: OctoObject;
}
interface RecipeNode extends DefaultNode {
  type: NodeTypes.RECIPE;
  recipe: Recipe;
}
interface MappingNode extends DefaultNode {
  type: NodeTypes.MAPPING;
  mapping: Mapping[];
}
interface IntegrationNode extends DefaultNode {
  type: NodeTypes.INTEGRATION;
  integration: Integration;
}
interface DestinationNode extends DefaultNode {
  type: NodeTypes.DESTINATION;
}
interface OtherNode extends DefaultNode {
  type: NodeTypes.MERGE | 'block' | 'logo';
}
export type Node =
  | ConnectionNode
  | SourceNode
  | ObjectNode
  | RecipeNode
  | DestinationNode
  | MappingNode
  | IntegrationNode
  | OtherNode;
export interface Edge {
  key: string;
  type?: string;
  source: string;
  target: string;
  label?: string | ReactNode | JSX.Element;
  animated?: boolean;
  data?: any;
  style?: any;
}

export interface Flow {
  key: string;
  name: string;
  type?: FLOW_TYPES.INTERNAL | FLOW_TYPES.SOURCE | FLOW_TYPES.SYNC | FLOW_TYPES.SEGMENT;
  nodes: Node[];
  edges: Edge[];
}
export enum NodeTypes {
  CONNECTION = 'connection',
  SOURCE = 'source',
  OBJECT = 'object',
  RECIPE = 'recipe',
  DESTINATION = 'destination',
  INTEGRATION = 'integration',
  MAPPING = 'mapping',
  MERGE = 'merge',
}
export enum FLOW_TYPES {
  SOURCE = 'source',
  INTERNAL = 'internal',
  SYNC = 'sync',
  SEGMENT = 'segment',
}

export interface NodeSourceData {
  key: string;
  name: string;
  pointer: string;
  connection: Connection;
  // combined source properties
  combinationKey?: string;
  segment?: Segment;
  // source properties
  records?: number;
  lastRun?: string;
  status?: string[];
  active?: boolean;
  deleted?: boolean;
  schedule?: string;
  sourceObject?: OctoObject;
  targetObject?: OctoObject;
  recipe?: Recipe;
  parseOption?: {
    delimiter: string;
    quote: string;
    escape: string;
  };
  actions?: any;
}
