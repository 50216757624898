import React from 'react';
import { Button, Col, Collapse, Form, Row, Tooltip } from 'antd';
import { EditOutlined, LoginOutlined } from '@ant-design/icons';
import { OctoInput } from '@components/form';

const { Panel } = Collapse;

interface OctoPanelProps {
  name: string
  value?: string
  label: string
  disabled: boolean
  noContent?:boolean
  onClick?:()=>void
  rule?: {
    required: boolean
    message: string
  }
}

const OctoPanel = (
  {
    name,
    label,
    disabled,
    noContent,
    onClick,
    value,
    rule
  }: OctoPanelProps) => {
  return (
    <Form.Item
      label={label}
      name={name}
    >
      <Collapse
        className={noContent?"ant-collapse-no-content":undefined}
        collapsible={disabled ? 'disabled' : 'header'}
        expandIcon={() => <LoginOutlined />}
      >
        <Panel
          key="1"
          header={
            <Row>
              <Col span={12}>{value}</Col>
              <Col span={12} style={{ textAlign: 'right' }}>
                <Tooltip title="Edit">
                  <Button
                    onClick={onClick}
                    size="small"
                    shape="circle"
                    icon={<EditOutlined />}
                    hidden={disabled}
                  />
                </Tooltip>
              </Col>
            </Row>
          }
        />
      </Collapse>
    </Form.Item>
  );
};

export default OctoPanel;
