import React from 'react';
import * as apiTypes from './types';
import { SourceFlowConfig } from './types';
import { generateId, sleep } from '@utils/helpers';
import { Position } from 'react-flow-renderer';
import { Flow, NodeTypes } from '@features/flows/types';
import generateSourceFlow from '@features/flows/generators/generateSourceFlow';
import { sources } from '@data/sources';
import { recipes } from '@data/recipes';
import { objects } from '@data/objects';
import generateSyncFlow from '@features/flows/generators/generateSyncFlow';
import { syncs } from '@data/syncs';
import segments from '@data/segments';
import generateSegmentFlow from '@features/flows/generators/generateSegmentFlow';

// @@@Amine fake api calls to simulate the app

const getFlows: apiTypes.APIGetFlows = async (name: string) => {
  await sleep(2000);

  let internalFlows: Flow[];
  let sourceFlows: Flow[];
  let syncFlows: Flow[];
  let segmentFlows: Flow[];

  internalFlows = [
    {
      key: generateId(),
      name: 'default',
      nodes: [
        {
          key: '1',
          type: NodeTypes.SOURCE,
          label: 'Source 1',
          source: sources[0],
          records: 3500,
          targetPosition: Position.Left,
          sourcePosition: Position.Right,
          position: { x: 100, y: 100 },
        },
        {
          key: '2',
          type: NodeTypes.OBJECT,
          label: 'Contacts from Source 1',
          object: objects[0],
          records: 3500,
          targetPosition: Position.Left,
          sourcePosition: Position.Right,
          position: { x: 250, y: 100 },
        },
        {
          key: '3',
          type: NodeTypes.RECIPE,
          targetPosition: Position.Left,
          sourcePosition: Position.Right,
          label: 'SQL Recipe 1',
          recipe: recipes[4],
          position: { x: 400, y: 100 },
        },
        {
          key: '4',
          type: NodeTypes.OBJECT,
          label: 'Bucket 1',
          object: objects[0],
          records: 3500,
          targetPosition: Position.Left,
          sourcePosition: Position.Right,
          position: { x: 550, y: 100 },
        },
        {
          key: '5',
          type: NodeTypes.RECIPE,
          targetPosition: Position.Left,
          sourcePosition: Position.Right,
          label: 'SQL Recipe 2',
          recipe: recipes[4],
          position: { x: 700, y: 100 },
        },
        {
          key: '6',
          type: NodeTypes.OBJECT,
          label: 'Contacts',
          object: objects[0],
          records: 3500,
          targetPosition: Position.Left,
          sourcePosition: Position.Right,
          position: { x: 850, y: 100 },
        },
        {
          key: '7',
          targetPosition: Position.Left,
          sourcePosition: Position.Right,
          type: NodeTypes.DESTINATION,
          label: 'Destination Flow 1',
          position: { x: 1000, y: 100 },
        },
      ],
      edges: [
        { key: 'e1-2', source: '1', target: '2', animated: true, type: 'smoothstep' },
        { key: 'e2-3', source: '2', target: '3', animated: true, type: 'smoothstep' },
        { key: 'e3-4', source: '3', target: '4', animated: true, type: 'smoothstep' },
        { key: 'e4-5', source: '4', target: '5', animated: true, type: 'smoothstep' },
        { key: 'e5-6', source: '5', target: '6', animated: true, type: 'smoothstep' },
        { key: 'e5-7', source: '6', target: '7', animated: true, type: 'smoothstep' },
      ],
    },
  ];

  sourceFlows = [
    {
      key: generateId(),
      name: 'Source 1 flow',
      nodes: [
        {
          key: '1',
          type: NodeTypes.SOURCE,
          label: 'Source 1',
          source: sources[0],
          records: 3500,
          targetPosition: Position.Left,
          sourcePosition: Position.Right,
          position: { x: 100, y: 100 },
        },
        {
          key: '2',
          type: NodeTypes.OBJECT,
          label: 'Raw Records from Source 1',
          records: 3500,
          object: objects[11],
          targetPosition: Position.Left,
          sourcePosition: Position.Right,
          position: { x: 250, y: 100 },
        },
        {
          key: '3',
          type: NodeTypes.RECIPE,
          targetPosition: Position.Left,
          sourcePosition: Position.Right,
          label: 'DataPrep from Source 1',
          recipe: recipes[0],
          position: { x: 400, y: 100 },
        },
        {
          key: '4',
          type: NodeTypes.OBJECT,
          label: 'Contacts generated from DataPrep 1',
          object: objects[4],
          records: 3500,
          targetPosition: Position.Left,
          sourcePosition: Position.Right,
          position: { x: 550, y: 100 },
        },
      ],
      edges: [
        { key: 'e1-2', source: '1', target: '2', animated: true, type: 'smoothstep' },
        { key: 'e2-3', source: '2', target: '3', animated: true, type: 'smoothstep' },
        { key: 'e3-4', source: '3', target: '4', animated: true, type: 'smoothstep' },
      ],
    },
    {
      key: generateId(),
      name: 'Source 2 flow',
      nodes: [
        {
          key: '1',
          type: NodeTypes.SOURCE,
          label: 'Source 2',
          source: sources[1],
          records: 3500,
          targetPosition: Position.Left,
          sourcePosition: Position.Right,
          position: { x: 100, y: 100 },
        },
        {
          key: '2',
          type: NodeTypes.OBJECT,
          label: 'Raw Records from Source 2',
          object: objects[12],
          records: 3500,
          targetPosition: Position.Left,
          sourcePosition: Position.Right,
          position: { x: 250, y: 100 },
        },
        {
          key: '3',
          type: NodeTypes.RECIPE,
          targetPosition: Position.Left,
          sourcePosition: Position.Right,
          label: 'DataPrep from Source 2',
          recipe: recipes[1],
          position: { x: 400, y: 100 },
        },
        {
          key: '4',
          type: NodeTypes.OBJECT,
          label: 'Orders generated from DataPrep 2',
          object: objects[4],
          targetPosition: Position.Left,
          sourcePosition: Position.Right,
          position: { x: 550, y: 100 },
        },
      ],
      edges: [
        { key: 'e1-2', source: '1', target: '2', animated: true, type: 'smoothstep' },
        { key: 'e2-3', source: '2', target: '3', animated: true, type: 'smoothstep' },
        { key: 'e3-4', source: '3', target: '4', animated: true, type: 'smoothstep' },
      ],
    },
    {
      key: generateId(),
      name: 'Source 3 flow',
      nodes: [
        {
          key: '1',
          type: NodeTypes.SOURCE,
          label: 'Source 3',
          source: sources[2],
          records: 3500,
          targetPosition: Position.Left,
          sourcePosition: Position.Right,
          position: { x: 100, y: 100 },
        },
        {
          key: '2',
          type: NodeTypes.OBJECT,
          label: 'Raw Records from Source 3',
          records: 3500,
          object: objects[13],
          targetPosition: Position.Left,
          sourcePosition: Position.Right,
          position: { x: 250, y: 100 },
        },
        {
          key: '3',
          type: NodeTypes.RECIPE,
          targetPosition: Position.Left,
          sourcePosition: Position.Right,
          label: 'DataPrep from Source 3',
          recipe: recipes[2],
          position: { x: 400, y: 100 },
        },
        {
          key: '4',
          type: NodeTypes.OBJECT,
          label: 'Products generated from DataPrep 3',
          object: objects[2],
          records: 3500,
          targetPosition: Position.Left,
          sourcePosition: Position.Right,
          position: { x: 550, y: 100 },
        },
      ],
      edges: [
        { key: 'e1-2', source: '1', target: '2', animated: true, type: 'smoothstep' },
        { key: 'e2-3', source: '2', target: '3', animated: true, type: 'smoothstep' },
        { key: 'e3-4', source: '3', target: '4', animated: true, type: 'smoothstep' },
      ],
    },
    {
      key: generateId(),
      name: 'Source 4 flow',
      nodes: [
        {
          key: '1',
          type: NodeTypes.SOURCE,
          label: 'Source 4',
          source: sources[3],
          records: 3500,
          sourcePosition: Position.Right,
          position: { x: 100, y: 100 },
        },
        {
          key: '2',
          type: NodeTypes.OBJECT,
          label: 'Raw Records from Source 4',
          object: objects[14],
          records: 3500,
          targetPosition: Position.Left,
          sourcePosition: Position.Right,
          position: { x: 250, y: 100 },
        },
        {
          key: '3',
          type: NodeTypes.RECIPE,
          targetPosition: Position.Left,
          sourcePosition: Position.Right,
          label: 'DataPrep from Source 4',
          recipe: recipes[3],
          position: { x: 400, y: 100 },
        },
        {
          key: '4',
          type: NodeTypes.OBJECT,
          label: 'Contacts generated from DataPrep 4',
          object: objects[0],
          records: 3500,
          targetPosition: Position.Left,
          sourcePosition: Position.Right,
          position: { x: 550, y: 100 },
        },
      ],
      edges: [
        { key: 'e1-2', source: '1', target: '2', animated: true, type: 'smoothstep' },
        { key: 'e2-3', source: '2', target: '3', animated: true, type: 'smoothstep' },
        { key: 'e3-4', source: '3', target: '4', animated: true, type: 'smoothstep' },
      ],
    },
  ]; // @@@Amine for now source flows will created once a source doesn't have a source flow
  syncFlows = [
    {
      key: generateId(),
      name: syncs[0].name,
      ...generateSyncFlow(syncs[0]),
    },
  ];
  segmentFlows = [
    {
      ...generateSegmentFlow(segments[0]),
      name: segments[0].name,
    },
  ];

  return {
    internalFlows,
    sourceFlows,
    segmentFlows,
    syncFlows,
    loaded: true,
  };
};
const saveSourceFlow: apiTypes.APISaveSourceFlow = async (sourceFlowConfig: SourceFlowConfig) => {
  await sleep(100);

  let sourceFlow: Flow;
  let error: boolean = false;
  let errorDetails: string | undefined = undefined;

  sourceFlow = {
    key: sourceFlowConfig.key,
    name: sourceFlowConfig.name,
    ...generateSourceFlow({
      source: sourceFlowConfig.source,
      recipe: sourceFlowConfig.recipe,
      recipeObject: sourceFlowConfig.recipeObject,
      sourceObject: sourceFlowConfig.sourceObject,
    }),
  };
  if (!sourceFlow) {
    error = true;
    errorDetails = 'No sourceFlow to save.';
  }
  return {
    sourceFlow,
    error,
    loaded: true,
    errorDetails: error && errorDetails ? errorDetails : undefined,
  };
};
const saveInternalFlow: apiTypes.APISaveInternalFlow = async (internalFlow: Flow) => {
  await sleep(1500);

  let error: boolean = false;
  let errorDetails: string | undefined = undefined;

  if (!internalFlow) {
    error = true;
    errorDetails = 'No internalFlow to save.';
  }
  return {
    internalFlow,
    error,
    loaded: true,
    errorDetails: error && errorDetails ? errorDetails : undefined,
  };
};
const deleteInternalFlow: apiTypes.APIDeleteInternalFlow = async (internalFlow: Flow) => {
  await sleep(1500);

  let error: boolean = false;
  let errorDetails: string | undefined = undefined;

  if (!internalFlow) {
    error = true;
    errorDetails = 'No internalFlow to delete.';
  }
  return {
    internalFlow,
    error,
    loaded: true,
    errorDetails: error && errorDetails ? errorDetails : undefined,
  };
};

export { getFlows, deleteInternalFlow, saveSourceFlow, saveInternalFlow };
