import { takeLatest, call, put } from 'redux-saga/effects';
// api
import * as API from './api';
// types
import * as types from './types';
import flowsSlice  from '@features/flows/ducks/flowsSlice';

function* getFlowsSaga({ payload }: types.GetFlowsSaga) {
  try {
    const { app } = payload;
    const getFlowsPayload: types.APIGetFlowsPayload = yield call(API.getFlows,app);
    yield put({ type: flowsSlice.actions.getFlowsSuccess.type, payload: getFlowsPayload });
  } catch (error) {
    yield put({ type: flowsSlice.actions.getFlowsFailed.type, payload: error });
  }
}

function* saveSourceFlowSaga({ payload }: types.SaveSourceFlowSaga) {
  try {
    const {sourceFlowConfig} = payload
    const saveFlowPayload: types.APISaveSourceFlowPayload = yield call(API.saveSourceFlow,sourceFlowConfig);
    yield put({ type: flowsSlice.actions.saveSourceFlowSuccess.type, payload: saveFlowPayload });
    yield put({ type: flowsSlice.actions.setActiveSourceFlow.type, payload: saveFlowPayload });
  } catch (error) {
    yield put({ type: flowsSlice.actions.saveSourceFlowFailed.type, payload: error });
  }
}
function* saveInternalFlowSaga({ payload }: types.SaveInternalFlowSaga) {
  try {
    const { internalFlow } = payload;
    const saveFlowPayload: types.APISaveInternalFlowPayload = yield call(API.saveInternalFlow,internalFlow);
    yield put({ type: flowsSlice.actions.saveInternalFlowSuccess.type, payload: saveFlowPayload });
  } catch (error) {
    yield put({ type: flowsSlice.actions.saveInternalFlowFailed.type, payload: error });
  }
}
function* deleteInternalFlowSaga({ payload }: types.DeleteInternalFlowSaga) {
  try {
    const { internalFlow } = payload;
    const deleteFlowPayload: types.APISaveInternalFlowPayload = yield call(API.deleteInternalFlow,internalFlow);
    yield put({ type: flowsSlice.actions.deleteInternalFlowSuccess.type, payload: deleteFlowPayload });
  } catch (error) {
    yield put({ type: flowsSlice.actions.deleteInternalFlowFailed.type, payload: error });
  }
}
export const flowsSagas = [
  takeLatest(flowsSlice.actions.getFlows.type, getFlowsSaga),
  takeLatest(flowsSlice.actions.deleteInternalFlow.type, deleteInternalFlowSaga),
  takeLatest(flowsSlice.actions.saveSourceFlow.type, saveSourceFlowSaga),
  takeLatest(flowsSlice.actions.saveInternalFlow.type, saveInternalFlowSaga),
];
