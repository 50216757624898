import React from 'react';
import { generateId } from '@utils/helpers';
import { Position } from 'react-flow-renderer';
import { Source } from '@features/sources/types';
import { Node, NodeTypes } from '@features/flows/types';
import { Recipe } from '@features/recipes/types';
import { OctoObject } from '@features/objects/types';
import generateObject from '@features/objects/generateObject';

type generateSourceFlowProps = {
  source: Source;
  recipe: Recipe;
  sourceObject?: OctoObject;
  recipeObject?: OctoObject;
};
const generateSourceFlow = ({
  source,
  recipe,
  sourceObject,
  recipeObject,
}: generateSourceFlowProps) => {
  const sourceNodeId = generateId();
  const sourceNode: Node = {
    source,
    key: sourceNodeId,
    type: NodeTypes.SOURCE,
    label: source.name,
    records: source.records,
    sourcePosition: Position.Right,
    position: { x: 100, y: 100 },
  };
  const sourceObjectNodeId = generateId();
  const sourceObjectNode: Node = {
    key: sourceObjectNodeId,
    type: NodeTypes.OBJECT,
    targetPosition: Position.Left,
    sourcePosition: Position.Right,
    label: sourceObject ? sourceObject.name : 'No object',
    object: sourceObject ? sourceObject : generateObject('no object'),
    position: { x: 300, y: 100 },
  };
  const sqlNodeId = generateId();
  const sqlNode: Node = {
    recipe,
    key: sqlNodeId,
    type: NodeTypes.RECIPE,
    targetPosition: Position.Left,
    sourcePosition: Position.Right,
    label: recipe.name,
    position: { x: 500, y: 100 },
  };
  const recipeObjectNodeId = generateId();
  const recipeObjectNode: Node = {
    key: recipeObjectNodeId,
    type: NodeTypes.OBJECT,
    targetPosition: Position.Left,
    sourcePosition: Position.Right,
    label: recipeObject ? recipeObject.name : 'No object',
    object: recipeObject ? recipeObject : generateObject('no object'),
    position: { x: 700, y: 100 },
  };
  const sourceToObjectEdge = {
    key: generateId(),
    source: sourceNodeId,
    target: sourceObjectNodeId,
    animated: true,
    type: 'smoothstep',
  };
  const objectToRecipeEdge = {
    key: generateId(),
    source: sourceObjectNodeId,
    target: sqlNodeId,
    animated: true,
    type: 'smoothstep',
  };
  const SQlToObjectEdge = {
    key: generateId(),
    source: sqlNodeId,
    target: recipeObjectNodeId,
    animated: true,
    type: 'smoothstep',
  };
  return {
    nodes: [sourceNode, sourceObjectNode, sqlNode, recipeObjectNode],
    edges: [objectToRecipeEdge, SQlToObjectEdge, sourceToObjectEdge],
  };
};

export default generateSourceFlow;
