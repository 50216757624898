import {ModifiedSQLHint} from './codeMirrorExtentions';
import 'codemirror/mode/sql/sql'; // SQL-Language Support
import 'codemirror/mode/javascript/javascript'; // SQL-Language Support
import 'codemirror/addon/edit/matchbrackets'; // Brackets Addon
import 'codemirror/addon/hint/show-hint'; // Hinter support
import 'codemirror/addon/lint/lint'; // Linter support
import {CustomSqlHinter} from "./customSQLHinter";
const CodeMirror = require('codemirror')

// Going to be replaced with a custom one
CodeMirror.registerHelper('hint', 'sql', CustomSqlHinter);

// Note: NO LINTER FOR SQL THAT WOULD SUPPORT ALL LANGUAGES -> Sending to the server

// Attaching extra functionality
// Runs after compilation is complete
window.onload = () => {
  // Extending SQL autosuggestion
  CodeMirror.hint.sql = ModifiedSQLHint(CodeMirror.hint.sql);
};
