const CodeMirror = require('codemirror');

let tables: any;
let defaultTable: any;
let keywords;
let identifierQuote: any;
const CONS = {
  QUERY_DIV: ';',
  ALIAS_KEYWORD: 'AS'
};
const Pos = CodeMirror.Pos;
const cmpPos = CodeMirror.cmpPos;

function isArray(val: any) {
  return Object.prototype.toString.call(val) === '[object Array]';
}

function getKeywords(editor: any) {
  let mode = editor.doc.modeOption;
  if (mode === 'sql') mode = 'text/x-sql';
  return CodeMirror.resolveMode(mode).keywords;
}

function getIdentifierQuote(editor: any) {
  let mode = editor.doc.modeOption;
  if (mode === 'sql') mode = 'text/x-sql';
  return CodeMirror.resolveMode(mode).identifierQuote || '`';
}

function getText(item: any) {
  return typeof item === 'string' ? item : item.text;
}

function wrapTable(name: string, value: any) {
  let Value: any;
  if (isArray(value)) Value = { columns: value };
  if (!value.text) value.text = name;
  return Value;
}

function parseTables(input: any) {
  const result: any = {};
  if (isArray(input)) {
    for (let i = input.length - 1; i >= 0; i--) {
      const item = input[i];
      result[getText(item).toUpperCase()] = wrapTable(getText(item), item);
    }
  } else if (input) {
    for (const name in input) {
      result[name.toUpperCase()] = wrapTable(name, input[name]);
    }
  }
  return result;
}

function getTable(name: string) {
  return tables[name.toUpperCase()];
}

function shallowClone(object: any) {
  const result: any = {};
  for (const key in object) {
    if (object.hasOwnProperty(key)) result[key] = object[key];
  }
  return result;
}

function match(str:string, word:string) {
  const len = str.length;
  const sub = getText(word).substr(0, len);

  // MATCHER (SUBSTRING)
  return str.toUpperCase() === sub.toUpperCase();
}

function addMatches(result:any, search:any, wordlist:any, formatter:any) {
  // MATCHING ALGORITHM
  // Result: List for adding matches to
  // Search: Sub-String of a Token entered by user (Cut fro the beginning until the cursor)
  // Wordlist: List of ALL possible strings to match
  // Formatter: Function for adding extra formatting to the matched words from the wordlist
  //    - Do NOT MODIFY (CODEMIRROR-SPECIFIC) -> Produces objects
  //    => Modify keywords / special words manually without using the formatter

  if (isArray(wordlist)) {
    for (let i = 0; i < wordlist.length; i++) {
      if (match(search, wordlist[i])) result.push(formatter(wordlist[i]));
    }
  } else {
    for (const word in wordlist) {
      if (wordlist.hasOwnProperty(word)) {
        let val = wordlist[word];
        if (!val || val === true) val = word;
        else {
          val = val.displayText ? { text: val.text, displayText: val.displayText } : val.text;
        };
        if (match(search, val)) result.push(formatter(val));
      }
    }
  }
}

function cleanName(Name:string) {
  let name = Name
  // Get rid name from identifierQuote and preceding dot(.)
  if (name.charAt(0) === '.') {
    name = name.substr(1);
  }
  // replace duplicated identifierQuotes with single identifierQuotes
  // and remove single identifierQuotes
  const nameParts = name.split(identifierQuote + identifierQuote);
  for (let i = 0; i < nameParts.length; i++) {
    nameParts[i] = nameParts[i].replace(new RegExp(identifierQuote, 'g'), '');
  }
  return nameParts.join(identifierQuote);
}

function insertIdentifierQuotes(Name:any) {
  let name=Name
  const nameParts = getText(name).split('.');
  for (let i = 0; i < nameParts.length; i++) {
    nameParts[i] = identifierQuote +
      // duplicate identifierQuotes
      nameParts[i].replace(new RegExp(identifierQuote, 'g'), identifierQuote + identifierQuote) +
      identifierQuote;
  }
  const escaped = nameParts.join('.');
  if (typeof name === 'string') return escaped;
  name = shallowClone(name);
  name.text = escaped;
  return name;
}

// Special auto-completion for names
function nameCompletion(cur:any, Token:any, result:any, editor:any) {
  let token = Token
  // Try to complete table, column names and return start position of completion
  let useIdentifierQuotes = false;
  const nameParts = [];
  let start = token.start;
  let cont = true;
  while (cont) {
    cont = (token.string.charAt(0) === '.');
    useIdentifierQuotes = useIdentifierQuotes || (token.string.charAt(0) === identifierQuote);

    start = token.start;
    nameParts.unshift(cleanName(token.string));

    token = editor.getTokenAt(Pos(cur.line, token.start));
    if (token.string === '.') {
      cont = true;
      token = editor.getTokenAt(Pos(cur.line, token.start));
    }
  }

  // Try to complete table names
  let str:string|undefined = nameParts.join('.');
  addMatches(result, str, tables, (w:any) => {
    return useIdentifierQuotes ? insertIdentifierQuotes(w) : w;
  });

  // Try to complete columns from defaultTable
  addMatches(result, str, defaultTable, (w:any)=> {
    return useIdentifierQuotes ? insertIdentifierQuotes(w) : w;
  });

  // Try to complete columns
  str = nameParts.pop();
  let table = nameParts.join('.');

  let alias = false;
  const aliasTable = table;
  // Check if table is available. If not, find table by Alias
  if (!getTable(table)) {
    const oldTable = table;
    table = findTableByAlias(table, editor);
    if (table !== oldTable) alias = true;
  }

  let columns = getTable(table);
  if (columns && columns.columns) columns = columns.columns;

  if (columns) {
    addMatches(result, str, columns, (w:any) =>{
      let word = w
      let tableInsert = table;
      if (alias) tableInsert = aliasTable;
      if (typeof word === 'string') {
        word = tableInsert + '.' + w;
      } else {
        word = shallowClone(w);
        word.text = tableInsert + '.' + w.text;
      }
      return useIdentifierQuotes ? insertIdentifierQuotes(word) : word;
    });
  }

  return start;
}

function eachWord(lineText:any, f:any) {
  const words = lineText.split(/\s+/);
  for (let i = 0; i < words.length; i++) {
    if (words[i]) f(words[i].replace(/[`,;]/g, ''));
  }
}

function findTableByAlias(alias:any, editor:any) {
  const doc = editor.doc;
  const fullQuery = doc.getValue();
  const aliasUpperCase = alias.toUpperCase();
  let previousWord = '';
  let table = '';
  const separator = [];
  let validRange = {
    start: Pos(0, 0),
    end: Pos(editor.lastLine(), editor.getLineHandle(editor.lastLine()).length)
  };

  // add separator
  let indexOfSeparator = fullQuery.indexOf(CONS.QUERY_DIV);
  while (indexOfSeparator !== -1) {
    separator.push(doc.posFromIndex(indexOfSeparator));
    indexOfSeparator = fullQuery.indexOf(CONS.QUERY_DIV, indexOfSeparator + 1);
  }
  separator.unshift(Pos(0, 0));
  separator.push(Pos(editor.lastLine(), editor.getLineHandle(editor.lastLine()).text.length));

  // find valid range
  let prevItem = null;
  const current = editor.getCursor();
  for (let i = 0; i < separator.length; i++) {
    if ((prevItem == null || cmpPos(current, prevItem) > 0) && cmpPos(current, separator[i]) <= 0) {
      validRange = { start: prevItem, end: separator[i] };
      break;
    }
    prevItem = separator[i];
  }

  if (validRange.start) {
    const query = doc.getRange(validRange.start, validRange.end, false);

    for (let i = 0; i < query.length; i++) {
      const lineText = query[i];

      // eslint-disable-next-line
      eachWord(lineText, (word:string) => {
        const wordUpperCase = word.toUpperCase();
        if (wordUpperCase === aliasUpperCase && getTable(previousWord)) table = previousWord;
        if (wordUpperCase !== CONS.ALIAS_KEYWORD) previousWord = word;
      });

      if (table) break;
    }
  }
  return table;
}

export const CustomSqlHinter = (editor:any, options:any) => {
  // OPTIONS PARSING

  tables = parseTables(options && options.tables);
  const defaultTableName = options && options.defaultTable;
  const disableKeywords = options && options.disableKeywords;
  defaultTable = defaultTableName && getTable(defaultTableName);
  keywords = getKeywords(editor);
  identifierQuote = getIdentifierQuote(editor);

  if (defaultTableName && !defaultTable) defaultTable = findTableByAlias(defaultTableName, editor);

  defaultTable = defaultTable || [];

  if (defaultTable.columns) defaultTable = defaultTable.columns;

  // TOKENIZATION

  const cur = editor.getCursor();
  const result:any = [];
  const token = editor.getTokenAt(cur)
  let start, end, search;
  if (token.end > cur.ch) {
    token.end = cur.ch;
    token.string = token.string.slice(0, cur.ch - token.start);
  }

  if (token.string.match(/^[.`"'\w@][\w$#]*$/g)) {
    search = token.string;
    start = token.start;
    end = token.end;
  } else {
    start = end = cur.ch;
    search = '';
  }

  // Search: Token from the start util user's cursor
  // Start: String-Index of Token-Start on the respective line
  // End: String-Index of Token-End on the respective line

  // ACTUAL MATCH-ADDING BELOW (Not using Token)

  // Dot / Special Quote -> Matching against columns directly
  if (search.charAt(0) === '.' || search.charAt(0) === identifierQuote) {
    start = nameCompletion(cur, token, result, editor);
  } else {
    const objectOrClass = function(w:any, className:any) {
      let word = w
      if (typeof word === 'object') {
        word.className = className;
      } else {
        word= {  className, text: word};
      }
      return w;
    };

    // Matching against columns in the default table
    addMatches(result, search, defaultTable, (w:string) =>{
      return objectOrClass(w, 'CodeMirror-hint-table CodeMirror-hint-default-table');
    });

    // console.log('Tables:', tables);

    // // Matching against table-names
    // addMatches(
    //   result,
    //   search,
    //   tables, (w:string) => {
    //     return objectOrClass(w, 'CodeMirror-hint-table');
    //   }
    // );
    if (!disableKeywords)
      addMatches(result, search, keywords, (w:string) => {
        return objectOrClass(w.toUpperCase(), 'CodeMirror-hint-keyword');
      });
  }

  // Returning
  //  1) List of suggestions
  //  2) Absolute position of start (NOT line-based)
  //  3) Absolute position of end (NOT line-based)
  return { list: result, from: Pos(cur.line, start), to: Pos(cur.line, end) };
}
