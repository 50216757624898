// Importing React
import React, { useEffect, useState } from 'react';
// Importing types & child components
import DataExplorer from '@features/recipes/DataExplorer';
import { Alert, Button, Col, Collapse, Form, Row, Space, Tabs, Typography } from 'antd';
import OctoButton from '../../components/form/items/OctoButtonItems';
import { CodeMirrorComponent, useOctoCodeMirror } from '@components/editor';
import { ReducerStates } from '@redux/reducers';
import { ThunkDispatch } from '@reduxjs/toolkit';
import { bindActionCreators } from 'redux';
import recipesSlice, { RecipesState } from '@features/recipes/ducks/recipesSlice';
import { connect } from 'react-redux';
import './styles.less';
import { OctoInput } from '../../components/form';
import { EditFilled, PlusOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { Model, Recipe } from '@features/recipes/types';
import QueryResultsTable from '@features/recipes/QueryResultsTable';
import { OctoObject, SchemaColumn } from '@features/objects/types';
import { OctoDrawer, useOctoDrawer } from '@components/drawer';
import ModelSelector from '@features/recipes/ModelSelector';
import { generateId } from '@utils/helpers';

const { TabPane } = Tabs;
const { Panel } = Collapse;

function getRecipesState(state: ReducerStates) {
  return state.recipes;
}

function getObjectsState(state: ReducerStates) {
  return state.objects.objects;
}

const mapStateToProps = (state: ReducerStates) => {
  return {
    state: getRecipesState(state),
    objects: getObjectsState(state),
  };
};

const mapDispatchToProps: (dispatch: ThunkDispatch<any, any, any>) => void = (dispatch) => {
  return {
    saveRecipe: bindActionCreators(recipesSlice.actions.saveRecipe, dispatch),
    deleteRecipe: bindActionCreators(recipesSlice.actions.deleteRecipe, dispatch),
    setActiveRecipe: bindActionCreators(recipesSlice.actions.setActiveRecipe, dispatch),
    runQuery: bindActionCreators(recipesSlice.actions.runQuery, dispatch),
  };
};

export const RECIPE_CREATED_IN = {
  SOURCE: 'source',
  FLOW: 'flow',
};

interface RecipesContainer {
  state: RecipesState;
  objects: OctoObject[];
  getRecipes?: () => void;
  createRecipeFor?: 'source' | 'flow';
  saveRecipe?: (p: any) => void;
  deleteRecipe?: (p: any) => void;
  setActiveRecipe?: (p: any) => void;
  runQuery?: (p: { recipe: Recipe }) => void;
}

const Editors = {
  SQL: 'sql',
  JAVASCRIPT: 'js',
};
const RecipesContainer = ({
  state,
  objects,
  saveRecipe,
  deleteRecipe,
  createRecipeFor,
  setActiveRecipe,
  runQuery,
}: RecipesContainer) => {
  const [form] = Form.useForm();
  const octoSQLCodeMirror = useOctoCodeMirror();
  const octoJSCodeMirror = useOctoCodeMirror();
  const octoDrawer = useOctoDrawer();
  const [isEditingName, setIsEditingName] = useState(false);
  const [recipeName, setRecipeName] = useState(state.activeRecipe.name);
  const [activeEditor, setActiveEditor] = useState(Editors.SQL);
  const [activeModel, setActiveModel] = useState<Model | null>();
  const [openContextCollapse, setOpenContextCollapse] = useState(false);

  useEffect(() => {
    octoSQLCodeMirror.putRecipe(state.activeRecipe?.query);
    if (state.activeRecipe?.context) octoJSCodeMirror.putRecipe(state.activeRecipe?.context);
  }, [state]);
  useEffect(() => {
    octoSQLCodeMirror.putTables({ ...getRecipeOrigins(), ...getRecipeTargets() });
  }, [state.activeRecipe]);

  const getRecipeOrigins = () => {
    const origins = state.activeRecipe.origins;
    return origins.reduce((accumulator: any, current) => {
      return {
        ...accumulator,
        [current.name]: current.schema,
      };
    }, {});
  };
  const getRecipeTargets = () => {
    const targets = state.activeRecipe.targets;
    return targets.reduce((accumulator: any, current) => {
      return {
        ...accumulator,
        [current.name]: current.schema,
      };
    }, {});
  };
  const selectModel = (model: Model) => {
    if (setActiveRecipe) {
      setOpenContextCollapse(true);
      octoJSCodeMirror.putRecipe(model.context);
      setActiveModel(model);
      setActiveRecipe({
        recipe: {
          key: generateId(),
          query: model.query,
          name: state.activeRecipe.name,
          context: model.context,
          model: model.key,
          origins: state.activeRecipe.origins ? state.activeRecipe.origins : [],
          targets: state.activeRecipe.targets ? state.activeRecipe.targets : [],
        },
      });
      octoDrawer.onClose();
    }
  };
  const openModelSelector = () => {
    octoDrawer.putContent(
      <ModelSelector cancel={octoDrawer.onClose} models={state.models} selectModel={selectModel} />
    );
  };
  const runActiveQuery = () => {
    const val = octoSQLCodeMirror.getValue();
    if (runQuery) {
      runQuery({
        recipe: { ...state.activeRecipe, query: val },
      });
    }
  };
  const handleCancel = () => {
    octoSQLCodeMirror.putRecipe('');
  };
  const handleSave = (values: any) => {
    if (saveRecipe) {
      saveRecipe({
        recipeConfig: {
          ...state.activeRecipe,
          name: values.name,
          query: octoSQLCodeMirror.getValue(),
          context: octoJSCodeMirror.getValue(),
          origins: state.activeRecipe.origins ? state.activeRecipe.origins : [],
          targets: state.activeRecipe.targets ? state.activeRecipe.targets : [],
        },
      });
    }
  };
  const getInsertTextFunctionOfActiveEditor = () => {
    if (activeEditor === Editors.SQL) {
      return octoSQLCodeMirror.putText;
    }
    if (activeEditor === Editors.JAVASCRIPT) {
      return octoJSCodeMirror.putText;
    }
    return () => {};
  };
  const deleteActiveRecipe = () => {
    if (deleteRecipe) {
      setRecipeName('New');
      deleteRecipe({ recipe: state.activeRecipe });
    }
  };

  return (
    <div style={{ backgroundColor: '#f5f6fa', padding: 30 }}>
      <Form
        form={form}
        name={'form-create'}
        onFinish={handleSave}
        initialValues={{ name: state.activeRecipe.name }}
      >
        <Row style={{ height: 55 }}>
          <Col span={20}>
            {isEditingName ? (
              <div onBlur={() => setIsEditingName(false)}>
                <OctoInput
                  name={'name'}
                  disabled={false}
                  onChange={setRecipeName}
                  rule={{ required: true, message: `Please enter a name for the recipe!` }}
                />
              </div>
            ) : (
              <div onClick={() => setIsEditingName(true)} style={{ cursor: 'pointer' }}>
                <OctoInput
                  name={'name'}
                  disabled={false}
                  hidden={true}
                  onChange={setRecipeName}
                  rule={{ required: true, message: `Please enter a name for the recipe!` }}
                />

                <h3 style={{ marginBottom: 10 }}>
                  {recipeName} <EditFilled style={{ fontSize: 20 }} />
                </h3>
              </div>
            )}
          </Col>
          <Col style={{ textAlign: 'right' }} span={4}>
            {activeModel ? (
              <>
                <Button type="default" style={{ marginLeft: 4 }}>
                  {activeModel.title}
                  <CloseCircleOutlined onClick={() => setActiveModel(undefined)} />
                </Button>
              </>
            ) : (
              <Button
                type="text"
                className="octolis__default-btn"
                icon={<PlusOutlined />}
                onClick={openModelSelector}
              >
                Use model
              </Button>
            )}
          </Col>
        </Row>
        <Row>
          <Col span={5}>
            <Collapse defaultActiveKey={[1, 2]} accordion={false}>
              <Panel key={1} header={<h3>Available data origins</h3>} collapsible={'header'}>
                <div style={{ marginLeft: '10px' }}>
                  <DataExplorer
                    expanded={false}
                    loading={state.isFetching}
                    insertText={getInsertTextFunctionOfActiveEditor()}
                    tables={getRecipeOrigins()}
                  />
                </div>
              </Panel>
              <Panel key={2} header={<h3>Available data target</h3>} collapsible={'header'}>
                <div style={{ marginLeft: '10px' }}>
                  <DataExplorer
                    expanded={false}
                    loading={state.isFetching}
                    insertText={getInsertTextFunctionOfActiveEditor()}
                    tables={getRecipeTargets()}
                  />
                </div>
              </Panel>
            </Collapse>
          </Col>
          <Col span={19} style={{ paddingLeft: 20 }}>
            <div
              style={{ border: '2px solid #f2f2f2', width: '100%' }}
              onKeyDown={(e) => {
                octoSQLCodeMirror.showHint();
              }}
            >
              <CodeMirrorComponent
                onFocus={() => setActiveEditor('sql')}
                mode={'sql'}
                {...octoSQLCodeMirror}
              />
            </div>
            <Collapse
              style={{ margin: '30px 0' }}
              defaultActiveKey={openContextCollapse ? 1 : -1}
              accordion={true}
            >
              <Panel key={1} header={<h4>Context</h4>}>
                <CodeMirrorComponent
                  onFocus={() => setActiveEditor('js')}
                  mode={'javascript'}
                  {...octoJSCodeMirror}
                />
              </Panel>
            </Collapse>
            <Row>
              <Col span={20} />
              <Col style={{ textAlign: 'right' }} span={4}>
                <Button type="text" className="octolis__primary-btn" onClick={runActiveQuery}>
                  Test query
                </Button>
              </Col>
            </Row>
            <div style={{ margin: '30px 0' }}>
              <Alert
                type={state.query ? (state.query.runSuccess ? 'success' : 'error') : 'warning'}
                message={
                  state.query
                    ? state.query.runSuccess
                      ? 'Query run with Success'
                      : 'Query failed'
                    : 'Run query'
                }
              />
            </div>
            <Tabs defaultActiveKey="1" type="card">
              <TabPane tab="Results" key="1">
                <QueryResultsTable records={state.query?.results} loading={state.isFetching} />
              </TabPane>
              <TabPane tab="Compiled SQL" key="2">
                <div style={{ padding: '20px' }}>
                  {state.query ? state.query.compiledCode : 'Please execute query'}
                </div>
              </TabPane>
              <TabPane tab="Logs" key="3">
                <div style={{ padding: '20px' }}>
                  {state.query
                    ? state.query.logs.map((log) => {
                        return (
                          <>
                            <h4>{log.status}</h4>
                            <h4>{log.message}</h4>
                          </>
                        );
                      })
                    : 'Please execute query'}
                </div>
              </TabPane>
            </Tabs>
            <div style={{ margin: '20px 0' }}>
              <Alert
                type={state.query?.runSuccess ? 'success' : 'warning'}
                message={
                  state.query?.runSuccess
                    ? 'You can save your recipe now'
                    : 'Query must be tested with success'
                }
              />
            </div>
            <Row>
              <Col span={10} style={{ textAlign: 'left' }}>
                {createRecipeFor !== 'source' && (
                  <OctoButton
                    popConfirmConfig={{
                      title: 'confirmDeletion',
                      okText: 'yes',
                      cancelText: 'no',
                    }}
                    buttonConfig={{
                      type: 'dashed',
                      danger: true,
                    }}
                    label={'delete'}
                    onClick={deleteActiveRecipe}
                  />
                )}
              </Col>
              <Col span={8} style={{ textAlign: 'left' }} />
              <Col span={6} style={{ textAlign: 'right' }}>
                <Space direction="horizontal">
                  <OctoButton
                    popConfirmConfig={{
                      title: `Confirm Cancel`,
                      okText: `yes`,
                      cancelText: `no`,
                    }}
                    label={`cancel`}
                    onClick={handleCancel}
                  />

                  <Form.Item>
                    <Button
                      type="text"
                      className="octolis__primary-btn"
                      htmlType="submit"
                      disabled={!state.query?.runSuccess}
                    >
                      Save
                    </Button>
                  </Form.Item>
                </Space>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>

      <OctoDrawer {...octoDrawer} />
    </div>
  );
};

export const RecipesComponent = connect(mapStateToProps, mapDispatchToProps)(RecipesContainer);

const RecipesScreen: React.FC = () => {
  return <RecipesComponent />;
};
export default RecipesScreen;
