import { generateObjectFromRecords } from '@features/objects/generateObject';
import { OctoObject } from '@features/objects/types';

import { generateRandomInteger, sleep } from '@utils/helpers';
import { joinSegmentSources } from './generateSegment';
import { MatchingConfig, Segment } from '../types';
import * as apiTypes from './types';
import segments from '@data/segments';
const getSegments: apiTypes.APIGetSegments = async () => {
  let error: boolean = false;
  let errorDetails: string | undefined = undefined;

  if (!segments) {
    error = true;
    errorDetails = 'No Syncs Found';
  }
  return {
    segments,
    error,
    errorDetails,
    loaded: true,
  };
};
//case new condition / condition in group / update condition
const saveSegment: apiTypes.APISaveSegment = async (segment: Segment) => {
  await sleep(500);
  let error: boolean = false;
  let errorDetails: string | undefined = undefined;
  if (!segment) {
    error = true;
    errorDetails = 'No segment to save.';
  }
  if (!segment.combinations[0].object) {
    segment.combinations = [];
  }
  return {
    error,
    segment: segment,
    loaded: true,
    errorDetails: error && errorDetails ? errorDetails : undefined,
  };
};

const runSegmentTargetObject: apiTypes.APIRunSegmentTargetObject = async (
  activeSegment: Segment
) => {
  await sleep(1500);
  let segment: Segment;
  let targetObject: OctoObject;
  let error: boolean = false;
  let errorDetails: string | undefined = undefined;
  const sortedCombinationsByLevels = activeSegment.combinations.sort(
    (a, b) => a.combinationLevel - b.combinationLevel
  );
  const combination = sortedCombinationsByLevels[activeSegment.combinations.length - 1];
  if (activeSegment.source?.targetObject) {
    const { records, schema, primaryKeys } = joinSegmentSources(
      activeSegment.source.targetObject,
      combination
    );
    targetObject = generateObjectFromRecords(
      `${activeSegment.targetObject?.tableName}__${combination.pointer}`,
      `${activeSegment.targetObject?.tableName}__${combination.pointer}`,
      activeSegment.source.targetObject.pointer,
      'default',
      schema,
      primaryKeys,
      records.length,
      records
    );

    if (!activeSegment) {
      error = true;
      errorDetails = 'No columns to check.';
    }
    segment = { ...activeSegment, targetObject: targetObject };
    return {
      error,
      segment,
      loaded: true,
      errorDetails: error && errorDetails ? errorDetails : undefined,
    };
  } else
    return {
      error,
      loaded: true,
      errorDetails: error && errorDetails ? errorDetails : undefined,
    };
};
const checkColumnsMatch: apiTypes.APICheckColumnsMatch = async (match: MatchingConfig) => {
  await sleep(500);
  let error: boolean = false;
  let errorDetails: string | undefined = undefined;
  if (!match.sourceObject.pointer || !match.sourceObject.pointer) {
    error = true;
    errorDetails = 'No pointers to check.';
  }
  if (!match.sourceColumn || !match.combinationColumn) {
    error = true;
    errorDetails = 'No columns to check.';
  }

  return {
    error,
    match: {
      ...match,
      result: generateRandomInteger(0, 100),
    },
    loaded: true,
    errorDetails: error && errorDetails ? errorDetails : undefined,
  };
};

export { saveSegment, checkColumnsMatch, runSegmentTargetObject, getSegments };
