import React, { createContext } from 'react';
import { AppContextType } from '@features/appContext/types';
import { ReducerStates } from '@redux/reducers';
import { connect } from 'react-redux';
import { Account } from '@features/account/types';

export const AppContext = createContext<AppContextType>({
  layoutPreferences: {
    sideBarCollapsed: false,
  },
  language: 'en',
  labels: {},
  accessibleFeatures: [],
  visibleFeatures: [],
  i18nlabels: {},
  theme: {
    name: '',
    variables: {},
  },
});
const mapStateToProps = (state: ReducerStates) => {
  return {
    appContext: state.appContext.appContext,
    account: state.account.account,
  };
};
// @@@Amine connect the context to the state
const AppContextContainer = ({
  children,
  appContext,
  account,
}: {
  children: JSX.Element;
  appContext: AppContextType;
  account: Account;
}) => {
  let appContextWithAccount = appContext;
  // if (account.name) {
  //   appContextWithAccount = {
  //     ...appContextWithAccount,
  //     labels: {
  //       appName: account.name,
  //       copyright: `©${new Date().getFullYear()} ${account.name}`
  //     }
  //   };
  // }
  if (account.visibleFeatures) {
    appContextWithAccount = {
      ...appContextWithAccount,
      visibleFeatures: account.visibleFeatures,
      accessibleFeatures: account.accessibleFeatures,
    };
  }
  return <AppContext.Provider value={appContextWithAccount}>{children}</AppContext.Provider>;
};
export const AppContextProvider = connect(mapStateToProps)(AppContextContainer);
