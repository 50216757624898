import { Operation } from '@features/appContext/types';

export const sum = (a: number, b: number): number => a + b;

export const capitalizeString = (s: string) => s.charAt(0).toUpperCase() + s.slice(1);

export const generateId = () =>
  (
    Date.now() +
    '.' +
    Math.random()
      .toString(36)
      .replace(/[^a-z0-9]+/g, '')
      .substr(0, 5)
  ).toString();

export const CompareDates = (a: string, b: string) => {
  const dateA: Date = new Date(a);
  const dateB: Date = new Date(b);

  return +dateA - +dateB;
};
export const CompareStrings = (a: string, b: string) => {
  if (a < b) {
    return -1;
  }
  if (a > b) {
    return 1;
  }
  return 0;
};
export const sleep = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

export const getStatusColor = (status: string): string | undefined => {
  switch (status) {
    case 'active':
      return 'green';
    case 'broken':
      return 'volcano';
    default:
      return undefined;
  }
};
export const formatOperationToRightsArray = (operation: Operation) => {
  switch (operation) {
    case 'read':
      return ['read'];
    case 'create':
      return ['read', 'create'];
    case 'update':
      return ['read', 'create', 'update'];
    case 'delete':
      return ['read', 'create', 'update', 'delete'];
  }
};

export const extractObjectKeys = Object.keys as <T>(o: T) => Extract<keyof T, string>[];

export const Debounce = (func: any, timeout = 1000) => {
  let handle: any = null;

  return (...args: any) => {
    if (handle !== null) clearTimeout(handle);

    handle = setTimeout(() => func(...args), timeout);
  };
};
export const updateOrAddElementInArray = (element: any, array: any[], key = 'key') => {
  let newArray: any = array;
  const elementExists = array.find((c: any) => c[key] === element[key]);
  if (elementExists) {
    newArray = array.map((el: any) => {
      if (el[key] === elementExists[key]) return element;
      return el;
    });
  } else newArray = [...newArray, element];
  return newArray;
};
export const updateOrAddElementInArrayWithKey = (element: any, array: any[], key: any) => {
  let newArray: any = array;
  const elementExists = array.find((c: any) => c[key] === element[key]);
  if (elementExists) {
    newArray = array.map((el: any) => {
      if (el[key] === elementExists[key]) return element;
      return el;
    });
  } else newArray.push(element);
  return newArray;
};
export const findElementByKey = (element: any, array: any[], key: any) => {
  return array.find((elem) => elem[key] === element[key]);
};

export const searchStrings = (array: any[], searchText: string, key?: string) => {
  if (key) {
    return array.filter((element) => element[key].includes(searchText));
  } else {
    return array.filter((element) => element.includes(searchText));
  }
};
export const generateRandomInteger = (min: number, max: number) => {
  return Math.floor(min + Math.random() * (max + 1 - min));
};
export const trimmedString = (length: number, string?: string) => {
  return string && string.length > length ? string.substring(0, length - 3) + '...' : string;
};

export const generateRandomBoolean = () => {
  return Math.random() < 0.5;
};

export const camelToSnakeCase = (str: string) =>
  str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);

export const spaceToSnakeCase = (str: string) => str.replace(/\s/g, (l) => `_`);

export const removeStringSpecialCharacters = (str: string) => str.replace(/[^a-zA-Z]/g, '');

export const limitTextDisplay = (text: string | number, maxLength: number) => {
  if (text)
    return text.toString().length > maxLength
      ? text.toString().substring(0, maxLength)
      : text.toString();
  else return '';
};
