// Importing type
import * as apiTypes from './types';
import { sleep } from '@utils/helpers';
import { Connection } from '@features/connections/types';
import { TreeStructure } from '@components/form/items/OctoTreeSelect';
import {
  dataWareHouseTree,
  staticTree,
  integrationsTree,
  destinationAPIConnections,
  sourceConnections,
} from '@data/connections';

const getConnections: apiTypes.APIGetConnections = async (type: string) => {
  await sleep(1500);
  let error: boolean = false;
  let errorDetails: string | undefined = undefined;

  if (!sourceConnections) {
    error = true;
    errorDetails = 'No connections found.';
  }
  return {
    error,
    connections: [...destinationAPIConnections, ...sourceConnections],
    loaded: true,
    errorDetails: error && errorDetails ? errorDetails : undefined,
  };
};
const saveConnection: apiTypes.APISaveConnection = async (connection: Connection) => {
  await sleep(1500);
  let error: boolean = false;
  let errorDetails: string | undefined = undefined;

  if (!connection) {
    error = true;
    errorDetails = 'No connections found.';
  }
  return {
    connection,
    error,
    loaded: true,
    errorDetails: error && errorDetails ? errorDetails : undefined,
  };
};
const getConnectionPointer: apiTypes.APIGetConnectionPointers = async (connection: Connection) => {
  await sleep(1500);
  let pointers: TreeStructure;
  let error: boolean = false;
  let errorDetails: string | undefined = undefined;

  switch (connection.connector.category) {
    case 'api':
      pointers = integrationsTree;
      break;
    case 'db':
      pointers = dataWareHouseTree;
      break;
    case 'ftp':
      pointers = staticTree;
      break;
    default:
      pointers = {};
      break;
  }
  if (!connection) {
    error = true;
    errorDetails = 'No connections found.';
  }
  return {
    pointers,
    error,
    loaded: true,
    errorDetails: error && errorDetails ? errorDetails : undefined,
  };
};
const deleteConnection: apiTypes.APIDeleteConnection = async (connection: Connection) => {
  await sleep(1500);
  let error: boolean = false;
  let errorDetails: string | undefined = undefined;

  if (!connection) {
    error = true;
    errorDetails = 'No connections found.';
  }
  return {
    connection,
    error,
    loaded: true,
    errorDetails: error && errorDetails ? errorDetails : undefined,
  };
};
export { getConnections, saveConnection, deleteConnection, getConnectionPointer };
