import React from 'react';

const UnderConstructionFeature = () => {
  return (
    <div>
      <h1 style={{ textAlign: 'center', background: 'white' }}>under construction</h1>
    </div>
  );
};

export default UnderConstructionFeature;
