import { createSlice } from '@reduxjs/toolkit';
import { ReducerActiveSyncActions, ReducerAllSyncsActions, ReducerSyncActions } from './types';
import { updateOrAddElementInArrayWithKey } from '@utils/helpers';
import { ActiveSync, Sync, SyncsTableRecord } from '../types';
import { Connection } from '@features/connections/types';
import { SchemaColumn } from '@features/objects/types';
import { ObjectReducerActions } from '@features/objects/ducks/types';
import { emptyMapping } from '@data/emptyMapping';
import { CronTriggers } from '@features/schedule/types';
import moment from 'moment';

export interface SyncsState {
  loaded: boolean;
  shouldBeClosed: boolean;
  isFetching?: boolean;
  error?: boolean;
  errorDetails?: string | undefined;
  syncs: Sync[];
  syncsTableRecords: SyncsTableRecord[];
  sync?: Sync;
  activeSync: ActiveSync;
  integrationSchema?: SchemaColumn[];
  destinationConnection?: Connection[];
}

export const INITIAL_SYNCS_STATE = {
  loaded: false,
  shouldBeClosed: true,
  isFetching: false,
  syncs: [],
  syncsTableRecords: [],
  activeSync: {
    mapping: emptyMapping,
    scheduleType: CronTriggers.REAL_TIME,
  },
} as SyncsState;

const syncsSlice = createSlice({
  name: 'syncs',
  initialState: INITIAL_SYNCS_STATE,
  reducers: {
    getSyncs: (state) => {
      state.isFetching = true;
      state.syncs = [];
    },
    getSyncsSuccess: (state, { payload }: ReducerAllSyncsActions) => {
      state.loaded = true;
      state.isFetching = false;
      state.syncs = payload.syncs;
      state.syncsTableRecords = payload.syncs.map((sync: Sync) =>
        Object.create({
          key: sync.key,
          name: sync.name,
          audience: sync.origin.name,
          lastRun: moment().locale('en').fromNow(),
          destination: sync.integration.name,
          schedule: typeof sync.schedule === 'string' ? sync.schedule : '',
          status: sync.active,
        })
      );
    },
    getSyncsFailed: (state, { payload }: ReducerAllSyncsActions) => {
      state.isFetching = false;
      state.error = true;
      state.errorDetails = payload.errorDetails;
    },
    saveSync: (state) => {
      state.isFetching = true;
    },
    saveSyncSuccess: (state, { payload }: ReducerSyncActions) => {
      const syncs = updateOrAddElementInArrayWithKey(payload.sync, state.syncs, 'key');
      state.loaded = true;
      state.isFetching = false;
      state.syncs = syncs;
      state.syncsTableRecords = syncs.map((sync: Sync) =>
        Object.create({
          key: sync.key,
          name: sync.name,
          audience: sync.origin.name,
          lastRun: moment().fromNow(),
          destination: sync.integration.name,
          schedule: typeof sync.schedule === 'string' ? sync.schedule : '',
          status: sync.active,
        })
      );

      state.activeSync = { mapping: emptyMapping, scheduleType: CronTriggers.REAL_TIME };
    },
    saveSyncFailed: (state, { payload }: ReducerSyncActions) => {
      console.log(payload);
      state.isFetching = false;
      state.error = true;
      state.errorDetails = payload.errorDetails;
    },

    updateActiveSync: (state, { payload }: ReducerActiveSyncActions) => {
      state.activeSync = payload.activeSync;
    },
    setEmptyActiveSync: (state) => {
      state.activeSync = { mapping: emptyMapping, scheduleType: CronTriggers.REAL_TIME };
    },
    setActiveSyncIntegrationObject: (state, { payload }: ObjectReducerActions) => {
      state.activeSync = {
        ...state.activeSync,
        integrationObject: payload.object,
      };
    },
  },
});

export default syncsSlice;
