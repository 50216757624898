import { createSlice } from '@reduxjs/toolkit';
import type { ReducerRawRecordsActions } from './types';
import { RawRecord } from '@features/rawRecords/types';
import { updateOrAddElementInArray } from '@utils/helpers';

export const INITIAL_SOURCE_STATE = { loaded: false, isFetching: false } as RawRecordsState;
export interface RawRecordsState {
  loaded: boolean;
  isFetching?: boolean;
  shouldBeClosed?: boolean;
  error?: boolean;
  errorDetails?: string | undefined;
  page?: number;
  limit?: number;
  total?: number;
  rawRecord: RawRecord[];
}
const rawRecordsSlice = createSlice({
  // Key name that gets added to combineReducers
  name: 'rawRecord',
  // Reducers object is the bread and butter of saga slice.
  // Defining a reducer also defines a type and action.
  // The type will be `source/fetchGet`, using the pattern of `{name}/{key}`

  initialState: INITIAL_SOURCE_STATE,
  reducers: {
    getRawData: (state) => {
      state.isFetching = true;
      state.rawRecord = [];
    },

    getRawDataSuccess: (state, { payload }: ReducerRawRecordsActions) => {
      state.loaded = true;
      state.isFetching = false;
      state.rawRecord = payload.records;
    },

    getRawDataFailed: (state, { payload }: ReducerRawRecordsActions) => {
      state.isFetching = false;
      state.error = true;
      state.errorDetails = payload.errorDetails;
    },
    clearRawData: (state) => {
      state.rawRecord = [];
      state.loaded = false;
    },
  },
});
export default rawRecordsSlice;
