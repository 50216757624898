import { takeLatest, call, put } from 'redux-saga/effects';
// api
import * as API from './api';
// connectorsSlice
import recipesSlice, { RecipesState } from '@features/recipes/ducks/recipesSlice';
// types
import * as types from './types';
import objectsSlice from '@features/objects/ducks/objectsSlice';
import generateObject from '@features/objects/generateObject';
import * as ObjectsApi from '@features/objects/ducks/api';
import { RECIPE_CREATED_IN } from '@screens/recipes/editor';
import sourcesSlice from '@features/sources/ducks/sourcesSlice';
import { ApiGetObjectPayload } from '@features/objects/ducks/types';
import { Recipe } from '../types';

function* getRecipesSaga({ payload }: types.GetRecipesSaga) {
  try {
    const getRecipesPayload: RecipesState = yield call(API.getRecipes);
    yield put({ type: recipesSlice.actions.getRecipesSuccess.type, payload: getRecipesPayload });
  } catch (error) {
    yield put({ type: recipesSlice.actions.getRecipesFailed.type, payload: error });
  }
}
function* getModelsSaga({ payload }: types.GetModelsSaga) {
  try {
    const getModelsPayload: RecipesState = yield call(API.getModels);
    yield put({ type: recipesSlice.actions.getModelsSuccess.type, payload: getModelsPayload });
  } catch (error) {
    yield put({ type: recipesSlice.actions.getModelsFailed.type, payload: error });
  }
}

function* saveRecipesSaga({ payload }: any) {
  try {
    const { recipeConfig } = payload;
    // @@@Amine recipes object are created when a new source is created (for source flow) or here if it didn't have a target
    if (!recipeConfig.targets.length) {
      let recipeObject = recipeConfig.recipeObject;
      if (!recipeObject && recipeConfig.recipeObjectName) {
        recipeObject = generateObject(recipeConfig.recipeObjectName);
      } else if (!recipeObject) {
        recipeObject = generateObject(`computed_from_${recipeConfig.name}`);
      }
      const saveObjectPayload: ApiGetObjectPayload = yield call(
        ObjectsApi.saveObject,
        recipeObject
      );
      yield put({ type: objectsSlice.actions.saveObjectSuccess.type, payload: saveObjectPayload });
      recipeConfig.targets = [recipeObject];
    }
    const saveRecipePayload: RecipesState = yield call(API.saveRecipe, recipeConfig);
    yield put({ type: recipesSlice.actions.saveRecipeSuccess.type, payload: saveRecipePayload });
    if (recipeConfig.createRecipeFor === RECIPE_CREATED_IN.SOURCE) {
      yield put({
        type: sourcesSlice.actions.setActiveSourceRecipe.type,
        payload: saveRecipePayload,
      });
    }
  } catch (error) {
    console.log(error);
    yield put({ type: recipesSlice.actions.saveRecipeFailed.type, payload: error });
  }
}
function* saveSourceRecipeSaga({ payload }: types.SaveRecipeSaga) {
  try {
    const { recipe } = payload;

    if (!recipe.targets.length) {
      const recipeObject = generateObject(`computed from ${recipe.name}`);
      const saveObjectPayload: ApiGetObjectPayload = yield call(
        ObjectsApi.saveObject,
        recipeObject
      );
      yield put({ type: objectsSlice.actions.saveObjectSuccess.type, payload: saveObjectPayload });
      recipe.targets = [recipeObject];
    }
    const saveRecipePayload: RecipesState = yield call(API.saveRecipe, recipe);
    yield put({
      type: recipesSlice.actions.saveSourceRecipeSuccess.type,
      payload: saveRecipePayload,
    });
    yield put({
      type: sourcesSlice.actions.setActiveSourceRecipe.type,
      payload: saveRecipePayload,
    });
  } catch (error) {
    console.log(error);
    yield put({ type: recipesSlice.actions.saveSourceRecipeFailed.type, payload: error });
  }
}

function* deleteRecipesSaga({ payload }: types.SaveRecipeSaga) {
  try {
    const { recipe } = payload;
    const deleteRecipePayload: RecipesState = yield call(API.deleteRecipe, recipe);
    yield put({
      type: recipesSlice.actions.deleteRecipeSuccess.type,
      payload: deleteRecipePayload,
    });
  } catch (error) {
    yield put({ type: recipesSlice.actions.deleteRecipeFailed.type, payload: error });
  }
}

function* runQuerySaga({ payload }: types.RunQuerySaga) {
  try {
    const { recipe } = payload;
    const runQueryPayload: RecipesState = yield call(API.runQuery, recipe);

    yield put({ type: recipesSlice.actions.runQuerySuccess.type, payload: runQueryPayload });
  } catch (error) {
    yield put({ type: recipesSlice.actions.runQueryFailed.type, payload: error });
  }
}

export const recipesSagas = [
  takeLatest(recipesSlice.actions.getRecipes.type, getRecipesSaga),
  takeLatest(recipesSlice.actions.getModels.type, getModelsSaga),
  takeLatest(recipesSlice.actions.saveRecipe.type, saveRecipesSaga),
  takeLatest(recipesSlice.actions.saveSourceRecipe.type, saveSourceRecipeSaga),
  takeLatest(recipesSlice.actions.deleteRecipe.type, deleteRecipesSaga),
  takeLatest(recipesSlice.actions.runQuery.type, runQuerySaga),
];
