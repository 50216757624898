import React from 'react';
import { extractObjectKeys, generateId } from '@utils/helpers';
import { OctoObject, SchemaColumn, SchemaRowColumn } from '@features/objects/types';
const generateObject = (label: string) => {
  const objectKey = generateId();
  const object: OctoObject = {
    key: objectKey,
    name: label,
    tableName: `table-${label}`,
    category: 'default',
    schema: defaultSchema,
    primaryKeys: ['col1'],
    recordsNumber: 0,
    pointer: 'default',
    records: [
      {
        col1: 'val1',
        col2: 'val2',
        col3: 'val3',
        col4: 'val4',
      },
    ],
    created: new Date(),
  };
  return object;
};
export const generateObjectFromRecords = (
  name: string,
  tableName: string,
  pointer: string,
  category: string,
  schema: SchemaColumn[],
  primaryKeys: string[],
  recordsNumber: number,
  records: any[],
  updatedAtId?: string
) => {
  const objectKey = generateId();
  const object: OctoObject = {
    updatedAtId,
    name,
    tableName,
    category,
    schema,
    primaryKeys,
    recordsNumber,
    pointer,
    records,
    key: objectKey,
    created: new Date(),
  };
  return object;
};

const getDefaultSchemaColumnType = (key: string) => {
  const typesByColumn: { [key: string]: string } = {
    name: 'string',
    email: 'string',
    age: 'number',
    client: 'string',
    product: 'string',
    price: 'string',
    date: 'date',
    transaction_id: 'string',
    total_orders: 'number',
    day_orders: 'string',
    product_ref: 'string',
    userId: 'string',
    firstName: 'string',
    lastName: 'string',
    fullName: 'string',
    birthdate: 'date',
    language: 'string',
    gender: 'string',
    civility: 'string',
    address: 'string',
    postalCode: 'string',
    city: 'string',
    region: 'string',
    countryName: 'string',
    countryCode: 'string',

    emailFormat: 'string',
    emailDomain: 'string',
    emailValidity: '',
    emailValidityReason: '',
    phoneMobile: 'string',
    phoneLandline: 'number',
    phone: 'number',
    phoneValidity: '',
    phoneValidityReason: '',
    phoneCountry: 'string',
    phoneNormalized: '',
    phoneType: '',
    consentEmail: 'string',
    consentEmailUpdatedAt: 'string',
    consentSms: 'string',
    consentSmsUpdatedAt: 'string',
    linkedin: '',
    website: '',
    companyName: 'string',
    jobTitle: 'string',
    createdAt: 'date',
    updatedAt: 'date',
    recordId: 'string',
    contactId: 'string',
    totalAmount: 'number',
    shippingAmount: 'number',
    discountAmount: 'number',
    taxAmount: 'number',
    currency: 'number',
    completed: 'boolean',
    storeId: 'string',
    salesPerson: 'string',

    brand: '',
    description: 'string',

    category: 'string',
    imgUrl: '',
    sku: '',
  };
  return typesByColumn[key] ? typesByColumn[key] : 'no type';
};
export const generateSchemaFromRecords = (record: { [key: string]: any }) => {
  const keys = extractObjectKeys(record);
  const columns: SchemaColumn[] = keys.map((name: any, index) => {
    return {
      id: generateId(),
      columnName: name,
      columnType: getDefaultSchemaColumnType(name),
      required: false,
      description: '',
      category: 'no category',
      position: index,
    };
  });
  return columns;
};
export const generateEmptyColumn = (): SchemaColumn => {
  return {
    id: '',
    columnName: '',
    columnType: '', // to give random type that we don't have in our types
    required: false,
    description: '',
    category: '',
    externalReference: '',
    isExternalId: false,
    position: 0,
  };
};
export const defaultSchema: SchemaColumn[] = [
  {
    id: generateId(),
    columnName: 'col1',
    columnType: 'String',
    required: false,
    category: 'col2',
    description: '',
    position: 1,
  },
  {
    id: generateId(),
    columnName: 'col2',
    columnType: 'String',
    required: false,
    category: 'col2',
    description: '',
    position: 2,
  },
  {
    id: generateId(),
    columnName: 'col3',
    columnType: 'String',
    required: false,
    category: 'col3',
    description: '',
    position: 3,
  },
  {
    id: generateId(),
    columnName: 'col4',
    columnType: 'String',
    required: false,
    category: 'col4',
    description: '',
    position: 4,
  },
];

export const defaultSchemaRowColumn: SchemaRowColumn[] = [
  {
    id: generateId(),
    position: 1,
    required: false,
    isExternalId: false,
  },
  {
    id: generateId(),
    position: 2,
    required: false,
    isExternalId: false,
  },
  {
    id: generateId(),
    position: 3,
    required: false,
    isExternalId: false,
  },
  {
    id: generateId(),
    position: 4,
    required: false,
    isExternalId: false,
  },
];

export default generateObject;
