const menuLeftConfig = [
  {
    key: 'sources',
    // TODO @@@@slava i18n? {type:string, data:object} `all_sources %data.XXXX% (optional)`
    breadcrumb: ['All sources'],
  },
  {
    key: 'destinations',
    breadcrumb: ['All destinations'],
  },
  {
    key: 'flows',
    breadcrumb: ['All flows'],
  },
  {
    key: 'recipes',
    breadcrumb: ['All recipes'],
  },
  {
    key: 'objects',
    breadcrumb: ['All objects'],
  },
  {
    key: 'control-center',
    breadcrumb: ['All jobs'],
  },
];

const getHighlightedMenuItem = (path: string) => {
  let highlightedItemTemp:any = menuLeftConfig.find((e) => e.key === path.substring(1).split('/')[0]) || {
    key: null,
    breadcrumb: [],
    defaultOpenKey: undefined
  };

  if (highlightedItemTemp && highlightedItemTemp.key !== null && ['flows', 'objects'].indexOf(highlightedItemTemp.key) > -1) {
    const urlSubPart = window.location.pathname.substring(1).split('/')[1];

    if (highlightedItemTemp.key === 'flows') {
      switch (urlSubPart) {
        case 'source':
          highlightedItemTemp.key = 'flowssource';
          highlightedItemTemp.breadcrumb.push('Source flows');
          break;
        case 'destination':
          highlightedItemTemp.key = 'flowsdestination';
          highlightedItemTemp.breadcrumb.push('Destination flows');
          break;
        case 'internal':
          highlightedItemTemp.key = 'flowsinternal';
          highlightedItemTemp.breadcrumb.push('Internal flows');
          break;
        default:
          highlightedItemTemp.key = 'flowsall';
      }
      highlightedItemTemp= {
        ...highlightedItemTemp,
        defaultOpenKey :'flows'
      };
    }
  }

  return highlightedItemTemp;
}

export { getHighlightedMenuItem }
