import * as apiTypes from './types';
import { sleep } from '@utils/helpers';
import { destinations } from '@data/destinations';

const getDestinations: apiTypes.APIGetDestinations = async () => {
  await sleep(1500);
  let error: boolean = false;
  let errorDetails: string | undefined = undefined;

  if (!destinations) {
    error = true;
    errorDetails = 'No destinations found.';
  }
  return {
    destinations,
    error,
    loaded: true,
    errorDetails: error && errorDetails ? errorDetails : undefined,
  };
};

export { getDestinations };
