import { createSlice } from '@reduxjs/toolkit';

import {  User } from '@features/users/types';
import {
  DeletedUserReducerActions,
  UserReducerActions,
  UsersReducerActions
} from '@features/users/ducks/types';

export interface UsersState {
  loaded: boolean;
  isFetching?: boolean;
  error?: boolean;
  errorDetails?: string | undefined;
  user: User
  users: User[]
}

export const INITIAL_THEME_STATE:UsersState = {
  loaded: false,
  isFetching: false,
  users:[],
  user:{
    id:'',
    email:'',
    firstName:"",
    lastLogin:"",
    access:[],
    lastName:""
  }
} ;


const usersSlice = createSlice({
  name: 'users',

  initialState: INITIAL_THEME_STATE,
  reducers: {


    getUsers: (state) => {
      state.isFetching = true;
    },
    getUsersSuccess: (state, { payload }: UsersReducerActions) => {
      state.loaded = true;
      state.isFetching = false;
      state.users = payload.users;
    },
    getUsersFailed: (state, { payload }: UsersReducerActions) => {
      state.isFetching = false;
      state.error = true;
      state.errorDetails = payload.errorDetails;
    },
    getUser: (state) => {
      state.isFetching = true;
    },
    getUserSuccess: (state, { payload }: UserReducerActions) => {
      state.loaded = true;
      state.isFetching = false;
      state.user = payload.user;
    },
    getUserFailed: (state, { payload }: UserReducerActions) => {
      state.isFetching = false;
      state.error = true;
      state.errorDetails = payload.errorDetails;
    },
    saveUser: (state) => {
      state.isFetching = true;
    },
    saveUserSuccess: (state, { payload }: UserReducerActions) => {
      state.loaded = true;
      state.isFetching = false;
      const userExists = state.users.find(u=>u.id===payload.user.id)
      if ( userExists) {
        state.users = state.users.map(user=>{
          if(user.id === userExists.id) return payload.user
          return user
        })
      } else state.users.push(payload.user)
    },
    saveUserFailed: (state, { payload }: UserReducerActions) => {
      state.isFetching = false;
      state.error = true;
      state.errorDetails = payload.errorDetails;
    },
    deleteUser: (state) => {
      state.isFetching = true;
    },
    deleteUserSuccess: (state, { payload }: DeletedUserReducerActions) => {
      state.loaded = true;
      state.isFetching = false;
      state.users = state.users.filter(u=>u.id !== payload.userId);
    },
    deleteUserFailed: (state, { payload }: DeletedUserReducerActions) => {
      state.isFetching = false;
      state.error = true;
      state.errorDetails = payload.errorDetails;
    }
  }
});

export default usersSlice;
