import { Connection } from '@features/connections/types';
import { combinationOperator, OctoRecordsFilter, operator } from '@features/filter/types';
import { BusinessCategory, OctoObject, SchemaColumn } from '@features/objects/types';

// Audience interfaces
export interface AudiencesTableRecord {
  key: string;
  name: string;
  sources: string[];
  deduplication: string[];
  lastRun: Date;
  status: boolean;
}
export interface Audience {
  key: string;
  name: string;
  category: BusinessCategory;
  buildType: buildTypesEnum;
  retrievalMode: retrievalModeEnum;
  sources: SourceConfiguration[];
  preparationSteps: PreparationStep[];
  mappingRows: MappingRow[];
  deduplicateSettings: AudienceDeduplicateSettings;
  object: OctoObject;
  filter: OctoRecordsFilter;
  status: boolean;
}
export interface Source {
  key: string;
  name: string;
  category: BusinessCategory;
  connection: Connection;
  pointer: string;
  object: OctoObject;
}

export type SourceConfiguration = DefaultSourceConfiguration &
  (JoinSourceConfiguration | UnionSourceConfiguration);
export interface DefaultSourceConfiguration {
  key: string;
  dataType: dataTypesEnum;
  sourceType: sourceTypesEnum;
  audienceSource: Source | Audience;
  combinationLevel: number;
  selectedColumns: SchemaColumn[];
  retrieveFrequency: retrieveFrequencyEnum;
  deduplicateSettings: SourceDeduplicateSettings;
}
export interface UnionSourceConfiguration {}
export interface JoinSourceConfiguration {
  joinByColumns: JoinByColumn[];
}
export interface AggregateDetails {
  // AggregateDetails used for aggs columns (JOIN case)
  filterByColumns: boolean;
  excludeNullvalues: boolean;
  filterByOtherDateColumn: boolean;
  sourceColumnId?: string;
  function?: string;
  datePeriod?: string;
  sourceFilterDateColumnId?: string;
  sourceFilterColumnId?: string;
  filterOperator?: operator;
  filterOperatorValue?: string | Date | number | string[] | Date[] | number[] | boolean | unknown;
}

// Active interfaces used for forms
export type ActiveAudience = {
  key: string;
  name?: string;
  category?: BusinessCategory;
  buildType?: buildTypesEnum;
  object?: OctoObject;
  filter?: OctoRecordsFilter;
  retrievalMode: retrievalModeEnum;
  sources: ActiveSourceConfiguration[];
  preparationSteps: PreparationStep[];
  mappingRows: MappingRow[];
  deduplicateSettings: AudienceDeduplicateSettings;
};
export interface ActiveSource {
  key: string;
  name?: string;
  category?: BusinessCategory;
  connection?: Connection;
  pointer?: string;
  object?: OctoObject;
}

export type ActiveSourceConfiguration = DefaultActiveSourceConfiguration &
  (JoinActiveSourceConfiguration | UnionActiveSourceConfiguration);
export interface DefaultActiveSourceConfiguration {
  key: string;
  dataType?: dataTypesEnum; // for the first source it's UNION else  depends on user choise  // DataType = "UNION" | "JOIN"
  sourceType: sourceTypesEnum;
  audienceSource: ActiveSource | ActiveAudience;
  combinationLevel: number;
  selectedColumns: SchemaColumn[];
  retrieveFrequency?: retrieveFrequencyEnum;
  deduplicateSettings?: SourceDeduplicateSettings;
}
export interface UnionActiveSourceConfiguration {}
export interface JoinActiveSourceConfiguration {
  joinByColumns: JoinByColumn[];
}

// Audience Types
export type AudienceDeduplicateSettings = {
  duplicateKeys: string[]; // MappingRow[] keys from the mappingRows rows
  preservedValuesKeys: string[]; // MappingRow[] keys from the mappingRows rows
};
export type SourceDeduplicateSettings = {
  keep: keepTypesEnum; // default value recent
  deduplicateRecord: string; // default value false
  deduplicateColumns?: DeduplicateColumnsType;
};
export type DeduplicateColumnsType = {
  key: string;
  combinationOperator: combinationOperator;
  columnGroups: ColumnGroupType[];
};
export type ColumnGroupType = {
  key: string;
  columns: SchemaColumn[];
};
export type JoinByColumn = { key: string; sourceColumnId?: string; audienceColumnId?: string };
// types related to audience mapping
export type MappingRow = {
  key: string;
  mappedColumns: MappedColumns;
  audienceColumnName: string;
  audienceColumnType: string;
  businessColumn?: SchemaColumn;
  primaryKey?: boolean;
  updatedAtKey?: boolean;
  canDelete?: boolean;
};
export type MappedColumns = {
  [sourceKey: string]: MappedColumn;
};
export type MappedColumn = {
  columnId: string;
  columnName: string;
  columnType: string;
  aggregate?: AggregateDetails;
  primaryKey?: boolean;
  updatedAtKey?: boolean;
  columnDisplay?: columnDisplayEnum;
};
// Mapping types for the Form values
export type MappingRowsForm = {
  [rowKey: string]: {
    mappedColumns: { [sourceKey: string]: { columnId: string } };
    audienceColumnName: string;
    businessColumnId: string | undefined;
  };
};
export type MappingJoinColumnsRowsForm = {
  [rowKey: string]: {
    sourceColumnId: string | undefined;
    audienceColumnId: string | undefined;
  };
};
// Used for mapping rows factory
export type MappingRowsTypes = 'defaultMapping' | 'union' | 'join';

// Audience enums
export enum dataTypesEnum {
  UNION = 'union',
  JOIN = 'join',
}
export enum sourceTypesEnum {
  SOURCE = 'source',
  AUDIENCE = 'audience',
}
export enum buildTypesEnum {
  BUSINESS = 'businessProfile',
  DATA = 'dataProfile',
}
export enum retrieveFrequencyEnum {
  EVERY_HOUR = '1h',
  EVERY_THIRTY_MINUTES = '30mn',
  EVERY_FIFTEEN_MINUTES = '15mn',
  EVERY_MINUTE = '1mn',
}
export enum retrievalModeEnum {
  INCREMENTAL_MODE = 'incrementalMode',
  ALL = 'all',
}
export enum keepTypesEnum {
  ALL = 'all',
  RECENT = 'recent',
}
export enum columnDisplayEnum {
  HIDDEN = 'hidden',
  DISABLED = 'disabled',
  LONG_LINE = 'longLine',
  SELECT = 'select',
  DROP_DOWN_VIEW_MENU = 'dropDownViewMenu',
}

// Preparation STEPS TYPES / ENUM / FORM TYPES
export enum PreparationCategoriesEnum {
  DATA_PREPARATION = 'dataPreparation',
  BUSINESS_PREPARATION = 'businessPreparation',
  SCORING = 'scoring',
}
export enum PreparationsStepsTypesEnum {
  INITIAL_SOURCE = 'initialSource',
  COMBINED_SOURCE = 'combinedSource',
  COPY_COLUMNS = 'copyColumns',
  FIND_AND_REPLACE = 'findAndReplace',
  FORMULA = 'formula',
  SPLIT_COLUMN = 'splitColumn',
  EXTRACT_REGULAR_EXPRESSION = 'extractRegularExpression',
  NORMALIZE_COLUMN_VALUES = 'normalizeColumnValues',
  FILL_COLUMN_VALUE = 'fillColumnValue',
  USE_SQL = 'useSQL',
  ADD_COMPUTED_FIELD = 'addComputedField',
}
export type PreparationStepCategory = 'dataPreparation' | 'businessPreparation' | 'scoring';
export type DataPreparationType =
  | 'initialSource'
  | 'combinedSource'
  | 'copyColumns'
  | 'renameColumns'
  | 'deleteColumns'
  | 'keepColumns'
  | 'changeColumnsType'
  | 'containsRegex'
  | 'concatColumns'
  | 'findAndReplace'
  | 'formula'
  | 'useSQL';
export type BusinessPreparationType = 'formatPhoneNumber';
export type ScoringPreparationType = 'randomRepartition';

export type PreparationStepType =
  | DataPreparationType
  | BusinessPreparationType
  | ScoringPreparationType;
export type PreparationConfig = {};
export type PreparationStep = {
  key: string;
  position: number;
  type: PreparationStepType;
  category?: PreparationStepCategory;
  title: string;
  displayedTitle?: string;
  configuration: {
    sourceKey?: string;
  };
};
