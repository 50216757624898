// Importing type
import * as apiTypes from './types';
import { sleep } from '@utils/helpers';
import { Organization } from '@features/organizations/types';

const getOrganizations: apiTypes.APIGetOrganizations = async (app: string) => {
  await sleep(1000);

  let organizations: Organization[];
  let error: boolean = false;
  let errorDetails: string | undefined = undefined;

  organizations = [
    {
      key: 'sendinblue',
      details: {
        name: 'sendinblue',
        short_name: 'SNB',
      },
      theme: 'sendinblue-default',
    },
    {
      key: 'splio',
      details: {
        name: 'Splio',
        short_name: 'SPL',
      },
      theme: 'splio-black',
    },
  ];
  if (!organizations) {
    error = true;
    errorDetails = 'No organizations found.';
  }

  return {
    error,
    organizations,
    loaded: true,
    errorDetails: error && errorDetails ? errorDetails : undefined,
  };
};

const getOrganization: apiTypes.APIGetOrganization = async (organizationName: string) => {
  await sleep(1000);

  let organization: Organization;
  let error: boolean = false;
  let errorDetails: string | undefined = undefined;

  if (organizationName === 'sendinblue') {
    organization = {
      key: 'sendinblue',
      details: {
        name: 'Sendinblue',
        short_name: 'SNB',
      },
      theme: 'sendinblue-default',
    };
  } else if (organizationName === 'octolis') {
    organization = {
      key: 'octolis',
      details: {
        name: 'octolis',
        short_name: 'OCT',
      },
      theme: 'octolis-default',
    };
  } else {
    organization = {
      key: 'splio',
      details: {
        name: 'Splio',
        short_name: 'SPL',
      },
      theme: 'splio-black',
    };
  }

  if (!organization) {
    error = true;
    errorDetails = 'No organizations found.';
  }

  return {
    error,
    organization,
    loaded: true,
    errorDetails: error && errorDetails ? errorDetails : undefined,
  };
};

export { getOrganizations, getOrganization };
