import { sleep } from '@utils/helpers';
import { generateFilter } from '../generateFilter';
import {
  combinationOperator,
  ConditionType,
  OctoRecordsFilter,
  OctoRecordsQuickFilter,
} from '../types';
import * as apiTypes from './types';

// FILTER
const getFilterByObject: apiTypes.APIGetFilterByObject = async (objectId: string) => {
  await sleep(500);
  let filters: OctoRecordsFilter[];
  let filter: OctoRecordsFilter;
  let error: boolean = false;
  let errorDetails: string | undefined = undefined;
  filters = [
    {
      key: '1',
      combinationOperator: 'or',
      groupFilters: [
        {
          combinationOperator: 'and',
          conditions: [
            {
              id: '1625048185347.0jhvp',
              field: 'emails_org',
              operator: 'is',
              value: 'member@octolis.com',
              type: 'string',
              category: 'email',
            },
          ],
          id: '1625048185348.0c9v5',
          type: 'property',
        },
        {
          combinationOperator: 'and',
          conditions: [
            {
              id: '1625048164134.02e9f',
              field: 'phone',
              operator: 'is not',
              value: '00000000',
              type: 'string',
              category: 'phone',
            },
          ],
          id: '1625048164134.0jnk6',
          type: 'property',
        },
        {
          combinationOperator: 'and',
          conditions: [
            {
              id: '1625048084294.0qois',
              field: 'name',
              operator: 'is not empty',
              type: 'string',
              category: 'firstName',
            },
          ],
          id: '1625048084294.0mni6',
          type: 'property',
        },
      ],
      object: 'object1',
    },
    {
      key: '2',
      combinationOperator: 'or',
      groupFilters: [
        {
          combinationOperator: 'and',
          conditions: [
            {
              id: '1625048253161.04b4c',
              field: 'price',
              operator: 'is not',
              value: 33,
              type: 'number',
              category: 'price',
            },
          ],
          id: '1625048253161.0vpfw',
          type: 'property',
        },
        {
          combinationOperator: 'and',
          conditions: [
            {
              id: '1625048245844.07ang',
              field: 'date',
              operator: 'is in list of dates',
              value: [
                '2021-06-05T23:00:00.000Z',
                '2021-06-16T23:00:00.000Z',
                '2021-06-27T23:00:00.000Z',
              ],
              type: 'date',
              category: 'Date',
            },
          ],
          id: '1625048245845.05s9w',
          type: 'property',
        },
      ],
      object: 'object2',
    },
    {
      key: '3',
      combinationOperator: 'or',
      groupFilters: [
        {
          combinationOperator: 'and',
          conditions: [
            {
              id: '1625048393636.0owwq',
              field: 'total_orders',
              operator: 'greater than',
              value: 5,
              type: 'number',
              category: 'total_orders',
            },
          ],
          id: '1625048393637.0evoc',
          type: 'property',
        },
        {
          combinationOperator: 'and',
          conditions: [
            {
              id: '1625048384634.0xtct',
              field: 'day_orders',
              operator: 'is empty',
              type: 'number',
              category: 'day_orders',
            },
          ],
          id: '1625048384634.05fb5',
          type: 'property',
        },
      ],
      object: 'object3',
    },
  ];

  const result = await filters.find((element) => element.object === objectId);
  filter = result || generateFilter(objectId);

  return {
    filter,
    error,
    loaded: true,
    errorDetails: error && errorDetails ? errorDetails : undefined,
  };
};

const saveFilter: apiTypes.APISaveFilter = async (filter: OctoRecordsFilter) => {
  await sleep(500);
  let error: boolean = false;
  let errorDetails: string | undefined = undefined;
  if (!filter) {
    error = true;
    errorDetails = 'No filter to save.';
  }
  return {
    error,
    filter,
    loaded: true,
    errorDetails: error && errorDetails ? errorDetails : undefined,
  };
};
//case new condition / condition in group / update condition
const saveFilterCondition: apiTypes.APISaveFilterCondition = async (
  condition: ConditionType,
  groupId?: string
) => {
  await sleep(500);
  let error: boolean = false;
  let errorDetails: string | undefined = undefined;
  if (!condition) {
    error = true;
    errorDetails = 'No filter condition to save.';
  }
  return {
    error,
    condition: condition,
    groupId,
    loaded: true,
    errorDetails: error && errorDetails ? errorDetails : undefined,
  };
};

const removeFilterCondition: apiTypes.APIRemoveFilterCondition = async (
  condition: ConditionType,
  groupId: string
) => {
  await sleep(500);

  let error: boolean = false;
  let errorDetails: string | undefined = undefined;

  if (!condition) {
    error = true;
    errorDetails = 'Impossible to delete filter conditon (not found).';
  }

  return {
    error,
    condition,
    groupId,
    loaded: true,
    errorDetails: error && errorDetails ? errorDetails : undefined,
  };
};
const updateCombinationOperator: apiTypes.APIUpdateCombinationOperator = async (
  operatorKey: combinationOperator,
  groupId?: string
) => {
  await sleep(500);
  let error: boolean = false;
  let errorDetails: string | undefined = undefined;
  if (!operatorKey) {
    error = true;
    errorDetails = 'No filter operator found.';
  }
  return {
    error,
    operatorKey,
    groupId,
    loaded: true,
    errorDetails: error && errorDetails ? errorDetails : undefined,
  };
};
// QUICK FILTERS
const getQuickFiltersByObject: apiTypes.APIGetQuickFiltersByObject = async (objectId: string) => {
  await sleep(500);
  let quickFilters: OctoRecordsQuickFilter[];
  let error: boolean = false;
  let errorDetails: string | undefined = undefined;
  const availableQuickFilters: OctoRecordsQuickFilter[] = [
    {
      key: '1',
      name: 'client not empty',
      condition: {
        id: '1625050296369.0b4u2',
        field: 'client',
        operator: 'is not empty',
        type: 'string',
        category: 'key',
      },
      object: 'object2',
    },
    {
      key: '2',
      name: 'price > 33',
      condition: {
        id: '1625050321346.0veq2',
        field: 'price',
        operator: 'greater than',
        value: 33,
        type: 'number',
        category: 'price',
      },
      object: 'object2',
    },
    {
      key: '3',
      name: 'email octolis',
      condition: {
        id: '1625050992340.0l6va',
        field: 'email',
        operator: 'contains',
        value: '@octolis.com',
        type: 'string',
        category: 'email',
      },
      object: 'object1',
    },
  ];
  quickFilters = availableQuickFilters.filter((element) => element.object === objectId);
  if (!quickFilters) {
    error = true;
    errorDetails = 'No quick filters found.';
  }
  return {
    quickFilters,
    error,
    loaded: true,
    errorDetails: error && errorDetails ? errorDetails : undefined,
  };
};
const saveQuickFilter: apiTypes.APISaveQuickFitler = async (
  quickFilter: OctoRecordsQuickFilter
) => {
  await sleep(500);
  let error: boolean = false;
  let errorDetails: string | undefined = undefined;
  if (!quickFilter) {
    error = true;
    errorDetails = 'No quick filter condition found.';
  }
  return {
    quickFilter,
    error,
    loaded: true,
    errorDetails: error && errorDetails ? errorDetails : undefined,
  };
};
const removeQuickFilter: apiTypes.APIRemoveQuickFilters = async (
  condition: ConditionType,
  objectKey: string
) => {
  await sleep(500);

  let error: boolean = false;
  let errorDetails: string | undefined = undefined;

  if (!condition) {
    error = true;
    errorDetails = 'Impossible to delete quick filter (not found).';
  }

  return {
    error,
    condition,
    objectKey,
    loaded: true,
    errorDetails: error && errorDetails ? errorDetails : undefined,
  };
};
export {
  getFilterByObject,
  saveFilter,
  saveFilterCondition,
  removeFilterCondition,
  updateCombinationOperator,
  getQuickFiltersByObject,
  saveQuickFilter,
  removeQuickFilter,
};
