import { BusinessCategory } from '@features/objects/types';
import {
  consentsColumns,
  contactsColumns,
  eventsColumns,
  eventsStatisticsColumns,
  ordersColumns,
  ordersItemsColumns,
  productsColumns,
  storesColumns,
} from '@data/objectsColumns';

export const businessCategoriesDefaultList: BusinessCategory[] = [
  { key: '1', name: 'contacts', columns: contactsColumns },
  { key: '2', name: 'orders', columns: ordersColumns },
  { key: '3', name: 'orders_items', columns: ordersItemsColumns },
  { key: '4', name: 'products', columns: productsColumns },
  { key: '5', name: 'stores', columns: storesColumns },
  { key: '6', name: 'events', columns: eventsColumns },
  { key: '7', name: 'events_statistics', columns: eventsStatisticsColumns },
  { key: '8', name: 'consents', columns: consentsColumns },
];
