import React from 'react';
import { routesEnum } from '@routes/types';
import { useHistory } from 'react-router';

const useRedirectionHandler = () => {
  const history = useHistory();

  const goToLink = (link: string) => {
    history.push(link);
  };
  const goToRecommandations = () => {
    history.push(routesEnum.RECOMMANDATIONS);
  }
  const goToConnections = () => {
    history.push(routesEnum.CONNECTONS);
  };
  const goToSources = () => {
    history.push(routesEnum.SOURCES);
  };
  const goToObject = () => {
    history.push(routesEnum.OBJECTS);
  };
  const goToFlows = () => {
    history.push(routesEnum.FLOWS);
  };
  const goToRecipes = () => {
    history.push(routesEnum.RECIPES);
  };
  const goToRecipeEditor = () => {
    history.push(routesEnum.RECIPES_EDITOR);
  };
  const goToSourceFlows = () => {
    history.push(routesEnum.FLOWS_SOURCES);
  };
  const goToDestinationFlows = () => {
    history.push(routesEnum.FLOWS_DESTINATION);
  };
  const goToInternalFlows = () => {
    history.push(routesEnum.FLOWS_INTERNAL);
  };
  const goToAccountManagement = () => {
    history.push(routesEnum.ACCOUNT_MANAGEMENT);
  };
  const goToDestinations = () => {
    history.push(routesEnum.DESTINATION);
  };
  const goToSyncs = () => {
    history.push(routesEnum.SYNCS);
  };
  const goToSyncsCreator = () => {
    history.push(routesEnum.SYNCS_CREATE);
  };
  const goToSyncsCreatorWithParams = (audienceId: string, connectionId: string) => {
    history.push(`${routesEnum.SYNCS_CREATE}?audienceId=${audienceId}&connectionId=${connectionId}`);
  };
  const goToSyncsEditor = (syncId: string) => {
    history.push(`${routesEnum.SYNCS_EDIT}?syncKey=${syncId}`);
  };
  const goToDisplaySegments = () => {
    history.push(routesEnum.SEGMENTS);
  };
  const goToCreateSegment = () => {
    history.push(routesEnum.SEGMENTS_CREATE);
  };
  const goToEditSegment = () => {
    history.push(routesEnum.SEGMENTS_EDIT);
  };

  const goToAudiences = () => {
    history.push(routesEnum.AUDIENCES);
  };
  const goToCreateAudience = () => {
    history.push(routesEnum.AUDIENCES_CREATE);
  };
  const goToPrepareAudienceData = () => {
    history.push(routesEnum.AUDIENCES_PREPARE_DATA);
  };

  const goToViewAudience = () => {
    history.push(routesEnum.AUDIENCES_VIEW);
  };
  return {
    goToRecommandations,
    goToConnections,
    goToSources,
    goToRecipes,
    goToRecipeEditor,
    goToFlows,
    goToSourceFlows,
    goToDestinationFlows,
    goToInternalFlows,
    goToObject,
    goToAccountManagement,
    goToDestinations,
    goToLink,
    goToSyncs,
    goToSyncsCreator,
    goToSyncsCreatorWithParams,
    goToSyncsEditor,
    goToDisplaySegments,
    goToCreateSegment,
    goToEditSegment,
    goToAudiences,
    goToCreateAudience,
    goToPrepareAudienceData,
    goToViewAudience,
  };
};

export { useRedirectionHandler };
