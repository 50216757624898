import React, { CSSProperties } from 'react';
const InaccessibleFeatureContainerStyle: CSSProperties = { textAlign: 'center', padding: 40 };
const InaccessibleFeature = () => {
  return (
    <h2 style={InaccessibleFeatureContainerStyle}>
      This feature isn't accessible in your app or your profile doesn't have access to this feature
      please contact your consultant for more information !
    </h2>
  );
};

export default InaccessibleFeature;
