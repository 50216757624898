import React from 'react';
import ReactDOM from 'react-dom';
import { I18nextProvider } from 'react-i18next';
import i18n from './configs/i18n';
import store from '@redux/store';
import { Provider } from 'react-redux';
import App from '@layout/App';
import * as serviceWorker from './serviceWorker';
import './index.less';
import 'moment/locale/fr';
import { AppContextProvider } from '@providers/AppContextProvider';
import { ProfileContextProvider } from '@providers/ProfileContextProvider';
import { ReactFlowProvider } from 'react-flow-renderer';
require('dotenv').config();
const AppWithProviders = () => {
  return (
    <Provider store={store}>
      <I18nextProvider i18n={i18n}>
        <AppContextProvider>
          <ProfileContextProvider>
            <ReactFlowProvider>
              <App />
            </ReactFlowProvider>
          </ProfileContextProvider>
        </AppContextProvider>
      </I18nextProvider>
    </Provider>
  );
};

ReactDOM.render(<AppWithProviders />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
