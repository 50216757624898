import { Connection } from '@features/connections/types';
import { connectors } from './connectors';
// use sourceConnections instead connections
export const sourceConnections: Connection[] = [
  {
    key: 'connection1',
    name: 'Marketing SFTP',
    connector: connectors[3],
    usedAs: ['source'],
    credentials: {
      host: 'sftp.octolis.cloud',
      port: '22',
      user: 'admin',
      password: 'admin',
    },
  },
  // {
  //   key: 'connection2',
  //   name: 'Marketing DB',
  //   connector: connectors[2],
  //   usedAs: ['source'],
  //   credentials: {
  //     host: 'examplepg@db.octolis.cloud',
  //     port: '5432',
  //     user: 'postgres',
  //     password: 'postgres',
  //     sslMode: 'verify',
  //   },
  // },
  // {
  //   key: 'connection3',
  //   name: 'Salesforce',
  //   connector: connectors[0],
  //   usedAs: ['source'],
  //   credentials: {
  //     url: 'customdomain.salesforce.com',
  //     apiKey: 'Kq5-SD$O5qsd"79qsdLSdf*/sdf64654qs',
  //   },
  // },
  {
    key: 'connection2',
    name: 'My shop',
    connector: connectors[13],
    usedAs: ['source'],
    credentials: {
      url: 'customdomain.shopify.com',
      apiKey: 'Kq5-SD$O5qsd"79qsdLSdf*/sdf64654qs',
    },
  },
  // {
  //   key: 'connection4',
  //   name: 'Octolis WebTracker',
  //   connector: connectors[10], // 11
  //   usedAs: ['source'],
  //   credentials: {
  //     url: 'customdomain.octolis.com',
  //     apiKey: 'Kq5-SD$O5qsd"79qsdLSdf*/sdf64654qs',
  //   },
  // },
  {
    key: 'connection3',
    name: 'Market BigQuery',
    connector: connectors[12], // 12
    usedAs: ['source'],
    credentials: {
      host: 'mkbq.bigquery.com',
      port: '5432',
      user: 'admin',
      password: 'bq',
      sslMode: 'verify',
    },
  },
  {
    key: 'connection4',
    name: 'Market GSheet',
    connector: connectors[15], // 12
    usedAs: ['source'],
    credentials: {
      url: 'mkt.gsheet.com',
      apiKey: 'Kq5-SD$O5qsd"79qsdLSdf*/sdf64654qs',
    },
  },
  // {
  //   key: 'connection6',
  //   name: 'BigQuery',
  //   connector: connectors[13],
  //   usedAs: ['source'],
  //   credentials: {
  //     url: 'customdomain.bigquery.com',
  //     apiKey: 'Kq5-SD$O5qsd"79qsdLSdf*/sdf64654qs',
  //   },
  // },
  // {
  //   key: 'connection3',
  //   name: 'Sendinblue',
  //   usedAs: ['source'],
  //   connector: connectors[1],
  //   credentials: {
  //     url: 'customdomain.sendinblue.com',
  //     apiKey: 'Kq5-SD$O5qsd"79qsdLSdf*/sdf64654qs',
  //   },
  // },
];
export const destinationAPIConnections: Connection[] = [
  {
    key: 'connection5',
    name: 'Salesforce',
    usedAs: ['destination'],
    connector: connectors[0],
    credentials: {
      url: 'customdomain.salesforce.com',
      apiKey: 'Kq5-SD$O5qsd"79qsdLSdf*/sdf64654qs',
    },
  },
  {
    key: 'connection6',
    name: 'Zendesk',
    usedAs: ['destination'],
    connector: connectors[7],
    credentials: {
      url: 'customdomain.zendesk.com',
      apiKey: 'Kq5-SD$O5qsd"79qsdLSdf*/sd985654qs',
    },
  },
  {
    key: 'connection7',
    name: 'Splio',
    usedAs: ['destination'],
    connector: connectors[8],
    credentials: {
      url: 'customdomain.splio.com',
      apiKey: 'Kq5-SD$O5qsd"79qsdLSdf*/sdf64654qs',
    },
  },
  {
    key: 'connection8',
    name: 'Google Ads',
    usedAs: ['destination'],
    connector: connectors[9],
    credentials: {
      url: 'customdomain.googleAds.com',
      apiKey: 'Kq5-SD$O5qsd"79qsdLSdf*/sdf64654qs',
    },
  },
  {
    key: 'connection9',
    name: 'FB Ads',
    usedAs: ['destination'],
    connector: connectors[14],
    credentials: {
      url: 'customdomain.fbads.com',
      apiKey: 'Kq5-SD$O5qsd"79qsdLSdf*/sdf64654qs',
    },
  },
  // {
  //   key: 'connection10',
  //   name: 'Mailchimp',
  //   connector: connectors[5],
  //   usedAs: ['destination'],
  //   credentials: {
  //     url: 'customdomain.mailchimp.com',
  //     apiKey: 'Kq5-SD$O5qsd"79qsdLSdf*/sdf64654qs',
  //   },
  // },
  // {
  //   key: 'connection11',
  //   name: 'Intercom ',
  //   usedAs: ['destination'],
  //   connector: connectors[6],
  //   credentials: {
  //     url: 'customdomain.intercom.com',
  //     apiKey: 'Kq5-SD$O5qsd"79qsdLSdf*/sdf64654qs',
  //   },
  // },
  // {
  //   key: 'connection12',
  //   name: 'Sendinblue',
  //   usedAs: ['destination'],
  //   connector: connectors[1],
  //   credentials: {
  //     url: 'customdomain.sendinblue.com',
  //     apiKey: 'Kq5-SD$O5qsd"79qsdLSdf*/sdf64654qs',
  //   },
  // },
];
export const connections: Connection[] = [...sourceConnections, ...destinationAPIConnections];
export const dataWareHouseTree: {
  octolis_raw: {
    files: string[];
  };
  octolis_output: {
    files: string[];
  };
} = {
  octolis_raw: {
    files: [
      'contacts_salesforce',
      'contacts_cegid',
      'contacts_newsletter',
      'orders_salesforce',
      'orders_cegid',
      'stores_cegid',
      'salespersons_cegid',
    ],
  },
  octolis_output: {
    files: ['contacts', 'orders', 'stores', 'salespersons'],
  },
};

export const staticTree: {
  raw: {
    files: string[];
  };
  imports: {
    files: string[];
  };
} = {
  raw: {
    files: [
      'contacts_salesforce_YYYYMMDD.csv',
      'contacts_cegid_YYYYMMDD.csv',
      'contacts_newsletter_YYYYMMDD.csv',
      'orders_salesforce_YYYYMMDD.csv',
      'orders_cegid_YYYYMMDD.csv',
      'stores_cegid_YYYYMMDD.csv',
      'salespersons_cegid_YYYYMMDD.csv',
    ],
  },
  imports: {
    files: ['contacts.csv', 'orders.csv', 'stores.csv', 'salespersons.csv'],
  },
};

export const integrationsTree: {
  'Available integrations': {
    files: string[];
  };
} = {
  'Available integrations': {
    files: ['All contacts', 'All orders', 'All products'],
  },
};
