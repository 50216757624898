import { takeLatest, call, put } from 'redux-saga/effects';
// api
import * as API from './api';
// connectorsSlice
import connectorsSlice, { ConnectorsState } from '@features/connectors/ducks/connectorsSlice';
import { FetchGetConnectorsSaga } from '@features/connectors/ducks/types';

function* fetchConnectorsSaga({ payload }: FetchGetConnectorsSaga) {
  try {
    const { type } = payload;
    const getConnectorsPayload: ConnectorsState = yield call(API.getConnectors, type);
    yield put({ type: connectorsSlice.actions.getConnectorsSuccess.type, payload: getConnectorsPayload });
  } catch (error) {
    yield put({ type: connectorsSlice.actions.getConnectorsFailed.type, payload: error });
  }
}

export const connectorsSagas = [
  takeLatest(connectorsSlice.actions.getConnectors.type, fetchConnectorsSaga)
];
