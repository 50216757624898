import React, { useState, forwardRef, useImperativeHandle, useEffect } from 'react';
import { UnControlled as CodeMirror } from 'react-codemirror2';
import { sqlHintDefinitionFromTables } from './codeMirrorExtentions';
import './codemirrorPlugins';
import './index.less';
import { TablesMapper } from '@features/recipes/types';
export type useOctoCodeMirrorType = {
  ref: React.RefObject<any>;
  tables: TablesMapper;
  putText: (text: string) => void;
  putRecipe: (text: string) => void;
  putTables: (tables: TablesMapper) => void;
  getValue: () => any;
  showHint: () => void;
};
export const useOctoCodeMirror = (): useOctoCodeMirrorType => {
  const [tables, setTables] = useState<TablesMapper>({});

  // @@@Amine type problem
  const ref = React.createRef<any>();

  return {
    ref,
    tables,
    putText: (text: string) => {
      ref.current?.insertText(text);
    },
    putRecipe: (text: string) => {
      ref.current?.updateText(text);
    },
    putTables: (tables: TablesMapper) => {
      setTables(tables);
    },
    getValue: () => {
      return ref.current?.getValue();
    },
    showHint: () => {
      ref.current?.showHint(tables);
    },
  };
};
export const CodeMirrorComponent = forwardRef<any, any>((props, ref: any) => {
  const codeMirrorRef = React.createRef<any>();
  useImperativeHandle(ref, () => ({
    insertText: (text: any) => {
      const doc = codeMirrorRef?.current?.editor?.getDoc();
      const cursor = doc?.getCursor();
      doc?.replaceRange(`{{${text}}} `, cursor);
    },
    updateText: (text: any) => {
      const doc = codeMirrorRef?.current?.editor?.getDoc();
      doc?.setValue(text);
    },
    getValue: () => {
      return codeMirrorRef?.current?.editor?.getValue();
    },
    showHint: (tables: any) => {
      codeMirrorRef?.current?.editor?.showHint({
        ...sqlHintDefinitionFromTables(tables),
      });
    },
  }));
  return <CodeMirrorRef ref={codeMirrorRef} {...props} />;
});

export const CodeMirrorRef = forwardRef<any, any>((props, ref: any) => {
  return (
    <CodeMirror
      value=""
      ref={ref}
      onFocus={props.onFocus}
      className="react-codemirror2-width-control"
      options={{
        mode: props.mode,
        indentWithTabs: true,
        smartIndent: true,
        lineNumbers: true,
        matchBrackets: true,
        autofocus: true,
        hintOptions: {
          extraKeys: {
            'Ctrl-Space': 'autocomplete',
          },
        },
        lint: true,
        gutters: ['CodeMirror-lint-markers'],
      }}
    />
  );
});
