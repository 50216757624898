import { generateEmptyColumn } from '@features/objects/generateObject';
import { Mapping, SYNC_TRIGGERS } from '@features/syncs/types';
import { generateId } from '@utils/helpers';

export const emptyMapping: Mapping[] = [
  {
    id: generateId(),
    isStaticValue: false,
    targetColumn: generateEmptyColumn(),
    originColumn: generateEmptyColumn(),
    syncTrigger: SYNC_TRIGGERS.CREATED_UPDATED,
  },
];
