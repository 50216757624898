import { takeLatest, call, put } from "redux-saga/effects";
// api
import * as API from "./api";
// connectionsSlice
import {
  ApiDeleteConnectionPayload,
  ApiGetConnectionsPayload, ApiSaveConnectionPayload,
  DeleteConnectionSaga, GetConnectionPointersSaga,
  GetConnectionsSaga,
  SaveConnectionSaga,
} from "@features/connections/ducks/types";
import connectionsSlice from "@features/connections/ducks/connectionsSlice";
import sourcesSlice from '@features/sources/ducks/sourcesSlice';

function* getConnectionsSaga({ payload }: GetConnectionsSaga) {
  try {
    const { type } = payload;
    const getConnectorsPayload: ApiGetConnectionsPayload = yield call(API.getConnections, type);
    yield put({ type: connectionsSlice.actions.getConnectionsSuccess.type, payload: getConnectorsPayload });
  } catch (error) {
    yield put({ type: connectionsSlice.actions.getConnectionsFailed.type, payload: error });
  }
}

function* saveConnectionSaga({ payload }: SaveConnectionSaga) {
  try {
    const { connection } = payload;
    const getConnectorsPayload: ApiSaveConnectionPayload = yield call(API.saveConnection, connection);
    yield put({ type: connectionsSlice.actions.saveConnectionSuccess.type, payload: getConnectorsPayload });
  } catch (error) {
    yield put({ type: connectionsSlice.actions.saveConnectionFailed.type, payload: error });
  }
}
function* saveSourceConnectionSaga({ payload }: SaveConnectionSaga) {
  try {
    const { connection } = payload;
    console.log(connection)
    const getConnectorsPayload: ApiSaveConnectionPayload = yield call(API.saveConnection, connection);
    yield put({ type: connectionsSlice.actions.saveConnectionSuccess.type, payload: getConnectorsPayload });
    yield put({ type: sourcesSlice.actions.setActiveSourceConnection.type, payload: getConnectorsPayload });
  } catch (error) {
    yield put({ type: connectionsSlice.actions.saveConnectionFailed.type, payload: error });
  }
}


function* deleteConnectionSaga({ payload }: DeleteConnectionSaga) {
  try {
    const { connection } = payload;
    const getConnectorsPayload: ApiDeleteConnectionPayload = yield call(API.deleteConnection, connection);
    yield put({ type: connectionsSlice.actions.deleteConnectionSuccess.type, payload: getConnectorsPayload });
  } catch (error) {
    yield put({ type: connectionsSlice.actions.deleteConnectionFailed.type, payload: error });
  }
}

function* getConnectionPointersSaga({ payload }: GetConnectionPointersSaga) {
  try {
    const { connection } = payload;
    const getConnectorsPayload: ApiSaveConnectionPayload = yield call(API.getConnectionPointer, connection);
    yield put({ type: connectionsSlice.actions.getConnectionPointersSuccess.type, payload: getConnectorsPayload });
  } catch (error) {
    yield put({ type: connectionsSlice.actions.getConnectionPointersFailed.type, payload: error });
  }
}
export const connectionsSagas = [
  takeLatest(connectionsSlice.actions.getConnections.type, getConnectionsSaga),
  takeLatest(connectionsSlice.actions.saveConnection.type, saveConnectionSaga),
  takeLatest(connectionsSlice.actions.saveSourceConnection.type, saveSourceConnectionSaga),
  takeLatest(connectionsSlice.actions.deleteConnection.type, deleteConnectionSaga),
  takeLatest(connectionsSlice.actions.getConnectionPointers.type, getConnectionPointersSaga),
];
