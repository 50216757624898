import { Button, Col, Dropdown, Menu, Radio, Row } from 'antd';
import {
  UserOutlined,
  SecurityScanOutlined,
  SettingOutlined,
  RightOutlined,
} from '@ant-design/icons';
import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { AppContext } from '@providers/AppContextProvider';
import { useRedirectionHandler } from '@handlers/redirectionHandler';
import { OrganizationsState } from '@features/organizations/ducks/organizationsSlice';
import { UsersState } from '@features/users/ducks/usersSlice';
import { OctoDropDown, useOctoDropDown } from '@components/dropDown';
import { MENU_ROUTES } from './types';

interface ConfigurationProps {
  usersState: UsersState;
  organizationsState: OrganizationsState;
  breadcrumb: string[];
  activeMenuItem?: string;
  isSiderCollapsed?: boolean;
  setActiveMenuItem: (value: React.SetStateAction<string | undefined>) => void;
  getMenuItemStyle: (menuKey: string) => string;
  getProfile?: (p: any) => void;
  getUser?: (p: { userId: string; organization: string }) => void;
  getOrganization?: (p: any) => void;
  getStateProfile?: (p: any) => void;
  setLanguage?: (p: any) => void;
  setTheme?: (p: any) => void;
  key?: string;
}
const fullWidthStyle = { width: '100%' };
const btnSettingsIconStyle = { marginRight: '10px' };
const collapsedBtnSettingsIconStyle = { marginTop: '5px' };
const collapsedMenuBtnIconStyle = { marginTop: '-5px' };
const ConfigMenu = ({
  usersState,
  organizationsState,
  isSiderCollapsed,
  setActiveMenuItem,
  getMenuItemStyle,
  getOrganization,
  getUser,
  setLanguage,
}: ConfigurationProps) => {
  const { t } = useTranslation();
  const settingsDropDown = useOctoDropDown();
  const redirectHandler = useRedirectionHandler();
  const [profile, setProfile] = useState('data');
  const appContext = useContext(AppContext);
  //  const visibleFeatures = appContext.visibleFeatures;

  const handleLanguageChange = (e: any) => {
    if (setLanguage) setLanguage({ language: e.target.value });
  };
  const handleUserChange = (e: any) => {
    if (getUser) {
      getUser({ userId: e.target.value, organization: organizationsState.organization.key });
      setProfile(e.target.value);
    }
  };
  const handleOrganizationChange = (e: any) => {
    if (getOrganization) getOrganization({ organization: e.target.value });
    if (getUser) getUser({ userId: 'admin', organization: e.target.value });
  };

  const usersOptions = () => {
    const usersOptions = usersState.users.map((user) => {
      const organizationAccess = user.access.find(
        (a) => a.organization === organizationsState.organization.key
      );
      if (organizationAccess) {
        return {
          label: user.firstName,
          value: organizationAccess.userProfile,
        };
      }
    });
    return usersOptions.filter((u) => u !== undefined);
  };
  return (
    <Menu theme="dark" mode={'inline'} className={'config-menu'}>
      <Menu.Item key="controlCenter" disabled={false}>
        <Button
          type={'text'}
          onClick={() => {
            setActiveMenuItem(MENU_ROUTES.ACCOUNT_MANAGEMENT);
            redirectHandler.goToAccountManagement();
          }}
          className={getMenuItemStyle(MENU_ROUTES.ACCOUNT_MANAGEMENT)}
          icon={<SecurityScanOutlined style={isSiderCollapsed ? collapsedMenuBtnIconStyle : {}} />}
        >
          {'Control center'}
        </Button>
      </Menu.Item>

      <OctoDropDown
        {...settingsDropDown}
        containerClassName="configuration-menu__settings-container"
        placement="right"
        triggerContent={
          <Button
            type="text"
            style={fullWidthStyle}
            className={
              settingsDropDown.visible
                ? 'sider-menu-item-button sider-menu-item-selected'
                : 'sider-menu-item-button'
            }
            icon={
              <SettingOutlined
                style={isSiderCollapsed ? collapsedBtnSettingsIconStyle : btnSettingsIconStyle}
              />
            }
          >
            {!isSiderCollapsed && 'Settings'}
            {!isSiderCollapsed && <RightOutlined style={{ marginLeft: '100px' }} />}
          </Button>
        }
        content={
          <SettingsMenu
            appContext={appContext}
            profile={profile}
            handleLanguageChange={handleLanguageChange}
            handleUserChange={handleUserChange}
            usersOptions={usersOptions}
            organizationsState={organizationsState}
            handleOrganizationChange={handleOrganizationChange}
          />
        }
      />

      <Menu.Item key="account" disabled={false}>
        <Button
          type={'text'}
          onClick={() => {
            setActiveMenuItem(MENU_ROUTES.ACCOUNT_MANAGEMENT);
            redirectHandler.goToAccountManagement();
          }}
          className={getMenuItemStyle(MENU_ROUTES.ACCOUNT_MANAGEMENT)}
          icon={<UserOutlined style={isSiderCollapsed ? collapsedMenuBtnIconStyle : {}} />}
        >
          {'Account'}
        </Button>
      </Menu.Item>
      {/*<Menu.Item key="support" disabled={false} icon={<InfoOutlined />}>*/}
      {/*  <Link to="/controlCenter">{"Support"}</Link>*/}
      {/*</Menu.Item>*/}
    </Menu>
  );
};
const SettingsMenu = ({
  appContext,
  profile,
  handleLanguageChange,
  handleUserChange,
  usersOptions,
  organizationsState,
  handleOrganizationChange,
}: any) => {
  return (
    <div className="configuration-menu__settings-menu-content">
      <div className="configuration-menu__settings-menu-content-item">
        <div>
          <p>Language :</p>
        </div>
        <div style={{ marginLeft: 10 }}>
          <Radio.Group size={'small'} value={appContext.language} onChange={handleLanguageChange}>
            <Radio.Button value="fr">French</Radio.Button>
            <Radio.Button value="en">English</Radio.Button>
          </Radio.Group>
        </div>
      </div>

      <div className="configuration-menu__settings-menu-content-item">
        <div>
          <p>Users :</p>
        </div>
        <div style={{ marginLeft: 10 }}>
          <Radio.Group size={'small'} value={profile} onChange={handleUserChange}>
            {usersOptions().map((option: any, i: number) => (
              <Radio.Button key={i} value={option.value}>
                {option.label}
              </Radio.Button>
            ))}
          </Radio.Group>
        </div>
      </div>

      <div className="configuration-menu__settings-menu-content-item">
        <div>
          <p>Organizations :</p>
        </div>
        <div style={{ marginLeft: 10 }}>
          <Radio.Group
            size={'small'}
            value={organizationsState.organization.key}
            onChange={handleOrganizationChange}
          >
            <Radio.Button value={'octolis'}>Octolis</Radio.Button>
            <Radio.Button value={'sendinblue'}>Sendinblue</Radio.Button>
            <Radio.Button value={'splio'}>Splio</Radio.Button>
          </Radio.Group>
        </div>
      </div>
    </div>
  );
};
export default ConfigMenu;
