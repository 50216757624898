// Importing type
import * as apiTypes from './types';
import { AppContextType } from '@features/appContext/types';
import { sleep } from '@utils/helpers';

const getAppContext: apiTypes.APIGetAppContext = async (name: string) => {
  await sleep(1000);

  let appContext: AppContextType;
  let error: boolean = false;
  let appNameDisplayed: string;
  let errorDetails: string | undefined = undefined;

  appNameDisplayed = `${name.charAt(0).toUpperCase()}${name.slice(1)}`;
  if (name === 'sendinblue') {
    appContext = {
      language: 'en',
      labels: {
        appNameDisplayed,
        appName: name,
        copyright: `©${new Date().getFullYear()} ${appNameDisplayed}`,
      },
      layoutPreferences: {
        sideBarCollapsed: false,
      },
      accessibleFeatures: [
        'connections',
        'sources',
        'flows',
        'recipes',
        'destinations',
        'objects',
        'sourceFlows',
        'internalFlows',
        'destinationFlows',
        'accountManagement',
        'syncs',
        'segments',
      ],
      visibleFeatures: [
        'connections',
        'sources',
        'flows',
        'recipes',
        'destinations',
        'objects',
        'sourceFlows',
        'internalFlows',
        'destinationFlows',
        'accountManagement',
        'syncs',
        'segments',
      ],
      i18nlabels: {},
      theme: {
        name,
        variables: {
          '--main-primary-color': '#000000',
          '--main-bg-color': '#044A75',
          '--main-text-color': '#444',
          '--main-select-input-child-button': '#3c3fee',
          '--layout-trigger-background': '#044A95',
          '--menu-dark-inline-submenu-bg': '#242424',
        },
      },
      pureReverseETl: true,
      logoStyle: {
        top: 'calc(50% - 8px)',
        width: '150px',
        height: '18px',
      },
    };
  } else if (name === 'splio') {
    appContext = {
      language: 'en',
      labels: {
        appNameDisplayed,
        appName: name,
        copyright: `©${new Date().getFullYear()} ${appNameDisplayed}`,
      },
      layoutPreferences: {
        sideBarCollapsed: false,
      },
      accessibleFeatures: ['connections', 'syncs', 'segments'],
      visibleFeatures: ['connections', 'syncs', 'segments'],
      i18nlabels: {},
      theme: {
        name,
        variables: {
          '--main-primary-color': '#27416B',
          '--main-bg-color': '#27416B',
          '--main-text-color': '#444',
          '--main-select-input-child-button': '#3c3fee',
          '--layout-trigger-background': '#27418B',
          '--menu-dark-inline-submenu-bg': '#27416B',
        },
      },
      logoStyle: {
        top: 'calc(50% - 20px)',
        width: '150px',
        height: '40px',
      },
    };
  } else if (name === 'octolis') {
    appContext = {
      language: 'en',
      labels: {
        appNameDisplayed,
        appName: name,
        copyright: `©${new Date().getFullYear()} ${appNameDisplayed}`,
      },
      layoutPreferences: {
        sideBarCollapsed: false,
      },
      accessibleFeatures: ['recommandations','connections', 'syncs', 'segments'],
      visibleFeatures: ['recommandations', 'connections', 'syncs', 'segments'],
      i18nlabels: {},
      theme: {
        name,
        variables: {
          '--main-primary-color': '#27416B',
          '--main-bg-color': '#161033',
          '--main-text-color': '#444',
          '--main-select-input-child-button': '#3c3fee',
          '--layout-trigger-background': '#161053',
          '--menu-dark-inline-submenu-bg': '#27416B',
        },
      },
      logoStyle: {
        top: 'calc(50% - 20px)',
        width: '150px',
        height: '40px',
      },
    };
  } else {
    appContext = {
      language: 'en',
      labels: {
        appNameDisplayed,
        appName: name,
        copyright: `©${new Date().getFullYear()} ${appNameDisplayed}`,
      },
      layoutPreferences: {
        sideBarCollapsed: false,
      },
      accessibleFeatures: [],
      visibleFeatures: [],
      i18nlabels: {},
      theme: {
        name,
        variables: {
          '--main-primary-color': '#560149',
          '--main-bg-color': '#1c0228',
          '--main-text-color': '#444',
          '--main-select-input-child-button': '#3c3fee',
          '--layout-trigger-background': '#1d011c',
          '--menu-dark-inline-submenu-bg': '#0a0109',
        },
      },
      logoStyle: {
        top: 'calc(50% - 20px)',
        width: '150px',
        height: '30px',
      },
    };
  }

  if (!appContext) {
    error = true;
    errorDetails = 'No appContext found.';
  }

  return {
    error,
    appContext,
    loaded: true,
    errorDetails: error && errorDetails ? errorDetails : undefined,
  };
};

const saveAppContext: apiTypes.APISaveAppContext = async (appContext: AppContextType) => {
  await sleep(1000);

  let error: boolean = false;
  let errorDetails: string | undefined = undefined;

  if (!appContext) {
    error = true;
    errorDetails = 'No appContext found.';
  }
  return {
    appContext,
    error,
    loaded: true,
    errorDetails: error && errorDetails ? errorDetails : undefined,
  };
};

export { getAppContext, saveAppContext };
