import { takeLatest, call, put } from 'redux-saga/effects';
// api
import * as API from './api';
// connectionsSlice
import usersSlice from '@features/users/ducks/usersSlice';
// types
import * as types from './types';
import profilesSlice from '@features/profiles/ducks/profilesSlice';

function* getUsersSaga({ payload }: types.GetUsersSaga) {
  try {
    const { app } = payload;
    const getUsersPayload: types.ApiGetUsersPayload = yield call(API.getUsers, app);
    yield put({ type: usersSlice.actions.getUsersSuccess.type, payload: getUsersPayload });
  } catch (error) {
    yield put({ type: usersSlice.actions.getUsersFailed.type, payload: error });
  }
}

function* getUserSaga({ payload }: types.GetUserSaga) {
  try {
    const { userId, organization } = payload;
    const getUserPayload: types.ApiGetUserPayload = yield call(API.getUser, userId, organization);
    yield put({ type: usersSlice.actions.getUserSuccess.type, payload: getUserPayload });
    // @@@Amine fake update to profile state
    const profileName = getUserPayload.user.access.find((a) => a.organization === organization)
      ?.userProfile;
    yield put({ type: profilesSlice.actions.getStateProfile.type, payload: { profileName } });
  } catch (error) {
    yield put({ type: usersSlice.actions.getUserFailed.type, payload: error });
  }
}

function* saveUserSaga({ payload }: types.SaveUserSaga) {
  try {
    const { user } = payload;
    const saveUserPayload: types.ApiGetUserPayload = yield call(API.saveUser, user);
    yield put({ type: usersSlice.actions.saveUserSuccess.type, payload: saveUserPayload });
  } catch (error) {
    yield put({ type: usersSlice.actions.saveUserFailed.type, payload: error });
  }
}

function* deleteUserSaga({ payload }: types.DeleteUserSaga) {
  try {
    const { userId } = payload;
    const deleteUserPayload: types.ApiGetUserPayload = yield call(API.deleteUser, userId);
    yield put({ type: usersSlice.actions.deleteUserSuccess.type, payload: deleteUserPayload });
  } catch (error) {
    yield put({ type: usersSlice.actions.deleteUserSuccess.type, payload: error });
  }
}

export const usersSagas = [
  takeLatest(usersSlice.actions.getUsers.type, getUsersSaga),
  takeLatest(usersSlice.actions.getUser.type, getUserSaga),
  takeLatest(usersSlice.actions.saveUser.type, saveUserSaga),
  takeLatest(usersSlice.actions.deleteUser.type, deleteUserSaga),
];
