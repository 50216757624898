import React, { useEffect } from 'react';
import { OctoTable, useOctoTable } from '@components/table';
import { TableRecord } from '@components/table/types';
import { extractObjectKeys } from '@utils/helpers';

interface QueryResultsTableProps {
  records?: TableRecord[];
  loading?: boolean;
}

const QueryResultsTable = ({ records, loading }: QueryResultsTableProps) => {
  const octoTable = useOctoTable([]);

  const tableColumnsFromQueryRecordResult = (result: {[key:string]:string}) => {
    const resultKeys = extractObjectKeys(result);
    console.log(resultKeys);
    return resultKeys.map((key) => {
      return {
        key,
        title: key,
      };
    });
  };
  useEffect(() => {
    const tablesColumns = tableColumnsFromQueryRecordResult(records && records[0] ? records[0]: []);
    octoTable.putColumns(tablesColumns);
    octoTable.putRecords(records);
  }, [records]);

  return <OctoTable {...octoTable} tableLoading={loading} />;
};

export default QueryResultsTable;
