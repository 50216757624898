import React, { useState } from 'react';
import { Drawer } from 'antd';
const drawerDefaultStyle = { padding: 0 };
const useOctoDrawer = () => {
  const [content, setContent] = useState(<></>);
  const [visibility, setVisibility] = useState(false);
  const [closable, setClosable] = useState(true);
  const [width, setWidth] = useState('60%');

  return {
    visibility,
    closable,
    content,
    width,
    putWidth: setWidth,
    putContent: (content: JSX.Element) => {
      setVisibility(true);
      setContent(content);
    },
    putClosable: setClosable,
    onClose: () => {
      setContent(<></>);
      setVisibility(false);
    },
  };
};

interface OctoDrawerProps {
  onClose: () => void;
  visibility: boolean;
  closable: boolean;
  content: JSX.Element;
  width: string;
}

const OctoDrawer = ({ onClose, visibility, closable, content, width }: OctoDrawerProps) => {
  return (
    <Drawer
      onClose={onClose}
      closable={closable}
      visible={visibility}
      style={drawerDefaultStyle}
      width={width}
    >
      {content}
    </Drawer>
  );
};
export { useOctoDrawer, OctoDrawer };
