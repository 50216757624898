// Importing type
import * as apiTypes from './types';
import {
  BusinessCategory,
  OctoObject,
  OctoObjectCategory,
  SchemaColumn,
} from '@features/objects/types';
import { OctoStats } from '@features/objects/statistics/types';
import { generateId, generateRandomInteger, sleep } from '@utils/helpers';
import { objects } from '@data/objects';
import { generateObjectFromRecords, generateSchemaFromRecords } from '../generateObject';
import { RawRecord } from '@features/rawRecords/types';
import {
  contactsRecordsGenerator,
  ordersRecordsGenerator,
  productsRecordsGenerator,
} from '@utils/recordsGenerator';
import { businessCategoriesDefaultList } from '@data/businessCategory';
import { defaultColumns } from '@data/objectsColumns';

const getObjects: apiTypes.APIGetObjects = async () => {
  await sleep(1500);
  let error: boolean = false;
  let errorDetails: string | undefined = undefined;
  if (!objects) {
    error = true;
    errorDetails = 'No objects found.';
  }
  return {
    objects,
    error,
    loaded: true,
    errorDetails: error && errorDetails ? errorDetails : undefined,
  };
};

const getObject: apiTypes.APIGetObject = async (objectKey: string) => {
  await sleep(1500);
  let object: OctoObject;
  let error: boolean = false;
  let errorDetails: string | undefined = undefined;

  object = {
    key: 'object1',
    name: 'Contacts',
    tableName: 'Contacts',
    recordsNumber: 152000,
    records: [
      {
        name: 'Alfred',
        email: 'Alfred@gmail.com',
        phone: '26154859',
        emails_org: 'Alfred@org.co',
      },
      {
        name: 'Blfred',
        email: 'Alfred@gmail.com',
        phone: '26154859',
        emails_org: 'Alfred@org.co',
      },
    ],
    pointer: 'octolis_raw.contacts_ceigd',
    created: new Date(),
    category: 'contacts',
    schema: [
      {
        id: 'col1',
        columnName: 'name',
        columnType: 'string',
        required: false,
        category: 'firstName',
        description: 'this is a description of the column',
        position: 1,
      },
      {
        id: 'col2',
        columnName: 'email',
        columnType: 'string',
        required: false,
        category: 'email',
        description: 'this is a description of the column',
        position: 2,
      },
      {
        id: 'col3',
        columnName: 'phone',
        columnType: 'string',
        required: false,
        category: 'phone',
        description: 'this is a description for the column',
        position: 3,
      },
      {
        id: 'col4',
        columnName: 'emails_org',
        columnType: 'string',
        required: false,
        category: 'email',
        description: 'this is a description of the column',
        position: 4,
      },
    ],
    primaryKeys: ['col4'],
  };

  if (!object) {
    error = true;
    errorDetails = 'No object found.';
  }
  return {
    object,
    error,
    loaded: true,
    errorDetails: error && errorDetails ? errorDetails : undefined,
  };
};
const randomInteger = generateRandomInteger(8, 50);
const getRecordsObject: apiTypes.APIGetRecords = async (connection, pointer) => {
  await sleep(1500);
  let object: OctoObject;
  let records: RawRecord[];
  let category: string;
  let error: boolean = false;
  let updatedAtColumnID: string;
  let errorDetails: string | undefined = undefined;

  if (pointer.indexOf('contacts') !== -1) {
    records = contactsRecordsGenerator(randomInteger);
    category = 'contacts';
  } else if (pointer.indexOf('orders') !== -1) {
    records = ordersRecordsGenerator(randomInteger);
    category = 'orders';
  } else if (pointer.indexOf('products') !== -1) {
    records = productsRecordsGenerator(randomInteger);
    category = 'products';
  } else {
    category = 'products';
    records = productsRecordsGenerator(randomInteger);
  }
  const sourceObjectSchema = generateSchemaFromRecords(records[0]);
  const updatedAtColumn = sourceObjectSchema.find((c) => c.columnName === 'updatedAt');
  if (updatedAtColumn) {
    updatedAtColumnID = updatedAtColumn?.id;
  } else {
    updatedAtColumnID = sourceObjectSchema[0].id;
  }
  object = generateObjectFromRecords(
    `object_${pointer}`,
    pointer,
    pointer,
    category,
    sourceObjectSchema,
    [sourceObjectSchema[0].id],
    records.length,
    records,
    updatedAtColumnID
  );
  if (!records) {
    error = true;
    errorDetails = 'No Records found.';
  }
  return {
    error,
    object: object,
    loaded: true,
    errorDetails: error && errorDetails ? errorDetails : undefined,
  };
};
const saveObject: apiTypes.APISaveObject = async (object: OctoObject) => {
  await sleep(1500);

  let error: boolean = false;
  let errorDetails: string | undefined = undefined;

  if (!object) {
    error = true;
    errorDetails = 'No object found.';
  }
  return {
    error,
    object,
    loaded: true,
    errorDetails: error && errorDetails ? errorDetails : undefined,
  };
};

const deleteObject: apiTypes.APIDeleteObject = async (object: OctoObject) => {
  await sleep(1500);

  let error: boolean = false;
  let errorDetails: string | undefined = undefined;

  if (!object) {
    error = true;
    errorDetails = 'Impossible to object object (not found).';
  }
  return {
    error,
    object,
    loaded: true,
    errorDetails: error && errorDetails ? errorDetails : undefined,
  };
};
// New
const getBusinessCategories: apiTypes.APIGetBusinessCategories = async () => {
  await sleep(1500);
  let businessCategories: BusinessCategory[];
  let error: boolean = false;
  let errorDetails: string | undefined = undefined;
  businessCategories = businessCategoriesDefaultList;
  if (!businessCategories) {
    error = true;
    errorDetails = 'No categories found.';
  }
  return {
    businessCategories,
    error,
    loaded: true,
    errorDetails: error && errorDetails ? errorDetails : undefined,
  };
};

const getBusinessCategory: apiTypes.APIGetBusinessCategory = async (
  businessCategoryKey: string
) => {
  await sleep(1500);
  let error: boolean = false;
  let errorDetails: string | undefined = undefined;

  if (!businessCategoryKey) {
    error = true;
    errorDetails = 'No category found.';
  }
  return {
    businessCategoryKey,
    error,
    loaded: true,
    errorDetails: error && errorDetails ? errorDetails : undefined,
  };
};

const saveBusinessCategory: apiTypes.APISaveBusinessCategory = async (name: string) => {
  await sleep(1500);

  let error: boolean = false;
  let errorDetails: string | undefined = undefined;
  let businessCategory: BusinessCategory = { key: generateId(), columns: defaultColumns, name };
  if (!businessCategory) {
    error = true;
    errorDetails = 'No category found.';
  }
  return {
    error,
    businessCategory,
    loaded: true,
    errorDetails: error && errorDetails ? errorDetails : undefined,
  };
};
// BusinessTerm is a SchemaColumn of business category
const saveBusinessTermToBusinessCategory: apiTypes.APISaveBusinessTermToBusinessCategory = async (
  businessCategory: BusinessCategory,
  businessTermName: string,
  businessTermType: string
) => {
  await sleep(1500);
  let error: boolean = false;
  let errorDetails: string | undefined = undefined;
  const newBusinessTerm: SchemaColumn = {
    id: generateId(),
    columnName: businessTermName,
    columnType: businessTermType,
    category: businessTermType,
    description: businessTermName,
    position: businessCategory.columns.length + 1,
    required: true,
  };
  const newColumns: SchemaColumn[] = [...businessCategory.columns, newBusinessTerm];
  const businessCategoryNewValues: BusinessCategory = { ...businessCategory, columns: newColumns };
  if (!businessCategory) {
    error = true;
    errorDetails = 'No category found.';
  }
  return {
    error,
    businessCategory: businessCategoryNewValues,
    businessTerm: newBusinessTerm,
    loaded: true,
    errorDetails: error && errorDetails ? errorDetails : undefined,
  };
};

const deleteBusinessCategory: apiTypes.APIDeleteBusinessCategory = async (
  businessCategory: BusinessCategory
) => {
  await sleep(1500);

  let error: boolean = false;
  let errorDetails: string | undefined = undefined;

  if (!businessCategory) {
    error = true;
    errorDetails = 'Impossible to delete category (not found).';
  }
  return {
    error,
    businessCategory,
    loaded: true,
    errorDetails: error && errorDetails ? errorDetails : undefined,
  };
};

// Old
const getObjectCategories: apiTypes.APIGetObjectCategories = async () => {
  await sleep(1500);
  let categories: OctoObjectCategory[];
  let error: boolean = false;
  let errorDetails: string | undefined = undefined;
  categories = [
    {
      key: 'contacts',
      value: 'contacts',
    },
    {
      key: 'products',
      value: 'products',
    },
    {
      key: 'orders',
      value: 'orders',
    },
  ];
  if (!categories) {
    error = true;
    errorDetails = 'No categories found.';
  }
  return {
    categories,
    error,
    loaded: true,
    errorDetails: error && errorDetails ? errorDetails : undefined,
  };
};
const getObjectCategory: apiTypes.APIGetCategory = async (categoryKey: string) => {
  await sleep(1500);
  let category: OctoObjectCategory;
  let error: boolean = false;
  let errorDetails: string | undefined = undefined;

  category = {
    key: 'contacts',
    value: 'contacts',
  };

  if (!category) {
    error = true;
    errorDetails = 'No category found.';
  }
  return {
    category,
    error,
    loaded: true,
    errorDetails: error && errorDetails ? errorDetails : undefined,
  };
};
const saveCategory: apiTypes.APISaveCategory = async (category: OctoObjectCategory) => {
  await sleep(1500);

  let error: boolean = false;
  let errorDetails: string | undefined = undefined;

  if (!category) {
    error = true;
    errorDetails = 'No category found.';
  }
  return {
    error,
    category,
    loaded: true,
    errorDetails: error && errorDetails ? errorDetails : undefined,
  };
};
const deleteCategory: apiTypes.APIDeleteCategory = async (category: OctoObjectCategory) => {
  await sleep(1500);

  let error: boolean = false;
  let errorDetails: string | undefined = undefined;

  if (!category) {
    error = true;
    errorDetails = 'Impossible to delete category (not found).';
  }
  return {
    error,
    category,
    loaded: true,
    errorDetails: error && errorDetails ? errorDetails : undefined,
  };
};

const getSchemaPointers: apiTypes.APIGetSchemaPointers = async () => {
  await sleep(1500);
  let schemaPointers: any[];
  let error: boolean = false;
  let errorDetails: string | undefined = undefined;
  schemaPointers = [
    { value: 'octolisInput', title: 'Octolis input' },
    { value: 'octolisRaw', title: 'Octolis raw' },
  ];
  if (!schemaPointers) {
    error = true;
    errorDetails = 'No schema pointers found.';
  }
  return {
    schemaPointers,
    error,
    loaded: true,
    errorDetails: error && errorDetails ? errorDetails : undefined,
  };
};
const getSchemaCategories: apiTypes.APIGetSchemaCategories = async () => {
  await sleep(1500);
  let schemaCategories: OctoObjectCategory[];
  let error: boolean = false;
  let errorDetails: string | undefined = undefined;
  schemaCategories = [
    { key: 'Date', value: 'Date' },
    { key: 'firstName', value: 'firstName' },
    { key: 'price', value: 'price' },
    { key: 'key', value: 'key' },
    { key: 'email', value: 'email' },
    { key: 'phone', value: 'phone' },
  ];
  if (!schemaCategories) {
    error = true;
    errorDetails = 'No categories found.';
  }
  return {
    schemaCategories,
    error,
    loaded: true,
    errorDetails: error && errorDetails ? errorDetails : undefined,
  };
};
const getSchemaCategory: apiTypes.APIGetSchemaCategory = async (categoryKey: string) => {
  await sleep(1500);
  let schemaCategory: OctoObjectCategory;
  let error: boolean = false;
  let errorDetails: string | undefined = undefined;

  schemaCategory = {
    key: 'firstName',
    value: 'firstName',
  };

  if (!schemaCategory) {
    error = true;
    errorDetails = 'No category found.';
  }
  return {
    schemaCategory,
    error,
    loaded: true,
    errorDetails: error && errorDetails ? errorDetails : undefined,
  };
};

const saveSchemaCategory: apiTypes.APISaveSchemaCategory = async (
  schemaCategory: OctoObjectCategory
) => {
  await sleep(1500);

  let error: boolean = false;
  let errorDetails: string | undefined = undefined;

  if (!schemaCategory) {
    error = true;
    errorDetails = 'No category found.';
  }
  return {
    error,
    schemaCategory,
    loaded: true,
    errorDetails: error && errorDetails ? errorDetails : undefined,
  };
};
const deleteSchemaCategory: apiTypes.APIDeleteSchemaCategory = async (
  schemaCategory: OctoObjectCategory
) => {
  await sleep(1500);

  let error: boolean = false;
  let errorDetails: string | undefined = undefined;

  if (!schemaCategory) {
    error = true;
    errorDetails = 'Impossible to delete category (not found).';
  }
  return {
    error,
    schemaCategory,
    loaded: true,
    errorDetails: error && errorDetails ? errorDetails : undefined,
  };
};

const getObjectRecordStats: apiTypes.APIgetObjectRecordStats = async () => {
  await sleep(1500);
  let ObjectRecordStats: OctoStats[];
  let error: boolean = false;
  let errorDetails: string | undefined = undefined;
  // objectDetails = [to fill with data]
  ObjectRecordStats = [
    {
      key: 0,
      columnName: 'Column 1',
      columnType: 'string',
      null_data: [300, 10, 50],
      null_percent: 84,
      summary_table_data: [
        {
          key: 1,
          stats: 'Distinct values',
          value: 120,
        },
        {
          key: 2,
          stats: 'Uniques',
          value: 0,
        },
        {
          key: 3,
          stats: 'Invalids',
          value: 0,
        },
      ],
      chart_data: [
        {
          key: 1,
          value: 'Hoodie',
          count: 500,
          percentage: 50,
          cumulative_percentage: 50,
        },
        {
          key: 2,
          value: 'White T-Shirt M',
          count: 25,
          percentage: 25,
          cumulative_percentage: 75,
        },
        {
          key: 3,
          value: 'Yello T-Shirt M',
          count: 25,
          percentage: 25,
          cumulative_percentage: 100,
        },
      ],
    },
    {
      key: 1,
      columnName: 'Column 2',
      columnType: 'number',
      null_data: [300, 0, 0],
      null_percent: 100,
      summary_table_data: [
        {
          key: 1,
          stats: 'Min',
          value: 0,
        },
        {
          key: 2,
          stats: 'Max',
          value: 74246,
        },
        {
          key: 3,
          stats: 'Mean',
          value: 37043,
        },
        {
          key: 4,
          stats: 'Median',
          value: 36979,
        },
        {
          key: 5,
          stats: 'StdDev',
          value: 21577,
        },
        {
          key: 6,
          stats: 'Mode',
          value: 0,
        },
        {
          key: 7,
          stats: 'Distinct',
          value: 10000,
        },
        {
          key: 8,
          stats: 'IQR',
          value: 37302,
        },
        {
          key: 9,
          stats: 'Sum',
          value: 370426792,
        },
      ],
      chart_data: {
        labels: ['1', '2', '3', '4', '5'],
        datasets: [
          {
            label: 'Sample Values',
            data: [100, 200, 300, 250, 50],
            backgroundColor: ['rgb(0,151,167,0.7)'],
            borderColor: ['rgb(0,151,167,1)'],
          },
        ],
      },
    },
    {
      key: 2,
      columnName: 'Column 3',
      columnType: 'date',
      null_data: [300, 10, 50],
      null_percent: 84,
      summary_table_data: [
        {
          key: 1,
          stats: 'Distinct values',
          value: 120,
        },
        {
          key: 2,
          stats: 'Months without data',
          value: '',
        },
      ],
      chart_data: {
        labels: [],
        datasets: [
          {
            label: 'Sample Values',
            data: [
              { x: '2020-01-01', y: 50 },
              { x: '2021-07-01', y: 100 },
              { x: '2021-07-02', y: 200 },
              { x: '2021-07-03', y: 300 },
              { x: '2021-07-04', y: null },
              { x: '2021-07-05', y: 500 },
            ],
            backgroundColor: [
              'rgb(0,151,167,0.7)',
              'rgb(0,151,167,0.7)',
              'rgb(0,151,167,0.7)',
              'rgb(0,151,167,0.7)',
              'rgb(0,151,167,0.7)',
            ],
            borderColor: [
              'rgb(0,151,167,1)',
              'rgb(0,151,167,1)',
              'rgb(0,151,167,1)',
              'rgb(0,151,167,1)',
              'rgb(0,151,167,1)',
            ],
          },
        ],
      },
      chart_data_month: {
        labels: [],
        datasets: [
          {
            label: 'Sample Values',
            data: [
              { x: 'January', y: 100 },
              { x: 'February', y: 200 },
              { x: 'March', y: 300 },
              { x: 'April', y: 400 },
              { x: 'May', y: 500 },
            ],
            backgroundColor: [
              'rgb(0,151,167,0.7)',
              'rgb(0,151,167,0.7)',
              'rgb(0,151,167,0.7)',
              'rgb(0,151,167,0.7)',
              'rgb(0,151,167,0.7)',
            ],
            borderColor: [
              'rgb(0,151,167,1)',
              'rgb(0,151,167,1)',
              'rgb(0,151,167,1)',
              'rgb(0,151,167,1)',
              'rgb(0,151,167,1)',
            ],
          },
        ],
      },
      chart_data_year: {
        labels: [],
        datasets: [
          {
            label: 'Sample Values',
            data: [
              { x: '2018', y: 100 },
              { x: '2019', y: 200 },
              { x: '2020', y: 300 },
              { x: '2021', y: 400 },
            ],
            backgroundColor: [
              'rgb(0,151,167,0.7)',
              'rgb(0,151,167,0.7)',
              'rgb(0,151,167,0.7)',
              'rgb(0,151,167,0.7)',
            ],
            borderColor: [
              'rgb(0,151,167,1)',
              'rgb(0,151,167,1)',
              'rgb(0,151,167,1)',
              'rgb(0,151,167,1)',
            ],
          },
        ],
      },
    },
    {
      key: 3,
      columnName: 'Column 4',
      columnType: 'string',
      null_data: [300, 10, 50],
      null_percent: 84,
      summary_table_data: [
        {
          key: 1,
          stats: 'Distinct values',
          value: 120,
        },
        {
          key: 2,
          stats: 'Uniques',
          value: 0,
        },
        {
          key: 3,
          stats: 'Invalids',
          value: 0,
        },
      ],
      chart_data: [
        {
          key: 1,
          value: 'Hoodie',
          count: 500,
          percentage: 50,
          cumulative_percentage: 50,
        },
        {
          key: 2,
          value: 'White T-Shirt M',
          count: 25,
          percentage: 25,
          cumulative_percentage: 75,
        },
        {
          key: 3,
          value: 'Yello T-Shirt M',
          count: 25,
          percentage: 25,
          cumulative_percentage: 100,
        },
      ],
    },
    {
      key: 4,
      columnName: 'Column 5',
      columnType: 'number',
      null_data: [300, 0, 0],
      null_percent: 100,
      summary_table_data: [
        {
          key: 1,
          stats: 'Min',
          value: 0,
        },
        {
          key: 2,
          stats: 'Max',
          value: 74246,
        },
        {
          key: 3,
          stats: 'Mean',
          value: 37043,
        },
        {
          key: 4,
          stats: 'Median',
          value: 36979,
        },
        {
          key: 5,
          stats: 'StdDev',
          value: 21577,
        },
        {
          key: 6,
          stats: 'Mode',
          value: 0,
        },
        {
          key: 7,
          stats: 'Distinct',
          value: 10000,
        },
        {
          key: 8,
          stats: 'IQR',
          value: 37302,
        },
        {
          key: 9,
          stats: 'Sum',
          value: 370426792,
        },
      ],
      chart_data: {
        labels: ['1', '2', '3', '4', '5'],
        datasets: [
          {
            label: 'Sample Values',
            data: [100, 200, 300, 250, 50],
            backgroundColor: ['rgb(0,151,167,0.7)'],
            borderColor: ['rgb(0,151,167,1)'],
          },
        ],
      },
    },
    {
      key: 5,
      columnName: 'Column 6',
      columnType: 'date',
      null_data: [300, 10, 50],
      null_percent: 84,
      summary_table_data: [
        {
          key: 1,
          stats: 'Distinct values',
          value: 120,
        },
        {
          key: 2,
          stats: 'Months without data',
          value: 'February, Mars, April, May, June',
        },
      ],
      chart_data: {
        labels: [],
        datasets: [
          {
            label: 'Sample Values',
            data: [
              { x: '2021-01-01', y: 100 },
              { x: '2021-07-01', y: 200 },
              { x: '2021-07-02', y: 300 },
              { x: '2021-07-04', y: 400 },
              { x: '2021-07-11', y: 500 },
            ],
            backgroundColor: [
              'rgb(0,151,167,0.7)',
              'rgb(0,151,167,0.7)',
              'rgb(0,151,167,0.7)',
              'rgb(0,151,167,0.7)',
              'rgb(0,151,167,0.7)',
            ],
            borderColor: [
              'rgb(0,151,167,1)',
              'rgb(0,151,167,1)',
              'rgb(0,151,167,1)',
              'rgb(0,151,167,1)',
              'rgb(0,151,167,1)',
            ],
          },
        ],
      },
      chart_data_month: {
        labels: [],
        datasets: [
          {
            label: 'Sample Values',
            data: [
              { x: 'January', y: 100 },
              { x: 'February', y: 200 },
              { x: 'March', y: 300 },
              { x: 'April', y: 400 },
              { x: 'May', y: 500 },
            ],
            backgroundColor: [
              'rgb(0,151,167,0.7)',
              'rgb(0,151,167,0.7)',
              'rgb(0,151,167,0.7)',
              'rgb(0,151,167,0.7)',
              'rgb(0,151,167,0.7)',
            ],
            borderColor: [
              'rgb(0,151,167,1)',
              'rgb(0,151,167,1)',
              'rgb(0,151,167,1)',
              'rgb(0,151,167,1)',
              'rgb(0,151,167,1)',
            ],
          },
        ],
      },
      chart_data_year: {
        labels: [],
        datasets: [
          {
            label: 'Sample Values',
            data: [
              { x: '2018', y: 100 },
              { x: '2019', y: 200 },
              { x: '2020', y: 300 },
              { x: '2021', y: 400 },
            ],
            backgroundColor: [
              'rgb(0,151,167,0.7)',
              'rgb(0,151,167,0.7)',
              'rgb(0,151,167,0.7)',
              'rgb(0,151,167,0.7)',
            ],
            borderColor: [
              'rgb(0,151,167,1)',
              'rgb(0,151,167,1)',
              'rgb(0,151,167,1)',
              'rgb(0,151,167,1)',
            ],
          },
        ],
      },
    },
  ];

  if (!ObjectRecordStats) {
    error = true;
    errorDetails = 'No ObjectRecordStats found.';
  }
  return {
    ObjectRecordStats,
    error,
    loaded: true,
    errorDetails: error && errorDetails ? errorDetails : undefined,
  };
};
const getObjectRecordStatByColumn: apiTypes.APIGetObjectRecordStatByColumn = async (
  column: SchemaColumn
) => {
  await sleep(1500);
  let ObjectRecordStat: OctoStats;
  let error: boolean = false;
  let errorDetails: string | undefined = undefined;

  ObjectRecordStat =
    column.columnType === 'string'
      ? {
          key: 0,
          columnName: column.columnName,
          columnType: column.columnType,
          null_data: [300, 10, 50],
          null_percent: generateRandomInteger(50, 100),
          summary_table_data: [
            {
              key: 1,
              stats: 'Distinct values',
              value: generateRandomInteger(100, 150),
            },
            {
              key: 2,
              stats: 'Uniques',
              value: generateRandomInteger(0, 100),
            },
            {
              key: 3,
              stats: 'Invalids',
              value: generateRandomInteger(0, 100),
            },
          ],
          chart_data: [
            {
              key: 1,
              value: 'Hoodie',
              count: generateRandomInteger(100, 500),
              percentage: generateRandomInteger(0, 100),
              cumulative_percentage: generateRandomInteger(0, 100),
            },
            {
              key: 2,
              value: 'White T-Shirt M',
              count: generateRandomInteger(0, 50),
              percentage: generateRandomInteger(0, 100),
              cumulative_percentage: generateRandomInteger(0, 100),
            },
            {
              key: 3,
              value: 'Yello T-Shirt M',
              count: generateRandomInteger(0, 100),
              percentage: generateRandomInteger(0, 100),
              cumulative_percentage: generateRandomInteger(0, 100),
            },
          ],
        }
      : column.columnType === 'number'
      ? {
          key: 1,
          columnName: column.columnName,
          columnType: column.columnType,
          null_data: [300, 0, 0],
          null_percent: generateRandomInteger(0, 100),
          summary_table_data: [
            {
              key: 1,
              stats: 'Min',
              value: 0,
            },
            {
              key: 2,
              stats: 'Max',
              value: generateRandomInteger(50000, 500000),
            },
            {
              key: 3,
              stats: 'Mean',
              value: generateRandomInteger(20000, 500000),
            },
            {
              key: 4,
              stats: 'Median',
              value: generateRandomInteger(20000, 500000),
            },
            {
              key: 5,
              stats: 'StdDev',
              value: generateRandomInteger(20000, 500000),
            },
            {
              key: 6,
              stats: 'Mode',
              value: 0,
            },
            {
              key: 7,
              stats: 'Distinct',
              value: 10000,
            },
            {
              key: 8,
              stats: 'IQR',
              value: 37302,
            },
            {
              key: 9,
              stats: 'Sum',
              value: generateRandomInteger(20000, 500000),
            },
          ],
          chart_data: {
            labels: ['1', '2', '3', '4', '5'],
            datasets: [
              {
                label: 'Sample Values',
                data: [100, 200, 300, 250, 50],
                backgroundColor: ['rgb(0,151,167,0.7)'],
                borderColor: ['rgb(0,151,167,1)'],
              },
            ],
          },
        }
      : column.columnType === 'date'
      ? {
          key: 2,
          columnName: column.columnName,
          columnType: column.columnType,
          null_data: [300, 10, 50],
          null_percent: generateRandomInteger(0, 100),
          summary_table_data: [
            {
              key: 1,
              stats: 'Distinct values',
              value: 120,
            },
            {
              key: 2,
              stats: 'Months without data',
              value: '',
            },
          ],
          chart_data: {
            labels: [],
            datasets: [
              {
                label: 'Sample Values',
                data: [
                  { x: '2020-01-01', y: 50 },
                  { x: '2021-07-01', y: 100 },
                  { x: '2021-07-02', y: 200 },
                  { x: '2021-07-03', y: 300 },
                  { x: '2021-07-04', y: null },
                  { x: '2021-07-05', y: 500 },
                ],
                backgroundColor: [
                  'rgb(0,151,167,0.7)',
                  'rgb(0,151,167,0.7)',
                  'rgb(0,151,167,0.7)',
                  'rgb(0,151,167,0.7)',
                  'rgb(0,151,167,0.7)',
                ],
                borderColor: [
                  'rgb(0,151,167,1)',
                  'rgb(0,151,167,1)',
                  'rgb(0,151,167,1)',
                  'rgb(0,151,167,1)',
                  'rgb(0,151,167,1)',
                ],
              },
            ],
          },
          chart_data_month: {
            labels: [],
            datasets: [
              {
                label: 'Sample Values',
                data: [
                  { x: 'January', y: 100 },
                  { x: 'February', y: 200 },
                  { x: 'March', y: 300 },
                  { x: 'April', y: 400 },
                  { x: 'May', y: 500 },
                ],
                backgroundColor: [
                  'rgb(0,151,167,0.7)',
                  'rgb(0,151,167,0.7)',
                  'rgb(0,151,167,0.7)',
                  'rgb(0,151,167,0.7)',
                  'rgb(0,151,167,0.7)',
                ],
                borderColor: [
                  'rgb(0,151,167,1)',
                  'rgb(0,151,167,1)',
                  'rgb(0,151,167,1)',
                  'rgb(0,151,167,1)',
                  'rgb(0,151,167,1)',
                ],
              },
            ],
          },
          chart_data_year: {
            labels: [],
            datasets: [
              {
                label: 'Sample Values',
                data: [
                  { x: '2018', y: 100 },
                  { x: '2019', y: 200 },
                  { x: '2020', y: 300 },
                  { x: '2021', y: 400 },
                ],
                backgroundColor: [
                  'rgb(0,151,167,0.7)',
                  'rgb(0,151,167,0.7)',
                  'rgb(0,151,167,0.7)',
                  'rgb(0,151,167,0.7)',
                ],
                borderColor: [
                  'rgb(0,151,167,1)',
                  'rgb(0,151,167,1)',
                  'rgb(0,151,167,1)',
                  'rgb(0,151,167,1)',
                ],
              },
            ],
          },
        }
      : {
          key: 0,
          columnName: column.columnName,
          columnType: column.columnType,
          null_data: [300, 10, 50],
          null_percent: 84,
          summary_table_data: [
            {
              key: 1,
              stats: 'Distinct values',
              value: 120,
            },
            {
              key: 2,
              stats: 'Uniques',
              value: 0,
            },
            {
              key: 3,
              stats: 'Invalids',
              value: 0,
            },
          ],
          chart_data: [
            {
              key: 1,
              value: 'Hoodie',
              count: 500,
              percentage: 50,
              cumulative_percentage: 50,
            },
            {
              key: 2,
              value: 'White T-Shirt M',
              count: 25,
              percentage: 25,
              cumulative_percentage: 75,
            },
            {
              key: 3,
              value: 'Yello T-Shirt M',
              count: 25,
              percentage: 25,
              cumulative_percentage: 100,
            },
          ],
        };

  if (!ObjectRecordStat) {
    error = true;
    errorDetails = 'No stats found.';
  }
  return {
    ObjectRecordStats: [],
    ObjectRecordStat,
    error,
    loaded: true,
    errorDetails: error && errorDetails ? errorDetails : undefined,
  };
};

export {
  getObjects,
  getRecordsObject,
  getObject,
  saveObject,
  deleteObject,
  getSchemaPointers,
  // New categories api calls
  getBusinessCategories,
  getBusinessCategory,
  saveBusinessCategory,
  saveBusinessTermToBusinessCategory,
  deleteBusinessCategory,
  // Old categories api calls
  getObjectCategories,
  getObjectCategory,
  saveCategory,
  deleteCategory,
  getObjectRecordStats,
  getObjectRecordStatByColumn,
  getSchemaCategories,
  getSchemaCategory,
  saveSchemaCategory,
  deleteSchemaCategory,
};
