// Importing type
import * as apiTypes from './types';
import { CombinedSource, Source } from '@features/sources/types';
import { generateId, sleep } from '@utils/helpers';
import { objects } from '@data/objects';
import { recipes } from '@data/recipes';
import { sources } from '@data/sources';
import { OctoObject, SchemaColumn } from '@features/objects/types';
import { Combination, Segment } from '@features/segments/types';
import { sourceConnections } from '@data/connections';
import { ActiveSourceConfiguration, MappingRow } from '@features/audiences/types';
import generateObject from '@features/objects/generateObject';
import { getLastCombinedSource } from '@features/audiences/generateAudience';
import { generateRecordsFromSchema } from '@utils/recordsGenerator';

// TODO @@@@slava api should be injected instead of hardcoded solution
// @@@Amine here we re gonna define functions containing the api calls and error handling

// API call emulating Connection details per Source
const getSources: apiTypes.APIGetSources = async () => {
  await sleep(1500);
  let error: boolean = false;
  let errorDetails: string | undefined = undefined;

  if (!sources) {
    error = true;
    errorDetails = 'No sources found.';
  }
  return {
    sources,
    error,
    loaded: true,
    errorDetails: error && errorDetails ? errorDetails : undefined,
  };
};

const getSource: apiTypes.APIGetSource = async (recordKey: string) => {
  await sleep(1500);
  let source: Source;
  let error: boolean = false;
  let errorDetails: string | undefined = undefined;

  source = {
    key: 'sourcename1',
    name: 'Source name 1',
    connection: sourceConnections[2],
    pointer: 'somesourcepointer',
    schedule: '* * */6 *',
    records: 3500,
    lastRun: '2020-02-01 00:00:00 (01:35:12)',
    status: ['active'],
    active: true,
    deleted: false,
  };

  if (!source) {
    error = true;
    errorDetails = 'No source found.';
  }
  return {
    source,
    error,
    loaded: true,
    errorDetails: error && errorDetails ? errorDetails : undefined,
  };
};

// API call for emulating new source saving
const saveSource: apiTypes.APISaveSource = async (sourceValues: Source) => {
  await sleep(1500);

  let source: Source;
  let error: boolean = false;
  let errorDetails: string | undefined = undefined;

  source = sourceValues;

  if (!source) {
    error = true;
    errorDetails = 'Impossible to save source (not found).';
  }
  source.lastRun = new Date().toString();

  if (!source.key) {
    source.key = generateId();
    source.status = ['broken'];
  }
  return {
    error,
    source,
    loaded: true,
    errorDetails: error && errorDetails ? errorDetails : undefined,
  };
};

// API call for emulating new source saving
const saveCombinedSource: apiTypes.APISaveCombinedSource = async (
  combination: Combination,
  segment: Segment
) => {
  await sleep(1500);

  let combinedSource: CombinedSource;
  let error: boolean = false;
  let errorDetails: string | undefined = undefined;
  if (combination.connection && combination.pointer) {
    combinedSource = {
      segment,
      key: generateId(),
      connection: combination.connection,
      pointer: combination.pointer,
      name: `combined_source_${combination.pointer}`,
      combinationKey: combination.key,
      sourceObject: combination.object,
    };
    return {
      error,
      combinedSource,
      loaded: true,
      errorDetails: error && errorDetails ? errorDetails : undefined,
    };
  }

  error = true;
  errorDetails = 'Impossible to save source (not found).';
  return {
    error,
    errorDetails,
    loaded: true,
  };
};

// API call for emulating source deletion
const deleteSource: apiTypes.APIDeleteSource = async (source: Source) => {
  await sleep(1500);

  let error: boolean = false;
  let errorDetails: string | undefined = undefined;

  if (!source) {
    error = true;
    errorDetails = 'Impossible to delete source (not found).';
  }
  return {
    error,
    source,
    loaded: true,
    errorDetails: error && errorDetails ? errorDetails : undefined,
  };
};

const runSource: apiTypes.APIRunSource = async (source: Source) => {
  await sleep(1500);
  let error: boolean = false;
  let errorDetails: string | undefined = undefined;
  let sourceObject: OctoObject;
  let targetObject: OctoObject;
  let newSource: Source = source;

  if (source.sourceObject) {
    sourceObject = {
      ...source.sourceObject,
      records: [...source.sourceObject.records, ...source.sourceObject.records],
    };
    newSource = { ...newSource, sourceObject };
  }

  if (source.targetObject) {
    targetObject = {
      ...source.targetObject,
      records: [...source.targetObject.records, ...source.targetObject.records],
    };
    newSource = { ...newSource, targetObject };
  }

  if (!source) {
    error = true;
    errorDetails = 'Impossible to delete source (not found).';
  }
  return {
    error,
    source: newSource,
    loaded: true,
    errorDetails: error && errorDetails ? errorDetails : undefined,
  };
};

const runSourcesForAudience: apiTypes.APIRunSourcesForAudience = async (sources, mappingRows) => {
  await sleep(1500);
  let error: boolean = false;
  let errorDetails: string | undefined = undefined;

  let object: OctoObject = generateObject('object');
  let primaryKeyColumnsId: string[] = [];
  let updateAtColumnId: string = '';
  // used to get fake records by some defined column names
  const referenceColumnNamesByMapping = mappingRows.reduce(
    (result: { [rowKey: string]: string }, row) => {
      const rowKey: string = row.key;
      const referenceColumnName: string = Object.keys(row.mappedColumns).reduce(
        (rs: string, sourceKey) => {
          if (row.mappedColumns[sourceKey].columnName) rs = row.mappedColumns[sourceKey].columnName;
          return rs;
        },
        ''
      );
      result = { ...result, [rowKey]: referenceColumnName };
      return result;
    },
    {}
  );
  // prepare the new object schema columns
  const newSchemaValues = mappingRows.reduce(
    (result: apiTypes.SchemaColumnWithReferenceColumn[], row, currentIndex) => {
      const rowKey: string = row.key;
      let newColumnValue: apiTypes.SchemaColumnWithReferenceColumn = {
        id: rowKey,
        columnName: row.audienceColumnName,
        columnType: row.audienceColumnType,
        category: '',
        description: '',
        position: currentIndex + 1,
        required: true,
        referenceColumnName: referenceColumnNamesByMapping[rowKey],
      };

      if (row.businessColumn) {
        newColumnValue = {
          ...newColumnValue,
          columnType: row.businessColumn.columnType,
          category: row.businessColumn.category,
          description: row.businessColumn.description,
          externalReference: row.businessColumn.externalReference,
          isExternalId: row.businessColumn.isExternalId,
        };
      }
      if (row.canDelete) {
        newColumnValue = { ...newColumnValue, required: false };
      }
      if (row.primaryKey) {
        primaryKeyColumnsId.push(rowKey);
      }
      if (row.updatedAtKey) {
        updateAtColumnId = rowKey;
      }
      result.push(newColumnValue);
      return result;
    },
    []
  );
  object.schema = newSchemaValues;
  object.updatedAtId = updateAtColumnId;
  object.primaryKeys = primaryKeyColumnsId;
  object.records = generateRecordsFromSchema(newSchemaValues, 20);
  if (!mappingRows) {
    error = true;
    errorDetails = 'Impossible to run sources (not found).';
  }
  return {
    error,
    object,
    loaded: true,
    errorDetails: error && errorDetails ? errorDetails : undefined,
  };
};

export {
  getSources,
  getSource,
  saveSource,
  deleteSource,
  runSource,
  saveCombinedSource,
  runSourcesForAudience,
};
