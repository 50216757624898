import { takeLatest, call, put } from 'redux-saga/effects';
// api
import * as API from './api';
// connectionsSlice
import appContextSlice, { AppContextState } from '@features/appContext/ducks/appContextSlice';
// types
import * as types from './types';
import i18n from '@configs/i18n';

function* getAppContextSaga({ payload }: types.GetAppContextSaga) {
  try {
    const { name } = payload;
    const getAppContextPayload: AppContextState = yield call(API.getAppContext, name);
    yield put({
      type: appContextSlice.actions.getAppContextSuccess.type,
      payload: getAppContextPayload,
    });
  } catch (error) {
    yield put({ type: appContextSlice.actions.getAppContextFailed.type, payload: error });
  }
}

function* saveAppContextSaga({ payload }: types.SaveAppContextSaga) {
  try {
    const { appContext } = payload;
    const saveAppContextPayload: AppContextState = yield call(API.saveAppContext, appContext);
    yield put({
      type: appContextSlice.actions.saveAppContextSuccess.type,
      payload: saveAppContextPayload,
    });
  } catch (error) {
    yield put({ type: appContextSlice.actions.saveAppContextFailed.type, payload: error });
  }
}

function* setAppLanguageSaga({ payload }: types.SetAppLanguageSaga) {
  try {
    const { language } = payload;
    yield i18n.changeLanguage(language);

    yield put({ type: appContextSlice.actions.setAppLanguageSuccess.type, payload: { language } });
  } catch (error) {
    yield put({ type: appContextSlice.actions.setAppLanguageFailed.type, payload: error });
  }
}

export function* setAppIconAndNameSaga({ payload }: types.SetAppIconAndNameSaga) {
  try {
    // @@@Amine maybe here we will add a url property to http favicon
    const { name } = payload;
    console.log(name);
    let link: HTMLLinkElement;
    const faviconLink: HTMLLinkElement = document.querySelectorAll<HTMLLinkElement>(
      'link[rel~="icon"]'
    )[0];
    yield (document.title = `${name.charAt(0).toUpperCase()}${name.slice(1)}`);

    if (faviconLink) {
      yield (faviconLink.href = `${process.env.PUBLIC_URL}/icons/appLogos/${name}.ico`);
    } else {
      link = document.createElement('link');
      link.rel = 'shortcut icon';
      link.href = `${process.env.PUBLIC_URL}/icons/appLogos/${name}.ico`;
      yield document.getElementsByTagName('head')[0].appendChild(link);
    }
  } catch (error) {
    console.log(error);
  }
}
export const appContextSagas = [
  takeLatest(appContextSlice.actions.getAppContext.type, getAppContextSaga),
  takeLatest(appContextSlice.actions.saveAppContext.type, saveAppContextSaga),
  takeLatest(appContextSlice.actions.setAppLanguage.type, setAppLanguageSaga),
  takeLatest(appContextSlice.actions.setAppIconAndName.type, setAppIconAndNameSaga),
];
