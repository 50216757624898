import { Segment } from '@features/segments/types';
import { sources } from '@data/sources';
import { generateId } from '@utils/helpers';
import { connections } from '@data/connections';
import { objects } from '@data/objects';
import { recipes } from '@data/recipes';

const segments: Segment[] = [
  {
    key: 'segment1',
    source: sources[1],
    name: 'My audience',
    combinations: [
      {
        key: generateId(),
        combinationLevel: 1,
        connection: connections[2],
        pointer: 'contacts',
        object: objects[0],
        joiningConfiguration: sources[1].targetObject
          ? {
              combinedColumn: objects[0].schema[2],
              sourceColumn: sources[1].targetObject.schema[1],
            }
          : undefined,
      },
      {
        key: generateId(),
        combinationLevel: 2,
        connection: connections[1],
        pointer: 'orders table',
        object: objects[1],
        joiningConfiguration: sources[2].targetObject
          ? {
              combinedColumn: objects[1].schema[2],
              sourceColumn: sources[2].targetObject.schema[1],
            }
          : undefined,
      },
      {
        key: generateId(),
        combinationLevel: 3,
        connection: connections[0],
        pointer: 'contacts',
        object: objects[0],
        joiningConfiguration: sources[1].targetObject
          ? {
              combinedColumn: objects[0].schema[2],
              sourceColumn: sources[1].targetObject.schema[1],
            }
          : undefined,
      },
    ],
    recipe: recipes[0],
    targetObject: objects[4],
  },
];

export default segments;
