import { ApiGetObjectPayload } from '@features/objects/ducks/types';
import { all, call, put, takeLatest } from '@redux-saga/core/effects';
import { getRecordsObject } from '@features/objects/ducks/api';
import { runSourcesForAudience } from '@features/sources/ducks/api';
// types
import {
  ApiAudiencePayload,
  ApiGetAudiencePayload,
  ApiGetAudiencesPayload,
  CreateSourceDataFormSaga,
  DeleteAudienceSaga,
  EditSourceDataFormSaga,
  GetAudienceSaga,
  SaveActiveSourceSaga,
  SaveAudienceSaga,
  SaveInitialAudienceFormSaga,
} from './types';
import audiencesSlice from './audiencesSlice';
import { ActiveSourceConfiguration } from '../types';
import { ApiRunSourcesPayload } from '@features/sources/ducks/types';
import { push } from 'connected-react-router';
import { deleteAudience, getAudience, getAudiences, saveAudience } from './api';
import { isActiveSourceType } from '@features/audiences/typesPredicates';
import { routesEnum } from '@routes/types';

// Audience CRUD saga's
function* getAudiencesSaga() {
  try {
    const getAudiencesPayload: ApiGetAudiencesPayload = yield call(getAudiences);
    yield put({
      type: audiencesSlice.actions.getAudiencesSuccess.type,
      payload: getAudiencesPayload,
    });
  } catch (error) {
    yield put({ type: audiencesSlice.actions.getAudiencesFailed.type, payload: error });
  }
}
function* getAudienceSaga({ payload }: GetAudienceSaga) {
  try {
    const { audienceKey } = payload;
    const getAudiencePayload: ApiGetAudiencePayload = yield call(getAudience, audienceKey);
    yield put({
      type: audiencesSlice.actions.getAudienceSuccess.type,
      payload: getAudiencePayload,
    });
  } catch (error) {
    yield put({ type: audiencesSlice.actions.saveAudienceFailed.type, payload: error });
  }
}
function* saveAudienceSaga({ payload }: SaveAudienceSaga) {
  try {
    const { audience } = payload;
    const saveAudiencePayload: ApiAudiencePayload = yield call(saveAudience, audience);
    yield all([
      put({
        type: audiencesSlice.actions.saveAudienceSuccess.type,
        payload: saveAudiencePayload,
      }),
      put({ type: audiencesSlice.actions.setInitialActiveAudienceFormsStates.type }),
      put(push(routesEnum.AUDIENCES)),
    ]);
  } catch (error) {
    yield put({
      type: audiencesSlice.actions.saveAudienceFailed.type,
      payload: { error, errorDetails: error },
    });
  }
}
function* deleteAudienceSaga({ payload }: DeleteAudienceSaga) {
  const { audience } = payload;
  try {
    const deleteAudiencePayload: ApiAudiencePayload = yield call(deleteAudience, audience);
    yield put({
      type: audiencesSlice.actions.deleteAudienceSuccess.type,
      payload: deleteAudiencePayload,
    });
  } catch (error) {
    yield put({ type: audiencesSlice.actions.deleteAudienceFailed.type, payload: error });
  }
}

// Active audience forms saga's
function* updateActiveSourceObjectSaga({ payload }: SaveActiveSourceSaga) {
  try {
    const { sourceConfiguration } = payload;
    if (
      isActiveSourceType(sourceConfiguration.audienceSource) &&
      sourceConfiguration.audienceSource.connection &&
      sourceConfiguration.audienceSource.pointer
    ) {
      const { connection, pointer } = sourceConfiguration.audienceSource;
      const getRecordsObjectPayload: ApiGetObjectPayload = yield call(
        getRecordsObject,
        connection,
        pointer
      );
      const newSourceConfiguration: ActiveSourceConfiguration = {
        ...sourceConfiguration,
        audienceSource: {
          ...sourceConfiguration.audienceSource,
          object: getRecordsObjectPayload.object,
        },
      };
      yield put({
        type: audiencesSlice.actions.updateActiveSourceObjectSuccess.type,
        payload: { sourceConfiguration: newSourceConfiguration },
      });
    } else {
      throw new Error('connection or pointer are undefined');
    }
  } catch (error) {
    yield put({
      type: audiencesSlice.actions.updateActiveSourceObjectFailed.type,
      payload: error,
    });
  }
}

function* saveInitialAudienceFormSaga({ payload }: SaveInitialAudienceFormSaga) {
  try {
    const { activeAudience, sourceConfiguration } = payload;

    const runSourcesPayload: ApiRunSourcesPayload = yield call(
      runSourcesForAudience,
      activeAudience.sources,
      activeAudience.mappingRows
    );

    yield all([
      put({
        type: audiencesSlice.actions.updateActiveAudience.type,
        payload: {
          activeAudience: {
            ...activeAudience,
            object: runSourcesPayload.object,
          },
        },
      }),
      put({
        type: audiencesSlice.actions.saveInitialAudiencePreparationStep.type,
        payload: {
          sourceConfiguration,
        },
      }),
      put({
        type: audiencesSlice.actions.updateActiveMappingRows.type,
        payload: {
          mappingRows: activeAudience.mappingRows,
        },
      }),
      // Redirection after success
      put(push(routesEnum.AUDIENCES_PREPARE_DATA)),
      put({
        type: audiencesSlice.actions.setNewActiveSourceConfiguration.type,
        payload: {
          combinationLevel: activeAudience.sources.length,
        },
      }),
      put({
        type: audiencesSlice.actions.saveInitialAudienceFormSuccess.type,
      }),
    ]);
  } catch (err: unknown) {
    if (err instanceof Error) {
      yield put({
        type: audiencesSlice.actions.saveInitialAudienceFormFailed.type,
        payload: { error: err, errorDetails: err.message },
      });
    }
  }
}

function* createSourceDataFormSaga({ payload }: CreateSourceDataFormSaga) {
  try {
    const { activeAudience, sourceConfiguration } = payload;

    const runSourcesPayload: ApiRunSourcesPayload = yield call(
      runSourcesForAudience,
      activeAudience.sources,
      activeAudience.mappingRows
    );

    yield all([
      put({
        type: audiencesSlice.actions.updateActiveAudience.type,
        payload: {
          activeAudience: {
            ...activeAudience,
            object: runSourcesPayload.object,
          },
        },
      }),
      put({
        type: audiencesSlice.actions.updateActiveMappingRows.type,
        payload: {
          mappingRows: activeAudience.mappingRows,
        },
      }),
      put({
        type: audiencesSlice.actions.saveSourceDataPreparationStep.type,
        payload: {
          sourceConfiguration,
        },
      }),
      put({
        type: audiencesSlice.actions.setNewActiveSourceConfiguration.type,
        payload: {
          combinationLevel: activeAudience.sources.length,
        },
      }),
      put({
        type: audiencesSlice.actions.createSourceDataFormSuccess.type,
      }),
    ]);
  } catch (err: unknown) {
    if (err instanceof Error) {
      yield put({
        type: audiencesSlice.actions.createSourceDataFormFailed.type,
        payload: { error: err, errorDetails: err.message },
      });
    }
  }
}

function* editAudienceSourceSaga({ payload }: EditSourceDataFormSaga) {
  try {
    const { activeAudience } = payload;

    const runSourcesPayload: ApiRunSourcesPayload = yield call(
      runSourcesForAudience,
      activeAudience.sources,
      activeAudience.mappingRows
    );

    yield all([
      put({
        type: audiencesSlice.actions.updateActiveAudience.type,
        payload: {
          activeAudience: {
            ...activeAudience,
            object: runSourcesPayload.object,
          },
        },
      }),
      put({
        type: audiencesSlice.actions.updateActiveMappingRows.type,
        payload: {
          mappingRows: activeAudience.mappingRows,
        },
      }),

      put({
        type: audiencesSlice.actions.setNewActiveSourceConfiguration.type,
        payload: {
          combinationLevel: activeAudience.sources.length,
        },
      }),
      put({
        type: audiencesSlice.actions.editSourceDataFormSuccess.type,
      }),
    ]);
  } catch (err: unknown) {
    if (err instanceof Error) {
      yield put({
        type: audiencesSlice.actions.editSourceDataFormFailed.type,
        payload: { error: err, errorDetails: err.message },
      });
    }
  }
}
export const audienceSagas = [
  // Used for active audience forms
  takeLatest(audiencesSlice.actions.updateActiveSourceObject.type, updateActiveSourceObjectSaga),
  takeLatest(audiencesSlice.actions.saveInitialAudienceForm.type, saveInitialAudienceFormSaga),
  takeLatest(audiencesSlice.actions.createSourceDataForm.type, createSourceDataFormSaga),
  takeLatest(audiencesSlice.actions.editSourceDataForm.type, editAudienceSourceSaga),
  // Audience CRUD
  takeLatest(audiencesSlice.actions.getAudiences.type, getAudiencesSaga),
  takeLatest(audiencesSlice.actions.getAudience.type, getAudienceSaga),
  takeLatest(audiencesSlice.actions.saveAudience.type, saveAudienceSaga),
  takeLatest(audiencesSlice.actions.deleteAudience.type, deleteAudienceSaga),
];
