import { generateSchemaFromRecords } from '@features/objects/generateObject';
import { OctoObject, SchemaColumn } from '@features/objects/types';
import { RawRecord } from '@features/rawRecords/types';
import { generateId } from '@utils/helpers';
import { Combination, Segment } from '../types';

export const generateSegment = () => {
  const segmentKey = generateId();
  const segment: Segment = {
    key: segmentKey,
    name: 'generated segment',
    combinations: [generateCombination(0)],
  };
  return segment;
};

export const resetSegmentCombination = (oldValuesSegment: Segment) => {
  const segment: Segment = {
    ...oldValuesSegment,
    combinations: [generateCombination(0)],
    targetObject: oldValuesSegment.source?.targetObject,
  };
  return segment;
};
export const generateCombination = (combinationLevel: number) => {
  const combinationKey = generateId();
  const combination: Combination = {
    key: combinationKey,
    combinationLevel: combinationLevel,
  };
  return combination;
};
export const joinSegmentSources = (oldTargetObject: OctoObject, combination: Combination) => {
  let records: RawRecord[] = [];
  let schema: SchemaColumn[] = [];
  let primaryKeys: string[] = [];
  const combineColumnName: string = combination.joiningConfiguration
    ? combination.joiningConfiguration?.combinedColumn.columnName
    : '';
  const sourceColumnName: string = combination.joiningConfiguration
    ? combination.joiningConfiguration?.sourceColumn.columnName
    : '';

  const mappingColumnName: string = combineColumnName + '_' + sourceColumnName;

  const combinationNewRecords = combination.object?.records
    ? combination.object.records.map((elem) => {
        return {
          [mappingColumnName]: elem[combineColumnName],
          ...elem,
        };
      })
    : [];

  const combinationSchema: SchemaColumn[] = combination.object?.records
    ? generateSchemaFromRecords(combinationNewRecords[0])
    : [];

  const prepareColumnsWithFormula: SchemaColumn[] = combination.selectedColumns
    ? combination.selectedColumns.map<SchemaColumn>((element) => {
        return {
          ...element.column,
          columnName: element.column.columnName + '_' + element.formula,
          id: generateId(),
        };
      })
    : [];
  const filterSchemaFromJoiningColumns: SchemaColumn[] = [
    ...combinationSchema,
    ...oldTargetObject.schema,
    ...prepareColumnsWithFormula,
  ].filter(
    (column) =>
      column.columnName !== combineColumnName &&
      column.id !== combination.joiningConfiguration?.sourceColumn.id
  );

  const allRecords = [...oldTargetObject.records, ...combinationNewRecords].map((elem) =>
    Object.create({
      [mappingColumnName]: elem[sourceColumnName],
      ...elem,
    })
  );
  schema = filterSchemaFromJoiningColumns;
  records = allRecords;
  primaryKeys = [...oldTargetObject.primaryKeys];

  return { records, schema, primaryKeys };
};
