import { SchemaColumn } from '@features/objects/types';
import { generateId } from '@utils/helpers';

export const contactsColumns: SchemaColumn[] = [
  {
    id: 'col1',
    columnName: 'userId',
    columnType: 'string',
    required: true,
    category: 'string',
    description: 'this is a description of the column',
    position: 1,
  },
  {
    id: generateId(),
    columnName: 'firstName',
    columnType: 'string',
    required: true,
    category: 'first_name',
    description: 'this is a description of the column',
    position: 1,
  },
  {
    id: generateId(),
    columnName: 'lastName',
    columnType: 'string',
    required: false,
    category: 'string',
    description: 'this is a description of the column',
    position: 2,
  },
  {
    id: generateId(),
    columnName: 'fullName',
    columnType: 'string',
    required: false,
    category: 'string',
    description: 'this is a description of the column',
    position: 3,
  },
  {
    id: generateId(),
    columnName: 'birthdate',
    columnType: 'date',
    required: false,
    category: 'date',
    description: 'this is a description of the column',
    position: 4,
  },
  {
    id: generateId(),
    columnName: 'language',
    columnType: 'string',
    required: false,
    category: 'string',
    description: 'this is a description of the column',
    position: 5,
  },
  {
    id: generateId(),
    columnName: 'gender',
    columnType: 'string',
    required: false,
    category: 'string',
    description: 'this is a description of the column',
    position: 6,
  },
  {
    id: generateId(),
    columnName: 'civility',
    columnType: 'string',
    required: false,
    category: 'string',
    description: 'this is a description of the column',
    position: 7,
  },
  {
    id: generateId(),
    columnName: 'address',
    columnType: 'string',
    required: false,
    category: 'string',
    description: 'this is a description of the column',
    position: 8,
  },
  {
    id: generateId(),
    columnName: 'postalCode',
    columnType: 'string',
    required: false,
    category: 'string',
    description: 'this is a description of the column',
    position: 9,
  },
  {
    id: generateId(),
    columnName: 'city',
    columnType: 'string',
    required: false,
    category: 'string',
    description: 'this is a description of the column',
    position: 10,
  },
  {
    id: generateId(),
    columnName: 'region',
    columnType: 'string',
    required: false,
    category: 'string',
    description: 'this is a description of the column',
    position: 11,
  },
  {
    id: generateId(),
    columnName: 'countryName',
    columnType: 'string',
    required: false,
    category: 'string',
    description: 'this is a description of the column',
    position: 12,
  },
  {
    id: generateId(),
    columnName: 'countryCode',
    columnType: 'string',
    required: false,
    category: 'string',
    description: 'this is a description of the column',
    position: 13,
  },
  {
    id: generateId(),
    columnName: 'email',
    columnType: 'string',
    required: true,
    category: 'email',
    description: 'this is a description of the column',
    position: 14,
  },
  {
    id: generateId(),
    columnName: 'emailFormat',
    columnType: 'string',
    required: false,
    category: 'string',
    description: 'this is a description of the column',
    position: 15,
  },
  {
    id: generateId(),
    columnName: 'emailDomain',
    columnType: 'string',
    required: false,
    category: 'string',
    description: 'this is a description of the column',
    position: 16,
  },
  {
    id: generateId(),
    columnName: 'emailValidity',
    columnType: 'string',
    required: false,
    category: 'string',
    description: 'this is a description of the column',
    position: 17,
  },
  {
    id: generateId(),
    columnName: 'emailValidityReason',
    columnType: 'string',
    required: false,
    category: 'string',
    description: 'this is a description of the column',
    position: 18,
  },
  {
    id: generateId(),
    columnName: 'phoneMobile',
    columnType: 'string',
    required: false,
    category: 'phone',
    description: 'this is a description of the column',
    position: 19,
  },
  {
    id: generateId(),
    columnName: 'phoneLandline',
    columnType: 'string',
    required: false,
    category: 'phone',
    description: 'this is a description of the column',
    position: 20,
  },
  {
    id: generateId(),
    columnName: 'phone',
    columnType: 'string',
    required: false,
    category: 'phone',
    description: 'this is a description of the column',
    position: 21,
  },
  {
    id: generateId(),
    columnName: 'phoneValidity',
    columnType: 'string',
    required: false,
    category: 'string',
    description: 'this is a description of the column',
    position: 22,
  },
  {
    id: generateId(),
    columnName: 'phoneValidityReason',
    columnType: 'string',
    required: false,
    category: 'string',
    description: 'this is a description of the column',
    position: 23,
  },
  {
    id: generateId(),
    columnName: 'phoneCountry',
    columnType: 'string',
    required: false,
    category: 'string',
    description: 'this is a description of the column',
    position: 24,
  },
  {
    id: generateId(),
    columnName: 'phoneNormalized',
    columnType: 'string',
    required: false,
    category: 'string',
    description: 'this is a description of the column',
    position: 25,
  },
  {
    id: generateId(),
    columnName: 'phoneType',
    columnType: 'string',
    required: false,
    category: 'string',
    description: 'this is a description of the column',
    position: 26,
  },
  {
    id: generateId(),
    columnName: 'consentEmail',
    columnType: 'boolean',
    required: false,
    category: 'boolean',
    description: 'this is a description of the column',
    position: 27,
  },
  {
    id: generateId(),
    columnName: 'consentEmailUpdatedAt',
    columnType: 'date',
    required: false,
    category: 'date',
    description: 'this is a description of the column',
    position: 28,
  },
  {
    id: generateId(),
    columnName: 'consentSms',
    columnType: 'boolean',
    required: false,
    category: 'boolean',
    description: 'this is a description of the column',
    position: 29,
  },
  {
    id: generateId(),
    columnName: 'consent_sms_updated_at',
    columnType: 'date',
    required: false,
    category: 'date',
    description: 'this is a description of the column',
    position: 30,
  },
  {
    id: generateId(),
    columnName: 'linkedin',
    columnType: 'string',
    required: false,
    category: 'string',
    description: 'this is a description of the column',
    position: 31,
  },
  {
    id: generateId(),
    columnName: 'website',
    columnType: 'string',
    required: false,
    category: 'string',
    description: 'this is a description of the column',
    position: 32,
  },
  {
    id: generateId(),
    columnName: 'companyName',
    columnType: 'string',
    required: false,
    category: 'string',
    description: 'this is a description of the column',
    position: 33,
  },
  {
    id: generateId(),
    columnName: 'jobTitle',
    columnType: 'string',
    required: false,
    category: 'string',
    description: 'this is a description of the column',
    position: 34,
  },
  {
    id: generateId(),
    columnName: 'createdAt',
    columnType: 'date',
    required: false,
    category: 'date',
    description: 'this is a description of the column',
    position: 35,
  },
  {
    id: generateId(),
    columnName: 'updatedAt',
    columnType: 'date',
    required: false,
    category: 'date',
    description: 'this is a description of the column',
    position: 36,
  },
];
export const ordersColumns: SchemaColumn[] = [
  {
    id: 'col1',
    columnName: 'recordId',
    columnType: 'string',
    required: true,
    category: 'string',
    description: 'Mandatory',
    position: 1,
  },
  {
    id: generateId(),
    columnName: 'contact_masterid',
    columnType: 'string',
    required: true,
    category: 'string',
    description: 'Mandatory, link to contact',
    position: 1,
  },
  {
    id: generateId(),
    columnName: 'totalAmount',
    columnType: 'number',
    required: true,
    category: 'number',
    description: 'Mandatory, instead of price',
    position: 2,
  },
  {
    id: generateId(),
    columnName: 'shippingAmount',
    columnType: 'number',
    required: false,
    category: 'number',
    description: 'this is a description of the column',
    position: 3,
  },
  {
    id: generateId(),
    columnName: 'discountAmount',
    columnType: 'number',
    required: false,
    category: 'number',
    description: 'this is a description of the column',
    position: 4,
  },
  {
    id: '1634805510991.0mnt3',
    columnName: 'taxAmount',
    columnType: 'number',
    required: false,
    category: 'number',
    description: 'this is a description of the column',
    position: 5,
  },
  {
    id: generateId(),
    columnName: 'currency',
    columnType: 'string',
    required: false,
    category: 'string',
    description: 'this is a description of the column',
    position: 6,
  },
  {
    id: generateId(),
    columnName: 'completed',
    columnType: 'boolean',
    required: true,
    category: 'boolean',
    description: 'Mandatory',
    position: 7,
  },
  {
    id: generateId(),
    columnName: 'storeId',
    columnType: 'string',
    required: false,
    category: 'string',
    description: 'Link to store',
    position: 8,
  },
  {
    id: generateId(),
    columnName: 'salesPerson',
    columnType: 'string',
    required: false,
    category: 'string',
    description: 'this is a description of the column',
    position: 9,
  },
  {
    id: generateId(),
    columnName: 'createdAt',
    columnType: 'date',
    required: true,
    category: 'date',
    description: 'Mandatory',
    position: 10,
  },
];
export const ordersItemsColumns: SchemaColumn[] = [
  {
    id: 'col1',
    columnName: 'recordId',
    columnType: 'string',
    required: true,
    category: 'string',
    description: 'Mandatory',
    position: 1,
  },
  {
    id: generateId(),
    columnName: 'orderId',
    columnType: 'string',
    required: true,
    category: 'string',
    description: 'Mandatory',
    position: 1,
  },
  {
    id: generateId(),
    columnName: 'orderCompleted',
    columnType: 'boolean',
    required: true,
    category: 'boolean',
    description: 'Mandatory, link to contact',
    position: 1,
  },
  {
    id: generateId(),
    columnName: 'contact_masterid',
    columnType: 'string',
    required: true,
    category: 'string',
    description: 'Mandatory, instead of price',
    position: 2,
  },
  {
    id: generateId(),
    columnName: 'productId',
    columnType: 'string',
    required: false,
    category: 'string',
    description: 'this is a description of the column',
    position: 3,
  },
  {
    id: generateId(),
    columnName: 'unitPrice',
    columnType: 'number',
    required: false,
    category: 'number',
    description: 'this is a description of the column',
    position: 4,
  },
  {
    id: generateId(),
    columnName: 'quantity',
    columnType: 'number',
    required: false,
    category: 'number',
    description: 'this is a description of the column',
    position: 5,
  },
  {
    id: generateId(),
    columnName: 'taxAmount',
    columnType: 'number',
    required: false,
    category: 'number',
    description: 'this is a description of the column',
    position: 6,
  },
  {
    id: generateId(),
    columnName: 'discountAmount',
    columnType: 'number',
    required: true,
    category: 'number',
    description: 'Mandatory',
    position: 7,
  },
  {
    id: generateId(),
    columnName: 'totalAmount',
    columnType: 'number',
    required: false,
    category: 'number',
    description: 'Link to store',
    position: 8,
  },
  {
    id: generateId(),
    columnName: 'currency',
    columnType: 'string',
    required: false,
    category: 'string',
    description: 'this is a description of the column',
    position: 9,
  },
  {
    id: generateId(),
    columnName: 'createdAt',
    columnType: 'date',
    required: true,
    category: 'date',
    description: 'Mandatory',
    position: 10,
  },
];
export const productsColumns: SchemaColumn[] = [
  {
    id: 'col1',
    columnName: 'recordId',
    columnType: 'string',
    required: true,
    category: 'string',
    description: 'Mandatory',
    position: 1,
  },
  {
    id: generateId(),
    columnName: 'createdAt',
    columnType: 'date',
    required: true,
    category: 'date',
    description: 'Mandatory',
    position: 1,
  },
  {
    id: generateId(),
    columnName: 'name',
    columnType: 'string',
    required: true,
    category: 'string',
    description: 'Mandatory, link to contact',
    position: 1,
  },
  {
    id: generateId(),
    columnName: 'brand',
    columnType: 'string',
    required: true,
    category: 'string',
    description: 'Mandatory, instead of price',
    position: 2,
  },
  {
    id: generateId(),
    columnName: 'description',
    columnType: 'string',
    required: false,
    category: 'string',
    description: 'this is a description of the column',
    position: 3,
  },
  {
    id: generateId(),
    columnName: 'price',
    columnType: 'number',
    required: false,
    category: 'number',
    description: 'this is a description of the column',
    position: 4,
  },
  {
    id: generateId(),
    columnName: 'category',
    columnType: 'string',
    required: false,
    category: 'string',
    description: 'this is a description of the column',
    position: 5,
  },
  {
    id: generateId(),
    columnName: 'imgUrl',
    columnType: 'string',
    required: false,
    category: 'string',
    description: 'this is a description of the column',
    position: 6,
  },
  {
    id: generateId(),
    columnName: 'sku',
    columnType: 'string',
    required: true,
    category: 'string',
    description: 'Mandatory',
    position: 7,
  },
];
export const storesColumns: SchemaColumn[] = [
  {
    id: 'col1',
    columnName: 'recordId',
    columnType: 'string',
    required: true,
    category: 'string',
    description: 'Mandatory',
    position: 1,
  },
  {
    id: generateId(),
    columnName: 'createdAt',
    columnType: 'date',
    required: true,
    category: 'date',
    description: 'Mandatory',
    position: 1,
  },
  {
    id: generateId(),
    columnName: 'name',
    columnType: 'string',
    required: true,
    category: 'string',
    description: 'Mandatory, link to contact',
    position: 1,
  },
  {
    id: generateId(),
    columnName: 'storeType',
    columnType: 'string',
    required: true,
    category: 'string',
    description: 'Mandatory, instead of price',
    position: 2,
  },
  {
    id: generateId(),
    columnName: 'manager',
    columnType: 'string',
    required: false,
    category: 'string',
    description: 'this is a description of the column',
    position: 3,
  },
];
export const eventsColumns: SchemaColumn[] = [
  {
    id: 'col1',
    columnName: 'recordId',
    columnType: 'string',
    required: true,
    category: 'string',
    description: 'Mandatory',
    position: 1,
  },
  {
    id: generateId(),
    columnName: 'contactId',
    columnType: 'string',
    required: true,
    category: 'date',
    description: 'Mandatory, link to contact',
    position: 1,
  },
  {
    id: generateId(),
    columnName: 'name',
    columnType: 'string',
    required: false,
    category: 'string',
    description: 'Name of event',
    position: 1,
  },
  {
    id: generateId(),
    columnName: 'valueNumber',
    columnType: 'number',
    required: true,
    category: 'number',
    description: '',
    position: 2,
  },
  {
    id: generateId(),
    columnName: 'valueDate',
    columnType: 'date',
    required: false,
    category: 'date',
    description: 'this is a description of the column',
    position: 3,
  },
  {
    id: generateId(),
    columnName: 'createdAt',
    columnType: 'date',
    required: true,
    category: 'date',
    description: 'Mandatory',
    position: 3,
  },
];
export const eventsStatisticsColumns: SchemaColumn[] = [
  {
    id: 'col1',
    columnName: 'recordId',
    columnType: 'string',
    required: true,
    category: 'string',
    description: 'Mandatory',
    position: 1,
  },
  {
    id: generateId(),
    columnName: 'contactId',
    columnType: 'string',
    required: true,
    category: 'string',
    description: 'Mandatory, link to contact',
    position: 2,
  },
  {
    id: generateId(),
    columnName: 'name',
    columnType: 'string',
    required: false,
    category: 'string',
    description: 'Name of event stat',
    position: 3,
  },
  {
    id: generateId(),
    columnName: 'valueNumber',
    columnType: 'number',
    required: true,
    category: 'number',
    description: '',
    position: 4,
  },
  {
    id: generateId(),
    columnName: 'valueDate',
    columnType: 'date',
    required: false,
    category: 'date',
    description: 'this is a description of the column',
    position: 5,
  },
  {
    id: generateId(),
    columnName: 'updatedAt',
    columnType: 'date',
    required: false,
    category: 'date',
    description: '',
    position: 6,
  },
  {
    id: generateId(),
    columnName: 'createdAt',
    columnType: 'date',
    required: true,
    category: 'date',
    description: 'Mandatory',
    position: 7,
  },
];

export const consentsColumns: SchemaColumn[] = [
  {
    id: 'col1',
    columnName: 'recordId',
    columnType: 'string',
    required: true,
    category: 'string',
    description: 'Mandatory',
    position: 1,
  },
  {
    id: generateId(),
    columnName: 'contact_masterid',
    columnType: 'string',
    required: true,
    category: 'string',
    description: 'Mandatory, link to contact',
    position: 2,
  },
  {
    id: generateId(),
    columnName: 'value',
    columnType: 'boolean',
    required: false,
    category: 'boolean',
    description: 'Name of event stat',
    position: 3,
  },
  {
    id: generateId(),
    columnName: 'source',
    columnType: 'string',
    required: true,
    category: 'string',
    description: '',
    position: 4,
  },
  {
    id: generateId(),
    columnName: 'category',
    columnType: 'string',
    required: true,
    category: 'string',
    description: '',
    position: 5,
  },
  {
    id: generateId(),
    columnName: 'updatedAt',
    columnType: 'date',
    required: false,
    category: 'date',
    description: '',
    position: 6,
  },
  {
    id: generateId(),
    columnName: 'createdAt',
    columnType: 'date',
    required: true,
    category: 'date',
    description: 'Mandatory',
    position: 7,
  },
];

export const defaultColumns: SchemaColumn[] = [
  {
    id: 'col1',
    columnName: 'recordId',
    columnType: 'string',
    required: true,
    category: 'string',
    description: 'Mandatory',
    position: 1,
  },

  {
    id: generateId(),
    columnName: 'updatedAt',
    columnType: 'date',
    required: false,
    category: 'date',
    description: '',
    position: 2,
  },
  {
    id: generateId(),
    columnName: 'createdAt',
    columnType: 'date',
    required: true,
    category: 'date',
    description: 'Mandatory',
    position: 3,
  },
];

export const AudienceRFMColumns: SchemaColumn[] = [
  {
    id: '1639398596380.040o5m',
    columnName: 'master_id',
    columnType: 'string',
    category: '',
    description: '',
    position: 0,
    required: true,
  },
  {
    id: '1639398596380.04o5m',
    columnName: 'id_eshop',
    columnType: 'string',
    category: '',
    description: '',
    position: 1,
    required: true,
  },
  {
    id: '1639398596380.0ohfi',
    columnName: 'id_pos',
    columnType: 'date',
    category: '',
    description: '',
    position: 2,
    required: true,
  },
  {
    id: '1639398596380.0n5do',
    columnName: 'phone',
    columnType: 'string',
    category: '',
    description: '',
    position: 3,
    required: false,
  },
  {
    id: '1639398596380.0n5do',
    columnName: 'firstname',
    columnType: 'string',
    category: '',
    description: '',
    position: 4,
    required: false,
  },
  {
    id: '1639398596380.0fn7o',
    columnName: 'RFM_segment',
    columnType: 'string',
    category: '',
    description: '',
    position: 5,
    required: false,
  },
  {
    id: '1639398596380.0fn7o',
    columnName: 'RFM_score',
    columnType: 'string',
    category: '',
    description: '',
    position: 6,
    required: false,
  },
  {
    id: '1640963173901.0zasd',
    columnName: 'cross_sell',
    columnType: 'string',
    category: '',
    description: '',
    position: 7,
    required: false,
  }
];
